import React from "react";
import {useTranslation} from "react-i18next";
import {Col, Form, Radio, Row, Typography} from "@bezlimit/bezlimit-ui";
import {Divider, FormInstance, FormItemProps} from "antd";
import _ from "lodash";
// core
import {IPhonesFiltersStore} from "../../../core/models/PhonesFilters";
//components
import DropdownFilter from "../../blocks/numbers/filters/desktop/selected/myFilterSets/dropdown";
//services
import NumbersFiltersStoreService from "../../../services/NumbersFiltersStoreService";
import {observer} from "mobx-react";

export default observer(function RadioSaveField({
    name,
    items,
    options,
    formInstance,
    ...props
}: IProps) {
    const {t} = useTranslation();

    const onChange = (e: any) => {
        const field = formInstance.getFieldValue(name);

        if (_.isEqual(field, +e.target.value)) {
            NumbersFiltersStoreService.reset();
            props.setType(["standard"]);
            props.setTariff([]);
            props.setCategories([]);
            props.setRegion([]);
            props.setPattern([]);
            props.setPhoneCombs(undefined);
            props.setPhonePattern([]);

            if (props.setTariffSize) {
                props.setTariffSize(0);
            }
            if (props.setCategoriesSize) {
                props.setCategoriesSize(0);
            }
            if (props.setRegionSize) {
                props.setRegionSize(0);
            }
        } else if (
            _.isNil(NumbersFiltersStoreService.current) &&
            !_.isNil(NumbersFiltersStoreService.items[+e.target.value])
        ) {
            NumbersFiltersStoreService.current = +e.target.value;
            const {values} = NumbersFiltersStoreService.items[+e.target.value];

            props.setType(values.type ?? []);
            props.setTariff(values.tariff ?? []);
            props.setCategories(values.categories ?? []);
            props.setRegion(values.region ?? []);
            props.setPattern(values.pattern ?? []);
            props.setPhoneCombs(values.phone_combs);
            props.setPhonePattern(values.phone_pattern ?? []);

            if (props.setTariffSize && !_.isNil(values.tariff)) {
                props.setTariffSize(values.tariff.length ?? 0);
            }
            if (props.setCategoriesSize && !_.isNil(values.categories)) {
                props.setCategoriesSize(values.categories.length ?? 0);
            }
            if (props.setRegionSize && !_.isNil(values.region)) {
                props.setRegionSize(values.region.length ?? 0);
            }
        } else {
            NumbersFiltersStoreService.current = +e.target.value;
            const {values} = NumbersFiltersStoreService.items[+e.target.value];

            props.setType(values.type ?? []);
            props.setTariff(values.tariff ?? []);
            props.setCategories(values.categories ?? []);
            props.setRegion(values.region ?? []);
            props.setPattern(values.pattern ?? []);
            props.setPhoneCombs(values.phone_combs);
            props.setPhonePattern(values.phone_pattern ?? []);

            if (props.setTariffSize && !_.isNil(values.tariff)) {
                props.setTariffSize(values.tariff.length ?? 0);
            }
            if (props.setCategoriesSize && !_.isNil(values.categories)) {
                props.setCategoriesSize(values.categories.length ?? 0);
            }
            if (props.setRegionSize && !_.isNil(values.region)) {
                props.setRegionSize(values.region.length ?? 0);
            }
        }
    };

    return (
        <Form.Item {...options} className="field-radio" name={name}>
            <Radio.Group>
                {_.chain(items)
                    .map(({name: label}, k) => (
                        <React.Fragment key={k}>
                            <Radio value={k} checked={true} onClick={onChange}>
                                <Row>
                                    <Col span={23}>
                                        <Typography as="div" size={14}>
                                            {t(label)}
                                        </Typography>
                                    </Col>
                                    <Col span={1}>
                                        <Typography as="div" size={14} className="dropdown-filter">
                                            <DropdownFilter index={k} {...props} />
                                        </Typography>
                                    </Col>
                                </Row>
                            </Radio>
                            <Divider />
                        </React.Fragment>
                    ))
                    .valueOf()}
            </Radio.Group>
        </Form.Item>
    );
});

interface IProps {
    name: string;
    items: IPhonesFiltersStore[];
    options?: FormItemProps<any>;
    formInstance: FormInstance<any>;
    phonePattern: string[];
    phoneCombs: string | undefined;
    setType: React.Dispatch<React.SetStateAction<string[]>>;
    setTariff: React.Dispatch<React.SetStateAction<string[]>>;
    setCategories: React.Dispatch<React.SetStateAction<string[]>>;
    setRegion: React.Dispatch<React.SetStateAction<string[]>>;
    setPattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhonePattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhoneCombs: React.Dispatch<React.SetStateAction<string | undefined>>;
    setTariffSize?: React.Dispatch<React.SetStateAction<number>>;
    setCategoriesSize?: React.Dispatch<React.SetStateAction<number>>;
    setRegionSize?: React.Dispatch<React.SetStateAction<number>>;
}
