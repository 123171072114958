import {Button, Collapse, Typography} from "@bezlimit/bezlimit-ui";
import {RangeValue} from "rc-picker/lib/interface";
import "./style.scss";
import _ from "lodash";
import "./style.scss";
import React, {useState} from "react";
import {useTranslation} from "react-i18next";
//core
import {IBonusesData, IBonusesRequest} from "../../../../core/models/Bonus";
//components
import TitlePayments from "./titlePayments";
import Items from "./items";
import TitlePayouts from "./titlePayouts";
import moment from "moment";

export default function List({start, data, period, activeKey, loading, onClick, fn}: IProps) {
    const {t} = useTranslation();
    const [activeKeys, setCctiveKeys] = useState([]);

    const onChange = (values: any) => {
        setCctiveKeys(values);
    };

    return (
        <>
            <Collapse
                ghost
                expandIconPosition="end"
                activeKey={activeKeys}
                destroyInactivePanel={true}
                onChange={onChange}
            >
                {_.chain(data)
                    .filter(({summary}) => !!(summary?.cnt || summary?.sum))
                    .map(({month, request, summary}) => {
                        return (
                            <React.Fragment key={month.startOf("month").format("YYYY-MM-DD")}>
                                {_.isEqual(activeKey, "payments") ? (
                                    <TitlePayments month={month} summary={summary!} />
                                ) : (
                                    <TitlePayouts month={month} summary={summary!} />
                                )}
                                <Collapse.Panel
                                    header=""
                                    collapsible={_.isEqual(summary?.cnt, 0) ? "disabled" : "header"}
                                    key={month.startOf("month").format("YYYY-MM-DD")}
                                >
                                    <Items
                                        period={period}
                                        request={request}
                                        activeKey={activeKey}
                                        fn={fn}
                                    />
                                </Collapse.Panel>
                            </React.Fragment>
                        );
                    })
                    .valueOf()}
            </Collapse>

            {!period &&
                _.gt(_.last(data)?.month.diff(moment(start).startOf("month"), "seconds"), 0) && (
                    <Typography as="div" className="btn-show">
                        <Button onClick={onClick} loading={loading} className="btn-show-list">
                            {t("Показать еще")}
                        </Button>
                    </Typography>
                )}
        </>
    );
}

interface IProps {
    start: string;
    period: RangeValue<moment.Moment>;
    data: IBonusesData[];
    activeKey: string;
    loading: boolean;
    onClick: () => void;
    setData: React.Dispatch<React.SetStateAction<IBonusesData[]>>;
    fn: (request?: IBonusesRequest | undefined, loading?: boolean) => Promise<any>;
}
