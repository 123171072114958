import {useContext} from "react";
import {Button, Col, Icon, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import "./style.scss";
// core
import {IReservations} from "../../../../../../core/models/Reservations";
import {LayoutContext, ModalContext} from "../../../../../../core/Contexts";
// components
import Phone from "../../../../numbers/list/default/info/phone";
import Description from "../../../reservationList/info/description";
import Status from "./status";
import NumberListInfoModal from "../../../../../modal/numberListInfo";
import {observer} from "mobx-react";
import RemoveSim from "../../../../../modal/removeSim";

export default observer(function Item({
    id,
    sim,
    phone_number: phone,
    pay_type,
    additional_data,
    removed_at,
    can_change_sim,
    phone: {
        status,
        activated_at,
        unblock_sum,
        type,
        mask: {category},
        region: {name: regionName}
    },
    tariff: {
        id: tariffId,
        name: tariffName,
        minutes,
        internet,
        sms,
        prolong_paid_price: prolongPaidPrice
    }
}: IReservations) {
    const contextLayout = useContext(LayoutContext);
    const contextModal = useContext(ModalContext);

    const onClick = () => {
        contextModal?.setChildren(
            <NumberListInfoModal
                phone={phone}
                tariffName={tariffName}
                minutes={minutes}
                internet={internet}
                type={type}
                sms={sms}
                id={id}
            />
        );
        contextModal?.setVisible(true);
    };

    const onRemoveSim = () => {
        contextModal?.setChildren(<RemoveSim phone={phone} sim={sim} id={id} />);
        contextModal?.setVisible(true);
    };
    let phoneNPS = "";
    if (additional_data?.nps) {
        phoneNPS = additional_data?.nps.phone;
    }

    return (
        <Typography
            as="div"
            className={`bl-activation-list-items-item${
                _.isEqual(pay_type, "super_link")
                    ? " bl-super_link"
                    : !_.isEqual(phoneNPS, "")
                    ? " bl-nps"
                    : ""
            }`}
        >
            <Row gutter={[16, _.gt(contextLayout?.width ?? 0, 768) ? 16 : 8]}>
                <Col xs={12} lg={_.isEqual(can_change_sim, true) ? 16 : 18}>
                    <Row gutter={16} className="left">
                        <Col xs={24} xl={4} className="phone">
                            <Space direction="horizontal" size={8}>
                                <Phone category={category} phone={phone} />
                                {_.lte(contextLayout?.width, 992) ? (
                                    <Icon
                                        className="info"
                                        name="info"
                                        size="16"
                                        onClick={onClick}
                                    />
                                ) : null}
                            </Space>
                        </Col>
                        <Col xs={24} xl={18} className="desc">
                            <Description
                                tariffName={tariffName}
                                minutes={minutes}
                                internet={internet}
                                sms={sms}
                                pay_type={pay_type}
                                regionName={regionName}
                                additional_data={additional_data}
                                phoneNPS={phoneNPS}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col xs={12} lg={_.isEqual(can_change_sim, true) ? 4 : 6} className="status">
                    <Status
                        status={status}
                        activated_at={activated_at}
                        unblock_sum={unblock_sum}
                        pay_type={pay_type}
                    />
                </Col>
                {_.isEqual(can_change_sim, true) && (
                    <Col xs={24} lg={4}>
                        <Button className="btn-remove-sim" onClick={onRemoveSim}>
                            Смена СИМ-карты
                        </Button>
                    </Col>
                )}
            </Row>
        </Typography>
    );
});
