import React, {useContext} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import "./style.scss";
// components
import Duration from "../../blocks/duration";
// services
import UserService from "../../../services/UserService";
// core
import {InterceptorContext, LayoutContext} from "../../../core/Contexts";
import CodeConfirmation from "../../forms/codeConfirmation";

export default React.memo(function EmailCodeConfirmation({
    email,
    onSubmit,
    type = "email",
    ...props
}: any) {
    const context = useContext(InterceptorContext);
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();

    return (
        <Typography as="div" className="bl-email-code-confirmation">
            <Space
                size={_.gte(contextLayout?.width ?? 0, 768) ? 8 : 56}
                direction="vertical"
                align="center"
            >
                <Typography as="div" className="title-container">
                    <Space size={8} direction="vertical" align="center">
                        <Typography
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                            level={2}
                            color="#000000"
                        >
                            {t("Введите код")}
                        </Typography>
                        <Typography
                            size={14}
                            color={_.gte(contextLayout?.width ?? 0, 768) ? "#000" : "#828282"}
                            className="text-helper subtitle"
                        >
                            {t("Вам будет отправлен код на почту ")}
                            <br />
                            {type === "password"
                                ? t("для смены пароля")
                                : t("для смены электронной почты")}
                        </Typography>
                    </Space>
                </Typography>
                <CodeConfirmation {...props} type={type} email={email} onSubmit={onSubmit} />

                {/* <EmailCodeConfirmationForm {...props} type={type} email={email} onSubmit={onSubmit} /> */}
                {!context?.data?.message && _.gte(contextLayout?.width ?? 0, 768) && (
                    <Typography size={14} color="#000000" className="send-to-email-message">
                        {t("Мы отправили код подтверждения на {email}", {email})}
                        {
                            (type = "password" && (
                                <>
                                    <br />
                                    {t("Для смены пароля почта должна быть подтверждена")}
                                </>
                            ))
                        }
                    </Typography>
                )}
                <Duration
                    onTrigger={() => {
                        if (type === "password") {
                            const password: string = props.password;
                            const password_new: string = props.password_new;
                            const password_new_repeat: string = props.password_new_repeat;
                            UserService.putChangePassword({
                                password,
                                password_new,
                                password_new_repeat,
                                isloading: false
                            });
                        } else {
                            UserService.putUsers({email, isloading: false});
                        }
                    }}
                />
            </Space>
        </Typography>
    );
});
