import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {Form, Typography, Button, Input} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {useContext, useRef} from "react";
import {useTranslation} from "react-i18next";
import "./style.scss";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
// core
import {InterceptorContext} from "../../../core/Contexts";
// components
import DurationPhone from "../../blocks/durationPhone";

export default React.memo(
    observer(function CodeConfirmationFormPhone({onSubmit, ...props}: any) {
        const ref = useRef(null);
        const context = useContext(InterceptorContext);
        const {t} = useTranslation();
        const [initForm] = Form.useForm();
        const formInstance = useFormHandler(initForm);
        const [errorsButton, setErrorsButton] = useState(false);
        const [phoneAndEmail, setPhoneAndEmail] = useState<string>();

        const onSend = async (values: any) => {
            try {
                await onSubmit(values);
            } catch (e) {
                setErrorsButton(true);
            }
        };

        const onFinish = async (values: any) => {
            onSend({...props, code: phoneAndEmail});
        };

        const error = _.chain(
            _.isArray(context?.data)
                ? context?.data
                : context?.data?.message
                ? [{message: context?.data?.message, field: context?.data?.message}]
                : []
        )
            .map(({field, message}) => message)
            .join(" ")
            .valueOf();

        const onChangeDummy = (value: any) => {
            if (phoneAndEmail?.length === 6) {
                context!.setErrors(null);
            }
            setErrorsButton(false);
            setPhoneAndEmail(value.join(""));
        };

        useEffect(() => {
            document!.getElementsByTagName("input")[0].focus();
        }, []);

        return (
            <div ref={ref}>
                <Typography
                    as="div"
                    className="bl-code-confirmation-form bl-code-confirmation-formPhone"
                >
                    <Form
                        form={formInstance}
                        name="codeConfirmation"
                        initialValues={{code: ""}}
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <Typography as="div" className="code-container">
                            <Input.Number
                                onChange={onChangeDummy}
                                count={6}
                                type={"number"}
                                inputMode={"numeric"}
                                pattern={"[0-9]*"}
                                autoComplete={"off"}
                                error={error}
                            />
                        </Typography>
                        <DurationPhone phone={props.phone} runInit={false} />
                        <Form.Item shouldUpdate>
                            {() => {
                                const formIsComplete =
                                    phoneAndEmail &&
                                    _.isEqual(phoneAndEmail.length, 6) &&
                                    !errorsButton &&
                                    !error;

                                return (
                                    <Button
                                        className="bl-code-confirmation-button-submit"
                                        type={!formIsComplete ? "default" : "primary"}
                                        htmlType="submit"
                                        disabled={!formIsComplete}
                                        loading={context?.loadingBtn}
                                    >
                                        {t("Подтвердить")}
                                    </Button>
                                );
                            }}
                        </Form.Item>
                    </Form>
                </Typography>
            </div>
        );
    })
);
