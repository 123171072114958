import confirmYourAccount from "../../images/confirmYourAccount.png";
import confirmYourAccountProfile from "../../images/confirmYourAccountProfile.png";

export const SUPPORTFAQMOCK = [
    {
        id: 1,
        title: "Как активировать номер?",
        description: `<p><strong>Для активации номера необходимо:</strong></p>
        <ul>
        <li>Забронировать номер в разделе <strong>"База номеров"</strong></li>
        <li>Внести серийный номер сим-карты и паспортные данные пользователя в разделе  <strong>«Брони и Активации»</strong></li>
        <li>Пополнить баланс</li>
        </ul>
        <p><strong>Для моментального поступления денежных средств на номер пополняйте баланс следующими способами:</strong></p>
        <ul>
        <li>Бонусами в приложении Store</li>
        <li>Используя Сбербанк онлайн, выбрав оператора «Безлимит» в разделе «Мобильная связь»</li>
        <li>Через терминалы QIWI и Элекснет, выбирая оператора «Безлимит»</li>
        <li>Банковской картой через банкоматы МКБ и Сбербанк выбирая оператора «Безлимит»</li>
        <li>Банковской картой на нашем сайте: <a href="https://bezlimit.ru/payment/">Пополнить баланс - Безлимит</a></li>
        </ul>
        <p><strong>ВНИМАНИЕ!</strong> В случае пополнения денежных средств через Билайн, зачисление возможно от 1 часа до суток. Для корректной активации номера, средства на баланс должны поступить до истечения срока брони.</p>
        <p>После поступления денежных средств на номер, закрепление к сим-карте производится в течении 10 минут. Ввиду технических настроек сим-карт, интернет-соединение на номере может появиться в течении 1 часа.</p>`
    },
    {
        id: 2,
        title: "Как получить бонусы?",
        description: `<p>Активируя номера, Вы будете получать <strong> бонусы в размере процента от тарифного плана</strong>, на который включился номер. <strong>Один бонус – один рубль.</strong> Посмотреть количество бонусов и процент за активации можно в разделе <strong>«Мои бонусы»</strong> и на <strong>Главной</strong>.</p>
        <p>Если Вы пользуетесь номером Безлимит, то будете получать за активации <strong>дополнительные 10%.</strong> Бонусы начисляются в течение первых 30 дней активности номера. Чтобы получить бонусы за активацию номера важно пополнить баланс номера до истечения срока брони.</p>
        <p><strong>Бонусами можно:</strong></p>
        <ul>
        <li>Мгновенно <strong>пополнять любые номера Безлимит</strong> от 100 бонусов</li>
        <li><strong>Вывести на банковскую карту</strong> при накоплении от<br/> 5 000 бонусов</li>
        <li><strong>Оплатить платную бронь</strong> на самые редкие и ценные номера</li>
        </ul>
        <p>Пополнить баланс номеров Безлимит и вывести бонусы на карту можно после прохождения  <strong>процедуры регистрации </strong>. В процессе регистрации нужно прикрепить фото своего паспорта.</p>
        <p>Все начисления по бонусам отображаются на главном экране и в <strong>«Истории начислений»</strong> в разделе <strong>«Мои бонусы»</strong>. Начисления бонусов за активации приглашенных пользователей отображаются в <strong>«Начислениях дилеров»</strong>. Все списания с бонусного счета отображаются в <strong>«Истории списаний»</strong> и для удобства разделены на «Вывод на карту», «Пополнение баланса», «Платная бронь».
        </p>
        <p>Вывод бонусов на банковскую карту производится <strong>в течение 5 рабочих дней</strong> после отправки заявки. </p>`
    },
    {
        id: 3,
        title: "Как заказать SIM-карту?",
        description: `<p><strong>Бесплатную</strong> доставку сим-карт и конвертов можно заказать в разделе <strong>«Доставка»</strong>. Доставка по Москве, Санкт-Петербургу и Махачкале осуществляется курьерами в будние дни с 10 до 19 часов. Доставка в другие регионы осуществляется Почтой России. </p>
        <p>Чтобы заказать доставку, нажмите кнопку <strong>«Новая доставка»</strong>. Заполните необходимые поля формы заказа, указав количество сим-карт, конвертов, ФИО получателя, контактный телефон, адрес доставки, также можете оставить комментарий к заказу в свободной форме.</p>
        <p><strong>Количество сим-карт и конвертов</strong>, которые Вы можете заказать за один раз, <strong>зависит от количества Ваших активаций</strong> за последние 30 дней:</p>
        <ul>
        <li><strong>от 0 до 3 активаций</strong> – можно заказать до 3 сим-карт и 3 конвертов</li>
        <li><strong>от 4 до 10 активаций</strong> – можно заказать до 15 сим-карт и 10 конвертов</li>
        <li><strong>от 11 до 30 активаций</strong> – можно заказать до 25 сим-карт и 20 конвертов</li>
        <li><strong>от 31 и более активаций </strong>– можно заказать до 50 сим-карт и 30 конвертов</li>
        </ul>
        <p>Заказывать доставку можно один раз в 7 дней.</p>
        <p>После того как Вы заполните форму заказа, и нажмите кнопку <strong>«Оформить доставку»</strong> — заказ будет сформирован. Отследить <strong> статус заказа</strong> можно в разделе <strong>«Мои доставки»</strong>. Статус заказ будет меняться в процессе его обработки – «Новый», «В обработке», «Отправлен почтой» и т. д. Если заказ отправлен Почтой России или курьерской службой СДЭК, вам будет доступна ссылка с номером трека для отслеживания заказа. </p>`
    },
    {
        id: 4,
        title: "Как участвовать в Розыгрыше Безлимит?",
        description: `
        <p>Компания Безлимит регулярно проводит розыгрыши бонусов для своих партнеров. Для участия нужно подключить хотя бы один красивый номер через Store Безлимит или по суперссылке.</p>
        <p>В зависимости от тарифа и способа подключения активация даст от 1 до 4 кодов. 1 код — 1 шанс выиграть. Чем больше номеров вы активируете, тем больше шансов на победу.</p>
        <p>Название текущего розыгрыша, информацию о номерах, участвующих в нем, дату розыгрыша, количество мест и призов вы можете посмотреть на странице Розыгрыша в разделе <strong>«Подробные условия проведения»</strong>.</p>
        <p>В день проведения розыгрыша всем активированным номерам робот проставит коды розыгрыша — A, B, C. Появится таймер отсчета времени и ссылка на трансляцию. В прямом эфире на нашем YouTube-канале ведущие будут вынимать из трех лототронов шары, соответствующие каждому из кодов. Вы выиграете, если выпадет ваш номер.</p>
        <p>Обязательным условием участия является прохождение полной регистрации в Store</p>
        
        
        <p>Мы гарантируем честное проведение и соблюдение всех правил и условий Розыгрыша. Убедительно просим и наших партнеров подключать реальных пользователей, чтобы одержать честную победу в Розыгрыше.</p>
        <p>Безлимит оставляет за собой право не выплачивать призы и прекратить сотрудничество в случае выявления массовых подключений фиктивных активаций. </p>`
    },
    {
        id: 5,
        title: "Как получить SIM-карту в офисе Билайн?",
        description: `<p>Для получения сим-карты в Билайн перейдите в раздел <strong>«Брони и активации»</strong>, вкладка <strong>«Брони»</strong>. В карточке номера выберите <strong>«Легкая выдача SIM в Билайн»</strong> (в приложении) или кликните на <strong>иконку сим-карты </strong>(веб-версия). После этого сформируется задание на получение сим-карты в Билайн. На экране появится <strong>номер задания</strong>, который можно скопировать, нажав на кнопку «Скопировать».</p>
        <p>Обратите внимание, что Вы можете пользоваться опцией выдачи sim-карт в Билайн <strong>только на забронированных Вами номерах.</strong></p>
        <p>Отправьте пользователю номер задания. С этим номером он должен обратиться в ближайший собственный офис Билайн. Пользователю выдадут пустую сим-карту, номер которой нужно внести в окно редактирования бронирования или закрепить через кнопку «Сменить SIM», если номер активен (доступно в течение 3 дней с момента активации номера).</p>
        <p>Количество выдачи SIM в Билайн в день ограничено и зависит от Ваших личных активаций за последние 30 дней:</p>
        <ul>
        <li><strong>от 0 до 5 активаций</strong> - 2 легкие выдачи сим в день;</li>
        <li><strong>от 6 до 20 активаций</strong> - 3 легкие выдачи сим в день;</li>
        <li><strong>более 21 активации </strong>- 5 легких выдач сим в день.</li>
        </ul>
        <p>Если у Вас будут вопросы по поводу выдачи сим-карт в собственных офисах Билайн, обратитесь в <strong>чат Поддержки продаж</strong> в разделе <strong>«Поддержка»</strong>. Специалисты помогут Вам разобраться.</p>`
    },
    {
        id: 6,
        title: "Как подключить e-SIM?",
        description: `<p><strong>eSIM</strong> — это новая технология, позволяющая владельцам смартфонов пользоваться всеми услугами оператора без пластиковой сим-карты. eSIM уже <strong>встроена в устройство</strong>, поэтому для ее оформления клиенту <strong>не нужно идти в салон связи.</strong></p>
        <p>Удобство оформления eSIM в том, что оно происходит <strong>дистанционно</strong> и пользователю не нужно ждать получения пластиковой карты. Одну и ту же eSIM нельзя одновременно активировать на нескольких устройствах.
        </p>
        <p><strong>Для подключения виртуальной карты оператора Безлимит необходимо:</strong></p>
        <ul>
        <li>Забронировать и активировать номер</li>
        <li>Отправить заявку специалистам поддержки продаж в чат, указав номер телефона, на котором будет подключена eSIM, и адрес электронной почты для отправки QR-кода</li>
        <li>После получения письма необходимо отсканировать QR-код телефоном, поддерживающим eSIM, и активировать виртуальную карту на телефоне</li>
        </ul>
        <p>При возникновении вопросов обратитесь в <strong>чат Поддержки продаж</strong> в разделе <strong>«Поддержка»</strong>, где вам расскажут, как перенести номер на eSIM.</p>`
    },
    {
        id: 7,
        title: "Информация для клиентов",
        description: `<p>Пользователь всегда может самостоятельно обратиться в <strong>Поддержку Безлимит</strong> по всем интересующим вопросам.</p>
            <ul>
            <li>Круглосуточная техническая поддержка +74959669999</li>
            <li>Для звонка с блокированного номера 09966</li>
            <li><a href="https://wa.me/79039669999" target={"_blank"} rel="noreferrer">WhatsApp</a>, <a href="viber://chat?number=79039669999" target={"_blank"} rel="noreferrer">Viber</a> и <a href="https://telegram.im/@bezlimitruofficial" target={"_blank"} rel="noreferrer">Telegram</a></li>
            <li><a href="https://wa.me/79629669999" target={"_blank"} rel="noreferrer">WhatsApp</a> для внесения паспортных данных</li>
            <li>Почта <a href="mailto:info@bezlimit.ru" target={"_blank"} rel="noreferrer">info@bezlimit.ru</a></li>
            <li>Telegram-бот <a href="https://telegram.im/@BezlimitRuBot" target={"_blank"} rel="noreferrer">@BezlimitRuBot</a>, который подскажет ваш текущий баланс, остаток минут, смс и интернета.</li>
            </ul>
            <p><strong>Личный кабинет пользователя</strong> — это приложение Безлимит, которое всегда под рукой: легкое управление номерами, тарифами и услугами мобильной связи. Приложение доступно в AppStore и Google Play.</p>
            <ul>
            <li><a href="https://play.google.com/store/apps/details?id=com.bezlimit.lk" target={"_blank"} rel="noreferrer">Android</a></li>
            <li><a href="https://apps.apple.com/ru/app/%D0%B1%D0%B5%D0%B7%D0%BB%D0%B8%D0%BC%D0%B8%D1%82/id1580060416" target={"_blank"} rel="noreferrer">iOS</a></li>
            <li><a href="https://lk.bezlimit.ru/" target={"_blank"} rel="noreferrer">Веб версия приложения</a></li>
            </ul>
            <p><strong>USSD команды </strong>для самостоятельного решения частых вопросов по услугам Безлимит:</p>
            <ul>
            <li>меню управления услугами *966#</li>
            <li>проверка Баланса *966*0#</li>
            <li>текущее начисление *966*1#</li>
            <li>текущий тариф *966*2#</li>
            <li>номер телефона *966*3#</li>
            <li>обещанный платеж *966*5#</li>
            <li>отключение подписок *966*6#</li>
            <li>перевод денежных средств *966*7#</li>
            <li>остатки по пакетам *966*99#</li>
            </ul>
            <p><strong>Продление интернета:</strong></p>
            <ul>
            <li>*966*05# - 5 Гб. стоимость 290 руб.</li>
            <li>*966*20# - 20 Гб. стоимость 410 руб.</li>
            <li>*966*30# - 30 Гб. стоимость 510 руб.</li>
            </ul>
            <p>Официальный сайт: <a href="https://bezlimit.ru/" target={"_blank"} rel="noreferrer">https://bezlimit.ru</a></p>
            `
    },
    {
        id: 8,
        title: "Как не платить за связь?",
        description: `<p>Каждый пользователь компании Безлимит может принять участие в акции «Не плати за связь». Суть акции в том, что пользователь делится персональной ссылкой с друзьями, родственниками, близкими — с кем угодно. Ссылка-приглашение ведет на самостоятельный подбор и подключение красивого номера.</p>
        <p>Когда друг подключает красивый номер, пользователь получает бонусы — 20% от тарифного плана, на который включился номер. Бонусами можно оплачивать связь и услуги Безлимит. Количество друзей, приведенных по акции, не ограничено. Акция бессрочная, бонусы начисляются до тех пор, пока оба номера активны (не заблокированы).</p>
        <p>Партнеры компании тоже могут получить бонусы за акцию «Не плати за связь». Рассказывайте новым клиентам об акции и оповестите старых. Если ваш клиент примет участие в акции, вы получите 10% от тарифа, на который включится номер по ссылке-приглашению.</p>
        <p>Полученные бонусы будут начислены единовременно на бонусный счет в Store Безлимит. Бонусами можно оплатить сотовую связь и продление бронирования номера, а также вывести на карту.</p>`
    },
    {
        id: 9,
        title: "Как подтвердить номер на ГосУслугах?",
        description: `<p>Согласно Федеральным законам N 533-ФЗ «О внесении изменений в Федеральный закон "О связи"», N 115-ФЗ «О противодействии легализации (отмыванию) доходов, полученных преступным путем, и финансированию терроризма», N 126-ФЗ «О внесении изменений в отдельные законодательные акты Российской Федерации по вопросам обязательного социального страхования» компания Безлимит <strong>обязана осуществлять проверку на достоверность персональных данных</strong>, предоставленных пользователями.</p>
        <p><strong>Для активации сим-карты необходимо внести достоверные персональные данные удобным для Вас способом:</strong></p>
        <ul>
        <li>через круглосуточный центр поддержки клиентов по номеру <a href="tel:+74959669999" rel="noreferrer">+74959669999</a>;</li>
        <li>на сайте <a href="https://bezlimit.ru/verification/" target={"_blank"} rel="noreferrer">https://bezlimit.ru/verification/</a></li>
        <li>почту <a href="mailto:info@bezlimit.ru" target={"_blank"} rel="noreferrer">info@bezlimit.ru</a>;</li>
        <li>чаты технической поддержки <a href="https://wa.me/79039669999" target={"_blank"} rel="noreferrer">WhatsApp</a>, <a href="https://telegram.im/@bezlimitruofficial" target={"_blank"} rel="noreferrer">Telegram</a> и <a href="viber://chat?number=79039669999" target={"_blank"} rel="noreferrer">Viber</a> по номеру <a href="tel:+74959669999" rel="noreferrer">+74959669999</a></li>
        </ul>
        <p><strong>После внесения данных войдите в свою учетную запись на Госуслугах (она должна быть подтвержденной):</strong></p>
        <ul>
        <li>Оператор связи отправит запрос на активацию сим-карты. Он появится в разделе <strong>«Уведомления»</strong></li>
        <li>Проверьте номер телефона и Ваши паспортные данные — они должны совпадать с данными, которые Вы передали в Безлимит</li>
        <li>Нажмите кнопку <strong>«Подтвердить»</strong></li>
        </ul>
        <p>Через некоторое время в личный кабинет Госуслуг поступит уведомление об активации номера. <strong>Теперь сим-картой можно пользоваться</strong>, номер телефона будет добавлен в Ваш профиль на Госуслугах.</p>
        <p><strong>Подробная инструкция:</strong> <a href="https://www.youtube.com/watch?v=C0eZ8OU5_Gg" target={"_blank"} rel="noreferrer">https://www.youtube.com/watch?v=C0eZ8OU5_Gg</a></p>`
    },
    {
        id: 10,
        title: "Как изменить тариф?",
        description: `<p><strong>Сменить тариф на более высокий</strong> можно при бронировании номера. После активации номера смена тарифа возможна <strong>в начале каждого месяца с 1 по 5 число включительно</strong>. Изменение тарифного плана возможно один раз в месяц. Тариф <strong>нельзя изменить на более низкий</strong>, чем минимальный тариф, доступный при бронировании номера.</p>
        <p><strong>Запрос на смену тарифа</strong> пользователь может сделать в мобильном приложении Безлимит для iOS и Android. Также можно воспользоваться Личным кабинетом на сайте Безлимит:
        <a href="https://lk.bezlimit.ru/site/login" target={"_blank"} rel="noreferrer">https://lk.bezlimit.ru/site/login</a></p>
        <p><strong>Подробное описание тарифных планов</strong> доступно на сайте Безлимит:
        <a href="https://bezlimit.ru/plans/" target={"_blank"} rel="noreferrer">https://bezlimit.ru/plans/</a>
        и в личном кабинете пользователя. Для консультации и смены тарифного плана пользователь всегда может обратиться в <strong>Центр поддержки клиентов</strong> по номеру <a href="tel:+74959669999" target={"_blank"} rel="noreferrer">+7(495)966-9999</a></p>`
    },
    {
        id: 11,
        title: "Как разблокировать номер?",
        description: `<p><strong>ВНИМАНИЕ!</strong> На номерах с тарифным планом 1200 и выше установлена <strong>платная блокировка</strong>.</p>
        <p><strong>При отрицательном балансе</strong> на счете номер будет <strong>заблокирован</strong>. Для разблокировки необходимо пополнить баланс номера <strong>до суммы положительного баланса</strong> в зависимости от тарифного плана:</p>
        <ul>
        <li>Тариф 499 - 0,01 р.</li>
        <li>Тарифы 250 - 450 - 50 р.</li>
        <li>Тарифы 500 - 950 - 100 р.</li>
        <li>Тарифы 1000 - 1700 - 300 р.</li>
        <li>Тарифы 2000 - 3600 - 500 р.</li>
        <ul>`
    },
    {
        id: 12,
        title: "Как заменить SIM-карту на активном номере?",
        description: `<p>Если при бронировании номера Вы не указывали серийный номер сим-карты, то замену можно произвести в течение 3-х дней с момента активации номера. Для этого перейдите в раздел <strong>«Брони и активации»</strong>, раздел <strong>«Активации»</strong>, найдите нужный номер. В приложении выберите <strong>«Смена сим-карты»</strong>, в веб-версии нажмите на <strong>иконку сим-карты</strong> рядом с номером. Укажите серийный номер сим-карты, замена будет произведена в течение 5–10 минут. В момент смены сим-карты номер должен быть активен.</p>`
    },
    {
        id: 13,
        title: "Как подключить международный роуминг?",
        description: `<p>Для подключения или отключения услуги необходимо обратиться в наш Центр поддержки клиентов по номеру +7(495)966-9999 или подключить услугу самостоятельно через приложение Безлимит личный кабинет пользователя или на сайте <a href="https://bezlimit.ru/" target={"_blank"} rel="noreferrer">«Безлимит»</a>.</p>
        <p>Подключение будет выполнено в течение часа. По истечении указанного времени перезагрузите аппарат. Если на территории действия роуминга не удается автоматически подключиться к сети, нужно выбрать ручной поиск в настройках мобильного телефона и подключиться к любой доступной сети.</p>
        <p>Доступ к звонкам в Республике Крым и городе Севастополе осуществляется при включенном международном роуминге.</p>
        `
    },
    {
        id: 14,
        title: "Как подтвердить свою учетную запись в Store?",
        description: `<p>После первого входа в Store необходимо <strong>пройти регистрацию паспортных данных, подтвердить свой номер телефона и электронную почту</strong>. Регистрация паспортных данных необходима для <strong>вывода бонусов на карту, пополнения ими баланса</strong> своих номеров и <strong>участия в Розыгрыше</strong>.</p>
        <p>Для подтверждения паспортных данных в <strong>Профиле</strong> загрузите фото паспорта — разворот с фотографией и пропиской, и нажмите кнопку <strong>«Пройти регистрацию»</strong></p>
        <img src=${confirmYourAccountProfile}>
        <p>Для завершения регистрации примите условия партнерского соглашения.</p>
        <img src=${confirmYourAccount}>
        <p>Процедура проверки паспортных данных может занять до 24 часов. После подтверждения учетной записи вы сможете принимать участие в Розыгрышах компании, выводить бонусы и пополнять ими баланс номера.</p>
        `
    }
];
