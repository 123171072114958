import {Col, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import {notification} from "antd";
import {
    EmailShareButton,
    VKShareButton,
    WhatsappShareButton,
    ViberShareButton,
    TelegramShareButton
} from "react-share";
import _ from "lodash";
import {observer} from "mobx-react";
import {useContext} from "react";
import {useTranslation} from "react-i18next";
import "./style.scss";
import emalShare from "../../../images/emalShare.png";
import vkShare from "../../../images/vkShare.png";
import whatsappShare from "../../../images/whatsappShare.png";
import viberShare from "../../../images/viberShare.png";
import telegramShare from "../../../images/telegramShare.png";
import copyShare from "../../../images/copyShare.png";
// core
import {LayoutContext} from "../../../core/Contexts";
import UserService from "../../../services/UserService";

const ShareSuperLinkModal = observer(() => {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);

    const onClick = () => {
        navigator.clipboard.writeText(UserService.systemVars?.super_link!).then(() => {
            notification.open({
                message: t("Ссылка скопирована в буфер обмена"),
                placement: "bottom",
                closeIcon: undefined,
                className: "copy-referral",
                duration: 2
            });
        });
    };

    return (
        <Typography as="div" className="bl-share-super-limk-modal">
            <Space
                size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 16}
                direction="vertical"
                align="center"
            >
                <Typography as="div" color="#1F1F1F" size={17} className="title">
                    {t("Поделиться суперссылкой")}
                </Typography>
                <Row gutter={[16, 31]}>
                    <Col span={8}>
                        <Typography as="div" onClick={onClick} className="copy-btn">
                            <img src={copyShare} alt="" />
                            <Typography as="div" color="#1F1F1F" size={12}>
                                {t("Скопировать")}
                            </Typography>
                        </Typography>
                    </Col>
                    <Col span={8}>
                        <EmailShareButton url={UserService.systemVars?.super_link!}>
                            <img src={emalShare} alt="" />
                            <Typography as="div" color="#1F1F1F" size={12}>
                                {t("Отправить на почту")}
                            </Typography>
                        </EmailShareButton>
                    </Col>
                    <Col span={8}>
                        <VKShareButton url={UserService.systemVars?.super_link!}>
                            <img src={vkShare} alt="" />
                            <Typography as="div" color="#1F1F1F" size={12}>
                                {t("VK")}
                            </Typography>
                        </VKShareButton>
                    </Col>
                    <Col span={8}>
                        <WhatsappShareButton url={UserService.systemVars?.super_link!}>
                            <img src={whatsappShare} alt="" />
                            <Typography as="div" color="#1F1F1F" size={12}>
                                {t("WhatsApp")}
                            </Typography>
                        </WhatsappShareButton>
                    </Col>
                    <Col span={8}>
                        <ViberShareButton url={UserService.systemVars?.super_link!}>
                            <img src={viberShare} alt="" />
                            <Typography as="div" color="#1F1F1F" size={12}>
                                {t("Viber")}
                            </Typography>
                        </ViberShareButton>
                    </Col>
                    <Col span={8}>
                        <TelegramShareButton url={UserService.systemVars?.super_link!}>
                            <img src={telegramShare} alt="" />
                            <Typography as="div" color="#1F1F1F" size={12}>
                                {t("Telegram")}
                            </Typography>
                        </TelegramShareButton>
                    </Col>
                </Row>
            </Space>
        </Typography>
    );
});
export default ShareSuperLinkModal;
