import React, {useContext, useRef} from "react";
import {Space, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext} from "../../../core/Contexts";
import useFieldFocus from "../../../core/hooks/useFieldFocus";
//forms
import SaveFiltersForm from "../../forms/saveFilters";

export default React.memo(
    observer(function SaveFiltersModal({title, index, name, setIsModalOpen, ...props}: IProps) {
        const contextLayout = useContext(LayoutContext);
        const ref = useRef(null);
        useFieldFocus(ref, "phone");

        return (
            <Typography as="div" className="bl-ten-for-bezlimit">
                <Space
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 40 : 24}
                    direction="vertical"
                    align="center"
                    className="conteiner-input"
                >
                    <Typography as="div">
                        <Space size={8} direction="vertical" align="center">
                            <Typography
                                size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                                level={2}
                                color="#000000"
                            >
                                {title}
                            </Typography>
                        </Space>
                    </Typography>
                    <SaveFiltersForm
                        index={index}
                        name={name}
                        setIsModalOpen={setIsModalOpen}
                        {...props}
                    />
                </Space>
            </Typography>
        );
    })
);

interface IProps {
    isMobile?: boolean;
    title: string;
    index?: number;
    name?: string;
    phonePattern: string[];
    phoneCombs: string | undefined;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
