import {useCallback, useEffect} from "react";
// services
import UserService from "../../services/UserService";

export default function useInitUser() {
    const initUser = useCallback(async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const cookies = document.cookie.split(";");
        const authToken = [];
        for (let i = 0; i < cookies.length; i++) {
            const parts = cookies[i].split("="),
                name = parts[0],
                value = parts[1];
            if (name.trim() === "authToken") {
                authToken.push(value);
            }
        }

        if (
            urlParams.get("seamless-authorization") &&
            urlParams.get("auth-token") &&
            authToken[0].length > 1
        ) {
            localStorage.setItem("auth-token", authToken[0]);
        }

        if (localStorage.getItem("auth-token")) {
            await UserService.init();
        }
    }, []);

    useEffect(() => {
        initUser();
    }, [initUser]);
}
