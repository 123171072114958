import {Back, Col, Row, Tabs, Title, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import _ from "lodash";
import {useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {observer} from "mobx-react";
import {useCallback, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
// components
import Delivery from "../../../blocks/delivery";
import NewDelivery from "../../../blocks/delivery/newDelivery";
import ArchivedDelivery from "../../../blocks/delivery/archivedDelivery";
import {svgIconSprite} from "../../../blocks/ui/svgIconSprite";
// services
import DeliveryService from "../../../../services/DeliveryService";
// core
import {IItems} from "../../../../core/models/Delivery";
import {LayoutContext} from "../../../../core/Contexts";
// hooks
import useSearchRoute from "../../../../core/hooks/useSearchRoute";
// services
import UserService from "../../../../services/UserService";
import {isMobile} from "react-device-detect";
import Header from "../../../blocks/header";

export default observer(function DeliveryPage() {
    const {t} = useTranslation();
    const [activeKey, setActiveKey] = useState("archive");
    const [, setItems] = useState<IItems[]>([]);
    const location = useLocation();
    const context = useContext(LayoutContext);
    const naigate = useNavigate();
    const params = {sort: "date", order: "newest"};
    const [, setSearchParams] = useSearchParams();

    const navigate = useNavigate();
    const route = useSearchRoute(UserService.token);

    const onTabClick = (key: string, recipient?: any) => {
        setSearchParams(params);
        naigate({pathname: `/${key}`, search: recipient && _.isString(recipient) ? recipient : ""});

        setActiveKey(key);
        // getDeliveryByArchived(key);
    };

    const getDeliveryByArchived = useCallback(async (key: string) => {
        const {items} = await DeliveryService.fetchDelivery({
            is_archived: DeliveryService.isArchive(key)
        });

        setItems(items);
    }, []);

    useEffect(() => {
        if (!location) {
            return;
        }
        const key = location.pathname.slice(1);
        setActiveKey(key);
        getDeliveryByArchived(key);
    }, [location?.pathname]);

    useEffect(() => {
        if (!activeKey) {
            return;
        }
        let title = "";
        switch (activeKey) {
            case "deliveries/archive":
                title = "Архив доставок";
                break;
            case "deliveries":
                title = "Мои доставки";
                break;
            case "deliveries/new":
                title = "Новая доставка";
                break;
            default:
                break;
        }
        document.title = `${title} | Store`;
    }, [activeKey]);

    const toggle = () => {
        (document.body.querySelector(".paralax-btn") as any)?.click();
    };

    return (
        <>
            {isMobile && <Header toggle={toggle} />}
            <Typography as="div" className="bl-delivery-sim">
                {svgIconSprite()}
                <Row>
                    <Back navigate={() => navigate("/")} />
                    <Col span={24}>
                        {_.gte(context?.width ?? 0, 768) ? (
                            <Title className="bl-title" title={_.get(route, "title")} />
                        ) : (
                            <Typography className="title-mobile" size={32} fontFamily="alumna">
                                {t("Доставка")}
                            </Typography>
                        )}
                    </Col>
                    <Col span={24}>
                        <Tabs
                            className="tabs-container"
                            tabPosition="top"
                            activeKey={activeKey}
                            animated={false}
                            centered
                            onTabClick={onTabClick}
                            tabPanes={[
                                {
                                    title: t("Новая доставка"),
                                    key: "deliveries/new",
                                    className: "content tariffs-cards",
                                    content: <NewDelivery />
                                },
                                {
                                    title: t("Мои доставки"),
                                    key: "deliveries",
                                    className: "content tariffs-cards",
                                    content: (
                                        <Delivery onTabClick={onTabClick} activeKey={activeKey} />
                                    )
                                },
                                {
                                    title: t("Архив"),
                                    key: "deliveries/archive",
                                    className: "content tariffs-cards",
                                    content: (
                                        <ArchivedDelivery
                                            onTabClick={onTabClick}
                                            activeKey={activeKey}
                                        />
                                    )
                                }
                            ]}
                        />
                    </Col>
                </Row>
            </Typography>
        </>
    );
});
