import {Button, Typography, Icon, Drawer} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import _ from "lodash";
import {useEffect, useState} from "react";
import "./style.scss";
import {Tabs} from "antd";
import {useTranslation} from "react-i18next";
import {createSearchParams, useNavigate} from "react-router-dom";
import Scrollbar from "react-scrollbars-custom";
// store
import Store from "../../../../../stores";
//services
import SettingsService from "../../../../../services/SettingsService";
//images
import closeIcon from "../../../../../images/close.png";
import aarrow from "../../../../../images/aarrow.png";
import bronzeLoyalty from "../../../../../images/bronze_loyalty.png";
import goldLoyalty from "../../../../../images/gold_loyalty.png";
import silverLoyalty from "../../../../../images/silver_loyalty.png";
import topLoyalty from "../../../../../images/top_loyalty.png";
//components
import ShimmerLoyalty from "../shimmerLoyalty";

export default observer(function HintLoyalty() {
    const [nameCode, setNameCode] = useState("");
    const [open, setOpen] = useState(false);
    const [shimmer, setShimmer] = useState(true);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const onTabClick = (activeKey: string) => {
        setShimmer(true);
    };
    const showDrawer = () => {
        setOpen(true);
    };

    const onClose = () => {
        setOpen(false);
    };

    const onClick = () => {
        navigate({
            pathname: "bonus",
            search: createSearchParams({
                modal: "1"
            }).toString()
        });
    };

    useEffect(() => {
        let name = "loyalty";
        setNameCode(name);
        if (!_.isEqual(name, "")) {
            SettingsService.getHints(name);
        }
    }, []);

    useEffect(() => {
        if (shimmer) {
            setTimeout(() => {
                setShimmer(false);
            }, 1000);
        }
    }, [shimmer]);

    return (
        <>
            {nameCode && !_.isNil(Store.user.loyalty) && (
                <Typography as="div" className="bl-hint_header bl-hint_header__move-shake">
                    <Button
                        className="btn-default"
                        onClick={showDrawer}
                        icon={<Icon className="bl-hint_header__icon" name="hint_header" />}
                    />
                    <Drawer
                        title="Программа лояльности"
                        placement="right"
                        onClose={onClose}
                        open={open}
                        className="drawer-loyalty"
                        closeIcon={<img src={closeIcon} alt="" />}
                        destroyOnClose={true}
                    >
                        <Tabs
                            defaultActiveKey={Store.user.loyalty?.code}
                            destroyInactiveTabPane={true}
                            onTabClick={onTabClick}
                        >
                            {_.chain(Store.loyalty.items)
                                .map((item) => {
                                    return (
                                        <Tabs.TabPane
                                            tab={
                                                <Typography as="div">
                                                    <Typography
                                                        as="div"
                                                        className={`tabs-icons ${item.code}-loyalty`}
                                                    >
                                                        <img
                                                            src={item.icon}
                                                            width={58}
                                                            alt="loyalty"
                                                        />
                                                        <Typography
                                                            as="div"
                                                            className={`tabs-name-${item.code}`}
                                                        >
                                                            {item.name}
                                                        </Typography>
                                                        {_.isEqual(item.code, "bronze") ? (
                                                            <Typography
                                                                as="div"
                                                                className="tabs-name-active"
                                                            >
                                                                <img
                                                                    src={bronzeLoyalty}
                                                                    width={54}
                                                                    alt="arrow"
                                                                />
                                                            </Typography>
                                                        ) : _.isEqual(item.code, "silver") ? (
                                                            <Typography
                                                                as="div"
                                                                className="tabs-name-active"
                                                            >
                                                                <img
                                                                    src={silverLoyalty}
                                                                    width={55}
                                                                    alt="arrow"
                                                                />
                                                            </Typography>
                                                        ) : _.isEqual(item.code, "gold") ? (
                                                            <Typography
                                                                as="div"
                                                                className="tabs-name-active"
                                                            >
                                                                <img
                                                                    src={goldLoyalty}
                                                                    width={55}
                                                                    alt="arrow"
                                                                />
                                                            </Typography>
                                                        ) : (
                                                            <Typography
                                                                as="div"
                                                                className="tabs-name-active"
                                                            >
                                                                <img
                                                                    src={topLoyalty}
                                                                    width={30}
                                                                    alt="arrow"
                                                                />
                                                            </Typography>
                                                        )}
                                                    </Typography>
                                                </Typography>
                                            }
                                            key={item.code}
                                        >
                                            <div className="conteiner-descrription">
                                                {shimmer ? (
                                                    <ShimmerLoyalty />
                                                ) : (
                                                    <Scrollbar
                                                        style={{height: "75vh"}}
                                                        onScroll={(value: any) => {}}
                                                    >
                                                        <div
                                                            className="loyalty-description"
                                                            dangerouslySetInnerHTML={{
                                                                __html: t(item.desc)
                                                            }}
                                                        ></div>
                                                        <Typography
                                                            as="div"
                                                            className="details-btn"
                                                            onClick={onClick}
                                                        >
                                                            Подробнее о программе лояльности
                                                            <img
                                                                src={aarrow}
                                                                width={16}
                                                                alt="arrow"
                                                            />
                                                        </Typography>
                                                        <Typography
                                                            as="div"
                                                            className="close-button"
                                                        >
                                                            <Button
                                                                type="primary"
                                                                onClick={onClose}
                                                            >
                                                                Закрыть
                                                            </Button>
                                                        </Typography>
                                                    </Scrollbar>
                                                )}
                                            </div>
                                        </Tabs.TabPane>
                                    );
                                })
                                .valueOf()}
                        </Tabs>
                    </Drawer>
                </Typography>
            )}
        </>
    );
});
