import {Col, Row, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import React from "react";
import {useTranslation} from "react-i18next";
import {RangeValue} from "rc-picker/lib/interface";

export default function TitleColapsePanelPayments({period}: IProps) {
    const {t} = useTranslation();

    return (
        <Typography as="div" className="bl-title-colapse-panel-payments">
            <Row>
                <Col span={!period ? 4 : 6}>
                    <Typography size={14} color="rgba(255, 255, 255, 0.5)">
                        {t("Дата")}
                    </Typography>
                </Col>
                <Col md={!period ? 4 : 6}>
                    <Typography size={14} color="rgba(255, 255, 255, 0.5)">
                        {t("Статус лояльности ")}
                    </Typography>
                </Col>
                <Col span={!period ? 4 : 8}>
                    <Typography size={14} color="rgba(255, 255, 255, 0.5)">
                        {" "}
                        {t("Операция")}
                    </Typography>
                </Col>
                {!period ? (
                    <Col span={5}>
                        <Typography size={14} color="rgba(255, 255, 255, 0.5)">
                            {" "}
                            {t("Статус")}
                        </Typography>
                    </Col>
                ) : null}

                <Col span={3}>
                    <Typography size={14} color="rgba(255, 255, 255, 0.5)">
                        {t("Накоплено")}
                    </Typography>
                </Col>
                {!period ? (
                    <Col span={3}>
                        <Typography size={14} color="rgba(255, 255, 255, 0.5)">
                            {t("Начисляется")}
                        </Typography>
                    </Col>
                ) : null}
            </Row>
        </Typography>
    );
}
interface IProps {
    period?: RangeValue<moment.Moment>;
}
