import {Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import {useContext} from "react";
import {LayoutContext} from "../../../../../core/Contexts";
import _ from "lodash";

export default function Block4() {
    const contextLayout = useContext(LayoutContext);

    return (
        <Typography as="div" className="bl-block4">
            {_.gt(contextLayout?.width, 768) ? (
                <>
                    <Typography as="div" className="title">
                        Торопитесь!
                    </Typography>
                    <Typography as="div" className="text">
                        Принять участие в акции могут только те клиенты, номера которых были
                        подключены в течение последних 180* дней!
                    </Typography>
                </>
            ) : (
                <Typography as="div" className="text">
                    Торопитесь! Принять участие в акции могут только те клиенты, номера которых были
                    подключены в течение последних 180* дней!
                </Typography>
            )}
            <Typography as="div" className="subtext">
                *но не ранее, чем за 7 дней до запуска акции НПС в STORE
            </Typography>
        </Typography>
    );
}
