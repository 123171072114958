import {useEffect, useMemo, useRef, useState} from "react";
import {Form} from "@bezlimit/bezlimit-ui";
import {AutoComplete, FormItemProps, Spin} from "antd";
import _ from "lodash";
import cn from "classnames";
import AddressesService from "../../../services/AddressesService";
// rules
import {Rules} from "../../forms/rules";
//core
import {IAddress} from "../../../core/models/Address";
//images
import spinnerImg from "../../../images/lions.png";
import "./style.scss";

export default function AddressField({title, props, defaultValue}: IProps) {
    const {Option} = AutoComplete;
    const [value, setValue] = useState<string | undefined>(defaultValue);
    const [options, setOptions] = useState<IAddress[]>([]);
    const [, setSelected] = useState<IAddress>({});
    const [focused, setFocused] = useState(false);

    const [fetching, setFetching] = useState(false);
    const fetchRef = useRef(0);

    const debounceFetcher = useMemo(() => {
        const loadOptions = (searchText: string) => {
            fetchRef.current += 1;
            const fetchId = fetchRef.current;
            setFetching(true);

            AddressesService.fetchAddresses({query: searchText}).then((newOptions) => {
                if (fetchId !== fetchRef.current) {
                    return;
                }
                setOptions(newOptions);
                setFetching(false);
            });
        };

        return _.debounce(loadOptions, 300);
    }, [AddressesService.fetchAddresses, 300]);

    const onSelect = (data: string) => {
        setSelected({value: data});
    };

    const onChange = (data: string) => {
        setValue(data);
    };

    useEffect(() => {
        setValue(defaultValue);
    }, [defaultValue]);

    return (
        <div className="field-address-wrapper">
            <Form.Item
                {...props}
                hasFeedback
                name="address"
                rules={[Rules.required()]}
                validateTrigger={["onSelect", "onChange"]}
            >
                <AutoComplete
                    className={cn("field-adress", "with-title", {filled: !!value})}
                    allowClear={false}
                    value={value}
                    onSelect={onSelect}
                    onSearch={debounceFetcher}
                    onChange={onChange}
                    onFocus={() => setFocused(true)}
                    onBlur={() => setFocused(false)}
                    notFoundContent={
                        fetching ? (
                            <Spin
                                className="bz-spinner"
                                indicator={<img src={spinnerImg} alt="spinner" />}
                            />
                        ) : null
                    }
                >
                    {_.chain(options)
                        .map((item) => (
                            <Option key={item.value} value={item.value}>
                                {item.value}
                            </Option>
                        ))
                        .valueOf()}
                </AutoComplete>
            </Form.Item>
            <label
                className={cn("title", {filled: !!value}, {focused: focused})}
                htmlFor={"login_address"}
            >
                {title}
            </label>
        </div>
    );
}
interface IProps extends FormItemProps<any> {
    title?: string;
    props?: FormItemProps<any>;
    defaultValue?: string;
}
