import {Col, Row, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {RangeValue} from "rc-picker/lib/interface";

export default function PaymentsTitlt({period}: IProps) {
    const {t} = useTranslation();
    return (
        <Typography as="div" className="subtitle-payments">
            <Row>
                <Col span={6}>
                    <Typography size={17} color="rgba(255, 255, 255, 0.5)">
                        {t("Дата")}
                    </Typography>
                </Col>
                <Col span={6}>
                    <Typography size={17} color="rgba(255, 255, 255, 0.5)">
                        {t("Операций")}
                    </Typography>
                </Col>
                <Col span={4}></Col>
                <Col span={8}>
                    <Typography size={17} color="rgba(255, 255, 255, 0.5)">
                        {t("Накоплено")}
                    </Typography>
                </Col>
            </Row>
        </Typography>
    );
}

interface IProps {
    period: RangeValue<moment.Moment>;
}
