import React, {useContext} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext} from "../../../core/Contexts";
import {IFilter} from "../../../core/models/Phones";
// components
import NumbersFilterForm from "../../forms/numbersFilter";

export default React.memo(function NumbersFilterModal({
    title,
    items,
    selected,
    disabledFilters,
    type,
    set,
    setShouldUpdate
}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();

    return (
        <Typography as="div" className="bl-numbers-filtre-modal">
            <Space direction="vertical" size={24}>
                <Typography
                    level={2}
                    color={_.gte(contextLayout?.width ?? 0, 768) ? "#642878" : "#000"}
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 36 : 24}
                    fontFamily={_.gte(contextLayout?.width ?? 0, 768) ? "hauss" : "alumna"}
                >
                    {t(title)}
                </Typography>
                <NumbersFilterForm
                    items={items}
                    selected={selected}
                    type={type}
                    disabledFilters={disabledFilters}
                    set={set}
                    setShouldUpdate={setShouldUpdate}
                />
            </Space>
        </Typography>
    );
});

interface IProps {
    title: string;
    selected: string[];
    items: IFilter[];
    disabledFilters?: IFilter[];
    set: React.Dispatch<React.SetStateAction<string[]>>;
    setShouldUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
    type: string;
}
