import React from "react";
import {LionProgressbar, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import "./style.scss";
// services
import BonusesService from "../../../../../services/BonusesService";

export default React.memo(
    observer(function Progress() {
        return (
            <Typography as="div" className="bl-mb-progress">
                <LionProgressbar
                    className="progress"
                    value={+BonusesService.processSumGraph ?? 0}
                    type="circle"
                    progressColor="goldTexture"
                />
            </Typography>
        );
    })
);
