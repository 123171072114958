import {Typography, Row, Col, Skeleton} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import "./style.scss";
import _ from "lodash";

export default observer(function ReservationShimmer({gridType}: IProps) {
    return (
        <div className="reservation-shimmer">
            {_.isEqual(gridType, "list") ? (
                <Typography as="div" className="list-reservation">
                    <Row className="row-group-btn-close" gutter={[8, 8]}>
                        <Col sm={6} xs={12} className="col-back">
                            <Skeleton.Button active={true} className="btn-skeleton-back" />
                        </Col>
                        <Col sm={12} xs={12} className="col-back">
                            <Skeleton.Button active={true} className="btn-skeleton-region" />
                        </Col>
                        <Col sm={6} xs={0} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-close" />
                        </Col>
                        <Col sm={6} xs={24} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-text" />
                        </Col>
                        <Col sm={12} xs={24} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-text" />
                        </Col>
                        <Col sm={0} xs={24} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-text" />
                        </Col>
                        <Col sm={0} xs={24} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-text" />
                        </Col>
                        <Col sm={6} xs={0} className="col-close"></Col>
                        <Col sm={6} xs={0} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-close" />
                        </Col>
                        <Col sm={10} xs={0} className="col-close"></Col>
                        <Col sm={0} xs={12} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-process" />
                        </Col>
                        <Col sm={4} xs={24} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-close" />
                        </Col>
                        <Col sm={4} xs={24} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-close" />
                        </Col>
                    </Row>
                </Typography>
            ) : (
                <Typography as="div" className="kanban-reservation">
                    <Row className="row-group-btn-close" gutter={[8, 8]}>
                        <Col sm={12} xs={12} className="col-back">
                            <Skeleton.Button active={true} className="btn-skeleton-back" />
                        </Col>
                        <Col sm={12} xs={12} className="col-back">
                            <Skeleton.Button active={true} className="btn-skeleton-region" />
                        </Col>
                        <Col sm={0} xs={0} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-close" />
                        </Col>
                        <Col sm={24} xs={24} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-text" />
                        </Col>
                        <Col sm={24} xs={24} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-text" />
                        </Col>
                        <Col sm={24} xs={24} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-text" />
                        </Col>
                        <Col sm={24} xs={24} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-text" />
                        </Col>
                        <Col sm={12} xs={12} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-process" />
                        </Col>
                        <Col sm={24} xs={24} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-close" />
                        </Col>
                        <Col sm={24} xs={24} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-close" />
                        </Col>
                    </Row>
                </Typography>
            )}
        </div>
    );
});

interface IProps {
    gridType?: string;
}
