import {Form, Radio} from "@bezlimit/bezlimit-ui";
import BonusesService from "../../../../services/BonusesService";

export default function RadioButtonGroup() {
    return (
        <Form.Item name="replenichSum">
            <Radio.Group optionType="button">
                <Radio.Button value="5000" disabled={BonusesService.accumulated < 5000}>
                    5000
                </Radio.Button>
                <Radio.Button value="7000" disabled={BonusesService.accumulated < 7000}>
                    7000
                </Radio.Button>
                <Radio.Button value="10000" disabled={BonusesService.accumulated < 10000}>
                    10000
                </Radio.Button>
                <Radio.Button value="25000" disabled={BonusesService.accumulated < 25000}>
                    25000
                </Radio.Button>
                <Radio.Button value="50000" disabled={BonusesService.accumulated < 50000}>
                    50000
                </Radio.Button>
            </Radio.Group>
        </Form.Item>
    );
}
