import React, {useContext} from "react";
import "./style.scss";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {Block, Col, Row, Typography, Progress, Button, Icon} from "@bezlimit/bezlimit-ui";
import cn from "classnames";
//store
import Store from "../../../../stores";
//core
import {LayoutContext} from "../../../../core/Contexts";
import HintLoyalty from "../../widgets/loyalty/hint";

export const Loyalty: React.FC<IProps> = observer(({setVisibleLoyalty}) => {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);

    const onClick = () => {
        const el = document.body.querySelector(".bl-hint_header");
        el?.classList.add("none-loyalty");
        setVisibleLoyalty(false);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const getProgressPersent = (
        code: string,
        upgrade_cnt: number,
        last_month_cnt: number
    ): number => {
        if (code === "top" || _.gte(last_month_cnt, 500)) {
            return 100;
        }
        if (!upgrade_cnt) {
            return 0;
        }
        return (100 * last_month_cnt) / (last_month_cnt + upgrade_cnt);
    };

    return (
        <Block className="bl-loyalty">
            <Row gutter={[32, 0]}>
                <Col lg={15} md={15} xs={24}>
                    {_.isNil(Store.user.loyalty) ? (
                        <>
                            <Typography className="loyalty-code">{t("Персональный")}</Typography>
                        </>
                    ) : (
                        <>
                            <Typography className="subtitle">
                                {t("Программа лояльности")}
                            </Typography>
                            <Typography
                                className={cn("loyalty-code", `${Store.user.loyalty?.code ?? ""}`)}
                            >
                                {Store.user.loyalty?.name}
                            </Typography>
                            {Store.user.loyalty?.code !== "top" ? (
                                <div className="loyalty-status">
                                    <Typography className="subtitle">
                                        {t("До следующего статуса")}
                                    </Typography>
                                    <Typography className="loyalty-status-count">
                                        {Store.user.loyalty?.scores?.upgrade_cnt ?? 0}
                                    </Typography>
                                </div>
                            ) : (
                                <Typography className="subtitle">
                                    {t("Вы достигли максимального уровня")}
                                </Typography>
                            )}
                        </>
                    )}

                    <Progress
                        className="loaylty-progress-line"
                        percent={getProgressPersent(
                            Store.user.loyalty?.code,
                            Store.user.loyalty?.scores?.upgrade_cnt,
                            Store.user.loyalty?.scores?.last_month_total_cnt
                        )}
                        showInfo={false}
                    />
                </Col>
                {_.gte(contextLayout?.width ?? 0, 768) ? (
                    <Typography as="div" className="hint-icon">
                        <HintLoyalty />
                    </Typography>
                ) : (
                    !_.isNil(Store.user.loyalty) && (
                        <Typography
                            as="div"
                            className="bl-hint_header bl-hint_header__move-shake btn-hint-mobile"
                        >
                            <Button
                                onClick={onClick}
                                icon={<Icon className="bl-hint_header__icon" name="hint_header" />}
                                className="btn-default"
                            />
                        </Typography>
                    )
                )}

                <Col lg={9} md={9} xs={24}>
                    <div className="activation">
                        <div className="activation-row">
                            {_.gte(contextLayout?.width, 991) ?? 0 ? (
                                <Typography as="div" className="last-month-total">
                                    {t("Баллов ")}
                                    {t("за последние 30 дней")}{" "}
                                    <Typography className="activation-cnt gold">
                                        {Store.user.loyalty?.scores?.last_month_total_cnt ?? 0}
                                    </Typography>
                                </Typography>
                            ) : (
                                <Typography as="div" className="last-month-total">
                                    {t("Баллов за последние 30 дней")}{" "}
                                    <Typography className="activation-cnt gold">
                                        {Store.user.loyalty?.scores?.last_month_total_cnt ?? 0}
                                    </Typography>
                                </Typography>
                            )}
                        </div>

                        {Store.user.level?.level < 2 && (
                            <Row>
                                <Col sm={12} xs={9}>
                                    <Typography as="div" className="bl-last-month">
                                        {t("Личные")}{" "}
                                        <Typography className="activation-cnt gold">
                                            {Store.user.loyalty?.scores?.last_month_personal_cnt ??
                                                0}
                                        </Typography>
                                    </Typography>
                                </Col>
                                <Col
                                    sm={12}
                                    xs={7}
                                    order={_.gte(contextLayout?.width, 431) ? 1 : 0}
                                >
                                    <Typography as="div" className="bl-last-month">
                                        {t("НПС")}{" "}
                                        <Typography className="activation-cnt gold">
                                            {Store.user.loyalty?.scores?.last_month_nps_cnt ?? 0}
                                        </Typography>
                                    </Typography>
                                </Col>
                                <Col sm={12} xs={8}>
                                    <Typography as="div" className="bl-last-month">
                                        {t("Дилерские")}{" "}
                                        <Typography className="activation-cnt gold">
                                            {Store.user.loyalty?.scores?.last_month_level_cnt ?? 0}
                                        </Typography>
                                    </Typography>
                                </Col>
                            </Row>
                        )}
                    </div>
                </Col>
            </Row>
        </Block>
    );
});

interface IProps {
    setVisibleLoyalty: React.Dispatch<React.SetStateAction<boolean>>;
}
