import React, {useContext} from "react";
import {Button, Col, Row, Space, Title, Typography, Back} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import {useEffect} from "react";
import {useTranslation} from "react-i18next";
//service
import NotificationsService from "../../../../services/NewsService";
//components
import BlContent from "./blContent";
//core
import {InterceptorContext} from "../../../../core/Contexts";
// hooks
import useSearchRoute from "../../../../core/hooks/useSearchRoute";
// services
import UserService from "../../../../services/UserService";

export default observer(function NewsPage() {
    const context = useContext(InterceptorContext);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const route = useSearchRoute(UserService.token);

    const onClick = async () => {
        await NotificationsService.loadMoreNews({page: NotificationsService.currentPage + 1});
    };

    useEffect(() => {
        NotificationsService.fetchNews();
    }, []);

    return (
        <Typography as="div" className="bl-news">
            <Row>
                <Back navigate={() => navigate("/")} />
                <Col span={24}>
                    <Title title={_.get(route, "title")} />
                </Col>
                <Space direction="vertical" className="list-news-block" size={16}>
                    {_.chain(NotificationsService.items)
                        .map(
                            (
                                {id, preview_text, published_date, title, preview_image, is_read},
                                key
                            ) => (
                                <React.Fragment key={key}>
                                    <Col span={24}>
                                        <BlContent
                                            published_date={published_date}
                                            title={title}
                                            preview_text={preview_text}
                                            id={id}
                                            preview_image={preview_image}
                                            is_read={is_read}
                                        />
                                    </Col>
                                </React.Fragment>
                            )
                        )
                        .valueOf()}
                </Space>
                <Col span={24}>
                    <Typography as="div" className="btn-show">
                        <Button
                            className="btn-show-more"
                            onClick={onClick}
                            disabled={!NotificationsService.hasItems}
                            loading={context?.loadingBtn}
                        >
                            {t("Показать еще")}
                        </Button>
                    </Typography>
                </Col>
            </Row>
        </Typography>
    );
});
