import {Col, Row, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import React from "react";
import {useTranslation} from "react-i18next";

export default function TitleColapsePanelPayouts() {
    const {t} = useTranslation();
    return (
        <Typography as="div" className="bl-title-colapse-panel-payouts">
            <Row>
                <Col span={4}>
                    <Typography size={14} color="rgba(255, 255, 255, 0.5)">
                        {t("Дата")}
                    </Typography>
                </Col>
                <Col span={6}>
                    <Typography size={14} color="rgba(255, 255, 255, 0.5)">
                        {t("Действие")}
                    </Typography>
                </Col>
                <Col span={6}>
                    <Typography size={14} color="rgba(255, 255, 255, 0.5)">
                        {t("Операция")}
                    </Typography>
                </Col>
                <Col span={4}>
                    <Typography size={14} color="rgba(255, 255, 255, 0.5)">
                        {t("Статус")}
                    </Typography>
                </Col>
                <Col span={4}>
                    <Typography size={14} color="rgba(255, 255, 255, 0.5)">
                        {t("Списание")}
                    </Typography>
                </Col>
            </Row>
        </Typography>
    );
}
