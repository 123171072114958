import {useTranslation} from "react-i18next";
import {Form, Radio, Typography} from "@bezlimit/bezlimit-ui";
import {FormItemProps} from "antd";
import _ from "lodash";
// core
import {IFilter} from "../../../core/models/Phones";

export default function RadioField({name, items, props}: IProps) {
    const {t} = useTranslation();

    return (
        <Form.Item {...props} className="field-radio" name={name}>
            <Radio.Group>
                {_.chain(items)
                    .map(({name: label, filter: value}) => (
                        <Radio key={value} value={value}>
                            <Typography as="div" size={17} color="#000">
                                {t(label)}
                            </Typography>
                        </Radio>
                    ))
                    .valueOf()}
            </Radio.Group>
        </Form.Item>
    );
}

interface IProps {
    name: string;
    items: IFilter[];
    props?: FormItemProps<any>;
}
