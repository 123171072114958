import {useContext, useState} from "react";
import {Form, Space, Typography, Tabs, Radio, Row, Col, Button} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {FormItemProps} from "antd";
import {observer} from "mobx-react";
import _ from "lodash";
import cn from "classnames";
import "./style.scss";
// core
import {LayoutContext} from "../../../core/Contexts";
import {ITariffsAvailable} from "../../../core/models/Tariffs";
// components
import Limits from "../../blocks/numbers/list/default/info/description/limits";

export default observer(function TariffField({props, tariffs}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();

    const perPageInitial: {[key: string]: number} = {};
    const groupedTarrifs = _.chain(tariffs).groupBy("group_name").valueOf();
    for (const prop in groupedTarrifs) {
        perPageInitial[prop] = 4;
    }
    const [perPageObj, setPerPageObj] = useState(perPageInitial);

    const onClick = (key: string) => {
        const newPerPageObj = {...perPageObj};
        newPerPageObj[key] = newPerPageObj[key] + 4;
        setPerPageObj(newPerPageObj);
    };

    const isNotMobile =_.gte(contextLayout?.width ?? 0, 768);

    return (
        <Typography as="div" className="bl-field-tariff">
            <Space direction="vertical" size={isNotMobile ? 43 : 32}>
                <Typography as="div" color="#fff" size={24} className="header">
                    {t("Доступные тарифы")}
                </Typography>
                <Typography as="div" className="content">
                    <Form.Item {...props} className="field-tariffs" name="tariffs">
                        <Radio.Group>
                            <Tabs
                                className="tabs-container"
                                tabPosition="top"
                                animated={true}
                                tabPanes={_.chain(tariffs)
                                    .groupBy("group_name")
                                    .map((i, k) => {
                                        const tariffList = _.chain(i)
                                            .filter((i, ind) => isNotMobile || _.lt(ind, perPageObj[k]))
                                            .map(({id, name, internet, minutes, sms}) => (
                                                <Radio key={id} value={id}>
                                                    <Row
                                                        gutter={[
                                                            16,
                                                            _.gte(
                                                                contextLayout?.width ?? 0,
                                                                768
                                                            )
                                                                ? 16
                                                                : 0
                                                        ]}
                                                    >
                                                        <Col xs={24} md={12}>
                                                            <Typography
                                                                as="div"
                                                                color="#fff"
                                                                size={17}
                                                            >
                                                                {name}
                                                            </Typography>
                                                        </Col>
                                                        <Col xs={24} md={12} className="left">
                                                            <Limits
                                                                minutes={minutes}
                                                                internet={internet}
                                                                sms={sms}
                                                            />
                                                        </Col>
                                                    </Row>
                                                </Radio>
                                            ))
                                            .valueOf();
                                        const contentMobile = (
                                            <>
                                                {tariffList}
                                                <Typography as="div" className="btn-show">
                                                    <Button
                                                        block
                                                        className={cn("btn-show-more", {"hidden": _.gte(perPageObj[k], _.size(i))})}
                                                        onClick={() => onClick(k)}
                                                    >
                                                        {t("Показать еще")}
                                                    </Button>
                                                </Typography>
                                            </>);
                                        return {
                                            title: _.get(i, "[0].group_name"),
                                            key: k,
                                            content: isNotMobile ? tariffList : contentMobile
                                        };
                                    })
                                    .valueOf()}
                            />
                        </Radio.Group>
                    </Form.Item>
                </Typography>
            </Space>
        </Typography>
    );
});

interface IProps {
    props?: FormItemProps<any>;
    tariffs: ITariffsAvailable[];
}
