import React, {useContext} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";

// forms
import EditPasswordForm from "../../forms/editPassword";
// core
import {LayoutContext} from "../../../core/Contexts";

export default React.memo(
    observer(function EditPasswordModal({email}: Props) {
        const contextLayout = useContext(LayoutContext);
        const {t} = useTranslation();

        return (
            <Typography as="div" className="bl-edit-password-modal">
                <Space
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 40 : 24}
                    direction="vertical"
                    align="center"
                >
                    <Typography as="div">
                        <Space size={8} direction="vertical" align="center">
                            <Typography
                                size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                                level={2}
                                color="#000000"
                            >
                                {t("Сменить пароль")}
                            </Typography>
                            <Typography color="#000000" className="subtitle">
                                {t("Новый пароль будет действовать как для кабинета ")}
                                <br />
                                {t("на сайте, так и для моб. приложения")}
                            </Typography>
                        </Space>
                    </Typography>
                    <EditPasswordForm email={email}/>
                </Space>
            </Typography>
        );
    })
);

interface Props {
    email: string;
}
