import React, {useContext} from "react";
import {t} from "i18next";
import {Block, Col, Typography, Row} from "@bezlimit/bezlimit-ui";
import SupportFaqCarusel from "../../../modal/support";
// core
import {ModalContext} from "../../../../core/Contexts";
import "../style.scss";

export interface ISupportFaq {
    id: number;
    title: string;
    description: string;
}
export interface ISupportFaqList {
    list: ISupportFaq[];
}

export const SupportFaqList = ({list}: ISupportFaqList): JSX.Element => {
    const contextModal = useContext(ModalContext);

    const onClick = (id: number) => {
        contextModal?.setVisible(true);
        contextModal?.setId(id);
        contextModal?.setChildren(<SupportFaqCarusel list={list} />);
    };

    return (
        <Row gutter={[16, 16]}>
            {list.map((item: ISupportFaq) => (
                <Col xs={24} md={8} key={item.id}>
                    <Block className="support-list-item" onClick={() => onClick(item.id - 1)}>
                        <div className="support-list-item-container">
                            <Typography className="support-list-item-title">
                                {t(item.title)}
                            </Typography>
                            <Typography className="support-list-item-more">
                                {t("Подробнее")} &nbsp; →
                            </Typography>
                        </div>
                    </Block>
                </Col>
            ))}
        </Row>
    );
};
