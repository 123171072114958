import {Form, Radio} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import BonusesService from "../../../../services/BonusesService";

export default function RadioButtonGroup() {
    const {t} = useTranslation();
    return (
        <Form.Item name="replenichSum">
            <Radio.Group optionType="button">
                <Radio.Button value="100" disabled={BonusesService.accumulated < 100}>
                    100 {t("Б")}
                </Radio.Button>
                <Radio.Button value="200" disabled={BonusesService.accumulated < 200}>
                    200 {t("Б")}
                </Radio.Button>
                <Radio.Button value="300" disabled={BonusesService.accumulated < 300}>
                    300 {t("Б")}
                </Radio.Button>
                <Radio.Button value="500" disabled={BonusesService.accumulated < 500}>
                    500 {t("Б")}
                </Radio.Button>
                <Radio.Button value="5000" disabled={BonusesService.accumulated < 5000}>
                    5000 {t("Б")}
                </Radio.Button>
            </Radio.Group>
        </Form.Item>
    );
}
