import {Block, Button, Col, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {observer} from "mobx-react";
import {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
// core
import {LayoutContext} from "../../../../../core/Contexts";
import date from "../../../../../core/functions/date";

export default observer(function BlNews({
    id,
    published_date,
    title,
    preview_text,
    preview_image
}: IProps) {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);

    const dateFormat: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
    };

    return (
        <>
            <Block className="preview-image">
                <div
                    className="bl-list-news-page-fallback-images preview-image-bl-list-news-page-wrapper-images"
                ></div>
                <div
                    className="bl-list-news-page-wrapper-images preview-image-bl-list-news-page-wrapper-images"
                    style={{
                        backgroundImage: `url(${preview_image})`
                    }}
                ></div>
                <Space
                    size={_.gte(contextLayout?.width, 768) ?? 0 ? 22 : 11}
                    direction="vertical"
                    className="carousel-content"
                >
                    <Typography as="div">
                        <Row>
                            <Col span={8}>
                                <Typography className="static-tag">{t("Новость")}</Typography>
                            </Col>
                            <Col span={16}>
                                <Typography className="published-date">
                                    {date(published_date, dateFormat)}
                                </Typography>
                            </Col>
                        </Row>
                        <Space direction="vertical" size={32}>
                            <Typography as="div" className="bl-modal-content">
                                <Space direction="vertical" size={8}>
                                    <Typography className="title" size={17} appearance="bold">
                                        {t(title)}
                                    </Typography>
                                    <Typography className="preview-text" color="#FFF">
                                        {preview_text}
                                    </Typography>
                                </Space>
                            </Typography>
                        </Space>
                    </Typography>
                </Space>
            </Block>
            <Button type="default">
                <Link to={`/news/${id}`} className="table-title-button">
                    {t("Смотреть новость")}
                </Link>
            </Button>
        </>
    );
});

interface IProps {
    preview_image: string;
    published_date: string;
    title: string;
    preview_text: string;
    id: number;
}
