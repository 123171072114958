import {Button, Dropdown, Typography} from "@bezlimit/bezlimit-ui";
// import {useTranslation} from "react-i18next";
import {MenuProps} from "antd";
import {useContext} from "react";
import {LayoutContext, ModalContext} from "../../../core/Contexts";
import _ from "lodash";
import ModalRules from "./modalRules";

export const LotteryRules = ({placeholder, items}: IProps): JSX.Element => {
    const contextLayout = useContext(LayoutContext);
    const contextModal = useContext(ModalContext);
    const isMobile = _.lt(contextLayout?.width ?? 0, 993);

    // const {t} = useTranslation();
    // const {Panel} = Collapse;
    // const createMarkup = (description: string) => {
    //     return {__html: description};
    // };

    const onClick = () => {
        contextModal?.setMaskStyle({
            background: "rgba(255, 255, 255, 0.05)",
            // @ts-ignore
            "-webkit-backdrop-filter": " blur(50px)",
            "backdrop-filter": " blur(50px)"
        });
        contextModal?.setCssClass(" bl-modal-lottery");
        contextModal?.setChildren(
            <ModalRules placeholder={placeholder} text={items ? (items as any)[0].label : ""} />
        );
        contextModal?.setVisible(true);
    };

    return (
        <Typography as="div" id="rules" className="custom-overlay-container">
            <Dropdown
                menu={{items}}
                trigger={["click"]}
                overlayClassName="custom-overlay"
                getPopupContainer={() => document.getElementById("rules")!}
                open={isMobile ? false : undefined}
                onOpenChange={isMobile ? onClick : undefined}
            >
                <Button>
                    {placeholder}
                    {isMobile ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                        >
                            <path
                                d="M13 6L8 11L3 6"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    ) : (
                        <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 6.5L10 14.5L19 6.5"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    )}
                </Button>
            </Dropdown>
        </Typography>
        // <Collapse
        //     ghost
        //     className="lottery-collapse"
        //     expandIconPosition="end"
        //     expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
        // >
        //     <Panel header={t("Подробные условия проведения")} key="1">
        //         <div className="rules-description">
        //             <div
        //                 dangerouslySetInnerHTML={createMarkup(description)}
        //             ></div>
        //         </div>
        //     </Panel>
        // </Collapse>
    );
};

interface IProps {
    placeholder: string;
    items: MenuProps["items"];
}
