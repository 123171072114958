import React, {useContext, useState, useEffect} from "react";
import {observer} from "mobx-react";
import _ from "lodash";
import {Block, Button, Col, Typography, Row} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import moment from "moment";
//service
import BonusService from "../../../../services/BonusService";
//components
import ListPayouts from "./payouts";
//core
import { InterceptorContext } from "../../../../core/Contexts";

import "./style.scss";

export default observer(function PayoytsHistory({Service}: IProps) {
    const {t} = useTranslation();
    const [perPage, setPerPage] = useState(3);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const context = useContext(InterceptorContext);
    const navigate = useNavigate();
    const date30DaysBefore = moment().add(-30, 'days');

    const onClick = () => {
        setPerPage(perPage + 6);
    };

    useEffect(() => {
        const btnDisabledNew =_.lt(
            _.size(
                _.chain(Service.items)
                .filter((i) => moment(i.created_at).isAfter(date30DaysBefore))
            ), perPage);
        setBtnDisabled(btnDisabledNew);
    }, [Service.items?.length, perPage])

    return (
        <Block className="bl-payouts-history">
            <Row gutter={[16, 16]}>
                <Col>
                    <Typography className="payouts-title">{t("История списаний")}</Typography>
                    <Typography className="payouts-subtitle">{t("Все списания за последние 30 дней")}</Typography>
                </Col>
                <Col span={24}>
                    <Row>
                        <Col lg={20} md={20} xs={0}></Col>
                        <Col lg={4} md={4} xs={0}>
                            <Typography className="payouts-col-title">{t("Списания")}</Typography>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row>
                        {_.chain(Service.items)
                            .filter((i) => moment(i.created_at).isAfter(date30DaysBefore))
                            .filter((i, key) => _.lt(key, perPage))
                            .map(
                                (item,key) => (
                                    <React.Fragment key={key}>
                                        <Col span={24}>
                                            <ListPayouts
                                                created_at={item.created_at}
                                                type={item.type}
                                                card={item.card}
                                                phone={item.phone}
                                                status={item.status}
                                                sum={item.sum}
                                            />
                                        </Col>
                                    </React.Fragment>
                                )
                            )
                            .valueOf()}
                    </Row>
                </Col>
                <Col lg={12} xs={24}>
                    <Typography as="div" className="btn-show">
                        <Button
                            block
                            className="btn-show-more"
                            loading={context?.loadingBtn}
                            disabled={btnDisabled}
                            onClick={onClick}
                        >
                            {t("Показать еще")}
                        </Button>
                    </Typography>
                </Col>
                <Col lg={12} xs={24}>
                    <Typography as="div">
                        <Button
                            type="primary"
                            block
                            onClick={() => navigate("/history")}
                        >
                            {t("История операций")}
                        </Button>
                    </Typography>
                </Col>
            </Row>
        </Block>
    );
});

interface IProps {
    Service: BonusService;
}