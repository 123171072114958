import React, {useEffect, useRef, useState, useContext} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {Button, Col, Row, Typography, Title} from "@bezlimit/bezlimit-ui";
import {Table, ConfigProvider} from "antd";
import _ from "lodash";
import moment from "moment";
//component
import {LotteryRules} from "./rules";
import {LotteryAwards} from "./awards";
import LotteryTimer from "./timer";
import {SummaryBlock} from "./summary";
import LotteryEmptyResult from "./empty";
//service
import LotteryService from "../../../services/LotteryService";
import LotteryPhonesService from "../../../services/LotteryPhonesService";
// store
import Store from "../../../stores";
//core
import {LayoutContext} from "../../../core/Contexts";
// images
import awardImgW1x from "../../../images/award@1x.webp";
import awardImgW2x from "../../../images/award@2x.webp";
import awardImg from "../../../images/award@1x.png";
import LotterySkeleton from "./skeleton";

import "./style.scss";

import "moment/locale/ru";
import Search from "./search";
moment.locale("ru");

const getBalls = (code: string | undefined): JSX.Element => {
    if (!code) {
        return <p>{"Код розыгрыша: - "}</p>;
    }
    const balls = (
        <>
            {(code.split(".") || []).map((item, ind) => {
                return <div className={`balls-${ind % 3}`}>{item}</div>;
            })}
        </>
    );
    return (
        <div className="bl-balls">
            <p>{"Код розыгрыша:"}</p>
            <Typography as="div" className="bl-balls-wrapper">
                {balls}
            </Typography>
        </div>
    );
};

export const Lottery: React.FC = observer(() => {
    const {t} = useTranslation();
    const timeout: any = useRef();
    const navigate = useNavigate();
    const [timerRun, setTimerRun] = useState<boolean | undefined>(undefined);
    const [loading, setLoading] = useState(false);
    const [isSearch, setIsSearch] = useState(false);
    const [loadingSkeleton, setLoadingSkeleton] = useState(false);
    const [data, setData] = useState<ILotteryPhoneView[]>([]);
    const contextLayout = useContext(LayoutContext);
    const {Column} = Table;
    const [clicked, setClicked] = useState(false);

    useEffect(() => {
        LotteryService.getLotteryInfo();
        LotteryService.getSummary();
        LotteryPhonesService.fetchlotteryPhones();
    }, []);

    const summary = Store.lottery?.summary;
    const started = Store.lottery?.lottery?.started_at;
    const startedStr = started ? moment(started).format("LLL") : "";
    const link = Store.lottery?.lottery?.link;
    const phones = Store.lotteryPhones?.items ?? [];
    const noPassport = (Store.user?.personal_data?.passport_status ?? "") !== "confirmed";
    const isMobile = _.lt(contextLayout?.width ?? 0, 993);

    const MSK_UTC_OFFSET = 180;

    useEffect(() => {
        if (!_.isNil(started)) {
            clearTimeout(timeout.current);
            const interval = 1000;
            const eventTime = moment(started);
            const currentTime = moment();
            const localUtcOffset = currentTime.utcOffset();
            const utcOffsetDiff = localUtcOffset - MSK_UTC_OFFSET;
            const eventTimeUnix = eventTime.add(utcOffsetDiff, "minutes").unix(); // переводим в локальное
            const currentTimeUnix = currentTime.unix();
            const diffTime = eventTimeUnix - currentTimeUnix;
            let duration: any = moment.duration(diffTime * 1000, "milliseconds");

            timeout.current = setTimeout(function () {
                duration = moment.duration(duration - interval, "milliseconds");
                if (_.gt(duration.asMilliseconds(), 0)) {
                    !timerRun && setTimerRun(true);
                } else {
                    clearTimeout(timeout.current);
                    timerRun && setTimerRun(false);
                }
            }, interval);
        }
        return () => {
            clearTimeout(timeout.current);
        };
    }, [timerRun, started]);

    useEffect(() => {
        let newData = (phones || []).map((item, ind) => {
            return {
                id: ind,
                phone: item.phone,
                phone_mob: item.phone,
                activated_at: moment(item.activated_at).format("DD.MM.YYYY"),
                code: getBalls(item.code),
                activated_code: {
                    activated_at: moment(item.activated_at).format("DD.MM.YYYY"),
                    code: getBalls(item.code)
                }
            } as ILotteryPhoneView;
        });
        if (!clicked) {
            newData = newData.slice(0, 6);
        }
        setData(newData);
    }, [phones, clicked]);

    const onClick = async () => {
        setClicked(true);
        if (data.length < 25) {
            return;
        }
        setLoading(true);
        try {
            const options = {
                page: LotteryPhonesService.currentPage + 1
            };

            await LotteryPhonesService.loadMorelotteryPhones(options);
        } finally {
            setLoading(false);
        }
    };

    const getTable = (isMobile: boolean) => {
        if (!isMobile) {
            return (
                <Table
                    className="lottery-table"
                    dataSource={data}
                    pagination={false}
                    rowKey={(record) => record.id}
                >
                    <Column
                        dataIndex="phone"
                        key="phone"
                        className={`col-phone ${noPassport ? "inActive" : "gold"}`}
                    />
                    <Column dataIndex="activated_at" key="activated_at" className="col-activated" />
                    <Column dataIndex="code" key="code" className="col-code" />
                </Table>
            );
        }

        return (
            <Table
                className="lottery-table"
                dataSource={data}
                pagination={false}
                rowKey={(record) => record.id}
            >
                <Column
                    dataIndex="phone_mob"
                    key="phone_mob"
                    className={`col-phone ${noPassport ? "inActive" : "gold"}`}
                />
                <Column
                    dataIndex="activated_code"
                    key="activated_code"
                    className="col-activated-code"
                    render={(activated_code: ActivateCodeView) => (
                        <>
                            <Typography className="col-activated">
                                {activated_code.activated_at}
                            </Typography>
                            <Typography className="col-code">{activated_code.code}</Typography>
                        </>
                    )}
                />
            </Table>
        );
    };

    return (
        <div className="bl-lottery-page">
            <Title title="Розыгрыш" className="gold" />
            <Typography as="div" className="lottery-container">
                <Typography as="div" className="lottery-text-container">
                    <Typography as="div" className="text">
                        {Store.lottery?.lottery?.name
                            ? Store.lottery?.lottery?.name
                                  .toLocaleLowerCase()
                                  .charAt(0)
                                  .toUpperCase() +
                              Store.lottery?.lottery?.name.toLocaleLowerCase().slice(1)
                            : ""}
                    </Typography>
                </Typography>

                {!isMobile ? (
                    <>
                        {!link ? (
                            <>
                                <Typography as="div" className="lottery-timer-container">
                                    {!(!timerRun && link) && (
                                        <picture>
                                            <source
                                                type="image/webp"
                                                srcSet={`${awardImgW1x} 1x, ${awardImgW2x} 2x`}
                                            />
                                            <img
                                                src={awardImg}
                                                width={340}
                                                srcSet={`${awardImg} 2x`}
                                                alt={"award"}
                                            />
                                        </picture>
                                    )}

                                    <Typography as="div" className="lottery-rules-container">
                                        <LotteryRules
                                            placeholder="Подробные условия проведения"
                                            items={[
                                                {
                                                    label: (
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html:
                                                                    Store.lottery?.lottery
                                                                        ?.description ?? ""
                                                            }}
                                                        ></div>
                                                    ),
                                                    key: 0
                                                }
                                            ]}
                                        />
                                        <LotteryAwards
                                            placeholder="Список призов"
                                            awards={Store.lottery?.lottery?.awards ?? []}
                                        />
                                    </Typography>
                                </Typography>
                                <Typography as="div" className="lottery-countdown-container">
                                    <Typography as="div" className="countdown">
                                        {timerRun && started && (
                                            <div className="lottery-timer-timer">
                                                <LotteryTimer
                                                    started_at={started}
                                                    suffix={true}
                                                    startedStr={startedStr}
                                                />
                                            </div>
                                        )}
                                        {timerRun === false && !link && (
                                            <div className="lottery-timer-timer timer-container mt-6">
                                                <Typography className="lottery-timer-text gold">
                                                    {t("Скоро")}
                                                </Typography>
                                            </div>
                                        )}
                                        {timerRun === undefined && <></>}
                                    </Typography>
                                    <Typography as="div" className="summury">
                                        {summary && _.isNil(summary.level_cnt) && (
                                            <SummaryBlock
                                                title={t("Шансы")}
                                                value={summary.personal_cnt}
                                            />
                                        )}
                                        {summary && !_.isNil(summary.level_cnt) && (
                                            <>
                                                <SummaryBlock
                                                    title={t("Личные шансы")}
                                                    value={summary.personal_cnt}
                                                />
                                                <SummaryBlock
                                                    title={t("Шансы субдилеров")}
                                                    value={summary.level_cnt}
                                                />
                                            </>
                                        )}
                                    </Typography>
                                </Typography>
                                {_.find(phones ?? [], ({code}) => !_.isNil(code) && !!code) ||
                                (!phones.length && isSearch) ? (
                                    <Search
                                        setLoading={setLoadingSkeleton}
                                        setIsSearch={setIsSearch}
                                    />
                                ) : null}
                            </>
                        ) : (
                            <Row gutter={[24, 24]}>
                                <Col xs={24} lg={12}>
                                    <div className="lottery-video">
                                        <iframe
                                            className="lottery-video"
                                            width="786"
                                            height="316"
                                            title="bezlimit розыгрыш"
                                            frameBorder="0"
                                            src={link.replace("watch?v=", "embed/")}
                                        ></iframe>
                                    </div>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Typography as="div" className="lottery-timer-container mb-36">
                                        <Typography as="div" className="lottery-rules-container">
                                            <LotteryRules
                                                placeholder="Подробные условия проведения"
                                                items={[
                                                    {
                                                        label: (
                                                            <div
                                                                dangerouslySetInnerHTML={{
                                                                    __html:
                                                                        Store.lottery?.lottery
                                                                            ?.description ?? ""
                                                                }}
                                                            ></div>
                                                        ),
                                                        key: 0
                                                    }
                                                ]}
                                            />
                                            <LotteryAwards
                                                placeholder="Список призов"
                                                awards={Store.lottery?.lottery?.awards ?? []}
                                            />
                                        </Typography>
                                    </Typography>
                                    <Typography
                                        as="div"
                                        className="lottery-countdown-container mt-0"
                                    >
                                        <Typography as="div" className="summury">
                                            {summary && _.isNil(summary.level_cnt) && (
                                                <SummaryBlock
                                                    title={t("Шансы")}
                                                    value={summary.personal_cnt}
                                                />
                                            )}
                                            {summary && !_.isNil(summary.level_cnt) && (
                                                <>
                                                    <SummaryBlock
                                                        title={t("Личные шансы")}
                                                        value={summary.personal_cnt}
                                                    />
                                                    <SummaryBlock
                                                        title={t("Шансы субдилеров")}
                                                        value={summary.level_cnt}
                                                    />
                                                </>
                                            )}
                                        </Typography>
                                    </Typography>
                                </Col>
                                {_.find(phones ?? [], ({code}) => !_.isNil(code) && !!code) ||
                                (!phones.length && isSearch) ? (
                                    <Col xs={24}>
                                        <Search
                                            setLoading={setLoadingSkeleton}
                                            setIsSearch={setIsSearch}
                                        />
                                    </Col>
                                ) : null}
                            </Row>
                        )}

                        {noPassport && (
                            <Typography as="div" className="lottery-table-container">
                                <Row gutter={[24, 24]}>
                                    <Col md={14} xs={24}>
                                        <Typography className="lottery-text-register">
                                            {t(
                                                "Для участия в конкурсе пройдите\n полную регистрацию паспортных данных"
                                            )}
                                        </Typography>
                                    </Col>
                                    <Col className="lottery-register" md={10} xs={24}>
                                        <Button
                                            className="lottery-btn-register"
                                            type="primary"
                                            onClick={() => navigate("/profile")}
                                        >
                                            {t("Зарегистрироваться")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Typography>
                        )}
                        <Typography as="div" className="lottery-result-container">
                            {_.size(phones) ? (
                                <>
                                    {loadingSkeleton ? (
                                        <LotterySkeleton />
                                    ) : (
                                        <Typography as="div">
                                            <ConfigProvider>
                                                {getTable(isMobile)}
                                                <div className="lottery-table-btn">
                                                    <Button
                                                        type="default"
                                                        onClick={onClick}
                                                        loading={loading}
                                                        disabled={
                                                            data.length >=
                                                            LotteryPhonesService.totalCount
                                                        }
                                                    >
                                                        {t("Показать еще")}
                                                    </Button>
                                                </div>
                                            </ConfigProvider>
                                        </Typography>
                                    )}
                                </>
                            ) : (
                                <LotteryEmptyResult isSearch={isSearch} />
                            )}
                        </Typography>
                    </>
                ) : (
                    <>
                        {!link ? (
                            <>
                                <Row gutter={[8, 8]}>
                                    <Col xs={24}>
                                        <Typography as="div" className="lottery-timer-container">
                                            {!(!timerRun && link) && (
                                                <picture>
                                                    <source
                                                        type="image/webp"
                                                        srcSet={`${awardImgW1x} 1x, ${awardImgW2x} 2x`}
                                                    />
                                                    <img
                                                        src={awardImg}
                                                        width={224}
                                                        srcSet={`${awardImg} 2x`}
                                                        alt={"award"}
                                                    />
                                                </picture>
                                            )}
                                        </Typography>
                                    </Col>
                                    <Col xs={24}>
                                        <Typography
                                            as="div"
                                            className="lottery-countdown-container"
                                        >
                                            <Typography as="div" className="countdown">
                                                {timerRun && started && (
                                                    <div className="lottery-timer-timer">
                                                        <LotteryTimer
                                                            started_at={started}
                                                            suffix={true}
                                                            startedStr={startedStr}
                                                        />
                                                    </div>
                                                )}
                                                {timerRun === false && !link && (
                                                    <div className="lottery-timer-timer timer-container mt-6">
                                                        <Typography className="lottery-timer-text gold">
                                                            {t("Скоро")}
                                                        </Typography>
                                                    </div>
                                                )}
                                                {timerRun === undefined && <></>}
                                            </Typography>
                                        </Typography>
                                    </Col>
                                    <Col xs={24}>
                                        <Typography as="div" className="lottery-timer-container">
                                            <Typography
                                                as="div"
                                                className="lottery-rules-container"
                                            >
                                                <LotteryRules
                                                    placeholder="Подробные условия проведения"
                                                    items={[
                                                        {
                                                            label: (
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            Store.lottery?.lottery
                                                                                ?.description ?? ""
                                                                    }}
                                                                ></div>
                                                            ),
                                                            key: 0
                                                        }
                                                    ]}
                                                />
                                                <LotteryAwards
                                                    placeholder="Список призов"
                                                    awards={Store.lottery?.lottery?.awards ?? []}
                                                />
                                            </Typography>
                                        </Typography>
                                    </Col>
                                    <Col xs={24}>
                                        <Typography
                                            as="div"
                                            className="lottery-countdown-container mt-10"
                                        >
                                            <Typography as="div" className="summury">
                                                {summary && _.isNil(summary.level_cnt) && (
                                                    <SummaryBlock
                                                        title={t("Шансы")}
                                                        value={summary.personal_cnt}
                                                    />
                                                )}
                                                {summary && !_.isNil(summary.level_cnt) && (
                                                    <>
                                                        <SummaryBlock
                                                            title={t("Личные шансы")}
                                                            value={summary.personal_cnt}
                                                        />
                                                        <SummaryBlock
                                                            title={t("Шансы субдилеров")}
                                                            value={summary.level_cnt}
                                                        />
                                                    </>
                                                )}
                                            </Typography>
                                        </Typography>
                                    </Col>
                                </Row>
                                {_.find(phones ?? [], ({code}) => !_.isNil(code) && !!code) ||
                                (!phones.length && isSearch) ? (
                                    <Search
                                        setLoading={setLoadingSkeleton}
                                        setIsSearch={setIsSearch}
                                    />
                                ) : null}
                            </>
                        ) : (
                            <>
                                <Row gutter={[8, 8]}>
                                    <Col xs={24}>
                                        <Typography as="div" className="lottery-timer-container">
                                            <Typography
                                                as="div"
                                                className="lottery-rules-container"
                                            >
                                                <LotteryRules
                                                    placeholder="Подробные условия проведения"
                                                    items={[
                                                        {
                                                            label: (
                                                                <div
                                                                    dangerouslySetInnerHTML={{
                                                                        __html:
                                                                            Store.lottery?.lottery
                                                                                ?.description ?? ""
                                                                    }}
                                                                ></div>
                                                            ),
                                                            key: 0
                                                        }
                                                    ]}
                                                />
                                                <LotteryAwards
                                                    placeholder="Список призов"
                                                    awards={Store.lottery?.lottery?.awards ?? []}
                                                />
                                            </Typography>
                                        </Typography>
                                    </Col>
                                    <Col xs={24} lg={12} className="vid">
                                        <div className="lottery-video">
                                            <iframe
                                                className="lottery-video"
                                                width="786"
                                                height="316"
                                                title="bezlimit розыгрыш"
                                                frameBorder="0"
                                                src={link.replace("watch?v=", "embed/")}
                                            ></iframe>
                                        </div>
                                    </Col>
                                    <Col xs={24}>
                                        <Typography
                                            as="div"
                                            className="lottery-countdown-container"
                                        >
                                            <Typography as="div" className="summury">
                                                {summary && _.isNil(summary.level_cnt) && (
                                                    <SummaryBlock
                                                        title={t("Шансы")}
                                                        value={summary.personal_cnt}
                                                    />
                                                )}
                                                {summary && !_.isNil(summary.level_cnt) && (
                                                    <>
                                                        <SummaryBlock
                                                            title={t("Личные шансы")}
                                                            value={summary.personal_cnt}
                                                        />
                                                        <SummaryBlock
                                                            title={t("Шансы субдилеров")}
                                                            value={summary.level_cnt}
                                                        />
                                                    </>
                                                )}
                                            </Typography>
                                        </Typography>
                                    </Col>
                                    {_.find(phones ?? [], ({code}) => !_.isNil(code) && !!code) ||
                                    (!phones.length && isSearch) ? (
                                        <Col xs={24}>
                                            <Search
                                                setLoading={setLoadingSkeleton}
                                                setIsSearch={setIsSearch}
                                            />
                                        </Col>
                                    ) : null}
                                </Row>
                            </>
                        )}
                        {noPassport && (
                            <Typography as="div" className="lottery-table-container">
                                <Row gutter={[24, 24]}>
                                    <Col lg={14} xs={24}>
                                        <Typography className="lottery-text-register">
                                            {t(
                                                "Для участия в конкурсе пройдите\n полную регистрацию паспортных данных"
                                            )}
                                        </Typography>
                                    </Col>
                                    <Col className="lottery-register" lg={10} xs={24}>
                                        <Button
                                            className="lottery-btn-register"
                                            type="primary"
                                            onClick={() => navigate("/profile")}
                                        >
                                            {t("Зарегистрироваться")}
                                        </Button>
                                    </Col>
                                </Row>
                            </Typography>
                        )}
                        <Typography as="div" className="lottery-result-container">
                            {_.size(phones) ? (
                                <>
                                    {loadingSkeleton ? (
                                        <LotterySkeleton />
                                    ) : (
                                        <Typography as="div">
                                            <ConfigProvider>
                                                {getTable(isMobile)}
                                                <div className="lottery-table-btn">
                                                    <Button
                                                        type="default"
                                                        onClick={onClick}
                                                        loading={loading}
                                                        disabled={
                                                            data.length >=
                                                            LotteryPhonesService.totalCount
                                                        }
                                                    >
                                                        {t("Показать еще")}
                                                    </Button>
                                                </div>
                                            </ConfigProvider>
                                        </Typography>
                                    )}
                                </>
                            ) : (
                                <LotteryEmptyResult isSearch={isSearch} />
                            )}
                        </Typography>
                    </>
                )}
            </Typography>
        </div>
    );
});

export interface ILotteryPhoneView {
    id: number;
    phone: number;
    phone_mob: number;
    activated_at?: string; // datetime "DD.MM.YYYY",
    code?: JSX.Element;
    activated_code?: ActivateCodeView;
}

interface ActivateCodeView {
    activated_at?: string; // datetime "DD.MM.YYYY",
    code?: JSX.Element;
}
