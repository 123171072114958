import {Col, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import React from "react";
import {useTranslation} from "react-i18next";
import "./style.scss";

export default React.memo(function SubtitleSuccess({sum}: IProps) {
    const {t} = useTranslation();
    return (
        <Space>
            <Typography as="div" className="body-subtitle-bonuses-success">
                <Row>
                    <Col>
                        <Typography color="#000000" size={20}>
                            {t("Сумма пополнения - ")}
                        </Typography>
                    </Col>

                    <Col>
                        <Typography color="#5d256f" size={20}>
                            {t("count_bonus_succes", {
                                count: +sum!
                            })}
                        </Typography>
                    </Col>
                </Row>
            </Typography>
        </Space>
    );
});

interface IProps {
    sum: any;
}
