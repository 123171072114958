// store
import Store from "../stores";
// core
import {axiosFetchBuilder} from "../core/Axios";
import {ILoyalty} from "../core/models/Loyalty";

enum Path {
    loyalty = "/loyalty"
}

class LoyaltyService {
    [Symbol.toStringTag] = this.constructor.name;
    private static readonly loyalty = Store.loyalty;

    static async getLoyalty() {
        const res: ILoyalty[] = await axiosFetchBuilder({
            url: Path.loyalty
        });
        LoyaltyService.loyalty.items = res;
    }
}

export default LoyaltyService;
