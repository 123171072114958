import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Form, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import {IPersonalDataRequest} from "../../../core/models/User";
import {InterceptorContext, LayoutContext, ModalContext} from "../../../core/Contexts";
import {getFieldsErrorSize} from "../../../core/functions/form";
import {IPhonesFiltersRequest} from "../../../core/models/PhonesFilters";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
import useOnCancel from "../../../core/hooks/useOnCancel";
// components
import PhoneField from "../../fields/phone";
import Error from "../../modal/error";
import Success from "../../modal/success";
// services
import ReservationsService from "../../../services/ReservationsService";
import PhonesFiltersService from "../../../services/PhonesFiltersService";
import PhoneService from "../../../services/PhoneService";
import PhonesDefaultService from "../../../services/PhonesDefaultService";

export default React.memo(
    observer(function BookANumberForm({phone, type, id, filters, Service}: IProps) {
        const context = useContext(InterceptorContext);
        const contextModal = useContext(ModalContext);
        const contextLayout = useContext(LayoutContext);
        const ref = useRef(null);
        const {t} = useTranslation();
        const [state, setState] = useState<string>("3 часа");
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        const onCancel = useOnCancel(formInstance);

        useEffect(() => {
            let name: string;

            switch (type) {
                case 4:
                    name = "1 день";
                    break;
                case 5:
                    name = "5 дней";
                    break;
                default:
                    name = "3 часа";
                    break;
            }

            setState(name);
        }, [type]);

        const onFinish = async (values: IPersonalDataRequest) => {
            const phone = _.replace(values.phone!, /(\+7|\s|-)/gi, "");
            try {
                await ReservationsService.postReservation({phone, tariff_id: id});
                await Service.updatePhones(PhonesFiltersService.getFormatedFilters(filters));
                contextModal?.setChildren(
                    <Success
                        title={
                            <Typography as="span">
                                {t("Номер успешно")}
                                <br />
                                {t("забронирован")}
                            </Typography>
                        }
                        subtitle={
                            <Typography as="span">
                                {t("Номер забронирован на {days},", {days: state})}
                                <br />
                                {t("вы можете просмотреть его на странице")}
                                <br />
                                {t("“Мои брони”")}
                            </Typography>
                        }
                    />
                );
            } catch (e: any) {
                contextModal?.setChildren(<Error phone={phone} />);
            }
        };

        return (
            <div ref={ref}>
                <Typography as="div" className="bl-book-a-number-form">
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="book-a-number"
                        onFinish={onFinish}
                        autoComplete="off"
                        initialValues={{
                            phone
                        }}
                    >
                        <Space size={24} direction="vertical">
                            <PhoneField hidden={true} />
                            {!PhonesDefaultService.isPaid(type) && (
                                <Form.Item noStyle shouldUpdate>
                                    {({getFieldsValue, getFieldsError}) => {
                                        const {phone} = getFieldsValue();
                                        const errors = getFieldsErrorSize(getFieldsError);
                                        const formIsComplete = !!phone && !errors;

                                        return (
                                            <Button
                                                type={!formIsComplete ? "default" : "primary"}
                                                htmlType="submit"
                                                disabled={!formIsComplete}
                                                loading={context?.loadingBtn}
                                            >
                                                {t("Забронировать номер")}
                                            </Button>
                                        );
                                    }}
                                </Form.Item>
                            )}

                            <Typography
                                as="div"
                                className="text-center cancel"
                                size={14}
                                color="#000"
                            >
                                <Button type="text" onClick={onCancel}>
                                    {_.gte(contextLayout?.width ?? 0, 768)
                                        ? t("Отменить")
                                        : t("Закрыть")}
                                </Button>
                            </Typography>
                        </Space>
                    </Form>
                </Typography>
            </div>
        );
    })
);

interface IProps {
    phone: number;
    type: number;
    id: number;
    filters: IPhonesFiltersRequest;
    Service: PhoneService;
}
