import React, {useContext} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// forms
import ExtendForm from "../../forms/extend";
// core
import {LayoutContext} from "../../../core/Contexts";

export default React.memo(
    observer(function ExtendModal({id, phone, tariffId, prolongPaidPrice}: IProps) {
        const contextLayout = useContext(LayoutContext);
        const {t} = useTranslation();

        return (
            <Typography as="div" className="bl-extend-modal">
                <Space
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 40 : 16}
                    direction="vertical"
                    align="center"
                >
                    <Typography as="div">
                        <Space size={16} direction="vertical" align="center">
                            <Typography
                                size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                                level={2}
                                color="#000000"
                            >
                                {t("Продлить бронь")}
                            </Typography>
                            <Typography as="div" color="#000000" size={17} className="subtitle">
                                {t(`Продление брони для телефона ${phone},`)}
                                <br />
                                <Typography as="div" size={17} color="#000000">
                                    {t(`услуга стоит`)}
                                    <Typography as="span" size={17} color="#642878">
                                        {t(`${prolongPaidPrice} бонусов/день`)}
                                    </Typography>
                                </Typography>
                            </Typography>
                        </Space>
                    </Typography>
                    <ExtendForm id={id} phone={phone} tariffId={tariffId} />
                </Space>
            </Typography>
        );
    })
);

interface IProps {
    id: number;
    phone: number;
    tariffId: number;
    prolongPaidPrice: number;
}
