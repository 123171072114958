import React, {useContext, useEffect} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import CodeConfirmationFormOutput from "../../forms/codeConfirmationOutput";
import _ from "lodash";
import "./style.scss";
// components
import DurationPhone from "../../blocks/durationPhone";
// services
import UserService from "../../../services/UserService";
// core
import {LayoutContext} from "../../../core/Contexts";
import {observer} from "mobx-react";

export default React.memo(
    observer(function CodeConfirmationOutputBonuses({phone, onSubmit, card, sum, ...props}: any) {
        const contextLayout = useContext(LayoutContext);
        const {t} = useTranslation();

        useEffect(() => {
            const el = document.body.querySelector(".send-phone-message-diler");

            if (el) {
                el.textContent = UserService.personalData.phone.toString();
            }
        }, [contextLayout?.width]);

        return (
            <Typography
                as="div"
                className="bl-code-confirmation-replenish-bonuses bl-code-confirmation-replenish-bonuses-output"
            >
                <Space
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 8 : 56}
                    direction="vertical"
                    align="center"
                >
                    <Typography as="div" className="title-container">
                        <Space size={8} direction="vertical" align="center">
                            <Typography
                                size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                                level={2}
                                color="#000000"
                            >
                                {t("Введите код")}
                            </Typography>
                            <Typography
                                size={14}
                                color={_.gte(contextLayout?.width ?? 0, 768) ? "#000" : "#828282"}
                                className="text-helper subtitle"
                            >
                                {t("Вам будет отправлен код по SMS для подтверждения ")}
                                <br />
                                {t(" вывода бонусов на карту")}
                            </Typography>
                        </Space>
                    </Typography>
                    <Typography size={24} color="#642878" className="text-helper phone">
                        {card}
                    </Typography>
                    <CodeConfirmationFormOutput
                        {...props}
                        phone={phone}
                        onSubmit={onSubmit}
                        card={_.replace(card, /(\+|\s|-)/gi, "")}
                        sum={sum}
                    />
                    {_.gte(contextLayout?.width ?? 0, 768) && (
                        <Typography size={14} color="#000000" className="send-to-phone-message">
                            {t("Мы отправили код подтверждения на {phone}", {
                                phone: UserService.personalData.phone
                            })}
                        </Typography>
                    )}
                    <DurationPhone
                        isCodeConfirmationOutputBonuses={true}
                        card={_.replace(card, /(\+|\s|-)/gi, "")}
                        sum={sum}
                    />
                </Space>
            </Typography>
        );
    })
);
