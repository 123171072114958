import {Button, Icon} from "@bezlimit/bezlimit-ui";
import {useNavigate} from "react-router-dom";

export default function UserDropdownInfo() {
    const navigate = useNavigate();

    const click = () => {
        navigate("/profile");
    };

    return (
        <div className="bl-user-dropdown-info">
            <Button className="btn-default" onClick={click} icon={<Icon name={"user"} />} />
        </div>
    );
}
