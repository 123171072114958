import {Form, Input, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {observer} from "mobx-react";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {InterceptorContext} from "../../../core/Contexts";
// rules
import {Rules} from "../../forms/rules";

export default observer(function EmailAndPhoneField({className, ...props}: any) {
    const {t} = useTranslation();
    const context = useContext(InterceptorContext);
    const [phoneAndEmail, setPhoneAndEmail] = useState<string>();

    const errorContext = _.chain(
        _.isArray(context?.data)
            ? context?.data
            : context?.data?.message
            ? [{message: context?.data?.message, field: context?.data?.message}]
            : []
    )
        .map(({field, message}) => (field === "phone" ? message : undefined))
        .join(" ")
        .valueOf();

    const [error, seterror] = useState(errorContext);

    useEffect(() => {
        seterror(errorContext);
    }, [errorContext]);

    return (
        <>
            <Form.Item
                {...props}
                hasFeedback
                className={className ?? "field-email"}
                name={"email"}
                rules={[
                    Number.isInteger(+phoneAndEmail!) ? Rules.min(10) : Rules.email(),
                    Number.isInteger(+phoneAndEmail!) ? Rules.max(12) : Rules.max(50)
                ]}
            >
                <Input
                    value={phoneAndEmail}
                    onChange={(e) => {
                        setPhoneAndEmail(e.target.value);
                        seterror("");
                    }}
                    title={t("Номер телефона или электронная почта")}
                    autoComplete="new-email"
                />
            </Form.Item>
            <Typography className={"field-email-errorTypography"}>{error}</Typography>
        </>
    );
});
