export default function currency(num: number, props?: IOptions) {
    const options = {
        style: "currency",
        currency: "RUB",
        minimumFractionDigits: 2
    };
    return new Intl.NumberFormat(localStorage.getItem("locale") || "ru-RU", {
        ...options,
        ...(props ?? {})
    }).format(num);
}

interface IOptions {
    style?: string;
    currency?: string;
    minimumFractionDigits?: number;
}
