import {Col, Row, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import "./style.scss";
import {observer} from "mobx-react";
import {useContext, useRef} from "react";
import {useTranslation} from "react-i18next";
import {useParams} from "react-router-dom";
//components
import ListModal from "./listModal";
//core
import {LayoutContext, ModalContext} from "../../../../../core/Contexts";

//store
import {IDescriptionTariffAvailable} from "../../../../../core/models/Tariffs";
import TariffsService from "../../../../../services/TariffsService";

export default observer(function ModalInfoTariff() {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();
    const {name} = useParams();

    const contextModal = useContext(ModalContext);

    const divEl = useRef(null) as any;

    const onClick = (obj: IDescriptionTariffAvailable) => {
        contextModal?.setVisible(true);
        contextModal?.setChildren(<ListModal {...obj} />);
    };

    return (
        <Typography as="div" className="bl-modal-info-tariff">
            <Row gutter={[0, 120]} ref={divEl}>
                {_.chain(TariffsService.items)
                    .filter((i) => _.isEqual(i.name.trim(), name))
                    .map((i) =>
                        _.chain(_.get(i, "desc").slice(0, -1))
                            .map((i, k) => (
                                <Col
                                    className="modal-info-tariff-wrapper-description-item"
                                    key={k}
                                    xs={18}
                                    lg={10}
                                    offset={
                                        (contextLayout?.width ?? 0) > 992
                                            ? _.isEqual(k % 2, 0)
                                                ? 10
                                                : 3
                                            : _.isEqual(k % 2, 0)
                                            ? 6
                                            : 0
                                    }
                                >
                                    <Typography
                                        as="div"
                                        className="bl-tariff-available-title"
                                        onClick={() => onClick(i)}
                                    >
                                        {t(i.title)}
                                    </Typography>
                                </Col>
                            ))
                            .valueOf()
                    )
                    .valueOf()}
            </Row>
        </Typography>
    );
});
