import {makeAutoObservable} from "mobx";
import {axiosFetchBuilder} from "../core/Axios";
import {ICollection, ICollectionResponse} from "../core/models/Phones";
import _ from "lodash";

enum Path {
    collection = "/super-link/phones/collection"
}

class CollectionFilters {
    [Symbol.toStringTag] = this.constructor.name;

    public isCollection: boolean = false;
    public collection?: ICollection[] = [];
    public collectionLength?: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    getCollection = async () => {
        const res: ICollectionResponse = await axiosFetchBuilder({
            url: Path.collection,
            loading: true,
            params: {
                expand: "phones.tariff,phones.region"
            }
        });
        this.collection = res.items;
    };

    getCollectionLength = async () => {
        const res: ICollectionResponse = await axiosFetchBuilder({
            url: Path.collection,
            loading: true,
            params: {
                expand: "",
                fields: "id"
            }
        });
        this.collectionLength = res.items?.length ?? 0;
    };

    loadMoreCollectionItem = async (id: number, page?: number) => {
        if (!this.collection) {
            return;
        }
        const res: ICollectionResponse = await axiosFetchBuilder({
            url: Path.collection,
            loading: false,
            loadingBlock: false,
            loadingBtn: true,
            params: {
                expand: "phones.tariff",
                id: id,
                page: page ?? 1
            }
        });
        const newItemInd = this.collection.findIndex((item) => item.id === id);
        const newItem = res.items[0];
        if (newItemInd !== -1) {
            const updated = _.clone(this.collection);
            updated[newItemInd] = newItem;
            this.collection = updated;
        }
    };

    clearCollection = () => {
        this.collection = [];
    };
}

export default CollectionFilters;
