import {Block, Button, Space, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import _ from "lodash";
import {Moment} from "moment";
import {useContext} from "react";
import {RangeValue} from "rc-picker/lib/interface";
import {useTranslation} from "react-i18next";
//images
import ErrorImg from "../../../images/errorImg";
//core
import {LayoutContext} from "../../../../core/Contexts";

export default function DefaultBlock({title, subtitle, activeKey, setPeriod}: IProps) {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);

    const onClick = () => {
        setPeriod(null);
    };

    return (
        <Block className="bl-default-history">
            <Typography as="div" className="text">
                <Space direction="vertical" size={40}>
                    <Typography as="div" className="images">
                        <ErrorImg />
                    </Typography>

                    <Typography
                        className="title-default"
                        level={2}
                        size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                        color="#fff"
                    >
                        {t(title)}
                    </Typography>
                </Space>
                <Typography
                    className="sub-title-default"
                    as="div"
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 17 : 14}
                    color="rgba(255, 255, 255, 0.5)"
                >
                    {t(subtitle)}
                </Typography>
            </Typography>
            <Typography as="div" className="btn-default">
                <Button type="default" onClick={onClick}>
                    {t("Выбрать дату")}
                </Button>
            </Typography>
        </Block>
    );
}

interface IProps {
    title?: any;
    subtitle?: any;
    activeKey?: string;
    setPeriod: React.Dispatch<React.SetStateAction<RangeValue<Moment>>>;
}
