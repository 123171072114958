import {Col, Icon, Row, Typography} from "@bezlimit/bezlimit-ui";
import {useNavigate} from "react-router-dom";
import "./style.scss";
//components
import {useTranslation} from "react-i18next";
// context
import infinity_bl from "../../../../images/infinity_bl.svg";
import SocialIconsServices from "../../widgets/socialIconsServices";

const infinityIcon = <Icon className="infinity-bl" src={infinity_bl} />;

export default function Plan({
    name,
    subscription_fee,
    packet_minutes,
    packet_sms,
    packet_internet,
    isDesktop = false,
    isMobile = false
}: Props) {
    const {t} = useTranslation();
    const navigate = useNavigate();

    return (
        <Typography
            as="div"
            className={`card ${isMobile && "card-mobile"} 
                        ${isDesktop && "card-desktop"}
                        `}
            onClick={() => navigate("/tariffs/" + name)}
        >
            <Row className="bl-plan-mobile">
                <Col xs={24}>
                    <Typography className="title">
                        {subscription_fee}
                        <span className="suffix">{t(" ₽ в месяц")}</span>
                    </Typography>
                </Col>
                <Col xs={24}>
                    <Row
                        gutter={[16, {xs: 4, sm: 4, md: 8, lg: 8}]}
                        className="bl-plan-mobile-row-socialIconsServices-wrapper"
                    >
                        <SocialIconsServices
                            isPagePlans={true}
                            socialArrayNew={true}
                            subscriptionFee={subscription_fee}
                        />
                    </Row>
                </Col>

                <Col xs={24}>
                    <Typography className="social-rest">{t("На все остальное")}</Typography>
                </Col>
                <Col xs={8}>
                    <Typography className="title-value">
                        {packet_internet !== 0 ? packet_internet : infinityIcon}
                        <span className="suffix inline">{" Гб"}</span>
                    </Typography>
                </Col>
                <Col xs={8}>
                    <Typography className="title-value">
                        {packet_minutes !== 0 ? packet_minutes : infinityIcon}
                        <span className="suffix inline">{" мин"}</span>
                    </Typography>
                </Col>
                <Col xs={8}>
                    <Typography className="title-value">
                        {packet_sms !== 0 ? packet_sms : infinityIcon}
                        <span className="suffix inline">{" sms"}</span>
                    </Typography>
                </Col>
            </Row>
        </Typography>
    );
}

interface IProps {
    subscription_fee: any;
    packet_minutes: any;
    packet_sms: any;
    packet_internet: any;
    name: any;
    group_name: string;
    isDesktop?: boolean;
    isMobile?: boolean;
}

type Props = IProps;
