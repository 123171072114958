import React, {useRef, useState} from "react";
import {observer} from "mobx-react";
import {Carousel, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
// import SVG from "react-inlinesvg";
import "./style.scss";
// components
import Banner from "./banner";
//store
import Store from "../../../../stores";

export default React.memo(
    observer(function LoyaltyBannerSlider() {
        const ref = useRef<any>(null);
        const [currentIndex, setCurrentIndex] = useState(1);

        return (
            <Typography as="div" className="bl-banner-slider">
                <Carousel
                    autoplay={true}
                    autoplaySpeed={5000}
                    ref={ref}
                    speed={100}
                    effect="fade"
                    dots={true}
                    dotPosition="bottom"
                    arrows={false}
                    infinite={true}
                    adaptiveHeight={false}
                    onReInit={() => {
                        if (
                            ref.current &&
                            !_.isEqual(
                                _.get(ref.current, "innerSlider.track.props.currentSlide", 0),
                                currentIndex
                            )
                        ) {
                            ref.current.goTo(currentIndex);
                        }
                    }}
                    beforeChange={(currentSlide, nextSlide) => {
                        setCurrentIndex(nextSlide);
                    }}
                >
                    {_.chain(Store.loyalty.items)
                        .map((item) => (
                            <Typography as="div" key={item.code}>
                                <Banner loyalty={item} />
                            </Typography>
                        ))
                        .valueOf()}
                </Carousel>
            </Typography>
        );
    })
);
