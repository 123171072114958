import {Row, Col} from "@bezlimit/bezlimit-ui";
// components
import RegisterStore from "../../../blocks/registerStore";

export default function RegisterStorePage() {
    return (
        <Row justify="center" gutter={32}>
            <Col lg={{span: 24}} xs={{span: 24}}>
                <RegisterStore />
            </Col>
        </Row>
    );
}
