import React, {useContext} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import CodeConfirmationFormPhone from "../../forms/codeConfirmationPhone";
import _ from "lodash";
// core
import {LayoutContext} from "../../../core/Contexts";

export default React.memo(function CodeConfirmationPhone({phone, onSubmit, ...props}: any) {
    const contextLayout = useContext(LayoutContext);

    return (
        <Typography as="div" className="bl-code-confirmation">
            <Space
                size={_.gte(contextLayout?.width ?? 0, 768) ? 8 : 56}
                direction="vertical"
                align="center"
            >
                <CodeConfirmationFormPhone {...props} phone={phone} onSubmit={onSubmit} />
            </Space>
        </Typography>
    );
});
