import React from "react";
import {Space, Typography} from "@bezlimit/bezlimit-ui";
import moment from "moment";
import _ from "lodash";
// components
import Text from "./text";
import {useTranslation} from "react-i18next";

export default React.memo(function Time({duration, suffix}: IProps) {
    const {t} = useTranslation();

    return (
        <Typography as="div" className="bl-numbers-in-booking-duration">
            <Space direction="horizontal" size={4}>
                {!!duration.days() && 
                <>
                    <Text
                        className="time"
                        text={`${_.lt(duration.days(), 10) ? `0${duration.days()}` : duration.days()}${
                            suffix ? t("д") : ""
                        }`}
                    />
                    <Text text=":" />
                </> }
                {(!!duration.days() || !!duration.hours()) && 
                <>
                    <Text
                        className="time"
                        text={`${
                            _.lt(duration.hours(), 10) ? `0${duration.hours()}` : duration.hours()
                        }${suffix ? t("ч") : ""}`}
                    />
                    <Text text=":" />
                </>}
                <Text
                    className="time"
                    text={`${
                        _.lt(duration.minutes(), 10) ? `0${duration.minutes()}` : duration.minutes()
                    }${suffix ? t("м") : ""}`}
                />
                <Text text=":" />
                <Text
                    className="time"
                    text={`${
                        _.lt(duration.seconds(), 10) ? `0${duration.seconds()}` : duration.seconds()
                    }${suffix ? t("с") : ""}`}
                />
            </Space>
        </Typography>
    );
});

interface IProps {
    duration: moment.Duration;
    suffix?: boolean;
}
