import React, {useContext} from "react";
import {Typography, Button, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
//core
import {ModalContext} from "../../../core/Contexts";
import {ILevel, ILevelRestoreResponse} from "../../../core/models/Levels";
// services
import LevelsService from "../../../services/LevelsService";
// components
import Error from "../error";
import RestoreAccessSuccess from "./restoreAccessSuccess";

import "./style.scss";

export default React.memo(
    observer(function RestoreAccess(props: ILevel) {
        const {t} = useTranslation();
        const contextModal = useContext(ModalContext);
        const {name, id} = props;

        const onRestore = async (id: number) => {
            const res = await LevelsService.RestoreLevelAccess(id);
            const modal = (res as any).status ? (
                <Error title={<Typography as="span">{t("Доступ не восстановлен")}</Typography>} />
            ) : (
                <RestoreAccessSuccess res={res as ILevelRestoreResponse} />
            );

            contextModal?.setChildren(modal);
        };

        return (
            <Space className="bl-restore-access" size={16} direction="vertical" align="center">
                <Typography className="restore-access-title">{t("Восстановить доступ")}</Typography>
                <Typography>
                    <span>{t("Вы точно хотите восстановить доступ данному дилеру ")}</span>
                    <span className="restore-access-name">{name}</span>
                    <span>{"?"}</span>
                </Typography>

                <Button className="restore-access-btn" type="primary" onClick={() => onRestore(id)}>
                    {t("Восстановить")}
                </Button>
                <Button
                    className="restore-access-cansel-btn"
                    type="default"
                    onClick={() => {
                        contextModal?.setVisible(false);
                    }}
                >
                    {t("Отменить")}
                </Button>
            </Space>
        );
    })
);
