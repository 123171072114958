import React, {useRef, useState} from "react";
import {Typography, Button} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import Clipboard from "react-clipboard.js";
// store
import Store from "../../../../stores";
//core
import {ILevelRestoreResponse} from "../../../../core/models/Levels";
// components
import Success from "../../success";

export default React.memo(
    observer(function RestoreAccessSuccess({res}: IProps) {
        const {t} = useTranslation();

        const [disabled, setDisabled] = useState(false);
        const timeout: any = useRef();

        const onSuccess = async () => {
            clearTimeout(timeout.current);
            setDisabled(true);
            timeout.current = setTimeout(() => {
                setDisabled(false);
            }, 3000);
        };

        return (
            <Success
                title={<Typography as="span">{t("Доступ успешно восстановлен")}</Typography>}
                subtitle={
                    <>
                        <Typography as="div" className="bl-restore-access__success-loginPassword">
                            {res.phone
                                ? t("Логин и новый пароль успешно отправлены на номер")
                                : t("Логин и новый пароль успешно отправлены по SMS")}
                            <br />
                            <Typography as="span" color="#5d256f">
                                {res.phone ? res.phone : ""}
                            </Typography>
                        </Typography>
                        {Store.user?.level?.level === 0 && (
                            <Button
                                type="primary"
                                disabled={disabled}
                                className="bl-restore-access-btn-recycle"
                            >
                                <Clipboard
                                    component="span"
                                    data-clipboard-text={`Логин\n${
                                        res.login ? res.login : "не определен"
                                    }\nПароль\n${
                                        res.password ? res.password : "не определен"
                                    }\nВаш персональный менеджер\n${
                                        res.manager ? res.manager : "не определен"
                                    }`}
                                    onSuccess={onSuccess}
                                >
                                    {t(
                                        disabled
                                            ? "Доступы скопированы"
                                            : "Скопировать новые доступы"
                                    )}
                                </Clipboard>
                            </Button>
                        )}
                    </>
                }
            />
        );
    })
);

interface IProps {
    res: ILevelRestoreResponse;
}
