import {Typography, Row, Col, Checkbox, Space, Skeleton, Divider} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {MobXProviderContext, observer} from "mobx-react";
import up from "../../../../../images/arrow-up.png";
import {useContext, useEffect, useState} from "react";

const SkeletonList: React.FC = observer(() => {
    const [arr, setArr] = useState(Array(30));
    const {store} = useContext(MobXProviderContext);

    useEffect(() => {
        if (store.pageLoading) {
            setTimeout(() => {
                setArr(Array(100));
            }, 300);
        } else {
            setArr(Array(30));
        }
    }, [store.pageLoading]);

    return (
        <Typography as="div" className="skeleton-list">
            <Row gutter={[24, 24]} className="head">
                <Col md={12}>
                    <Checkbox disabled={true}>Выбрать все</Checkbox>
                </Col>
                <Col md={12} className="flex-left">
                    <Space direction="horizontal" size={8} className="rt8">
                        <Skeleton.Button active={true} className="w40 h40" />
                        <Skeleton.Button active={true} className="w40 h40" />
                    </Space>
                </Col>
                <Col md={24}>
                    <Divider />
                </Col>
            </Row>
            {_.chain(arr)
                .map((i, k) => (
                    <>
                        <Row gutter={[24, 24]} className="list">
                            <Col md={2} lg={1}>
                                <Skeleton.Button active={true} className="w26" />
                            </Col>
                            <Col md={4} lg={4}>
                                <Skeleton.Button active={true} className="w100" />
                            </Col>
                            <Col md={9} lg={12}>
                                <Skeleton.Button active={true} className="w100" />
                            </Col>
                            <Col md={3} lg={2} className="flex-center">
                                <img src={up} alt="" width={20} />
                            </Col>
                            <Col md={4} lg={4} className="left">
                                <Skeleton.Button active={true} className="w100" />
                            </Col>
                            <Col md={2} lg={1} className="flex-left right">
                                <Skeleton.Button active={true} className="w26 " />
                            </Col>
                        </Row>
                        <Row gutter={[24, 24]}>
                            <Col xs={24}>
                                <Divider className="dv" />
                            </Col>
                        </Row>
                    </>
                ))
                .valueOf()}
            <Row gutter={[24, 24]}>
                <Col lg={24}>
                    <Skeleton.Button active={true} className="w100 h48" />
                </Col>
            </Row>
        </Typography>
    );
});

export default SkeletonList;
