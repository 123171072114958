import React, {useContext, useRef} from "react";
import {Button, Form, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";

// core
import {InterceptorContext, LayoutContext, ModalContext} from "../../../core/Contexts";
import {getFieldsErrorSize} from "../../../core/functions/form";
// services
import UserService from "../../../services/UserService";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
import useFieldFocus from "../../../core/hooks/useFieldFocus";
import useOnChange from "../../../core/hooks/useOnChange";
// components
import PasswordField from "../../fields/password";
import Error from "../../modal/error";
import EmailCodeConfirmation from "../../modal/emailCodeConfirmation";
import Success from "../../modal/success";

export default React.memo(
    observer(function EditPasswordForm({email}: Props) {
        const context = useContext(InterceptorContext);
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const ref = useRef(null);
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        const onChange = useOnChange(formInstance);
        useFieldFocus(ref, "oldPassword");

        const onSubmit = async ({code, password, password_new, password_new_repeat}: any) => {
            await UserService.putChangePassword({
                code,
                password,
                password_new,
                password_new_repeat
            });
            contextModal?.setChildren(
                <Success
                    title={
                        <Typography as="span">
                            {t("Пароль успешно ")}
                            <br />
                            {t("изменен")}
                        </Typography>
                    }
                />
            );
            await UserService.getUsers();
        };

        const onFinish = async (values: any) => {
            const password: string = values.oldPassword;
            const password_new: string = values.newPassword;
            const password_new_repeat: string = values.confirmPassword;
            try {
                await UserService.putChangePassword({password, password_new, password_new_repeat});
                contextModal?.setChildren(
                    <EmailCodeConfirmation
                        email={email}
                        password={password}
                        password_new={password_new}
                        password_new_repeat={password_new_repeat}
                        onSubmit={onSubmit}
                        type="password"
                    />
                );
                formInstance.resetFields();
            } catch (e: any) {
                contextModal?.setChildren(<Error />);
            }
        };

        return (
            <div ref={ref}>
                <Typography as="div">
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="edit-password"
                        onFinish={onFinish}
                        onFieldsChange={onChange}
                        autoComplete="off"
                        initialValues={{
                            oldPassword: "",
                            newPassword: "",
                            confirmPassword: ""
                        }}
                    >
                        <Space
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 8 : 24}
                            direction="vertical"
                        >
                            <PasswordField name={"oldPassword"} label={t("Старый пароль")} />
                            <PasswordField name={"newPassword"} label={t("Придумайте пароль")} />
                            <PasswordField name={"confirmPassword"} label={t("Повторите пароль")} />
                            <Form.Item noStyle shouldUpdate>
                                {({getFieldsValue, getFieldsError}) => {
                                    const {oldPassword, newPassword, confirmPassword} =
                                        getFieldsValue();
                                    const errors = getFieldsErrorSize(getFieldsError);
                                    const formIsComplete =
                                        !!oldPassword &&
                                        !!newPassword &&
                                        !!confirmPassword &&
                                        newPassword === confirmPassword &&
                                        !errors;

                                    return (
                                        <Button
                                            type={!formIsComplete ? "default" : "primary"}
                                            htmlType="submit"
                                            disabled={!formIsComplete}
                                            loading={context?.loadingBtn}
                                        >
                                            {t("Сменить пароль")}
                                        </Button>
                                    );
                                }}
                            </Form.Item>
                        </Space>
                    </Form>
                </Typography>
            </div>
        );
    })
);

interface Props {
    email: string;
}
