import {Form, Input} from "@bezlimit/bezlimit-ui";
import {FormItemProps} from "antd";
import {useTranslation} from "react-i18next";
// rules
import {Rules} from "../../forms/rules";

const {TextArea} = Input;

export default function CommentField(props: FormItemProps<any>) {
    const {t} = useTranslation();

    return (
        <Form.Item {...props} className="field-comment" name="comment" rules={[Rules.min(4)]}>
            <TextArea
                title={t("Комментарий")}
                placeholder={t("Комментарий")}
                autoComplete="new-comment"
            />
        </Form.Item>
    );
}
