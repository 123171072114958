import {Form, Input} from "@bezlimit/bezlimit-ui";
import {FormItemProps} from "antd";
import {useTranslation} from "react-i18next";

export default function SaveFiltersField(props: FormItemProps<any>) {
    const {t} = useTranslation();

    return (
        <Form.Item {...props} className="field-save-filters" name="saveFilters">
            <Input title={t("Название")}></Input>
        </Form.Item>
    );
}
