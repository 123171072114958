// core
import _ from "lodash";
import {IAxiosConfig, axiosFetchBuilder} from "../core/Axios";
import {
    IBonusesLevelsResponse,
    IGetLevelsSummary,
    ILevelsSummaryRequest,
    IPayoutRequest,
    ISummaryResponse
} from "../core/models/Bonuses";
// store
import Store from "../stores";

enum Path {
    summary = "/bonuses/summary",
    postPayout = "/bonuses/payouts",
    getLevels = "/bonuses/levels",
    getLevelsSummary = "/bonuses/levels/summary"
}

class BonusesService {
    [Symbol.toStringTag] = this.constructor.name;
    private static readonly bonuses = Store.bonuses;
    private static readonly user = Store.user;

    static get rateBasicPersonal() {
        return BonusesService.bonuses.rateBasicPersonal;
    }

    static get rateBasicLevel() {
        return BonusesService.bonuses.rateBasicLevel;
    }

    static get rateLoyalty() {
        return BonusesService.bonuses.rateLoyalty;
    }

    static get rateLoyaltyPersonal() {
        return BonusesService.bonuses.rateLoyaltyPersonal;
    }

    static get ratePhoneBl() {
        return BonusesService.bonuses.ratePhoneBl;
    }

    static get processSum() {
        return BonusesService.bonuses.processSum;
    }

    static get futurePaymentSum() {
        return BonusesService.bonuses.futurePaymentSum;
    }

    static get paymentSum() {
        return BonusesService.bonuses.paymentSum;
    }

    static get payoutSum() {
        return BonusesService.bonuses.payoutSum;
    }

    static get totalCount() {
        return BonusesService.bonuses.totalCount;
    }

    static get summarySum() {
        return BonusesService.bonuses.summarySum;
    }

    static get summaryProcessSum() {
        return BonusesService.bonuses.summaryProcessSum;
    }

    static get firstPaymentAt() {
        return BonusesService.bonuses.firstPaymentAt;
    }

    static get firstPayoutAt() {
        return BonusesService.bonuses.firstPayoutAt;
    }

    static get accumulated() {
        const res = BonusesService.paymentSum - BonusesService.payoutSum;
        return _.gte(res, 0) ? res : 0;
    }

    static get getRatePersonal() {
        return (
            BonusesService.rateBasicPersonal +
            BonusesService.ratePhoneBl +
            (!_.isNil(BonusesService.user.loyalty) ? BonusesService.rateLoyaltyPersonal : 0)
        );
    }

    static get getRateLevel() {
        return (
            (!_.isNil(BonusesService.user.loyalty) ? BonusesService.rateLoyalty : 0) +
            BonusesService.rateBasicLevel
        );
    }

    static get processSumGraph() {
        const sum = (BonusesService.processSum + BonusesService.accumulated).toFixed(2);

        return _.gte(BonusesService.accumulated, BonusesService.processSum)
            ? ((BonusesService.accumulated / +sum) * 100).toFixed(2) ?? 0
            : (100 - (BonusesService.processSum / +sum) * 100).toFixed(2) ?? 0;
    }

    static get paymentProcessSum() {
        return BonusesService.paymentSum + BonusesService.processSum;
    }

    static async summary(options?: IAxiosConfig) {
        const res: ISummaryResponse = await axiosFetchBuilder({
            url: Path.summary,
            params: {
                expand: "rates"
            },
            ...(options
                ? {loading: options.loading ?? true, loadingBlock: options.loadingBlock ?? false}
                : {})
        });

        BonusesService.bonuses.rateBasicPersonal = res.rates.default.basic_personal;
        BonusesService.bonuses.rateBasicLevel = res.rates.default.basic_level;
        BonusesService.bonuses.rateLoyalty = res.rates.default.loyalty_level;
        BonusesService.bonuses.rateLoyaltyPersonal = res.rates.default.loyalty_personal;
        BonusesService.bonuses.ratePhoneBl = res.rates.default.phone_bl;
        BonusesService.bonuses.paymentSum = res.payment_sum;
        BonusesService.bonuses.payoutSum = res.payout_sum;
        BonusesService.bonuses.processSum = res.process_sum;
        BonusesService.bonuses.futurePaymentSum = res.future_payment_sum;
        BonusesService.bonuses.firstPaymentAt = res.first_payment_at;
        BonusesService.bonuses.firstPayoutAt = res.first_payout_at;
        BonusesService.bonuses.rateLoyaltyLevel = res.rate_loyalty_level;
    }

    static async postPayoutBonuses(request: IPayoutRequest): Promise<IPayoutRequest> {
        return await axiosFetchBuilder({
            url: Path.postPayout,
            method: "POST",
            loading: false,
            data: {...request}
        });
    }

    static async getLevelsBonuses(): Promise<IBonusesLevelsResponse> {
        const res: IBonusesLevelsResponse = await axiosFetchBuilder({
            url: Path.getLevels,
            params: {
                fields: "_meta",
                per_page: 1
            }
        });

        BonusesService.bonuses.totalCount = res._meta.totalCount;
        return res;
    }

    static async getLevelsSummary(request?: ILevelsSummaryRequest): Promise<IGetLevelsSummary> {
        const res: IGetLevelsSummary = await axiosFetchBuilder({
            url: Path.getLevelsSummary,
            params: {
                ...request
            }
        });

        BonusesService.bonuses.summarySum = res.sum;
        BonusesService.bonuses.summaryProcessSum = res.process_sum;
        return res;
    }
}

export default BonusesService;
