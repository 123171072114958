// core
import _ from "lodash";
import {axiosFetchBuilder} from "../core/Axios";
import {
    IAuthRequest,
    IAuthResponse,
    IChangePasswordRequest,
    IRegisterStore,
    IRestorePasswordRequest
} from "../core/models/Auth";
import {
    IInviteRequest,
    IPersonalDataRequest,
    IUserPersonalManagerResponse,
    IUsersRequest,
    IUsersResponse,
    IPersonalDataPassportRequest,
    IPhotoRequest,
    IReviewRequest,
    ISystemVars
} from "../core/models/User";
// store
import Store from "../stores";
import {
    browserName,
    browserVersion,
    isAndroid,
    isIOS,
    mobileModel,
    mobileVendor,
    osName
} from "react-device-detect";

enum Path {
    addresses = "/users/addresses",
    systemVars = "/system/vars",
    login = "/users/login",
    usersRegister = "/users/register",
    resetPassword = "users/restore-password",
    changePassword = "users/change-password",
    personalData = "users/personal-data/phone",
    personalManager = "users/personal-manager",
    bitrixManager = "users/bitrix-manager",
    logout = "users/logout",
    users = "users",
    invite = "users/invite",
    avatar = "users/avatar",
    photos = "users/personal-data/photos",
    personalDataPassport = "users/personal-data/passport",
    review = "feedback/review",
    users_check_access = "users/check-access"
}

class UserService {
    [Symbol.toStringTag] = this.constructor.name;
    private static readonly user = Store.user;

    static get token() {
        return UserService.user.token;
    }

    static get storeCanBookForDelivery() {
        return UserService.user.storeCanBookForDelivery;
    }

    static get id() {
        return UserService.user.id;
    }

    static get level() {
        return UserService.user.level;
    }

    static get login() {
        return UserService.user.login;
    }

    static get profile() {
        return UserService.user.profile;
    }

    static get personalData() {
        return UserService.user.personal_data;
    }

    static get personalManager() {
        return UserService.user.personal_manager;
    }

    static get loyalty() {
        return UserService.user.loyalty;
    }

    static get hasPersonalManager() {
        return !!UserService.user.personal_manager;
    }

    static get levelUserInfo() {
        return UserService.user.levelUserInfo;
    }

    static get systemVars() {
        return UserService.user.systemVars;
    }

    static get isNotBezlimitAndPasswordConfirmed() {
        return !(
            _.isEqual(UserService.personalData.phone_status, "confirmed") &&
            _.isEqual(UserService.personalData.passport_status, "confirmed") &&
            UserService.personalData.is_phone_bezlimit
        );
    }

    static get isBezlimitIsNotPasswordConfirmed() {
        return (
            _.isEqual(UserService.personalData.phone_status, "confirmed") &&
            _.isEqual(UserService.personalData.passport_status, "reject") &&
            UserService.personalData.is_phone_bezlimit
        );
    }

    static get isPasswordReject() {
        return _.isEqual(UserService.personalData.passport_status, "reject");
    }

    static get isPasswordConfirmed() {
        return _.isEqual(UserService.personalData.passport_status, "confirmed");
    }

    static async init() {
        UserService.user.token = localStorage.getItem("auth-token");

        if (UserService.user.token) {
            await Promise.all([UserService.getUsers(), UserService.getSystemVars()]);
        }
    }

    static async getLogin(request: IAuthRequest) {
        let data;
        let lat;
        let lon;
        if ("geolocation" in navigator) {
            /* местоположение доступно */
            const position: any = await new Promise((resolve, reject) => {
                navigator.geolocation.getCurrentPosition(resolve, (error: any) => {
                    if (error.code == error.PERMISSION_DENIED) {
                        resolve(true);
                    }
                });
            });

            lat = position?.coords?.latitude ?? "";
            lon = position?.coords?.longitude ?? "";
            data = {
                ...request,
                ...{
                    lat,
                    lon,
                    device_data: JSON.stringify({
                        name: isIOS ? mobileModel : isAndroid ? mobileVendor : "PC",
                        type: "web",
                        os_name: _.isEqual(osName, "Linux")
                            ? "linux"
                            : _.isEqual(osName, "Mac OS")
                            ? "macOS"
                            : _.isEqual(osName, "Windows")
                            ? "windows"
                            : _.isEqual(osName, "Android")
                            ? "android"
                            : osName,
                        browser_name: browserName,
                        browser_version: browserVersion
                    })
                }
            };
        } else {
            data = {
                ...request,
                ...{
                    lat: "",
                    lon: "",
                    device_data: JSON.stringify({
                        name: isIOS ? mobileModel : isAndroid ? mobileVendor : "PC",
                        type: "web",
                        os_name: _.isEqual(osName, "Linux")
                            ? "linux"
                            : _.isEqual(osName, "Mac OS")
                            ? "macOS"
                            : _.isEqual(osName, "Windows")
                            ? "windows"
                            : _.isEqual(osName, "Android")
                            ? "android"
                            : osName,
                        browser_name: browserName,
                        browser_version: browserVersion
                    })
                }
            };
        }

        const res: IAuthResponse = await axiosFetchBuilder({
            url: Path.login,
            method: "POST",
            data: {...data}
        });

        // localStorage.setItem("auth-token", res.access_token);
        localStorage.setItem("auth-token", res.token);
        await UserService.init();
        return res;
    }

    static async getSystemVars() {
        const res = await axiosFetchBuilder<ISystemVars>({
            url: Path.systemVars,
            method: "GET",
            loading: true
        });
        UserService.user.systemVars = res;
        // return res;
    }

    static async getUsersRegister(request: IRegisterStore) {
        return await axiosFetchBuilder({
            url: Path.usersRegister,
            method: "POST",
            data: {...request}
        });
    }

    static async putRestorePassword(request: IRestorePasswordRequest) {
        await axiosFetchBuilder({
            url: Path.resetPassword,
            method: "PUT",
            data: {...request}
        });
    }

    static async putChangePassword({isloading, ...request}: IChangePasswordRequest) {
        await axiosFetchBuilder({
            url: Path.changePassword,
            method: "PUT",
            data: {...request},
            loading: false,
            loadingBtn: !_.isNil(isloading) ? isloading : true
        });
    }

    static async putPersonalData({isloading, ...request}: IPersonalDataRequest) {
        return await axiosFetchBuilder({
            url: Path.personalData,
            method: "PUT",
            data: {...request},
            loading: false,
            loadingBtn: !_.isNil(isloading) ? isloading : true
        });
    }

    static async putUsers({isloading, ...request}: IUsersRequest) {
        return await axiosFetchBuilder({
            url: Path.users,
            method: "PUT",
            data: {...request},
            loading: false,
            loadingBtn: !_.isNil(isloading) ? isloading : true
        });
    }

    static async getUsers() {
        const storeCanBookForDelivery: boolean = await axiosFetchBuilder({
            url: Path.users_check_access,
            params: {name: "storeCanBookForDelivery"}
        });
        const res: IUsersResponse = await axiosFetchBuilder({
            url: Path.users,
            params: {
                expand: "profile,personal_data,level,loyalty,loyalty.upgrade_cnt,level.activation,loyalty.scores"
            }
        });
        UserService.user.id = res.id;
        UserService.user.login = res.login;
        UserService.user.profile = res.profile;
        UserService.user.personal_data = res.personal_data;
        UserService.user.level = res.level;
        UserService.user.loyalty = res.loyalty;
        UserService.user.storeCanBookForDelivery = storeCanBookForDelivery;
    }

    static async getFullUserInfoLevel(): Promise<IUsersResponse> {
        const res: IUsersResponse = await axiosFetchBuilder({
            url: Path.users,
            params: {
                expand: "level.activation,loyalty.scores"
            }
        });

        UserService.user.levelUserInfo = res.level;
        return res;
    }

    static async getDealer() {
        const res: IUsersResponse = await axiosFetchBuilder({
            url: Path.users,
            params: {
                expand: "dealer"
            }
        });
        UserService.user.dealer = res.dealer;
    }

    static async getPersonalManager() {
        try {
            const res: IUserPersonalManagerResponse = await axiosFetchBuilder({
                url: Path.bitrixManager
            });
            UserService.user.personal_manager = res;
        } catch (e) {
            return null;
        }
    }

    static async getPersonalManager2() {
        try {
            const res: IUserPersonalManagerResponse = await axiosFetchBuilder({
                url: Path.personalManager
            });
            UserService.user.personal_manager = res;
        } catch (e) {
            return null;
        }
    }

    static async postLogout() {
        localStorage.removeItem("auth-token");
        UserService.user.token = null;
    }

    static async postInvite(request: IInviteRequest): Promise<any> {
        return await axiosFetchBuilder({
            url: Path.invite,
            method: "POST",
            loading: false,
            data: {...request}
        });
    }

    static async postAvatar(avatar: File) {
        await axiosFetchBuilder({
            url: Path.avatar,
            method: "POST",
            loading: false,
            data: {avatar}
        });
    }

    static async putPersonalDataPassport(request: IPersonalDataPassportRequest) {
        await axiosFetchBuilder({
            url: Path.personalDataPassport,
            method: "PUT",
            data: {...request}
        });
    }

    static async postPersonalDataPhotos(photos: File[]) {
        if (photos.length !== 2 || !photos[0] || !photos[1]) {
            return null;
        }
        const request1: IPhotoRequest = {
            file: photos[0]
        };
        const request2: IPhotoRequest = {
            file: photos[1]
        };
        try {
            await Promise.all([
                await axiosFetchBuilder({
                    url: Path.photos,
                    method: "POST",
                    loading: false,
                    data: {...request1}
                }),
                await axiosFetchBuilder({
                    url: Path.photos,
                    method: "POST",
                    loading: false,
                    data: {...request2}
                })
            ]);
            await UserService.putPersonalDataPassport({send_moderation: true});
        } catch (err) {
            throw err;
        }
    }

    static async getUserLoyalty() {
        const res: IUsersResponse = await axiosFetchBuilder({
            url: Path.users,
            params: {
                expand: "loyalty,loyalty.upgrade_cnt,loyalty.scores"
            }
        });

        UserService.user.loyalty = res.loyalty;
    }

    static async postReview(request: IReviewRequest) {
        const res: IUsersResponse = await axiosFetchBuilder({
            url: Path.review,
            method: "POST",
            loading: false,
            data: request
        });
        UserService.user.loyalty = res.loyalty;
    }

    static async getAddresses() {
        return await axiosFetchBuilder<ISystemVars>({
            url: Path.addresses,
            method: "GET",
            params: {
                per_page: 50
            }
        });
    }

    static async postAddresses(value: string) {
        return await axiosFetchBuilder<ISystemVars>({
            url: Path.addresses,
            method: "POST",
            data: {
                value
            }
        });
    }

    static async deletAddresses(id: number) {
        return await axiosFetchBuilder<ISystemVars>({
            url: `${Path.addresses}/${id}`,
            method: "DELETE"
        });
    }
}

export default UserService;
