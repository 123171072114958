import React, {useContext, useEffect, useRef} from "react";
import {Button, Checkbox, Col, Collapse, Form, Icon, Row, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import "./style.scss";
// core
import {IMeta, IReservations, IReservationsRequest} from "../../../core/models/Reservations";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
// components
import NumberField from "../../fields/number";
import RangePickerField from "../../fields/rangePicker";
import moment from "moment";
import _ from "lodash";
import {LayoutContext} from "../../../core/Contexts";

const CheckboxGroup = Checkbox.Group;

export default React.memo(
    observer(function ActivationFilterForm({
        updateList,
        setItems,
        setFilterRequest,
        setShimmer
    }: IProps) {
        const contextLayout = useContext(LayoutContext);
        const ref = useRef<HTMLDivElement>(null);
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);

        const onFinish = async (values: any) => {
            setShimmer(true);
            if (!values.phone && !values.period && (!values.type || !_.size(values.type))) {
                setFilterRequest(undefined);
                setTimeout(() => {
                    updateList(undefined, false, true);
                }, 0);
            } else {
                setItems([]);
                let request: any = {
                    page: 1
                };

                if (values.type) {
                    request = {...request, type: values.type.join(",")};
                }

                if (values.phone) {
                    request = {...request, phone_number: values.phone.trim()};
                }

                if (values.period) {
                    request = {
                        ...request,
                        activated_from: moment(values.period[0]).format("YYYY-MM-DD 00:00:00"),
                        activated_to: moment(values.period[1]).format("YYYY-MM-DD 23:59:59")
                    };
                }

                setFilterRequest(request);
                setTimeout(() => {
                    updateList(request, false, true);
                }, 0);
            }
        };

        useEffect(() => {
            return () => {
                formInstance.resetFields();
                setFilterRequest(undefined);
            };
        }, []);

        return (
            <div ref={ref}>
                <Typography as="div" className="bl-activation-filter-form">
                    <Collapse
                        defaultActiveKey="1"
                        expandIcon={({isActive}) => {
                            return (
                                <Icon
                                    name="arrow_down"
                                    size="16"
                                    style={{transform: `rotate(${isActive ? "180" : "0"}deg)`}}
                                />
                            );
                        }}
                    >
                        <Collapse.Panel header={t("Фильтр")} key="1">
                            <Form
                                form={formInstance}
                                layout="horizontal"
                                name="activation-filter"
                                onFinish={onFinish}
                                autoComplete="off"
                            >
                                <Row gutter={[16, 16]}>
                                    {_.gt(contextLayout?.width ?? 0, 768) && (
                                        <Col xs={24}>
                                            <Typography as="div" className="ch-container">
                                                <Form.Item name="type" noStyle>
                                                    <CheckboxGroup>
                                                        <Checkbox value="nps">
                                                            {t("Активации с НПС")}
                                                        </Checkbox>
                                                        <Checkbox value="free,bonus">
                                                            {t("Личные")}
                                                        </Checkbox>
                                                        <Checkbox value="super_link">
                                                            {t("Активации по суперссылке")}
                                                        </Checkbox>
                                                    </CheckboxGroup>
                                                </Form.Item>
                                            </Typography>
                                        </Col>
                                    )}

                                    <Col xs={24} lg={11}>
                                        <NumberField
                                            updateList={updateList}
                                            allowClear
                                            name="phone"
                                            title="Поиск по номеру телефона"
                                            formInstance={formInstance}
                                        />
                                    </Col>
                                    <Col xs={24} lg={8}>
                                        <RangePickerField
                                            props={{
                                                name: "period",
                                                label: t("Период активации")
                                            }}
                                        />
                                    </Col>
                                    {_.lte(contextLayout?.width ?? 0, 768) && (
                                        <>
                                            <Col xs={24}>
                                                <Typography as="div" className="ch-container">
                                                    <Form.Item name="type" noStyle>
                                                        <CheckboxGroup>
                                                            <Checkbox value="nps">
                                                                {t("Активации с НПС")}
                                                            </Checkbox>
                                                            <Checkbox value="free,bonus">
                                                                {t("Личные")}
                                                            </Checkbox>
                                                            <Checkbox value="super_link">
                                                                {t("Активации по суперссылке")}
                                                            </Checkbox>
                                                        </CheckboxGroup>
                                                    </Form.Item>
                                                </Typography>
                                            </Col>
                                            <Col xs={24} className="reset">
                                                <Button
                                                    type="text"
                                                    icon={<Icon name="reset" size="16" />}
                                                    onClick={() => {
                                                        formInstance.resetFields();
                                                        ref.current
                                                            ?.querySelector(
                                                                ".field-range-picker-container"
                                                            )
                                                            ?.classList.remove("focused");
                                                    }}
                                                >
                                                    {t("Сбросить фильтры")}
                                                </Button>
                                            </Col>
                                        </>
                                    )}
                                    <Col xs={24} lg={5}>
                                        <Form.Item noStyle shouldUpdate>
                                            {() => {
                                                return (
                                                    <Button type="primary" htmlType="submit">
                                                        {t("Показать номера")}
                                                    </Button>
                                                );
                                            }}
                                        </Form.Item>
                                    </Col>
                                    {_.gt(contextLayout?.width ?? 0, 768) && (
                                        <Col xs={24} className="reset">
                                            <Button
                                                type="text"
                                                icon={<Icon name="reset" size="16" />}
                                                onClick={() => {
                                                    formInstance.resetFields();
                                                    ref.current
                                                        ?.querySelector(
                                                            ".field-range-picker-container"
                                                        )
                                                        ?.classList.remove("focused");
                                                }}
                                            >
                                                {t("Сбросить фильтры")}
                                            </Button>
                                        </Col>
                                    )}
                                </Row>
                            </Form>
                        </Collapse.Panel>
                    </Collapse>
                </Typography>
            </div>
        );
    })
);

interface IProps {
    updateList: (request?: IReservationsRequest, loading?: boolean, reset?: boolean) => void;
    setMeta: React.Dispatch<React.SetStateAction<IMeta | undefined>>;
    setFilterRequest: React.Dispatch<React.SetStateAction<IReservationsRequest | undefined>>;
    setItems: React.Dispatch<React.SetStateAction<IReservations[]>>;
    setShimmer: React.Dispatch<React.SetStateAction<boolean>>;
}
