import {useRef} from "react";
import {Form, Typography, DatePicker} from "@bezlimit/bezlimit-ui";
import {RangeValue} from "rc-picker/lib/interface";
import {FormItemProps} from "antd";
import _ from "lodash";
import {Moment} from "moment";

import "./style.scss";

const options = {
    allowClear: true,
    format: "DD.MM.YYYY",
    separator: "-"
};
const {RangePicker} = DatePicker;

export default function RangePickerField({props, onChange}: IProps) {
    const ref = useRef(null);

    const change = (dates: RangeValue<Moment>, dateStrings: [string, string]) => {
        if (!dates && ref.current) {
            (ref.current as any)
                .querySelector(".field-range-picker-container")
                .classList.remove("focused");
        }

        if (onChange) {
            onChange(dates);
        }
    };

    return (
        <div ref={ref}>
            <Typography as="div" className="field-range-picker-container">
                <Form.Item {...props} className="field-range-picker">
                    <RangePicker
                        {...options}
                        onFocus={(option: {target: any}) => {
                            (option.target as any)
                                .closest(".field-range-picker-container")
                                .classList.add("focused");
                        }}
                        onBlur={(option: {target: any}) => {
                            const target = option.target as any;
                            if (!_.size(_.trim(target.value))) {
                                target
                                    .closest(".field-range-picker-container")
                                    .classList.remove("focused");
                            }
                        }}
                        dropdownClassName="dropdown-date-picker-field"
                        placeholder={["", ""]}
                        // disabled={context?.loadingBtn}
                        onChange={change}
                    />
                </Form.Item>
            </Typography>
        </div>
    );
}

interface IProps {
    props: FormItemProps<any>;
    onChange?: (values: any) => void;
}
