import React from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import cn from "classnames";
import "./style.scss";

export default React.memo(function Text({text, className}: IProps) {
    return (
        <Typography
            as="div"
            color="#EFCE73"
            size={24}
            fontFamily="alumna"
            className={cn("bl-numbers-in-booting-duration-text",  "gold", className ?? '')}
        >
            {text}
        </Typography>
    );
});

interface IProps {
    text: string;
    className?: string;
}
