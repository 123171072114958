import React from "react";
import {Block, Row, Col, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import "./style.scss";
// services
import BonusesService from "../../../../services/BonusesService";
// components
import Progress from "../../widgets/myBonuses/progress";
import Accumulated from "../../widgets/myBonuses/accumulated";
import Accrued from "../../widgets/myBonuses/accrued";
import Rate, {Rates} from "../rate";
//store
import Store from "../../../../stores";

export default React.memo(
    observer(function MyBonuses() {
        const {t} = useTranslation();

        return (
            <Typography as="div" className="bl-mybonuses">
                <Block>
                    <Row gutter={[24, 24]}>
                        <Col lg={6} md={6} xs={24} className="bonuses-progress">
                            <Progress />
                        </Col>
                        <Col lg={8} md={8} xs={24}>
                            <Row gutter={[16, 24]}>
                                <Col lg={24} md={24} xs={12} className="bonuses-counts">
                                    <Accumulated />
                                </Col>
                                <Col lg={24} md={24} xs={12} className="bonuses-counts">
                                    <Accrued />
                                </Col>
                            </Row>
                        </Col>
                        <Col lg={10} md={10} xs={24}>
                            <Row gutter={[16, 0]}>
                                <Col span={24} className="bonuses-future-payment">
                                    <Typography className="sum">
                                        {BonusesService.futurePaymentSum > 0 &&
                                            BonusesService.futurePaymentSum}
                                    </Typography>
                                    <Typography className="label">
                                        {BonusesService.futurePaymentSum > 0 &&
                                            t("Ближайшее начисление")}
                                    </Typography>
                                </Col>
                                {Store.user.level?.level < 2 && (
                                    <Col lg={24} md={24} xs={12} className="col-bonuses-rate">
                                        <Rate rateType={Rates.level} />
                                    </Col>
                                )}
                                {Store.user.level?.level < 2 && (
                                    <Col lg={24} md={24} xs={12} className="col-bonuses-rate">
                                        <Rate rateType={Rates.personal} />
                                    </Col>
                                )}
                                {Store.user.level?.level >= 2 && (
                                    <Col span={24}>
                                        <Rate rateType={Rates.levelTwo} />
                                    </Col>
                                )}
                            </Row>
                        </Col>
                    </Row>
                </Block>
            </Typography>
        );
    })
);
