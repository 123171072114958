import {makeAutoObservable} from "mobx";
// core
import {IMeta, IPhone} from "../core/models/Phones";
// core

class Phones {
    [Symbol.toStringTag] = this.constructor.name;
    private _items!: IPhone[] | undefined;
    private _meta!: IMeta | undefined;
    private _selectedRowKeys!: React.Key[];
    private _type: string;
    private _name?: string;

    constructor(type: string, name?: string) {
        this._type = type;

        if (name) {
            this._name = name;
        }
        makeAutoObservable(this);
    }

    get items() {
        return this._items;
    }

    set items(value: IPhone[] | undefined) {
        this._items = value;
    }

    get meta() {
        return this._meta;
    }

    set meta(value: IMeta | undefined) {
        this._meta = value;
    }

    get selectedRowKeys() {
        return this._selectedRowKeys;
    }

    set selectedRowKeys(value: React.Key[]) {
        this._selectedRowKeys = value;
    }

    get type() {
        return this._type;
    }

    get name() {
        return this._name;
    }
}

export default Phones;
