import {useContext} from "react";
import {Collapse, Block, Row, Col, Icon, Typography, Button} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import moment from "moment";
import _ from "lodash";
//core
import {LayoutContext} from "../../../../core/Contexts";
import {ModalContext} from "../../../../core/Contexts";
// store
import Store from "../../../../stores";
//component
import {ILevel} from "../../../../core/models/Levels";
import RestoreAccess from "../../../modal/restoreAccess";
import {observer} from "mobx-react";

const DealerInfoHeader = observer((props: ILevel): JSX.Element => {
    const {t} = useTranslation();
    const {login, name, phone, user_id} = props;

    return (
        <Row gutter={[16, {xs: 24, sm: 24, md: 24, lg: 32}]}>
            <Col xs={24} lg={12}>
                <Typography className="dealer-info-name">{name}</Typography>
                {!_.isEqual(Store.user?.level?.level, 1) && (
                    <Typography className="dealer-info-header-label">{`(ID в биллинге: ${user_id})`}</Typography>
                )}
            </Col>
            <Col xs={12} lg={4}>
                <Typography className="dealer-info-header-label">{t("Статус")}</Typography>
                <Typography>
                    {_.isEqual(props.loyalty?.code, "bronze") && (
                        <Icon name="loyalty_bronze" size="19" />
                    )}
                    {_.isEqual(props.loyalty?.code, "silver") && (
                        <Icon name="loyalty_silver" size="19" />
                    )}
                    {_.isEqual(props.loyalty?.code, "gold") && (
                        <Icon name="loyalty_gold" size="19" />
                    )}
                    {_.isEqual(props.loyalty?.code, "top") && <Icon name="loyalty_top" size="19" />}
                    {" " + props.loyalty?.name}
                </Typography>
            </Col>
            <Col xs={12} lg={4}>
                <Typography className="dealer-info-header-label">{t("Логин")}</Typography>
                <Typography>{login}</Typography>
            </Col>
            {phone && (
                <Col xs={12} lg={4}>
                    <Typography className="dealer-info-header-label">{t("Телефон")}</Typography>
                    <Typography>{phone}</Typography>
                </Col>
            )}
        </Row>
    );
});

const getDate = (date: string, width: number | undefined): string => {
    if (!date) {
        return "";
    }

    const dateDesk = moment(`${date}`).format("DD MMMM YYYY");
    const dateMobile = moment(`${date}`).format("DD.MM.YYYY");

    return width && width > 768 ? dateDesk : dateMobile;
};

export const DealerInfo = (props: ILevel): JSX.Element => {
    const {t} = useTranslation();
    const {Panel} = Collapse;
    const context = useContext(LayoutContext);
    const contextModal = useContext(ModalContext);
    const {activation, name, id} = props;
    const date = getDate(activation?.last_date, context?.width);

    const onRestore = (name: string, id: number) => {
        contextModal?.setVisible(true);
        contextModal?.setChildren(<RestoreAccess {...props} />);
    };

    return (
        <Block className="bl-dealer-info">
            <Collapse ghost className="dealer-info-collapse" expandIconPosition="end">
                <Panel header={<DealerInfoHeader {...props} />} key="1">
                    <Row gutter={[16, 16]}>
                        <Col xs={12} lg={5}>
                            <Typography className="dealer-info-label">
                                {t("Активаций всего")}
                            </Typography>
                            <Typography className="dealer-info-activation gold">
                                {activation?.total_cnt ?? 0}
                            </Typography>
                        </Col>
                        <Col xs={12} lg={7}>
                            <Typography className="dealer-info-label">
                                {t("Активаций в текущем месяце")}
                            </Typography>
                            <Typography className="dealer-info-activation gold">
                                {activation?.current_month_cnt ?? 0}
                            </Typography>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Typography className="dealer-info-label">
                                {t("За последние 30 дней")}
                            </Typography>
                            <Typography className="dealer-info-activation gold">
                                {activation?.last_month_personal_cnt ?? 0}
                            </Typography>
                        </Col>
                        <Col xs={12} lg={6}>
                            <Typography className="dealer-info-label">
                                {t("За прошлый месяц")}
                            </Typography>
                            <Typography className="dealer-info-activation gold">
                                {activation?.previous_month_cnt ?? 0}
                            </Typography>
                        </Col>
                        {date && (
                            <Col xs={24} lg={9}>
                                <Typography className="dealer-info-label">
                                    {t("Дата последней активации")}
                                </Typography>
                                <Typography className="dealer-info-date">{date}</Typography>
                            </Col>
                        )}
                        <Col span={24}>
                            <Button
                                style={{width: "100%"}}
                                type="default"
                                onClick={() => {
                                    onRestore(name, id);
                                }}
                                disabled={!props.phone}
                            >
                                {t("Восстановить доступ")}
                            </Button>
                        </Col>
                    </Row>
                </Panel>
            </Collapse>
        </Block>
    );
};
