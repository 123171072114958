import {Col, Icon, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
import {Moment} from "moment";
//core
import {IBonusesSummaryPaymentsResponse} from "../../../../../core/models/Bonus";

export default function TitlePayments({month, summary}: IProps) {
    const {t} = useTranslation();

    return (
        <>
            {!_.isEqual(summary.sum, 0) ? (
                <Typography as="div" className="colapse-payments-group colapse-payouts-group">
                    <Row>
                        <Col md={6} xs={12}>
                            <Typography as="div" size={17} className="date-colapse">
                                {_.startCase(month.format("MMMM YYYY"))}
                            </Typography>
                        </Col>
                        <Col md={6} xs={0}>
                            {_.isEqual(summary.cnt, 0) ? (
                                ""
                            ) : (
                                <Typography size={17} className="accruals-size-colapse">
                                    {t("count_number_payments", {
                                        count: summary.cnt
                                    })}
                                </Typography>
                            )}
                        </Col>
                        <Col md={4} xs={0}></Col>
                        <Col md={0} xs={12}>
                            {_.isEqual(summary.cnt, 0) ? (
                                ""
                            ) : (
                                <Typography size={17} className="accruals-size-colapse-mobile">
                                    {t("count_number_payments", {
                                        count: summary.cnt
                                    })}
                                </Typography>
                            )}
                        </Col>
                        <Col md={0} xs={12}>
                            <Typography as="div" className="total-sum-payout ">
                                <Space>
                                    <Typography
                                        as="div"
                                        size={24}
                                        className="sum-mobile-payout-colapse"
                                    >
                                        <Typography
                                            as="div"
                                            size={14}
                                            color="rgba(255, 255, 255, 0.5)"
                                            className="sum-mobile-colapse-subtitle"
                                        >
                                            {t("Накоплено")}
                                        </Typography>
                                        <Typography
                                            size={24}
                                            appearance="medium"
                                            className="sum-bold"
                                        >
                                            <NumberFormat
                                                thousandSeparator=" "
                                                displayType="text"
                                                value={summary.sum.toFixed(0)}
                                            />
                                        </Typography>
                                    </Typography>
                                </Space>
                            </Typography>
                        </Col>
                        <Col md={8} xs={0}>
                            <Typography as="div" className="total-sum-payout ">
                                <Space>
                                    <Typography
                                        as="div"
                                        size={24}
                                        className="sum-mobile-payout-colapse"
                                    >
                                        <Typography
                                            as="div"
                                            size={14}
                                            color="rgba(255, 255, 255, 0.5)"
                                            className="sum-mobile-colapse-subtitle"
                                        >
                                            {t("Накоплено")}
                                        </Typography>
                                        <Typography
                                            size={24}
                                            appearance="medium"
                                            className="sum-bold"
                                        >
                                            <NumberFormat
                                                thousandSeparator=" "
                                                displayType="text"
                                                value={summary.sum.toFixed(0)}
                                            />
                                        </Typography>
                                    </Typography>
                                    <Typography>
                                        <Icon name="Polygon5" size="8" />
                                    </Typography>
                                </Space>
                            </Typography>
                        </Col>
                    </Row>
                </Typography>
            ) : null}
        </>
    );
}

interface IProps {
    month: Moment;
    summary: IBonusesSummaryPaymentsResponse;
}
