import {Skeleton, Space, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";

export default function ShimmerStatistic() {
    return (
        <Typography as="div" className="bl-group-statistics-shimmer">
            <Typography as="div" className="group-coll">
                <Typography as="div">
                    <Typography as="div" className="group-count">
                        <Space direction="horizontal" size={12}>
                            <Skeleton.Button active={true} className="block-1" />
                            <Skeleton.Button active={true} className="block-2" />
                        </Space>
                    </Typography>
                </Typography>
                <Typography as="div">
                    <Typography as="div" className="group-count">
                        <Space direction="horizontal" size={12}>
                            <Skeleton.Button active={true} className="block-1" />
                            <Skeleton.Button active={true} className="block-2" />
                        </Space>
                    </Typography>
                </Typography>
                <Typography as="div">
                    <Typography as="div" className="group-count">
                        <Space direction="horizontal" size={12}>
                            <Skeleton.Button active={true} className="block-1" />
                            <Skeleton.Button active={true} className="block-2" />
                        </Space>
                    </Typography>
                </Typography>
            </Typography>
        </Typography>
    );
}
