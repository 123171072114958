import {Block, Col, Divider, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {Moment} from "moment";
import {RangeValue} from "rc-picker/lib/interface";
import React, {useState} from "react";
//services
import BonusService from "../../../../services/BonusService";
//forms
import OperationHistoryForm from "../../../forms/operationHistory";
//core
import useBonusSummary from "../../../../core/hooks/useBonusSummary";
//components
import Title from "../title";
import Total from "../total";
import List from "../list";
import PayoutsTitle from "./payoutsTitle";
import DefaultBlock from "../defaultBlock";
import BonusesService from "../../../../services/BonusesService";

export default React.memo(function Payouts({activeKey}: IProps) {
    const {t} = useTranslation();
    const [period, setPeriod] = useState<RangeValue<Moment> | null>(null);
    const {summary, data, loading, setData, callback} = useBonusSummary(
        period,
        activeKey,
        "payouts",
        BonusService.getPayoutsSummary
    );

    const onClick = () => {
        callback(period, data, true);
    };

    return (
        <>
            {_.isEqual(summary?.sum, 0) && _.isEqual(period, null) ? (
                <Typography className="text-content-null">{t("Списаний не найдено")}</Typography>
            ) : _.isEqual(summary?.sum, 0) && !_.isEqual(period, null) ? (
                <DefaultBlock
                    setPeriod={setPeriod}
                    title="За выбранный период списаний не найдено"
                    subtitle=""
                />
            ) : (
                <Block className="bl-accrual-history">
                    <Typography as="div" className="header-bl-operations-histoty">
                        <Row>
                            <Col md={0} xs={24}>
                                <Typography as="div" className="title-mobile">
                                    <Title>История списаний</Title>
                                </Typography>
                            </Col>
                            <Col xs={24} md={{span: 12, push: 12}}>
                                <Typography as="div" className="total-sum-amount">
                                    <Space size={0}>
                                        <Total title="Сумма списаний" sum={summary?.sum ?? 0} />
                                    </Space>
                                </Typography>
                            </Col>
                            <Col xs={24} md={{span: 12, pull: 12}}>
                                <Typography as="div" className="title-and-datapicker">
                                    <Space size={16} direction="vertical">
                                        <Typography as="div" className="title-desctop">
                                            <Title>История списаний</Title>
                                        </Typography>
                                        <OperationHistoryForm
                                            label="Период списаний"
                                            activeKey={activeKey}
                                            set={setPeriod}
                                        />
                                    </Space>
                                </Typography>
                            </Col>
                        </Row>
                    </Typography>
                    <PayoutsTitle />
                    <Divider />

                    {_.size(data) && (
                        <List
                            start={BonusesService.firstPayoutAt}
                            period={period}
                            data={data}
                            activeKey={activeKey}
                            loading={loading}
                            setData={setData}
                            onClick={onClick}
                            fn={BonusService.getPayoutsItems}
                        />
                    )}
                </Block>
            )}
        </>
    );
});

interface IProps {
    activeKey: string;
}
