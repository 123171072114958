import {Form, Input} from "@bezlimit/bezlimit-ui";
import {FormItemProps} from "antd";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
// rules
import {Rules} from "../../forms/rules";

export default function PhoneField({title, props}: IProps) {
    const {t} = useTranslation();

    return (
        <Form.Item
            {...props}
            hasFeedback
            className={props?.className ?? "field-phone"}
            name="phone"
            rules={[Rules.required()]}
        >
            <NumberFormat<any>
                customInput={Input}
                autoComplete="new-phone"
                format={(value) => {
                    if (_.isEqual(value[0], "7") || _.isEqual(value[0], "8")) {
                        value = value.substring(1);
                    }
                    const code = value.substring(0, 3);
                    const nums1 = value.substring(3, 6);
                    const nums2 = value.substring(6, 8);
                    const nums3 = value.substring(8, 10);

                    return `+7 ${code}${nums1 ? ` ${nums1}` : ""}${nums2 ? ` ${nums2}` : ""}${
                        _.gt(_.size(value), 8) ? `-${nums3}` : ""
                    }`;
                }}
                title={title ? title : t("Номер телефона")}
            />
        </Form.Item>
    );
}

interface IProps extends FormItemProps<any> {
    title?: any;
    props?: FormItemProps<any>;
}
