import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Block, Space, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import _ from "lodash";
// forms
import EditPhoneForm from "../../../forms/editPhone";
import ConfirmPhoneForm from "../../../forms/confirmPhone";
// core
import {LayoutContext} from "../../../../core/Contexts";
//store
import Store from "../../../../stores";
import "./style.scss";

export const ConfirmPhone: React.FC = observer(() => {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);
  
    return (
        <Block className="bl-confirm-phone">
            <Space
                size={_.gte(contextLayout?.width ?? 0, 768) ? 40 : 24}
                direction="vertical"
                align="start"
            >
                <Typography as="div">
                    <Space size={8} direction="vertical" align="start">
                        <Typography
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                            level={2}
                            color="#ffffff"
                        >
                            {t("Подтвердите номер телефона")}
                        </Typography>
                        <Typography className="subtitle">
                            {t("Подтвердите свой номер телефона – укажите контактный номер компании Безлимит, и вы будете получать дополнительные 10% комиссии за активацию номеров.")}
                        </Typography>
                    </Space>
                </Typography>
                {!Store.user.personal_data?.phone && <EditPhoneForm blur={true}/>}
                {Store.user.personal_data?.phone && <ConfirmPhoneForm phone={Store.user.personal_data.phone}/>}
            </Space>
        </Block>
    );
});
