import React, {useContext} from "react";
import {Space, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import "./style.scss";
// services
import PhoneService from "../../../../../../services/PhoneService";
// components
import NumbersList from "../../../../../blocks/numbers/list/default";
// core
import {LayoutContext} from "../../../../../../core/Contexts";
import {MobXProviderContext} from "mobx-react";
import Collection from "../../../../../blocks/numbers/list/default/collection";

export default React.memo(function DefaultList({objects, ...props}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const {store} = useContext(MobXProviderContext);

    return (
        <Typography as="div" className="bl-numbers-list-default">
            <Space direction="vertical" size={_.gte(contextLayout?.width ?? 0, 768) ? 64 : 48}>
                {store.numberFilters.isCollection ? (
                    <Collection isFavorite={props.isFavorite} setIsFavorite={props.setIsFavorite} />
                ) : (
                    _.chain(objects)
                        .orderBy(
                            ({type}) => _.indexOf(["standard", "paid", "internet"], type),
                            "asc"
                        )
                        .map((i) => <NumbersList {...props} key={i.type} Service={i} />)
                        .valueOf()
                )}
            </Space>
        </Typography>
    );
});

interface IProps {
    objects: PhoneService[];
    phone: string | undefined;
    type: string[];
    tariff: string[];
    categories: string[];
    region: string[];
    pattern: string[];
    gridType: string;
    isAllChecked: boolean;
    sort: string;
    phonePattern: string[];
    phoneCombs: string | undefined;
    isFavorite: boolean;
    setIsFavorite: React.Dispatch<React.SetStateAction<boolean>>;
    setType: React.Dispatch<React.SetStateAction<string[]>>;
    setTariff: React.Dispatch<React.SetStateAction<string[]>>;
    setCategories: React.Dispatch<React.SetStateAction<string[]>>;
    setRegion: React.Dispatch<React.SetStateAction<string[]>>;
    setPattern: React.Dispatch<React.SetStateAction<string[]>>;
    setAllIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
    setPhone: React.Dispatch<React.SetStateAction<string | undefined>>;
    setGridType: React.Dispatch<React.SetStateAction<string>>;
    setGridTypePrev: React.Dispatch<React.SetStateAction<string>>;
    setSort: React.Dispatch<React.SetStateAction<string>>;
    setCssClassGrouped: React.Dispatch<React.SetStateAction<boolean>>;
    setPhonePattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhoneCombs: React.Dispatch<React.SetStateAction<string | undefined>>;
}
