import React, {ReactFragment, ReactNode, useContext, useEffect, useState} from "react";
import {Typography, Space, Button, Form, Modal} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext, ModalContext} from "../../../core/Contexts";
import Dots from "../../../images/dot.png";
import SBP from "../../../images/sbp_payment.png";
import AutopaymentSBP from "../../blocks/widgets/paymentReservedCard/autopaymentSBP";
import PaymentForNumberBonuses from "../paymentForNumberBonuses";
import useFormHandler from "../../../core/hooks/useFormHandler";
import {IReservations} from "../../../core/models/Reservations";
import AutopaymentDefaultCreate from "../../blocks/widgets/paymentReservedCard/autopaymentDefaultCreate";
import UserCardService from "../../../services/UserCardService";
import NumberFormat from "react-number-format";
import {isMobile} from "react-device-detect";
import SuccessImg from "../../images/successImg";
import ErrorImg from "../../images/errorImg";
import {useNavigate} from "react-router-dom";
import ReplenishmentOfBalanceBonuses from "../replenishmentOfBalanceBonuses";

type TContent = {
    [key: string]: {
        title: string;
        footer: Array<ReactNode>;
        children: ReactFragment | ReactNode;
    };
};

export default React.memo(
    observer(function NotBonuses({
        isMainPage,
        visible,
        cancelHandler,
        number,
        tariffName,
        price,
        activeKey,
        hashSuccess,
        recommend_sum,
        setItems
    }: IProps) {
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const {t} = useTranslation();
        const [state, setState] = useState<"default" | "defaultCreate" | "success" | "error">(
            "default"
        );
        const [apiErrors, setApiErrors] = useState(null);
        const [initForm] = Form.useForm();
        const form = useFormHandler(initForm);
        const navigate = useNavigate();

        const cardOrBonusesClickHandler = (key: string) => {
            switch (key) {
                case "card":
                    setState("defaultCreate");
                    break;
                case "sbp":
                    cancelHandler();
                    contextModal?.setVisible(true);
                    contextModal?.setChildren(
                        <AutopaymentSBP
                            number={number}
                            isMainPage={isMainPage}
                            form={form}
                            tariffName={tariffName}
                            cancelHandler={cancelHandler}
                            setApiErrors={setApiErrors}
                            setState={setState}
                        />
                    );
                    break;
                case "bonuses":
                    cancelHandler();
                    contextModal?.setVisible(true);
                    isMainPage
                        ? contextModal?.setChildren(<ReplenishmentOfBalanceBonuses />)
                        : contextModal?.setChildren(
                              <PaymentForNumberBonuses
                                  phone={number!}
                                  tariffName={tariffName!}
                                  price={price!}
                                  activeKey={activeKey!}
                                  setItems={setItems!}
                                  recommend_sum={recommend_sum}
                              />
                          );
                    break;

                default:
                    break;
            }
        };

        const onCloseModal = () => {
            cancelHandler();
            setState("default");
            form.resetFields();
            navigate(
                `/${
                    window.location.href
                        .split("/")
                        [window.location.href.split("/").length - 1].split("?")[0]
                        .split("#")[0]
                }`
            );
        };

        const onFinish = async (props: {
            card_id: string | number;
            phone: string | number;
            sum_payment: string | number;
        }) => {
            try {
                const phone = isMainPage
                    ? +_.replace(props.phone.toString(), /(\+7|\s|-)/gi, "")
                    : number;
                await UserCardService.postBankCardPayment(
                    +props.card_id,
                    phone!,
                    +props.sum_payment
                );
                setState("success");
            } catch (err: any) {
                if (!!err.response.data.name) {
                    setApiErrors(err.response.data.message);
                    setState("error");
                }
            }
        };

        useEffect(() => {
            if (hashSuccess) {
                setState("defaultCreate");
            }
        }, [hashSuccess]);

        const content: TContent = {
            default: {
                title: t("Недостаточно бонусов \nдля оплаты номера"),
                footer: [
                    <Button
                        type="primary"
                        className="btn-payment-card"
                        onClick={() => cardOrBonusesClickHandler("card")}
                    >
                        Оплатить картой
                    </Button>,
                    <Button
                        type="default"
                        className="btn-payment-sbp"
                        onClick={() => cardOrBonusesClickHandler("sbp")}
                    >
                        Оплатить через СБП
                        <img src={SBP} width={20} alt="" />
                    </Button>
                ],
                children: (
                    <>
                        <Typography as="div" className="bl-not-bonuse">
                            <Space
                                size={_.gte(contextLayout?.width ?? 0, 768) ? 40 : 24}
                                direction="vertical"
                                align="center"
                            >
                                <Typography as="div">
                                    <Space size={20} direction="vertical" align="center">
                                        <Typography
                                            as="div"
                                            className="subtitle"
                                            color="#000"
                                            size={20}
                                        >
                                            {t(
                                                "Вы можете пополнить баланс номера \nдругими удобными способами:"
                                            )}
                                        </Typography>
                                        <Typography
                                            as="div"
                                            color="#000"
                                            size={18}
                                            className="list-payment"
                                        >
                                            <img src={Dots} width={8} alt="" />
                                            {t(
                                                "Используя Сбербанк онлайн, выбрав оператора “Безлимит” в разделе Мобильная связь"
                                            )}
                                        </Typography>
                                        <Typography
                                            as="div"
                                            color="#000"
                                            size={18}
                                            className="list-payment"
                                        >
                                            <img src={Dots} width={8} alt="" />
                                            {t(
                                                "Через терминалы оплаты - выбирая оператора “Безлимит”"
                                            )}
                                        </Typography>
                                        <Typography
                                            as="div"
                                            color="#000"
                                            size={18}
                                            className="list-payment"
                                        >
                                            <img src={Dots} width={8} alt="" />
                                            {t(
                                                "Банковской картой через банкоматы МКБ и Сбербанк выбирая оператора “Безлимит”"
                                            )}
                                        </Typography>
                                        <Typography
                                            as="div"
                                            color="#000"
                                            size={18}
                                            className="list-payment"
                                        >
                                            <img src={Dots} width={8} alt="" />
                                            {t("Банковской картой на нашем сайте: ")}
                                            <a
                                                href={" https://bezlimit.ru/payment"}
                                                target={"_blank"}
                                                rel="noreferrer"
                                            >
                                                Пополнить баланс - “Безлимит“
                                            </a>
                                        </Typography>
                                        <Typography
                                            as="div"
                                            color="#000"
                                            size={18}
                                            className="list-payment"
                                        >
                                            <img src={Dots} width={8} alt="" />
                                            {t("Оплатить банковской картой в приложении")}
                                        </Typography>
                                    </Space>
                                </Typography>
                            </Space>
                        </Typography>
                    </>
                )
            },
            defaultCreate: {
                title: isMainPage ? t("Пополнить картой") : t("Оплатить картой"),
                footer: [],
                children: (
                    <AutopaymentDefaultCreate
                        numberPhone={number}
                        isMainPage={isMainPage}
                        onFinish={onFinish}
                        form={form}
                        tariffName={tariffName}
                    />
                )
            },
            success: {
                title: isMainPage ? t("Номер пополнен") : t("Номер активирован"),
                footer: [],
                children: isMainPage ? (
                    <div className="activation-modal--description">
                        {t(`Зачислено ${form.getFieldValue("sum_payment")} ₽`)}
                    </div>
                ) : (
                    <>
                        {number && (
                            <Typography className="removement-modal--number" color="#642878">
                                {
                                    <NumberFormat
                                        value={number}
                                        displayType={"text"}
                                        thousandSeparator={false}
                                        format="### ###-##-##"
                                    />
                                }
                            </Typography>
                        )}
                        <div className="activation-modal--description">
                            {t(
                                "Вы можете совершать звонки и получать SMS сообщения и пользоваться интернетом"
                            )}
                        </div>
                    </>
                )
            },
            error: {
                title: t("Произошла ошибка"),
                footer: [],
                children: (
                    <>
                        {apiErrors ? (
                            <div key={apiErrors} style={{textAlign: "center"}}>
                                {apiErrors}
                            </div>
                        ) : (
                            <div style={{textAlign: "center"}}>{t("Повторите попытку позже")}</div>
                        )}
                    </>
                )
            }
        };

        let ModalImage = null;
        if (state === "success") {
            ModalImage = <SuccessImg />;
        } else if (state === "error") {
            ModalImage = <ErrorImg />;
        }
        const isForPhoneModalType = isMobile && state === "default";

        return (
            <Modal
                centered
                open={visible}
                onCancel={onCloseModal}
                title={content[state].title}
                footer={content[state].footer}
                img={ModalImage}
                forPhone={isForPhoneModalType}
                className="modal-not-bonuses"
            >
                {content[state].children}
            </Modal>
        );
    })
);

interface IProps {
    isMainPage: boolean;
    visible: boolean;
    cancelHandler: () => void;
    number?: number;
    tariffName?: string;
    price?: number;
    activeKey?: string;
    hashSuccess?: boolean;
    recommend_sum?: number;
    setItems?: React.Dispatch<React.SetStateAction<IReservations[]>>;
}
