import {makeAutoObservable} from "mobx";
// core
import {IPhonesFiltersResponse} from "../core/models/Phones";
import {IPhonesCurrentFilters} from "../core/models/PhonesFilters";
// core

class PhonesFilters {
    [Symbol.toStringTag] = this.constructor.name;
    private _filters!: IPhonesFiltersResponse | undefined;
    private _filtersActive!: IPhonesFiltersResponse | undefined;
    private _phone!: string | undefined;
    public general_number?: number[];
    private _isRemovedItem: boolean = false;
    private _currentFilter: IPhonesCurrentFilters = {
        type: ["standard"],
        tariff: [],
        categories: [],
        region: [],
        pattern: [],
        phone_pattern: [],
        phone_combs: "",
        sort: "expensive",
        grid: "list"
    };

    constructor() {
        makeAutoObservable(this);
    }

    get filters() {
        return this._filters;
    }

    set filters(value: IPhonesFiltersResponse | undefined) {
        this._filters = value;
    }

    get filtersActive() {
        return this._filtersActive;
    }

    set filtersActive(value: IPhonesFiltersResponse | undefined) {
        this._filtersActive = value;
    }

    get phone() {
        return this._phone;
    }

    set phone(value: string | undefined) {
        this._phone = value;
    }

    get currentFilter() {
        return this._currentFilter;
    }

    set currentFilter(value: IPhonesCurrentFilters) {
        this._currentFilter = value;
    }

    get isRemovedItem() {
        return this._isRemovedItem;
    }

    set isRemovedItem(value: boolean) {
        this._isRemovedItem = value;
    }
}

export default PhonesFilters;
