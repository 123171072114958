import {makeAutoObservable} from "mobx";
import {ICardItems} from "../core/models/UserCard";

class UserCard {
    [Symbol.toStringTag] = this.constructor.name;
    private _cards: ICardItems[] = [];

    constructor() {
        makeAutoObservable(this);
    }

    get cards() {
        return this._cards;
    }

    set cards(value: ICardItems[]) {
        this._cards = value;
    }
}

export default UserCard;
