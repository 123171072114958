import _ from "lodash";
import {observer} from "mobx-react";
import {useCallback, useContext, useEffect, useRef, useState} from "react";
import printJS from "print-js";
import "./style.scss";
import {Button, Carousel, Col, Icon, Row, Typography} from "@bezlimit/bezlimit-ui";
import {LoadingContext} from "../../../../../../core/Contexts";
import {makeAutoObservable} from "mobx";
import {useTranslation} from "react-i18next";
import {IUrl} from "../../../../../../core/models/User";

export const store: any = makeAutoObservable({
    data: []
});

const Slider: React.FC<IProps> = observer(({data}) => {
    const ref = useRef<HTMLDivElement>(null);
    const contextloading = useContext(LoadingContext);
    const [current, setCurrent] = useState(0);
    const [loadingDownload, setLoadingDownload] = useState(false);
    const {t} = useTranslation();

    const onPrint = () => {
        printJS({printable: store.data[current].url, type: "image"});
    };

    const onDownload = () => {
        setLoadingDownload(true);
        setTimeout(async () => {
            const image = await fetch(store.data[current].url);
            const imageBlog = await image.blob();
            const imageURL = URL.createObjectURL(imageBlog);
            const file = /^.*\/(.*).(jpg|png)$/gi.exec(store.data[current].url);
            const link = document.createElement("a");
            link.href = imageURL;
            link.download = _.isArray(file) && file[1] ? file[1] : "file";
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
            setLoadingDownload(false);
        }, 2000);
    };

    const getData = useCallback((data: IUrl[]) => {
        contextloading?.setLoading(true);
        store.data = data;
    }, []);

    useEffect(() => {
        getData(data);

        const current = document.body.querySelector(".ant-modal-root");

        if (current) {
            current.addEventListener("scroll", (e) => {});
        }

        return () => {
            store.data = [];
        };
    }, []);

    useEffect(() => {
        if (_.isEqual(_.size(data), _.size(store.data))) {
            setTimeout(() => {
                contextloading?.setLoading(false);
            }, 2000);
        }
    }, [store.data.length, data]);

    return (
        <div ref={ref}>
            <Typography as="div" className="bl-slider-superlink">
                <Carousel
                    speed={100}
                    effect="fade"
                    dots={true}
                    arrows={true}
                    infinite={true}
                    adaptiveHeight={true}
                    beforeChange={(currentSlide, nextSlide) => {
                        setCurrent(nextSlide);
                    }}
                >
                    {_.chain(store.data)
                        .map(({url}, k) => <img src={url} key={k} />)
                        .valueOf()}
                </Carousel>
                <Row gutter={[16, 20]} className="actions">
                    <Col xs={24} lg={12}>
                        <Button
                            type="outline"
                            icon={<Icon name="print" size="17" />}
                            onClick={onPrint}
                        >
                            {t("Распечатать плакат")}
                        </Button>
                    </Col>
                    <Col xs={24} lg={12}>
                        <Button
                            type="outline"
                            loading={loadingDownload}
                            icon={<Icon name="dowload2" size="20" />}
                            onClick={onDownload}
                        >
                            {t("Скачать плакат")}
                        </Button>
                    </Col>
                </Row>
            </Typography>
        </div>
    );
});

interface IProps {
    data: IUrl[];
}
export interface IData {
    src: string;
    size: number;
    position: {x: number; y: number};
}
export default Slider;
