import {Col, Row, Typography, Back} from "@bezlimit/bezlimit-ui";
import {useNavigate} from "react-router-dom";
import "./style.scss";
// components
import {Bonuses} from "../../../blocks/bonuses";
import {isMobile} from "react-device-detect";
import Header from "../../../blocks/header";
import {useState} from "react";
import HintMobile from "../../../blocks/widgets/loyalty/hintMobile";

export default function BonusesPage() {
    const navigate = useNavigate();
    const [visibleLoyalty, setVisibleLoyalty] = useState(true);
    const toggle = () => {
        (document.body.querySelector(".paralax-btn") as any)?.click();
    };
    return (
        <>
            {isMobile && <Header toggle={toggle} />}
            {visibleLoyalty ? (
                <Typography as="div" className="bl-bonuses-page">
                    <Row justify="center" gutter={32}>
                        <Back navigate={() => navigate("/")} />
                        <Col lg={{span: 24}} xs={{span: 24}}>
                            <Bonuses setVisibleLoyalty={setVisibleLoyalty} />
                        </Col>
                    </Row>
                </Typography>
            ) : (
                <HintMobile setVisibleLoyalty={setVisibleLoyalty} />
            )}
        </>
    );
}
