import {Collapse, Menu, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {MenuInfo} from "rc-menu/lib/interface";
import "./style.scss";
//images
import apple_icon from "../../../../../images/apple_icon.svg";
import google_play_icon from "../../../../../images/google_play_icon.svg";
import vk from "../../../../../images/social_vk.svg";
import youtobe_icon from "../../../../../images/youtobe_icon.svg";
import telegram_icon from "../../../../../images/telegram_icon.svg";
import ok_icon from "../../../../../images/social_ok.svg";
import rutobe_icon from "../../../../../images/rutobe_icon.svg";
//components
import LabelIconContent from "./labelIconContent";
// store
import Store from "../../../../../stores";

export default observer(function LabelMenuRoute({
    click,
    selectedItem,
    header,
    arrayLabelAndRouteFromTo
}: IProps) {
    const {Panel} = Collapse;
    const {t} = useTranslation();
    const arrayLabelAndRoute = [
        {
            key: "https://apps.apple.com/ru/app/id1495144217",
            label: <LabelIconContent src={apple_icon} label={"App Store"} />
        },
        {
            key: "https://play.google.com/store/apps/details?id=com.bezlimit.store&pli=1",
            label: <LabelIconContent src={google_play_icon} label={"Google Play"} />
        },
        {
            key: "https://vk.com/bezlimit.official",
            label: <LabelIconContent src={vk} label={"ВКонтакте"} />
        },
        {
            key: "https://www.youtube.com/channel/UCs2uaoSV9noMOKhXeLW56kA/featured",
            label: <LabelIconContent src={youtobe_icon} label={"УouTube"} />
        },
        {
            key: "https://rutube.ru/channel/30803606/",
            label: <LabelIconContent src={rutobe_icon} label={"Rutube"} />
        },
        {
            key: " https://t.me/bezlimitofficial_tg",
            label: <LabelIconContent src={telegram_icon} label={"Telegram"} />
        },
        {
            key: "https://ok.ru/bezlimitofficial",
            label: <LabelIconContent src={ok_icon} label={"OK"} />
        }
    ];

    return (
        <Collapse ghost expandIconPosition="end" className="bl-labelMenuRoute">
            <Panel
                header={
                    <Typography as="div" size={14} color="#000000">
                        {t(header)}
                    </Typography>
                }
                key={1}
            >
                <Menu
                    mode="inline"
                    defaultSelectedKeys={["/"]}
                    onClick={click}
                    selectedKeys={[`${selectedItem}`]}
                    items={[
                        ...(Store.user.token
                            ? arrayLabelAndRoute.slice(
                                  arrayLabelAndRouteFromTo[0],
                                  arrayLabelAndRouteFromTo[1]
                              )
                            : [])
                    ]}
                />
            </Panel>
        </Collapse>
    );
});

interface IProps {
    click: (x: MenuInfo) => Promise<null | undefined>;
    selectedItem: string;
    header: string;
    arrayLabelAndRouteFromTo: number[];
}
