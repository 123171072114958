import React, {useContext, useEffect, useState} from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import {Table, ConfigProvider} from "antd";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// components
import Info from "./info";
import Actions from "./actions";
import Empty from "../../../empty";
// core
import {LayoutContext} from "../../../../../core/Contexts";
// services
import PhoneService from "../../../../../services/PhoneService";

export default observer(function NumbersListBooking({gridType, Service}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const [data, setData] = useState<any>([]);

    const columns = [
        {
            dataIndex: "info",
            key: "info",
            title: Service.name ? (
                <Typography as="div" color="#fff" size={17} className="title">
                    {Service.name}
                </Typography>
            ) : (
                ""
            )
        },
        {dataIndex: "actions", key: "actions"}
    ];

    useEffect(() => {
        let res = _.chain(Service.items)
            .filter(({type}) => !_.isEqual(type, 9))
            .uniqBy("phone")
            .valueOf();

        setData(
            _.chain(res.slice(0, 6))
                .reduce<DataType[]>((res, i) => {
                    (res || (res = [])).push({
                        key: i.phone as React.Key,
                        info: <Info {...i} />,
                        actions: <Actions props={i} gridType={gridType} />
                    });
                    return res;
                }, [])
                .valueOf()
        );
    }, [Service.items, Service.meta, gridType]);

    useEffect(() => {
        return () => {
            Service.cleanUp();
        };
    }, []);

    return (
        <Typography
            as="div"
            className={`bl-numbers-list bl-numbers-in-booking ${
                _.gte(contextLayout?.width ?? 0, 768) ? gridType : "kanban"
            }`}
        >
            <ConfigProvider renderEmpty={() => <Empty />}>
                <Table
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        defaultPageSize: Service.perPage * Service.currentPage,
                        pageSize: Service.perPage * Service.currentPage,
                        showTitle: false,
                        showSizeChanger: false,
                        showLessItems: true
                    }}
                />
            </ConfigProvider>
        </Typography>
    );
});

interface DataType {
    key: React.Key;
    info: JSX.Element;
    actions: JSX.Element;
}

interface IProps {
    gridType: string;
    Service: PhoneService;
}
