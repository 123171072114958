import React, {useContext} from "react";
import _ from "lodash";
import errorImgW1x from "../../../images/error@1x.webp";
import errorImgW2x from "../../../images/error@2x.webp";
import errorImg2x from "../../../images/error@2x_2.jpg";
import errorImg from "../../../images/error@1x.png";
//core
import {LayoutContext} from "../../../core/Contexts";

const ErrorImg = ({alt = "error", className = "", ...delegated}) => {
    const context = useContext(LayoutContext);
    const width = _.gte(context?.width ?? 0, 768) ? 97 : 80;

    return (
        <picture>
            <source type="image/webp" srcSet={`${errorImgW1x} 1x, ${errorImgW2x} 2x`} />
            <img
                src={errorImg}
                width={width}
                srcSet={`${errorImg2x} 2x`}
                alt={alt}
                className={className}
                {...delegated}
            />
        </picture>
    );
};

export default ErrorImg;
