import React, {useRef, useState} from "react";
import {Carousel, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {observer} from "mobx-react";
import "./style.scss";
//components
import {LotteryAdvertisement} from "../advertisement";
import {Nps} from "../nps";
import {NewYear} from "../newYearBanner";
// import {LotteryZero} from "../lotteryZero";

export default React.memo(
    observer(function LotterySlider() {
        const ref = useRef<any>(null);
        const [currentIndex, setCurrentIndex] = useState(0);

        return (
            <Typography as="div" className="bl-lottery-widget">
                <Carousel
                    autoplay={true}
                    autoplaySpeed={5000}
                    ref={ref}
                    speed={100}
                    effect="fade"
                    dots={true}
                    dotPosition="bottom"
                    arrows={false}
                    infinite={true}
                    adaptiveHeight={false}
                    onReInit={() => {
                        if (
                            ref.current &&
                            !_.isEqual(
                                _.get(ref.current, "innerSlider.track.props.currentSlide", 0),
                                currentIndex
                            )
                        ) {
                            ref.current.goTo(currentIndex);
                        }
                    }}
                    beforeChange={(currentSlide, nextSlide) => {
                        setCurrentIndex(nextSlide);
                    }}
                >
                    {/* <LotteryZero /> */}
                    <NewYear />
                    <Nps />
                    <LotteryAdvertisement />
                </Carousel>
            </Typography>
        );
    })
);
