import {Layout} from "antd";
import Menu from "./menu";
import "./style.scss";

export default function Sider({collapsed, toggle}: IProps) {
    return (
        <>
            <Layout.Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                collapsedWidth={0}
                width={350}
            >
                <Menu toggle={toggle} />
            </Layout.Sider>
            {!collapsed && <div className="sidebar-drop" onClick={toggle} />}
        </>
    );
}

interface IProps {
    collapsed: boolean;
    toggle: () => void;
}
