import {useContext} from "react";
import {Row, Col} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
// components
import Login from "../../../blocks/login";
import Politics from "../../../blocks/politics";
// core
import {LayoutContext} from "../../../../core/Contexts";

export default function Home() {
    const context = useContext(LayoutContext);

    return (
        <>
            <Row justify="center" gutter={32}>
                <Col lg={{span: 24}} xs={{span: 24}}>
                    <Login />
                </Col>
            </Row>
            {_.lt(context?.width ?? 0, 768) && <Politics />}
        </>
    );
}
