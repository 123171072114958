import React, {useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import _ from "lodash";
import {Block, Button, Col, Typography, Row} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import moment from "moment";
//service
import BonusService from "../../../../services/BonusService";
//components
import ListPayments from "./payments";
//core
import {InterceptorContext} from "../../../../core/Contexts";
//store
// import Store from "../../../../stores";

import "./style.scss";

export default observer(function PaymentsHistory({Service}: IProps) {
    const {t} = useTranslation();
    const context = useContext(InterceptorContext);
    const navigate = useNavigate();
    const [perPage, setPerPage] = useState(12);
    const [btnDisabled, setBtnDisabled] = useState(false);
    const [isLoyalty, setIsLoyalty] = useState(false);
    const date30DaysBefore = moment().add(-30, "days");

    const onClick = () => {
        setPerPage(perPage + 12);
    };

    useEffect(() => {
        const btnDisabledNew = _.lt(
            _.size(
                _.chain(Service.items).filter((i) => moment(i.created_at).isAfter(date30DaysBefore))
            ),
            perPage
        );
        setBtnDisabled(btnDisabledNew);
        setIsLoyalty(
            !!_.chain(Service.items)
                .find(({loyalty}) => !!loyalty)
                .valueOf()
        );
    }, [Service.items?.length, perPage]);

    return (
        <Block className="bl-payments-history">
            <Row gutter={[16, 16]}>
                <Col>
                    <Typography className="payments-title">{t("История начислений")}</Typography>
                    <Typography className="payments-subtitle">
                        {t("Все начисления за последние 30 дней")}
                    </Typography>
                </Col>
                <Col span={24}>
                    <Row>
                        <Col lg={18} md={18} xs={0}></Col>
                        <Col lg={3} md={3} xs={0}>
                            <Typography className="payments-col-title">
                                {t("Накопления")}
                            </Typography>
                        </Col>
                        <Col lg={3} md={3} xs={0}>
                            <Typography className="payments-col-title last">
                                {t("Начисления")}
                            </Typography>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row>
                        {_.chain(Service.items)
                            .filter((i) => moment(i.created_at).isAfter(date30DaysBefore))
                            .filter((i, key) => _.lt(key, perPage))
                            .map((item, key) => (
                                <React.Fragment key={key}>
                                    <Col span={24}>
                                        <ListPayments {...item} isLoyalty={isLoyalty} />
                                    </Col>
                                </React.Fragment>
                            ))
                            .valueOf()}
                    </Row>
                </Col>
                <Col lg={12} xs={24}>
                    <Typography as="div" className="btn-show">
                        <Button
                            block
                            className="btn-show-more"
                            loading={context?.loadingBtn}
                            disabled={btnDisabled}
                            onClick={onClick}
                        >
                            {t("Показать еще")}
                        </Button>
                    </Typography>
                </Col>
                <Col lg={12} xs={24}>
                    <Typography as="div">
                        <Button type="primary" block onClick={() => navigate("/history")}>
                            {t("История операций")}
                        </Button>
                    </Typography>
                </Col>
            </Row>
        </Block>
    );
});

interface IProps {
    Service: BonusService;
}
