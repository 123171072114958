// core
import _ from "lodash";
import {axiosFetchBuilder, IAxiosConfig} from "../core/Axios";
import {IItems, INewsCount, INewsRequest, INotificationsResponse} from "../core/models/News";
// store
import Store from "../stores";

enum Path {
    news = "notifications/news",
    newsRead = "notifications/news/read",
    newsCount = "notifications/count"
}

class NotificationsService {
    [Symbol.toStringTag] = this.constructor.name;
    private static readonly news = Store.news;

    static get items() {
        return NotificationsService.news.items;
    }

    static get newsCount() {
        return NotificationsService.news.items;
    }

    static get itemsSize() {
        return _.size(NotificationsService.items);
    }

    static get meta() {
        return NotificationsService.news.meta;
    }

    static get perPage() {
        return NotificationsService.news.meta?.perPage ?? 9;
    }

    static get totalCount() {
        return NotificationsService.news.meta?.totalCount ?? 0;
    }

    static get currentPage() {
        return NotificationsService.news.meta?.currentPage ?? 1;
    }

    static get hasItems() {
        return _.gt(NotificationsService.totalCount, NotificationsService.itemsSize);
    }

    static async init() {
        await Promise.all([await NotificationsService.fetchNews()]);
    }

    static async getNewsById(id: string): Promise<IItems> {
        return await axiosFetchBuilder({
            url: `${Path.news}/${id}`,
            params: {
                source: "web"
            }
        } as IAxiosConfig);
    }

    static async postRead(id: string) {
        await axiosFetchBuilder({
            url: Path.newsRead,
            method: "POST",
            data: {id},
            loading: false
        } as IAxiosConfig);
    }

    private static async getNews(
        request?: INewsRequest,
        loading: boolean = true
    ): Promise<INotificationsResponse> {
        const res: INotificationsResponse = await axiosFetchBuilder({
            url: Path.news,
            loading,
            tag: "update",
            params: {
                ...request,
                source: "web",
                per_page: NotificationsService.perPage
            }
        } as IAxiosConfig);
        NotificationsService.news.meta = (res as any)._meta;
        return res;
    }

    static async getNewsCount() {
        const res: INewsCount = await axiosFetchBuilder({
            url: Path.newsCount
        });
        NotificationsService.news.newsCount = res.count;
    }

    static async fetchNews() {
        const res: INotificationsResponse = await NotificationsService.getNews();
        NotificationsService.news.items = res.items;
    }

    static async loadMoreNews(request: INewsRequest) {
        const res: INotificationsResponse = await NotificationsService.getNews(request, false);
        NotificationsService.news.items = [...NotificationsService.news.items, ...res.items];
    }
}

export default NotificationsService;
