import {Col, Row, Back} from "@bezlimit/bezlimit-ui";
import {useNavigate} from "react-router-dom";
import "./style.scss";
// components
import {Support} from "../../../blocks/support";

export default function SupportPage() {
    const navigate = useNavigate();
    return (
        <>
            <Row justify="center" gutter={32}>
                <Back navigate={() => navigate("/")} />
                <Col lg={{span: 24}} xs={{span: 24}}>
                    <Support />
                </Col>
            </Row>
        </>
    );
}
