import {Col, Row, Tabs, Title, Typography, Back} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import {observer} from "mobx-react";
import {useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
// components
import Payments from "../../../blocks/operationHistory/payments";
import Payouts from "../../../blocks/operationHistory/payouts";
// hooks
import useSearchRoute from "../../../../core/hooks/useSearchRoute";
// services
import BonusesService from "../../../../services/BonusesService";
import UserService from "../../../../services/UserService";

export default observer(function OperationHistoryPage() {
    const {t} = useTranslation();
    const [activeKey, setActiveKey] = useState("payments");
    const navigate = useNavigate();
    const route = useSearchRoute(UserService.token);

    const onTabClick = (activeKey: string) => {
        setActiveKey(activeKey);
    };

    useEffect(() => {
        BonusesService.summary();
    }, []);
    return (
        <Typography as="div" className="bl-operations-history">
            <Row>
                <Back navigate={() => navigate("/")} />
                <Col span={24}>
                    <Title className="bl-title" title={_.get(route, "title")} />
                </Col>
                <Col span={24}>
                    <Tabs
                        className="tabs-container"
                        tabPosition="top"
                        activeKey={activeKey}
                        animated={false}
                        centered
                        onTabClick={onTabClick}
                        tabPanes={[
                            {
                                title: t("Начисления"),
                                key: "payments",
                                className: "content tariffs-cards",
                                content: <Payments activeKey={activeKey} />
                            },
                            {
                                title: t("Списания"),
                                key: "payouts",
                                className: "content tariffs-cards",
                                content: <Payouts activeKey={activeKey} />
                            }
                        ]}
                    />
                </Col>
            </Row>
        </Typography>
    );
});
