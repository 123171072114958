import {configure, makeAutoObservable, reaction} from "mobx";
// store
import Bonuses from "./Bonuses";
import Phones from "./Phones";
import PhonesFilters from "./PhonesFilters";
import Reservations from "./Reservations";
import Settings from "./Settings";
import User from "./User";
import Notifications from "./Notifications";
import Controls from "./Controls";
import Tariffs from "./Tariffs";
import Levels from "./Levels";
import Bonus from "./Bonus";
import Loyalty from "./Loyalty";
import Delivery from "./Delivery";
import BonusesLevels from "./BonusesLevels";
import Addresses from "./Addresses";
import LotteryPhones from "./LotteryPhones";
import Lottery from "./Lottery";
import CollectionFilters from "./CollectionFilters";
import NumbersFiltersStore from "./NumbersFiltersStore";
import UserCard from "./UserCard";
import Banks from "./Banks";
import BellNotifications from "./BellNotifications";
import Sessions from "./Sessions";

configure({
    enforceActions: "never"
});

export interface IStore {
    settings: Settings;
    user: User;
    userCard: UserCard;
    bonuses: Bonuses;
    bonus: {
        payments: Bonus;
        payouts: Bonus;
    };
    bonusesLevels: BonusesLevels;
    phonesFilters: PhonesFilters;
    phones: {
        default: {
            default: Phones;
            paid: Phones;
            standard: Phones;
            internet: Phones;
        };
        booking: {
            default: Phones;
            paid: Phones;
            standard: Phones;
            internet: Phones;
        };
    };
    reservations: Reservations;
    bellNotifications: BellNotifications;
    news: Notifications;
    tariff: Tariffs;
    controls: Controls;
    levels: Levels;
    loyalty: Loyalty;
    delivery: Delivery;
    addresses: Addresses;
    lottery: Lottery;
    lotteryPhones: LotteryPhones;
    pageLoading: boolean;
    isFavorite: boolean;
    numberFilters: CollectionFilters;
    numbersFiltersStore: NumbersFiltersStore;
    banks: Banks;
    promoCode: string | undefined;
    paymentStatus: number | undefined;
    phone: number | undefined;
    sessions: Sessions;
}

class Store implements IStore {
    settings = new Settings();
    user = new User();
    userCard = new UserCard();
    bonuses = new Bonuses();
    bonus = {
        payments: new Bonus("payments", "Начисления"),
        payouts: new Bonus("payouts", "Списания")
    };
    bonusesLevels = new BonusesLevels();
    phonesFilters = new PhonesFilters();
    reservations = new Reservations();
    phones = {
        default: {
            default: new Phones("default"),
            paid: new Phones("paid", "Платные"),
            standard: new Phones("standard", "Обычные"),
            internet: new Phones("internet", "Интернет")
        },
        booking: {
            default: new Phones("default"),
            paid: new Phones("paid", "Платные"),
            standard: new Phones("standard", "Обычные"),
            internet: new Phones("internet", "Интернет")
        }
    };
    news = new Notifications();
    bellNotifications = new BellNotifications();
    tariff = new Tariffs();
    controls = new Controls();
    levels = new Levels();
    loyalty = new Loyalty();
    delivery = new Delivery();
    addresses = new Addresses();
    lottery = new Lottery();
    lotteryPhones = new LotteryPhones();
    numberFilters = new CollectionFilters();
    numbersFiltersStore = new NumbersFiltersStore();
    banks = new Banks();
    promoCode: string | undefined;
    paymentStatus: number | undefined;
    phone: number | undefined;
    sessions = new Sessions();

    public pageLoading = false;
    public isFavorite = true;

    constructor() {
        makeAutoObservable(this);
        reaction(
            () => Object.keys(Object.getOwnPropertyNames(this)).filter((d) => d !== undefined),
            (data) => {}
        );
    }
}

export default new Store();
