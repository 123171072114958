import {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import {Badge, Form, Input, Typography} from "@bezlimit/bezlimit-ui";
import {FormItemProps, Select} from "antd";
import _ from "lodash";
import "./style.scss";
// core
import {IFilter} from "../../../core/models/Phones";
import {LayoutContext, ModalContext} from "../../../core/Contexts";
// components
import NumbersFilterModal from "../../modal/numbersFilter";

const {Option} = Select;

export default function FakeSelectField({
    name,
    title,
    items,
    count,
    selected,
    disabled,
    set,
    setShouldUpdate,
    ...props
}: IProps) {
    const contextModal = useContext(ModalContext);
    const contextLayout = useContext(LayoutContext);
    const ref = useRef(null);
    const {t} = useTranslation();
    const [open, setIsOpen] = useState(false);

    const onCancel = () => {
        if (_.lte(contextLayout?.width ?? 0, 992)) {
            const el: any = document.body.querySelector(".bl-filter-button .btn-filter");

            if (el) {
                el.click();
            }
        }
    };

    const onFocus = () => {
        if (_.gt(_.size(items), 10)) {
            contextModal?.setOnCancel(() => onCancel);
            contextModal?.setChildren(
                <NumbersFilterModal
                    type={name}
                    title={title}
                    items={items}
                    selected={selected}
                    set={set}
                    setShouldUpdate={setShouldUpdate}
                />
            );
        } else {
            setIsOpen(true);
        }
    };

    const onBlur = (e: any) => {
        setIsOpen(false);
        const el: any = document.body.querySelector(`.hidden-fake-input-${name}`);
        if (el) {
            setTimeout(() => {
                el?.focus();
            }, 0);
        }
    };

    const setReadOnly = () => {
        const el1 = document.getElementById("numbers-filter-mobile_tariff") as HTMLInputElement;
        const el2 = document.getElementById("numbers-filter-mobile_categories") as HTMLInputElement;
        el1.readOnly = true;
        el2.readOnly = true;
    };

    useEffect(() => {
        setReadOnly();
    }, [open]);

    return (
        <div ref={ref}>
            <Form.Item
                {...props}
                className={`field-fake-select ${name}${disabled ? " disabled" : ""}`}
                name={name}
                label={
                    <Typography as="div" color="#828282" size={14}>
                        {title}
                        {!!count && <Badge count={count} />}
                    </Typography>
                }
            >
                <Select
                    mode="multiple"
                    open={open}
                    onBlur={onBlur}
                    onFocus={onFocus}
                    disabled={disabled}
                    dropdownClassName={`dropdown-fake-select ${name}`}
                    getPopupContainer={() =>
                        document.body.querySelector(`.field-fake-select.${name}`)!
                    }
                >
                    {_.chain(items)
                        .map(({name: text, filter}) => (
                            <Option key={filter} value={filter}>
                                <Typography as="div" size={14} color="#000">
                                    {t(text)}
                                </Typography>
                            </Option>
                        ))
                        .valueOf()}
                </Select>
            </Form.Item>
            <Input className={`hidden-fake-input hidden-fake-input-${name}`} />
        </div>
    );
}

interface IProps {
    name: string;
    title: string;
    items: IFilter[];
    selected: string[];
    count?: number;
    props?: FormItemProps<any>;
    disabled?: boolean;
    set: React.Dispatch<React.SetStateAction<string[]>>;
    setShouldUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
}
