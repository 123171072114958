import _ from "lodash";
// store
import Store from "../stores";
// core

import {axiosFetchBuilder, IAxiosConfig} from "../core/Axios";
import {ILotteryPhonesRequest, ILotteryPhonesResponse} from "../core/models/Lottery";

enum Path {
    lotteryPhones = "lottery/phones"
}

class LotteryPhonesService {
    [Symbol.toStringTag] = this.constructor.name;
    private static readonly lotteryPhones = Store.lotteryPhones;

    static get items() {
        return LotteryPhonesService.lotteryPhones.items;
    }

    static get itemsSize() {
        return _.size(LotteryPhonesService.items);
    }

    static get meta() {
        return LotteryPhonesService.lotteryPhones.meta;
    }

    static get perPage() {
        return LotteryPhonesService.lotteryPhones.meta?.perPage ?? 25;
    }

    static get totalCount() {
        return LotteryPhonesService.lotteryPhones.meta?.totalCount ?? 0;
    }

    static get currentPage() {
        return LotteryPhonesService.lotteryPhones.meta?.currentPage ?? 1;
    }

    static get hasItems() {
        return _.gt(LotteryPhonesService.totalCount, LotteryPhonesService.itemsSize);
    }

    private static async getLotteryPhones(
        request?: ILotteryPhonesRequest,
        loading: boolean = true
    ): Promise<ILotteryPhonesResponse> {
        const params = {...request};
        if (!params.per_page) {
            params.per_page = LotteryPhonesService.perPage;
        }
        const res: ILotteryPhonesResponse = await axiosFetchBuilder({
            url: Path.lotteryPhones,
            loading,
            tag: "update",
            params: params
        } as IAxiosConfig);
        LotteryPhonesService.lotteryPhones.meta = (res as any)._meta;
        return res;
    }

    static async fetchlotteryPhones(request?: ILotteryPhonesRequest, loading: boolean = true) {
        const res: ILotteryPhonesResponse = await LotteryPhonesService.getLotteryPhones(
            request,
            loading
        );
        LotteryPhonesService.lotteryPhones.items = res.items;
    }

    static async loadMorelotteryPhones(request?: ILotteryPhonesRequest) {
        const res: ILotteryPhonesResponse = await LotteryPhonesService.getLotteryPhones(
            request,
            false
        );
        LotteryPhonesService.lotteryPhones.items = [
            ...LotteryPhonesService.lotteryPhones.items,
            ...res.items
        ];
    }
}

export default LotteryPhonesService;
