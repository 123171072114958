import {Col, Divider, Row, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import {useTranslation} from "react-i18next";

export default function TitleDeliveries() {
    const {t} = useTranslation();
    return (
        <>
            <Typography as="div" className="title-colapse-deliveries">
                <Row>
                    <Col md={1} xs={0} className="img-status"></Col>
                    <Col md={5} xs={12}>
                        <Typography color="rgba(255, 255, 255, 0.5)">
                            {t("Номер заказа")}
                        </Typography>
                    </Col>
                    <Col md={8} xs={12}>
                        <Typography color="rgba(255, 255, 255, 0.5)">{t("Статус")}</Typography>
                    </Col>
                    <Col md={5} xs={12}>
                        <Typography color="rgba(255, 255, 255, 0.5)">
                            {t("Дата отправки")}
                        </Typography>
                    </Col>
                    <Col md={5} xs={12}>
                        <Typography color="rgba(255, 255, 255, 0.5)">
                            {t("Номер для отслеживания")}
                        </Typography>
                    </Col>
                </Row>
            </Typography>

            <Divider className="divider-title-deliviries" />
        </>
    );
}
