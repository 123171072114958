import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Collapse, Form, Input, SelectField, Space, Typography} from "@bezlimit/bezlimit-ui";
import {Divider, Empty, UploadFile} from "antd";
import {useNavigate, useParams, useSearchParams} from "react-router-dom";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext, ModalContext} from "../../../core/Contexts";
import {IReservations, IReservationsPutRequest} from "../../../core/models/Reservations";
import {toGroupNumbers} from "../../../core/functions/inputNumber";
import {ITariffsAvailable} from "../../../core/models/Tariffs";
import arrow from "../../../images/esimarrow.png";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
// components
import Error from "../../modal/error";
import SimField from "../../fields/sim";
import TariffField from "../../fields/tariff";
import PassportUploadField from "../../fields/passportUpload";
// services
import ReservationsService from "../../../services/ReservationsService";
// ui
import UserService from "../../../services/UserService";
import ButtonChatSupport from "../../blocks/delivery/buttonChatSupport";
import RadioField from "../../fields/radio";
import {Rules} from "../rules";
import {axiosFetchBuilder} from "../../../core/Axios";
import {getFieldsErrorSize} from "../../../core/functions/form";

export default React.memo(
    observer(function BookReservationCreateForm({tariffs, reservations, edit, npsPhone}: IProps) {
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const navigate = useNavigate();
        const ref = useRef<HTMLDivElement>(null);
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        const [prefix] = useState("8970199");
        const [deviceValue, setDeviceValue] = useState(
            _.get(reservations, "additional_data.delivery.esim_device", undefined)
        );
        const [emailValue, setEmailValue] = useState(
            _.get(reservations, "additional_data.delivery.email", undefined)
        );
        const [loading, setLoading] = useState(false);
        const [simType, setSimType] = useState("sim");
        const [options, setOptions] = useState<any[]>([]);
        const {id: tariffId, phone: phone_number} = useParams();
        const [search] = useSearchParams();

        const onClickWhatsApp = () => {
            (
                window.open(`https://wa.me/7${UserService.personalManager.phone}`, "_blank") as any
            ).focus();
        };

        const uploadPhotos = async (
            passport?: UploadFile<any>[],
            passport_second?: UploadFile<any>[]
        ) => {
            try {
                await ReservationsService.postPassportPhoto({
                    type: "passport",
                    phone: +phone_number!,
                    photo: _.head(passport as any[])?.originFileObj
                });
                await ReservationsService.postPassportPhoto({
                    type: "passport_second",
                    phone: +phone_number!,
                    photo: _.head(passport_second as any[])?.originFileObj
                });
            } catch (e: any) {
                throw e;
            }
        };

        const onFinish = async ({
            tariffs,
            multi,
            passport,
            passport_second,
            email,
            type,
            device,
            bookType,
            ...values
        }: IReservationsPutRequest | any) => {
            try {
                const term = bookType === "super" ? 4320 : 0;
                const filter =
                    localStorage.getItem("isFavorite") === "collection"
                        ? "collection"
                        : !!localStorage.getItem("isFavorite")
                        ? "favorite"
                        : "professional";
                const simValues = _.values(_.omitBy(multi, _.isNil)).filter((i) =>
                    _.size(_.trim(i))
                );

                setLoading(true);
                if (_.isEqual(simType, "sim")) {
                    if (edit) {
                        await Promise.all([
                            ReservationsService.putReservation({
                                ...values,
                                ...(!!_.size(simValues) && _.isEqual(_.size(simValues), 11)
                                    ? {sim: prefix + _.join(simValues, "")}
                                    : {}),
                                ...(tariffs ? {tariff_id: tariffs} : {}),
                                ...(!!term ? {term} : {}),
                                additional_data: JSON.stringify({
                                    ...(npsPhone ? {nps: {phone: npsPhone}} : {nps: {phone: ""}})
                                })
                            }),
                            uploadPhotos(passport, passport_second)
                        ]);
                    } else {
                        if (_.isEqual(_.size(simValues), 11)) {
                            await Promise.all([
                                ReservationsService.postReservation({
                                    additional_data: JSON.stringify({
                                        source: "store_web",
                                        filter,
                                        delivery: {
                                            type: "sim",
                                            sim: prefix + _.join(simValues, "")
                                        },
                                        ...(npsPhone ? {nps: {phone: npsPhone}} : {})
                                    }),
                                    phone: phone_number!.toString(),
                                    tariff_id: tariffs!,
                                    ...(!!_.size(simValues) && _.isEqual(_.size(simValues), 11)
                                        ? {sim: prefix + _.join(simValues, "")}
                                        : {}),
                                    ...(!!term ? {term} : {})
                                })
                            ]);
                        } else {
                            await Promise.all([
                                ReservationsService.postReservation({
                                    additional_data: JSON.stringify({
                                        source: "store_web",
                                        filter,
                                        ...(npsPhone ? {nps: {phone: npsPhone}} : {})
                                    }),
                                    phone: phone_number!.toString(),
                                    tariff_id: tariffs!,
                                    ...(!!_.size(simValues) && _.isEqual(_.size(simValues), 11)
                                        ? {sim: prefix + _.join(simValues, "")}
                                        : {}),
                                    ...(!!term ? {term} : {})
                                })
                            ]);
                        }
                    }
                } else {
                    if (edit) {
                        await Promise.all([
                            ReservationsService.putReservationEsim({
                                additional_data: JSON.stringify({
                                    source: "store_web",
                                    filter,
                                    delivery: {email, esim_device: device},
                                    ...(npsPhone ? {nps: {phone: npsPhone}} : {nps: {phone: ""}})
                                }),
                                id: values.id,
                                ...(tariffs ? {tariff_id: tariffs} : {}),
                                ...(!!term ? {term} : {})
                            }),
                            uploadPhotos(passport, passport_second)
                        ]);
                    } else {
                        await Promise.all([
                            ReservationsService.postReservationEsim({
                                additional_data: JSON.stringify({
                                    source: "store_web",
                                    filter,
                                    delivery: {email, esim_device: device},
                                    ...(npsPhone ? {nps: {phone: npsPhone}} : {})
                                }),
                                phone: phone_number!.toString(),
                                tariff_id: tariffs!,
                                ...(!!_.size(simValues) && _.isEqual(_.size(simValues), 11)
                                    ? {sim: prefix + _.join(simValues, "")}
                                    : {}),
                                ...(!!term ? {term} : {})
                            })
                        ]);
                    }
                }
                setTimeout(async () => {
                    try {
                        await uploadPhotos(passport, passport_second);
                        navigate("/reservation");
                    } finally {
                        setLoading(false);
                    }
                }, 3000);
            } catch (e: any) {
                setLoading(false);
                if (_.isEqual(e.response.status, 430)) {
                    contextModal?.setChildren(
                        <Error
                            title="Вы превысили лимит"
                            message={
                                <Space size={16} direction="vertical">
                                    {_.isEqual(UserService.level.level, 0) ? (
                                        <>
                                            <Typography as="div" size={14} color="#000000">
                                                {t("Повторите попытку завтра.")}
                                                <br />
                                                {t("Чтобы увеличить лимит обратитесь в чат")}
                                            </Typography>
                                            <ButtonChatSupport
                                                type="primary"
                                                text="Написать в чат"
                                            />
                                        </>
                                    ) : (
                                        <>
                                            <Typography as="div" size={14} color="#000000">
                                                {t(
                                                    "Повторите попытку завтра. Чтобы увеличить лимит"
                                                )}
                                                <br />
                                                {t("обратитесь к персональному менеджеру")}
                                            </Typography>
                                            <Button onClick={onClickWhatsApp} type="primary">
                                                {t("Написать в WhatsApp")}
                                            </Button>
                                        </>
                                    )}
                                </Space>
                            }
                        />
                    );
                } else {
                    contextModal?.setChildren(<Error />);
                }
                contextModal?.setVisible(true);
            }
        };

        const onValuesChange = (value: any, values: any) => {
            if (_.get(value, "type")) {
                setSimType(value.type);
            } else if (_.has(value, "device")) {
                setDeviceValue(_.get(value, "device"));
            } else if (_.has(value, "email")) {
                setEmailValue(_.get(value, "email"));
            }
        };

        const onSearch = async (value: any) => {
            const res: any = await axiosFetchBuilder({
                url: "/system/esim-devices",
                loading: false,
                params: {
                    query: value
                }
            });
            setOptions(
                _.chain(res.items)
                    .map(({name}) => ({
                        value: name,
                        label: (
                            <Typography as="div" className="wrapper-container">
                                <Typography as="div" className="wrapper">
                                    <Typography as="div" color="#fff" size={17}>
                                        {name}
                                    </Typography>
                                    <Typography as="div" className="ch"></Typography>
                                </Typography>
                                <Divider />
                            </Typography>
                        )
                    }))
                    .valueOf()
            );
        };

        useEffect(() => {
            const f = async () => {
                const res: any = await axiosFetchBuilder({
                    url: "/system/esim-devices",
                    loading: true
                });
                setOptions(
                    _.chain(res.items)
                        .map(({name}) => ({
                            value: name,
                            label: (
                                <Typography as="div" className="wrapper-container">
                                    <Typography as="div" className="wrapper">
                                        <Typography as="div" color="#fff" size={17}>
                                            {name}
                                        </Typography>
                                        <Typography as="div" className="ch"></Typography>
                                    </Typography>
                                    <Divider />
                                </Typography>
                            )
                        }))
                        .valueOf()
                );
            };
            f();
        }, []);

        useEffect(() => {
            toGroupNumbers(ref, "sim", formInstance);

            if (_.isEqual(reservations?.additional_data?.delivery_type, "esim")) {
                setSimType("esim");
            }
        }, []);

        return (
            <div ref={ref}>
                <Typography as="div" className="bl-book-beservation-form">
                    {loading && <Typography as="div" className="opacity-overlay"></Typography>}
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="book-beservation"
                        onFinish={onFinish}
                        onValuesChange={onValuesChange}
                        autoComplete="off"
                        initialValues={{
                            ...(reservations?.id ? {id: reservations.id} : {}),
                            tariff_id: tariffId,
                            tariffs: tariffId ? +tariffId : 0,
                            type: _.isEqual(reservations?.additional_data?.delivery_type, "esim")
                                ? "esim"
                                : "sim",
                            device: _.get(
                                reservations,
                                "additional_data.delivery.esim_device",
                                undefined
                            ),
                            email: _.get(reservations, "additional_data.delivery.email", undefined),
                            ...(edit && !!reservations?.sim
                                ? {
                                      multi: reservations?.sim
                                          ? _.split(_.replace(reservations.sim, prefix, ""))
                                          : "",
                                      ...(!!_.size(_.replace(reservations?.sim, prefix, ""))
                                          ? _.chain(
                                                _.split(
                                                    _.replace(reservations?.sim, prefix, ""),
                                                    ""
                                                )
                                            )
                                                .reduce<any>((res, i, k) => {
                                                    res[`multi${k}`] = i;
                                                    return res;
                                                }, {})
                                                .valueOf()
                                          : {})
                                  }
                                : {multi: []}),
                            ...(UserService.storeCanBookForDelivery
                                ? {bookType: search.get("super") ? "super" : "default"}
                                : {})
                        }}
                    >
                        {!_.isNil(reservations?.id) && (
                            <Form.Item name="id" hidden={true}>
                                <Input value={reservations?.id} />
                            </Form.Item>
                        )}
                        <Form.Item name="tariff_id" hidden={true}>
                            <Input value={tariffId} />
                        </Form.Item>

                        <Space
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 80 : 56}
                            direction="vertical"
                            className="fields-container"
                        >
                            <Typography as="div" className="tariffname" size={24} color="#fff">
                                {_.chain(tariffs)
                                    .filter(({id}) => _.isEqual(id.toString(), tariffId))
                                    .map(({name}) => name)
                                    .valueOf()}
                            </Typography>
                            <Typography as="div" className="sim-type">
                                <Typography as="div" className="desc" size={24} color="#fff">
                                    Выберите способ оформления
                                </Typography>
                                <RadioField
                                    name="type"
                                    items={[
                                        {name: "SIM-карта", filter: "sim"},
                                        {name: "eSIM", filter: "esim"}
                                    ]}
                                />
                            </Typography>
                            {_.isEqual(simType, "sim") ? (
                                <SimField
                                    props={{noStyle: true}}
                                    prefix={prefix}
                                    formInstance={formInstance}
                                />
                            ) : (
                                <Space
                                    size={_.gte(contextLayout?.width ?? 0, 768) ? 32 : 16}
                                    direction="vertical"
                                    className="esim-container"
                                >
                                    <Typography as="div" size={17} color="#fff" className="desc">
                                        Инструкция по самостоятельной установке eSIM придет на
                                        указанную почту
                                        <br />
                                        Убедитесь, что устройство поддерживает eSIM
                                    </Typography>
                                    <Collapse
                                        ghost
                                        expandIconPosition="end"
                                        expandIcon={() => <img src={arrow} alt="" />}
                                    >
                                        <Collapse.Panel header="Как оформить eSIM" key="1">
                                            <Typography as="div" size={17} color="#fff">
                                                Замените пластиковую SIM на eSIM или перенесите eSIM
                                                на новое устройство не выходя из дома!
                                            </Typography>
                                            <Typography as="div" size={17} color="#fff">
                                                Необходимо подключение к Wi-Fi, поскольку во время
                                                замены на eSIM действующая SIM-карта отключится от
                                                сети.
                                                <br />
                                                После замены на 24 часа будут приостановлены
                                                входящие SMS от банков и финансовых сервисов. Это
                                                делается в целях безопасности.
                                            </Typography>
                                            <Typography as="div" size={17} color="#fff">
                                                Убедитесь, что ваш смартфон поддерживает eSIM. Для
                                                оформления заказа укажите адрес электронной почты.
                                                На него придет инструкция по самостоятельной
                                                установке eSIM.
                                            </Typography>
                                        </Collapse.Panel>
                                    </Collapse>
                                    <Form.Item name="email" rules={[Rules.required, Rules.email]}>
                                        <Input title="Электронная почта" />
                                    </Form.Item>
                                    <Typography as="div" className="device-container">
                                        <SelectField
                                            formItemProps={{
                                                name: "device",
                                                label: "Например iPhone",
                                                rules: [Rules.required]
                                            }}
                                            selectProps={{
                                                allowClear: true,
                                                showSearch: true,
                                                notFoundContent: (
                                                    <Empty
                                                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                                                        description={
                                                            <Typography color="#fff" size={17}>
                                                                Не найдено
                                                            </Typography>
                                                        }
                                                    />
                                                ),
                                                onSearch,
                                                options,
                                                getPopupContainer: () => {
                                                    return ref.current!.querySelector(
                                                        ".device-popup-container"
                                                    )!;
                                                }
                                            }}
                                        />
                                        <Typography
                                            as="div"
                                            className="device-popup-container"
                                        ></Typography>

                                        <Typography
                                            as="div"
                                            className="device-desc"
                                            size={17}
                                            color="rgba(255,255,255,.5)"
                                        >
                                            Введите в поиске ваше устройство
                                        </Typography>
                                    </Typography>
                                </Space>
                            )}

                            {_.size(tariffs) ? <TariffField tariffs={tariffs} /> : null}
                            <PassportUploadField
                                form={formInstance}
                                title={t("Паспортные данные")}
                                subtitle={t("Внесите фото разворотов паспорта")}
                            />
                            <Form.Item noStyle shouldUpdate>
                                {({getFieldsValue, getFieldsError}) => {
                                    const errors = getFieldsErrorSize(getFieldsError);
                                    const {
                                        sim,
                                        passport,
                                        passport_second,
                                        email = emailValue,
                                        device = deviceValue
                                    } = getFieldsValue();
                                    const simValues = _.values(_.omitBy(sim, _.isNil)).filter((i) =>
                                        _.size(_.trim(i))
                                    );
                                    const bothPhotos: number =
                                        (passport?.length ?? 0) + (passport_second?.length ?? 0);
                                    let formIsComplete;

                                    if (!_.isEqual(simType, "sim")) {
                                        formIsComplete =
                                            !_.size(email) || !_.size(device) || !!errors;
                                    } else {
                                        formIsComplete =
                                            (!!_.size(simValues) && _.lt(_.size(simValues), 11)) ||
                                            _.isEqual(bothPhotos, 1);
                                    }

                                    return (
                                        <Button
                                            type={formIsComplete ? "default" : "primary"}
                                            htmlType="submit"
                                            disabled={formIsComplete}
                                            loading={loading}
                                        >
                                            {edit
                                                ? t("Сохранить изменения")
                                                : t("Забронировать номер")}
                                        </Button>
                                    );
                                }}
                            </Form.Item>
                            {UserService.storeCanBookForDelivery && (
                                <Typography as="div" className="super-container">
                                    <RadioField
                                        name="bookType"
                                        items={[
                                            {name: "Супербронь", filter: "super"},
                                            {name: "Обычная бронь", filter: "default"}
                                        ]}
                                    />
                                </Typography>
                            )}
                        </Space>
                    </Form>
                </Typography>
            </div>
        );
    })
);

interface IProps {
    tariffs: ITariffsAvailable[];
    reservations?: IReservations;
    edit: boolean;
    npsPhone: string | undefined;
}
