import {Col, Layout, Row} from "@bezlimit/bezlimit-ui";
import BezlimitInfo from "./bezlimitlInfo";
import "./style.scss";
import DownloadApp from "./downloadApp";

export default function Footer() {
    return (
        <Layout.Footer className={"bl-footer"}>
            <Row gutter={[16, 40]}>
                <Col xs={24} md={12} lg={12}>
                    <DownloadApp />
                </Col>
                <Col xs={24} md={12} lg={12} className="col-info">
                    <BezlimitInfo />
                </Col>
            </Row>
        </Layout.Footer>
    );
}
