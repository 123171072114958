import React from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";

export default React.memo(function ModalAwards({placeholder, text}: IProps) {
    return (
        <Typography as="div" className="modal-rules modal-awards">
            <Typography as="div" className="title">
                {placeholder}
            </Typography>
            <Typography as="div" className="text">
                {text}
            </Typography>
        </Typography>
    );
});

interface IProps {
    placeholder: string;
    text: any;
}
