import {useContext} from "react";
import {Form, Input} from "@bezlimit/bezlimit-ui";
import {FormItemProps} from "antd";
import "./style.scss";
// rules
import {Rules} from "../../forms/rules";
// core
import {InterceptorContext} from "../../../core/Contexts";
import _ from "lodash";

export default function CodeField(props: FormItemProps<any>) {
    const context = useContext(InterceptorContext);

    const error = _.chain(
        _.isArray(context?.data)
            ? context?.data
            : context?.data?.message
            ? [{message: context?.data?.message, field: context?.data?.message}]
            : []
    )
        .map(({field, message}) => message)
        .join(" ")
        .valueOf();

    return (
        <Form.Item {...props} className="field-code" name="code" rules={[Rules.required()]}>
            <Input.Number
                count={4}
                type={"number"}
                inputMode={"numeric"}
                pattern={"[0-9]*"}
                autoComplete={"off"}
                error={error}
            />
        </Form.Item>
    );
}
