import {Block, Button, Col, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import {useContext} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
// core
import {InterceptorContext, ModalContext} from "../../../../core/Contexts";

export default function BodyDeliveryModal({fio, phone, address, comment, id}: IProps) {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const contextModal = useContext(ModalContext);
    const context = useContext(InterceptorContext);

    const click = () => {
        navigate("/deliveries");
        contextModal?.setVisible(false);
    };

    return (
        <Typography as="div" className="bl-body-modal">
            <Typography color="#828282" size={17}>
                {t(
                    "Доставка успешно оформлена, с вами свяжется наш менеджер для подтверждения заявки"
                )}
            </Typography>
            <Block>
                <Row gutter={14}>
                    <Space direction="vertical" size={24}>
                        <Col span={24}>
                            <Typography color="#1F1F1F" size={17} appearance="bold">
                                {t("Подробности")}
                            </Typography>
                        </Col>
                        <Col span={24}>
                            <Typography color="#828282" size={14} className="block-subtitle">
                                {t("ФИО")}:
                            </Typography>
                            <Typography color="#1F1F1F" size={17} className="text">
                                {t(fio)}
                            </Typography>
                        </Col>
                        <Col span={24}>
                            <Typography color="#828282" size={14} className="block-subtitle">
                                {t("Контактный номер")}
                            </Typography>
                            <Typography color="#1F1F1F" size={17} className="text">
                                {t(phone)}
                            </Typography>
                        </Col>
                        <Col span={24}>
                            <Typography color="#828282" size={14} className="block-subtitle">
                                {t("Адрес доставки")}:
                            </Typography>
                            <Typography color="#1F1F1F" size={17} className="text">
                                {t(address)}
                            </Typography>
                        </Col>
                        <Col span={24}>
                            <Typography color="#828282" size={14} className="block-subtitle">
                                {t("Коментарий к заказу")}:
                            </Typography>
                            {comment && (
                                <Typography color="#1F1F1F" size={17} className="text">
                                    {t(comment)}
                                </Typography>
                            )}
                        </Col>
                    </Space>
                </Row>
            </Block>
            <Button type="primary" onClick={click} loading={context?.loadingBtn}>
                {t("Вернуться в Мои доставки")}
            </Button>
        </Typography>
    );
}

interface IProps {
    fio: string;
    address: string;
    phone: string;
    comment: string | undefined;
    id: number;
}
