import React, {useContext} from "react";
import {Typography, Space, Button, Icon} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// forms
import WriteBossForm from "../../forms/writeBoss";
// core
import {LayoutContext, ModalContext} from "../../../core/Contexts";

export default React.memo(
    observer(function WriteBossModal() {
        const contextLayout = useContext(LayoutContext);
        const {t} = useTranslation();
        const contextModal = useContext(ModalContext);

        const onClose = () => {
            contextModal?.setVisible(false);
            contextModal?.setCssClass("");
        };

        return (
            <Typography as="div" className="bl-write-boss-modal">
                <Button className="close-btn" onClick={onClose}>
                    <Icon name={"close"} size={"18px"} />
                </Button>
                <Space
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 40 : 16}
                    direction="vertical"
                    align="center"
                >
                    <Typography as="div">
                        <Space size={8} direction="vertical" align="center">
                            <Typography
                                size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                                level={2}
                                color="#000000"
                            >
                                {t("Написать директору")}
                            </Typography>
                            <Typography color="#000000" size={14} className="subtitle">
                                {t(
                                    "Здесь Вы можете оставить свои предложения по улучшению качества услуг или оставить свой отзыв о работе"
                                )}
                            </Typography>
                        </Space>
                    </Typography>
                    <WriteBossForm />
                </Space>
            </Typography>
        );
    })
);
