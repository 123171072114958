import React, {useState} from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import {Table, ConfigProvider} from "antd";
import {observer} from "mobx-react";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import _ from "lodash";
import "./style.scss";
// components
import ColumnTitle from "../../../../ui/columnTitle";
import Header from "../header";
// services
import PhoneService from "../../../../../../services/PhoneService";
import PhonesDefaultService from "../../../../../../services/PhonesDefaultService";

export default observer(function NumbersListFakeHeader({
    phone,
    type,
    tariff,
    categories,
    region,
    pattern,
    isAllChecked,
    gridType,
    sort,
    phonePattern,
    phoneCombs,
    Service,
    isFavorite,
    setIsFavorite,
    setType,
    setTariff,
    setCategories,
    setRegion,
    setPattern,
    setAllIsChecked,
    setPhone,
    setGridType,
    setSort,
    setCssClass,
    setGridTypePrev,
    setPhonePattern,
    setPhoneCombs
}: IProps) {
    const [data] = useState<any>([]);

    const columns = [
        {dataIndex: "info", key: "info"},
        {
            dataIndex: "actions",
            key: "actions",
            ...(_.isEqual(Service.type, "default") && {
                title: (
                    <Header
                        phone={phone}
                        type={type}
                        tariff={tariff}
                        categories={categories}
                        region={region}
                        pattern={pattern}
                        gridType={gridType}
                        sort={sort}
                        phonePattern={phonePattern}
                        phoneCombs={phoneCombs}
                        isFavorite={isFavorite}
                        setIsFavorite={setIsFavorite}
                        setType={setType}
                        setTariff={setTariff}
                        setCategories={setCategories}
                        setRegion={setRegion}
                        setPattern={setPattern}
                        setSort={setSort}
                        setGridType={setGridType}
                        setGridTypePrev={setGridTypePrev}
                        setCssClass={setCssClass}
                        setAllIsChecked={setAllIsChecked}
                        setPhone={setPhone}
                        setPhonePattern={setPhonePattern}
                        setPhoneCombs={setPhoneCombs}
                        Service={Service}
                    />
                )
            })
        }
    ];

    const onChange = ({target: {checked}}: CheckboxChangeEvent) => {
        PhonesDefaultService.checkAll(checked);
    };

    return (
        <Typography as="div" className="bl-numbers-list fake">
            <ConfigProvider>
                <Table
                    rowSelection={{
                        columnTitle: (
                            <ColumnTitle
                                onChange={onChange}
                                disabled={false}
                                checked={isAllChecked}
                            />
                        ),
                        type: "checkbox"
                    }}
                    columns={columns}
                    dataSource={data}
                    pagination={false}
                />
            </ConfigProvider>
        </Typography>
    );
});

interface IProps {
    phone: string | undefined;
    type: string[];
    tariff: string[];
    categories: string[];
    region: string[];
    pattern: string[];
    gridType: string;
    isAllChecked: boolean;
    sort: string;
    Service: PhoneService;
    phonePattern: string[];
    phoneCombs: string | undefined;
    isFavorite: boolean;
    setIsFavorite: React.Dispatch<React.SetStateAction<boolean>>;
    setType: React.Dispatch<React.SetStateAction<string[]>>;
    setTariff: React.Dispatch<React.SetStateAction<string[]>>;
    setCategories: React.Dispatch<React.SetStateAction<string[]>>;
    setRegion: React.Dispatch<React.SetStateAction<string[]>>;
    setPattern: React.Dispatch<React.SetStateAction<string[]>>;
    setAllIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
    setPhone: React.Dispatch<React.SetStateAction<string | undefined>>;
    setGridType: React.Dispatch<React.SetStateAction<string>>;
    setGridTypePrev: React.Dispatch<React.SetStateAction<string>>;
    setSort: React.Dispatch<React.SetStateAction<string>>;
    setCssClass: React.Dispatch<React.SetStateAction<boolean>>;
    setPhonePattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhoneCombs: React.Dispatch<React.SetStateAction<string | undefined>>;
}
