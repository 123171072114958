import {IBonusesRequest} from "../core/models/Bonus";
// store
import Store from "../stores";
// service
import BonusService from "./BonusService";

enum Path {
    bonusesPayments = "/bonuses/payments",
    bonusesPayout = "/bonuses/payouts",
    bonusesPaymentsSummary = "/bonuses/payments/summary",
    bonusesPayoutsSummary = "/bonuses/payouts/summary"
}

class BonusHistoryService {
    [Symbol.toStringTag] = this.constructor.name;
    private _payments: BonusService = new BonusService(Store.bonus.payments);
    private _payouts: BonusService = new BonusService(Store.bonus.payouts);

    public get payments() {
        return this._payments;
    }

    public get payouts() {
        return this._payouts;
    }

    cleanUp() {
        if (this.payments.itemsSize) {
            this.payments.cleanUp();
        }
        if (this.payouts.itemsSize) {
            this.payouts.cleanUp();
        }

        return this;
    }

    getObject(type?: string) {
        switch (type) {
            case "payments":
                return this.payments;
            default:
                return this.payouts;
        }
    }

    async fetchBonusesItems(request?: IBonusesRequest) {
        await Promise.all([
            await this.payments.fetchBonusesItems(Path.bonusesPayments, request),
            await this.payments.getBonusSummary(Path.bonusesPaymentsSummary, request),
            await this.payouts.fetchBonusesItems(Path.bonusesPayout, request),
            await this.payouts.getBonusSummary(Path.bonusesPayoutsSummary, request)
        ]);
    }

    async fetchBonusesItemsByType(type: string, request?: IBonusesRequest) {
        switch (type) {
            case "payments":
                await Promise.all([
                    await this.payments.fetchBonusesItems(Path.bonusesPayments, request),
                    await this.payments.getBonusSummary(Path.bonusesPaymentsSummary, request)
                ]);
                break;
            default:
                await Promise.all([
                    await this.payouts.fetchBonusesItems(Path.bonusesPayout, request),
                    await this.payouts.getBonusSummary(Path.bonusesPayoutsSummary, request)
                ]);
                break;
        }
    }
}

export default new BonusHistoryService();
