import _ from "lodash";
// store
import Store from "../stores";
// core
import Axios, {axiosFetchBuilder, IAxiosConfig} from "../core/Axios";
import {ISessionsResponse} from "../core/models/Sessions";

enum Path {
    sessions = "/sessions",
    deleteSessions = "/sessions/{id}",
    updateSessions = "/sessions"
}

class SessionsServise {
    [Symbol.toStringTag] = this.constructor.name;
    private static readonly session = Store.sessions;

    static get items() {
        return SessionsServise.session.items;
    }

    static async fetchSessions() {
        try {
            const res: ISessionsResponse = await axiosFetchBuilder({
                url: Path.sessions,
                method: "GET",
                loading: true,
                params: {
                    expand: "user_agent"
                }
            } as IAxiosConfig);
            SessionsServise.session.items = res.items;
        } catch (err) {
            throw err;
        }
    }

    static async deleteSession(id: number) {
        try {
            await Axios({
                url: _.replace(Path.deleteSessions, "{id}", id.toString()),
                method: "DELETE",
                loading: false
            } as IAxiosConfig);
            SessionsServise.session.items = _.filter(
                SessionsServise.session.items,
                (i) => i.id != id
            );
        } catch (err) {
            throw err;
        }
    }

    static async putSessions(data: any) {
        try {
            return await Axios({
                url: Path.updateSessions,
                method: "PUT",
                loading: true,
                data
            } as IAxiosConfig);
        } catch (err) {
            throw err;
        }
    }
}

export default SessionsServise;
