import ReactDOM from "react-dom";
import React, {useEffect, useState} from "react";

const root = document.getElementById("portal-default");

export default React.memo(function DefaultPortal({children}: IProps) {
    const [node] = useState(document.createElement("div"));

    useEffect(() => {
        if (root) {
            root.appendChild(node);

            return () => {
                root.removeChild(node);
            };
        }
    }, []);

    return ReactDOM.createPortal(children, node);
});

interface IProps {
    children: JSX.Element;
}
