import React, {useContext} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext} from "../../../../core/Contexts";
// components
import Item from "./item";

export default React.memo(function Info({minutes, internet, sms}: IProps) {
    const contextLayout = useContext(LayoutContext);

    return (
        <Typography as="div" className="bl-number-list-info-modal-info">
            <Space size={_.gte(contextLayout?.width ?? 0, 768) ? 47 : 42} align="center">
                {_.gte(minutes, 0) ? <Item value={minutes} header="Минуты" /> : null}
                {_.gte(sms, 0) ? <Item value={sms} header="СМС" /> : null}
                {_.gte(internet, 0) ? <Item value={internet} header="ГБ" /> : null}
            </Space>
        </Typography>
    );
});

interface IProps {
    minutes: number;
    internet: number;
    sms: number;
}
