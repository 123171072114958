import React, {useContext, useEffect, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import _ from "lodash";
import {Block, Button, Col, Row, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
//service
import BonusesLevelsService from "../../../../services/BonusesLevelsService";
//components
import DealerBonuses from "../../dealersBonuses/dealerBonuses";
//core
import {InterceptorContext} from "../../../../core/Contexts";

import "./style.scss";
import BonusesService from "../../../../services/BonusesService";

export default observer(function DealersBonuses({showPayments}: IProps) {
    const mountedRef = useRef(true);
    const {t} = useTranslation();
    const context = useContext(InterceptorContext);
    const navigate = useNavigate();
    const levels = BonusesLevelsService.items;

    const getLevels = async () => {
        if (!mountedRef.current) {
            return;
        }
        await BonusesLevelsService.fetchlevels();
    };

    useEffect(() => {
        getLevels();
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const onAddMore = async () => {
        await BonusesLevelsService.loadMorelevels({
            page: BonusesLevelsService.currentPage + 1
        });
    };

    return (
        <Block className="bl-dealers-bonuses">
            <Row gutter={[16, 16]}>
                <Col>
                    <Typography className="title">{t("Начисления дилеров")}</Typography>
                    <Typography className="subtitle">
                        {t(
                            showPayments
                                ? "Все начисления закрепленных дилеров"
                                : "Нет начислений закрепленных дилеров"
                        )}
                    </Typography>
                </Col>
                <Col span={24}>
                    <Row gutter={[16, 16]}>
                        <Col lg={18} md={18} xs={0}></Col>
                        <Col lg={!!BonusesService.summaryProcessSum ? 3 : 4} md={3} xs={0}>
                            <Typography
                                className={`col-title${
                                    !!BonusesService.summaryProcessSum ? "" : " penultimate"
                                }`}
                            >
                                {t("Накоплено")}
                            </Typography>
                        </Col>
                        {!!BonusesService.summaryProcessSum && (
                            <Col lg={3} md={3} xs={0}>
                                <Typography className="col-title last">
                                    {t("Начисляется")}
                                </Typography>
                            </Col>
                        )}
                    </Row>
                </Col>
                <Col span={24}>
                    <Row gutter={[16, {xs: 0, sm: 0, md: 16, lg: 16}]}>
                        {_.chain(levels)
                            .map((item, key) => (
                                <React.Fragment key={key}>
                                    <Col span={24}>
                                        <DealerBonuses level={item} />
                                    </Col>
                                </React.Fragment>
                            ))
                            .valueOf()}
                    </Row>
                </Col>
                <Col lg={12} xs={24}>
                    <Typography as="div" className="btn-show">
                        <Button
                            block
                            className="btn-show-more"
                            loading={context?.loadingBtn}
                            disabled={!BonusesLevelsService.hasItems}
                            onClick={onAddMore}
                        >
                            {t("Показать еще")}
                        </Button>
                    </Typography>
                </Col>
                <Col lg={12} xs={24}>
                    <Typography as="div">
                        <Button type="primary" block onClick={() => navigate("/dealers/bonus")}>
                            {t("Начисления дилеров")}
                        </Button>
                    </Typography>
                </Col>
            </Row>
        </Block>
    );
});

interface IProps {
    showPayments: boolean;
}
