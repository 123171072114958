import "./style.scss";

export const svgIconNumberSprite = (): JSX.Element => {
    return (
        <svg
            className="svgAssetHidden"
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
        >
            <symbol id="lightning1" x="0px" y="0px" viewBox="0 0 32 32" fill="none">
                <g>
                    <path
                        d="M10.1473 17.3152L17.8279 8.6458C17.9684 8.48717 18.2268 8.62738 18.1703 8.83166L16.6 14.5185H21.7778C21.864 14.5185 21.9098 14.6203 21.8527 14.6848L14.1721 23.3542C14.0316 23.5128 13.7732 23.3726 13.8297 23.1683L15.4 17.4815H10.2222C10.136 17.4815 10.0902 17.3797 10.1473 17.3152Z"
                        stroke="#642878"
                        strokeWidth="1.5"
                    />
                </g>
            </symbol>
        </svg>
    );
};
