//banners
import bronzeImgW1x from "../../../../../images/banner-bronze@1x.webp";
import bronzeImgW2x from "../../../../../images/banner-bronze@2x.webp";
import bronzeImg from "../../../../../images/banner-bronze.png";
import silverImgW1x from "../../../../../images/banner-silver@1x.webp";
import silverImgW2x from "../../../../../images/banner-silver@2x.webp";
import silverImg from "../../../../../images/banner-silver.png";
import goldImgW1x from "../../../../../images/banner-gold@1x.webp";
import goldImgW2x from "../../../../../images/banner-gold@2x.webp";
import goldImg from "../../../../../images/banner-gold.png";
import topImgW1x from "../../../../../images/banner-top@1x.webp";
import topImgW2x from "../../../../../images/banner-top@2x.webp";
import topImg from "../../../../../images/banner-top.png";

export const getLoyaltyBanner = (code: string, name: string): JSX.Element => {
    switch(code){
        case "bronze":
            return (
                <picture>
                    <source type='image/webp' srcSet={`${bronzeImgW1x} 1x, ${bronzeImgW2x} 2x`}/>
                    <img src={bronzeImg} width={130} srcSet={`${bronzeImg} 2x`} alt={name}/>
                </picture>
            );
        case "silver":
            return (
                <picture>
                    <source type='image/webp' srcSet={`${silverImgW1x} 1x, ${silverImgW2x} 2x`}/>
                    <img src={silverImg} width={130} srcSet={`${silverImg} 2x`} alt={name}/>
                </picture>
            );
        case "gold":
            return (
                <picture>
                    <source type='image/webp' srcSet={`${goldImgW1x} 1x, ${goldImgW2x} 2x`}/>
                    <img src={goldImg} width={130} srcSet={`${goldImg} 2x`} alt={name}/>
                </picture>
            );
        case "top":
            return (
                <picture>
                    <source type='image/webp' srcSet={`${topImgW1x} 1x, ${topImgW2x} 2x`}/>
                    <img src={topImg} width={130} srcSet={`${topImg} 2x`} alt={name}/>
                </picture>
            );
        default:
            return <></>;
    }
}
