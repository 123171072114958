import _ from "lodash";
// core
import {axiosFetchBuilder} from "../core/Axios";
import {
    IPhonesCurrentFilters,
    IPhonesFiltersRequest,
    IPhonesFiltersResponse
} from "../core/models/PhonesFilters";
// store
import Store from "../stores";

enum Path {
    phones = "/phones",
    filters = "/phones/filters"
}

class PhonesFiltersService {
    [Symbol.toStringTag] = this.constructor.name;
    public static phonesFilters = Store.phonesFilters;

    static get isRemovedItem() {
        return PhonesFiltersService.phonesFilters.isRemovedItem;
    }

    static set isRemovedItem(value: boolean) {
        PhonesFiltersService.phonesFilters.isRemovedItem = value;
    }

    static get currentFilter() {
        return PhonesFiltersService.phonesFilters.currentFilter;
    }

    static get filters() {
        return PhonesFiltersService.phonesFilters.filters;
    }

    static get filtersActive() {
        return PhonesFiltersService.phonesFilters.filtersActive;
    }

    static get filterType() {
        return PhonesFiltersService.phonesFilters.filters?.type || [];
    }

    static get filterTariff() {
        return PhonesFiltersService.phonesFilters.filters?.service_limit || [];
    }

    static get filterCategories() {
        return PhonesFiltersService.phonesFilters.filters?.mask_category || [];
    }

    static get filterRegion() {
        return PhonesFiltersService.phonesFilters.filters?.region || [];
    }

    static get filterPattern() {
        return PhonesFiltersService.phonesFilters.filters?.mask_pattern || [];
    }

    static active() {
        return !_.includes(PhonesFiltersService.currentFilter.type, "standard") ? " active" : "";
    }

    static size() {
        return !!_.size([
            ...PhonesFiltersService.currentFilter.tariff!,
            ...PhonesFiltersService.currentFilter.categories!,
            ...PhonesFiltersService.currentFilter.region!,
            ...PhonesFiltersService.currentFilter.pattern!
        ]);
    }

    static cleanUp() {
        PhonesFiltersService.phonesFilters.phone = undefined;
        PhonesFiltersService.phonesFilters.filters = undefined;
        PhonesFiltersService.phonesFilters.filtersActive = undefined;
    }

    static getFormatedFilters({
        phone,
        type,
        categories,
        pattern,
        tariff,
        region,
        phone_pattern,
        phone_combs,
        sort,
        general_number
    }: IPhonesFiltersRequest) {
        let s = "random";
        const t = _.head(type);

        switch (t) {
            case "paid":
                s = _.isEqual(sort, "expensive")
                    ? "-price"
                    : _.isEqual(sort, "cheap")
                    ? "price"
                    : "random";
                break;
            case "standard":
                s = _.isEqual(sort, "expensive")
                    ? "-tariff_price"
                    : _.isEqual(sort, "cheap")
                    ? "tariff_price"
                    : "random";
                break;
            default:
                s = "random";
                break;
        }

        return {
            sort: s,
            ...(!_.isNil(phone) && _.size(_.trim(phone)) ? {phone: _.trim(phone)} : {}),
            ...(_.size(type) ? {type: _.join(type, ",")} : {}),
            ...(_.size(categories) ? {mask_category: _.join(categories, ",")} : {}),
            ...(_.size(pattern) ? {mask_pattern: _.join(pattern, ",")} : {}),
            ...(_.size(tariff) ? {service_limit: _.join(tariff, ",")} : {}),
            ...(_.size(region) ? {region_id: _.join(region, ",")} : {}),
            ...(_.size(phone_pattern)
                ? {
                      phone_pattern: _.chain(phone_pattern as any)
                          .reduce<string[]>((res, i) => {
                              (res || (res = [])).push(!_.isEmpty(i) ? i : "N");
                              return res;
                          }, [])
                          .join("")
                          .valueOf()
                  }
                : {}),
            ...(!_.isEmpty(phone_combs) && _.size(_.replace(phone_combs!, /,/gi, ""))
                ? {phone_combs}
                : {}),
            ...(!_.isNil(general_number) && !!_.size(general_number)
                ? {general_number: general_number.join("")}
                : {})
        };
    }

    static getFormatedFilters2({
        phone,
        type,
        categories,
        pattern,
        tariff,
        region,
        phone_pattern,
        phone_combs,
        sort
    }: IPhonesCurrentFilters) {
        let s = "random";
        const t = _.head(type);

        switch (t) {
            case "paid":
                s = _.isEqual(sort, "expensive")
                    ? "-price"
                    : _.isEqual(sort, "cheap")
                    ? "price"
                    : "random";
                break;
            case "standard":
                s = _.isEqual(sort, "expensive")
                    ? "-tariff_price"
                    : _.isEqual(sort, "cheap")
                    ? "tariff_price"
                    : "random";
                break;
            default:
                s = "random";
                break;
        }

        return {
            sort: s,
            ...(!_.isNil(phone) && _.size(_.trim(phone)) ? {phone: _.trim(phone)} : {}),
            ...(_.size(type) ? {type: _.join(type, ",")} : {}),
            ...(_.size(categories) ? {mask_category: _.join(categories, ",")} : {}),
            ...(_.size(pattern) ? {mask_pattern: _.join(pattern, ",")} : {}),
            ...(_.size(tariff) ? {service_limit: _.join(tariff, ",")} : {}),
            ...(_.size(region) ? {region_id: _.join(region, ",")} : {}),
            ...(_.size(phone_pattern)
                ? {
                      phone_pattern: _.chain(phone_pattern as any)
                          .reduce<string[]>((res, i) => {
                              (res || (res = [])).push(!_.isEmpty(i) ? i : "N");
                              return res;
                          }, [])
                          .join("")
                          .valueOf()
                  }
                : {}),
            ...(!_.isEmpty(phone_combs) && _.size(_.replace(phone_combs!, /,/gi, ""))
                ? {phone_combs}
                : {})
        };
    }

    static setCurrentFilter(value: IPhonesCurrentFilters) {
        PhonesFiltersService.phonesFilters.currentFilter = {
            ...PhonesFiltersService.currentFilter,
            ...value
        };
    }

    static resetCurrentFilter() {
        PhonesFiltersService.phonesFilters.currentFilter = {
            ...PhonesFiltersService.phonesFilters.currentFilter,
            type: ["standard"],
            tariff: [],
            categories: [],
            region: [],
            pattern: [],
            phone_pattern: [],
            phone_combs: ""
        };
    }

    static async getFilters() {
        const res: IPhonesFiltersResponse = await axiosFetchBuilder({
            url: Path.filters
        });
        PhonesFiltersService.phonesFilters.filters = {
            ...res,
            type: [
                {name: "Обычные", filter: "standard"},
                {name: "Платные", filter: "paid"}
            ]
        };
    }

    static async getFiltersActive(req?: IPhonesFiltersRequest) {
        const res: IPhonesFiltersResponse = await axiosFetchBuilder({
            url: Path.filters,
            loading: false,
            params: {
                ...(req ? PhonesFiltersService.getFormatedFilters(req) : {}),
                ...(req ? {fields: "mask_category,service_limit"} : {})
            }
        });

        PhonesFiltersService.phonesFilters.filtersActive = {
            ...res,
            ...{
                service_limit: _.filter(
                    PhonesFiltersService.filters?.service_limit,
                    (i) => !_.find(res.service_limit, (j) => j.filter === i.filter)
                ),
                mask_category: _.filter(
                    PhonesFiltersService.filters?.mask_category,
                    (i) => !_.find(res.mask_category, (j) => j.filter === i.filter)
                ),
                region: _.filter(
                    PhonesFiltersService.filters?.region,
                    (i) => !_.find(res.region, (j) => j.filter === i.filter)
                ),
                mask_pattern: _.filter(
                    PhonesFiltersService.filters?.mask_pattern,
                    (i) => !_.find(res.mask_pattern, (j) => j.filter === i.filter)
                )
            }
        };
    }
}

export default PhonesFiltersService;
