
export enum HttpErrorStatusCode {
    notModified = 304,
    badRequest = 400,
    unauthorized = 401,
    forbidden = 403,
    notFound = 404,
    timeout = 408,
    conflict = 409,
    validationError = 422,
    tooManyRequests = 429,
    exception = 500,
    notImplemented = 501,
    serviceUnavailable = 503,
}

export interface IErrorValidationResponse {
    field: string;
    message: string;
}

export interface IErrors {
    name: string;
    errors: string[];
}

export interface IErrorResponse {
    name?: string;
    message?: string;
    code?: number;
    status?: number;
}
