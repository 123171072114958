import React, {useContext} from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext} from "../../../../../../../core/Contexts";

export default React.memo(function Internet({id, type, phone}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const onClick = async () => {
        navigate(`/reservation/${id}/${phone}`);
    };

    return (
        <Typography
            size={14}
            as="span"
            color="#fff"
            underline
            className="bl-numbers-list-actions-internet"
            onClick={onClick}
        >
            {t(
                `${
                    _.gt(contextLayout?.width ?? 0, 768)
                        ? "Забронировать"
                        : _.gt(contextLayout?.width ?? 0, 320)
                        ? "Забро..."
                        : "Забронировать"
                }`
            )}
        </Typography>
    );
});

interface IProps {
    phone: number;
    type: number;
    id: number;
}
