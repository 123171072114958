import React, {useContext, useRef, useState} from "react";
import {Button, Form, Icon, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import {InterceptorContext, LayoutContext, ModalContext} from "../../../core/Contexts";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
// components
import ItemsField from "../../fields/items";
import SearchField from "../../fields/search";
// core
import {IFilter} from "../../../core/models/Phones";
import PhonesFiltersService from "../../../services/PhonesFiltersService";

export default React.memo(
    observer(function NumbersFilterForm({
        items,
        type,
        selected,
        disabledFilters,
        set,
        setShouldUpdate
    }: IProps) {
        const context = useContext(InterceptorContext);
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const ref = useRef(null);
        const {t} = useTranslation();
        const [isEmpty, setIsEmpty] = useState(false);
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);

        const onFinish = async ({items}: INumbersFilterRequest) => {
            set(items);

            let updated: any = _.clone(PhonesFiltersService.currentFilter);
            updated[type] = items;

            PhonesFiltersService.setCurrentFilter(updated);

            if (setShouldUpdate) {
                setShouldUpdate(true);
            }

            contextModal?.setCssClass("");

            if (_.lte(contextLayout?.width ?? 0, 768)) {
                const el: any = document.body.querySelector(".bl-filter-button .btn-filter");

                if (el) {
                    el.click();
                }
            } else {
                contextModal?.setVisible(false);
            }
        };

        const onReset = () => {
            formInstance.setFieldsValue({items: [], search: ""});
        };

        const onSearch = async (value: string) => {
            const els = (ref.current as any).querySelectorAll(
                ".ant-checkbox-wrapper .bz-typography"
            );
            setIsEmpty(false);

            if (value) {
                const items = _.chain(els)
                    .filter((i) =>
                        _.includes(i.textContent.toLocaleLowerCase(), value.toLocaleLowerCase())
                    )
                    .map((i) => {
                        i.closest(".ant-space-item").style.display = "inline-flex";
                        return i;
                    })
                    .valueOf();
                _.chain(els)
                    .filter(
                        (i) =>
                            !_.includes(
                                i.textContent.toLocaleLowerCase(),
                                value.toLocaleLowerCase()
                            )
                    )
                    .forEach((i) => {
                        i.closest(".ant-space-item").style.display = "none";
                    })
                    .valueOf();

                if (!_.size(items)) {
                    setIsEmpty(true);
                }
            } else {
                _.chain(els)
                    .forEach((i) => {
                        i.closest(".ant-space-item").style.display = "inline-flex";
                    })
                    .valueOf();
            }
        };

        const onPressEnter = (e: any) => {
            (e as any).preventDefault();
            onSearch(e.target.value);
        };

        const onKeyUp = (e: any) => {
            (e as any).preventDefault();
            onSearch(e.target.value);
        };

        return (
            <div ref={ref}>
                <Typography as="div" className="bl-numbers-filter-form">
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="numbers-filter"
                        onFinish={onFinish}
                        autoComplete="off"
                        initialValues={{
                            items: selected
                        }}
                    >
                        <Space
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 24}
                            direction="vertical"
                        >
                            {_.lte(contextLayout?.width ?? 0, 768) && (
                                <SearchField
                                    allowClear
                                    title="Поиск"
                                    onSearch={onSearch}
                                    onPressEnter={onPressEnter}
                                    onKeyUp={onKeyUp}
                                />
                            )}
                            <ItemsField
                                items={items}
                                isEmpty={isEmpty}
                                disabledFilters={disabledFilters}
                            />
                            <Form.Item noStyle shouldUpdate>
                                {({getFieldsValue}) => {
                                    const {items} = getFieldsValue();

                                    return (
                                        <>
                                            {!!_.size(items) &&
                                                _.gte(contextLayout?.width ?? 0, 768) && (
                                                    <Button
                                                        type="text"
                                                        icon={<Icon name="close2" size="14" />}
                                                        onClick={onReset}
                                                    >
                                                        {t("Сбросить выбор")}
                                                    </Button>
                                                )}
                                            <Button
                                                type="primary"
                                                htmlType="submit"
                                                loading={context?.loadingBtn}
                                            >
                                                {t("Применить")}
                                            </Button>
                                        </>
                                    );
                                }}
                            </Form.Item>
                        </Space>
                    </Form>
                </Typography>
            </div>
        );
    })
);

interface IProps {
    items: IFilter[];
    selected: string[];
    disabledFilters?: IFilter[];
    set: React.Dispatch<React.SetStateAction<string[]>>;
    setShouldUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
    type: any;
}

interface INumbersFilterRequest {
    items: string[];
    search?: string;
}
