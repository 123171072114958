import {observer} from "mobx-react";
import {SetStateAction} from "react";
import {IItems} from "../../../core/models/Notifications";
import {
    funcFilterDescription,
    funcIconSwitchIndex,
    funcNotifyNotificationsMap,
    switchNotifyNotificationsIndex
} from "./helper";

interface INotificationsDescription {
    NotifyNotifications: IItems[];
    title: string;
    switchIndex: number;
    IconSwitchIndex: string;
    setIconSwitchIndexCallback: (x: SetStateAction<string>) => void;
    pageSize: number;
}

export default observer(function NotificationsDescription({
    NotifyNotifications,
    title,
    switchIndex,
    setIconSwitchIndexCallback,
    IconSwitchIndex,
    pageSize
}: INotificationsDescription) {
    return (
        <>
            <div className="notifications-page-wrapper-notifications-description-headerTop">
                <p>
                    {!!NotifyNotifications.filter((item) =>
                        switchNotifyNotificationsIndex(item.created_at, switchIndex)
                    ).length && title}
                </p>
                {!!NotifyNotifications.length &&
                    funcIconSwitchIndex(switchIndex, setIconSwitchIndexCallback, IconSwitchIndex)}
            </div>
            {!!NotifyNotifications.filter((item) =>
                switchNotifyNotificationsIndex(item.created_at, switchIndex)
            ).length && (
                <div className={funcFilterDescription(IconSwitchIndex)}>
                    {funcNotifyNotificationsMap(NotifyNotifications, switchIndex, pageSize)}
                </div>
            )}
        </>
    );
});
