import React, {useContext, useEffect, useState} from "react";
import {Row, Col, Typography, Button, Space, Divider} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// services
import ReservationsService from "../../../../../services/ReservationsService";
import BonusesService from "../../../../../services/BonusesService";
// components
import Phone from "../../../numbers/list/default/info/phone";
import Icons from "../../../numbers/list/booking/actions/icons";
import Duration from "../../../numbers/list/booking/actions/duration";
// core
import {LayoutContext} from "../../../../../core/Contexts";
//modal
import ActivationModal from "../../paymentReservedCard";
import UserCardService from "../../../../../services/UserCardService";
import NpsHistory from "../../../../../images/nps-history.png";
import NotBonuses from "../../../../modal/notBonuses";

export default React.memo(
    observer(function List({
        id,
        created_at,
        is_activated,
        phone_number,
        pay_type,
        removed_at,
        recommend_sum,
        name,
        price,
        phone,
        category,
        k,
        additional_data
    }: IProps) {
        const {t} = useTranslation();
        const contextLayout = useContext(LayoutContext);
        const [isActivationModalVisible, setActivationModalVisible] = useState(false);
        const [isActivationModalNotBonuses, setActivationModalNotBonuses] = useState(false);
        const [hashSuccess, setHashSuccess] = useState(false);

        const onClick = () => {
            UserCardService.fetchGetBankCard();
            if (!_.gte(BonusesService.accumulated, price)) {
                setActivationModalNotBonuses(true);
            } else {
                setActivationModalVisible(true);
            }
        };

        useEffect(() => {
            if (_.isEqual("#card-add-success", document.location.hash)) {
                UserCardService.fetchGetBankCard();
                setHashSuccess(true);
                setTimeout(() => {
                    setActivationModalVisible(true);
                }, 100);
            }
        }, []);

        return (
            <>
                <Typography as="div" className="bl-in-booking-list">
                    <Row gutter={[16, _.gte(contextLayout?.width ?? 0, 768) ? 24 : 28]}>
                        <Col span={24}>
                            <Row gutter={[16, 16]} className="row">
                                <Col xs={12} lg={5} className="phone-container">
                                    <Space direction="vertical" size={8}>
                                        <Phone category={category} phone={phone} />
                                        {_.lte(contextLayout?.width ?? 0, 768) ? (
                                            <Typography
                                                as="div"
                                                className="name"
                                                color="rgba(255, 255, 255, 0.5)"
                                                size={14}
                                            >
                                                {t(name)}
                                            </Typography>
                                        ) : null}
                                    </Space>
                                </Col>
                                <Col xs={12} lg={0} className="pay mobile">
                                    <Button type="primary" onClick={onClick}>
                                        {t(`Оплатить ${recommend_sum ?? price}`)}
                                    </Button>
                                </Col>
                                <Col xs={0} lg={5} className="name-container">
                                    <Typography
                                        as="div"
                                        className="name"
                                        color="rgba(255, 255, 255, 0.5)"
                                        size={17}
                                    >
                                        {t(name)}
                                    </Typography>
                                </Col>
                                <Col xs={8} lg={4} className="icons">
                                    {!_.isEqual(additional_data.nps, undefined) ? (
                                        <Typography as="div" className="nps-icons-phone">
                                            <img src={NpsHistory} width={20} alt="" />{" "}
                                            {additional_data.nps.phone}
                                        </Typography>
                                    ) : null}
                                    <Icons
                                        reservation={{
                                            created_at,
                                            id,
                                            is_activated,
                                            pay_type,
                                            phone_number,
                                            removed_at
                                        }}
                                    />
                                </Col>
                                <Col xs={16} lg={6} className="duration">
                                    <Duration
                                        suffix={true}
                                        reservation={{
                                            created_at,
                                            id,
                                            is_activated,
                                            pay_type,
                                            phone_number,
                                            removed_at
                                        }}
                                    />
                                </Col>
                                <Col xs={0} lg={4} className="pay desktop">
                                    <Button type="primary" onClick={onClick}>
                                        {t(`Оплатить ${recommend_sum ?? price}`)}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                        {_.gt(ReservationsService.itemsSize - 1, k) && <Divider />}
                    </Row>
                </Typography>
                <ActivationModal
                    hashSuccess={hashSuccess}
                    isMainPage={false}
                    visible={isActivationModalVisible}
                    cancelHandler={() => setActivationModalVisible(false)}
                    number={phone_number}
                    tariffName={name}
                    recommend_sum={recommend_sum}
                />
                <NotBonuses
                    hashSuccess={hashSuccess}
                    isMainPage={false}
                    visible={isActivationModalNotBonuses}
                    cancelHandler={() => setActivationModalNotBonuses(false)}
                    number={phone_number}
                    tariffName={name}
                    recommend_sum={recommend_sum}
                />
            </>
        );
    })
);

interface IProps {
    id: number;
    created_at: string;
    is_activated: boolean;
    phone_number: number;
    pay_type: string;
    removed_at: string;
    name: string;
    price: number;
    phone: number;
    category: string;
    recommend_sum: number;
    k: number;
    additional_data: any;
    // setItems: React.Dispatch<React.SetStateAction<IReservations[]>>;
}
