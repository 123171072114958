import React from "react";
import {Collapse, Space, Typography} from "@bezlimit/bezlimit-ui";
import {DownOutlined} from "@ant-design/icons";

import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import "./style.scss";
// services
import BonusesService from "../../../../services/BonusesService";
import _ from "lodash";
import Store from "../../../../stores";

export enum Rates {
    level = "level",
    personal = "personal",
    levelTwo = "levelTwo"
}

interface IProps {
    rateType: Rates;
}

const RateHeader = ({rateType}: IProps): JSX.Element => {
    const {t} = useTranslation();

    return (
        <Typography as="div" className="bonuses-rate">
            <Space size={19} direction="vertical">
                {rateType === Rates.level && (
                    <Typography as="div" className="bonuses-rate-text">
                        <span>{t("Дилерские ")}</span>
                        <span className="gold">{BonusesService.getRateLevel}%</span>
                    </Typography>
                )}
                {rateType === Rates.personal && (
                    <Typography as="div" className="bonuses-rate-text">
                        <span>{t("Личные ")}</span>
                        <span className="gold">{BonusesService.getRatePersonal}%</span>
                    </Typography>
                )}
                {rateType === Rates.levelTwo && (
                    <Typography as="div" className="bonuses-rate-text">
                        <span>{t("Процент за активации ")}</span>
                        <span className="gold">{BonusesService.getRatePersonal}%</span>
                    </Typography>
                )}
            </Space>
        </Typography>
    );
};

export default React.memo(
    observer(function Rate({rateType}: IProps) {
        const {t} = useTranslation();
        const {Panel} = Collapse;

        return (
            <Collapse
                ghost
                className="bonuses-rate-collapse"
                expandIconPosition="end"
                expandIcon={({isActive}) => <DownOutlined rotate={isActive ? 180 : 0} />}
            >
                <Panel header={<RateHeader rateType={rateType} />} key="1">
                    {rateType === Rates.level ? (
                        <Typography as="div" size={12} color="#fff" className="bonuses-rate-panel">
                            <p>
                                {t("Базовая ставка ")}
                                <span>{BonusesService.rateBasicLevel}%</span>
                            </p>
                            {!_.isNil(Store.user.loyalty) && (
                                <p>
                                    {t("Статус лояльности ")}
                                    <span>{BonusesService.rateLoyalty}%</span>
                                </p>
                            )}
                        </Typography>
                    ) : (
                        <Typography as="div" size={12} color="#fff" className="bonuses-rate-panel">
                            <p>
                                {t("Базовая ставка ")}
                                <span>{BonusesService.rateBasicPersonal}%</span>
                            </p>
                            <p>
                                {t("За номер Безлимит ")}
                                <span>{BonusesService.ratePhoneBl}%</span>
                                <br />
                            </p>
                            {!_.isNil(Store.user.loyalty) && (
                                <p>
                                    {t("Статус лояльности ")}
                                    <span>{BonusesService.rateLoyaltyPersonal}%</span>
                                </p>
                            )}
                        </Typography>
                    )}
                </Panel>
            </Collapse>
        );
    })
);
