import React, {useEffect} from "react";
import {observer} from "mobx-react";
import {Form, Typography, Button} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {useContext, useRef} from "react";
import {useTranslation} from "react-i18next";
import {FieldData} from "rc-field-form/lib/interface";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
// core
import {InterceptorContext, LayoutContext} from "../../../core/Contexts";
import {toGroupNumbers} from "../../../core/functions/inputNumber";
import CodeField from "../../fields/code";
import useFieldFocus from "../../../core/hooks/useFieldFocus";

export default React.memo(
    observer(function CodeConfirmationFormOutput({onSubmit, card, sum, ...props}: any) {
        const ref = useRef(null);
        const context = useContext(InterceptorContext);
        const contextLayout = useContext(LayoutContext);
        const {t} = useTranslation();
        const [initForm] = Form.useForm();
        const formInstance = useFormHandler(initForm);
        useFieldFocus(ref, "code");

        const onSend = async (values: any) => {
            try {
                await onSubmit(values);
            } catch (e) {}
        };

        const onFinish = async ({code}: any) => {
            onSend({...props, code: code.join(""), card: card, sum: sum});
        };

        const onFieldsChange = async (changedFields: FieldData[], allFields: FieldData[]) => {
            const code = _.values(_.omitBy(_.head(changedFields)?.value, _.isNil)).filter(
                (i) => !_.isEmpty(i)
            );

            if (_.lt(_.size(code), 4) && context?.data?.message) {
                context.setErrors(null);
            }
        };

        useEffect(() => {
            toGroupNumbers(ref, "code", formInstance);
        }, [ref]);

        return (
            <div ref={ref}>
                <Typography as="div" className="bl-code-confirmation-form">
                    <Form
                        form={formInstance}
                        name="codeConfirmation"
                        initialValues={{code: ""}}
                        onFinish={onFinish}
                        onFieldsChange={onFieldsChange}
                        autoComplete="off"
                    >
                        <Typography as="div" className="code-container">
                            <CodeField />
                        </Typography>
                        {_.lte(contextLayout?.width ?? 0, 767) && (
                            <Typography
                                as="div"
                                size={14}
                                color="#000000"
                                className="send-to-phone-message"
                            >
                                {t("Мы отправили код подтверждения ")}
                                <br />
                                {t("на ")}
                                <Typography
                                    as="span"
                                    color="#642878"
                                    size={14}
                                    className="send-phone-message-diler"
                                >
                                    {props.phone}
                                </Typography>
                            </Typography>
                        )}
                        <Form.Item shouldUpdate>
                            {({getFieldsValue}) => {
                                const {code} = getFieldsValue();
                                const start = (code || []).length;
                                const end = _.chain(code || [])
                                    .filter((i) => !_.isEmpty(i))
                                    .valueOf().length;
                                const errors = !!context?.data?.message;
                                const formIsComplete = !!code && _.isEqual(start, end) && !errors;

                                return (
                                    <Button
                                        className="bl-code-confirmation-button-submit"
                                        type={!formIsComplete ? "default" : "primary"}
                                        htmlType="submit"
                                        disabled={!formIsComplete}
                                        loading={context?.loadingBtn}
                                    >
                                        {t("Подтвердить")}
                                    </Button>
                                );
                            }}
                        </Form.Item>
                    </Form>
                </Typography>
            </div>
        );
    })
);
