import {Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";

export default function Block3() {
    return (
        <Typography as="div" className="bl-block3">
            <Typography as="div" className="title">
                Как принять участие в акции?
            </Typography>
            <Typography as="div" className="list mb24">
                <Typography as="div" className="items">
                    <Typography as="div" className="item">
                        <Typography as="div" className="left">
                            1
                        </Typography>
                        <Typography as="div" className="right">
                            Вы подключили номер клиенту. Расскажите ему об акции «Не плати за
                            связь», где за приведенных в Безлимит друзей связь для него может стать
                            бесплатной.
                        </Typography>
                    </Typography>
                    <Typography as="div" className="item">
                        <Typography as="div" className="left">
                            2
                        </Typography>
                        <Typography as="div" className="right">
                            К вам обращается друг клиента. Подберите и забронируйте красивый номер
                            для друга.
                        </Typography>
                    </Typography>
                    <Typography as="div" className="item">
                        <Typography as="div" className="left">
                            3
                        </Typography>
                        <Typography as="div" className="right">
                            <Typography as="div" className="subtitle">
                                Прикрепите номер по акции. Сделать это можно двумя способами:
                            </Typography>
                            <Typography as="ul">
                                <Typography as="li">
                                    Во время бронирования: нажмите на{" "}
                                    <b>баннер «Не плати за связь».</b>
                                </Typography>
                                <Typography as="li">
                                    Позже: Зайдите в карточку номера в разделе «Брони» и нажмите
                                    «Прикрепить номер по акции НПС».
                                </Typography>
                            </Typography>
                        </Typography>
                    </Typography>
                </Typography>
            </Typography>
        </Typography>
    );
}
