import _ from "lodash";
// core
import {IAxiosConfig, axiosFetchBuilder} from "../core/Axios";
import {
    IBonusesPaymentsSummary,
    IBonusesRequest,
    IBonusesResponse,
    IBonusesSummaryRequest
} from "../core/models/Bonus";
import Bonus from "../stores/Bonus";

enum Path {
    getPayments = "/bonuses/payments",
    getPayouts = "/bonuses/payouts",
    getPaymentsSummary = "/bonuses/payments/summary",
    getPayoutsSummary = "/bonuses/payouts/summary"
}

class BonusService {
    [Symbol.toStringTag] = this.constructor.name;
    protected readonly store: Bonus;

    constructor(store: Bonus) {
        this.store = store;
    }

    get name() {
        return this.store.name;
    }

    get type() {
        return this.store.type;
    }

    get items() {
        return this.store.items;
    }

    get itemsSize() {
        return _.size(this.store.items);
    }

    get meta() {
        return this.store.meta;
    }

    get perPage() {
        return this.store.meta?.perPage ?? 3;
    }

    get totalCount() {
        return this.store.meta?.totalCount ?? 0;
    }

    get currentPage() {
        return this.store.meta?.currentPage ?? 1;
    }

    get hasItems() {
        return _.gt(this.totalCount, this.itemsSize);
    }

    get summary() {
        return this.store.summary;
    }

    cleanUp() {
        this.store.meta = undefined;
        this.store.items = undefined;
    }

    private async getBonusPayments(
        url: string,
        request?: IBonusesRequest,
        loading: boolean = true
    ): Promise<IBonusesResponse> {
        const res: IBonusesResponse = await axiosFetchBuilder({
            url,
            loading,
            params: {
                ...request,
                expand: "loyalty,action"
            }
        } as IAxiosConfig);
        this.store.meta = (res as any)._meta;

        return res;
    }
    async fetchBonusesItems(url: string, request?: IBonusesRequest) {
        const res: IBonusesResponse = await this.getBonusPayments(url, request);
        this.store.items = res.items;
    }

    async getBonusSummary(url: string, request?: IBonusesRequest) {
        const res: IBonusesPaymentsSummary = await axiosFetchBuilder({
            url,
            params: {
                ...request
            }
        } as IAxiosConfig);
        this.store.summary = res;
    }

    static async getPaymentsSummary(request?: IBonusesSummaryRequest, loading: boolean = true) {
        return await axiosFetchBuilder({
            url: Path.getPaymentsSummary,
            loading,
            params: {
                ...request
            }
        } as IAxiosConfig);
    }

    static async getPayoutsSummary(request?: IBonusesSummaryRequest, loading: boolean = true) {
        return await axiosFetchBuilder({
            url: Path.getPayoutsSummary,
            loading,
            params: {
                ...request
            }
        } as IAxiosConfig);
    }

    static async getPaymentsItems(request?: IBonusesRequest, loading: boolean = true) {
        return await axiosFetchBuilder({
            url: Path.getPayments,
            loading,
            params: {
                ...request,
                expand: "loyalty, action"
            }
        } as IAxiosConfig);
    }

    static async getPayoutsItems(request?: IBonusesRequest, loading: boolean = true) {
        return await axiosFetchBuilder({
            url: Path.getPayouts,
            loading,
            params: {
                ...request,
                expand: "loyalty"
            }
        } as IAxiosConfig);
    }
}

export default BonusService;
