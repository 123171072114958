import React, {useContext, useEffect, useRef, useState} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import Store from "../../../stores";
import "./style.scss";
import cn from "classnames";
import BanksService from "../../../services/BanksService";
import {ModalContext} from "../../../core/Contexts";
import ringImg from "../../../images/ring2.png";
import _ from "lodash";

interface IProps {
    paymentId: number;
    phone: number;
}

export default observer(function SbpModal({paymentId, phone}: IProps) {
    const {t} = useTranslation();
    const [seconds, setSeconds] = useState(30);
    const [loading, setLoading] = useState(false);
    const contextModal = useContext(ModalContext);

    const savedCallBack = useRef<() => void>();

    const getTime = async () => {
        if (seconds === 0) {
            setLoading(true);
            const timeS = Date.now();
            const res = await BanksService.getPaymentStatus(paymentId);
            const timeE = Date.now();
            const diff = timeE - timeS;
            if (diff < 500) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
            }
            if (res?.status === 1) {
                Store.paymentStatus = 1;
                contextModal?.setCssClass("");
                contextModal?.setVisible(false);
            }
            setLoading(false);
        }
        const newSeconds = !seconds ? 30 : seconds - 1;
        setSeconds(newSeconds);
    };

    useEffect(() => {
        savedCallBack.current = getTime;
    });

    useEffect(() => {
        const interval = setInterval(() => {
            savedCallBack.current && savedCallBack.current();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="sbp-wrapper">
            <Space direction="vertical" align="center" size={20}>
                <Typography className="sbp-title">{t("Ждем информацию от банка")}</Typography>
                <img
                    className={cn("sbp-image", {"ring-shimmer": loading})}
                    src={ringImg}
                    width={200}
                    alt={"logo"}
                />
                <div>
                    <Typography className={cn("sbp-subtitle", {shimmer: loading})}>
                        {t("Если оплата номера ")}
                        <span>{phone}</span>
                    </Typography>
                    <Typography className={cn("sbp-subtitle", {shimmer: loading})}>
                        {t("прошла успешно, скоро мы это увидим")}
                    </Typography>
                </div>
                {_.lt(seconds, 10) ? (
                    <Typography className="sbp-text gray">
                        {t(`Данные о статусе платежа \nобновятся через 00:0${seconds}`)}
                    </Typography>
                ) : (
                    <Typography className="sbp-text gray">
                        {t(`Данные о статусе платежа \nобновятся через 00:${seconds}`)}
                    </Typography>
                )}
                <Typography className="spb-comment">
                    {t(
                        "Если перейти в приложение банка \nне удалось, выберите другой банк \nили способ оплаты"
                    )}
                </Typography>
            </Space>
        </div>
    );
});
