import {Typography, Block, Row, Col, Space, Icon} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import _ from "lodash";
//images
import phone_profile from "../../../../../images/phone_profile.png";
//core
import {ISessions} from "../../../../../core/models/Sessions";

export default observer(function DesctopActive({i}: Props) {
    return (
        <Block>
            <Row>
                <Col span={6} className="col-img">
                    <Typography as="div" className="group-icon-in-type">
                        {_.isEqual(i.user_agent.type, "mobile") ? (
                            <img src={phone_profile} width={32} alt="logo" />
                        ) : _.isEqual(i.user_agent.type, "web") &&
                          _.isEqual(i.user_agent.os_name, "linux") ? (
                            <Icon name={"linux_session"} size={"52px"} />
                        ) : _.isEqual(i.user_agent.type, "web") &&
                          _.isEqual(i.user_agent.os_name, "windows") ? (
                            <Icon name={"Win_profile"} size={"52px"} />
                        ) : (_.isEqual(i.user_agent.type, "web") &&
                              _.isEqual(i.user_agent.os_name, "macOS")) ||
                          _.isEqual(i.user_agent.os_name, "MacOS") ? (
                            <Icon name={"mac_session"} size={"52px"} />
                        ) : _.isEqual(i.user_agent.type, "web") &&
                          _.isEqual(i.user_agent.os_name, "android") ? (
                            <Icon name={"android_session"} size={"52px"} />
                        ) : _.isEqual(i.user_agent.type, "web") &&
                          _.isEqual(i.user_agent.os_name, "iOS") ? (
                            <Icon name={"apple_session"} size={"52px"} />
                        ) : (
                            <Icon name={"other_session"} size={"52px"} />
                        )}
                    </Typography>
                </Col>

                <Col span={12} className="col-content-block">
                    <Space direction="vertical" size={8}>
                        <Typography as="div" className="title-devices" size={18}>
                            {_.isEqual(i.user_agent.name, "")
                                ? "Неизвестное устройство"
                                : `${i.user_agent.name}, ${i.user_agent.os_name}`}
                        </Typography>
                        <Typography as="div" className="sub-title-devices" size={16}>
                            {i.user_agent.type}
                        </Typography>
                        {_.isEqual(i.location, null) || _.isEqual(i.location, "") ? (
                            <Typography as="div" className="discription-devices" size={14}>
                                {i.ip}
                            </Typography>
                        ) : (
                            <Typography as="div" className="discription-devices" size={14}>
                                {i.ip} - {i.location}
                            </Typography>
                        )}
                    </Space>
                </Col>
                <Col span={6}>
                    <Typography as="div" className="online">
                        В сети
                    </Typography>
                </Col>
            </Row>
        </Block>
    );
});

interface IProps {
    i: ISessions;
}

type Props = IProps;
