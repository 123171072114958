import React, {useContext} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// forms
import RemoveBookingForm from "../../forms/removeBooking";
// core
import {LayoutContext} from "../../../core/Contexts";
import {IReservationsRequest} from "../../../core/models/Reservations";

export default React.memo(
    observer(function RemoveBookingModal({id, phone, updateList}: IProps) {
        const contextLayout = useContext(LayoutContext);
        const {t} = useTranslation();

        return (
            <Typography as="div" className="bl-remove-booking-modal">
                <Space
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 40 : 16}
                    direction="vertical"
                    align="center"
                >
                    <Typography as="div">
                        <Space size={16} direction="vertical" align="center">
                            <Typography
                                size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                                level={2}
                                color="#000000"
                            >
                                {t("Отмена брони")}
                            </Typography>
                            <Typography as="div" color="#000000" size={17} className="subtitle">
                                <Typography as="div" size={17} color="#000000">
                                    {t(`После отмены брони номер`)}
                                    <Typography as="span" size={17} color="#642878">
                                        {phone}
                                    </Typography>
                                </Typography>
                                {t(`будет доступен в Базе номеров для всех`)}
                            </Typography>
                        </Space>
                    </Typography>
                    <RemoveBookingForm id={id} phone={phone} updateList={updateList} />
                </Space>
            </Typography>
        );
    })
);

interface IProps {
    id: number;
    phone: number;
    updateList: (request?: IReservationsRequest, loading?: boolean, reset?: boolean) => void;
}
