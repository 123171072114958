import {Col, Row, Space} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import "./style.scss";
import _ from "lodash";
import {useContext, useEffect} from "react";
// components
import News from "./news";
import HintHeader from "./hint";
import UserDropdownInfo from "./userDrodownInfo";
import BellNotificationsService from "../../../../services/BellNotificationsService";
// store
import {LayoutContext} from "../../../../core/Contexts";
import Notifications from "./notifications";

export default observer(function Info() {
    const contextLayout = useContext(LayoutContext);

    useEffect(() => {
        BellNotificationsService.getNewsNoticeCount();
    }, []);

    return (
        <div className="bl-info item">
            <Row gutter={[49, 0]}>
                <Col lg={12}>
                    <Row gutter={[16, 0]} justify="center" align="middle">
                        <Space size={_.gt(contextLayout?.width ?? 0, 320) ? 8 : 0}>
                            <Col>
                                <HintHeader />
                            </Col>
                            <Col>
                                <News
                                    newsCount={
                                        BellNotificationsService.newsNoticeCount?.news_count ?? 0
                                    }
                                />
                            </Col>
                            <Col>
                                <Notifications
                                    noticeCount={
                                        BellNotificationsService.newsNoticeCount?.notice_count ?? 0
                                    }
                                />
                            </Col>
                            <Col>
                                <UserDropdownInfo />
                            </Col>
                        </Space>
                    </Row>
                </Col>
            </Row>
        </div>
    );
});
