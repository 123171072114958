import React, {useContext} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import {IPhone} from "../../../../../../core/models/Phones";
import {LayoutContext} from "../../../../../../core/Contexts";
// services
import PhonesBookingService from "../../../../../../services/PhonesBookingService";
// components
import Paid from "./paid";
import Duration from "./duration";
import Icons from "./icons";

export default React.memo(
    observer(function Actions({
        gridType,
        props: {
            reservation,
            type,
            priceParams: {mask_price}
        },
        ...p
    }: IProps) {
        const contextLayout = useContext(LayoutContext);
        const {t} = useTranslation();

        return (
            <Typography as="div" className="bl-numbers-list-actions">
                <Space size={_.gte(contextLayout?.width ?? 0, 768) ? 16 : 8} direction="horizontal">
                    <Icons reservation={reservation} />
                    {!_.isEqual(gridType, "kanban") ? (
                        <>
                            <Duration reservation={reservation} suffix={true} />
                            {PhonesBookingService.isPaid(type) && <Paid maskPrice={mask_price} />}
                        </>
                    ) : null}
                </Space>
                {_.isEqual(gridType, "kanban") ? (
                    <Typography as="div" className="absolute">
                        {PhonesBookingService.isPaid(type) ? (
                            <Paid maskPrice={mask_price} />
                        ) : (
                            <Typography as="div">
                                <Typography size={14} color="#fff" className="dealer-id-mobile">
                                    {t("В брони: {dealer}", {dealer: reservation?.dealer_id})}
                                </Typography>
                            </Typography>
                        )}
                        <Duration reservation={reservation} suffix={true} />
                    </Typography>
                ) : null}
            </Typography>
        );
    })
);

interface IProps {
    gridType: string;
    props: IPhone;
}
