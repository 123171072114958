import React, {useContext, useEffect, useRef, useState} from "react";
import {notification} from "antd";
import {Button, Form, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import Clipboard from "react-clipboard.js";
import _ from "lodash";
import "./style.scss";
// core
import {IInviteRequest} from "../../../core/models/User";
import {LayoutContext, ModalContext} from "../../../core/Contexts";
import {getFieldsErrorSize} from "../../../core/functions/form";
// services
import UserService from "../../../services/UserService";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
import useFieldFocus from "../../../core/hooks/useFieldFocus";
import useOnCancel from "../../../core/hooks/useOnCancel";
import useOnChange from "../../../core/hooks/useOnChange";
// components
import PhoneField from "../../fields/phone";
import Error from "../../modal/error";
import FIOField from "../../fields/fio";
import Success from "../../modal/success";

export default React.memo(
    observer(function InviteAUserForm() {
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const [loading, setLoading] = useState(false);
        const [loadingCopy] = useState(false);
        const [clip, setClip] = useState<string>();
        const ref = useRef<HTMLDivElement>(null);
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        const onCancel = useOnCancel(formInstance);
        const onChange = useOnChange(formInstance);
        useFieldFocus(ref, "fio");

        const onFinish = async (values: IInviteRequest) => {
            setLoading(true);
            const phone = _.replace(values.phone!, /(\+7|\s|-)/gi, "");
            try {
                const {name, login} = await UserService.postInvite({
                    phone,
                    name: (values as any).fio
                });
                contextModal?.setChildren(
                    <Success
                        title={
                            <Typography as="span">
                                {t("Новый пользователь {name} ID {login} успешно приглашен", {
                                    name,
                                    login
                                })}
                            </Typography>
                        }
                    />
                );
                formInstance.resetFields();
            } catch (e: any) {
                contextModal?.setChildren(<Error phone={phone} />);
            } finally {
                setLoading(false);
                contextModal?.setOnCancel(() => onCancel);
            }
        };

        const onSuccess = () => {
            notification.open({
                message: t("Ссылка скопирована в буфер обмена"),
                placement: "bottom",
                closeIcon: undefined,
                className: "copy-referral",
                duration: 2
            });
        };

        useEffect(() => {
            // setLoadingCopy(true);
            setClip(UserService.systemVars?.referral_link);

            // UserService.getSystemVars().then(({referral_link}) => {
            //     setClip(referral_link);
            //     setLoadingCopy(false);
            // });
        }, []);

        return (
            <div ref={ref}>
                <Typography as="div" className="bl-invite-a-user-form">
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="invaite-a-user"
                        onFinish={onFinish}
                        onFieldsChange={onChange}
                        autoComplete="off"
                        initialValues={{
                            fio: "",
                            phone: ""
                        }}
                    >
                        <Space
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 0 : 8}
                            direction="vertical"
                        >
                            <FIOField />
                            <PhoneField />
                            <Form.Item>
                                <Typography
                                    as="div"
                                    color="#000"
                                    className="text-center"
                                    style={{fontWeight: 500}}
                                >
                                    {t("Либо воспользуйтесь реферальной ссылкой")}
                                </Typography>
                            </Form.Item>
                            <Form.Item>
                                <Clipboard
                                    data-clipboard-text={clip}
                                    className={`ant-btn ant-btn-primary${
                                        loadingCopy ? " ant-btn-loading" : ""
                                    }`}
                                    onSuccess={onSuccess}
                                >
                                    {t("Скопировать ссылку")}
                                </Clipboard>
                            </Form.Item>
                            <Form.Item shouldUpdate>
                                {({getFieldsValue, getFieldsError}) => {
                                    const {fio, phone} = getFieldsValue();
                                    const errors = getFieldsErrorSize(getFieldsError);
                                    const formIsComplete =
                                        !!fio &&
                                        !!phone &&
                                        !(_.replace(phone!, /(\+7|\s|-)/gi, "").length < 10) &&
                                        !errors;

                                    return (
                                        <Button
                                            type={!formIsComplete ? "default" : "primary"}
                                            htmlType="submit"
                                            disabled={!formIsComplete}
                                            loading={loading}
                                        >
                                            {t("Отправить")}
                                        </Button>
                                    );
                                }}
                            </Form.Item>
                            <Typography
                                as="div"
                                className="text-center cancel"
                                size={14}
                                color="#000"
                            >
                                <Button type="text" onClick={onCancel}>
                                    {t("Отменить")}
                                </Button>
                            </Typography>
                        </Space>
                    </Form>
                </Typography>
            </div>
        );
    })
);
