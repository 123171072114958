import React from "react";
import {Icon, Typography} from "@bezlimit/bezlimit-ui";
import {Segmented} from "antd";
import "./style.scss";

export default React.memo(function Grid({value, onChange}: IProps) {
    return (
        <Typography as="div" className="bl-grid">
            <Segmented
                value={value}
                options={[
                    {
                        value: "list",
                        icon: <Icon name="list" size="17" className="list" />
                    },
                    {
                        value: "kanban",
                        icon: <Icon name="kanban" size="17" />
                    }
                ]}
                onChange={onChange}
            />
        </Typography>
    );
});

interface IProps {
    value: string | number;
    onChange: (value: string | number) => void;
}
