import React, {useEffect} from "react";
import {Button, Checkbox, Col, Form, Input, Row, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {makeAutoObservable} from "mobx";
import {useNavigate} from "react-router-dom";
// core
import {IRegisterStoreForm} from "../../../core/models/Auth";
import {getFieldsErrorSize} from "../../../core/functions/form";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
// components
import FirstAndLastNameField from "../../fields/firstAndLastName";
import Card from "../../blocks/ui/card";

type IisPatronymic = {
    isPatronymic: boolean;
};

export const isPatronymic: IisPatronymic = makeAutoObservable({
    isPatronymic: false
});

export const registerStoreContext: IRegisterStoreForm = makeAutoObservable({
    firstName: "",
    lastName: "",
    patronymic: "",
    phone: undefined,
    ref_id: undefined
});

export default React.memo(
    observer(function RegisterStoreForm() {
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        const navigate = useNavigate();

        const onFinish = async (values: IRegisterStoreForm) => {
            registerStoreContext.ref_id = values.ref_id;
            registerStoreContext.firstName = values.firstName?.trimEnd();
            registerStoreContext.lastName = values.lastName?.trimEnd();
            registerStoreContext.patronymic =
                values.patronymic?.length !== 0 ? values.patronymic!.trimEnd() : "";
            navigate("/register-store-phone");
        };

        useEffect(() => {
            document!.getElementsByTagName("input")[0].focus();
        }, []);

        return (
            <Form
                form={formInstance}
                layout="horizontal"
                name="register-store"
                onFinish={onFinish}
                autoComplete="off"
                initialValues={{
                    firstName: registerStoreContext.firstName,
                    lastName: registerStoreContext.lastName,
                    patronymic: registerStoreContext.patronymic,
                    isPatronymic: isPatronymic.isPatronymic,
                    ref_id: new URL(window.location.href).searchParams.get("ref_id")
                }}
            >
                <Form.Item hidden={true} name="ref_id">
                    <Input />
                </Form.Item>
                <Card theme="blured">
                    <Typography as="div" className="bl-register-store-form">
                        <Row gutter={[16, 0]}>
                            <Col lg={12} md={12} xs={24}>
                                <FirstAndLastNameField name={"lastName"} titleText={t("Фамилия")} />
                            </Col>
                            <Col lg={12} md={12} xs={24}>
                                <FirstAndLastNameField name={"firstName"} titleText={t("Имя")} />
                            </Col>
                        </Row>
                        <Row gutter={[16, 0]}>
                            <Col lg={12} md={12} xs={24}>
                                <FirstAndLastNameField
                                    name={"patronymic"}
                                    titleText={t("Отчество")}
                                />
                            </Col>
                            <Col
                                lg={6}
                                md={6}
                                xs={24}
                                className="bl-register-store-form-butCheckbox-isPatronymic"
                            >
                                <Form.Item noStyle name="isPatronymic" valuePropName="checked">
                                    <Checkbox
                                        onClick={() => {
                                            form.resetFields(["patronymic"]);
                                            isPatronymic.isPatronymic = !isPatronymic.isPatronymic;
                                        }}
                                    >
                                        <Typography>{t("Нет отчества")}</Typography>
                                    </Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Typography>
                </Card>

                <Typography as="div" className="bl-register-store-form-button">
                    <Form.Item noStyle shouldUpdate>
                        {({getFieldsValue, getFieldsError}) => {
                            const {firstName, lastName, patronymic, isPatronymic} =
                                getFieldsValue();
                            const errors = getFieldsErrorSize(getFieldsError) !== 0 ? false : true;
                            const isPatronymicIsComplete = isPatronymic ? true : patronymic;
                            const formIsComplete =
                                isPatronymicIsComplete && firstName && lastName && errors;
                            return (
                                <Button
                                    type={!formIsComplete ? "default" : "primary"}
                                    htmlType="submit"
                                    disabled={!formIsComplete}
                                >
                                    {t("Продолжить")}
                                </Button>
                            );
                        }}
                    </Form.Item>
                </Typography>
            </Form>
        );
    })
);
