import _ from "lodash";
// core
import {IAxiosConfig, axiosFetchBuilder} from "../core/Axios";
import {
    IDeliveryRequest,
    IDeliveryRequestSim,
    IDeliveryResponse,
    IItems
} from "../core/models/Delivery";
import Store from "../stores";

enum Path {
    delivery = "/delivery",
    postDelivery = "/delivery"
}

class DeliveryService {
    [Symbol.toStringTag] = this.constructor.name;
    private static readonly delivery = Store.delivery;

    static get items() {
        return DeliveryService.delivery.items;
    }

    static get itemsSize() {
        return _.size(DeliveryService.delivery.items);
    }

    static get meta() {
        return DeliveryService.delivery.meta;
    }

    static get perPage() {
        return DeliveryService.delivery.meta?.perPage ?? 6;
    }

    static get totalCount() {
        return DeliveryService.delivery.meta?.totalCount ?? 6;
    }

    static get currentPage() {
        return DeliveryService.delivery.meta?.currentPage ?? 1;
    }

    static get hasItems() {
        return _.gt(DeliveryService.totalCount, DeliveryService.itemsSize);
    }

    static isArchive(key: string) {
        return _.isEqual(key, "deliveries/archive");
    }

    private static async getDelivery(
        request?: IDeliveryRequest,
        loading: boolean = true
    ): Promise<IDeliveryResponse> {
        const res: IDeliveryResponse = await axiosFetchBuilder({
            url: Path.delivery,
            loading,
            tag: "update",
            params: {
                ...request
            }
        } as IAxiosConfig);
        DeliveryService.delivery.meta = (res as any)._meta;
        return res;
    }

    static async fetchDelivery(request?: IDeliveryRequest) {
        const res: IDeliveryResponse = await DeliveryService.getDelivery(request);
        DeliveryService.delivery.items = res.items;
        return res;
    }

    static async loadMoreDelivery(request?: IDeliveryRequest) {
        const res: IDeliveryResponse = await DeliveryService.getDelivery(request, false);
        DeliveryService.delivery.items = [...DeliveryService.delivery.items, ...res.items];
        return res;
    }

    static async postDelivery(request: IDeliveryRequestSim): Promise<IItems> {
        return await axiosFetchBuilder({
            url: Path.postDelivery,
            method: "POST",
            loading: false,
            data: {...request}
        });
    }
}

export default DeliveryService;
