import {observer} from "mobx-react";
import {Col, Radio, Row} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import SVG from "react-inlinesvg";
import "./style.scss";
//images
import americanexpress from "../../../../../../images/americanexpress.svg";
import mastercard from "../../../../../../images/mastercard.svg";
import dinersclub from "../../../../../../images/dinersclub.svg";
import elo from "../../../../../../images/elo.svg";
import hiper from "../../../../../../images/hiper.svg";
import hipercard from "../../../../../../images/hipercard.svg";
import jcb from "../../../../../../images/jcb.svg";
import maestro from "../../../../../../images/maestro.svg";
import unionpay from "../../../../../../images/unionpay.svg";
import visa from "../../../../../../images/visa.svg";
import mir from "../../../../../../images/mir.svg";
import discover from "../../../../../../images/discover.svg";
import {IBankCards} from "../../../../../../core/models/BankCard";
import {useContext} from "react";
import {LayoutContext} from "../../../../../../core/Contexts";

export default observer(function ListRowBankCardsAutopayment({type, maskerPan, card}: Props) {
    const context = useContext(LayoutContext);
    const arrayIconsCardType = [
        {cardType: "americanexpress", cardImport: americanexpress},
        {cardType: "mir", cardImport: mir},
        {cardType: "mastercard", cardImport: mastercard},
        {cardType: "dinersclub", cardImport: dinersclub},
        {cardType: "elo", cardImport: elo},
        {cardType: "hiper", cardImport: hiper},
        {cardType: "hipercard", cardImport: hipercard},
        {cardType: "jcb", cardImport: jcb},
        {cardType: "maestro", cardImport: maestro},
        {cardType: "unionpay", cardImport: unionpay},
        {cardType: "visa", cardImport: visa},
        {cardType: "discover", cardImport: discover}
    ];

    return (
        <>
            <Row className="bankCards-list--row">
                <Col span={24}>
                    <Row gutter={[0, 16]} justify="space-between">
                        <Col className="numbers-list--type">
                            <SVG
                                src={
                                    _.find(arrayIconsCardType, (card) =>
                                        _.isEqual(
                                            card.cardType,
                                            type.toLowerCase().replace(/\s/g, "")
                                        )
                                    )?.cardImport ?? ""
                                }
                                width={30}
                            />
                            <p>{type}</p>
                            <span>
                                {(context?.width ?? 0) > 330 ? "•••• •••• •••• " : "•••• •••• "}
                            </span>
                            {maskerPan}
                        </Col>
                        <Col>
                            <Radio.Button value={card.id} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </>
    );
});

type Props = {
    type: string;
    maskerPan: string;
    card: IBankCards;
};
