import React, {useContext} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import "./style.scss";
//images
import ErrorImg from "../../images/errorImg";
// core
import {InterceptorContext, LayoutContext} from "../../../core/Contexts";

export default React.memo(function Error({
    title,
    subtitle,
    phone,
    customError,
    message,
    isDelivery
}: IProps) {
    const context = useContext(InterceptorContext);
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();

    const titleNew = title ?? t("Произошла ошибка!");

    if (_.isUndefined(context?.data) && isDelivery) {
        return (
            <Typography as="div" className="bl-error">
                <Space size={16} direction="vertical" align="center">
                    <ErrorImg />
                    <Typography
                        size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                        level={2}
                        color="#000000"
                    >
                        {t('Раздел "Доставка" временно недоступен')}
                    </Typography>
                    <Typography as="div" size={14} color="#000000" className="subtitle">
                        {t("Попробуйте зайти позднее для просмотра и оформления заказов")}
                    </Typography>
                </Space>
            </Typography>
        );
    }

    return (
        <Typography as="div" className="bl-error">
            <Space size={16} direction="vertical" align="center">
                <ErrorImg />
                <Typography
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                    level={2}
                    color="#000000"
                >
                    {titleNew}
                </Typography>
                {phone && (
                    <Typography
                        size={_.gte(contextLayout?.width ?? 0, 768) ? 48 : 32}
                        color="#642878"
                        fontFamily="alumna"
                    >
                        {phone}
                    </Typography>
                )}
                {subtitle && (
                    <Typography size={14} color="#000000" fontFamily="hauss" className="subtitle">
                        {subtitle}
                    </Typography>
                )}
                <Typography as="div" size={14} color="#000000" className="subtitle">
                    {message
                        ? message
                        : _.chain(
                              _.isArray(context?.data)
                                  ? context?.data
                                  : context?.data?.message
                                  ? [
                                        {
                                            message: context?.data?.message,
                                            field: context?.data?.message
                                        }
                                    ]
                                  : []
                          )
                              .map(({field, message}) => (
                                  <Typography key={field} className="description">
                                      {t(message)}
                                  </Typography>
                              ))
                              .valueOf()}
                </Typography>
                {customError && (
                    <Typography size={14} color="#000000" fontFamily="hauss" className="subtitle">
                        {customError}
                    </Typography>
                )}
            </Space>
        </Typography>
    );
});

interface IProps {
    phone?: string;
    title?: JSX.Element | string;
    subtitle?: JSX.Element;
    customError?: string;
    message?: JSX.Element | string;
    isDelivery?: boolean;
}
