import React, {useContext, useEffect, useState} from "react";
import {Button, Typography} from "@bezlimit/bezlimit-ui";
import {Table, ConfigProvider} from "antd";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import _ from "lodash";
import "./style.scss";
// components
import Info from "./info";
import Actions from "./actions";
import Empty from "../../../empty";
import ColumnTitle from "../../../ui/columnTitle";
import Header from "./header";
// core
import {LayoutContext} from "../../../../../core/Contexts";
// services
import PhonesFiltersService from "../../../../../services/PhonesFiltersService";
import PhoneService from "../../../../../services/PhoneService";
import {isPhonePattern} from "../../../../forms/numberSearch";
import UserService from "../../../../../services/UserService";

export default observer(function NumbersList({
    phone,
    type,
    tariff,
    categories,
    region,
    pattern,
    gridType,
    sort,
    phonePattern,
    phoneCombs,
    Service,
    isFavorite,
    setIsFavorite,
    setType,
    setTariff,
    setCategories,
    setRegion,
    setPattern,
    setPhone,
    setGridType,
    setSort,
    setGridTypePrev,
    setCssClassGrouped,
    setPhonePattern,
    setPhoneCombs
}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();
    const [data, setData] = useState<any>([]);
    const [cssClass, setCssClass] = useState(false);
    const [isAllChecked, setAllIsChecked] = useState(false);
    const phone_pattern = phonePattern;
    const phone_combs = isPhonePattern.phone_combs;
    const totallList = Service.meta?.totalCount;
    const totallPage = Service.totall;

    const [loading, setLoading] = useState(false);
    const columns = [
        {dataIndex: "info", key: "info"},
        {
            dataIndex: "actions",
            key: "actions",
            ...(_.isEqual(Service.type, "default") && {
                title: (
                    <Header
                        phone={phone}
                        type={type}
                        tariff={tariff}
                        categories={categories}
                        region={region}
                        pattern={pattern}
                        gridType={gridType}
                        sort={sort}
                        phonePattern={phonePattern}
                        phoneCombs={phoneCombs}
                        isFavorite={isFavorite}
                        setIsFavorite={setIsFavorite}
                        setType={setType}
                        setTariff={setTariff}
                        setCategories={setCategories}
                        setRegion={setRegion}
                        setPattern={setPattern}
                        setSort={setSort}
                        setGridType={setGridType}
                        setGridTypePrev={setGridTypePrev}
                        setCssClass={setCssClass}
                        setAllIsChecked={setAllIsChecked}
                        setPhone={setPhone}
                        setPhonePattern={setPhonePattern}
                        setPhoneCombs={setPhoneCombs}
                        Service={Service}
                    />
                )
            })
        }
    ];

    const onClick = async () => {
        setLoading(true);
        try {
            const options = {
                page: Service.currentPage + 1,
                ...PhonesFiltersService.getFormatedFilters({
                    phone,
                    ...(_.isEqual(Service.type, "default") ? {type} : {type: [Service.type]}),
                    tariff,
                    categories,
                    region,
                    pattern,
                    phone_pattern,
                    phone_combs,
                    sort,
                    general_number: PhonesFiltersService.phonesFilters.general_number
                })
            };

            await Service.loadMorePhones(options);
        } finally {
            setLoading(false);
            setAllIsChecked(false);
        }
    };
    const onChange = ({target: {checked}}: CheckboxChangeEvent) => {
        Service.selectedRowKeys = checked ? _.chain(Service.items).map("phone").valueOf() : [];
        setAllIsChecked(checked);
    };

    useEffect(() => {
        let res = _.chain(Service.items)
            .filter(({type}) => !_.isEqual(type, 9))
            .uniqBy("phone")
            .valueOf();

        const data = _.chain(res)
            .reduce<DataType[]>((res, i) => {
                (res || (res = [])).push({
                    key: i.phone as React.Key,
                    info: (
                        <Info
                            props={i}
                            filters={{
                                phone,
                                type,
                                tariff,
                                categories,
                                region,
                                pattern
                            }}
                            Service={Service}
                            gridType={gridType}
                        />
                    ),
                    actions: (
                        <Actions
                            props={i}
                            filters={{
                                phone,
                                type,
                                tariff,
                                categories,
                                region,
                                pattern
                            }}
                            Service={Service}
                        />
                    )
                });
                return res;
            }, [])
            .valueOf();

        setData(data);
    }, [Service.items, Service.meta, sort, gridType]);

    useEffect(() => {
        if (Service.isEqualItemsSizeAndselectedRowKeysSize && Service.selectedRowKeysSize) {
            setAllIsChecked(true);
        } else {
            setAllIsChecked(false);
        }
    }, [Service.selectedRowKeys]);

    useEffect(() => {
        setCssClassGrouped(cssClass);
    }, [cssClass]);

    useEffect(() => {
        return () => {
            Service.cleanUp();
        };
    }, []);

    const rowSelection = {
        onChange: (value: React.Key[]) => {
            Service.selectedRowKeys = value;

            if (!Service.isEqualItemsSizeAndselectedRowKeysSize) {
                setAllIsChecked(false);
            }
        }
    };

    return (
        <Typography
            as="div"
            className={`bl-numbers-list ${
                UserService.storeCanBookForDelivery ? "is-can-super-booking" : ""
            } ${_.isEqual(_.size(type), 1) && _.includes(type, "paid") ? "paid" : Service.type}${
                _.lte(contextLayout?.width ?? 0, 768) && cssClass ? " mobile-dropdown-open" : ""
            } ${_.gte(contextLayout?.width ?? 0, 768) ? gridType : "kanban"}${
                isFavorite ? " isfav" : ""
            }`}
        >
            <ConfigProvider renderEmpty={() => <Empty />}>
                <Table
                    rowSelection={{
                        columnTitle: (
                            <ColumnTitle
                                onChange={onChange}
                                disabled={!Service.itemsSize}
                                title={Service.name}
                                checked={isAllChecked}
                            />
                        ),
                        selectedRowKeys: Service.selectedRowKeys,
                        type: "checkbox",
                        ...rowSelection
                    }}
                    columns={columns}
                    dataSource={data}
                    pagination={{
                        defaultPageSize: Service.perPage * Service.currentPage,
                        pageSize: Service.perPage * Service.currentPage,
                        showTitle: false,
                        showSizeChanger: false,
                        showLessItems: true
                    }}
                />

                {Service.hasItems ? (
                    <Button
                        className="load-more"
                        type="default"
                        onClick={onClick}
                        loading={loading}
                    >
                        {t("Показать еще")}
                        {_.isEqual(Service.type, "default") ? (
                            <>{t(`(показано ${totallPage} номеров из ${totallList})`)}</>
                        ) : null}
                    </Button>
                ) : null}
            </ConfigProvider>
        </Typography>
    );
});

interface DataType {
    key: React.Key;
    info: JSX.Element;
    actions: JSX.Element;
}

interface IProps {
    phone: string | undefined;
    type: string[];
    tariff: string[];
    categories: string[];
    region: string[];
    pattern: string[];
    isAllChecked: boolean;
    gridType: string;
    sort: string;
    phonePattern: any;
    phoneCombs: string | undefined;
    Service: PhoneService;
    isFavorite: boolean;
    setIsFavorite: React.Dispatch<React.SetStateAction<boolean>>;
    setType: React.Dispatch<React.SetStateAction<string[]>>;
    setTariff: React.Dispatch<React.SetStateAction<string[]>>;
    setCategories: React.Dispatch<React.SetStateAction<string[]>>;
    setRegion: React.Dispatch<React.SetStateAction<string[]>>;
    setPattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhone: React.Dispatch<React.SetStateAction<string | undefined>>;
    setGridType: React.Dispatch<React.SetStateAction<string>>;
    setGridTypePrev: React.Dispatch<React.SetStateAction<string>>;
    setSort: React.Dispatch<React.SetStateAction<string>>;
    setCssClassGrouped: React.Dispatch<React.SetStateAction<boolean>>;
    setPhonePattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhoneCombs: React.Dispatch<React.SetStateAction<string | undefined>>;
}
