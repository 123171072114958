import {
    Row,
    Col,
    Typography,
    Back,
    Title,
    Form,
    FIOField,
    InputField,
    ButtonField,
    Button,
    PhoneField
} from "@bezlimit/bezlimit-ui";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import useSearchRoute from "../../../../core/hooks/useSearchRoute";
import UserService from "../../../../services/UserService";
import Logo from "../../../blocks/header/logo";
import useFormHandler from "../../../../core/hooks/useFormHandler";
import CheckboxField from "../../../fields/checkbox";
import {Rules} from "../../../forms/rules";
import {axiosFetchBuilder} from "../../../../core/Axios";
import {useContext} from "react";
import {ModalContext} from "../../../../core/Contexts";
// components
import Error from "./errorModal";
import Success from "./successModal";

export default observer(function ClearMyDataPage() {
    const navigate = useNavigate();
    const route = useSearchRoute(UserService.token);
    const [form] = Form.useForm();
    const formInstance = useFormHandler(form);
    const contextModal = useContext(ModalContext);

    const onCancel = () => {
        contextModal?.setVisible(false);
        contextModal?.setCssClass("");
        contextModal?.setMaskStyle(undefined);
    };

    const onFinish = async ({agree, phone, ...values}: any) => {
        const styles = {
            background: "rgba(255, 255, 255, 0.05)",
            // @ts-ignore
            "-webkit-backdrop-filter": " blur(50px)",
            "backdrop-filter": " blur(50px)"
        };
        try {
            await axiosFetchBuilder({
                url: "/feedback/clear-data",
                loading: false,
                method: "POST",
                data: {
                    ...values,
                    source: "web",
                    phone: _.replace(_.replace(phone, /\s|-|\(|\)/gim, ""), /\+7/g, "")
                }
            });
            contextModal?.setChildren(<Success onCancel={onCancel} />);
            contextModal?.setVisible(true);
            contextModal?.setCssClass(" modal-success-clear");
            contextModal?.setMaskStyle(styles);
        } catch (e) {
            contextModal?.setChildren(<Error onCancel={onCancel} />);
            contextModal?.setVisible(true);
            contextModal?.setCssClass(" modal-error-clear");
            contextModal?.setMaskStyle(styles);
        } finally {
            form.resetFields();
        }
    };

    return (
        <Typography as="div" className="bl-clear-my-data">
            <Row gutter={[16, 16]}>
                <Back navigate={() => navigate("/")} />
                <Col span={24}>
                    <Logo />
                    <Title className="bl-title" title={_.get(route, "title")} />
                    <Typography as="div" className="subtitle">
                        {"Укажите известную информацию \nдля запроса на удаление аккаунта:"}
                    </Typography>
                </Col>
                <Col span={24}>
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        onFinish={onFinish}
                        autoComplete="off"
                    >
                        <FIOField formItemProps={{label: "ФИО*", name: "name"}} />
                        <Row gutter={[16, 0]}>
                            <Col xs={24} lg={12}>
                                <InputField
                                    formItemProps={{
                                        label: "ID-пользователя STORE*",
                                        name: "user_id",
                                        rules: [Rules.required],
                                        normalize: (value) => _.replace(value, /[^\d]/gim, "")
                                    }}
                                />
                            </Col>
                            <Col xs={24} lg={12}>
                                <InputField
                                    formItemProps={{
                                        label: "Логин*",
                                        name: "login",
                                        rules: [Rules.required]
                                    }}
                                />
                            </Col>
                            <Col xs={24} lg={12}>
                                <PhoneField
                                    formItemProps={{label: "Контактный телефон*", name: "phone"}}
                                />
                            </Col>
                            <Col xs={24} lg={12}>
                                <InputField
                                    formItemProps={{
                                        label: "Электронная почта*",
                                        name: "email",
                                        rules: [Rules.email(), Rules.max(100)]
                                    }}
                                />
                            </Col>
                        </Row>
                        <InputField.TextArea
                            formItemProps={{
                                label: "Причина удаления (комментарий)*",
                                name: "comment",
                                rules: [Rules.requiredTextArea()]
                            }}
                        />
                        <CheckboxField
                            name="agree"
                            items={[
                                {
                                    name: "Согласие на обработку персональных данных",
                                    filter: "true"
                                }
                            ]}
                        />

                        <Typography as="div" className="actions">
                            <ButtonField
                                fields={["name", "login", "phone", "email", "comment", "agree"]}
                            >
                                Отправить
                            </ButtonField>
                            <Button type="default" className="cancel" onClick={() => navigate("/")}>
                                Отменить
                            </Button>
                        </Typography>
                    </Form>
                </Col>
            </Row>
        </Typography>
    );
});
