import React from "react";
import {Icon, Typography, Space} from "@bezlimit/bezlimit-ui";
import "./style.scss";

export default React.memo(function Phone({category, phone}: IProps) {
    return (
        <Typography as="div" className="bl-numbers-list-info-phone">
            <Space size={6}>
                <Icon name={category} size="16" />
                <Typography size={24} fontFamily="alumna" color="#fff" className="phone">
                    {phone}
                </Typography>
            </Space>
        </Typography>
    );
});

interface IProps {
    category: string;
    phone: number;
}
