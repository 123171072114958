import {Button} from "antd";
import {Icon, Typography} from "@bezlimit/bezlimit-ui";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import {useEffect} from "react";
import _ from "lodash";

interface INotifications {
    noticeCount: number;
}

export default observer(function Notifications({noticeCount}: INotifications) {
    const navigate = useNavigate();

    const click = () => {
        navigate("/notifications");
    };

    useEffect(() => {
        if (!_.isEqual(noticeCount, 0)) {
            document.querySelector(".bl-notifications")?.classList.add("bl-news__move-shake");
            setTimeout(() => {
                document
                    .querySelector(".bl-notifications")
                    ?.classList.remove("bl-news__move-shake");
            }, 3000);
        }
    }, [noticeCount]);

    return (
        <Typography as="div" className="bl-notifications">
            <Button
                className="btn-default"
                onClick={() => {
                    click();
                }}
                icon={<Icon className="bl-news__icon" name="notifications"/>}
            />
            {!_.isEqual(noticeCount, 0) && (
                <Typography className="bl-news__red-circle" as={"div"}>
                    {noticeCount}
                </Typography>
            )}
        </Typography>
    );
});
