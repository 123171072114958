import {Form, Input} from "@bezlimit/bezlimit-ui";
import {FormInstance, FormItemProps} from "antd";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
import "./style.scss";
// rules
import {Rules} from "../../forms/rules";
import {KeyboardEventHandler, useState} from "react";
import {IReservationsRequest} from "../../../core/models/Reservations";

const {Search} = Input;

export default function NumberField({
    name,
    title,
    allowClear,
    isRules,
    onSearch,
    onPressEnter,
    formInstance,
    updateList,
    ...props
}: IProps) {
    const {t} = useTranslation();
    const [isClearIcon, setIsClearIcon] = useState(true);

    const onChangeNumberFormat = () => {
        const fields = formInstance?.getFieldsValue(["phone"]).phone;
        isClearIcon &&
            document
                .getElementsByClassName("ant-input-clear-icon")[0]
                .addEventListener("click", () => {
                    updateList(undefined, true);
                });
        fields.trim().length > 0 && setIsClearIcon(false);
    };

    return (
        <Form.Item
            {...props}
            className={`field-number-search field-${name ?? "number"}`}
            name={name ?? "number"}
            rules={isRules ? [Rules.required()] : undefined}
        >
            <NumberFormat<any>
                customInput={onSearch ? Search : Input}
                autoComplete={`new-${name ?? "number"}`}
                format="##########"
                placeholder={t(title ?? "Номер телефона")}
                allowClear
                {...(onSearch ? {onSearch} : undefined)}
                {...(onPressEnter ? {onPressEnter} : undefined)}
                onChange={() => {
                    onChangeNumberFormat();
                }}
            />
        </Form.Item>
    );
}

interface IProps extends FormItemProps<any> {
    name?: string;
    title?: string;
    allowClear?: boolean;
    isRules?: boolean;
    onSearch?: (value: any) => void;
    onPressEnter?: (e: KeyboardEventHandler<HTMLInputElement>) => void;
    formInstance?: FormInstance<any>;
    updateList: (request?: IReservationsRequest, loading?: boolean, reset?: boolean) => void;
}
