import { useEffect, useState } from 'react';

export default function useResize() {
  const [state, setState] = useState({
    width: 0,
    height: 0,
  });

  useEffect(() => {
    setState({
      width: document.body.clientWidth,
      height: document.body.clientHeight,
    });
    window.addEventListener('resize', () => {
      setState({
        width: document.body.clientWidth,
        height: document.body.clientHeight,
      });
    });
  }, []);

  return state;
}
