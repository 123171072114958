import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Form, Input, Skeleton, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {MobXProviderContext, observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext, ModalContext} from "../../../core/Contexts";
import {toGroupNumbers, withCharacterCb} from "../../../core/functions/inputNumber";
// services
import PhonesGlobalService from "../../../services/PhonesGlobalService";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
import useOnCancel from "../../../core/hooks/useOnCancel";
import {IPhonesRequest} from "../../../core/models/Phones";
// components
import SmartField from "../../fields/smart";
import Error from "../../modal/error";
import mode from "../../../images/mode.png";
import {makeAutoObservable} from "mobx";
import NumberFilterMode from "../../modal/numberFilterMode";
import PhonesFiltersService from "../../../services/PhonesFiltersService";
import {isMobile} from "react-device-detect";

type TIsPhonePattern = {
    phone_pattern: string | undefined;
    phone_combs: string | undefined;
    searchСounterСlick: number;
    argModalClose: number;
    isFilters: boolean;
};

export const isPhonePattern: TIsPhonePattern = makeAutoObservable({
    phone_pattern: "",
    phone_combs: "",
    searchСounterСlick: 0,
    argModalClose: 0,
    isFilters: false
});

export default React.memo(
    observer(function NumberSearchForm({
        type,
        tariff,
        categories,
        region,
        pattern,
        phonePattern,
        phoneCombs,
        sort,
        isFavorite,
        setIsFavorite,
        setPhonePattern,
        setPhoneCombs
    }: IProps) {
        const {store} = useContext(MobXProviderContext);
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const [pageLoading, setPageLoading] = useState(false);
        const ref = useRef(null);
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        const onCancel = useOnCancel(formInstance);
        const [init, setInit] = useState(false);
        const timeout: any = useRef();
        const [enter, setEnter] = useState(false);

        const onFinish = async (values: IPhonesRequest) => {
            clearTimeout(timeout.current);
            await onSubmit(values);
        };

        useEffect(() => {
            const searchButton: any = document.body.querySelector(
                ".bl-numbers-search-form__search-button"
            );
            _.gte(isPhonePattern.argModalClose, 1) && searchButton.click();
        }, [isPhonePattern.argModalClose]);

        const onSubmit = async ({phone_pattern, phone_combs}: IPhonesRequest) => {
            isPhonePattern.phone_combs = phone_combs;
            isPhonePattern.phone_pattern = phone_pattern;
            isPhonePattern.searchСounterСlick = isPhonePattern.searchСounterСlick + 1;
            isPhonePattern.isFilters = true;
            try {
                store.pageLoading = true;
                await PhonesGlobalService.fetchAll(
                    {
                        type,
                        tariff,
                        categories,
                        region,
                        pattern,
                        phone_pattern,
                        phone_combs,
                        sort,
                        general_number: PhonesFiltersService.phonesFilters.general_number
                    },
                    {loading: false}
                );
            } catch (e: any) {
                onError();
            } finally {
                store.pageLoading = false;
            }
        };

        const onValuesChange = (changedValues: any) => {
            if (changedValues.phone_pattern) {
                setPhonePattern(
                    _.chain(changedValues.phone_pattern)
                        .reduce<string[]>((res, i) => {
                            (res || (res = [])).push(i ? i.toLocaleUpperCase() : "");
                            return res;
                        }, [])
                        .valueOf()
                );
            }
        };

        const onError = () => {
            contextModal?.setVisible(true);
            contextModal?.setCssClass(" ");
            contextModal?.setChildren(<Error />);
            contextModal?.setOnCancel(() => onCancel);
        };

        const onClick = () => {
            contextModal?.setCssClass(" ");
            contextModal?.setVisible(true);
            contextModal?.setChildren(
                <NumberFilterMode isFavorite={isFavorite} setIsFavorite={setIsFavorite} />
            );
        };

        useEffect(() => {
            const fields = formInstance.getFieldsValue();
            fields["phone_pattern"] = phonePattern;
            formInstance.setFieldsValue(fields);
        }, [phonePattern]);

        useEffect(() => {
            toGroupNumbers(ref, "phone_pattern", formInstance, withCharacterCb);
        }, [ref.current]);

        useEffect(() => {
            setPageLoading(store.pageLoading);
        }, [store.pageLoading]);

        useEffect(() => {
            if (init && !contextModal?.visible && isMobile && !enter) {
                clearTimeout(timeout.current);
                timeout.current = setTimeout(() => {
                    (
                        document.body.querySelector(".bl-numbers-search-form__search-button") as any
                    )?.click();
                }, 3000);
            }
            setTimeout(() => {
                setInit(true);
                setEnter(false);
            }, 1000);
        }, [phonePattern, phoneCombs]);

        useEffect(() => {
            document.addEventListener("keyup", (event) => {
                if (
                    event.code === "Enter" ||
                    event.code === "NumpadEnter" ||
                    event.code === "vk_enter"
                ) {
                    clearTimeout(timeout.current);
                    event.preventDefault();
                    (
                        document.body.querySelector(".bl-numbers-search-form__search-button") as any
                    )?.click();
                    setEnter(true);
                }
            });
        }, []);

        return (
            <div ref={ref}>
                <Typography as="div" className="bl-numbers-search-form">
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="ten-for-bezlimit"
                        onFinish={onFinish}
                        onValuesChange={onValuesChange}
                        autoComplete="off"
                        initialValues={{
                            phone_pattern: phonePattern,
                            phone_combs: phoneCombs
                        }}
                    >
                        <Space
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 16}
                            direction="vertical"
                        >
                            <Space
                                size={_.gte(contextLayout?.width ?? 0, 768) ? 8 : 8}
                                direction="vertical"
                            >
                                <Form.Item
                                    className="field-phone_pattern"
                                    name="phone_pattern"
                                    normalize={(value) => {
                                        const regex = /[0-9abcnABCN]+/gim;
                                        const arr = _.chain(
                                            _.map(value, (i) => (i && _.size(i) ? i : "_"))
                                                .join("")
                                                .replace(/(\+7)/gm, "")
                                                .split("")
                                                .map((i) =>
                                                    _.isEqual(i, "_")
                                                        ? ""
                                                        : (_.isEqual(i, "а") && !isMobile) ||
                                                          (_.isEqual(i, "А") && !isMobile)
                                                        ? (i = "A")
                                                        : (_.isEqual(i, "ф") && !isMobile) ||
                                                          (_.isEqual(i, "Ф") && !isMobile)
                                                        ? (i = "A")
                                                        : (_.isEqual(i, "в") && !isMobile) ||
                                                          (_.isEqual(i, "В") && !isMobile)
                                                        ? (i = "B")
                                                        : (_.isEqual(i, "и") && !isMobile) ||
                                                          (_.isEqual(i, "И") && !isMobile)
                                                        ? (i = "B")
                                                        : (_.isEqual(i, "с") && !isMobile) ||
                                                          (_.isEqual(i, "С") && !isMobile)
                                                        ? (i = "C")
                                                        : (_.isEqual(i, "т") && !isMobile) ||
                                                          (_.isEqual(i, "Т") && !isMobile)
                                                        ? (i = "N")
                                                        : (_.isEqual(i, "н") && !isMobile) ||
                                                          (_.isEqual(i, "Н") && !isMobile)
                                                        ? (i = "N")
                                                        : i
                                                )
                                        )
                                            .filter((i) => {
                                                if (i) {
                                                    return !!i.match(regex);
                                                } else {
                                                    return _.isNil(i) || _.isEmpty(i);
                                                }
                                            })
                                            .filter((i, k) => _.lt(k, 10))
                                            .valueOf();

                                        return arr;
                                    }}
                                >
                                    <Input.Number
                                        count={10}
                                        type={"text"}
                                        inputMode={"text"}
                                        pattern={"[0-9ABCN]"}
                                        autoComplete={"off"}
                                        placeholder="N"
                                    />
                                </Form.Item>
                                <Typography
                                    as="div"
                                    size={14}
                                    color="rgba(255,255,255,.5)"
                                    className="helper-text"
                                >
                                    {t("Для фильтра можно использовать ABCN или числа")}
                                </Typography>
                            </Space>
                            <Space
                                size={_.gte(contextLayout?.width ?? 0, 768) ? 8 : 8}
                                direction="vertical"
                                className="smart-container"
                            >
                                <SmartField
                                    formInstance={formInstance}
                                    phoneCombs={phoneCombs}
                                    setPhoneCombs={setPhoneCombs}
                                />
                                <Typography
                                    as="div"
                                    size={14}
                                    color="rgba(255,255,255,.5)"
                                    className="helper-text"
                                >
                                    {t(
                                        "Введите суммарно до 10 цифр для подбора номера с комбинациями в разных местах номера"
                                    )}
                                </Typography>
                            </Space>
                            <Form.Item noStyle shouldUpdate>
                                {() => {
                                    return (
                                        <Space direction="horizontal" size={24} className="actions">
                                            {!!_.gte(contextLayout?.width ?? 0, 768) && (
                                                <>
                                                    {pageLoading ? (
                                                        <Skeleton.Button
                                                            active={true}
                                                            className="btn-skeleton"
                                                        />
                                                    ) : (
                                                        <Button
                                                            type="primary"
                                                            className="mode"
                                                            icon={<img src={mode} alt="" />}
                                                            onClick={onClick}
                                                        >
                                                            Режим фильтров
                                                        </Button>
                                                    )}
                                                </>
                                            )}

                                            {pageLoading ? (
                                                <Skeleton.Button
                                                    active={true}
                                                    className="btn-skeleton"
                                                />
                                            ) : _.gte(contextLayout?.width ?? 0, 768) ? (
                                                <Button
                                                    type="primary"
                                                    className="bl-numbers-search-form__search-button"
                                                    onClick={() => {
                                                        (
                                                            document.body.querySelector(
                                                                ".btn-filter"
                                                            ) as any
                                                        )?.click();
                                                    }}
                                                >
                                                    {t("Показать номера")}
                                                </Button>
                                            ) : (
                                                <Button
                                                    type="primary"
                                                    htmlType="submit"
                                                    className="bl-numbers-search-form__search-button"
                                                >
                                                    {t("Показать номера")}
                                                </Button>
                                            )}
                                        </Space>
                                    );
                                }}
                            </Form.Item>
                        </Space>
                    </Form>
                </Typography>
            </div>
        );
    })
);

interface IProps {
    phone: string | undefined;
    type: string[];
    tariff: string[];
    categories: string[];
    region: string[];
    pattern: string[];
    phonePattern: string[];
    phoneCombs: string | undefined;
    sort: string;
    isFavorite: boolean;
    setIsFavorite: React.Dispatch<React.SetStateAction<boolean>>;
    setType: React.Dispatch<React.SetStateAction<string[]>>;
    setTariff: React.Dispatch<React.SetStateAction<string[]>>;
    setCategories: React.Dispatch<React.SetStateAction<string[]>>;
    setRegion: React.Dispatch<React.SetStateAction<string[]>>;
    setPattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhone: React.Dispatch<React.SetStateAction<string | undefined>>;
    setPhonePattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhoneCombs: React.Dispatch<React.SetStateAction<string | undefined>>;
}
