//import React, {useContext, useEffect, useRef} from "react";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {Block, Space, Typography, Button} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
// forms
import EditEmailForm from "../../../forms/editEmail";
// core
import {LayoutContext, ModalContext} from "../../../../core/Contexts";
//store
import Store from "../../../../stores";
// services
import UserService from "../../../../services/UserService";
//components
import EmailCodeConfirmation from "../../../modal/emailCodeConfirmation";
import Success from "../../../modal/success";
import Error from "../../../modal/error";

import "./style.scss";

export const ConfirmEmail : React.FC = observer(() => {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);
    const contextModal = useContext(ModalContext);

    const onSubmit = async ({code, email}: any) => {
        await UserService.putUsers({email_code: code, email});
        contextModal?.setChildren(
            <Success
                title={
                    <Typography as="span">
                        {t("E-mail успешно ")}
                        <br />
                        {t("подтвержден")}
                    </Typography>
                }
            />
        );
        await UserService.getUsers();
    };

    const onConfirm = async () => {
        const email = Store.user.profile?.email;
        try {
            await UserService.putUsers({email});
            contextModal?.setVisible(true);
            contextModal?.setChildren(<EmailCodeConfirmation email={email} onSubmit={onSubmit} />);
            await UserService.getUsers();
        } catch (e: any) {
            contextModal?.setChildren(<Error />);
        }
    };
    
    return (
        <Block className="bl-confirm-email">
            <Space
                size={_.gte(contextLayout?.width ?? 0, 768) ? 40 : 24}
                direction="vertical"
                align="start"
            >
                <Typography as="div">
                    <Space size={8} direction="vertical" align="start">
                        <Typography
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                            level={2}
                            color="#ffffff"
                        >
                            {Store.user.profile?.email ? t("Подтвердите свой E-mail") : t("Привяжите E-mail")}
                        </Typography>
                        <Typography className="subtitle">
                            {t("Подтвердите свою электронную почту, чтобы редактировать свои данные: пароль, логин, а также для восстановления доступа в случае утери.")}
                        </Typography>
                    </Space>
                </Typography>
                {!Store.user.profile?.email && <EditEmailForm />}
                {Store.user.profile?.email && (
                    <Space size={_.gte(contextLayout?.width, 768) ?? 0 ? 24 : 17} direction="vertical">
                        <Button
                            style={{width: "100%"}}
                            type="primary"
                            onClick={onConfirm}
                            block
                        >
                            {t("Подтвердить")}
                        </Button>
                    </Space>
                )}
            </Space>
        </Block>
    );
});
