// core
import {axiosFetchBuilder} from "../core/Axios";
import {ITariffRequest, ITariffsAvailable} from "../core/models/Tariffs";
// store
import Store from "../stores";

enum Path {
    tariffs = "phones/tariffs"
}

class TariffsService {
    [Symbol.toStringTag] = this.constructor.name;
    private static readonly tariffs = Store.tariff;

    static get items() {
        return TariffsService.tariffs.items;
    }

    private static async getTariffsItems(request?: ITariffRequest): Promise<ITariffsAvailable[]> {
        return await axiosFetchBuilder({
            url: Path.tariffs,
            params: {
                ...request,
                expand: "desc"
            }
        });
    }

    static async fetchTariffAvailableItems() {
        const res: ITariffsAvailable[] = await TariffsService.getTariffsItems();
        TariffsService.tariffs.items = res;
    }

    static async getTariffsByPhone(request?: ITariffRequest): Promise<ITariffsAvailable[]> {
        return await TariffsService.getTariffsItems(request);
    }
}

export default TariffsService;
