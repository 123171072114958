import i18n from "i18next";
import _ from "lodash";
// core
import {axiosFetchBuilder} from "../core/Axios";
import {IHints, ITranslateResponse} from "../core/models/Translate";
// store
import Store from "../stores";

enum Path {
    translates = "/system/translates",
    hints = "/system/hints"
}

class SettingsService {
    [Symbol.toStringTag] = this.constructor.name;
    private static readonly settings = Store.settings;

    static async translate(locale: string = Store.settings.locale) {
        try {
            localStorage.setItem("locale", locale);
            const dictionary = SettingsService.settings.dictionaries.get(locale);

            if (dictionary) {
                i18n.addResourceBundle(locale, "translation", dictionary, true, true);
            } else {
                const res: ITranslateResponse[] = await axiosFetchBuilder({
                    url: Path.translates,
                    loading: false,
                    params: {
                        expand: "messages",
                        fields: "code, title, messages.message, messages.translation"
                    },
                    headers: {
                        Authorization: process.env.REACT_APP_BASIC_AUTH_HEADER!
                    }
                });

                _.chain(res)
                    .filter((i) => i.code === locale)
                    .forEach(({code, title, messages}) => {
                        const translation: any = _.reduce(
                            messages,
                            (res, {message, translation}) => {
                                res = {...res, ...{[message]: translation}};
                                return res;
                            },
                            {}
                        );

                        i18n.addResourceBundle(code, "translation", translation, true, true);
                        SettingsService.settings.dictionaries.set(code, {
                            title,
                            messages: translation
                        });
                    })
                    .valueOf();
            }

            i18n.changeLanguage(locale);
            SettingsService.settings.locale = locale;
        } catch (err) {
            throw err;
        }
    }

    static async getHints(code: string) {
        const res: IHints = await axiosFetchBuilder({
            url: Path.hints,
            params: {code, source: "web"}
        });
        SettingsService.settings.hints = res.items[0];
    }
}

export default SettingsService;
