import React, {useContext, useEffect} from "react";
import {observer} from "mobx-react";
import {Block, Button, Col, Row, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import "./style.scss";
import {LayoutContext} from "../../../../core/Contexts";
import _ from "lodash";
import imgFiles from "../../../../images/img_files.png";
import imgFilesMob from "../../../../images/img_files_mob.png";
import imgSaveCopy from "../../../../images/img_save _copy.svg";
import {isIOS} from "react-device-detect";
import bgAdvertisementIos from "../../../../images/bg_advertisement_ios.gif";

export const LotteryAdvertisement: React.FC = observer(() => {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);

    const onClick = () => {
        window.open(decodeURI(" https://disk.yandex.ru/d/upRoHABSpyghRQ"), "_blank");
    };

    useEffect(() => {
        document.querySelectorAll("video").forEach((v) => {
            v.setAttribute("pip", "false");
        });
    }, []);

    return (
        <Block className="bl-widget-advertisement">
            <div className="vidContain">
                {_.isEqual(isIOS, true) ? (
                    <img
                        src={bgAdvertisementIos}
                        alt=""
                        className="bl-widget-advertisement-is-ios"
                    />
                ) : (
                    <video autoPlay muted loop>
                        {_.gte(contextLayout?.width ?? 0, 821) ? (
                            <source src="abstract_web.mp4" type="video/mp4" />
                        ) : (
                            <source src="abstract_mob.mp4" type="video/mp4" />
                        )}
                    </video>
                )}

                <Row gutter={[8, 8]} className="caption">
                    <Col span={24}>
                        <Typography className="title">{t("Все рекламные материалы ")}</Typography>
                        <Typography className="title">{t("в одном месте!")}</Typography>
                    </Col>
                    {_.gte(contextLayout?.width ?? 0, 821) ? (
                        <Col span={24}>
                            <Typography className="description">
                                {t("Видео, плакаты, флаеры и картинки ")}
                            </Typography>
                            <Typography className="description">
                                {t("для привлечения новых пользователей")}
                            </Typography>
                        </Col>
                    ) : (
                        <Col span={24}>
                            <Typography className="description">
                                {t("Видео, плакаты, флаеры")}
                            </Typography>
                            <Typography className="description">
                                {t("и картинки для привлечения")}
                            </Typography>
                            <Typography className="description">
                                {t("новых пользователей")}
                            </Typography>
                        </Col>
                    )}

                    <Col span={24} className="col-btn-linc">
                        <Button
                            type="primary"
                            className="btn-linck"
                            icon={<img src={imgSaveCopy} alt="" width={20} height={20} />}
                            onClick={onClick}
                        >
                            {t("Перейти")}
                        </Button>
                    </Col>
                </Row>
                {_.gte(contextLayout?.width ?? 0, 821) ? (
                    <img src={imgFiles} alt="" width={251} height={195} className="img-files" />
                ) : (
                    <img
                        src={imgFilesMob}
                        alt=""
                        width={172}
                        height={146}
                        className="img-files_mob"
                    />
                )}
            </div>
        </Block>
    );
});
