import React, {useContext} from "react";
import {Typography, Space, Button} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import "./style.scss";
//images
import ErrorImg from "../../images/errorImg";
// core
import {LayoutContext, ModalContext} from "../../../core/Contexts";
import {useTranslation} from "react-i18next";
import attention from "../../../images/attention_sessions.png";

export default React.memo(function ImportantModal({onPhoneEdit}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const contextModal = useContext(ModalContext);
    const {t} = useTranslation();

    const onClick = () => {
        onPhoneEdit();
        contextModal?.setMaskStyle(undefined);
        contextModal?.setCssClass("");
    };

    return (
        <Typography as="div" className="bl-important">
            <Space
                size={_.gte(contextLayout?.width ?? 0, 768) ? 32 : 18}
                direction="vertical"
                align="center"
            >
                {_.gte(contextLayout?.width ?? 0, 768) ? (
                    <ErrorImg />
                ) : (
                    <img src={attention} width={70} alt="attention" />
                )}

                <Typography as="div" className="title">
                    Важно!
                </Typography>
                {_.gte(contextLayout?.width ?? 0, 768) ? (
                    <Typography as="div" className="text">
                        <span>{t("При изменении номера телефона вы ")}</span>
                        <span className="gold">{t("безвозвратно ")}</span>
                        <span>
                            {
                                "\nпотеряете ежедневные начисления за номера, подключенные \nпо акции «Не плати за связь»"
                            }
                        </span>
                    </Typography>
                ) : (
                    <Typography as="div" className="text">
                        <span>{t("При изменении номера телефона \nвы ")}</span>
                        <span className="gold">{t("безвозвратно ")}</span>
                        <span>
                            {
                                "потеряете \nежедневные начисления за номера, \nподключенные по акции \n«Не плати за связь»"
                            }
                        </span>
                    </Typography>
                )}

                <Button type="primary" onClick={onClick}>
                    Подтвердить
                </Button>
            </Space>
        </Typography>
    );
});

interface IProps {
    onPhoneEdit: () => void;
}
