import React, {useEffect, useRef, useContext} from "react";
import {observer} from "mobx-react";
import _ from "lodash";
import {Row, Col, Title, Block, Typography} from "@bezlimit/bezlimit-ui";
import moment from "moment";
//component
import OutputNumberBonuses from "../widgets/outputNumberBonuses";
import ReplenichNumberBonuses from "../widgets/replenichNumberBonuses";
import {Loyalty} from "./loyalty";
import MyBonuses from "./myBonuses";
import PaymentsHistory from "./paymentsHistory";
import PayoutsHistory from "./payoutsHistory";
import LoyaltyBannerSlider from "./bannerSlider";
import DealersBonuses from "./dealersBonuses";
// services
import BonusHistoryService from "../../../services/BonusHistoryService";
import BonusesService from "../../../services/BonusesService";
// core
import {LayoutContext} from "../../../core/Contexts";
import LoyaltyService from "../../../services/LoyaltyService";
//store
import Store from "../../../stores";

import "./style.scss";

export const Bonuses: React.FC<IProps> = observer(({setVisibleLoyalty}) => {
    const mountedRef = useRef(true);
    const contextLayout = useContext(LayoutContext);

    const date30DaysBefore = moment().add(-30, "days");
    const paymentsService = BonusHistoryService.getObject("payments");
    const showPayments = !!(paymentsService?.items || []).find((i) =>
        moment(i.created_at).isAfter(date30DaysBefore)
    );
    const payoutsService = BonusHistoryService.getObject("payouts");
    const showPayouts = !!(payoutsService?.items || []).find((i) =>
        moment(i.created_at).isAfter(date30DaysBefore)
    );

    const getLoyaltyInfo = async () => {
        if (!mountedRef.current) {
            return;
        }
        await LoyaltyService.getLoyalty();
    };
    const getSummary = async () => {
        if (!mountedRef.current) {
            return;
        }
        await BonusesService.summary();
    };

    useEffect(() => {
        BonusHistoryService.fetchBonusesItems();
        getSummary();
        getLoyaltyInfo();
        return () => {
            mountedRef.current = false;
        };
    }, []);

    return (
        <div className="bl-bonuses-page">
            <Title title={`${_.gte(contextLayout?.width, 768) ?? 0 ? "Мои бонусы" : "Бонусы"}`} />
            <Row className="bonuses-content" gutter={[16, {xs: 16, sm: 16, md: 16, lg: 24}]}>
                <Col lg={!_.isNil(Store.user.loyalty) ? 16 : 24} md={24} xs={24}>
                    <Loyalty setVisibleLoyalty={setVisibleLoyalty} />
                </Col>
                {!_.isNil(Store.user.loyalty) && (
                    <Col lg={8} md={24} xs={24}>
                        <LoyaltyBannerSlider />
                    </Col>
                )}

                <Col lg={16} md={24} xs={24}>
                    <MyBonuses />
                </Col>
                <Col lg={8} md={24} xs={24}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <ReplenichNumberBonuses />
                        </Col>
                        <Col span={24} className="bonuses-output-number">
                            <OutputNumberBonuses />
                        </Col>
                    </Row>
                </Col>
                {showPayments ? (
                    <Col span={24}>
                        <PaymentsHistory Service={paymentsService} />
                    </Col>
                ) : (
                    <Col span={24}>
                        <Block className="bl-payments-history">
                            <Row gutter={[16, 16]}>
                                <Col>
                                    <Typography className="payments-title">
                                        История начислений
                                    </Typography>
                                    <Typography className="payments-subtitle">
                                        У вас нет начислений за последние 30 дней! Детальную
                                        информацию по всему периоду можно посмотреть в{" "}
                                        <a href="https://bez.li/StoreApp" target="_blank">
                                            мобильном приложении STORE
                                        </a>
                                    </Typography>
                                </Col>
                            </Row>
                        </Block>
                    </Col>
                )}
                {showPayouts && (
                    <Col span={24}>
                        <PayoutsHistory Service={payoutsService} />
                    </Col>
                )}
                {Store.user.level?.level < 2 && (
                    <Col span={24}>
                        <DealersBonuses showPayments={showPayments} />
                    </Col>
                )}
            </Row>
        </div>
    );
});

interface IProps {
    setVisibleLoyalty: React.Dispatch<React.SetStateAction<boolean>>;
}
