import {Button, Icon, Space, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
//images
import SuccessImg from "../../../../../images/gal.png";
import backPng from "../../../../../images/back-callection.png";
import {useContext} from "react";
import {LayoutContext, ModalContext} from "../../../../../core/Contexts";
import {useNavigate} from "react-router";
import UserService from "../../../../../services/UserService";
import _ from "lodash";

export default function Success({onCancel}: IProps) {
    const contextModal = useContext(ModalContext);
    const navigate = useNavigate();
    const contextLayout = useContext(LayoutContext);

    const onClick = () => {
        !UserService.token ? navigate("/") : navigate("/home");
        contextModal?.setVisible(false);
    };
    return (
        <Typography as="div" className="modal-success-promised">
            <Button className="close-btn" onClick={onCancel}>
                <Icon name={"close"} size={"26px"} />
            </Button>
            <Button onClick={onClick} className="back-arrow">
                <img src={backPng} alt="" width={28} /> На главную
            </Button>
            <Typography as="div" className="promised-payment-modal-division-first-block">
                <Space direction="vertical" size={16}>
                    <img src={SuccessImg} alt={"star"} height={164} />
                    <Typography color={"#FFF"} className="title-success" fontFamily="alumna">
                        {"Заявка на удалениет\nаккаунта принята!"}
                    </Typography>
                    <Typography color={"#FFF"} className="desc-success">
                        {_.gt(contextLayout?.width, 768)
                            ? "Перед удалением персональных данных с Вами свяжется наш\nменеджер для подтверждения запроса и идентификации.\nВремя рассмотрения заявки - 45 дней."
                            : "Перед удалением персональных данных\nс Вами свяжется наш менеджер\nдля подтверждения запроса\nи идентификации.\nВремя рассмотрения заявки - 45 дней."}
                    </Typography>
                    <Button type="primary" onClick={onCancel} className="button-close">
                        Закрыть
                    </Button>
                </Space>
            </Typography>
        </Typography>
    );
}

interface IProps {
    onCancel: () => void;
}
