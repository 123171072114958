import React, {useContext, useEffect} from "react";
import {observer} from "mobx-react";
import {Block, Button, Col, Row, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import "./style.scss";
import {LayoutContext} from "../../../../core/Contexts";
import _ from "lodash";
import imgFiles from "../../../../images/nps3.svg";
import imgRedirect from "../../../../images/img_redirect.svg";
import {isIOS} from "react-device-detect";
import bgAdvertisementIos from "../../../../images/nps4.gif";
import {useNavigate} from "react-router-dom";

export const Nps: React.FC = observer(() => {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/nps");
    };

    useEffect(() => {
        document.querySelectorAll("video").forEach((v) => {
            v.setAttribute("pip", "false");
        });
    }, []);

    return (
        <Block className="bl-widget-nps">
            <div className="vidContain">
                {_.isEqual(isIOS, true) ? (
                    <img
                        src={bgAdvertisementIos}
                        alt=""
                        className="bl-widget-advertisement-is-ios"
                    />
                ) : (
                    <video autoPlay muted loop>
                        <source src="nps1.mp4" type="video/mp4" />
                    </video>
                )}

                <Row gutter={[8, 8]} className="caption">
                    <Col span={24}>
                        <Typography className="title">{t("Не плати за связь!")}</Typography>
                    </Col>
                    <Col span={24}>
                        <Typography className="description">
                            {t("Пусть клиент работает на тебя!")}
                        </Typography>
                    </Col>

                    <Col span={24} className="col-btn-linc">
                        <Button
                            type="primary"
                            className="btn-linck"
                            icon={<img src={imgRedirect} alt="" width={14} height={14} />}
                            onClick={onClick}
                        >
                            {t("Подробнее")}
                        </Button>
                    </Col>
                </Row>
                {_.gt(contextLayout?.width ?? 0, 576) ? (
                    <img src={imgFiles} alt="" width={161} className="img-files" />
                ) : (
                    <img src={imgFiles} alt="" width={106} className="img-files" />
                )}
            </div>
        </Block>
    );
});
