// core
import {axiosFetchBuilder} from "../core/Axios";
// store
import Store from "../stores";
import {INewsNoticeCount, INotificationsResponse} from "../core/models/Notifications";

enum Path {
    notices = "/notifications/notices",
    newsCount = "/notifications/count",
    actions = "/notifications/actions"
}

class BellNotificationsService {
    private static readonly bell = Store.bellNotifications;
    [Symbol.toStringTag] = this.constructor.name;

    static get items() {
        return BellNotificationsService.bell.items;
    }
    static get meta() {
        return BellNotificationsService.bell.meta;
    }

    static get newsNoticeCount() {
        return BellNotificationsService.bell.newsNoticeCount;
    }

    static async fetchNotifyNotifications(perPage?: number, page?: number) {
        try {
            const res: INotificationsResponse = await axiosFetchBuilder({
                url: Path.notices,
                loading: false,
                loadingBtn: true,
                params: {
                    "per-page": perPage,
                    page
                }
            });

            if (BellNotificationsService.bell.items.length) {
                BellNotificationsService.bell.items = [
                    ...BellNotificationsService.bell.items,
                    ...res.items
                ];
            } else {
                BellNotificationsService.bell.items = res.items;
            }
            BellNotificationsService.bell.meta = res._meta;

            return res;
        } catch (err) {
            throw err;
        }
    }

    static async getNewsNoticeCount() {
        try {
            const res: INewsNoticeCount = await axiosFetchBuilder({
                url: Path.newsCount,
                params: {expand: "news_count,notice_count"}
            });

            BellNotificationsService.bell.newsNoticeCount = res;
        } catch (err) {
            throw err;
        }
    }

    static async postNewActions(notifications_id: (number | undefined)[]) {
        for (const notification_id of notifications_id) {
            try {
                await axiosFetchBuilder({
                    url: Path.actions,
                    method: "POST",
                    loading: false,
                    data: {notification_id, action: "view"}
                });
            } catch (err) {
                throw err;
            }
        }
    }
}

export default BellNotificationsService;
