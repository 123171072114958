import React, {useContext, useRef} from "react";
import {Button, Col, Form, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import {InterceptorContext, LayoutContext, ModalContext} from "../../../core/Contexts";
// services
import BonusesService from "../../../services/BonusesService";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
import useFieldFocus from "../../../core/hooks/useFieldFocus";
// components
import CardField from "../../fields/card";
import Error from "../../modal/error";
import SumOutputBonusesField from "../../fields/sumOutputBonuses";
import Success from "../../modal/success";
import RadioButtonGroup from "./radioButtonGroup";
import CodeConfirmationOutputBonuses from "../../modal/codeConfirmationOutputBonuses";
import Store from "../../../stores";
import {idDurationChangeHandler} from "../../blocks/duration";

export default React.memo(
    observer(function FormOutputBonuses() {
        const context = useContext(InterceptorContext);
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const ref = useRef(null);
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);

        const onChangeSumm = () => {
            const fields = form.getFieldsValue();
            fields.summ = form.getFieldsValue().replenichSum;
            form.setFieldsValue(fields);
        };

        useFieldFocus(ref, "card");

        const phoneStore = Store.user.personal_data?.phone.toString();

        const onSubmit = async ({code, card, sum, type}: any) => {
            const id = idDurationChangeHandler.idDuration;
            await BonusesService.postPayoutBonuses({
                code,
                card,
                sum,
                id,
                type
            });
            contextModal?.setChildren(
                <Success
                    title={
                        <Typography as="span">{t("Заявка на вывод бонусов оформлена")}</Typography>
                    }
                    subtitle={
                        <Typography as="span" className="bl-success-output">
                            {t("Заявка на вывод ")}
                            <span>{formInstance.getFieldValue("summ")}</span>
                            {t(" бонусов на карту ")}
                            <span>
                                {_.replace(formInstance.getFieldValue("card")!, /(\+7|\s|-)/gi, "")}
                            </span>
                            {t(" оформлена ")}
                        </Typography>
                    }
                />
            );
            contextModal?.setOnCancel(() =>
                document
                    .getElementsByClassName("ant-modal-close-x")[0]
                    .addEventListener("click", () => {
                        window.location.reload();
                    })
            );
        };

        const onFinish = async () => {
            const card = _.replace(formInstance.getFieldValue("card")!, /(\+7|\s|-)/gi, "");
            const sum = formInstance.getFieldValue("summ");
            try {
                const {id} = await BonusesService.postPayoutBonuses({card, sum, type: "card"});
                idDurationChangeHandler.idDuration = id;
                contextModal?.setChildren(
                    <CodeConfirmationOutputBonuses
                        card={formInstance.getFieldValue("card")}
                        phone={phoneStore}
                        sum={sum}
                        type="card"
                        onSubmit={onSubmit}
                    />
                );
            } catch (e: any) {
                contextModal?.setChildren(<Error />);
            }
        };

        return (
            <div ref={ref}>
                <Typography
                    as="div"
                    className="bl-replenishment-bonuses-form bl-output-bonuses-form"
                >
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="ten-for-bezlimit"
                        onFinish={onFinish}
                        autoComplete="off"
                        initialValues={{
                            card: "",
                            summ: ""
                        }}
                    >
                        <Space
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 8 : 24}
                            direction="vertical"
                        >
                            <CardField/>
                            <Space size={20} className="input-summ-bonuses">
                                <Row>
                                    <Col span={10}>
                                        <SumOutputBonusesField name="summ" form={form}/>
                                    </Col>
                                    <Col span={14}>
                                        <Typography size={12} color="#828282">
                                            {t("От 5000 бонусов")}
                                        </Typography>
                                    </Col>
                                </Row>
                            </Space>
                            <Typography
                                as="div"
                                className="bl-radio-button-group"
                                onChange={onChangeSumm}
                            >
                                <RadioButtonGroup />
                            </Typography>

                            <Form.Item noStyle shouldUpdate>
                                {({getFieldsValue, getFieldsError}) => {
                                    const {card, summ} = getFieldsValue();
                                    const formIsComplete =
                                        !!card &&
                                        !!summ &&
                                        !(_.replace(card!, /(\+7|\s|-)/gi, "").length < 13) &&
                                        summ >= 5000 &&
                                        BonusesService.accumulated - summ > 0;

                                    return (
                                        <Button
                                            onClick={onFinish}
                                            type={!formIsComplete ? "default" : "primary"}
                                            disabled={!formIsComplete}
                                            loading={context?.loadingBtn}
                                        >
                                            {t("Вывести")}
                                        </Button>
                                    );
                                }}
                            </Form.Item>
                        </Space>
                    </Form>
                </Typography>
            </div>
        );
    })
);
