import {makeAutoObservable} from "mobx";
// core
import {
    IPersonalDataResponse,
    IProfileResponse,
    IUserPersonalManagerResponse,
    IUsersResponse,
    ILoyalty,
    ISystemVars
} from "../core/models/User";
import {ILevel} from "../core/models/Levels";
import {IDealer} from "../core/models/Dealer";
// core

class User implements IUsersResponse {
    [Symbol.toStringTag] = this.constructor.name;
    private _token: string | null = null;
    private _id!: number;
    private _login!: string;
    private _profile!: IProfileResponse;
    private _personal_data!: IPersonalDataResponse;
    private _personal_manager!: IUserPersonalManagerResponse;
    private _dealer!: IDealer;
    private _level!: ILevel;
    private _loyalty!: ILoyalty;
    private _levelUserInfo: ILevel | undefined = undefined;
    private _systemVars: ISystemVars | undefined = undefined;
    private _storeCanBookForDelivery: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    get systemVars() {
        return this._systemVars;
    }

    set systemVars(value: ISystemVars | undefined) {
        this._systemVars = value;
    }

    get token() {
        return this._token;
    }

    set token(value: string | null) {
        this._token = value;
    }

    get id() {
        return this._id;
    }

    set id(value: number) {
        this._id = value;
    }

    get login() {
        return this._login;
    }

    set login(value: string) {
        this._login = value;
    }

    get profile() {
        return this._profile;
    }

    set profile(value: IProfileResponse) {
        this._profile = value;
    }

    get personal_data() {
        return this._personal_data;
    }

    set personal_data(value: IPersonalDataResponse) {
        this._personal_data = value;
    }

    get personal_manager() {
        return this._personal_manager;
    }

    set personal_manager(value: IUserPersonalManagerResponse) {
        this._personal_manager = value;
    }

    get level() {
        return this._level;
    }

    set levelUserInfo(value: ILevel | undefined) {
        this._levelUserInfo = value;
    }

    get levelUserInfo() {
        return this._levelUserInfo;
    }

    set level(value: ILevel) {
        this._level = value;
    }

    get dealer() {
        return this._dealer;
    }

    set dealer(value: IDealer) {
        this._dealer = value;
    }

    get loyalty() {
        return this._loyalty;
    }

    set loyalty(value: ILoyalty) {
        this._loyalty = value;
    }

    get storeCanBookForDelivery() {
        return this._storeCanBookForDelivery;
    }

    set storeCanBookForDelivery(value: boolean) {
        this._storeCanBookForDelivery = value;
    }
}

export default User;
