import {Form, Input} from "@bezlimit/bezlimit-ui";
import {FormItemProps} from "antd";
import {useTranslation} from "react-i18next";
// rules
import {Rules} from "../../forms/rules";

export default function FIOField({title, props}: IProps) {
    const {t} = useTranslation();

    return (
        <Form.Item
            {...props}
            hasFeedback
            className="field-fio"
            name="fio"
            rules={[
                Rules.required(),
                Rules.min(4),
                Rules.max(50),
                Rules.regex(
                    new RegExp(
                        new RegExp(
                            /^([А-ЯЁа-яё-]+){1,}[\s]([А-ЯЁа-яё-]+){1,}[\s]?([А-ЯЁа-яё-]+)?$/gi
                        )
                    ),
                    "Введите ФИО через пробел"
                )
            ]}
        >
            <Input title={title ? title : t("ФИО")} autoComplete="new-fio" />
        </Form.Item>
    );
}
interface IProps extends FormItemProps<any> {
    title?: any;
    props?: FormItemProps<any>;
}
