import {useNavigate, useParams} from "react-router-dom";
import {Row, Col, Title, Typography, Back} from "@bezlimit/bezlimit-ui";
import React, {useEffect, useState} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import "./style.scss";
// components
import Info from "./info";
// core
import TariffsService from "../../../../../services/TariffsService";
import {ITariffsAvailable} from "../../../../../core/models/Tariffs";

export default observer(function Banner() {
    const {t} = useTranslation();

    const tariffAvailable = TariffsService.items;
    const {name} = useParams();
    const [state, setState] = useState<ITariffsAvailable[]>();
    const navigate = useNavigate();

    useEffect(() => {
        document.title = `Тариф ${name} | Store`;
    }, []);

    useEffect(() => {
        setState(
            _.chain(tariffAvailable)
                .filter((i) => _.isEqual(i.name.trim(), name))
                .valueOf()
        );
    }, [tariffAvailable, name]);

    return (
        <>
            <Typography as="div" className="page-header-banner" />
            <Back navigate={() => navigate("/tariffs")}>{t("Назад к тарифам")}</Back>
            <Typography as="div" className="bl-user-info bl-connection-banner">
                <Row
                    className="bl-connection-banner-row"
                    gutter={[0, {xs: 24, sm: 24, md: 24, lg: 42, xl: 42, xxl: 42}]}
                >
                    {_.gt(_.size(state), 0) ? (
                        _.map(state, ({name, id, price, minutes, sms, internet}) => (
                            <React.Fragment key={id}>
                                <Col key={id} span={24}>
                                    <Title title={name} />
                                </Col>
                                <Col key={id} span={24}>
                                    <Typography className="info-packet-subtitle">
                                        {t("Ежемесячная плата")}
                                    </Typography>
                                    <Typography className="info-packet-value">
                                        <span className="gold">{`${price}₽`}</span>
                                        <span className="info-packet-value-gray">
                                            {t(" в месяц")}
                                        </span>
                                    </Typography>
                                </Col>
                                <Col span={24}>
                                    <Info
                                        subscription_fee={price}
                                        packet_minutes={minutes}
                                        packet_sms={sms}
                                        packet_internet={internet}
                                        name={name}
                                        id={id}
                                    />
                                </Col>
                            </React.Fragment>
                        ))
                    ) : (
                        <Col span={24}>
                            <Typography as="div" className="not-connected">
                                <Typography>
                                    {tariffAvailable === undefined
                                        ? t("Информация о тарифе")
                                        : t("Тариф")}
                                </Typography>{" "}
                                <Typography className="not-available-connection">
                                    «{name}»{" "}
                                </Typography>
                                <Typography>
                                    {tariffAvailable === undefined
                                        ? t("загружается")
                                        : t("не доступен для этого номера.")}
                                </Typography>
                            </Typography>
                        </Col>
                    )}
                </Row>
            </Typography>
        </>
    );
});
