import './style.scss';

export const svgIconSprite = (): JSX.Element => {
    return (
        <svg className="svgAssetHidden" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <symbol id="process" x="0px" y="0px" viewBox="0 0 8 11" fill="none">
                <g>
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.33332 2.76152V1.8841C7.58119 1.73959 7.74998 1.47381 7.74998 1.16666V0.958339C7.74998 0.843183 7.6568 0.75 7.54164 0.75H0.458339C0.343183 0.75 0.25 0.843183 0.25 0.958339V1.16668C0.25 1.47381 0.418789 1.73959 0.666659 1.88412V2.76152C0.666659 3.58712 1.01863 4.37732 1.63224 4.92948L2.5439 5.74999L1.63224 6.57049C1.01863 7.12266 0.666659 7.91285 0.666659 8.73846V9.61586C0.418789 9.76039 0.25 10.0262 0.25 10.3333V10.5417C0.25 10.6568 0.343183 10.75 0.458339 10.75H7.54166C7.65682 10.75 7.75 10.6568 7.75 10.5416V10.3333C7.75 10.0262 7.58121 9.76039 7.33334 9.61586V8.73846C7.33334 7.91285 6.98137 7.12266 6.36776 6.57049L5.45608 5.74999L6.36774 4.92948C6.98135 4.37732 7.33332 3.5871 7.33332 2.76152ZM5.76471 2.74999H2.27143C2.1712 2.74999 2.08039 2.83382 2.03981 2.96356C1.99924 3.09367 2.01629 3.24516 2.08385 3.35036L3.84819 5.65707C3.89644 5.71915 3.95733 5.74998 4.01819 5.74998C4.07905 5.74998 4.13993 5.71911 4.18818 5.65707L5.95226 3.35036C6.01982 3.24516 6.0369 3.09367 5.9963 2.96356C5.95575 2.83382 5.86493 2.74999 5.76471 2.74999ZM5.99644 9.53639C5.95587 9.66613 5.86505 9.74995 5.76483 9.74995H2.27155C2.17132 9.74995 2.08051 9.66613 2.03995 9.53639C1.99936 9.40628 2.01644 9.25479 2.084 9.14959L3.84807 6.84288C3.89632 6.78083 3.95721 6.74996 4.01807 6.74996C4.07893 6.74996 4.13981 6.7808 4.18806 6.84288L5.9524 9.14958C6.01996 9.25479 6.03702 9.40628 5.99644 9.53639Z" fill="url(#pr)"/>
                    <defs>
                        <linearGradient id="pr" x1="8.82635" y1="-0.961859" x2="-2.39262" y2="0.860638" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#838383"/>
                            <stop offset="0.521" stopColor="#D6D6D6"/>
                            <stop offset="1" stopColor="#7A7A7A"/>
                        </linearGradient>
                    </defs>
                </g>
            </symbol>
            <symbol id="blocked" x="0px" y="0px" viewBox="0 0 20 20" fill="none">
                <g>
                    <path d="M5.06953 5.06972C7.53865 2.6006 11.4059 2.38176 14.1229 4.4132L4.41301 14.1231C2.38157 11.4061 2.60041 7.53885 5.06953 5.06972ZM5.82722 15.5373L15.5371 5.82742C17.5685 8.54438 17.3497 12.4116 14.8806 14.8808C12.4114 17.3499 8.54418 17.5687 5.82722 15.5373Z" stroke="url(#bl)" strokeWidth="2"/>
                    <defs>
                        <linearGradient id="bl" x1="18.1246" y1="14.375" x2="3.74963" y2="3.75002" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#846B4E"/>
                            <stop offset="0.432292" stopColor="#CA984D"/>
                            <stop offset="1" stopColor="#876F4A"/>
                        </linearGradient>
                    </defs>
                </g>
            </symbol>
            <symbol id="active" x="0px" y="0px" viewBox="0 0 20 20" fill="none">
                <g>
                    <path d="M10.1656 15.5372C10.0623 15.4822 9.93775 15.4822 9.8344 15.5372L4.9688 18.1245C4.70549 18.2645 4.39723 18.0342 4.46321 17.7467L5.64458 12.5996C5.67096 12.4847 5.63566 12.3645 5.55107 12.2812L1.35279 8.14649C1.13321 7.93023 1.28873 7.56046 1.59926 7.56046H6.70383C6.83585 7.56046 6.95655 7.487 7.01559 7.3707L9.68823 2.10636C9.81669 1.85334 10.1833 1.85334 10.3118 2.10636L12.9844 7.3707C13.0435 7.487 13.1641 7.56046 13.2962 7.56046H18.4007C18.7113 7.56046 18.8668 7.93023 18.6472 8.14649L14.4489 12.2812C14.3643 12.3645 14.329 12.4847 14.3554 12.5996L15.5368 17.7467C15.6028 18.0342 15.2945 18.2645 15.0312 18.1245L10.1656 15.5372Z" fill="url(#ac)"/>
                    <defs>
                        <linearGradient id="ac" x1="12.7056" y1="0.90097" x2="22.7553" y2="14.3023" gradientUnits="userSpaceOnUse">
                        <stop stopColor="#EFCE73"/>
                        <stop offset="0.531251" stopColor="#FEF0B7"/>
                        <stop offset="1" stopColor="#CEA86F"/>
                        </linearGradient>
                    </defs>
                </g>
            </symbol>
            <symbol id="new" x="0px" y="0px" viewBox="0 0 12 12" fill="none">
                <g>
                    <path d="M6.09936 8.32079C6.03735 8.28781 5.96265 8.28781 5.90064 8.32079L2.98128 9.87317C2.8233 9.95717 2.63834 9.81898 2.67793 9.64651L3.38675 6.55825C3.40257 6.4893 3.38139 6.41718 3.33064 6.3672L0.811675 3.88637C0.679925 3.75662 0.773235 3.53476 0.959558 3.53476H4.0223C4.10151 3.53476 4.17393 3.49068 4.20936 3.4209L5.81294 0.262296C5.89001 0.110485 6.10999 0.110485 6.18706 0.262296L7.79064 3.4209C7.82607 3.49068 7.89849 3.53476 7.9777 3.53476H11.0404C11.2268 3.53476 11.3201 3.75662 11.1883 3.88637L8.66936 6.3672C8.61861 6.41718 8.59743 6.4893 8.61325 6.55825L9.32207 9.64651C9.36166 9.81898 9.17671 9.95717 9.01872 9.87317L6.09936 8.32079Z" fill="url(#nw)"/>
                    <defs>
                        <linearGradient id="nw" x1="7.62336" y1="-0.460938" x2="13.6532" y2="7.57986" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#EFCE73"/>
                            <stop offset="0.531251" stopColor="#FEF0B7"/>
                            <stop offset="1" stopColor="#CEA86F"/>
                        </linearGradient>
                    </defs>
                </g>
            </symbol>
            <symbol id="sent" x="0px" y="0px" viewBox="0 0 10 10" fill="none">
                <g>
                    <path d="M1.125 1.32755L1.125 1.32755L3.96548 3.04433C4.60158 3.42879 5.39842 3.42879 6.03452 3.04433L8.875 1.32755V6.8047H1.125L1.125 1.32755Z" stroke="url(#sn0)" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M1.125 1.32755L1.125 1.32755L3.96548 3.04433C4.60158 3.42879 5.39842 3.42879 6.03452 3.04433L8.875 1.32755V6.8047H1.125L1.125 1.32755Z" stroke="url(#sn1" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                    <defs>
                        <linearGradient id="sn0" x1="10.0053" y1="5.9662" x2="2.26176" y2="-0.797904" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#A78A5F"/>
                            <stop offset="0.432292" stopColor="#EECD9B"/>
                            <stop offset="1" stopColor="#A78A5F"/>
                        </linearGradient>
                        <linearGradient id="sn1" x1="10.0053" y1="5.9662" x2="2.26176" y2="-0.797904" gradientUnits="userSpaceOnUse">
                            <stop stopColor="#846B4E"/>
                            <stop offset="0.432292" stopColor="#CA984D"/>
                            <stop offset="1" stopColor="#876F4A"/>
                        </linearGradient>
                    </defs>
                </g>
            </symbol>
        </svg>);
};