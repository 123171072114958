import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Badge, Button, Icon, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import {LayoutContext} from "../../../../core/Contexts";
import _ from "lodash";
import {MobXProviderContext} from "mobx-react";

export default React.memo(function Filter({count, isFavorite, onClick}: IProps) {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);
    const {store} = useContext(MobXProviderContext);

    return (
        <Typography as="div" className="bl-filter-button">
            <Button
                type="text"
                className="btn-filter"
                icon={<Icon name="filter" size="20" />}
                onClick={onClick}
            >
                <Typography size={17} color="#fff">
                    <Typography as="span" size={17} color="#fff" className="text">
                        {t(
                            !store.numberFilters.isCollection &&
                                isFavorite &&
                                _.lt(contextLayout?.width ?? 0, 768)
                                ? "Режим фильтров"
                                : "Фильтры"
                        )}
                    </Typography>{" "}
                    {!!count && !isFavorite && <Badge count={count} />}
                </Typography>
            </Button>
        </Typography>
    );
});

interface IProps {
    count: number;
    isFavorite: boolean;
    onClick: () => void;
}
