import {useContext} from "react";
import {Space, Title, Typography, Back} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
import "./style.scss";
// components
import ResetPasswordForm from "../../forms/resetPassword";
// ui
import Card from "../ui/card";
// core
import {LayoutContext} from "../../../core/Contexts";
// hooks
import useSearchRoute from "../../../core/hooks/useSearchRoute";
// services
import UserService from "../../../services/UserService";

export default function ResetPassword() {
    const context = useContext(LayoutContext);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const route = useSearchRoute(UserService.token);

    return (
        <Typography as="div" className="bl-reset-password">
            <Space
                size={_.gte(context?.width ?? 0, 768) ? 122 : 40}
                direction="vertical"
                align="center"
            >
                <Typography as="div">
                    <Back navigate={() => navigate("/")}>{""}</Back>
                    <Space size={16} direction="vertical" align="center">
                        <Title className="bl-title" title={_.get(route, "title")} />
                        <Typography size={14}>
                            {t(
                                "Для восстановления пароля укажите свой номер телефона или адрес электронной почты"
                            )}
                        </Typography>
                    </Space>
                </Typography>

                <Card theme="blured">
                    <ResetPasswordForm />
                </Card>
            </Space>
        </Typography>
    );
}
