import React, {useContext} from "react";
import {Checkbox, Space, Typography} from "@bezlimit/bezlimit-ui";
import {CheckboxChangeEvent} from "antd/lib/checkbox";
import "./style.scss";
import {MobXProviderContext} from "mobx-react";

export default React.memo(function ColumnTitle({title, disabled, checked, onChange}: IProps) {
    const {store} = useContext(MobXProviderContext);

    return (
        <Typography as="div" className={`bl-column-title${title ? " absolute" : ""}`}>
            <Space direction="horizontal" size={16}>
                {title && (
                    <Typography as="div" color="#fff" size={17} className="title">
                        {title}
                    </Typography>
                )}
                <Checkbox
                    disabled={disabled || store.pageLoading}
                    checked={checked}
                    onChange={onChange}
                >
                    Выбрать все
                </Checkbox>
            </Space>
        </Typography>
    );
});

interface IProps {
    disabled: boolean;
    checked: boolean;
    title?: string;
    onChange: ({target: {checked}}: CheckboxChangeEvent) => void;
}
