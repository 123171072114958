import ReactDOM from "react-dom";
import React, {useEffect, useState} from "react";

const copyRoot = document.getElementById("portal-copy");

export default React.memo(function CopyPortal({children}: IProps) {
    const [node] = useState(document.createElement("div"));

    useEffect(() => {
        if (copyRoot) {
            copyRoot.appendChild(node);

            return () => {
                copyRoot.removeChild(node);
            };
        }
    }, []);

    return ReactDOM.createPortal(children, node);
});

interface IProps {
    children: JSX.Element;
}
