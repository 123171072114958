import {ReactFragment, ReactNode, useState} from "react";
import {useTranslation} from "react-i18next";
import {Button, Modal} from "@bezlimit/bezlimit-ui";
import UserCardService from "../../../../../services/UserCardService";
import "./style.scss";
//images
import SuccessImg from "../../../../images/successImg";
import ErrorImg from "../../../../images/errorImg";

type TProps = {
    visible: boolean;
    cancelHandler: () => void;
    type: string;
    maskerPan: string;
    id: number;
};

type TContent = {
    [key: string]: {
        title: string;
        footer: Array<ReactNode>;
        children: ReactFragment | ReactNode;
    };
};

type TError = {
    field: string;
    message: string;
};

const ModalBankCardsRemoval = ({visible, cancelHandler, type, maskerPan, id}: TProps) => {
    const {t} = useTranslation("common");
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState<"default" | "success" | "error">("default");
    const [apiErrors, setApiErrors] = useState<Array<TError> | null>(null);

    const deleteClickHandler = async () => {
        try {
            setLoading(true);
            await UserCardService.deleteBankCard(id);
            setState("success");
        } catch (err: any) {
            setApiErrors(err.response.data);
            setState("error");
        } finally {
            setLoading(false);
        }
    };

    const onCloseModal = () => {
        cancelHandler();
        if (state === "success" || state === "error") {
            UserCardService.fetchGetBankCard();
        }
        setState("default");
    };

    const content: TContent = {
        default: {
            title: t(`Удалить ${type}`),
            footer: [
                <Button
                    className="removement-modal--delite-btn"
                    key="confirm"
                    onClick={deleteClickHandler}
                    type="primary"
                    loading={loading}
                >
                    {loading ? "" : t("Удалить")}
                </Button>,
                <Button
                    key="cancel"
                    className="removement-modal--cancel-btn"
                    onClick={onCloseModal}
                    disabled={loading}
                >
                    {t("Отменить")}
                </Button>
            ],
            children: (
                <>
                    <div className="removement-modal--description">
                        <span>{"•••• •••• •••• "}</span>
                        {maskerPan}
                    </div>
                </>
            )
        },
        success: {
            title: t("Карта удалена"),
            footer: [],
            children: (
                <>
                    <div className="removement-modal--description">
                        {`${t("Номер карты")} ${"•••• •••• •••• " + maskerPan} ${t(
                            "удален, больше вы его не будете видеть"
                        )}`}
                    </div>
                </>
            )
        },
        error: {
            title: t("Карта не удалена"),
            footer: [],
            children: (
                <>
                    {apiErrors && apiErrors.length ? (
                        apiErrors.map((err) => (
                            <div key={err.message} style={{textAlign: "center"}}>
                                {err.message}
                            </div>
                        ))
                    ) : (
                        <div style={{textAlign: "center"}}>{t("Повторите попытку позже")}</div>
                    )}
                </>
            )
        }
    };

    let ModalImage = null;
    if (state === "success") {
        ModalImage = <SuccessImg className={"header-img"} />;
    } else if (state === "error") {
        ModalImage = <ErrorImg className={"header-img"} />;
    }

    return (
        <Modal
            centered
            visible={visible}
            onCancel={onCloseModal}
            title={content[state].title}
            footer={content[state].footer}
            type={state}
            img={ModalImage}
            className="modal-removement-number-list bl-removement-bank-cards-modal"
        >
            {content[state].children}
        </Modal>
    );
};

export default ModalBankCardsRemoval;
