import {Form, Input, Radio, Space, Typography} from "@bezlimit/bezlimit-ui";
import {FormInstance} from "antd";
import {t} from "i18next";
import {observer} from "mobx-react-lite";
import "./style.scss";
//components
import NumberFormat from "react-number-format";

export default observer(function SpaceAutoPaymentAmount({form}: TProps) {
    const radioButtons = [100, 200, 300, 500, 1000];

    const onChangeRadioSumm = () => {
        form.setFieldsValue({
            sum_payment: form.getFieldsValue().radioauto_payment_amount
        });
    };

    const onChangeAutoPaymentAmountm = (summ: string) => {
        form.setFieldsValue({radioauto_payment_amount: +form.getFieldsValue().sum_payment});
    };

    return (
        <Space size={12} className="input-summ-space">
            <Form.Item
                name={"sum_payment"}
                rules={[
                    {
                        required: true,
                        message: t("Поле обязательно для заполнения")
                    },
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            const sum = Number(value);
                            if (sum < 100 || sum > 15000) {
                                return Promise.reject(
                                    new Error(t("Сумма автоплатежа от 100 ₽ до 15000 ₽"))
                                );
                            }
                            return Promise.resolve();
                        }
                    })
                ]}
            >
                <NumberFormat
                    customInput={Input}
                    format={(value) => {
                        return value.substring(0, 7);
                    }}
                    onChange={(e: {target: {value: string}}) => {
                        onChangeAutoPaymentAmountm(e.target.value);
                    }}
                    title={t("Сумма ₽ ")}
                />
            </Form.Item>
            <Typography size={12} color="#828282">
                {t("от 100 ₽ до 15000 ₽")}
            </Typography>
            <Typography as="div" className="bl-radio-button-group" onChange={onChangeRadioSumm}>
                <Form.Item name="radioauto_payment_amount">
                    <Radio.Group optionType="button">
                        {radioButtons.map((radioButton, index) => (
                            <Radio.Button value={radioButton} key={index}>
                                {radioButton + "₽"}
                            </Radio.Button>
                        ))}
                    </Radio.Group>
                </Form.Item>
            </Typography>
        </Space>
    );
});

type TProps = {
    form: FormInstance<any>;
};
