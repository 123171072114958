import _ from "lodash";
// store
import Store from "../stores";
// core
import {axiosFetchBuilder, IAxiosConfig} from "../core/Axios";
import {IBonusesLevelsResponse, IBonusesLevelsRequest} from "../core/models/Bonuses";

enum Path {
    levels = "bonuses/levels"
}

class BonusesLevelsService {
    [Symbol.toStringTag] = this.constructor.name;
    private static readonly levels = Store.bonusesLevels;

    static get items() {
        return BonusesLevelsService.levels.items;
    }

    static get itemsSize() {
        return _.size(BonusesLevelsService.items);
    }

    static get meta() {
        return BonusesLevelsService.levels.meta;
    }

    static get perPage() {
        return BonusesLevelsService.levels.meta?.perPage ?? 6;
    }

    static get totalCount() {
        return BonusesLevelsService.levels.meta?.totalCount ?? 0;
    }

    static get currentPage() {
        return BonusesLevelsService.levels.meta?.currentPage ?? 1;
    }

    static get hasItems() {
        return _.gt(BonusesLevelsService.totalCount, BonusesLevelsService.itemsSize);
    }

    private static async getBonusesLevels(
        request?: IBonusesLevelsRequest,
        loading: boolean = true
    ): Promise<IBonusesLevelsResponse> {
        const res: IBonusesLevelsResponse = await axiosFetchBuilder({
            url: Path.levels,
            loading,
            tag: "update",
            params: {
                ...request,
                per_page: BonusesLevelsService.perPage
            }
        } as IAxiosConfig);
        BonusesLevelsService.levels.meta = (res as any)._meta;
        return res;
    }

    static async fetchlevels(request?: IBonusesLevelsRequest) {
        const res: IBonusesLevelsResponse = await BonusesLevelsService.getBonusesLevels(request);
        BonusesLevelsService.levels.items = res.items;
    }

    static async loadMorelevels(request: IBonusesLevelsRequest) {
        const res: IBonusesLevelsResponse = await BonusesLevelsService.getBonusesLevels(
            request,
            false
        );
        BonusesLevelsService.levels.items = [...BonusesLevelsService.levels.items, ...res.items];
    }
}

export default BonusesLevelsService;
