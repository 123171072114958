import React, {useContext, useEffect} from "react";
import {Block, Row, Col, Typography, Button, Divider} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
import "./style.scss";
// services
import BonusesService from "../../../../services/BonusesService";
import UserService from "../../../../services/UserService";
// components
import Progress from "./progress";
import Base from "./base";
import Accumulated from "./accumulated";
import Accrued from "./accrued";
import TenForBezlimit from "../../../modal/tenForBezlimit";
// core
import {LayoutContext, ModalContext} from "../../../../core/Contexts";
// store
import Store from "../../../../stores";

export default React.memo(
    observer(function MyBonuses() {
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const {t} = useTranslation();
        const navigate = useNavigate();

        useEffect(() => {
            BonusesService.summary();
        }, []);

        const onClick = () => {
            navigate("/bonus");
        };

        const onModal = () => {
            contextModal?.setVisible(true);
            contextModal?.setChildren(<TenForBezlimit />);
        };

        return (
            <Typography
                as="div"
                className={`bl-my-bonuses ${
                    !_.isNil(Store.user.loyalty) ? "" : "without-loyalty"
                } ${!BonusesService.paymentProcessSum ? "empty" : ""}`}
            >
                <Block title={t("Мои бонусы")}>
                    {!_.isNil(Store.user.loyalty) ? (
                        <>
                            <Row gutter={[16, 8]}>
                                <Col span={24} className="progress-container">
                                    <Row
                                        gutter={[
                                            _.gte(contextLayout?.width, 768) ?? 0 ? 32 : 24,
                                            16
                                        ]}
                                        align="middle"
                                        justify="center"
                                    >
                                        <Col span={14}>
                                            <Progress />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24} className="accumulated-container">
                                    <Row gutter={[16, 0]} justify="center">
                                        <Col
                                            xs={
                                                _.gte(
                                                    BonusesService.accumulated.toFixed(0).toString()
                                                        .length,
                                                    6
                                                )
                                                    ? 11
                                                    : 10
                                            }
                                            lg={
                                                _.gte(
                                                    BonusesService.processSum.toFixed(0).toString()
                                                        .length,
                                                    7
                                                )
                                                    ? 11
                                                    : 10
                                            }
                                        >
                                            <Accumulated />
                                        </Col>
                                        <Col
                                            xs={
                                                _.gte(
                                                    BonusesService.accumulated.toFixed(0).toString()
                                                        .length,
                                                    6
                                                )
                                                    ? 11
                                                    : 10
                                            }
                                            lg={
                                                _.gte(
                                                    BonusesService.processSum.toFixed(0).toString()
                                                        .length,
                                                    7
                                                )
                                                    ? 11
                                                    : 10
                                            }
                                        >
                                            <Accrued />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Row
                                        gutter={[16, 0]}
                                        className="bl-my-bonuses-futurePaymentSum-wrapper"
                                    >
                                        <Typography className="bl-my-bonuses-futurePaymentSum">
                                            {BonusesService.futurePaymentSum > 0 &&
                                                BonusesService.futurePaymentSum}
                                        </Typography>
                                        <Typography className="bl-my-bonuses-futurePaymentSumText">
                                            {BonusesService.futurePaymentSum > 0 &&
                                                t("Ближайшее начисление")}
                                        </Typography>
                                    </Row>
                                    <Row className="bl-my-bonuses-futurePaymentSum-wrapper-base">
                                        <Base />
                                    </Row>
                                </Col>
                                <Col
                                    span={24}
                                    className={
                                        UserService.personalData &&
                                        _.isEqual(BonusesService.ratePhoneBl, 0)
                                            ? ""
                                            : "bl-my-bonuses-page-link-wrapper"
                                    }
                                >
                                    <Button type="default" onClick={onClick}>
                                        {t("Мои бонусы")}
                                    </Button>
                                </Col>
                                {UserService.personalData &&
                                    _.isEqual(BonusesService.ratePhoneBl, 0) && (
                                        <Col span={24}>
                                            <Button type="primary" onClick={onModal}>
                                                {t("10% за номер Безлимит")}
                                            </Button>
                                        </Col>
                                    )}
                            </Row>
                        </>
                    ) : (
                        <>
                            <Row gutter={[16, 8]} className="progress-row">
                                <Col span={20} className="progress-container">
                                    <Row
                                        gutter={[
                                            _.gte(contextLayout?.width, 768) ?? 0 ? 32 : 24,
                                            16
                                        ]}
                                        align="middle"
                                        justify="center"
                                    >
                                        <Col span={12}>
                                            <Progress />
                                        </Col>
                                        <Col span={12} className="with-divider">
                                            <Accumulated />
                                            <Divider />
                                            <Accrued />
                                        </Col>
                                    </Row>
                                </Col>
                                <Col span={24}>
                                    <Typography
                                        as="div"
                                        className="bl-my-bonuses-futurePaymentSum-wrapper"
                                    >
                                        <Typography as="div" className="future-container">
                                            <Typography
                                                as="div"
                                                className="bl-my-bonuses-futurePaymentSum"
                                            >
                                                {BonusesService.futurePaymentSum > 0 &&
                                                    BonusesService.futurePaymentSum}
                                            </Typography>
                                            <Typography
                                                as="div"
                                                className="bl-my-bonuses-futurePaymentSumText"
                                            >
                                                {BonusesService.futurePaymentSum > 0 &&
                                                    t("Ближайшее начисление")}
                                            </Typography>
                                        </Typography>
                                        <Typography
                                            as="div"
                                            className="bl-my-bonuses-futurePaymentSum-wrapper-base"
                                        >
                                            <Base />
                                        </Typography>
                                    </Typography>
                                </Col>
                                <Col
                                    span={24}
                                    className={
                                        UserService.personalData &&
                                        _.isEqual(BonusesService.ratePhoneBl, 0)
                                            ? ""
                                            : "bl-my-bonuses-page-link-wrapper"
                                    }
                                >
                                    <Button type="default" onClick={onClick}>
                                        {t("Мои бонусы")}
                                    </Button>
                                </Col>
                                {UserService.personalData &&
                                    _.isEqual(BonusesService.ratePhoneBl, 0) && (
                                        <Col span={24}>
                                            <Button type="primary" onClick={onModal}>
                                                {t("10% за номер Безлимит")}
                                            </Button>
                                        </Col>
                                    )}
                            </Row>
                        </>
                    )}
                </Block>
            </Typography>
        );
    })
);
