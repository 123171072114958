import React, {useEffect, useRef} from "react";
import {Form, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {Moment} from "moment";
import {RangeValue} from "rc-picker/lib/interface";
import "./style.scss";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
// components
import RangePickerField from "../../fields/rangePicker";

export default React.memo(function OperationHistoryForm({label, activeKey, set}: IProps) {
    const ref = useRef(null);
    const {t} = useTranslation();
    const [form] = Form.useForm();
    const formInstance = useFormHandler(form);

    const onChange = (values: any) => {
        set(values);
    };

    useEffect(() => {
        set(null);
        formInstance.resetFields();
    }, [activeKey]);

    return (
        <div ref={ref}>
            <Typography as="div" className="bl-operaion-history-form">
                <Form form={formInstance} autoComplete="off">
                    <RangePickerField
                        props={{
                            name: "period",
                            label: t(label)
                        }}
                        onChange={onChange}
                    />
                </Form>
            </Typography>
        </div>
    );
});

interface IProps {
    label: string;
    activeKey: string;
    set: React.Dispatch<React.SetStateAction<RangeValue<Moment>>>;
}
