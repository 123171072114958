import {Link} from "react-router-dom";
import {Logo as LogoImg, Space, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import {LayoutContext} from "../../../../core/Contexts";
import _ from "lodash";

export default function Logo() {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);

    return (
        <div className="bl-logo item">
            <Space size={16}>
                <Link to={"/"}>
                    <LogoImg />
                </Link>
                <Typography className="title-gold">
                    {t(_.gte(contextLayout?.width, 768) ? "Store Безлимит" : "Кабинет дилера")}
                </Typography>
            </Space>
        </div>
    );
}
