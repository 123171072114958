import {Col, Icon, Progress, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import "./style.scss";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
import cn from "classnames";
//core
import date from "../../../../../../core/functions/date";
import {IItems} from "../../../../../../core/models/Bonus";
import NpsHistory from "../../../../../../images/nps-history.png";

const dateFormat: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "numeric",
    day: "numeric"
};

const dateFormatMobile: Intl.DateTimeFormatOptions = {
    year: "numeric",
    month: "long",
    day: "numeric"
};

export default function ItemPaymants({
    item: {created_at, type, status, total_sum, sum, phone, comment, loyalty, action}
}: IProps) {
    const {t} = useTranslation();
    const accrued = total_sum - sum;
    const getLoyaltyProgress = (num1: number, num2: number): number => {
        const sum = num1 + num2;
        if (!sum) {
            return 0;
        }
        return (num1 * 100) / sum;
    };

    return (
        <Typography as="div" className="bl-list-payments">
            <Row>
                <Col md={0} xs={24}>
                    <Typography as="div" className="date-format-mobile">
                        <Typography size={14} className="date-format-mobile-text">
                            {date(created_at, dateFormatMobile)}
                        </Typography>
                    </Typography>
                </Col>
                <Col md={4} xs={0}>
                    <Typography size={17}>{date(created_at, dateFormat)}</Typography>
                </Col>
                <Col md={4} xs={0}>
                    <Space>
                        {!_.isNil(loyalty) && !_.isNil(loyalty.code) ? (
                            <Icon name={`loyalty_${loyalty.code}`} size="16" />
                        ) : null}
                        <Typography size={17} color="rgba(255, 255, 255, 0.5)">
                            {!_.isNil(loyalty) ? loyalty.name : "-"}
                        </Typography>
                    </Space>
                </Col>
                <Col md={4} xs={0}>
                    {_.isEqual(type, "gift") ? (
                        !_.isNil(action) ? (
                            <Typography as="div" className="bl-nps">
                                <Typography size={17}>{comment}</Typography>{" "}
                                <Typography as="div" size={14} className="nps-description">
                                    <img src={NpsHistory} width={20} alt="" />
                                    {action.description}
                                </Typography>
                            </Typography>
                        ) : (
                            <Typography size={17}>{comment}</Typography>
                        )
                    ) : !_.isNil(action) ? (
                        <Typography as="div" className="bl-nps">
                            <Typography size={17}>{phone}</Typography>{" "}
                            <Typography as="div" size={14} className="nps-description">
                                <img src={NpsHistory} width={20} alt="" />
                                {action.description}
                            </Typography>
                        </Typography>
                    ) : (
                        <Typography size={17}>{phone}</Typography>
                    )}
                </Col>
                <Col md={5} xs={0}>
                    {_.isEqual(type, "gift") ? (
                        <Space>
                            <Icon name="lightning2" size="14" />
                            <Typography size={17}>{t("Выплачен")}</Typography>
                        </Space>
                    ) : _.isEqual(status, 2) ? (
                        <Space>
                            <Icon name="status_active" size="14" />
                            <Typography size={17}>{t("Активен")}</Typography>
                        </Space>
                    ) : _.isEqual(status, 3) ? (
                        <Space>
                            <Icon name="status_blocked" size="14" />
                            <Typography size={17}>{t("Заблокирован")}</Typography>
                        </Space>
                    ) : _.isEqual(status, 4) ? (
                        <Space>
                            <Icon name="status_completed" size="14" />
                            <Typography size={17}>{t("Выплачен")}</Typography>
                        </Space>
                    ) : (
                        <Space>
                            <Icon name="status_process" size="14" />
                            <Typography size={17}>{t("Перевыпущен")}</Typography>
                        </Space>
                    )}
                </Col>
                <Col md={0} xs={12} className="col-mobile-status-payments">
                    {_.isEqual(type, "gift") ? (
                        <Typography as="div" className="group-status-icons">
                            <img src={"lightning2.png"} alt="" />
                            <Typography size={17}>{comment}</Typography>
                        </Typography>
                    ) : (
                        <Typography as="div" className="group-status-icons">
                            <img src={`status_${status}.png`} alt="" />
                            <Typography size={17}>{phone}</Typography>
                        </Typography>
                    )}
                </Col>
                <Col md={0} xs={12} className="col-status-name">
                    <Space>
                        {!_.isNil(action) ? (
                            <Typography as="div" className="bl-nps">
                                <Typography
                                    size={17}
                                    color="rgba(255, 255, 255, 0.5)"
                                    className="loyalty-name"
                                >
                                    {_.isEqual(loyalty?.code, "bronze") ? (
                                        <Icon name="bronze-loyalty-mob" size="15" />
                                    ) : _.isEqual(loyalty?.code, "silver") ? (
                                        <Icon name="silver-loyalty-mob" size="15" />
                                    ) : _.isEqual(loyalty?.code, "gold") ? (
                                        <Icon name="gold-loyalty-mob" size="15" />
                                    ) : _.isEqual(loyalty?.code, "top") ? (
                                        <Icon name="top-loyalty-mob" size="15" />
                                    ) : null}
                                    {!_.isNil(loyalty) ? loyalty.name : null}
                                </Typography>
                                <Typography as="div" size={14} className="nps-description">
                                    <img src={NpsHistory} width={20} alt="" />
                                    {action.description}
                                </Typography>
                            </Typography>
                        ) : (
                            <Typography as="div" className="bl-nps">
                                <Typography
                                    size={17}
                                    color="rgba(255, 255, 255, 0.5)"
                                    className="loyalty-name"
                                >
                                    {_.isEqual(loyalty?.code, "bronze") ? (
                                        <Icon name="bronze-loyalty-mob" size="15" />
                                    ) : _.isEqual(loyalty?.code, "silver") ? (
                                        <Icon name="silver-loyalty-mob" size="15" />
                                    ) : _.isEqual(loyalty?.code, "gold") ? (
                                        <Icon name="gold-loyalty-mob" size="15" />
                                    ) : _.isEqual(loyalty?.code, "top") ? (
                                        <Icon name="top-loyalty-mob" size="15" />
                                    ) : null}
                                    {!_.isNil(loyalty) ? loyalty.name : null}
                                </Typography>
                            </Typography>
                        )}
                    </Space>
                </Col>
                <Col md={0} xs={24} className="mobile-loyalty-bar">
                    {_.isEqual(type, "phone") && (
                        <Progress
                            className={cn("loaylty-progress-line", {active: status === 2})}
                            percent={getLoyaltyProgress(total_sum, accrued)}
                            showInfo={false}
                        />
                    )}
                </Col>
                <Col md={3} xs={12} className="col-mobile-payments-sum">
                    <Typography as="div" className="mobile-payments-sum">
                        <Typography size={24} className="list-total-sum">
                            <NumberFormat
                                thousandSeparator=" "
                                displayType="text"
                                value={sum.toFixed(2)}
                            />
                        </Typography>
                        <Typography
                            className="mobile-text-payments"
                            size={14}
                            color="rgba(255, 255, 255, 0.5)"
                        >
                            {t("Накоплено")}
                        </Typography>
                    </Typography>
                </Col>
                <Col md={3} xs={12} className="col-mobile-payauts-sum">
                    <Typography as="div" className="mobile-payments-sum">
                        <Typography size={24} className="list-total-sum">
                            <NumberFormat
                                thousandSeparator=" "
                                displayType="text"
                                value={accrued.toFixed(2)}
                            />
                            <Icon name="Polygon5" size="8" />
                        </Typography>
                        <Typography
                            className="mobile-text-payments"
                            size={14}
                            color="rgba(255, 255, 255, 0.5)"
                        >
                            {t("Начисляется")}
                        </Typography>
                        {/* <Typography>
                            <Icon name="Polygon5" size="8" />
                        </Typography> */}
                    </Typography>
                </Col>
            </Row>
        </Typography>
    );
}

interface IProps {
    item: IItems;
    // period: RangeValue<moment.Moment>;
    // activeKey: string;
}
