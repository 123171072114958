import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Col, Icon, Modal, Row, Skeleton, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {MobXProviderContext, observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// components
import Filter from "./filter";
import Selected from "./selected";
import Error from "../../../../modal/error";
import SaveFiltersModal from "../../../../modal/saveFilters";
// core
import {ModalContext} from "../../../../../core/Contexts";
// services
import PhonesFiltersService from "../../../../../services/PhonesFiltersService";
import {isPhonePattern} from "../../../../forms/numberSearch";
import PhonesGlobalService from "../../../../../services/PhonesGlobalService";
import NumbersFiltersStoreService from "../../../../../services/NumbersFiltersStoreService";
import {isDesktop} from "react-device-detect";

export default React.memo(
    observer(function NumbersFilters({
        phone,
        type,
        tariff,
        categories,
        region,
        pattern,
        phonePattern,
        phoneCombs,
        sort,
        setType,
        setTariff,
        setCategories,
        setRegion,
        setPattern,
        setPhonePattern,
        setPhoneCombs,
        setIsFavorite
    }: IProps) {
        const {store} = useContext(MobXProviderContext);
        const contextModal = useContext(ModalContext);
        const [pageLoading, setPageLoading] = useState(false);
        const {t} = useTranslation();
        const [currentCheckbox, setCurrentCheckbox] = useState(NumbersFiltersStoreService.current);
        const [isModalOpen, setIsModalOpen] = useState(false);
        const [shouldUpdate, setShouldUpdate] = useState(false);
        const [init, setInit] = useState(false);
        const [enter, setEnter] = useState(false);
        const timeout: any = useRef();

        const handleCancel = () => {
            setIsModalOpen(false);
        };
        const onReset = () => {
            setType([]);
            setTariff([]);
            setCategories([]);
            setRegion([]);
            setPattern([]);
            setPhonePattern([]);
            setPhoneCombs("");
            PhonesFiltersService.resetCurrentFilter();
        };
        const onClickModal = () => {
            setIsModalOpen(true);
        };

        const onUpdate = () => {
            const clone = _.clone(NumbersFiltersStoreService.items);
            const value = clone.find((i, k) => _.isEqual(k, currentCheckbox));

            if (value) {
                value!.values = {
                    ...PhonesFiltersService.currentFilter,
                    phone_combs: phoneCombs,
                    phone_pattern: phonePattern
                };
                clone[currentCheckbox!] = value;
                NumbersFiltersStoreService.update(clone);
                NumbersFiltersStoreService.updateFilter(value);
                setShouldUpdate(false);
            }
        };

        const onClick = async () => {
            try {
                store.pageLoading = true;
                await PhonesGlobalService.fetchAll(
                    {
                        phone,
                        type,
                        tariff,
                        categories,
                        region,
                        pattern,
                        phone_pattern: phonePattern as any,
                        phone_combs: phoneCombs,
                        sort,
                        general_number: PhonesFiltersService.phonesFilters.general_number
                    },
                    {loading: false}
                );
                isPhonePattern.searchСounterСlick = isPhonePattern.searchСounterСlick + 1;
                isPhonePattern.isFilters = true;
                setInit(true);
                clearTimeout(timeout.current);
            } catch (e) {
                contextModal?.setChildren(<Error />);
            } finally {
                store.pageLoading = false;
            }
        };

        useEffect(() => {
            setPageLoading(store.pageLoading);
        }, [store.pageLoading]);

        useEffect(() => {
            setCurrentCheckbox(NumbersFiltersStoreService.current);
        }, [NumbersFiltersStoreService.current]);

        useEffect(() => {
            if (!_.isNil(currentCheckbox)) {
                const filter: any = NumbersFiltersStoreService.items.find((i, k) =>
                    _.isEqual(k, NumbersFiltersStoreService.current)
                );

                if (filter) {
                    filter.values.sort = PhonesFiltersService.currentFilter.sort;
                    filter.values.grid = PhonesFiltersService.currentFilter.grid;

                    if (
                        !_.isEqual(PhonesFiltersService.currentFilter, {
                            ...filter.values,
                            // phone_combs: phoneCombs,
                            phone_pattern: phonePattern
                        })
                    ) {
                        setShouldUpdate(true);
                    } else {
                        setShouldUpdate(false);
                    }
                } else {
                    setShouldUpdate(false);
                }
            }
        }, [PhonesFiltersService.currentFilter, phoneCombs, phonePattern]);

        useEffect(() => {
            if (_.isNil(currentCheckbox)) {
                if (
                    _.isNil(NumbersFiltersStoreService.current) &&
                    (!!phoneCombs?.replace(/,/gi, "") || phonePattern.filter((i) => i).length)
                ) {
                    setShouldUpdate(true);
                } else {
                    setShouldUpdate(false);
                }
            }
        }, [phoneCombs, phonePattern]);

        useEffect(() => {
            if (init && isDesktop && !enter) {
                clearTimeout(timeout.current);
                timeout.current = setTimeout(() => {
                    (document.body.querySelector(".btn-filter") as any)?.click();
                }, 3000);
            }
            setTimeout(() => {
                setInit(true);
                setEnter(false);
            }, 500);
        }, [tariff, phone, type, categories, region, pattern, phonePattern, phoneCombs, sort]);

        useEffect(() => {
            document.addEventListener("keyup", (event) => {
                if (event.code === "Enter" || event.code === "NumpadEnter") {
                    clearTimeout(timeout.current);
                    event.preventDefault();
                    (document.body.querySelector(".btn-filter") as any)?.click();
                    setEnter(true);
                }
            });
        }, []);

        return (
            <Typography as="div" className="bl-numbers-filters">
                <Space direction="vertical" size={32}>
                    {!!_.size(_.values(PhonesFiltersService.currentFilter)) && (
                        <Space direction="vertical" size={16} className="selected">
                            <Selected
                                type={type}
                                tariff={tariff}
                                categories={categories}
                                region={region}
                                pattern={pattern}
                                phonePattern={phonePattern}
                                phoneCombs={phoneCombs}
                                setType={setType}
                                setTariff={setTariff}
                                setCategories={setCategories}
                                setRegion={setRegion}
                                setPattern={setPattern}
                                setPhonePattern={setPhonePattern}
                                setPhoneCombs={setPhoneCombs}
                            />
                            {_.isNil(NumbersFiltersStoreService.current) &&
                            !PhonesFiltersService.size() &&
                            !shouldUpdate ? null : !_.isNil(NumbersFiltersStoreService.current) &&
                              shouldUpdate ? (
                                <Typography as="div" className="group-button-refresh">
                                    <Row gutter={[50, 20]}>
                                        <Col xl={24} lg={24}>
                                            <Button
                                                type="text"
                                                className="btn-refresh"
                                                onClick={onUpdate}
                                            >
                                                {t("Обновить")}
                                            </Button>
                                        </Col>
                                        <Col xl={24} lg={24}>
                                            <Button
                                                type="text"
                                                className="btn-save-new"
                                                onClick={onClickModal}
                                            >
                                                <Space direction="horizontal" size={9}>
                                                    {t("Сохранить новый")}
                                                    <Icon name="Save" size="14" />
                                                </Space>
                                            </Button>
                                        </Col>
                                    </Row>
                                </Typography>
                            ) : !_.isNil(NumbersFiltersStoreService.current) &&
                              !shouldUpdate ? null : (
                                <Typography as="div" className="group-button">
                                    <Row gutter={50}>
                                        <Col xl={12} lg={24}>
                                            <Button
                                                type="text"
                                                icon={<Icon name="Save" size="14" />}
                                                onClick={onClickModal}
                                            >
                                                {t("Сохранить фильтр")}
                                            </Button>
                                        </Col>
                                        <Col xl={12} lg={24}>
                                            <Button
                                                type="text"
                                                className={`reset${
                                                    !!_.size([
                                                        ...tariff,
                                                        ...categories,
                                                        ...region,
                                                        ...pattern
                                                    ]) || !_.includes(type, "standard")
                                                        ? " active"
                                                        : ""
                                                }`}
                                                icon={<Icon name="close" size="14" />}
                                                onClick={onReset}
                                            >
                                                {t("Сбросить фильтр")}
                                            </Button>
                                        </Col>
                                    </Row>
                                </Typography>
                            )}
                        </Space>
                    )}
                    <Filter
                        title="Тип номера"
                        items={PhonesFiltersService.filterType}
                        selected={type}
                        set={setType}
                        type="type"
                    />
                    <Filter
                        title="Тарифы"
                        items={PhonesFiltersService.filterTariff}
                        selected={tariff}
                        disabled={!_.includes(type, "standard")}
                        set={setTariff}
                        type="tariff"
                    />
                    <Filter
                        title="Категории"
                        items={PhonesFiltersService.filterCategories}
                        selected={categories}
                        set={setCategories}
                        type="categories"
                    />
                    <Filter
                        title="Регионы"
                        items={PhonesFiltersService.filterRegion}
                        selected={region}
                        set={setRegion}
                        type="region"
                    />
                    <Filter
                        title="Маски"
                        items={PhonesFiltersService.filterPattern}
                        selected={pattern}
                        set={setPattern}
                        type="pattern"
                    />
                    {pageLoading ? (
                        <Skeleton.Button active={true} className="btn-skeleton" />
                    ) : (
                        <Button type="default" className="btn-filter" onClick={onClick}>
                            {t("Применить")}
                        </Button>
                    )}
                </Space>
                <Modal open={isModalOpen} onCancel={handleCancel} className="save-filter-modal">
                    <SaveFiltersModal
                        title={t("Сохранить набор фильтров")}
                        setIsModalOpen={setIsModalOpen}
                        phonePattern={phonePattern}
                        phoneCombs={phoneCombs}
                    />
                </Modal>
            </Typography>
        );
    })
);

interface IProps {
    phone: string | undefined;
    type: string[];
    tariff: string[];
    categories: string[];
    region: string[];
    pattern: string[];
    phonePattern: string[];
    phoneCombs: string | undefined;
    sort: string;
    setType: React.Dispatch<React.SetStateAction<string[]>>;
    setTariff: React.Dispatch<React.SetStateAction<string[]>>;
    setCategories: React.Dispatch<React.SetStateAction<string[]>>;
    setRegion: React.Dispatch<React.SetStateAction<string[]>>;
    setPattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhonePattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhoneCombs: React.Dispatch<React.SetStateAction<string | undefined>>;
    setIsFavorite: React.Dispatch<React.SetStateAction<boolean>>;
}
