import React, {useContext, useRef} from "react";
import {Button, Form, Input, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import {InterceptorContext, LayoutContext, ModalContext} from "../../../core/Contexts";
import {IReservationsPutRequest, IReservationsRequest} from "../../../core/models/Reservations";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
import useOnCancel from "../../../core/hooks/useOnCancel";
// components
import Error from "../../modal/error";
import Success from "../../modal/success";
// services
import ReservationsService from "../../../services/ReservationsService";

export default React.memo(
    observer(function RemoveBookingForm({id, phone, updateList}: IProps) {
        const context = useContext(InterceptorContext);
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const ref = useRef(null);
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        const onCancel = useOnCancel(formInstance);

        const onFinish = async (values: IReservationsPutRequest) => {
            try {
                await ReservationsService.deletReservation(values);
                updateList({}, false, true);
                contextModal?.setChildren(
                    <Success
                        title={<Typography as="span">{t("Отмена брони проведена")}</Typography>}
                        phone={phone.toString()}
                    />
                );
            } catch (e: any) {
                contextModal?.setChildren(<Error />);
            } finally {
                contextModal?.setOnCancel(() => onCancel);
            }
        };

        return (
            <div ref={ref}>
                <Typography as="div" className="bl-remove-booking-form">
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="extend"
                        onFinish={onFinish}
                        autoComplete="off"
                        initialValues={{
                            id
                        }}
                    >
                        <Form.Item noStyle name="id" hidden={true}>
                            <Input />
                        </Form.Item>
                        <Space
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 0 : 8}
                            direction="vertical"
                        >
                            <Form.Item shouldUpdate>
                                {() => {
                                    return (
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={context?.loadingBtn}
                                        >
                                            {t("Отменить бронь")}
                                        </Button>
                                    );
                                }}
                            </Form.Item>
                            <Typography
                                as="div"
                                className="text-center cancel"
                                size={14}
                                color="#000"
                            >
                                <Button type="text" onClick={onCancel}>
                                    {t("Закрыть")}
                                </Button>
                            </Typography>
                        </Space>
                    </Form>
                </Typography>
            </div>
        );
    })
);

interface IProps {
    id: number;
    phone: number;
    updateList: (request?: IReservationsRequest, loading?: boolean, reset?: boolean) => void;
}
