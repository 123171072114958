import _ from "lodash";
// store
import Store from "../stores";
// core
import {HttpErrorStatusCode, IErrorResponse} from "../core/models/Error";
import {axiosFetchBuilder, IAxiosConfig} from "../core/Axios";
import {
    ILevel,
    ILevelsResponse,
    ILevelsRequest,
    ILevelRestoreResponse
} from "../core/models/Levels";

enum Path {
    levels = "users/levels",
    levelsRestore = "/users/levels/restore"
}

class LevelsService {
    [Symbol.toStringTag] = this.constructor.name;
    private static readonly levels = Store.levels;

    static get items() {
        return LevelsService.levels.items;
    }

    static get itemsSize() {
        return _.size(LevelsService.items);
    }

    static get meta() {
        return LevelsService.levels.meta;
    }

    static get perPage() {
        return LevelsService.levels.meta?.perPage ?? 6;
    }

    static get totalCount() {
        return LevelsService.levels.meta?.totalCount ?? 0;
    }

    static get currentPage() {
        return LevelsService.levels.meta?.currentPage ?? 1;
    }

    static get hasItems() {
        return _.gt(LevelsService.totalCount, LevelsService.itemsSize);
    }

    static async getlevelsById(id: string): Promise<ILevel> {
        return await axiosFetchBuilder({
            url: `${Path.levels}/${id}`
        });
    }

    private static async getlevels(
        request?: ILevelsRequest,
        loading: boolean = true
    ): Promise<ILevelsResponse> {
        const res: ILevelsResponse = await axiosFetchBuilder({
            url: Path.levels,
            loading,
            tag: "update",
            params: {
                ...request,
                per_page: LevelsService.perPage
            }
        } as IAxiosConfig);
        LevelsService.levels.meta = (res as any)._meta;
        return res;
    }

    static async fetchlevels(request: ILevelsRequest) {
        const res: ILevelsResponse = await LevelsService.getlevels(request);
        LevelsService.levels.items = res.items;
    }

    static async loadMorelevels(request: ILevelsRequest) {
        const res: ILevelsResponse = await LevelsService.getlevels(request, false);
        LevelsService.levels.items = [...LevelsService.levels.items, ...res.items];
    }

    static async RestoreLevelAccess(id: number): Promise<ILevelRestoreResponse | IErrorResponse> {
        try {
            return await axiosFetchBuilder({
                url: `${Path.levelsRestore}/${id}`,
                method: "PUT",
                loading: false
            } as IAxiosConfig);
        } catch (err: any) {
            const error: IErrorResponse = {};
            if (!err.response) {
                error.status = HttpErrorStatusCode.exception;
                error.message = err.toString().includes("Network Error") ? "Ошибка сети" : err;
                return error;
            }
            error.status = err.response.data.status ?? HttpErrorStatusCode.exception;
            error.code = err.response.data.code ?? 0;
            error.message = err.response.data.message ?? "";
            error.name = err.response.data.name ?? "";
            return error;
        }
    }
}

export default LevelsService;
