import {Col, Icon, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import date from "../../../../../core/functions/date";
import NumberFormat from "react-number-format";

export default function ListPayouts({created_at, type, card, phone, status, sum}: IProps) {
    const {t} = useTranslation();

    const dateFormat: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
    };

    const dateFormatMobile: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric"
    };

    return (
        <Typography as="div" className="payouts-history">
            <Row>
                <Col md={0} xs={24}>
                    <Typography as="div" className="date-format-mobile">
                        {" "}
                        <Typography size={14} className="date-format-mobile-text">
                            {date(created_at, dateFormatMobile)}
                        </Typography>
                    </Typography>
                </Col>
                <Col md={4} xs={0}>
                    <Typography size={17}>{date(created_at, dateFormat)}</Typography>
                </Col>
                <Col md={0} xs={24}>
                    {_.isEqual(type, "card") ? (
                        <Typography size={17}>{card}</Typography>
                    ) : (
                        <Typography size={17}>{phone}</Typography>
                    )}
                </Col>

                <Col md={7} xs={0}>
                    {_.isEqual(type, "card") ? (
                        <Typography size={17}>{t("Вывод бонусов на карту")}</Typography>
                    ) : (
                        <Typography size={17}>{t("Пополнение баланса")}</Typography>
                    )}
                </Col>

                <Col md={6} xs={0}>
                    {_.isEqual(type, "card") ? (
                        <Typography size={17}>{card}</Typography>
                    ) : (
                        <Typography size={17}>{phone}</Typography>
                    )}
                </Col>
                <Col md={0} xs={12}>
                    <Typography
                        size={14}
                        className="date-format-mobile-payouts"
                        color="rgba(255, 255, 255, 0.5)"
                    >
                        {date(created_at, dateFormat)}
                    </Typography>
                </Col>
                <Col md={3} xs={0}>
                    {_.isEqual(status, "new") && <Typography size={17}>{t("Новый")}</Typography>}
                    {_.isEqual(status, "process") && (
                        <Typography size={17}>{t("В процессе")}</Typography>
                    )}
                    {_.isEqual(status, "confirmed") && (
                        <Typography size={17}>{t("Выплачен")}</Typography>
                    )}
                    {_.isEqual(status, "rejected") && (
                        <Typography size={17}>{t("Отклонен")}</Typography>
                    )}
                </Col>

                <Col md={4} xs={12} className="col-mobile-payouts-sum">
                    <Typography as="div" className="mobile-payouts-sum">
                        <Space>
                            <Typography size={24} className="payouts-sum">
                                <NumberFormat
                                    thousandSeparator=" "
                                    displayType="text"
                                    value={sum.toFixed(0)}
                                />
                            </Typography>

                            <Typography as="div">
                                <Icon name="Polygon_5" size="8" />
                            </Typography>
                        </Space>
                        <Typography
                            className="mobile-text-payout"
                            size={14}
                            color="rgba(255, 255, 255, 0.5)"
                        >
                            {t("Выполнено")}
                        </Typography>
                    </Typography>
                </Col>
            </Row>
        </Typography>
    );
}

interface IProps {
    created_at: string;
    type: string;
    card: string;
    phone: number;
    status: any;
    sum: number;
}
