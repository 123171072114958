import {ReactFragment, ReactNode, useState} from "react";
import {useTranslation} from "react-i18next";
import {Modal} from "@bezlimit/bezlimit-ui";
import {useNavigate} from "react-router-dom";
//images
import SuccessImg from "../../../../images/successImg";
import ErrorImg from "../../../../images/errorImg";
import {observer} from "mobx-react";

type TProps = {
    visible: boolean;
    cancelHandler: () => void;
};

type TContent = {
    [key: string]: {
        title: string;
        footer: Array<ReactNode>;
        children: ReactFragment | ReactNode;
    };
};

const ModalBankCardsSaving = ({visible, cancelHandler}: TProps) => {
    const {t} = useTranslation("common");
    const navigate = useNavigate();
    const [state, setState] = useState<"default" | "success" | "error">(
        (function cardAdd() {
            switch (document.location.hash) {
                case "#already_added_url":
                    return "default";
                case "#card-add-success":
                    return "success";
                case "#card-add-fail":
                    return "error";
                default:
                    return "default";
            }
        })()
    );

    const onCloseModal = () => {
        cancelHandler();
        navigate(
            `/${
                window.location.href
                    .split("/")
                    [window.location.href.split("/").length - 1].split("?")[0]
                    .split("#")[0]
            }`
        );
        setState("default");
    };

    const content: TContent = {
        default: {
            title: t("Произошла ошибка"),
            footer: [],
            children: (
                <>
                    <div className="removement-modal--description">
                        {t("Банковская карта была добавлена ранее")}
                    </div>
                </>
            )
        },
        success: {
            title: t("Карта добавлена"),
            footer: [],
            children: (
                <>
                    <div className="removement-modal--description">
                        {`${t("Карта")} ${
                            "•••• •••• •••• " + document.location.search.split("&")[0].split("=")[1]
                        } ${t(
                            "успешно добавлена в ваш список карт, теперь вы можете выбирать сохраненную карту при оплате"
                        )}`}
                    </div>
                </>
            )
        },
        error: {
            title: t("Карта не добавлена"),
            footer: [],
            children: (
                <>
                    <div style={{textAlign: "center"}}>
                        {t("При добавлении карты возникли ошибки, попробуйте добавить позже")}
                    </div>
                </>
            )
        }
    };

    let ModalImage = null;
    if (state === "success") {
        ModalImage = <SuccessImg className={"header-img"} />;
    } else if (state === "default") {
        ModalImage = <ErrorImg className={"header-img"} />;
    } else if (state === "error") {
        ModalImage = <ErrorImg className={"header-img"} />;
    }

    return (
        <Modal
            centered
            visible={visible}
            onCancel={onCloseModal}
            title={content[state].title}
            footer={content[state].footer}
            img={ModalImage}
            className="modal-removement-number-list bl-removement-bank-cards-modal bl-modal-bank-cards-saving"
        >
            {content[state].children}
        </Modal>
    );
};

export default observer(ModalBankCardsSaving);
