import {Typography, Button} from "@bezlimit/bezlimit-ui";
import React, {useCallback, useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import {registerStoreContext} from "../../forms/registerStore";
import {UserContext} from "../../../core/Contexts";
import {makeAutoObservable} from "mobx";
// services
import BonusesService from "../../../services/BonusesService";
// hooks
import UserService from "../../../services/UserService";
import {observer} from "mobx-react";
import {InvisibleSmartCaptcha} from "@yandex/smart-captcha";

type TidDurationChangeHandler = {
    idDuration: number | undefined;
};

export const idDurationChangeHandler: TidDurationChangeHandler = makeAutoObservable({
    idDuration: undefined
});

export default React.memo(
    observer(function DurationPhone({
        phone,
        isCodeConfirmationOutputBonuses,
        card,
        sum,
        runInit = true
    }: any) {
        const {t} = useTranslation();
        const contexUser = useContext(UserContext);
        const [resendCodeSeconds, setResendCodeSeconds] = useState<number | null>(null);
        const first = useRef<number>();
        const [token, setToken] = useState("");
        const [visible, setVisible] = useState(false);
        const clearTime = useRef<any>();

        const handleChallengeHidden = useCallback(() => {
            setVisible(false);
        }, []);

        const handleButtonClick = () => {
            setVisible(true);
        };

        const handleTransferPhoneButtonClick = async () => {
            if (resendCodeSeconds === null || resendCodeSeconds === 0) {
                if (contexUser?.email === "IsRegisteredPhone") {
                    const requestValues = {
                        name:
                            registerStoreContext?.firstName! +
                            " " +
                            registerStoreContext?.lastName +
                            " " +
                            registerStoreContext?.patronymic,
                        phone: phone,
                        ...(registerStoreContext.ref_id
                            ? {ref_id: registerStoreContext.ref_id}
                            : {}),
                        token: token
                    };
                    UserService.getUsersRegister(requestValues).then(() => {});
                } else {
                    await UserService.putRestorePassword({
                        phone:
                            phone.replace(/\+/g, "").length === 11
                                ? +phone.replace(/\+/g, "").substring(1)
                                : +phone.replace(/\+/g, "")
                    });
                }
            }
        };

        const outputBonuses = async () => {
            await BonusesService.postPayoutBonuses({
                card,
                sum,
                type: "card"
            }).then((res: any) => {
                first.current = res.timeout;
            });
            countDown(first.current);
        };

        const countDown = (count: number = 180): void => {
            clearInterval(clearTime.current);
            !isCodeConfirmationOutputBonuses && handleTransferPhoneButtonClick();
            clearTime.current = setInterval(() => {
                count--;
                if (count <= 0) {
                    clearInterval(clearTime.current);
                    count = 0;
                }
                setResendCodeSeconds(count);
            }, 1000);
        };

        const countDown2 = (count: number = 180): void => {
            clearInterval(clearTime.current);
            clearTime.current = setInterval(() => {
                count--;
                if (count <= 0) {
                    clearInterval(clearTime.current);
                    count = 0;
                }
                setResendCodeSeconds(count);
            }, 1000);
        };

        useEffect(() => {
            if (runInit) {
                isCodeConfirmationOutputBonuses && outputBonuses();
                !isCodeConfirmationOutputBonuses && countDown();
            } else {
                countDown2();
            }
            return () => {
                first.current = 0;
            };
        }, []);

        useEffect(() => {
            if (token) {
                handleTransferPhoneButtonClick();
                countDown();
            }
        }, [token]);

        return (
            <Typography as="div" className="bl-duration">
                <InvisibleSmartCaptcha
                    sitekey="ysc1_KjLgEbqva5WRdw5TBYh85XsB19EsqnwzcSAfvB1n1478758f"
                    onSuccess={setToken}
                    onChallengeHidden={handleChallengeHidden}
                    visible={visible}
                    language="ru"
                />
                {resendCodeSeconds !== null && (
                    <div
                        className={
                            isCodeConfirmationOutputBonuses
                                ? "my-modal-code__descriptionBonuses"
                                : "my-modal-code__description"
                        }
                    >
                        {resendCodeSeconds > 0 && (
                            <>
                                {t("Получить новый код можно через ")}
                                <Typography
                                    as="span"
                                    className="any-modal-code__timer"
                                    color={isCodeConfirmationOutputBonuses ? "#642878" : "white"}
                                >
                                    {moment.utc(resendCodeSeconds * 1000).format("m:ss")}
                                </Typography>
                            </>
                        )}
                        {resendCodeSeconds === 0 && (
                            <Button
                                type="text"
                                onClick={() => {
                                    handleButtonClick();
                                    !isCodeConfirmationOutputBonuses && countDown();
                                    isCodeConfirmationOutputBonuses && outputBonuses();
                                }}
                            >
                                <Typography
                                    as="span"
                                    size={14}
                                    color="#642878"
                                    className="text-helper pointer"
                                >
                                    {t("Получить новый код")}
                                </Typography>
                            </Button>
                        )}
                    </div>
                )}
            </Typography>
        );
    })
);
