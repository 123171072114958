import {Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";

export default function Block2() {
    return (
        <Typography as="div" className="bl-block2">
            <Typography as="div" className="title">
                Суть акции:
            </Typography>
            <Typography as="div" className="list mb24">
                <Typography as="div" className="subtitle">
                    Приведи друга — получи скидку навсегда!
                </Typography>
                <Typography as="div" className="items">
                    <Typography as="div" className="item">
                        <Typography as="div" className="left">
                            1
                        </Typography>
                        <Typography as="div" className="right">
                            Ваш клиент приводит друга в Безлимит.
                        </Typography>
                    </Typography>
                    <Typography as="div" className="item">
                        <Typography as="div" className="left">
                            2
                        </Typography>
                        <Typography as="div" className="right">
                            За это он получает пожизненную скидку на связь.
                        </Typography>
                    </Typography>
                    <Typography as="div" className="item">
                        <Typography as="div" className="left">
                            3
                        </Typography>
                        <Typography as="div" className="right">
                            Больше друзей — выше скидка. В итоге связь для клиента может стать
                            вообще бесплатной!
                        </Typography>
                    </Typography>
                </Typography>
            </Typography>

            <Typography as="div" className="list">
                <Typography as="div" className="subtitle">
                    Выигрывают все!
                </Typography>
                <Typography as="div" className="items">
                    <Typography as="div" className="item g16">
                        <Typography as="div" className="left">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="27"
                                height="13"
                                viewBox="0 0 27 13"
                                fill="none"
                            >
                                <path
                                    d="M26.5303 7.03033C26.8232 6.73744 26.8232 6.26256 26.5303 5.96967L21.7574 1.1967C21.4645 0.903806 20.9896 0.903806 20.6967 1.1967C20.4038 1.48959 20.4038 1.96447 20.6967 2.25736L24.9393 6.5L20.6967 10.7426C20.4038 11.0355 20.4038 11.5104 20.6967 11.8033C20.9896 12.0962 21.4645 12.0962 21.7574 11.8033L26.5303 7.03033ZM0 7.25H26V5.75H0V7.25Z"
                                    fill="white"
                                />
                            </svg>
                        </Typography>
                        <Typography as="div" className="right">
                            Ваш клиент получает скидку на связь.
                        </Typography>
                    </Typography>
                    <Typography as="div" className="item g16">
                        <Typography as="div" className="left">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="27"
                                height="13"
                                viewBox="0 0 27 13"
                                fill="none"
                            >
                                <path
                                    d="M26.5303 7.03033C26.8232 6.73744 26.8232 6.26256 26.5303 5.96967L21.7574 1.1967C21.4645 0.903806 20.9896 0.903806 20.6967 1.1967C20.4038 1.48959 20.4038 1.96447 20.6967 2.25736L24.9393 6.5L20.6967 10.7426C20.4038 11.0355 20.4038 11.5104 20.6967 11.8033C20.9896 12.0962 21.4645 12.0962 21.7574 11.8033L26.5303 7.03033ZM0 7.25H26V5.75H0V7.25Z"
                                    fill="white"
                                />
                            </svg>
                        </Typography>
                        <Typography as="div" className="right">
                            Новый клиент (друг) получает красивый номер
                        </Typography>
                    </Typography>
                    <Typography as="div" className="item g16">
                        <Typography as="div" className="left">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="27"
                                height="13"
                                viewBox="0 0 27 13"
                                fill="none"
                            >
                                <path
                                    d="M26.5303 7.03033C26.8232 6.73744 26.8232 6.26256 26.5303 5.96967L21.7574 1.1967C21.4645 0.903806 20.9896 0.903806 20.6967 1.1967C20.4038 1.48959 20.4038 1.96447 20.6967 2.25736L24.9393 6.5L20.6967 10.7426C20.4038 11.0355 20.4038 11.5104 20.6967 11.8033C20.9896 12.0962 21.4645 12.0962 21.7574 11.8033L26.5303 7.03033ZM0 7.25H26V5.75H0V7.25Z"
                                    fill="white"
                                />
                            </svg>
                        </Typography>
                        <Typography as="div" className="right">
                            Вы зарабатываете бонусы, подключая красивые номера друзьям клиента.
                        </Typography>
                    </Typography>
                </Typography>
            </Typography>
        </Typography>
    );
}
