import {Block, Space, Typography} from "@bezlimit/bezlimit-ui";
import NumberFormat from "react-number-format";
import {observer} from "mobx-react";
import "./style.scss";
import _ from "lodash";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
//core
import {LayoutContext} from "../../../core/Contexts";
// forms
import FormPaymentsNumberBonuses from "../../forms/paymentsNumberBonuses";
// services
import BonusesService from "../../../services/BonusesService";
import {IReservations} from "../../../core/models/Reservations";

export default React.memo(
    observer(function PaymentForNumberBonuses({
        phone,
        tariffName,
        price,
        activeKey,
        recommend_sum,
        setItems
    }: IProps) {
        const contextLayout = useContext(LayoutContext);
        const {t} = useTranslation();

        return (
            <Typography as="div" className="bl-payment-number-bonuses">
                <Space
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 24}
                    direction="vertical"
                    align="center"
                >
                    <Typography as="div">
                        <Space size={16} direction="vertical" align="center">
                            <Typography
                                size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                                level={2}
                                color="#000000"
                            >
                                {t("Оплата бонусами")}
                            </Typography>
                            <Typography color="#828282" className="subtitle">
                                {t("Оплата номера")} <span>{phone}</span> <br />
                                {t(`по тарифу ${tariffName}`)}
                            </Typography>
                            <Block>
                                <Space direction="vertical">
                                    <Typography size={12} fontFamily="hauss" color="#CFC8DB">
                                        {t("Бонусы доступные для списания")}
                                    </Typography>
                                    <Typography as="div" size={24} fontFamily="alumna">
                                        <NumberFormat
                                            className="format-accumulated-sum"
                                            thousandSeparator=" "
                                            displayType="text"
                                            value={BonusesService.accumulated.toFixed(0)}
                                        />
                                    </Typography>
                                </Space>
                            </Block>
                        </Space>
                    </Typography>
                    <FormPaymentsNumberBonuses
                        price={recommend_sum}
                        phone={phone}
                        activeKey={activeKey}
                        setItems={setItems}
                    />
                </Space>
            </Typography>
        );
    })
);

interface IProps {
    phone: number;
    tariffName: string;
    price: number;
    activeKey: string;
    recommend_sum?: number;
    setItems: React.Dispatch<React.SetStateAction<IReservations[]>>;
}
