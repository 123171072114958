import {useEffect, useState} from "react";
import {matchPath, useLocation} from "react-router-dom";
import _ from "lodash";
// routes
import privateRoutes from "../../layout/routes/privateRoutes";
import publicRoutes from "../../layout/routes/publicRoutes";

export default function useSearchRoute(token: string | null) {
    const [state, setState] = useState<typeof publicRoutes | typeof privateRoutes>();
    const location = useLocation();

    useEffect(() => {
        const searchRoutes = token ? privateRoutes : publicRoutes;
        setState(
            _.chain(searchRoutes)
                .reduce<any[]>((res, i) => {
                    (res || (res = [])).push(i);
                    return res;
                }, [])
                .filter((i) => matchPath(i.path, location.pathname))
                .head()
                .valueOf()
        );
    }, [location.pathname, token]);

    return state;
}
