import {Col, Tabs, Title, Typography, Back} from "@bezlimit/bezlimit-ui";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
import "./style.scss";
import {observer} from "mobx-react";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
//core
import {LayoutContext} from "../../../../core/Contexts";
//services
import TariffsService from "../../../../services/TariffsService";
import UserService from "../../../../services/UserService";
// hooks
import useSearchRoute from "../../../../core/hooks/useSearchRoute";
//components
import Desktop from "../../../blocks/tariffs/desktop";
import Mobile from "../../../blocks/tariffs/mobile";

export default observer(function TariffsPage() {
    const context = useContext(LayoutContext);
    const navigate = useNavigate();
    const route = useSearchRoute(UserService.token);

    useEffect(() => {
        TariffsService.fetchTariffAvailableItems();
    }, []);
    const tariffAvailable = TariffsService.items;

    const {t} = useTranslation();
    const [activeKey, setActiveKey] = useState<string | undefined>();

    useEffect(() => {
        setActiveKey(
            _.get(_.chain(tariffAvailable).groupBy("group_name").entries().valueOf(), "[0][0]")
        );
    }, [tariffAvailable]);

    return (
        <Typography
            as="div"
            className={`bl-plans ${
                (context?.width ?? 0) > 578 ? "bl-plans-desktop" : "bl-plans-mobile"
            }`}
        >
            <Back navigate={() => navigate("/numbers")}>{t("Назад к номерам")}</Back>
            <Col span={24}>
                <Title className="bl-title" title={_.get(route, "title")} />
            </Col>
            {_.isEqual(_.size(tariffAvailable), 0) ? (
                <Typography className="not-connected">
                    {tariffAvailable === undefined
                        ? t("Информация о тарифах загружается")
                        : t("Доступных для смены тарифов на номере нет")}
                </Typography>
            ) : (
                <Tabs
                    className="tabs-container"
                    tabPosition="top"
                    activeKey={activeKey}
                    animated={false}
                    centered
                    onTabClick={(key) => {
                        setActiveKey(key);
                    }}
                    tabPanes={_.chain(tariffAvailable)
                        .groupBy("group_name")
                        .map((i, k) => {
                            return {
                                title: _.get(i, "[0].group_name"),
                                key: k,
                                className: "content tariffs-cards",
                                content:
                                    (context?.width ?? 0) > 576 ? (
                                        <Desktop {...i} />
                                    ) : (
                                        <Mobile {...i} />
                                    )
                            };
                        })
                        .valueOf()}
                />
            )}
        </Typography>
    );
});
