import {makeAutoObservable} from "mobx";
// import {IMeta, IItems} from "../core/models/Bonuses";
// core

class Bonuses {
    [Symbol.toStringTag] = this.constructor.name;
    private _paymentSum: number = 0;
    private _payoutSum: number = 0;
    private _processSum: number = 0;
    private _rateBasicPersonal: number = 0;
    private _rateBasicLevel: number = 0;
    private _ratePhoneBl: number = 0;
    private _futurePaymentSum: number = 0;
    private _totalCount: number = 0;
    private _summarySum: number = 0;
    private _summaryProcessSum: number = 0;
    private _rateLoyalty: number = 0;
    private _firstPaymentAt: string | undefined;
    private _firstPayoutAt: string | undefined;
    private _rateLoyaltyPersonal: number = 0;
    private _rateLoyaltyLevel: number = 0;

    constructor() {
        makeAutoObservable(this);
    }

    get rateLoyaltyLevel() {
        return this._rateLoyaltyLevel!;
    }

    set rateLoyaltyLevel(value: number) {
        this._rateLoyaltyLevel = value;
    }

    get firstPaymentAt() {
        return this._firstPaymentAt!;
    }

    set firstPaymentAt(value: string) {
        this._firstPaymentAt = value;
    }

    get firstPayoutAt() {
        return this._firstPayoutAt!;
    }

    set firstPayoutAt(value: string) {
        this._firstPayoutAt = value;
    }

    get paymentSum() {
        return this._paymentSum;
    }

    set paymentSum(value: number) {
        this._paymentSum = value;
    }

    get payoutSum() {
        return this._payoutSum;
    }

    set payoutSum(value: number) {
        this._payoutSum = value;
    }

    get processSum() {
        return this._processSum;
    }

    set processSum(value: number) {
        this._processSum = value;
    }

    get futurePaymentSum() {
        return this._futurePaymentSum;
    }

    set futurePaymentSum(value: number) {
        this._futurePaymentSum = value;
    }

    get rateBasicPersonal() {
        return this._rateBasicPersonal;
    }

    set rateBasicPersonal(value: number) {
        this._rateBasicPersonal = value;
    }

    get rateBasicLevel() {
        return this._rateBasicLevel;
    }

    set rateBasicLevel(value: number) {
        this._rateBasicLevel = value;
    }

    get rateLoyalty() {
        return this._rateLoyalty;
    }

    set rateLoyalty(value: number) {
        this._rateLoyalty = value;
    }

    get rateLoyaltyPersonal() {
        return this._rateLoyaltyPersonal;
    }

    set rateLoyaltyPersonal(value: number) {
        this._rateLoyaltyPersonal = value;
    }

    get ratePhoneBl() {
        return this._ratePhoneBl;
    }

    set ratePhoneBl(value: number) {
        this._ratePhoneBl = value;
    }

    get summarySum() {
        return this._summarySum;
    }

    set summarySum(value: number) {
        this._summarySum = value;
    }

    get summaryProcessSum() {
        return this._summaryProcessSum;
    }

    set summaryProcessSum(value: number) {
        this._summaryProcessSum = value;
    }

    get totalCount() {
        return this._totalCount;
    }

    set totalCount(value: number) {
        this._totalCount = value;
    }
}

export default Bonuses;
