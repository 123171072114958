import {useContext} from "react";
import {Back, Button, Col, Icon, Row, Space, Title, Typography} from "@bezlimit/bezlimit-ui";
import {notification} from "antd";
import _ from "lodash";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import "./style.scss";
// core
import useSearchRoute from "../../../../../core/hooks/useSearchRoute";
import {LayoutContext, ModalContext} from "../../../../../core/Contexts";
// services
import UserService from "../../../../../services/UserService";
// components
import ShareSuperLinkModal from "../../../../modal/shareSuperLink";
import {useNavigate} from "react-router-dom";

const Top: React.FC = observer(() => {
    const route = useSearchRoute(UserService.token);
    const {t} = useTranslation();
    const contextModal = useContext(ModalContext);
    const contextLayout = useContext(LayoutContext);
    const navigate = useNavigate();

    const onShare = () => {
        contextModal?.setChildren(<ShareSuperLinkModal />);
        contextModal?.setVisible(true);
        contextModal?.setOnCancel(() => () => {
            contextModal?.setCssClass("");
            contextModal?.setVisible(false);
        });
    };

    const onCopy = () => {
        navigator.clipboard.writeText(UserService.systemVars?.super_link!).then(() => {
            notification.config({
                maxCount: 1
            });
            notification.open({
                message: t("Ссылка скопирована в буфер обмена"),
                placement: "bottom",
                closeIcon: undefined,
                className: "copy-referral",
                duration: 2
            });
        });
    };

    const onDownload = async () => {
        const image = await fetch(UserService.systemVars?.qr_code_super_link!);
        const imageBlog = await image.blob();
        const imageURL = URL.createObjectURL(imageBlog);

        const link = document.createElement("a");
        link.href = imageURL;
        link.download = "QRCode";
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
    };

    return (
        <>
            <Back navigate={() => navigate("/")} />
            <Typography as="div" className="bl-sl-top">
                <Typography as="div" className="inner">
                    <Space
                        direction="vertical"
                        size={_.gt(contextLayout?.width ?? 0, 992) ? 107 : 36}
                    >
                        <Typography as="div" className="text-center">
                            <Title>
                                <Typography as="div" className="first">
                                    {t(_.get(route, "title", ""))}
                                </Typography>
                                <Typography as="div" className="second">
                                    {t("Твой новый")}
                                </Typography>
                                <Typography as="div" className="third">
                                    {t("инструмент заработка")}
                                </Typography>
                            </Title>
                        </Typography>
                        <Typography as="div" className="text-center">
                            <Typography as="div" className="qr">
                                <img src={UserService.systemVars?.qr_code_super_link} alt="" />
                            </Typography>
                        </Typography>
                        <Typography as="div" className="text-center actions">
                            <Row gutter={[16, _.gte(contextLayout?.width ?? 0, 992) ? 20 : 24]}>
                                <Col xs={24} lg={12}>
                                    <Button
                                        type="outline"
                                        icon={<Icon name="selWhite" size="20" />}
                                        onClick={onShare}
                                    >
                                        {t("Поделиться суперссылкой")}
                                    </Button>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Button
                                        type="outline"
                                        icon={<Icon name="superLink" size="24" />}
                                        onClick={onCopy}
                                    >
                                        {t("Скопировать суперссылку")}
                                    </Button>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Button
                                        type="outline"
                                        icon={<Icon name="dowload" size="20" />}
                                        onClick={onDownload}
                                    >
                                        {t("Скачать QR-код")}
                                    </Button>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Button
                                        type="outline"
                                        icon={<Icon name="view_numbers" size="20" />}
                                        onClick={() => {
                                            window.open(
                                                UserService.systemVars?.super_link,
                                                "_blank"
                                            );
                                        }}
                                    >
                                        {t("Просмотреть номера")}
                                    </Button>
                                </Col>
                            </Row>
                        </Typography>
                    </Space>
                </Typography>
            </Typography>
        </>
    );
});

export default Top;
