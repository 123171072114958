import {makeAutoObservable} from "mobx";
// core
import { IBank } from "../core/models/Bank";
// core

class Banks {
    [Symbol.toStringTag] = this.constructor.name;
    private _items!: IBank[] | undefined;
    private _token: string | undefined = undefined;

    constructor() {
        makeAutoObservable(this);
    }

    get token() {
        return this._token;
    }

    set token(value: string | undefined) {
        this._token = value;
    }

    get items() {
        return this._items;
    }

    set items(value: IBank[] | undefined) {
        this._items = value;
    }
}

export default Banks;