import React, {useContext, useEffect, useRef, useState} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import Store from "../../../stores";
import "./style.scss";
import cn from "classnames";
import BanksService from "../../../services/BanksService";
import {ModalContext} from "../../../core/Contexts";
import _ from "lodash";

interface IProps {
    imgSrc: string;
    paymentId: number;
    phone: any;
}

export default observer(function QRCodeModal({imgSrc, paymentId, phone}: IProps) {
    const {t} = useTranslation();
    const [seconds, setSeconds] = useState(30);
    const [loading, setLoading] = useState(false);
    const contextModal = useContext(ModalContext);

    const savedCallBack = useRef<() => void>();

    const getTime = async () => {
        if (seconds === 0) {
            setLoading(true);
            const timeS = Date.now();
            const res = await BanksService.getPaymentStatus(paymentId);
            const timeE = Date.now();
            const diff = timeE - timeS;
            if (diff < 500) {
                await new Promise((resolve) => setTimeout(resolve, 1000));
            }
            if (res?.status === 1) {
                Store.paymentStatus = 1;
                contextModal?.setCssClass("");
                contextModal?.setVisible(false);
            }
            setLoading(false);
        }
        const newSeconds = !seconds ? 30 : seconds - 1;
        setSeconds(newSeconds);
    };

    useEffect(() => {
        savedCallBack.current = getTime;
    });

    useEffect(() => {
        const interval = setInterval(() => {
            savedCallBack.current && savedCallBack.current();
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (
        <div className="qrcode-wrapper">
            <Space direction="vertical" size={32}>
                <div>
                    <Typography className="qrcode-title">{t("Отсканируйте QR-код")}</Typography>
                    <Typography className="qrcode-text">
                        {t("Наведите камеру телефона на QR-код \nи откройте появившуюся ссылку.")}
                    </Typography>
                </div>
                <div>
                    <Typography className={cn("qrcode-title", {shimmer: loading})}>
                        {t("Номер ")}
                        <span>{phone}</span>
                    </Typography>
                    <Typography className={cn("qrcode-title", {shimmer: loading})}>
                        {t("еще не оплачен")}
                    </Typography>
                </div>
                {_.lt(seconds, 10) ? (
                    <Typography className="qrcode-text gray">
                        {t(`Данные о статусе платежа \nобновятся через 00:0${seconds}`)}
                    </Typography>
                ) : (
                    <Typography className="qrcode-text gray">
                        {t(`Данные о статусе платежа \nобновятся через 00:${seconds}`)}
                    </Typography>
                )}
                <img className="qrcode-img" src={imgSrc} width={340} alt="qr" />
                <Typography className="qrcode-text-bottom" size={18}>
                    {t("Не закрывайте окно до завершения операции")}
                </Typography>
            </Space>
        </div>
    );
});
