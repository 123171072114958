import React, {useContext, useRef} from "react";
import {Button, Col, Form, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// services
import BonusesService from "../../../services/BonusesService";
// core
import {InterceptorContext, LayoutContext, ModalContext} from "../../../core/Contexts";
import {getFieldsErrorSize} from "../../../core/functions/form";
import {IPayoutRequest} from "../../../core/models/Bonuses";
import useFormHandler from "../../../core/hooks/useFormHandler";
import useFieldFocus from "../../../core/hooks/useFieldFocus";
import useOnCancel from "../../../core/hooks/useOnCancel";
import {Rules} from "../rules";
// modal
import Error from "../../modal/error";
import Success from "../../modal/success";
import CodeConfirmationReplenishBonuses from "../../modal/codeConfirmationReplenishBonuses";
//fields
import ReplenichBonusesField from "../../fields/replenichBonuses";
//services
import ReservationsService from "../../../services/ReservationsService";
//core
import {IReservations} from "../../../core/models/Reservations";

export default React.memo(
    observer(function FormPaymentsNumberBonuses({price, phone, activeKey, setItems}: IProps) {
        const context = useContext(InterceptorContext);
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const ref = useRef(null);
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        const onCancel = useOnCancel(formInstance);
        useFieldFocus(ref, "phone");

        const onClickSum = () => {
            const fields = formInstance.getFieldsValue();
            fields.sum = price;
            formInstance.setFieldsValue(fields);
        };

        const onChange = (values: any) => {
            if (_.includes(values[0].name, "sum")) {
                let value = _.replace(
                    _.gte(values[0].value, price) &&
                        _.lte(BonusesService.accumulated, 5000) &&
                        _.gte(BonusesService.accumulated, 5000)
                        ? values[0].value
                        : _.gte(values[0].value, 5000)
                        ? "5000"
                        : values[0].value,
                    /[^0-9]/gi,
                    ""
                );

                const fields = formInstance.getFieldsValue();
                fields.sum = value;
                formInstance.setFieldsValue(fields);
            }
        };

        const onSubmit = async ({code, phone, sum}: any) => {
            await BonusesService.postPayoutBonuses({
                code,
                phone,
                sum,
                type: "phone"
            });

            contextModal?.setChildren(
                <Success
                    title={<Typography as="span">{t("Номер успешно оплачен")}</Typography>}
                    phone={phone}
                    subtitle={t(`Зачислено ${form.getFieldValue("sum")} ₽`)}
                />
            );
            setItems([]);
            const {items} = await ReservationsService.getReservationsByIsActivated(
                {
                    status: ReservationsService.isActivated(activeKey)
                },
                false
            );
            ReservationsService.items = items;

            setItems(items);
        };

        const onFinish = async ({sum}: IPayoutRequest) => {
            try {
                const {id} = await BonusesService.postPayoutBonuses({
                    phone: phone.toString(),
                    sum,
                    type: "phone"
                });
                contextModal?.setChildren(
                    <CodeConfirmationReplenishBonuses
                        phone={phone}
                        id={id}
                        sum={sum}
                        type="phone"
                        onSubmit={onSubmit}
                    />
                );

                formInstance.resetFields();
            } catch (e: any) {
                contextModal?.setChildren(<Error phone={phone.toString()} />);
            }
        };

        return (
            <div ref={ref}>
                <Typography as="div" className="bl-payments-number-form">
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="ten-for-bezlimit"
                        onFinish={onFinish}
                        onFieldsChange={onChange}
                        autoComplete="off"
                        initialValues={{
                            phone: "",
                            sum: ""
                        }}
                    >
                        <Space
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 8 : 24}
                            direction="vertical"
                        >
                            <Typography
                                className="recommended-sum"
                                as="div"
                                color="#00000"
                                appearance="bold"
                            >
                                {t("Рекомендуемая сумма для пополнения")}
                                {_.lte(contextLayout?.width, 768) ? <br /> : " "}
                                <span onClick={onClickSum}>{price}</span> {t("бонусов")}
                            </Typography>
                            <Space size={20} className="input-summ-bonuses">
                                <Row>
                                    <Col span={10}>
                                        <ReplenichBonusesField
                                            name="sum"
                                            form={formInstance}
                                            pattern={Rules.regex(
                                                /^(10[0-9]|1[1-9]\d|[2-9]\d\d|5000)$/gi,
                                                ""
                                            )}
                                        />
                                    </Col>
                                    <Col span={14}>
                                        <Typography size={12} color="#828282">
                                            {t("от ")} {price} {t("до 5000 бонусов")}
                                        </Typography>
                                    </Col>
                                </Row>
                            </Space>

                            <Form.Item noStyle shouldUpdate>
                                {({getFieldsValue, getFieldsError}) => {
                                    const {sum} = getFieldsValue();
                                    const errors = getFieldsErrorSize(getFieldsError);
                                    const formIsComplete = !!sum && _.gte(sum, price) && !errors;

                                    return (
                                        <Button
                                            type={!formIsComplete ? "default" : "primary"}
                                            htmlType="submit"
                                            disabled={!formIsComplete}
                                            loading={context?.loadingBtn}
                                        >
                                            {t("Пополнить")}
                                        </Button>
                                    );
                                }}
                            </Form.Item>

                            {_.lte(contextLayout?.width ?? 0, 768) && (
                                <Typography
                                    as="div"
                                    className="text-center cancel"
                                    size={14}
                                    color="#000"
                                >
                                    <Button type="text" onClick={onCancel}>
                                        {t("Отменить")}
                                    </Button>
                                </Typography>
                            )}
                        </Space>
                    </Form>
                </Typography>
            </div>
        );
    })
);

interface IProps {
    price: any;
    phone: number;
    activeKey: string;
    setItems: React.Dispatch<React.SetStateAction<IReservations[]>>;
}
