import React, {useContext, useEffect, useState} from "react";
import {Block, Button, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext, ModalContext} from "../../../../core/Contexts";
// components
import InviteAUserModal from "../../../modal/inviteAUser";
// store
import Store from "../../../../stores";

export default React.memo(function InviteAUser({isPageDealers}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const contextModal = useContext(ModalContext);
    const {t} = useTranslation();
    const [percent, setPercent] = useState(0);

    const onClick = () => {
        contextModal?.setVisible(true);
        contextModal?.setChildren(<InviteAUserModal />);
    };

    useEffect(() => {
        setPercent(
            Store.user.loyalty
                ? Store.bonuses.rateBasicLevel
                : Store.bonuses.rateBasicLevel + Store.bonuses.rateLoyaltyLevel
        );
    }, [Store.user.loyalty, Store.bonuses.rateBasicLevel, Store.bonuses.rateLoyaltyLevel]);

    if (isPageDealers) {
        return (
            <Typography as="div" className="bl-invite-a-user bl-invite-a-user-isPageDealers">
                <Block>
                    <Space
                        direction={
                            _.gte(contextLayout?.width, 768) ?? 0 ? "horizontal" : "vertical"
                        }
                    >
                        <Typography
                            color="#FFFFFF"
                            size={_.gte(contextLayout?.width, 768) ?? 0 ? 17 : 14}
                            className="text"
                        >
                            {t(
                                "Пригласите нового пользователя и получайте бонусы за его активации"
                            )}
                        </Typography>
                        <Button type="default" onClick={onClick}>
                            {t("Пригласить")}
                        </Button>
                    </Space>
                </Block>
            </Typography>
        );
    }

    return (
        <Typography as="div" className="bl-invite-a-user">
            <Block title={t("Пригласить пользователя ")}>
                <Space size={_.gte(contextLayout?.width, 768) ?? 0 ? 49 : 40} direction="vertical">
                    <Typography
                        color="rgba(255, 255, 255, 0.5)"
                        size={_.gte(contextLayout?.width, 768) ?? 0 ? 17 : 14}
                        className="text"
                    >
                        {t(
                            `Пригласите нового пользователя из контакта своих друзей и получайте дополнительные ${percent}% бонусов за активации`
                        )}
                    </Typography>
                    <Button type="default" onClick={onClick}>
                        {t(`Получить ${percent}%`)}
                    </Button>
                </Space>
            </Block>
        </Typography>
    );
});

interface IProps {
    isPageDealers?: boolean;
}
