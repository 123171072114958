import React from "react";
import {Col, Divider, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";

import "./style.scss";
import {IFilterRequest} from "../../../forms/accrualsFilter";

export default function SummaryTitle({summarySum, summaryProcessSum, filterState}: IProps) {
    const {t} = useTranslation();
    return (
        <Row className="bl-summary-title">
            <Col span={24}>
                <Typography as="div" className="total-sum-amount">
                    <Space size={0}>
                        <Typography as="div" size={17} color="rgba(255, 255, 255, 0.5)">
                            {t("Накоплено")}

                            <Typography size={40} fontFamily="alumna" className="total-sum gold">
                                <NumberFormat
                                    thousandSeparator=" "
                                    displayType="text"
                                    value={summarySum.toFixed(!(summarySum % 1) ? 0 : 2)}
                                />
                            </Typography>
                        </Typography>
                        {!(!summaryProcessSum || filterState?.period) && (
                            <>
                                <Divider type="vertical" />
                                <Typography as="div" size={17} color="rgba(255, 255, 255, 0.5)">
                                    {t("Начисляются")}
                                    <Typography
                                        size={40}
                                        fontFamily="alumna"
                                        className="total-sum gold"
                                    >
                                        <NumberFormat
                                            thousandSeparator=" "
                                            displayType="text"
                                            value={summaryProcessSum.toFixed(
                                                !(summaryProcessSum % 1) ? 0 : 2
                                            )}
                                        />
                                    </Typography>
                                </Typography>
                            </>
                        )}
                    </Space>
                </Typography>
            </Col>
        </Row>
    );
}

interface IProps {
    summarySum: number;
    summaryProcessSum: number;
    filterState: IFilterRequest | undefined;
}
