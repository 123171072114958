import React from "react";
import {Block, Space, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
// components
import Buttons from "./buttons";
import Info from "./info";
// store
import Store from "../../../../stores";

export default React.memo(function PersonalManager({title, isProfile}: IProps) {
    return (
        <Typography as="div" className="bl-personal-manager">
            <Block>
                <Space
                    size={
                        Store.user?.level?.level === 1
                            ? 45
                            : Store.user?.level?.level === 2
                            ? 16
                            : Store.user?.level?.level === 0
                            ? 105
                            : 16
                    }
                    direction="vertical"
                >
                    <Info title={title} />
                    <Buttons isProfile={isProfile} />
                </Space>
            </Block>
        </Typography>
    );
});

interface IProps {
    title?: any;
    isProfile?: boolean;
}
