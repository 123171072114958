import {Form, Input} from "@bezlimit/bezlimit-ui";
import {FormInstance, FormItemProps} from "antd";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
// rules
import {Rules} from "../../forms/rules";

export default function QuantitySimField({title, formInstance, props}: IProps) {
    const {t} = useTranslation();

    const onKeyDown = (e: any) => {
        if (
            (_.inRange(e.keyCode, 48, 58) && !e.shiftKey) ||
            _.isEqual(e.keyCode, 8) ||
            (_.inRange(e.keyCode, 96, 106) && !e.shiftKey)
        ) {
        } else {
            e.preventDefault();
        }
    };

    const onInput = (e: any) => {
        if (e.target.value.length > 2) e.target.value = e.target.value.slice(0, 2);
    };

    return (
        <Form.Item {...props} className="field-fio" name="sim_count" rules={[Rules.required()]}>
            <NumberFormat<any>
                customInput={Input}
                title={t(title)}
                format={(value) => {
                    const code = value.substring(0, 2);
                    const nums1 = value.substring(4, 8);

                    return `${code}${nums1 ? ` ${nums1}` : ""}`;
                }}
                onInput={onInput}
                onKeyDown={onKeyDown}
            />
        </Form.Item>
    );
}

interface IProps extends FormItemProps<any> {
    title: string;
    formInstance: FormInstance<any>;
    props?: FormItemProps<any>;
}
