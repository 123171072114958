const RUB = "₽";

export const SVGWITHDEFS = ['active', 'blocked', 'new', 'process', 'sent'];

export function parseMs(ms: number) {
    const round = ms > 0 ? Math.floor : Math.ceil;

    return {
        days: round(ms / 86400000),
        hours: round(ms / 3600000) % 24,
        minutes: round(ms / 60000) % 60,
        seconds: round(ms / 1000) % 60
    };
}

export const getFieldContent = (
    title: string | null,
    contentBefore: string | null,
    value: string | null,
    contentAfter: string | null
): string => {
    const contentAfterNew =
        !contentAfter && title?.includes("Абонентская плата") ? RUB : contentAfter;
    return [contentBefore, value, contentAfterNew].join(" ");
};
