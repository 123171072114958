import {Typography, Spin, Space, Button} from "@bezlimit/bezlimit-ui";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import "./style.scss";
// hooks
import useCodeConfirmation from "../../../core/hooks/useCodeConfirmation";
import {LayoutContext} from "../../../core/Contexts";
import BonusesService from "../../../services/BonusesService";
import {makeAutoObservable} from "mobx";
import {observer} from "mobx-react";

type TidDurationChangeHandler = {
    idDuration: number | undefined;
};

export const idDurationChangeHandler: TidDurationChangeHandler = makeAutoObservable({
    idDuration: undefined
});

export default React.memo(
    observer(function Duration({onTrigger, card, sum}: IProps) {
        const contextLayout = useContext(LayoutContext);
        const {t} = useTranslation();
        const {duration, loading, onClick} = useCodeConfirmation(onTrigger);

        return (
            <Typography as="div" className="bl-duration">
                {duration.asMilliseconds() > 0 ? (
                    <Space
                        size={4}
                        direction={
                            _.gte(contextLayout?.width ?? 0, 768) ? "horizontal" : "vertical"
                        }
                    >
                        <Typography as="span" size={14} color="#000000" className="text-helper">
                            {t("Получить новый код можно")}
                        </Typography>
                        <Typography as="span" size={14} color="#642878" className="text-helper">
                            {t("через ")}
                            <span>
                                {loading ? (
                                    <Spin
                                        size="small"
                                        tip={`${duration.minutes()}:${
                                            duration.seconds() >= 10
                                                ? duration.seconds()
                                                : `0${duration.seconds()}`
                                        }`}
                                    />
                                ) : (
                                    `${duration.minutes()}:${
                                        duration.seconds() >= 10
                                            ? duration.seconds()
                                            : `0${duration.seconds()}`
                                    }`
                                )}
                            </span>
                        </Typography>
                    </Space>
                ) : (
                    <Button
                        type="text"
                        onClick={() => {
                            onClick();
                            const onFinish = async () => {
                                const {id} = await BonusesService.postPayoutBonuses({
                                    card,
                                    sum,
                                    type: "card"
                                });
                                idDurationChangeHandler.idDuration = id;
                            };
                            card && onFinish();
                        }}
                        loading={loading}
                    >
                        <Typography
                            as="span"
                            size={14}
                            color="#642878"
                            className="text-helper pointer"
                        >
                            {t("Получить новый код")}
                        </Typography>
                    </Button>
                )}
            </Typography>
        );
    })
);

interface IProps {
    onTrigger: () => any;
    card?: string;
    sum?: number;
}
