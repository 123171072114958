import React from "react";
import {Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import moment from "moment";
import _ from "lodash";
// components
import Text from "./text";

import "./style.scss";

export default React.memo(function Time({duration, suffix}: IProps) {
    const {t} = useTranslation();

    return (
        <Typography as="div">
            <Space direction="horizontal" size={4}>
                {!!duration.months() && (
                    <div className="lottery-timer-item">
                        <div className="lottery-timer-row">
                            <Text className="time date" text={`${duration.months()}`} />
                        </div>
                        <p className="tiny gold">
                            {suffix ? t("count_month_suffix", {count: duration.months()}) : ""}
                        </p>
                    </div>
                )}
                {(!!duration.months() || !!duration.days()) && (
                    <div className="lottery-timer-item">
                        <div className="lottery-timer-row">
                            <Text className="time date" text={`${duration.days()}`} />
                            <Text text=" " />
                        </div>
                        <p className="tiny gold">
                            {suffix ? t("count_day_suffix", {count: duration.days()}) : ""}
                        </p>
                    </div>
                )}
                {(!!duration.months() || !!duration.days() || !!duration.hours()) && (
                    <div className="lottery-timer-item">
                        <div className="lottery-timer-row">
                            <Text
                                className="time"
                                text={`${
                                    _.lt(duration.hours(), 10)
                                        ? `0${duration.hours()}`
                                        : duration.hours()
                                }`}
                            />
                            <Text text=":" className={"mr-9"} />
                        </div>
                        <p className="tiny gold">
                            {suffix ? t("count_hour_suffix", {count: duration.hours()}) : ""}
                        </p>
                    </div>
                )}
                <div className="lottery-timer-item">
                    <div className="lottery-timer-row">
                        <Text
                            className="time"
                            text={`${
                                _.lt(duration.minutes(), 10)
                                    ? `0${duration.minutes()}`
                                    : duration.minutes()
                            }`}
                        />
                        <Text text=":" className={"mr-9"} />
                    </div>
                    <p className="tiny gold">
                        {suffix ? t("count_minute_suffix", {count: duration.minutes()}) : ""}
                    </p>
                </div>
                <div className="lottery-timer-item">
                    <div className="lottery-timer-row">
                        <Text
                            className="time"
                            text={`${
                                _.lt(duration.seconds(), 10)
                                    ? `0${duration.seconds()}`
                                    : duration.seconds()
                            }`}
                        />
                    </div>
                    <p className="tiny gold">
                        {suffix ? t("count_second_suffix", {count: duration.seconds()}) : ""}
                    </p>
                </div>
            </Space>
        </Typography>
    );
});

interface IProps {
    duration: moment.Duration;
    suffix?: boolean;
}
