import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Col, Form, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import {IAuthRequest} from "../../../core/models/Auth";
import {InterceptorContext, LayoutContext} from "../../../core/Contexts";
import {getFieldsErrorSize} from "../../../core/functions/form";
// services
import UserService from "../../../services/UserService";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
import useFieldFocus from "../../../core/hooks/useFieldFocus";
// components
import LoginField from "../../fields/login";
import PasswordField from "../../fields/password";
import {useNavigate} from "react-router-dom";
import {makeAutoObservable} from "mobx";

type TIsPhoneAndLogin = {
    isPhone: boolean;
};

export const isPhoneAndLogin: TIsPhoneAndLogin = makeAutoObservable({
    isPhone: false
});

export default React.memo(
    observer(function LoginForm() {
        const context = useContext(LayoutContext);
        const ref = useRef(null);
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        useFieldFocus(ref, "login");
        const navigate = useNavigate();
        const contextInteceptor = useContext(InterceptorContext);
        const [globalError, setGlobalError] = useState<string>();

        const onFinish = (values: IAuthRequest) => {
            UserService.getLogin(
                isPhoneAndLogin.isPhone
                    ? {
                          login: _.replace(values.login, /\s/g, "")
                              .replace(/\+7/g, "")
                              .replace(/-/g, ""),
                          password: values.password
                      }
                    : values
            ).then(() => (isPhoneAndLogin.isPhone = false));
        };

        const setPhoneLoginHandler = (value: boolean) => {
            isPhoneAndLogin.isPhone = value;
            formInstance.resetFields();
        };

        const onValuesChange = () => {
            setGlobalError(undefined);
        };

        useEffect(() => {
            if (contextInteceptor?.data && _.isEqual(contextInteceptor?.status, 422)) {
                setGlobalError(contextInteceptor.data.message);
            }
        }, [contextInteceptor?.status, contextInteceptor?.data]);

        return (
            <div ref={ref}>
                <Typography as="div" className="bl-login-form">
                    <Row justify="space-between" className="bl-login-form-title">
                        <Col
                            className={
                                isPhoneAndLogin.isPhone ? "bl-login-form-title-active" : undefined
                            }
                            onClick={() => setPhoneLoginHandler(true)}
                        >
                            {t("По телефону")}
                        </Col>
                        <Col
                            className={
                                isPhoneAndLogin.isPhone ? undefined : "bl-login-form-title-active"
                            }
                            onClick={() => setPhoneLoginHandler(false)}
                        >
                            {t("По логину")}
                        </Col>
                    </Row>

                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="login"
                        onFinish={onFinish}
                        onValuesChange={onValuesChange}
                        autoComplete="off"
                        initialValues={{
                            login: "",
                            password: ""
                        }}
                    >
                        <Space
                            size={_.gte(context?.width ?? 0, 768) ? 16 : 24}
                            direction="vertical"
                        >
                            <Typography as="div">
                                <LoginField />
                                <PasswordField />
                                {!!globalError && (
                                    <Typography as="div" className="global-error">
                                        {globalError}
                                    </Typography>
                                )}
                            </Typography>
                            <Typography as="div">
                                <Form.Item shouldUpdate>
                                    {({getFieldsValue, getFieldsError}) => {
                                        const {login, password} = getFieldsValue();
                                        const errors = getFieldsErrorSize(getFieldsError);
                                        let formIsComplete =
                                            !!login && !!password && !errors && !globalError;

                                        if (isPhoneAndLogin.isPhone && _.lt(_.size(login), 16)) {
                                            formIsComplete = false;
                                        }

                                        return (
                                            <Button
                                                type={!formIsComplete ? "default" : "primary"}
                                                htmlType="submit"
                                                disabled={!formIsComplete}
                                            >
                                                {t("Войти")}
                                            </Button>
                                        );
                                    }}
                                </Form.Item>
                                <Typography
                                    as="div"
                                    size={14}
                                    className="bl-login-form-register"
                                    onClick={() => navigate("/register-store")}
                                >
                                    {t("Зарегистрироваться")}
                                </Typography>
                            </Typography>
                        </Space>
                    </Form>
                </Typography>
            </div>
        );
    })
);
