import React, {useContext, useRef} from "react";
import {Button, Form, Input, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import Clipboard from "react-clipboard.js";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import {InterceptorContext, LayoutContext, ModalContext} from "../../../core/Contexts";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
import useOnCancel from "../../../core/hooks/useOnCancel";
// components
import Error from "../../modal/error";
import Success from "../../modal/success";

export default React.memo(
    observer(function SimForm({phone, message}: IProps) {
        const context = useContext(InterceptorContext);
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const ref = useRef(null);
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        const onCancel = useOnCancel(formInstance);

        const onFinish = async ({phone}: {phone: string}) => {
            try {
                (ref.current as any).querySelector(".copy").click();
                contextModal?.setChildren(
                    <Success
                        title={
                            <Typography as="span">
                                {t("Номер скопирован ")}
                                <br />
                                {t("в буфер обмена")}
                            </Typography>
                        }
                    />
                );
            } catch (e: any) {
                contextModal?.setChildren(<Error />);
            } finally {
                contextModal?.setOnCancel(() => onCancel);
            }
        };

        return (
            <div ref={ref}>
                <Clipboard
                    className="copy"
                    component="span"
                    data-clipboard-text={message}
                ></Clipboard>
                <Typography as="div" className="bl-sim-form">
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="sim"
                        onFinish={onFinish}
                        autoComplete="off"
                        initialValues={{
                            phone
                        }}
                    >
                        <Form.Item noStyle name="phone" hidden={true}>
                            <Input />
                        </Form.Item>
                        <Space
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 0 : 8}
                            direction="vertical"
                        >
                            <Form.Item shouldUpdate>
                                {() => {
                                    return (
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={context?.loadingBtn}
                                        >
                                            {t("Скопировать")}
                                        </Button>
                                    );
                                }}
                            </Form.Item>
                            <Typography
                                as="div"
                                className="text-center cancel"
                                size={14}
                                color="#000"
                            >
                                <Button type="text" onClick={onCancel}>
                                    {t("Закрыть")}
                                </Button>
                            </Typography>
                        </Space>
                    </Form>
                </Typography>
            </div>
        );
    })
);

interface IProps {
    phone: number;
    message: string | undefined;
}
