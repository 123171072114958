import React, {useContext, useEffect, useState} from "react";
import {Typography, Space, Button, Skeleton} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import {ModalContext} from "../../../core/Contexts";
import {MobXProviderContext, observer} from "mobx-react";
import {useTranslation} from "react-i18next";

export default observer(function NumberFilterMode({isFavorite, setIsFavorite}: IProps) {
    const {t} = useTranslation();
    const contextModal = useContext(ModalContext);
    const {store} = useContext(MobXProviderContext);
    const [pageLoading, setPageLoading] = useState(false);

    const onFavorite = () => {
        setIsFavorite(true);
        setPageLoading(true);
        store.numberFilters.isCollection = false;
        localStorage.setItem("isFavorite", "favorite");
        contextModal?.setVisible(false);
    };

    const onPro = () => {
        setIsFavorite(false);
        setPageLoading(true);
        store.numberFilters.isCollection = false;
        localStorage.setItem("isFavorite", "pro");
        contextModal?.setVisible(false);
    };

    const onCollection = () => {
        setIsFavorite(false);
        store.numberFilters.isCollection = true;
        localStorage.setItem("isFavorite", "collection");
        setPageLoading(true);
        contextModal?.setVisible(false);
    };

    useEffect(() => {
        if (!store.pageLoading && pageLoading) {
            setPageLoading(false);
            contextModal?.setMaskClosable(true);
            contextModal?.setCssClass("");
        }
    }, [store.pageLoading]);

    return (
        <Typography
            as="div"
            className={`bl-number-filter-mode${store.pageLoading ? " modal-loading" : ""}`}
        >
            {store.pageLoading ? (
                <>
                    <Skeleton.Button active={true} className="close" />
                    <Space size={16} direction="vertical" align="center">
                        <Skeleton.Button active={true} />
                        <Skeleton.Button active={true} />
                    </Space>
                </>
            ) : (
                <Space size={16} direction="vertical" align="center">
                    <Button
                        type={
                            isFavorite && !store.numberFilters.isCollection ? "primary" : "default"
                        }
                        onClick={onFavorite}
                    >
                        {t("Любимая цифра")}
                    </Button>
                    <Button
                        type={store.numberFilters.isCollection ? "primary" : "default"}
                        onClick={onCollection}
                    >
                        {t("Подборка дня")}
                    </Button>
                    <Button
                        type={
                            !isFavorite && !store.numberFilters.isCollection ? "primary" : "default"
                        }
                        onClick={onPro}
                    >
                        {"Pro-фильтры"}
                    </Button>
                </Space>
            )}
        </Typography>
    );
});

interface IProps {
    isFavorite: boolean;
    setIsFavorite: React.Dispatch<React.SetStateAction<boolean>>;
}
