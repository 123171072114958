import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Button, Space, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import "./style.scss";
// core
import {IFilter} from "../../../../../../core/models/Phones";
import {ModalContext} from "../../../../../../core/Contexts";
// components
import NumbersFilterModal from "../../../../../modal/numbersFilter";
import PhonesFiltersService from "../../../../../../services/PhonesFiltersService";
import {IPhonesCurrentFilters} from "../../../../../../core/models/PhonesFilters";

export default React.memo(function Filter({title, items, selected, disabled, type, set}: IProps) {
    const contextModal = useContext(ModalContext);
    const {t} = useTranslation();

    const onClick = (value: string) => {
        set([...selected, value]);

        let updated = _.clone(
            PhonesFiltersService.currentFilter[type as keyof IPhonesCurrentFilters]
        ) as string[];
        updated = _.isEqual(type, "type") ? [value] : [...updated, value];
        PhonesFiltersService.setCurrentFilter({
            [type as keyof IPhonesCurrentFilters]: updated
        });
    };

    const onCancel = () => {
        contextModal?.setVisible(false);
        contextModal?.setCssClass("");
    };

    const onModal = () => {
        contextModal?.setCssClass(" bl-filter-modal");
        contextModal?.setVisible(true);
        contextModal?.setOnCancel(() => onCancel);
        contextModal?.setChildren(
            <NumbersFilterModal
                title={title}
                items={items}
                selected={selected}
                set={set}
                type={type}
            />
        );
    };

    return (
        <Typography as="div" className="bl-numbers-filters-filter">
            <Space direction="vertical" size={8}>
                <Typography level={2}>{t(title)}</Typography>
                {_.gt(_.size(items) - _.size(selected), 0) && (
                    <Space size={8} className="items">
                        {_.chain(items)
                            .filter(({filter}) => !_.includes(selected, filter))
                            .filter((i, k) => _.lt(k, 7))
                            .map(({name, filter}) => (
                                <Button
                                    key={filter}
                                    type="default"
                                    onClick={() => onClick(filter)}
                                    disabled={disabled}
                                >
                                    {t(name)}
                                </Button>
                            ))
                            .valueOf()}
                        {_.gt(_.size(items) - _.size(selected), 7) && (
                            <Button type="default" onClick={onModal} disabled={disabled}>
                                {t("Еще +")}
                            </Button>
                        )}
                    </Space>
                )}
            </Space>
        </Typography>
    );
});

interface IProps {
    title: string;
    items: IFilter[];
    selected: string[];
    disabled?: boolean;
    type: string;
    set: React.Dispatch<React.SetStateAction<string[]>>;
}
