import {CSSProperties, useContext, useRef, useState} from "react";
import {observer} from "mobx-react";
import {Modal} from "@bezlimit/bezlimit-ui";
// core
import {InterceptorContext, LayoutContext, LoadingContext, ModalContext, UserContext} from "./core/Contexts";
// components
import Layout from "./layout";
import Loading from "./components/blocks/ui/loading";
// hooks
import useScrollToTop from "./core/hooks/useScrollToTop";
import useResize from "./core/hooks/useResize";

export default observer(function App() {
    useScrollToTop();
    const ref = useRef(null);
    const context = useContext(InterceptorContext);
    const {width} = useResize();
    const [email, setEmail] = useState<string | undefined>();
    const [phone, setPhone] = useState<string | undefined>();
    const [cssClass, setCssClass] = useState("");
    const [loading, setLoading] = useState(false);
    const [visible, setVisible] = useState(false);
    const [maskClosable, setMaskClosable] = useState(false);
    const [maskStyle, setMaskStyle] = useState<CSSProperties>();
    const [id, setId] = useState(0);
    const [chilren, setChildren] = useState<JSX.Element>();
    const [onCancel, setOnCancel] = useState<() => void>(() => () => {
        setVisible(false);
        setChildren(<></>);
        setMaskStyle(undefined);
        setCssClass("");
        context?.setErrors(undefined);
    });

    return (
        <div ref={ref}>
            <LoadingContext.Provider value={{setLoading}}>
                {loading && <Loading />}
                <LayoutContext.Provider value={{width}}>
                    <UserContext.Provider value={{email, phone, setPhone, setEmail}}>
                        <ModalContext.Provider
                            value={{
                                visible,
                                id,
                                setVisible,
                                setChildren,
                                setOnCancel,
                                setId,
                                setCssClass,
                                setMaskClosable,
                                setMaskStyle
                            }}
                        >
                            <Layout />
                            {visible && (
                                <Modal
                                    maskStyle={maskStyle}
                                    maskClosable={maskClosable}
                                    centered
                                    visible={visible}
                                    onCancel={onCancel}
                                    width={558}
                                    className={`bl-modal${cssClass}`}
                                >
                                    {chilren}
                                </Modal>
                            )}
                        </ModalContext.Provider>
                    </UserContext.Provider>
                </LayoutContext.Provider>
            </LoadingContext.Provider>
        </div>
    );
});
