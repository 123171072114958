import {Button, Col, Typography} from "@bezlimit/bezlimit-ui";
import {RangeValue} from "rc-picker/lib/interface";
import _ from "lodash";
import moment from "moment";
import React, {useCallback, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
//core
import {
    IBonusesRequest,
    IBonusesSummaryRequest,
    IItems,
    IMeta
} from "../../../../../core/models/Bonus";
//components
import ItemPaymants from "./itemPaymants";
import TitleColapsePanelPayments from "../../titleColapsePanelPayments";
import TitleColapsePanelPayouts from "../../titleColapsePanelPayouts";
import ItemPayouts from "./itemPayouts";

export default function Items({period, request, activeKey, fn}: IProps) {
    const {t} = useTranslation();
    const [loading, setLoading] = useState<boolean>(false);
    const [data, setData] = useState<IItems[]>([]);
    const [meta, setMeta] = useState<IMeta>();

    const onClick = () => {
        callback(data, meta, true);
    };

    const callback = useCallback(
        async (data: IItems[], meta: IMeta | undefined, isLoadMore: boolean = false) => {
            if (isLoadMore) {
                setLoading(true);
            }

            const {_meta, items} = await fn(
                {
                    page: meta ? meta.currentPage + 1 : 1,
                    ...request
                },
                !isLoadMore
            );

            if (!isLoadMore) {
                setData(items);
            } else {
                setData([...data, ...items]);
                setLoading(false);
            }
            setMeta(_meta);
        },
        []
    );

    useEffect(() => {
        callback(data, meta);
        return () => {
            setData([]);
            setMeta(undefined);
        };
    }, []);

    return (
        <>
            {_.isEqual(activeKey, "payments") ? (
                <TitleColapsePanelPayments />
            ) : (
                <TitleColapsePanelPayouts />
            )}

            {_.chain(data)
                .map((i, k) => (
                    <React.Fragment key={k}>
                        <Col span={24}>
                            {_.isEqual(activeKey, "payments") ? (
                                <ItemPaymants item={i} />
                            ) : (
                                <ItemPayouts item={i} />
                            )}
                        </Col>
                    </React.Fragment>
                ))
                .valueOf()}
            {meta && _.lt(_.size(data), meta.totalCount) && (
                <Typography as="div" className="btn-show">
                    <Button onClick={onClick} loading={loading} className="btn-show-list">
                        {t("Показать еще")}
                    </Button>
                </Typography>
            )}
        </>
    );
}

interface IProps {
    period: RangeValue<moment.Moment>;
    request: IBonusesSummaryRequest;
    activeKey: string;
    fn: (request?: IBonusesRequest | undefined, loading?: boolean) => Promise<any>;
}
