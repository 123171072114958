import {Col, Row, Space, Typography, Back} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useCallback, useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate, useParams} from "react-router-dom";
import "./style.scss";
// services
import NotificationsService from "../../../../../../services/NewsService";
// core
import {IItems} from "../../../../../../core/models/News";
import date from "../../../../../../core/functions/date";
import {ModalContext} from "../../../../../../core/Contexts";

export default observer(function NewsDescription() {
    const {t} = useTranslation();
    const {id} = useParams();
    const navigate = useNavigate();
    const [state, setState] = useState<IItems | undefined>();
    const contextModal = useContext(ModalContext);
    const dateFormat: Intl.DateTimeFormatOptions = {
        month: "long",
        day: "numeric"
    };

    const getNews = useCallback(async (id: string | undefined) => {
        const res: IItems = await NotificationsService.getNewsById(id!);
        NotificationsService.postRead(id!);
        NotificationsService.getNewsCount();
        setState(res);
    }, []);

    useEffect(() => {
        getNews(id);
    }, [id]);

    useEffect(() => {
        if (!state?.title) {
            return;
        }
        document.title = `${state.title} | Store`;
    }, [state?.id]);

    let imgs = document.querySelectorAll("img");

    useEffect(() => {
        let imgsAll = document.querySelectorAll("img");
        imgsAll.forEach.call(imgsAll, function (el) {
            el.onclick = function (e) {
                contextModal?.setVisible(true);
                contextModal?.setCssClass("photo-news-wrapper");
                contextModal?.setChildren(<img src={el.src} alt="news" />);
                const modalMask: HTMLDivElement = document.querySelector(".ant-modal-mask")!;
                modalMask!.style.background = "#1e1817e8";
            };
        });
    }, [imgs]);

    useEffect(() => {
        return () => contextModal?.setVisible(false);
    }, []);

    return (
        <Typography as="div" className="bl-news-discription">
            <Back navigate={() => navigate("/news")}>{""}</Back>
            {state && (
                <Row>
                    <Col span={24}>
                        <Row>
                            <Space direction="vertical" size={22}>
                                <Col>
                                    <Typography
                                        className="block-title"
                                        size={48}
                                        fontFamily="alumna"
                                    >
                                        {t(state.title)}
                                    </Typography>
                                </Col>
                                <Col>
                                    <Typography className="preview-text">
                                        {t(state.preview_text)}
                                    </Typography>
                                </Col>
                                <Row justify="start">
                                    <Typography
                                        className="published-date"
                                        color="rgba(255, 255, 255, 0.5)"
                                        size={14}
                                        appearance="medium"
                                    >
                                        {t(date(state.published_date, dateFormat)) +
                                            " " +
                                            state.published_date.split("-")[0] +
                                            " года"}
                                    </Typography>
                                </Row>
                                <Col>
                                    <Typography
                                        className="text-news"
                                        dangerouslySetInnerHTML={{__html: t(state.text)}}
                                    ></Typography>
                                </Col>
                            </Space>
                        </Row>
                    </Col>
                </Row>
            )}
        </Typography>
    );
});
