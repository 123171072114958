import React, {useEffect, useRef, useState} from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import moment from "moment";
import _ from "lodash";
// components
import Time from "./time";
import {LoadingIcon} from "../../ui/loadingIcon";

export default React.memo(function LotteryTimer({started_at, suffix, startedStr}: IProps) {
    const {t} = useTranslation();
    const timeout: any = useRef();
    const [state, setState] = useState(<LoadingIcon />);

    const MSK_UTC_OFFSET = 180;

    useEffect(() => {
        if (!_.isNil(started_at)) {
            clearTimeout(timeout.current);
            const interval = 1000;
            const eventTime = moment(started_at); //должно быть Мск
            const currentTime = moment(); // локальное
            const localUtcOffset = currentTime.utcOffset();
            const utcOffsetDiff = localUtcOffset - MSK_UTC_OFFSET;

            const eventTimeUnix = eventTime.add(utcOffsetDiff, "minutes").unix(); // переводим в локальное
            const currentTimeUnix = currentTime.unix();

            const diffTime = eventTimeUnix - currentTimeUnix;
            let duration: any = moment.duration(diffTime * 1000, "milliseconds");

            timeout.current = setTimeout(function () {
                duration = moment.duration(duration - interval, "milliseconds");
                if (_.gt(duration.asMilliseconds(), 0)) {
                    setState(<Time duration={duration} suffix={suffix} />);
                } else {
                    clearTimeout(timeout.current);
                    setState(
                        <Typography
                            as="div"
                            color="#fff"
                            size={15}
                            className="process-text gold"
                            fontFamily="alumna"
                        >
                            {t("Розыгрыш окончен")}
                        </Typography>
                    );
                }
            }, interval);
        }

        return () => {
            clearTimeout(timeout.current);
        };
    }, [state]);

    return (
        <>
            <Typography as="div" className="timer-container">
                {state}
                {!!startedStr && (
                    <Typography className="lottery-start">
                        {t(`Начало ${startedStr} по Московскому времени`)}
                    </Typography>
                )}
            </Typography>
        </>
    );
});

interface IProps {
    started_at?: string;
    suffix?: boolean;
    startedStr?: string;
}
