import React, {useContext} from "react";
import {Typography, Space, Image} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import "./style.scss";
// images
import lionCircle from "../../../../../images/lion_circle.png";
// core
import {LayoutContext} from "../../../../../core/Contexts";
// services
import UserService from "../../../../../services/UserService";

export default React.memo(
    observer(function Info({title}: IProps) {
        const contextLayout = useContext(LayoutContext);
        const {t} = useTranslation();

        return (
            <Typography as="div" className="bl-personal-manager-info">
                <Space size={0}>
                    <Image src={lionCircle} preview={false} />
                    <Space
                        size={_.gte(contextLayout?.width, 768) ?? 0 ? 8 : 8}
                        direction="vertical"
                    >
                        <Typography as="div" size={24} color="#fff" className="title">
                            {t(
                                UserService.hasPersonalManager
                                    ? UserService.personalManager.name
                                    : "Поддержка"
                            )}
                        </Typography>

                        <Typography
                            as="div"
                            size={15}
                            color="rgba(255, 255, 255, 0.5)"
                            className="text-helper"
                        >
                            {t(
                                UserService.hasPersonalManager
                                    ? title
                                    : "Ответим в кратчайшие сроки"
                            )}
                        </Typography>
                        <Typography
                            as="div"
                            size={_.gte(contextLayout?.width, 768) ?? 0 ? 20 : 16}
                            color="#fff"
                            className="phone"
                        >
                            {t(
                                UserService.hasPersonalManager
                                    ? `+7${UserService.personalManager.phone}`
                                    : "24/7"
                            )}
                        </Typography>
                    </Space>
                </Space>
            </Typography>
        );
    })
);

interface IProps {
    title?: any;
}
