import React, {useRef} from "react";
import {Col, Form, Row} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import "./style.scss";
// core
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
// components
import RangePickerField from "../../fields/rangePicker";
import {Moment} from "moment";
import SearchField from "../../fields/search";
import _ from "lodash";

export default React.memo(
    observer(function AccrualsFilterForm({setFilterState}: IProps) {
        const ref = useRef(null);
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);

        const onFinish = async (values: IFilterRequest) => {
            setFilterState(values);
        };

        const onSearch = () => {
            formInstance.submit();
        };

        const onPressEnter = () => {
            formInstance.submit();
        };

        const onValuesChange = (changedValues: any) => {
            if (!_.get(changedValues, "search")) {
                formInstance.submit();
            }
        };

        return (
            <div ref={ref} className="bl-accruals-filter-form">
                <Form
                    form={formInstance}
                    layout="horizontal"
                    name="accruals-filter"
                    onFinish={onFinish}
                    onValuesChange={onValuesChange}
                    autoComplete="off"
                >
                    <Row gutter={[16, 8]}>
                        <Col xs={24} lg={12}>
                            <RangePickerField
                                props={{
                                    name: "period",
                                    label: t("Период начислений")
                                }}
                            />
                        </Col>
                        <Col xs={24} lg={12}>
                            <SearchField
                                allowClear
                                title="ID в биллинге, ФИО"
                                onSearch={onSearch}
                                onPressEnter={onPressEnter}
                            />
                        </Col>
                    </Row>
                </Form>
            </div>
        );
    })
);

interface IProps {
    setFilterState: React.Dispatch<React.SetStateAction<IFilterRequest | undefined>>;
}

export type IFilterRequest = {
    search: string;
    period: [Moment, Moment];
};
