import {observer} from "mobx-react";
import Bottom from "./bottom";
import Top from "./top";

const SuperLinkWidget: React.FC = observer(() => {
    return (
        <>
            <Top />
            <Bottom />
        </>
    );
});
export default SuperLinkWidget;
