import {useContext, useEffect, useState} from "react";
import {Button, Col, Dropdown, Row, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {LayoutContext, ModalContext} from "../../../core/Contexts";
import {MenuProps} from "antd";
import ModalAwards from "./modalAwards";

export const LotteryAwards = ({placeholder, awards}: IProps): JSX.Element => {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);
    const contextModal = useContext(ModalContext);
    const isMobile = _.lt(contextLayout?.width ?? 0, 993);
    const [items, setItems] = useState<MenuProps["items"]>();

    const onClick = () => {
        contextModal?.setMaskStyle({
            background: "rgba(255, 255, 255, 0.05)",
            // @ts-ignore
            "-webkit-backdrop-filter": " blur(50px)",
            "backdrop-filter": " blur(50px)"
        });
        contextModal?.setCssClass(" bl-modal-lottery");
        contextModal?.setChildren(
            <ModalAwards placeholder={placeholder} text={items ? (items as any)[0].label : ""} />
        );
        contextModal?.setVisible(true);
    };

    useEffect(() => {
        const colCount = _.gte(contextLayout?.width ?? 0, 768) ? 3 : 2;

        const grouppedAwards = _.chain(awards)
            .groupBy("name")
            .map((i, k) => {
                const arr = i.map((item) => item.position);
                const arrRange =
                    arr.length > 1 ? `${arr[0]} - ${arr[arr.length - 1]}` : `${arr?.[0] ?? ""}`;
                return {
                    positions: arrRange,
                    name: k
                };
            })
            .valueOf();

        const len = grouppedAwards.length;
        const size = len % colCount === 0 ? len / colCount : Math.floor(len / colCount) + 1;
        let awardsThreeCol: any = [];
        for (let i = 0; i < Math.ceil(grouppedAwards.length / size); i++) {
            awardsThreeCol[i] = grouppedAwards.slice(i * size, i * size + size);
        }

        setItems([
            {
                key: 0,
                label: (
                    <Row gutter={[48, 16]}>
                        {(awardsThreeCol || []).map((item: any, ind: any) => (
                            <Col key={ind} span={24 / colCount}>
                                <Row gutter={[48, 24]}>
                                    {(item || []).map((i: any) => (
                                        <Col key={i.name} span={24}>
                                            <Typography className="award-position">
                                                {t(`${i.positions} место`)}
                                            </Typography>
                                            <Typography className="award-name ">
                                                {t(`${i.name}`)}
                                            </Typography>
                                        </Col>
                                    ))}
                                </Row>
                            </Col>
                        ))}
                    </Row>
                )
            }
        ]);
    }, [awards]);

    return (
        <Typography as="div" id="awards" className="custom-overlay-container ">
            <Dropdown
                menu={{items}}
                trigger={["click"]}
                overlayClassName="custom-overlay over2"
                getPopupContainer={() => document.getElementById("awards")!}
                open={isMobile ? false : undefined}
                onOpenChange={isMobile ? onClick : undefined}
            >
                <Button>
                    {placeholder}
                    {isMobile ? (
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16"
                            height="17"
                            viewBox="0 0 16 17"
                            fill="none"
                        >
                            <path
                                d="M13 6L8 11L3 6"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    ) : (
                        <svg
                            width="20"
                            height="21"
                            viewBox="0 0 20 21"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <path
                                d="M1 6.5L10 14.5L19 6.5"
                                stroke="white"
                                stroke-width="1.5"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                            />
                        </svg>
                    )}
                </Button>
            </Dropdown>
        </Typography>
        // <Collapse
        //     ghost
        //     className="lottery-collapse"
        //     expandIconPosition="end"
        //     expandIcon={({ isActive }) => <DownOutlined rotate={isActive ? 180 : 0} />}
        // >
        //     <Panel header={t("Список призов")} key="1">
        // <Row gutter={8}>
        //     {(awardsThreeCol || []).map((item, ind) => (
        //         <Col key={ind} span={24/colCount}>
        //             <Row gutter={[8, 24]}>
        //                 {(item || []).map(i => (
        //                     <Col key={i.name} span={24}>
        //                         <Typography className="award-position">{
        //                             t(`${i.positions} место`)}
        //                         </Typography>
        //                         <Typography className="award-name gold">
        //                             {t(`${i.name}`)}
        //                         </Typography>
        //                     </Col>
        //                 ))}
        //             </Row>
        //         </Col>
        //     ))}
        // </Row>
        //     </Panel>
        // </Collapse>
    );
};

interface IAward {
    position: number;
    name: string;
}

interface IProps {
    placeholder: string;
    awards: IAward[];
}
