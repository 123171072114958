import {Row, Col} from "@bezlimit/bezlimit-ui";
// components
import RegisterStorePhone from "../../../blocks/registerStorePhone";

export default function RegisterStorePhonePage() {
    return (
        <Row justify="center" gutter={32}>
            <Col lg={{span: 24}} xs={{span: 24}}>
                <RegisterStorePhone />
            </Col>
        </Row>
    );
}
