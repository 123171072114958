import React, {useContext, useRef} from "react";
import {Button, Form, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import cn from "classnames";
// core
import {IUsersRequest} from "../../../core/models/User";
import {
    InterceptorContext,
    LayoutContext,
    ModalContext,
    SessionContext
} from "../../../core/Contexts";
import {getFieldsErrorSize} from "../../../core/functions/form";
// services
import UserService from "../../../services/UserService";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
import useFieldFocus from "../../../core/hooks/useFieldFocus";
import useOnChange from "../../../core/hooks/useOnChange";
// components
import EmailField from "../../fields/email";
import Error from "../../modal/error";
import EmailCodeConfirmation from "../../modal/emailCodeConfirmation";
import Success from "../../modal/success";

export default React.memo(
    observer(function EditEmailForm(props: Props) {
        const currEmail = props.email;
        const context = useContext(InterceptorContext);
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const contextSession = useContext(SessionContext);
        const ref = useRef(null);
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        const onChange = useOnChange(formInstance);
        useFieldFocus(ref, "email");

        const onSubmit = async ({code, email}: any) => {
            await UserService.putUsers({email_code: code, email});
            contextModal?.setChildren(
                <Success
                    title={
                        <Typography as="span">
                            {t("E-mail успешно ")}
                            <br />
                            {currEmail ? t("изменен") : t("привязан")}
                        </Typography>
                    }
                />
            );
            await UserService.getUsers();
        };

        const onFinish = async (values: IUsersRequest) => {
            const email = values.email;
            try {
                await UserService.putUsers({email});
                contextModal?.setVisible(true);
                contextModal?.setChildren(
                    <EmailCodeConfirmation email={email} onSubmit={onSubmit} />
                );
                formInstance.resetFields();
                await UserService.getUsers();
            } catch (e: any) {
                contextModal?.setVisible(true);
                contextModal?.setChildren(<Error />);
            }
        };

        return (
            <div ref={ref}>
                <Typography as="div">
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="edit-email"
                        onFinish={onFinish}
                        onFieldsChange={onChange}
                        autoComplete="off"
                        initialValues={{
                            email: currEmail ?? ""
                        }}
                    >
                        <Space
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 8 : 24}
                            direction="vertical"
                        >
                            <EmailField className={cn({"confirm-field": !currEmail})} />
                            <Form.Item noStyle shouldUpdate>
                                {({getFieldsValue, getFieldsError}) => {
                                    const {email} = getFieldsValue();
                                    const errors = getFieldsErrorSize(getFieldsError);
                                    const formIsComplete =
                                        !!email && email !== currEmail && !errors;

                                    return (
                                        <Button
                                            style={currEmail ? {} : {width: "100%"}}
                                            type={!formIsComplete ? "default" : "primary"}
                                            htmlType="submit"
                                            disabled={!formIsComplete}
                                            loading={
                                                !contextSession?.toggleCollapsed
                                                    ? context?.loadingBtn
                                                    : false
                                            }
                                        >
                                            {currEmail ? t("Сменить почту") : t("Привязать почту")}
                                        </Button>
                                    );
                                }}
                            </Form.Item>
                        </Space>
                    </Form>
                </Typography>
            </div>
        );
    })
);

interface Props {
    email?: string;
}
