import React, {useContext, useEffect} from "react";
import {Button, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {IHintsItems} from "../../../../../../core/models/Translate";
import "./style.scss";
import {ModalContext} from "../../../../../../core/Contexts";
import {Scrollbar} from "react-scrollbars-custom";

export default React.memo(function HintModal({list}: IProps) {
    const {t} = useTranslation();
    const contextModal = useContext(ModalContext);

    const onCancel = () => {
        contextModal?.setVisible(false);
        contextModal?.setCssClass("");
    };

    useEffect(() => {
        document
            .querySelector(".ant-modal-root")
            ?.classList.add(`ant-modal-root__hint-scrollbar-color`);
        if (document.querySelector(".ant-modal-root iframe")) {
            //@ts-ignore
            var preSrc = document.querySelector(".ant-modal-root iframe").src; //@ts-ignore
            document.querySelector(".ant-modal-root iframe").src = preSrc + "?rel=0";
        }
    }, []);

    return (
        <div className="bl-support-faq bl-hint-modal">
            <Scrollbar
                style={{height: "80vh"}}
                onScroll={(value: any) => {
                    contextModal?.setCssClass(
                        ` bl-hint-modal-mobile${Boolean(value.scrollTop) ? " isScrolled" : ""}`
                    );
                }}
            >
                <Typography className="faq-title">{t(list.title)}</Typography>
                <div
                    className="faq-description"
                    dangerouslySetInnerHTML={{__html: t(list.description)}}
                ></div>
                <Typography as="div" className="text-center cancel" size={14} color="#000">
                    <Button type="primary" onClick={onCancel}>
                        {t("Закрыть")}
                    </Button>
                </Typography>
            </Scrollbar>
        </div>
    );
});

interface IProps {
    list: IHintsItems;
}
