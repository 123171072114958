import {makeAutoObservable} from "mobx";
import {IBonusesPaymentsSummary, IBonusesSummary, IItems, IMeta} from "../core/models/Bonus";
// core
// core

class Bonus {
    [Symbol.toStringTag] = this.constructor.name;
    private _items!: IItems[] | undefined;
    private _meta!: IMeta | undefined;
    private _type: string;
    private _name?: string;
    private _summary: IBonusesSummary | undefined;
    private _totalsummary: IBonusesPaymentsSummary | undefined;

    constructor(type: string, name?: string) {
        this._type = type;

        if (name) {
            this._name = name;
        }
        makeAutoObservable(this);
    }

    get totalsummary() {
        return this._totalsummary;
    }

    set totalsummary(value: IBonusesPaymentsSummary | undefined) {
        this._totalsummary = value;
    }

    get summary() {
        return this._summary;
    }

    set summary(value: IBonusesSummary | undefined) {
        this._summary = value;
    }

    get items() {
        return this._items;
    }

    set items(value: IItems[] | undefined) {
        this._items = value;
    }

    get meta() {
        return this._meta;
    }

    set meta(value: IMeta | undefined) {
        this._meta = value;
    }

    get type() {
        return this._type;
    }

    get name() {
        return this._name;
    }
}

export default Bonus;
