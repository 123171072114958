import React, {useContext, useEffect, useState} from "react";
import {Button, Space, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import moment from "moment";
import _ from "lodash";
import "./style.scss";
// components
import EmptyResult from "../emptyResult";
import ActivationFilterForm from "../../../forms/activationFilter";
import Items from "./items";
// core
import {InterceptorContext, LayoutContext} from "../../../../core/Contexts";
import {
    IMeta,
    IReservations,
    IReservationsBookRequest,
    IReservationsRequest
} from "../../../../core/models/Reservations";
import ActivationShimmer from "../../../private/pages/book/shimmerActivation";

export default React.memo(
    observer(function ActivationList({
        items,
        isFilter,
        isInitEmpty,
        meta,
        filterRequest,
        updateList,
        setMeta,
        getReservationsByIsActivated,
        setFilterRequest,
        setItems,
        setShimmer,
        shimmer
    }: IProps) {
        const contextLayout = useContext(LayoutContext);
        const context = useContext(InterceptorContext);
        const {t} = useTranslation();
        const [data, setData] = useState([]);

        const onClick = () => {
            getReservationsByIsActivated(
                {
                    key: "activation",
                    ...(filterRequest ? filterRequest : {}),
                    page: meta!.currentPage + 1
                },
                false
            );
        };

        useEffect(() => {
            let res: any = _.chain(items).orderBy("phone.activated_at", "desc").valueOf();
            res = _.chain(res)
                .groupBy(({phone: {activated_at}}) => moment(activated_at).unix())
                .reduce<any>((res, i, k) => {
                    res[k] = {unix: k, values: i};
                    return res;
                }, {})
                .valueOf();

            setData(res);
        }, [items]);

        return (
            <>
                {isInitEmpty && !_.size(data) ? (
                    <EmptyResult title="У вас пока нет активированных номеров" />
                ) : (
                    <Typography as="div" className="bl-activation-list">
                        <Space
                            direction="vertical"
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 32 : 24}
                        >
                            <ActivationFilterForm
                                updateList={updateList}
                                setMeta={setMeta}
                                setFilterRequest={setFilterRequest}
                                setItems={setItems}
                                setShimmer={setShimmer}
                            />
                            {shimmer ? (
                                <ActivationShimmer />
                            ) : _.size(data) ? (
                                <Space
                                    direction="vertical"
                                    size={_.gte(contextLayout?.width ?? 0, 768) ? 32 : 24}
                                >
                                    {_.chain(data)
                                        .orderBy("unix", "desc")
                                        .map(({unix, values}, k) => {
                                            const today = moment().unix();
                                            return (
                                                <Space
                                                    key={k}
                                                    direction="vertical"
                                                    size={
                                                        _.gte(contextLayout?.width ?? 0, 768)
                                                            ? 16
                                                            : 8
                                                    }
                                                >
                                                    <Typography
                                                        as="div"
                                                        color="#fff"
                                                        size={18}
                                                        className="grouped-date"
                                                    >
                                                        {_.isEqual(today, k)
                                                            ? t("Сегодня")
                                                            : _.isEqual(
                                                                  moment.unix(unix).format("YYYY"),
                                                                  moment().format("YYYY")
                                                              )
                                                            ? moment.unix(unix).format("DD MMMM")
                                                            : `${moment
                                                                  .unix(unix)
                                                                  .format("DD MMMM YYYY")} ${t(
                                                                  "года"
                                                              )}`}
                                                    </Typography>
                                                    <Items items={values} />
                                                </Space>
                                            );
                                        })
                                        .valueOf()}
                                </Space>
                            ) : shimmer ? null : (
                                <Typography
                                    as="div"
                                    color="rgba(255,255,255,.5)"
                                    fontFamily="alumna"
                                    size={32}
                                    className="empty"
                                >
                                    {t("Не найдено")}
                                </Typography>
                            )}

                            {shimmer ? null : !meta ||
                              (meta && _.lt(_.size(items), meta.totalCount)) ? (
                                <Typography as="div" className="loading-more">
                                    <Button
                                        type="default"
                                        onClick={onClick}
                                        loading={context?.loadingBtn}
                                    >
                                        {t("Показать еще")}
                                    </Button>
                                </Typography>
                            ) : null}
                        </Space>
                    </Typography>
                )}
            </>
        );
    })
);

interface IProps {
    items: IReservations[];
    isFilter: boolean;
    isInitEmpty: boolean;
    meta: IMeta | undefined;
    filterRequest: IReservationsRequest | undefined;
    updateList: (request?: IReservationsRequest, loading?: boolean, reset?: boolean) => void;
    setMeta: React.Dispatch<React.SetStateAction<IMeta | undefined>>;
    getReservationsByIsActivated: (
        {key, ...request}: IReservationsBookRequest,
        loading?: boolean
    ) => Promise<void>;
    setFilterRequest: React.Dispatch<React.SetStateAction<IReservationsRequest | undefined>>;
    setItems: React.Dispatch<React.SetStateAction<IReservations[]>>;
    setShimmer: React.Dispatch<React.SetStateAction<boolean>>;
    shimmer: boolean;
}
