import User from "../../stores/User";
// services
import ControlsService from "../../services/ControlsService";
import md5 from "md5";

export default function WidgetChartra(
    d: Document,
    w: any,
    c: string,
    user: User | null,
    isAuthorization: boolean = false
): void {
    if (!isAuthorization) {
        if (!user || !user.id) {
            return;
        }
    }

    const staticText = {
        ru: `Ожидайте, пожалуйста. Специалист уже присоединяется к чату 😃`,
        en: ``
    };

    if (!user || !user.id) {
        w.ChatraSetup = {
            disableChatOpenHash: true,
            chatHeight: 700,
            chatWidth: 370,
            colors: {
                clientBubbleBg: "#642878",
                agentBubbleBg: "#E5E5E5"
            },
            startHidden: true,
            locale: {
                customStrings: {
                    welcome: {
                        en: "",
                        ru: "Задайте свой вопрос! Мы уже спешим на помощь:)"
                    },
                    bot_thanksForMessage: "Здравствуйте!",
                    // bot_startOnline: staticText,
                    // bot_startOnline_required: staticText,
                    // bot_startOnline_email: staticText,
                    // bot_startOffline: staticText,
                    // bot_startOffline_required: staticText,
                    bot_beforeForm: {
                        en: " ",
                        ru: " "
                    },
                    bot_startOnline: staticText,
                    bot_startOnline_required: staticText,
                    bot_startOffline: staticText,
                    bot_startOffline_required: staticText
                }
            }
        };
    } else {
        w.ChatraSetup = {
            clientId: md5(user.personal_data.user_id.toString()),
            disableChatOpenHash: true,
            chatHeight: 700,
            chatWidth: 370,
            colors: {
                clientBubbleBg: "#642878",
                agentBubbleBg: "#E5E5E5"
            },
            startHidden: true,
            locale: {
                customStrings: {
                    welcome: {
                        en: "",
                        ru: "Задайте свой вопрос! Мы уже спешим на помощь 😃"
                    },
                    bot_thanksForMessage: "Здравствуйте!",
                    // bot_startOnline: staticText,
                    // bot_startOnline_required: staticText,
                    bot_startOnline_email: staticText,
                    // bot_startOffline: staticText,
                    // bot_startOffline_required: staticText,
                    bot_beforeForm: {
                        en: " ",
                        ru: " "
                    },
                    bot_startOnline: staticText,
                    bot_startOnline_required: staticText,
                    bot_startOffline: staticText,
                    bot_startOffline_required: staticText
                }
            }
        };
    }

    const name = `${user?.personal_data?.first_name ?? ""} ${
        user?.personal_data?.second_name ?? ""
    }`;
    const fio = `${name} ${user?.personal_data?.last_name ?? ""}`;
    w.ChatraIntegration = {
        /* main properties */
        name: name,
        email: user?.profile?.email ?? "",
        phone: user?.personal_data?.phone ?? "",

        /* any number of custom properties */
        "ФИО дилера": fio,
        "ФИО куратора": user?.personal_manager?.name ?? "",
        "Телефон куратора": user?.personal_manager?.phone ?? "",
        "Путь дилера в Биллинге": user?.dealer?.path ?? "",
        "Ссылка на сделку в битрикс": user?.dealer?.id
            ? `https://bbezlimit.ru/dealer/${user?.dealer?.id ?? ""}`
            : "",
        "Уровень Стор": user?.level?.level ?? ""
    };

    w.addEventListener("message", function (event: {data: string}) {
        try {
            let message = JSON.parse(event.data);
            if (message.sender === "Chatra" && message.type === "collapseWindow") {
                w.Chatra("hide");
                ControlsService.setChatOpened(false);
            }
        } catch (e) {}
    });

    w.ChatraID = "KiTo6mPMbGHBjAPgW";
    var s = d.createElement("script");
    w[c] =
        w[c] ||
        function () {
            (w[c].q = w[c].q || []).push(arguments);
        };
    s.async = true;
    s.src = "https://call.chatra.io/chatra.js";
    if (d.head) d.head.appendChild(s);
}
