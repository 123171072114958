import {Block, Space, Typography, Icon} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {useContext} from "react";
import {useTranslation} from "react-i18next";
import {LayoutContext} from "../../../../core/Contexts";
import "./style.scss";

export default function DownloadApp() {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);

    return (
        <Typography as="div" className={"bl-support support-block"}>
            <Space size={10} direction="horizontal" className="mobile">
                <Space direction="vertical">
                    <Typography className="support" size={16}>
                        {t("Скачайте приложение для дилеров:")}
                    </Typography>
                    <Typography fontFamily="alumna" size={19}>
                        {t("«Store! - Мир красивых номеров»")}
                    </Typography>
                </Space>
                <Space
                    direction={_.gt(contextLayout?.width ?? 0, 280) ? "horizontal" : "vertical"}
                    size={16}
                >
                    <Block>
                        <Typography as="div" className="support-contacts">
                            <a
                                href={
                                    "https://apps.apple.com/ru/app/store-%D0%BC%D0%B8%D1%80-%D0%BA%D1%80%D0%B0%D1%81%D0%B8%D0%B2%D1%8B%D1%85-%D0%BD%D0%BE%D0%BC%D0%B5%D1%80%D0%BE%D0%B2/id1495144217"
                                }
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <Icon style={{fill: "none"}} name={"apple"} size={"26"} />
                                <Space direction="vertical" size={0}>
                                    <Typography className="support-download">
                                        {t("Загрузите в")}
                                    </Typography>
                                    <Typography className="app-store">App Store</Typography>
                                </Space>
                            </a>
                        </Typography>
                    </Block>
                    <Block>
                        <Typography as="div" className="support-contacts">
                            <a
                                href={
                                    "https://play.google.com/store/apps/details?id=com.bezlimit.store"
                                }
                                target={"_blank"}
                                rel="noreferrer"
                            >
                                <Icon style={{fill: "none"}} name={"vector"} size={"26"} />
                                <Space direction="vertical" size={0}>
                                    <Typography className="support-download">
                                        {t("ДОСТУПНО В")}
                                    </Typography>
                                    <Typography className="google-play">Google Play</Typography>
                                </Space>
                            </a>
                        </Typography>
                    </Block>
                </Space>
            </Space>
        </Typography>
    );
}
