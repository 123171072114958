import _ from "lodash";
// core
import {IPhonesFiltersRequest} from "../core/models/PhonesFilters";
// services
import PhonesBookingService from "./PhonesBookingService";
import PhonesDefaultService from "./PhonesDefaultService";
import PhonesFiltersService from "./PhonesFiltersService";
import {IAxiosConfig} from "../core/Axios";

class PhonesGlobalService {
    [Symbol.toStringTag] = this.constructor.name;

    async fetchAllInit(pageStore: any, store: any) {
        if (!pageStore.isInit) {
            await Promise.all([
                PhonesDefaultService.fetchAllInit(),
                PhonesBookingService.fetchAllInit()
            ]);
            pageStore.isInit = true;
        } else {
            try {
                store.pageLoading = true;
                await this.fetchAll(
                    {
                        type: ["standard"],
                        sort: "expensive"
                    },
                    {loading: true}
                );
            } finally {
                store.pageLoading = false;
            }
        }
    }

    async fetchAll(props: IPhonesFiltersRequest, args?: IAxiosConfig) {
        PhonesDefaultService.cleanUp();
        PhonesBookingService.cleanUp();
        const options = PhonesFiltersService.getFormatedFilters(props);

        if (_.lt(_.size(props.type), 2)) {
            await Promise.all([
                PhonesDefaultService.getObject("default").searchPhones(options, args),
                PhonesBookingService.getObject("default").searchPhones(
                    {
                        ...options,
                        is_reserved: true,
                        per_page: 6
                    },
                    args
                )
            ]);
        } else {
            await Promise.all([
                ..._.chain(props.type)
                    .map(
                        async (i) =>
                            await PhonesDefaultService.getObject(i).searchPhones({
                                ...options,
                                type: i
                            })
                    )
                    .valueOf(),
                ...[
                    await PhonesBookingService.getObject("default").searchPhones({
                        ...options,
                        is_reserved: true,
                        per_page: 6
                    })
                ]
            ]);
        }
    }
}

export default new PhonesGlobalService();
