import {Form, Input} from "@bezlimit/bezlimit-ui";
import {FormItemProps} from "antd";
import {KeyboardEventHandler} from "react";
import {useTranslation} from "react-i18next";
import "./style.scss";

const {Search} = Input;

export default function SearchField({
    name,
    title,
    allowClear,
    onSearch,
    onPressEnter,
    onKeyUp,
    ...props
}: IProps) {
    const {t} = useTranslation();

    return (
        <Form.Item {...props} className={`field-search field-${name ?? "number"}`} name="search">
            <Search
                autoComplete="new-search"
                placeholder={t(title)}
                allowClear
                onSearch={onSearch}
                onPressEnter={onPressEnter}
                onKeyUp={onKeyUp}
            />
        </Form.Item>
    );
}

interface IProps extends FormItemProps<any> {
    title: string;
    allowClear?: boolean;
    onSearch?: (value: any) => void;
    onPressEnter?: KeyboardEventHandler<HTMLInputElement>;
    onKeyUp?: KeyboardEventHandler<HTMLInputElement>;
}
