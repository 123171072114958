import React, {useEffect} from "react";
import {Block, Button, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import "./style.scss";
// services
import ReservationsService from "../../../../services/ReservationsService";
// components
import List from "./list";
import _ from "lodash";

export default React.memo(
    observer(function InBooking() {
        const {t} = useTranslation();
        const navigate = useNavigate();

        const onBooking = () => {
            navigate("/numbers");
        };

        const onBook = () => {
            navigate("/reservation");
        };

        useEffect(() => {
            ReservationsService.getReservations({per_page: 4});
        }, []);

        return (
            <Typography as="div" className="bl-in-booking">
                <Block
                    title={
                        ReservationsService.itemsSize
                            ? t("Мои бронирования")
                            : t("У вас пока нет зарезервированных номеров")
                    }
                    description={
                        ReservationsService.itemsSize
                            ? t("Забронированные ранее номера")
                            : t("Перейдите в «Базу номеров» для выбора номера")
                    }
                >
                    {ReservationsService.itemsSize ? (
                        <Space direction="vertical" size={32}>
                            {_.chain(ReservationsService.items)
                                .map(
                                    (
                                        {
                                            id,
                                            created_at,
                                            is_activated,
                                            phone_number,
                                            recommend_sum,
                                            pay_type,
                                            removed_at,
                                            tariff: {name, price},
                                            phone: {
                                                phone,
                                                mask: {category}
                                            },
                                            additional_data
                                        },
                                        k
                                    ) => (
                                        <React.Fragment key={id}>
                                            <List
                                                id={id}
                                                created_at={created_at}
                                                is_activated={is_activated}
                                                phone_number={phone_number}
                                                pay_type={pay_type}
                                                removed_at={removed_at}
                                                recommend_sum={recommend_sum}
                                                name={name}
                                                price={price}
                                                phone={phone}
                                                category={category}
                                                k={k}
                                                additional_data={additional_data}
                                            />
                                        </React.Fragment>
                                    )
                                )
                                .valueOf()}
                            <Button type="default" onClick={onBook}>
                                {t("Все брони")}
                            </Button>
                        </Space>
                    ) : (
                        <Button type="default" onClick={onBooking}>
                            {t("База номеров")}
                        </Button>
                    )}
                </Block>
            </Typography>
        );
    })
);
