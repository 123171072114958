import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import "./style.scss";
// services
import PhoneService from "../../../../../../services/PhoneService";
// components
import NumbersListBooking from "../../../../../blocks/numbers/list/booking";
import {Space, Typography} from "@bezlimit/bezlimit-ui";
// core
import {LayoutContext} from "../../../../../../core/Contexts";
import {observer} from "mobx-react";
import {isPhonePattern} from "../../../../../forms/numberSearch";
import {isAndroid} from "react-device-detect";

export default React.memo(
    observer(function BookingList({objects, gridType, type, ...props}: IProps) {
        const contextLayout = useContext(LayoutContext);
        const [isPaid, setIsPaid] = useState<boolean>(true);
        const {t} = useTranslation();

        useEffect(() => {
            setIsPaid(!_.isEqual(type[0], "paid"));
        }, [isPhonePattern.searchСounterСlick]);

        return (
            <Typography as="div" className="bl-numbers-list-booking">
                {isPaid && (
                    <Space direction="vertical" size={isAndroid ? 0 : 12}>
                        <Typography as="div" color="#fff" size={17} className="title">
                            {t("В брони")}
                        </Typography>
                        <Space
                            direction="vertical"
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 64 : 48}
                        >
                            {_.chain(objects)
                                .map((i) => (
                                    <NumbersListBooking
                                        Service={i}
                                        gridType={gridType}
                                        key={`${i.type}-booking`}
                                    />
                                ))
                                .valueOf()}
                        </Space>
                    </Space>
                )}
            </Typography>
        );
    })
);

interface IProps {
    objects: PhoneService[];
    gridType: string;
    type: string[];
}
