import React, {useContext, useEffect, useState} from "react";
import {Block, Button, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import "./style.scss";
// Service
import UserCardService from "../../../../services/UserCardService";
//components
import ListRowBankCards from "./row";
import ModalBankCardsSaving from "./modalBankCardsSaving";
import _ from "lodash";
import {InterceptorContext, SessionContext} from "../../../../core/Contexts";

export default React.memo(
    observer(function SavingBankCards() {
        const {t} = useTranslation();
        const context = useContext(InterceptorContext);
        const contextSession = useContext(SessionContext);
        const [isSavingModalVisible, setSavingModalVisible] = useState(false);
        const cards = UserCardService.cardsUser;

        const arrayHash = ["#already_added_url", "#card-add-success", "#card-add-fail"];

        useEffect(() => {
            UserCardService.fetchGetBankCard();
        }, []);

        useEffect(() => {
            setTimeout(() => {
                !_.isEqual(
                    arrayHash.filter((hash: string) => _.isEqual(hash, document.location.hash))
                        .length,
                    0
                ) && setSavingModalVisible(true);
            }, 600);
        }, [window.location.href]);

        return (
            <Block>
                <Typography as="div" className="bl-saving-bank-cards ">
                    <Typography className="title">{t("Банковские карты")}</Typography>
                    <Typography className="description">
                        {t("Управление банковскими картами")}
                    </Typography>
                    {!_.isEqual(cards.length, 0) ? (
                        cards.map((card) => (
                            <ListRowBankCards
                                key={card.id}
                                type={card.type}
                                maskerPan={card.masked_pan}
                                cardId={card.id}
                            />
                        ))
                    ) : (
                        <>
                            <Typography className="description description-no-saved-cards">
                                {t("У вас нет сохраненных карт")}
                            </Typography>
                            <Typography className="description description-no-saved-cards-two">
                                {t("Добавьте карту для быстрого пополнения баланса")}
                            </Typography>
                        </>
                    )}
                    <Button
                        block
                        type="default"
                        onClick={() =>
                            UserCardService.postBankCardSave().then((res) =>
                                window.open(res.url, "_self")
                            )
                        }
                        loading={!contextSession?.toggleCollapsed ? context?.loadingBtn : false}
                    >
                        {t("Добавить карту")}
                    </Button>
                </Typography>
                <ModalBankCardsSaving
                    visible={isSavingModalVisible}
                    cancelHandler={() => setSavingModalVisible(false)}
                />
            </Block>
        );
    })
);
