import {Typography, Row, Col, Checkbox, Space, Skeleton, Divider} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {MobXProviderContext, observer} from "mobx-react";
import up from "../../../../../images/arrow-up.png";
import {useContext, useEffect, useState} from "react";

const SkeletonMobile: React.FC = observer(() => {
    const [arr, setArr] = useState(Array(20));
    const {store} = useContext(MobXProviderContext);

    useEffect(() => {
        if (store.pageLoading) {
            setTimeout(() => {
                setArr(Array(100));
            }, 300);
        } else {
            setArr(Array(20));
        }
    }, [store.pageLoading]);

    return (
        <Typography as="div" className="skeleton-list mobile-list">
            <Row gutter={[8, 8]} className="head">
                <Col xs={12}>
                    <Checkbox disabled={true}>Выбрать все</Checkbox>
                </Col>
                <Col xs={12} className="flex-left">
                    <Space direction="horizontal" size={8}>
                        <Skeleton.Button active={true} className="mw134 h20 f11" />
                        <Skeleton.Button active={true} className="w20 h20" />
                    </Space>
                </Col>
                <Col xs={24}>
                    <Divider />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="wrapper-container">
                {_.chain(arr)
                    .map((i, k) => (
                        <Col xs={24}>
                            <Typography as="div" className="wrapper">
                                <Row gutter={[24, 24]} className="list">
                                    <Col xs={2}>
                                        <Checkbox />
                                    </Col>
                                    <Col xs={10}>
                                        <Skeleton.Button active={true} className="w100" />
                                        <Skeleton.Button active={true} className="w100 h14" />
                                    </Col>
                                    <Col xs={4} className="flex-center">
                                        <img src={up} alt="" width={20} />
                                    </Col>
                                    <Col xs={8} className="flex-left g8">
                                        <Skeleton.Button active={true} className="w100 h32 f11" />
                                        <Skeleton.Button active={true} className="w100 w32 h32" />
                                    </Col>
                                </Row>
                            </Typography>
                        </Col>
                    ))
                    .valueOf()}
            </Row>
            <Row gutter={[24, 24]}>
                <Col lg={24}>
                    <Skeleton.Button active={true} className="w100 h48" />
                </Col>
            </Row>
        </Typography>
    );
});

export default SkeletonMobile;
