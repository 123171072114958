import axios, {AxiosInstance, AxiosRequestConfig} from "axios";
import {setupCache, buildWebStorage} from "axios-cache-interceptor";

export interface IAxiosConfig extends AxiosRequestConfig {
    loading?: boolean;
    loadingBtn?: boolean;
    loadingBlock?: boolean;
    requestId?: string;
    tag?: string;
    cache?: any;
    id?: string;
}

export const AxiosConfig: IAxiosConfig = {
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: false,
    method: "GET",
    timeout: 0,
    loading: true,
    loadingBtn: true,
    loadingBlock: true,
    headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
    }
};
const Axios: AxiosInstance = axios.create(AxiosConfig);
const AxiosCache = setupCache(Axios, {
    storage: buildWebStorage(localStorage, "axios-cache:")
});
export default AxiosCache;

export async function axiosFetchBuilder<T>(
    config: IAxiosConfig,
    transformValue?: (res: T) => T
): Promise<T> {
    config.method = config.method ?? "get";
    const cache = {ttl: 0};

    try {
        let res: T = await (
            await AxiosCache({
                ...config,
                cache
            })
        ).data;

        if (!!transformValue && res) {
            res = transformValue(res);
        }

        return res!;
    } catch (e) {
        throw e;
    }
}
