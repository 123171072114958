import React, {useContext} from "react";
import {Typography, Block} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import "./style.scss";
//images
import ErrorImg from "../../images/errorImg";
// core
import {LayoutContext} from "../../../core/Contexts";

export default React.memo(function LotteryEmptyResult({isSearch}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();

    return (
        <Block className={`lottery-empty-result${isSearch ? " is-search" : ""}`}>
            <ErrorImg />
            <Typography as="div" className="text-container">
                <Typography
                    as="div"
                    className="title"
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                >
                    {isSearch
                        ? t(
                              _.gt(contextLayout?.width ?? 0, 992)
                                  ? "Номера не найдены. Повторите попытку"
                                  : "Номера не найдены."
                          )
                        : t(
                              _.gt(contextLayout?.width ?? 0, 992)
                                  ? "У вас пока нет ни одного номера участвующего в розыгрыше"
                                  : "У вас пока нет ни одного номера\nучаствующего в розыгрыше"
                          )}
                </Typography>
                <Typography
                    as="div"
                    className="text"
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 17 : 14}
                >
                    {isSearch
                        ? t(_.gt(contextLayout?.width ?? 0, 992) ? "" : "Повторите попытку")
                        : t(
                              _.gt(contextLayout?.width ?? 0, 992)
                                  ? "Включайте номера — выиграйте ценные призы от Безлимит!"
                                  : "Включайте номера — выиграйте ценные призы\nот Безлимит!"
                          )}
                </Typography>
            </Typography>
        </Block>
    );
});

interface IProps {
    isSearch: boolean;
}
