import {Block, Button, Col, Icon, Row, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useContext} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext, ModalContext} from "../../../../core/Contexts";
// components
import ShareSuperLinkModal from "../../../modal/shareSuperLink";
import UserService from "../../../../services/UserService";

const SuperLinkWidget: React.FC = observer(() => {
    const {t} = useTranslation();
    const navigate = useNavigate();
    const contextModal = useContext(ModalContext);
    const contextLayout = useContext(LayoutContext);

    const onClick = () => {
        navigate("/super-link");
    };

    const onShare = () => {
        contextModal?.setChildren(<ShareSuperLinkModal />);
        contextModal?.setVisible(true);
    };

    return (
        <Block className="bl-super-link">
            <Row gutter={[16, _.gt(contextLayout?.width ?? 0, 910) ? 56 : 28]} className="row">
                <Col span={24}>
                    <Typography className="title" size={24} color="#fff">
                        {t("Твой новый инструмент заработка")}
                    </Typography>
                    <Typography className="subtitle" size={24} color="rgba(253, 110, 43, 1)">
                        {t("Все о суперссылке")}
                    </Typography>
                </Col>
                <Col span={_.lt(contextLayout?.width ?? 0, 1200) ? 12 : 7}>
                    <Button
                        onClick={onShare}
                        type="primary"
                        icon={<Icon name="sel" size="20" />}
                        className="share"
                    >
                        {t("Поделиться")}
                    </Button>
                </Col>
                <Col span={_.lt(contextLayout?.width ?? 0, 1200) ? 12 : 7}>
                    <Button
                        className="share"
                        icon={<Icon name="share_super" size="20" />}
                        onClick={onClick}
                        type="primary"
                    >
                        {t("Подробнее")}
                    </Button>
                </Col>
                <Col span={_.lt(contextLayout?.width ?? 0, 1200) ? 24 : 10}>
                    <Button
                        icon={<Icon name="view_numbers" size="20" />}
                        onClick={() => {
                            window.open(UserService.systemVars?.super_link, "_blank");
                        }}
                        type="primary"
                        className="share view-numbers"
                    >
                        {t("Просмотреть номера")}
                    </Button>
                </Col>
            </Row>
        </Block>
    );
});
export default SuperLinkWidget;
