// store
import Store from "../stores";
// services
import PhoneService from "./PhoneService";
import PhonesService from "./PhonesService";

class PhonesDefaultService extends PhonesService {
    [Symbol.toStringTag] = this.constructor.name;
    protected _default: PhoneService = new PhoneService(Store.phones.default.default);
    protected _paid: PhoneService = new PhoneService(Store.phones.default.paid);
    protected _standard: PhoneService = new PhoneService(Store.phones.default.standard);
    protected _internet: PhoneService = new PhoneService(Store.phones.default.internet);
}

export default new PhonesDefaultService();
