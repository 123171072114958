import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Block, Space, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import _ from "lodash";
// core
import {LayoutContext} from "../../../../core/Contexts";
//store
import Store from "../../../../stores";
import "./style.scss";

export const RejectPassport: React.FC = observer(() => {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);
    return (
        <Block className="bl-reject-passport">
            <Space
                size={_.gte(contextLayout?.width ?? 0, 768) ? 40 : 24}
                direction="vertical"
                align="start"
            >
                <Typography as="div">
                    <Space size={8} direction="vertical" align="start">
                        <Typography
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                            level={2}
                            color="#ffffff"
                        >
                            {t("Паспортные данные отклонены")}
                        </Typography>
                        <Typography className="subtitle">
                            {t(`Причина: ${Store.user.personal_data?.passport_comment || 'не указана'}`)}
                        </Typography>
                    </Space>
                </Typography>
            </Space>
        </Block>
    );
});
