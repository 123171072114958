import {Button, Col, Divider, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import React from "react";
import {useTranslation} from "react-i18next";
// services
import DeliveryService from "../../../../../../services/DeliveryService";

export default React.memo(function ListDescription({
    address,
    comment,
    fio,
    logistic_comment,
    additional_comment,
    phone,
    onTabClick
}: IProps) {
    const {t} = useTranslation();

    const onClick = () => {
        onTabClick("deliveries/new", `?fio=${fio}&address=${address}&phone=${phone}`);
    };

    return (
        <Typography as="div" className="bl-description-list-delivery">
            <Row>
                <Col span={24}>
                    <Space direction="vertical" className="spase-list-description" size={32}>
                        <Typography as="div" className="List">
                            <Space
                                direction="vertical"
                                size={32}
                                className="spase-list-description"
                            >
                                <Row gutter={[24, 24]}>
                                    <Col md={9} xs={24}>
                                        <Space direction="vertical">
                                            <Typography color="rgba(255, 255, 255, 0.5)" size={17}>
                                                {t("ФИО получателя")}:
                                            </Typography>
                                            <Typography className="copyable" size={17}>
                                                {" "}
                                                {t(fio)}{" "}
                                            </Typography>
                                        </Space>
                                    </Col>
                                    <Col md={9} xs={24}>
                                        <Space direction="vertical">
                                            <Typography color="rgba(255, 255, 255, 0.5)" size={17}>
                                                {t("Адрес")}:
                                            </Typography>
                                            <Typography className="copyable" size={17}>
                                                {t(address)}
                                            </Typography>
                                        </Space>
                                    </Col>
                                    <Col md={6} xs={24}>
                                        <Space direction="vertical">
                                            <Typography color="rgba(255, 255, 255, 0.5)" size={17}>
                                                {t("Телефон получателя")}:
                                            </Typography>
                                            <Typography className="copyable" size={17}>
                                                {t(phone)}
                                            </Typography>
                                        </Space>
                                    </Col>
                                </Row>
                                <Row gutter={[24, 24]} className="List2">
                                    <Col md={9} xs={24}>
                                        {comment && (
                                            <Space direction="vertical">
                                                <Typography
                                                    color="rgba(255, 255, 255, 0.5)"
                                                    size={17}
                                                >
                                                    {t("Коментарий к заказу")}:
                                                </Typography>
                                                <Typography className="copyable" size={17}>
                                                    {t(comment)}
                                                </Typography>
                                            </Space>
                                        )}
                                    </Col>
                                    <Col md={9} xs={24}>
                                        {logistic_comment && (
                                            <Space direction="vertical">
                                                <Typography
                                                    color="rgba(255, 255, 255, 0.5)"
                                                    size={17}
                                                >
                                                    {t("Коментарий логистики")}:
                                                </Typography>
                                                <Typography size={17}>
                                                    {t(logistic_comment)}
                                                </Typography>
                                            </Space>
                                        )}
                                    </Col>
                                    <Col md={6} xs={24}>
                                        {additional_comment && (
                                            <Space direction="vertical">
                                                <Typography
                                                    color="rgba(255, 255, 255, 0.5)"
                                                    size={17}
                                                >
                                                    {t("Дополнительно")}:
                                                </Typography>
                                                <Typography size={17}>
                                                    {t(additional_comment)}
                                                </Typography>
                                            </Space>
                                        )}
                                    </Col>
                                    <Col md={2} xs={0}></Col>
                                    {DeliveryService.isArchive("deliveries/archive") ? (
                                        <Col span={24}>
                                            <Typography as="div" className="btn-repeat-delivery">
                                                <Button type="default" onClick={onClick}>
                                                    {t("Повторить доставку")}
                                                </Button>
                                            </Typography>
                                        </Col>
                                    ) : null}
                                    <Divider />
                                </Row>
                            </Space>
                        </Typography>
                    </Space>
                </Col>
            </Row>
        </Typography>
    );
});

interface IProps {
    phone: any;
    address: string;
    comment: string;
    fio: string;
    logistic_comment: string;
    additional_comment: string;
    onTabClick: (key: string, recipient?: any) => void;
}
