import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {Button, Form, Input, Space, Typography} from "@bezlimit/bezlimit-ui";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
import _ from "lodash";
import {useNavigate} from "react-router-dom";
import "./style.scss";
// components
import Card from "../ui/card";
// core
import {InterceptorContext, LayoutContext, UserContext} from "../../../core/Contexts";
// hooks
import {registerStoreContext} from "../../forms/registerStore";
import UserService from "../../../services/UserService";
import {InvisibleSmartCaptcha} from "@yandex/smart-captcha";
import useFormHandler from "../../../core/hooks/useFormHandler";

export default function IsRegisteredPhone() {
    const {t} = useTranslation();
    const context = useContext(InterceptorContext);
    const contextUser = useContext(UserContext);
    const contextLayout = useContext(LayoutContext);
    const navigate = useNavigate();
    const [phoneRegister, setPhoneRegister] = useState<string>("");
    const [form] = Form.useForm();
    const formInstance = useFormHandler(form);
    const [, setWindowIsMobile] = useState<boolean>(
        document.documentElement.clientWidth < 768 ? true : false
    );
    const [windowInnerWidt, setWindowInnerWidt] = useState(document.documentElement.clientWidth);
    const [token, setToken] = useState("");
    const [visible, setVisible] = useState(false);
    const [isSubmit, setIsSubmit] = useState(false);
    console.log(isSubmit);

    const handleChallengeHidden = useCallback(() => {
        setVisible(false);
    }, []);

    const handleButtonClick = () => {
        setVisible(true);
        if (token) {
            formInstance.submit();
        }
    };

    window.onresize = function () {
        setWindowInnerWidt(document.documentElement.clientWidth);
        if (windowInnerWidt < 768) {
            setWindowIsMobile(true);
        } else if (windowInnerWidt > 768) {
            setWindowIsMobile(false);
        }
    };

    const ref = useRef(null);
    const errorContext = _.chain(
        _.isArray(context?.data)
            ? context?.data
            : context?.data?.message
            ? [{message: context?.data?.message, field: context?.data?.message}]
            : []
    )
        .map(({field, message}) => message)
        .join("\n")
        .valueOf();

    const [error, setError] = useState(errorContext);

    useEffect(() => {
        setError(errorContext);
    }, [errorContext]);

    useEffect(() => {
        if (_.isEmpty(registerStoreContext?.firstName)) {
            navigate("/register-store");
        }
    }, []);

    const onChangeDummy = (value: any) => {
        setPhoneRegister(value.join(""));
        setError("");
    };

    const onFinish = (values: any) => {
        setIsSubmit(false);
        const requestValues = {
            name:
                registerStoreContext?.lastName! +
                " " +
                registerStoreContext?.firstName +
                " " +
                registerStoreContext?.patronymic,
            phone: Number(phoneRegister),
            ...(registerStoreContext.ref_id ? {ref_id: registerStoreContext.ref_id} : {}),
            token: token
        };
        registerStoreContext.phone = Number(phoneRegister);
        UserService.getUsersRegister(requestValues)
            .then(() => {
                contextUser?.setEmail("IsRegisteredPhone");
                navigate("/new-password");
            })
            .finally(() => {
                setIsSubmit(true);
                setVisible(false);
            });
    };

    const format = (phone: string): string => {
        let value = phone.toString();
        if (_.isEqual(value[0], "7")) {
            value = value.substring(1);
        }
        const code = value.substring(0, 3);
        const nums1 = value.substring(3, 6);
        const nums2 = value.substring(6, 8);
        const nums3 = value.substring(8, 10);

        return `+7 ${code}${nums1 ? ` ${nums1}` : ""}${nums2 ? ` ${nums2}` : ""}${
            _.gt(_.size(value), 8) ? `-${nums3}` : ""
        }`;
    };

    useEffect(() => {
        document!.getElementsByTagName("input")[0].focus();
        setPhoneRegister(
            !_.isUndefined(registerStoreContext.phone) ? registerStoreContext.phone.toString() : ""
        );
        setError("");
    }, []);

    useEffect(() => {
        if (token) {
            formInstance.submit();
            setIsSubmit(true);
        }
    }, [token]);

    return (
        <div ref={ref}>
            <Typography as="div" className="bl-isRegistered-phone">
                <Space direction="vertical" align="center" size={!isMobile ? 90 : 48}>
                    <Card theme="blured" className="bl-isRegistered-phone-card">
                        <Form
                            name="phone"
                            initialValues={{phone: ""}}
                            autoComplete="off"
                            form={formInstance}
                            onFinish={onFinish}
                        >
                            <InvisibleSmartCaptcha
                                sitekey="ysc1_KjLgEbqva5WRdw5TBYh85XsB19EsqnwzcSAfvB1n1478758f"
                                onSuccess={setToken}
                                onChallengeHidden={handleChallengeHidden}
                                visible={visible}
                                language="ru"
                            />
                            <Input.Number
                                className="bl-isRegistered-phone-browser"
                                onChange={onChangeDummy}
                                value={phoneRegister}
                                count={10}
                                placeholder={"N"}
                                type={"number"}
                                inputMode={"numeric"}
                                pattern={"[0-9]*"}
                                autoComplete={"off"}
                                style={{marginBottom: "20px"}}
                            />
                            <Form.Item className="bl-isRegistered-phone-mobile" name="phone">
                                <Input
                                    value={format(phoneRegister)}
                                    onChange={(e: {target: {value: string}}) => {
                                        setError("");
                                        _.replace(e.target.value, /\s/g, "")
                                            .replace(/\+7/g, "")
                                            .replace(/-/g, "").length <= 10 &&
                                            setPhoneRegister(
                                                _.replace(e.target.value, /\s/g, "")
                                                    .replace(/\+7/g, "")
                                                    .replace(/-/g, "")
                                                    .replace(/[^\d]/g, "")
                                            );
                                    }}
                                    title={t("Номер телефона")}
                                />
                                <NumberFormat className="bl-isRegistered-phone-mobileNumberFormat" />
                            </Form.Item>
                            <Typography
                                className={
                                    "field-email-errorTypography field-phone-errorTypography"
                                }
                            >
                                {error}
                            </Typography>
                            <Form.Item>
                                <Space direction="vertical" align="center" size={12}>
                                    <Typography className="text-white">
                                        {t("Все права защищены. При входе вы принимаете:")}
                                    </Typography>
                                    <a
                                        href="/files/politika_konfidencialnosti_na_sajt_bezlimit.ru.pdf"
                                        target="_blank"
                                    >
                                        <Typography
                                            underline
                                            className="link link-white transition"
                                        >
                                            {t("Согласие с политикой конфиденциальности")}
                                        </Typography>
                                    </a>
                                    <a
                                        href="/files/dogovor_polzovatelskogo_obsluzhivaniya_na_sajt_bezlimit.ru.pdf"
                                        target="_blank"
                                    >
                                        <Typography
                                            underline
                                            className="link link-white transition"
                                        >
                                            {t("Договор пользовательского обслуживания")}
                                        </Typography>
                                    </a>
                                    <a
                                        href="/files/polzovatelskoe_soglashenie_na_sajt_bezlimit.ru.pdf"
                                        target="_blank"
                                    >
                                        <Typography
                                            underline
                                            className="link link-white transition"
                                        >
                                            {t("Пользовательское соглашение")}
                                        </Typography>
                                    </a>
                                </Space>
                            </Form.Item>
                            <Form.Item noStyle shouldUpdate>
                                {({getFieldsValue, getFieldsError}) => {
                                    const formIsComplete = phoneRegister!.length < 10;

                                    return (
                                        <Button
                                            onClick={handleButtonClick}
                                            type={"primary"}
                                            disabled={formIsComplete || !_.isEmpty(error)}
                                            className="auth-btn"
                                            size="large"
                                        >
                                            {t("Продолжить")}
                                        </Button>
                                    );
                                }}
                            </Form.Item>
                        </Form>
                    </Card>
                </Space>
                {_.lte(contextLayout?.width, 768) ?? 0
                    ? !visible && (
                          <Typography as="div" className="linck-smart-captcha">
                              <a
                                  href={"https://yandex.ru/legal/smartcaptcha_notice/"}
                                  target={"_blank"}
                                  rel="noreferrer"
                              >
                                  Ваши данные обрабатывает SmartCaptcha
                              </a>
                          </Typography>
                      )
                    : null}
            </Typography>
            {_.gte(contextLayout?.width, 768) ?? 0
                ? !visible && (
                      <Typography as="div" className="linck-smart-captcha">
                          <a
                              href={"https://yandex.ru/legal/smartcaptcha_notice/"}
                              target={"_blank"}
                              rel="noreferrer"
                          >
                              Ваши данные обрабатывает SmartCaptcha
                          </a>
                      </Typography>
                  )
                : null}
        </div>
    );
}
