// conponents
import {useEffect} from "react";
import Banner from "../../../../blocks/tariffs/connection/banner";
import ModalInfoTariff from "../../../../blocks/tariffs/connection/modalInfoTariff";
import TariffsService from "../../../../../services/TariffsService";

export default function TariffConnection() {
    useEffect(() => {
        TariffsService.fetchTariffAvailableItems();
    }, []);

    return (
        <>
            <Banner />
            <ModalInfoTariff />
        </>
    );
}
