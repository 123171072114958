import React, {useContext} from "react";
import {Block, Breadcrumb, Col, Collapse, Icon, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import {LayoutContext} from "../../../../../../../core/Contexts";
import Phone from "../phone";
import Description from "../description";
import {useTranslation} from "react-i18next";
import {IPhonesFiltersRequest} from "../../../../../../../core/models/PhonesFilters";
import {IPhone} from "../../../../../../../core/models/Phones";
import PhoneService from "../../../../../../../services/PhoneService";
import _ from "lodash";
import Mobile from "./mobile";
import Desctop from "./desctop";
import PhonesDefaultService from "../../../../../../../services/PhonesDefaultService";
import up from "../../../../../../../images/arrow-up.png";
import down from "../../../../../../../images/arrow-down.png";

export default React.memo(function ListTariffService({
    onClick,
    Service,
    filters,
    props: {
        phone,
        type,
        mask: {category},
        tariff: {id, name: tariffName, minutes, internet, sms, price},
        region: {name: regionName}
    }
}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();
    return (
        <Collapse
            expandIconPosition="end"
            ghost
            expandIcon={({isActive}) => <img src={isActive ? down : up} alt="" width={20} />}
        >
            <Collapse.Panel
                header={
                    <Space
                        size={_.gte(contextLayout?.width, 768) ? 16 : 0}
                        direction={_.gte(contextLayout?.width, 768) ? "horizontal" : "vertical"}
                    >
                        <Phone category={category} phone={phone} />
                        <Description
                            type={type}
                            phone={phone}
                            category={category}
                            tariffName={tariffName}
                            regionName={regionName}
                            minutes={minutes}
                            internet={internet}
                            sms={sms}
                            id={id}
                            filters={filters}
                            Service={Service}
                        />
                        {!PhonesDefaultService.isPaid(type) && (
                            <div className="gold-bg icon-wrapper lightning" onClick={onClick}>
                                <svg className="lightning" width="32" height="32">
                                    <use href={"#lightning1"} />
                                </svg>
                            </div>
                        )}
                    </Space>
                }
                key="1"
            >
                <Typography as="div" className="list-icons">
                    <Block>
                        <Row className="row-list-service">
                            <Col>
                                {_.gte(contextLayout?.width, 768) ?? 0 ? (
                                    <Desctop price={price} />
                                ) : (
                                    <Mobile price={price} />
                                )}
                                {_.lte(contextLayout?.width, 768) ?? 0 ? (
                                    <>
                                        <Col>
                                            <Typography
                                                as="div"
                                                color="rgba(255, 255, 255, 0.5)"
                                                size={14}
                                                className="title-price-list"
                                            >
                                                {t("На все остальное")}
                                            </Typography>
                                        </Col>
                                        <Breadcrumb
                                            separator={<Icon name="dot" size="5" />}
                                            className="bl-limits"
                                        >
                                            {_.gte(minutes, 0) && (
                                                <Breadcrumb.Item>
                                                    <Typography
                                                        as="span"
                                                        className="nowrap"
                                                        color="rgba(255, 255, 255, 0.5)"
                                                    >
                                                        {_.isEqual(minutes, 0)
                                                            ? t("Безлимит")
                                                            : `${minutes} ${t("мин.")}`}
                                                    </Typography>
                                                </Breadcrumb.Item>
                                            )}
                                            {_.gte(internet, 0) && (
                                                <Breadcrumb.Item>
                                                    <Typography
                                                        as="span"
                                                        className="nowrap"
                                                        color="rgba(255, 255, 255, 0.5)"
                                                    >
                                                        {_.isEqual(internet, 0)
                                                            ? t("Безлимит")
                                                            : `${internet} ${t("ГБ")}`}
                                                    </Typography>
                                                </Breadcrumb.Item>
                                            )}
                                            {_.gte(sms, 0) && (
                                                <Breadcrumb.Item>
                                                    <Typography
                                                        as="span"
                                                        className="nowrap"
                                                        color="rgba(255, 255, 255, 0.5)"
                                                    >
                                                        {_.isEqual(sms, 0)
                                                            ? t("Безлимит")
                                                            : `${sms} ${t("смс")}`}
                                                    </Typography>
                                                </Breadcrumb.Item>
                                            )}
                                        </Breadcrumb>
                                        <Col>
                                            <Typography as="div" className="price-tariff">
                                                {t(`Абонентская плата ${price} P`)}
                                            </Typography>
                                        </Col>
                                    </>
                                ) : null}
                            </Col>
                        </Row>
                    </Block>
                </Typography>
            </Collapse.Panel>
        </Collapse>
    );
});

interface IProps {
    filters: IPhonesFiltersRequest;
    props: IPhone;
    Service: PhoneService;
    onClick: () => Promise<void>;
}
