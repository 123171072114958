import {FormInstance} from "antd";
import _ from "lodash";
import {useState} from "react";
import BonusesService from "../../services/BonusesService";

export default function useOnChange(form: FormInstance<any>) {
    const [fioField, setFioField] = useState("");
    const [fioFieldFlagСarriage, setFioFieldFlagСarriage] = useState({
        fieldIsComplete: false,
        carriageNumber: 0
    });

    const setFirstNine = (input: string, form: FormInstance<any>) => {
        const value = _.replace(input, /\s/g, "").replace(/\+7/g, "").replace(/-/g, "").split("");
        const fields = form.getFieldsValue();
        const result = _.compact<string>(value);

        if (result.length > 0) {
            result[0] = "9";
        }

        fields.phone = result.join("");
        form.setFieldsValue(fields);
    };

    const setOnlyCyrillic = (input: string, form: FormInstance<any>) => {
        let value = _.replace(input, /[^А-ЯЁа-яё\-\s]/gi, "");
        const fields = form.getFieldsValue();
        const size = _.size(_.split(value, " "));

        if (
            input.length > 2 &&
            input[input.length - 1] === " " &&
            input[input.length - 2] === " "
        ) {
            value = value.slice(0, value.length - 1);
            fields.fio = value;
            setFioField(fields.fio);
        } else if (
            input.length > 2 &&
            input[input.length - 1] === "-" &&
            input[input.length - 2] === "-"
        ) {
            value = value.slice(0, value.length - 1);
            fields.fio = value;
            setFioField(fields.fio);
        } else if (
            input.length > 2 &&
            input[input.length - 1] === " " &&
            input[input.length - 2] === "-"
        ) {
            value = value.slice(0, value.length - 1);
            fields.fio = value;
            setFioField(fields.fio);
        } else if (
            input.length > 2 &&
            input[input.length - 1] === "-" &&
            input[input.length - 2] === " "
        ) {
            value = value.slice(0, value.length - 1);
            fields.fio = value;
            setFioField(fields.fio);
        } else if (_.isEqual(size, 4)) {
            fields.fio = fioField;
            setFioFieldFlagСarriage({
                fieldIsComplete: true,
                carriageNumber: fioField
                    .split("")
                    .findIndex((el, index) => el !== input.split("")[index])
            });
        } else if (input[input.length - 1] !== " ") {
            const split = _.split(value, " ").filter((i) => _.size(_.trim(i)));
            fields.fio = _.chain(split)
                .reduce<string[]>((res, i) => {
                    (res || (res = [])).push(i.charAt(0).toUpperCase() + i.slice(1));
                    return res;
                }, [])
                .join(" ")
                .valueOf();
            setFioField(fields.fio);
        } else {
            setFioField(value);
        }

        const fildNewFioInput = document.querySelector("[autocomplete='new-fio']");
        form.setFieldsValue(fields);

        if (fioFieldFlagСarriage.fieldIsComplete) {
            // @ts-ignore
            fildNewFioInput.setSelectionRange(
                fioFieldFlagСarriage.carriageNumber,
                fioFieldFlagСarriage.carriageNumber
            );
            setFioFieldFlagСarriage({
                ...fioFieldFlagСarriage,
                fieldIsComplete: false
            });
        }
    };

    const setValueToReplenichField = (input: string, form: FormInstance<any>) => {
        const fields = form.getFieldsValue();
        fields.sum = input;
        form.setFieldsValue(fields);
    };

    const setValueToSumField = (input: string, form: FormInstance<any>) => {
        let value = _.replace(
            _.gte(+input, BonusesService.accumulated) && _.lte(BonusesService.accumulated, 1000)
                ? BonusesService.accumulated.toString()
                : _.gte(+input, 1000)
                ? "1000"
                : input,
            /[^0-9]/gi,
            ""
        );

        const fields = form.getFieldsValue();
        fields.sum = value;
        form.setFieldsValue(fields);
    };

    const onChange = (values: any) => {
        if (_.includes(values[0].name, "phone")) {
            setFirstNine(values[0].value, form);
        }
        if (_.includes(values[0].name, "fio")) {
            setOnlyCyrillic(values[0].value, form);
        }
        if (_.includes(values[0].name, "replenichSum")) {
            setValueToReplenichField(values[0].value, form);
        }
        if (_.includes(values[0].name, "sum")) {
            setValueToSumField(values[0].value, form);
        }
    };

    return onChange;
}
