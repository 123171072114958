import {Typography} from "@bezlimit/bezlimit-ui";

export const SummaryBlock = ({title, value}: IProps): JSX.Element => {
    return (
        <Typography className="lottery-summary" as="div">
            <Typography className="summary-value gold">{value}</Typography>
            <svg
                width="122"
                height="1"
                viewBox="0 0 122 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <g opacity="0.8">
                    <rect width="122" height="1" fill="url(#paint0_linear_6257_116638)" />
                    <rect width="122" height="1" fill="url(#paint1_linear_6257_116638)" />
                </g>
                <defs>
                    <linearGradient
                        id="paint0_linear_6257_116638"
                        x1="17.08"
                        y1="1.07721"
                        x2="17.3593"
                        y2="5.94926"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#EFCE73" />
                        <stop offset="0.531251" stop-color="#FEF0B7" />
                        <stop offset="1" stop-color="#CEA86F" />
                    </linearGradient>
                    <linearGradient
                        id="paint1_linear_6257_116638"
                        x1="0"
                        y1="1.00017"
                        x2="122"
                        y2="1.00017"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stop-color="#642878" />
                        <stop offset="0.503789" stop-color="#642878" stop-opacity="0" />
                        <stop offset="1" stop-color="#642878" />
                    </linearGradient>
                </defs>
            </svg>
            <Typography className="summary-title">{title}</Typography>
        </Typography>
    );
};

interface IProps {
    title: string;
    value: number;
}
