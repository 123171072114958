import React, {useEffect, useRef, useState} from "react";
import moment from "moment";
import _ from "lodash";
// services
import PhonesBookingService from "../../../../../../../services/PhonesBookingService";
// core
import {IReservation} from "../../../../../../../core/models/Phones";
import superLink from "../../../../../../../images/super_link.svg";
import payType from "../../../../../../../images/payType.svg";
import timeLeft from "../../../../../../../images/time-left.svg";
import money from "../../../../../../../images/money.svg";
import free from "../../../../../../../images/free.svg";
import fire from "../../../../../../../images/fire.svg";
import star from "../../../../../../../images/star1.svg";
import npsbook from "../../../../../../../images/npsbook.svg";

export default React.memo(function Icons({reservation}: IProps) {
    const timeout: any = useRef();
    const [state, setState] = useState(<></>);

    useEffect(() => {
        if (!_.isNil(reservation)) {
            clearTimeout(timeout.current);
            const {removed_at} = reservation;
            const interval = 1000;
            const eventTime = moment(removed_at).unix();
            const currentTime = moment().unix();
            const diffTime = eventTime - currentTime;
            let duration: any = moment.duration(diffTime * 1000, "milliseconds");

            timeout.current = setTimeout(function () {
                duration = moment.duration(duration - interval, "milliseconds");
                if (_.gt(duration.asMilliseconds(), 0)) {
                    setState(<></>);
                } else {
                    clearTimeout(timeout.current);
                    const icon = PhonesBookingService.isLastCall(reservation) ? (
                        <img src={fire} width={24} alt="fire" />
                    ) : (
                        <></>
                    );
                    setState(icon);
                }
            }, interval);
        }

        return () => {
            clearTimeout(timeout.current);
        };
    }, [state]);

    return (
        <>
            {PhonesBookingService.isNps(reservation) && (
                <img src={npsbook} width={26} alt="npsbook" style={{marginTop: "3px"}} />
            )}
            {PhonesBookingService.isSuperLink(reservation) && (
                <img src={superLink} width={24} alt="superLink" />
            )}
            {PhonesBookingService.isPaidType(reservation) && (
                <img src={payType} width={24} alt="payType" />
            )}
            {PhonesBookingService.isFreeTime(reservation) && (
                <img src={timeLeft} width={24} alt="timeLeft" />
            )}
            {PhonesBookingService.isConnectionPaid(reservation) && (
                <img src={money} width={24} alt="money" />
            )}
            {PhonesBookingService.isFreeBooking(reservation) && (
                <img src={free} width={24} alt="free" />
            )}
            {PhonesBookingService.isLastCall(reservation) ? (
                <img src={fire} width={24} alt="fire" />
            ) : (
                state
            )}
            {PhonesBookingService.isSuperLink(reservation) && (
                <img src={star} width={24} alt="star" />
            )}
        </>
    );
});

interface IProps {
    reservation: IReservation | undefined;
}
