import React, {useContext, useEffect, useRef} from "react";
import {Button, Form, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import {IAuthRequest} from "../../../core/models/Auth";
import {LayoutContext, UserContext} from "../../../core/Contexts";
import {getFieldsErrorSize} from "../../../core/functions/form";
// services
import UserService from "../../../services/UserService";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
import useFieldFocus from "../../../core/hooks/useFieldFocus";
// components
import EmailText from "./emailText";
import LoginField from "../../fields/login";
import PasswordField from "../../fields/password";

export default React.memo(
    observer(function NewPasswordForm() {
        const context = useContext(LayoutContext);
        const userContext = useContext(UserContext);
        const ref = useRef(null);
        const {t} = useTranslation();
        const navigate = useNavigate();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        useFieldFocus(ref, "login");

        const onFinish = async (values: IAuthRequest) => {
            let login: any;
            const regex = new RegExp(/^(\+7|8|7)([\d]{10})$/gm);
            const result = Array.from(values.login.matchAll(regex));

            if (_.size(result)) {
                login = result[0][2];
            } else {
                login = values.login;
            }

            await UserService.getLogin({
                ...values,
                login: login
            });
            formInstance.resetFields();
            userContext?.setEmail(undefined);
            navigate("/");
        };

        useEffect(() => {
            if (!userContext?.email) {
                navigate("/reset-password");
            }
        }, [userContext?.email, navigate]);

        return (
            <div ref={ref}>
                <Typography as="div" className="bl-new-password-form">
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="new-password"
                        onFinish={onFinish}
                        autoComplete="off"
                        initialValues={{
                            login: "",
                            password: ""
                        }}
                    >
                        <Space
                            size={_.gte(context?.width ?? 0, 768) ? 16 : 24}
                            direction="vertical"
                        >
                            <Typography as="div">
                                <LoginField />
                                <PasswordField />
                                {_.gte(context?.width ?? 0, 768) && <EmailText />}
                            </Typography>
                            <Typography as="div">
                                <Form.Item noStyle shouldUpdate>
                                    {({getFieldsValue, getFieldsError}) => {
                                        const {login, password} = getFieldsValue();
                                        const errors = getFieldsErrorSize(getFieldsError);
                                        const formIsComplete = !!login && !!password && !errors;

                                        return (
                                            <Button
                                                type={!formIsComplete ? "default" : "primary"}
                                                htmlType="submit"
                                                disabled={!formIsComplete}
                                            >
                                                {t("Войти")}
                                            </Button>
                                        );
                                    }}
                                </Form.Item>
                            </Typography>
                        </Space>
                    </Form>
                </Typography>
            </div>
        );
    })
);
