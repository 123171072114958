import {Col, Row, Typography} from "@bezlimit/bezlimit-ui";
import {Link} from "react-router-dom";
import {t} from "i18next";
import "./style.scss";
//components
import InfoCommon from "../../infoCommon";
import SocialIconsServices from "../../../../widgets/socialIconsServices";

export default function Info({
    subscription_fee,
    packet_minutes,
    packet_sms,
    packet_internet,
    name,
    id
}: Props) {
    return (
        <Typography as="div" className="bl-tariff-connection-info">
            <Row gutter={[0, 32]}>
                <Col span={24} order={1} className="bl-tariff-connection-info-Common">
                    <InfoCommon
                        packet_minutes={packet_minutes}
                        packet_sms={packet_sms}
                        packet_internet={packet_internet}
                    />
                </Col>

                <Col span={24} order={2} className="connected-circle">
                    <Link to={"/tariffs"}>
                        <Typography as="div" className="circle">
                            <Typography className="transition">{t("Вернуться назад")}</Typography>
                        </Typography>
                    </Link>
                </Col>
                <Col span={24} order={0}>
                    <SocialIconsServices
                        isPagePlan={true}
                        socialArrayNew={true}
                        subscriptionFee={subscription_fee}
                    />
                </Col>
            </Row>
        </Typography>
    );
}

interface IProps {
    subscription_fee: number;
    packet_minutes: number;
    packet_sms: number;
    packet_internet: number;
    name: string;
    id: number;
}

type Props = IProps;
