import React, {useContext, useRef} from "react";
import {Button, Form, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
// core
import {IUsersRequest} from "../../../core/models/User";
import {InterceptorContext, LayoutContext, ModalContext} from "../../../core/Contexts";
import {getFieldsErrorSize} from "../../../core/functions/form";
// services
import UserService from "../../../services/UserService";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
import useFieldFocus from "../../../core/hooks/useFieldFocus";
import useOnCancel from "../../../core/hooks/useOnCancel";
import useOnChange from "../../../core/hooks/useOnChange";
// components
import LoginField from "../../fields/login";
import Error from "../../modal/error";
import Success from "../../modal/success";

export default React.memo(
    observer(function EditLoginForm(props: Props) {
        const currLogin = props.login;
        const context = useContext(InterceptorContext);
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const ref = useRef(null);
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        const onCancel = useOnCancel(formInstance);
        const onChange = useOnChange(formInstance);
        useFieldFocus(ref, "login");

        const onFinish = async (values: IUsersRequest) => {
            const newLogin = values.login;
            try {
                await UserService.putUsers({login: newLogin});
                contextModal?.setChildren(
                    <Success
                        title={
                            <Typography as="span">
                                {t("Логин успешно ")}
                                <br />
                                {t("изменен")}
                            </Typography>
                        }
                        subtitle={
                            <Typography as="span">
                                {t("Новый логин успешно изменен ")}
                                <br />
                                {t("и действует на всех устройствах")}
                            </Typography>
                        }
                    />
                );
                formInstance.resetFields();
                await UserService.getUsers();
            } catch (e: any) {
                contextModal?.setChildren(<Error />);
            } finally {
                contextModal?.setOnCancel(() => onCancel);
            }
        };

        return (
            <div ref={ref}>
                <Typography as="div">
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="edit-login"
                        onFinish={onFinish}
                        onFieldsChange={onChange}
                        autoComplete="off"
                        initialValues={{
                            login: currLogin ?? ""
                        }}
                    >
                        <Space
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 0 : 8}
                            direction="vertical"
                        >
                            <LoginField />
                            <Form.Item shouldUpdate>
                                {({getFieldsValue, getFieldsError}) => {
                                    const {login} = getFieldsValue();
                                    const errors = getFieldsErrorSize(getFieldsError);
                                    const formIsComplete =
                                        !!login &&
                                        !(login.length < 5) &&
                                        login !== currLogin &&
                                        !errors;

                                    return (
                                        <Button
                                            type={!formIsComplete ? "default" : "primary"}
                                            htmlType="submit"
                                            disabled={!formIsComplete}
                                            loading={context?.loadingBtn}
                                        >
                                            {t("Сменить логин")}
                                        </Button>
                                    );
                                }}
                            </Form.Item>
                        </Space>
                    </Form>
                </Typography>
            </div>
        );
    })
);

interface Props {
    login: string;
}
