import {ComponentTypes} from "../routes/publicRoutes";

export default function PublicLayout({component}: IProps) {
    const Component = component;
    return <Component />;
}

interface IProps {
    component: ComponentTypes;
}
