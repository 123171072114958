import i18n, {t} from "i18next";
import {RuleType} from "rc-field-form/lib/interface";

export class Rules {
    static requiredTextArea() {
        return {required: true, message: i18n.t("Укажите причину удаления")};
    }

    static required() {
        return {required: true, message: i18n.t("Поле обязательно для заполнения")};
    }

    static email() {
        return {
            required: true,
            type: "email" as RuleType,
            message: i18n.t("Указан неверный адрес электронной почты")
        };
    }

    static min(num: number) {
        return {min: num, message: i18n.t(`Минимальное кол-во символов {num}`, {num})};
    }

    static max(num: number) {
        return {max: num, message: i18n.t(`Максимальное кол-во символов {num}`, {num})};
    }

    static minIsPhone(num: number) {
        return {min: num, message: i18n.t("Номер телефона должен содержать 11 цифр")};
    }

    static minFirstAndLastName(num: number, name: string) {
        switch (name) {
            case "firstName":
                return {min: num, message: t("В поле имя должно быть минимум 2 символа")};
            case "lastName":
                return {min: num, message: t("В поле фамилия должно быть минимум 2 символа")};
            case "patronymic":
                return {min: num, message: t("В поле отчество должно быть минимум 2 символа")};
            default:
                break;
        }
    }

    static regex(patten: RegExp, message: string) {
        return {pattern: patten, message: i18n.t(message)};
    }
}
