import React, {useContext} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import CodeConfirmationForm from "../../forms/codeConfirmation";
import _ from "lodash";
import "./style.scss";
// components
import Duration from "../../blocks/duration";
// services
import UserService from "../../../services/UserService";
// core
import {InterceptorContext, LayoutContext} from "../../../core/Contexts";

export default React.memo(function CodeConfirmation({phone, onSubmit, ...props}: any) {
    const context = useContext(InterceptorContext);
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();

    return (
        <Typography as="div" className="bl-code-confirmation">
            <Space
                size={_.gte(contextLayout?.width ?? 0, 768) ? 8 : 56}
                direction="vertical"
                align="center"
            >
                <Typography as="div" className="title-container">
                    <Space size={8} direction="vertical" align="center">
                        <Typography
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                            level={2}
                            color="#000000"
                        >
                            {t("Введите код")}
                        </Typography>
                        <Typography
                            size={14}
                            color={_.gte(contextLayout?.width ?? 0, 768) ? "#000" : "#828282"}
                            className="text-helper subtitle"
                        >
                            {t("Вам отправлен код по SMS для подтверждения")}
                            <br />
                            {t("номера Безлимит")}
                        </Typography>
                    </Space>
                </Typography>
                {_.gte(contextLayout?.width ?? 0, 768) && (
                    <Typography size={24} color="#642878" className="text-helper phone">
                        {phone}
                    </Typography>
                )}
                <CodeConfirmationForm {...props} phone={phone} onSubmit={onSubmit} />
                {!context?.data?.message && _.gte(contextLayout?.width ?? 0, 768) && (
                    <Typography size={14} color="#000000" className="send-to-phone-message">
                        {t("Мы отправили код подтверждения на {phone}", {phone})}
                    </Typography>
                )}
                <Duration
                    onTrigger={() => UserService.putPersonalData({phone, isloading: false})}
                />
            </Space>
        </Typography>
    );
});
