import {Button, Icon, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
// core
import React, {Dispatch, SetStateAction, useEffect, useState} from "react";
import ShimmerLoyalty from "../shimmerLoyalty";
import _ from "lodash";
import Store from "../../../../../stores";
import {Tabs} from "antd";
import {useTranslation} from "react-i18next";
import {createSearchParams, useNavigate} from "react-router-dom";
//images
import aarrow from "../../../../../images/aarrow.png";
import bronzeLoyalty from "../../../../../images/bronze_loyalty.png";
import goldLoyalty from "../../../../../images/gold_loyalty.png";
import silverLoyalty from "../../../../../images/silver_loyalty.png";
import topLoyalty from "../../../../../images/top_loyalty.png";

export default React.memo(function HintMobile({setVisibleLoyalty}: IProps) {
    const [shimmer, setShimmer] = useState(true);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const el = document.body.querySelector(".bl-hint_header");

    const onTabClick = (activeKey: string) => {
        setShimmer(true);
    };

    const onClose = () => {
        el?.classList.remove("none-loyalty");
        setVisibleLoyalty(true);
    };

    const onClick = () => {
        el?.classList.remove("none-loyalty");
        navigate({
            pathname: "bonus",
            search: createSearchParams({
                modal: "1"
            }).toString()
        });
    };

    useEffect(() => {
        if (shimmer) {
            setTimeout(() => {
                setShimmer(false);
            }, 1000);
        }
    }, [shimmer]);

    return (
        <Typography as="div" className="bl-hint-mobile">
            <Button
                onClick={onClose}
                className="btn-close-back"
                icon={<Icon className="back-arrow-hint" name="back-arrow" />}
            />
            <Typography as="div" className="title-loyalty">
                Программы лояльности
            </Typography>
            <Tabs
                defaultActiveKey={Store.user.loyalty.code}
                destroyInactiveTabPane={true}
                onTabClick={onTabClick}
            >
                {_.chain(Store.loyalty.items)
                    .map((item) => {
                        return (
                            <Tabs.TabPane
                                tab={
                                    <Typography as="div">
                                        <Typography
                                            as="div"
                                            className={`tabs-icons ${item.code}-loyalty`}
                                        >
                                            <img src={item.icon} width={58} alt="loyalty" />
                                            <Typography
                                                as="div"
                                                className={`tabs-name-${item.code}`}
                                            >
                                                {item.name}
                                            </Typography>
                                            {_.isEqual(item.code, "bronze") ? (
                                                <Typography as="div" className="tabs-name-active">
                                                    <img
                                                        src={bronzeLoyalty}
                                                        width={54}
                                                        alt="arrow"
                                                    />
                                                </Typography>
                                            ) : _.isEqual(item.code, "silver") ? (
                                                <Typography as="div" className="tabs-name-active">
                                                    <img
                                                        src={silverLoyalty}
                                                        width={55}
                                                        alt="arrow"
                                                    />
                                                </Typography>
                                            ) : _.isEqual(item.code, "gold") ? (
                                                <Typography as="div" className="tabs-name-active">
                                                    <img src={goldLoyalty} width={55} alt="arrow" />
                                                </Typography>
                                            ) : (
                                                <Typography as="div" className="tabs-name-active">
                                                    <img src={topLoyalty} width={30} alt="arrow" />
                                                </Typography>
                                            )}
                                        </Typography>
                                    </Typography>
                                }
                                key={item.code}
                            >
                                <div className="conteiner-descrription">
                                    {shimmer ? (
                                        <ShimmerLoyalty />
                                    ) : (
                                        <>
                                            <div
                                                className="loyalty-description"
                                                dangerouslySetInnerHTML={{
                                                    __html: t(item.desc)
                                                }}
                                            ></div>
                                            <Typography
                                                as="div"
                                                className="details-btn"
                                                onClick={onClick}
                                            >
                                                Подробнее о программе лояльности
                                                <img src={aarrow} width={16} alt="arrow" />
                                            </Typography>
                                            <Typography as="div" className="close-button">
                                                <Button type="primary" onClick={onClose}>
                                                    Закрыть
                                                </Button>
                                            </Typography>
                                        </>
                                    )}
                                </div>
                            </Tabs.TabPane>
                        );
                    })
                    .valueOf()}
            </Tabs>
        </Typography>
    );
});

interface IProps {
    setVisibleLoyalty: Dispatch<SetStateAction<boolean>>;
}
