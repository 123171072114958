import {Form, Input} from "@bezlimit/bezlimit-ui";
import React from "react";
import {useTranslation} from "react-i18next";
// rules
import {Rules} from "../../forms/rules";
import BonusesService from "../../../services/BonusesService";
import NumberFormat from "react-number-format";

export default React.memo(function SumOutputBonusesField({name, form}: any) {
    const {t} = useTranslation();

    const onChangeSumm = (summ: string) => {
        const fields = form.getFieldsValue();
        fields.replenichSum = summ;
        form.setFieldsValue(fields);
    };

    return (
        <Form.Item
            name={name}
            className="field-sum"
            rules={[
                Rules.required(),
                Rules.regex(/^(10[0-9]|1[1-9]\d|[2-9]\d\d|1000)$/gi, ""),
                ({getFieldValue}) => ({
                    validator(_, value) {
                        const sum = Number(value);
                        if (BonusesService.accumulated - sum < 0) {
                            return Promise.reject(
                                new Error(t("Сумма вывода должна быть меньше или равна балансу"))
                            );
                        }
                        return Promise.resolve();
                    }
                })
            ]}
        >
            <NumberFormat<any>
                customInput={Input}
                autoComplete="new-sum"
                format={(value) => {
                    return value.substring(0, 7);
                }}
                onChange={(e: {target: {value: string}}) => {
                    onChangeSumm(e.target.value);
                }}
                title={t("Сумма")}
            />
        </Form.Item>
    );
});
