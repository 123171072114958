import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Block, Button, Form, Space, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext, ModalContext} from "../../../../core/Contexts";
import useFormHandler from "../../../../core/hooks/useFormHandler";
// store
import Store from "../../../../stores";
// component
import Error from "../../../modal/error";
import Success from "../../../modal/success";
//service
import UserService from "../../../../services/UserService";
//fields
import PassportUploadField from "../../../fields/passportUpload";

export const RegisterPassport: React.FC = observer(() => {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);
    const contextModal = useContext(ModalContext);
    const [form] = Form.useForm();
    const formInstance = useFormHandler(form);

    const onFinish = async (values: any) => {
   

        try {
            await UserService.postPersonalDataPhotos([
                _.head(values.passport as any[])?.originFileObj,
                _.head(values.passport_second as any[])?.originFileObj
            ]);
            contextModal?.setVisible(true);
            contextModal?.setChildren(
                <Success
                    title={
                        <Typography as="span">
                            {t("Паспортные данные")}
                            <br />
                            {t("отправлены на проверку")}
                        </Typography>
                    }
                    subtitle={
                        <Typography as="span">
                            {t("Проверка паспортных данных")}
                            <br />
                            {t("Занимает до 24 часов")}
                        </Typography>
                    }
                />
            );
            await UserService.getUsers();
            formInstance.resetFields();
        } catch (e) {
            contextModal?.setVisible(true);
            contextModal?.setChildren(<Error />);
        }
    };

    return (
        <Block className="bl-register-passport">
            <Space
                size={_.gte(contextLayout?.width ?? 0, 768) ? 40 : 24}
                direction="vertical"
                align="start"
            >
                <Form
                    form={formInstance}
                    layout="horizontal"
                    name="book-beservation"
                    autoComplete="off"
                    onFinish={onFinish}
                >
                    <Space direction="vertical" size={16}>
                        <PassportUploadField
                            form={formInstance}
                            title={t("Регистрация паспортных данных")}
                            subtitle={t(
                                "Пройдите регистрацию паспортных данных — это позволит вам пользоваться всеми функциями приложения пополнять номера бонусами, а также выводить бонусы на карту."
                            )}
                        />
                        <Form.Item noStyle shouldUpdate>
                            {({getFieldsValue, getFieldsError}) => {
                                const {passport, passport_second} = getFieldsValue();
                                const formIsComplete =
                                    !passport ||
                                    !passport_second ||
                                    Store.user.personal_data?.passport_status === "moderation";

                                return (
                                    <Button
                                        style={{width: "100%"}}
                                        type="primary"
                                        htmlType="submit"
                                        block
                                        disabled={formIsComplete}
                                    >
                                        {t(
                                            `${
                                                Store.user.personal_data?.passport_status ===
                                                "moderation"
                                                    ? "На модерации"
                                                    : "Пройти регистрацию ПД"
                                            }`
                                        )}
                                    </Button>
                                );
                            }}
                        </Form.Item>
                    </Space>
                </Form>
            </Space>
        </Block>
    );
});
