import React from "react";
import {Typography, Breadcrumb, Space, Col, Row} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {isIOS} from "react-device-detect";
// images
import dotImg2 from "../../../../../../../../images/dot_img.png";
import bee from "../../../../../../../../images/social_bee.svg";
import vk from "../../../../../../../../images/social_vk_c.png";
import ok from "../../../../../../../../images/social_ok_c.png";
import wa from "../../../../../../../../images/social_wa_c.png";
import tg from "../../../../../../../../images/social_tg_c.png";
import vb from "../../../../../../../../images/social_vb_c.png";
import sk from "../../../../../../../../images/social_sk_c.png";
import yt from "../../../../../../../../images/social_yt_c.png";
import ya from "../../../../../../../../images/social_ya_c.png";
import tt from "../../../../../../../../images/social_tt_c.png";
import nav from "../../../../../../../../images/social_nav_c.png";
import gmap from "../../../../../../../../images/social_gmap_c.png";
import yamap from "../../../../../../../../images/social_yamap_c.png";
import gmail from "../../../../../../../../images/social_gmail_c.png";
import mail from "../../../../../../../../images/social_mail_c.png";
import bz from "../../../../../../../../images/logo_bez_mobile_golden.svg";
interface ISocial {
    name: string;
    icons: string[];
    iconsWidth?: number;
    nameMobile: string;
}

export const SOCIAL_SERVICES: ISocial[] = [
    {
        name: "Билайн",
        icons: [bee],
        nameMobile: "Билайн"
    },
    {
        name: "Социальные сети",
        icons: [vk, ok],
        nameMobile: "Соц сети"
    },
    {
        name: "Мессенджеры",
        icons: [wa, tg, vb, sk],
        nameMobile: "Мессенджеры"
    },
    {
        name: "Видео",
        icons: [yt, tt],
        nameMobile: "Видео"
    },
    {
        name: "Музыку",
        icons: [vk, ya],
        nameMobile: "Музыку"
    },
    {
        name: "Карты",
        icons: [yamap, gmap, nav],
        nameMobile: "Карты"
    },
    {
        name: "Почтовые сервисы",
        icons: [gmail, mail, ya],
        nameMobile: "Почту"
    },
    {
        name: "Безлимит",
        icons: [bz],
        iconsWidth: 14,
        nameMobile: "Безлимит"
    }
];

const Item: React.FC<IProps> = observer(
    ({phone, name, connection_price, packet_internet, packet_minutes, sms, region}) => {
        const {t} = useTranslation();

        return (
            <Typography as="div" className="colapse-conteiner">
                <Typography as="div" className="container">
                    <Typography as="div" className="info">
                        <Typography as="div" className="info-tariff">
                            <Space direction="vertical">
                                <Typography as="div" className="group-icons">
                                    <Space direction="vertical">
                                        {SOCIAL_SERVICES.map((item, index) => {
                                            if (
                                                item.name === "Видео" &&
                                                _.isEqual(connection_price, 590)
                                            ) {
                                                return null;
                                            }
                                            return (
                                                <Row className="colapse-conteiner--social-icons-row">
                                                    <Col className="social-icons-col colapse-conteiner--social-icons-col">
                                                        {item.icons.map((icon) => (
                                                            <img
                                                                className="social-icon"
                                                                src={icon}
                                                                width={item.iconsWidth ?? 16}
                                                                alt="logo"
                                                            />
                                                        ))}
                                                    </Col>
                                                    <Typography className="social-text colapse-conteiner--social-text">
                                                        {t(`Безлимит на ${item.name}`)}
                                                    </Typography>
                                                </Row>
                                            );
                                        })}
                                    </Space>
                                </Typography>
                                <Typography as="div" className={"name-tariff"}>
                                    {t(name)}
                                </Typography>
                                <Typography
                                    as="div"
                                    className={isIOS ? "price-ios-rest" : "price-rest"}
                                >
                                    {t("На все остальное")}
                                </Typography>
                                <Breadcrumb
                                    separator={<img src={dotImg2} width={4} alt="logo" />}
                                    className={isIOS ? "name-ios" : "name"}
                                >
                                    {region && <Breadcrumb.Item>{t(region)}</Breadcrumb.Item>}
                                    <Breadcrumb.Item>
                                        {t(
                                            _.isEqual(packet_minutes, 0)
                                                ? "Безлимит мин"
                                                : "{packet_minutes} мин",
                                            {packet_minutes}
                                        )}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        {t(_.isEqual(sms, 0) ? "Безлимит SMS" : "{sms} SMS", {sms})}
                                    </Breadcrumb.Item>
                                    <Breadcrumb.Item>
                                        {t(
                                            _.isEqual(packet_internet, 0)
                                                ? "Безлимит ГБ"
                                                : "{packet_internet} ГБ",
                                            {packet_internet}
                                        )}
                                    </Breadcrumb.Item>
                                </Breadcrumb>
                                <Typography
                                    as="div"
                                    className={isIOS ? "price-ios" : "price-android"}
                                >
                                    {t("Абонентская плата {connection_price} ₽", {
                                        connection_price
                                    })}
                                </Typography>
                            </Space>
                        </Typography>
                    </Typography>
                </Typography>
            </Typography>
        );
    }
);

interface IProps {
    phone: number;
    name: string;
    connection_price: number;
    packet_internet: number;
    packet_minutes: number;
    sms: number;
    region: string | undefined;
}

export default Item;
