import {
    Button,
    Col,
    Dropdown,
    Form,
    InputField,
    Menu,
    Row,
    Space,
    Typography
} from "@bezlimit/bezlimit-ui";
import "./style.scss";
// import TextArea from "antd/lib/input/TextArea";
import _ from "lodash";
import moment from "moment";
import {observer} from "mobx-react";
import {useContext, useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
//core
import {getFieldsErrorSize} from "../../../core/functions/form";
import useFieldFocus from "../../../core/hooks/useFieldFocus";
import {IDeliveryRequestSim} from "../../../core/models/Delivery";
import {InterceptorContext, LayoutContext, ModalContext} from "../../../core/Contexts";
//fields
import FIOField from "../../fields/fio";
import PhoneField from "../../fields/phone";
import QuantitySimField from "../../fields/quantitysim";
import DatePickerField from "../../fields/datePicker";
import QuantityEnvelopesField from "../../fields/quantityEnvelopes";
import AddressField from "../../fields/address";
//rules
// import {Rules} from "../rules";
//modal
import Success from "../../modal/success";
import Error from "../../modal/error";
import BodyDeliveryModal from "../../blocks/delivery/bodyDeliveryModal";
//services
import DeliveryService from "../../../services/DeliveryService";
import useFormHandler from "../../../core/hooks/useFormHandler";
import useOnChange from "../../../core/hooks/useOnChange";
import UserService from "../../../services/UserService";
import trash from "../../../images/trash.png";
import save from "../../../images/save.png";
import save2 from "../../../images/save2.png";
import {isMacOs, isMobile} from "react-device-detect";

export default observer(function NewDeliveryForm() {
    const contextBtn = useContext(InterceptorContext);
    const ref = useRef<HTMLDivElement>(null);
    const {t} = useTranslation();
    const contextModal = useContext(ModalContext);
    const context = useContext(LayoutContext);
    const [addresses, setAddresses] = useState<any>();
    const [isMob, setIsMob] = useState(false);
    const [open, setOpen] = useState(false);
    const [isIcon, setisIcon] = useState(false);
    const [form] = Form.useForm();
    const formInstance = useFormHandler(form);
    const onChange = useOnChange(formInstance);
    useFieldFocus(ref, "login");
    const params = _.chain(decodeURI(window.location.search))
        .replace("?", "")
        .split("&")
        .reduce<any>((res, i) => {
            const [key, value] = _.split(i, "=");
            res[key] = value;
            return res;
        }, {})
        .valueOf();
    const onFinish = async ({
        date,
        phone: tel,
        sim_count,
        envelope_count,
        fio,
        address,
        comment
    }: IDeliveryRequestSim) => {
        const phone = _.replace(tel!, /(\+7|\s|-)/gi, "");

        try {
            const res = await DeliveryService.postDelivery({
                date: _.isEqual(moment().format("YYYY-MM-DD"), moment(date).format("YYYY-MM-DD"))
                    ? moment(date).add(1, "days").format("YYYY-MM-DD")
                    : moment(date).format("YYYY-MM-DD"),
                phone,
                fio,
                address,
                sim_count,
                comment,
                envelope_count
            });
            contextModal?.setChildren(
                <Success
                    title={<Typography as="span">{t("Доставка оформлена")}</Typography>}
                    subtitle={
                        <BodyDeliveryModal
                            id={res.id}
                            fio={fio}
                            phone={phone}
                            address={address}
                            comment={comment}
                        />
                    }
                />
            );
            formInstance.resetFields();
        } catch (err: any) {
            contextModal?.setChildren(<Error isDelivery={true} />);
        } finally {
            contextModal?.setVisible(true);
        }
    };

    const onValuesChange = (field: any) => {
        if (field.address) {
            if (!addresses.find((i: any) => i.value === field.address.trim())) {
                setisIcon(true);
            } else {
                setisIcon(false);
            }
        }
    };

    const onDelete = async (id: number) => {
        const address = addresses.find((i: any) => i.id === id).value;
        contextModal?.setChildren(
            <Typography as="div" className="address-action">
                <Typography as="div" className="title">
                    {t("Удалить сохраненный адрес")}
                </Typography>
                <Typography as="div" className="sub-title">
                    {t("Адрес будет удален из сохраненных адресов")}
                </Typography>
                <Typography as="div" className="actions">
                    <Button
                        type="primary"
                        onClick={async () => {
                            await UserService.deletAddresses(id);

                            contextModal?.setChildren(
                                <Success
                                    title={<Typography as="span">{t("Адрес удален")}</Typography>}
                                    subtitle={
                                        <Typography
                                            as="span"
                                            style={{
                                                fontWeight: 500,
                                                fontSize: "17px",
                                                lineHeight: "120%",
                                                color: "#642878"
                                            }}
                                        >
                                            {address}
                                        </Typography>
                                    }
                                />
                            );
                            const r: any = await UserService.getAddresses();
                            setAddresses(r?.items ?? []);

                            const currentAddress = formInstance.getFieldValue("address");

                            if (currentAddress === address) {
                                setisIcon(true);
                            }
                        }}
                    >
                        {t("Удалить адрес")}
                    </Button>
                    <Button
                        type="default"
                        onClick={() => {
                            contextModal.setVisible(false);
                        }}
                    >
                        {t("Отменить")}
                    </Button>
                </Typography>
            </Typography>
        );
        contextModal?.setVisible(true);
    };

    const onSelect = async (id: number) => {
        formInstance.setFieldValue("address", addresses.find((i: any) => i.id === id).value);
        setOpen(false);
        setisIcon(false);
    };

    const onSave = async () => {
        const address = formInstance.getFieldValue("address");
        contextModal?.setChildren(
            <Typography as="div" className="address-action">
                <Typography as="div" className="title">
                    {t("Добавить адрес в сохраненные?")}
                </Typography>
                <Typography as="div" className="sub-title">
                    {_.gte(context?.width, 768) ? (
                        <>
                            {t("Адрес будет добавлен в список")}
                            <br />
                            {t("сохраненных адресов")}
                        </>
                    ) : (
                        <>{t("Адрес будет добавлен в список сохраненных адресов")}</>
                    )}
                </Typography>
                <Typography as="div" className="actions">
                    <Button
                        type="primary"
                        onClick={async () => {
                            await UserService.postAddresses(address);
                            contextModal?.setChildren(
                                <Success
                                    title={<Typography as="span">{t("Адрес сохранен")}</Typography>}
                                    subtitle={
                                        <Typography
                                            as="span"
                                            style={{
                                                fontWeight: 500,
                                                fontSize: "17px",
                                                lineHeight: "120%",
                                                color: "#642878"
                                            }}
                                        >
                                            {address}
                                        </Typography>
                                    }
                                />
                            );
                            const r: any = await UserService.getAddresses();
                            setAddresses(r?.items ?? []);
                            setisIcon(false);
                        }}
                    >
                        {t("Сохранить адрес")}
                    </Button>
                    <Button
                        type="default"
                        onClick={() => {
                            contextModal.setVisible(false);
                        }}
                    >
                        {t("Отменить")}
                    </Button>
                </Typography>
            </Typography>
        );
        contextModal?.setVisible(true);
    };

    useEffect(() => {
        formInstance.setFieldsValue(params);
    }, [params]);

    useEffect(() => {
        const f = async () => {
            const r: any = await UserService.getAddresses();
            setAddresses(r?.items ?? []);

            if (r?.items && _.size(r?.items)) {
                formInstance.setFieldValue("address", r.items.at(0).value);

                const current = ref.current;

                if (current) {
                    current.querySelector(".field-address-wrapper label")?.classList.add("focused");
                    current.querySelector(".ant-select")?.classList.add("filled");
                }
            }
        };
        f();
    }, []);

    useEffect(() => {
        setIsMob(isMacOs || isMobile);
    }, [context?.width]);

    return (
        <div ref={ref}>
            <Typography
                as="div"
                className={`bl-delivery-form${
                    _.gt(context?.width, 992) && isMob ? " isMobile" : ""
                }`}
            >
                <Form
                    form={formInstance}
                    layout="horizontal"
                    name="login"
                    onFinish={onFinish}
                    autoComplete="off"
                    initialValues={params}
                    onFieldsChange={onChange}
                    onValuesChange={onValuesChange}
                >
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <FIOField title="ФИО получателя" />
                        </Col>
                        <Col span={12}>
                            <PhoneField
                                title={`${
                                    _.gte(context?.width, 768) ?? 0
                                        ? "Номер телефона получателя"
                                        : "Телефон получателя"
                                }`}
                            />
                        </Col>
                        <Col span={12}>
                            <Typography as="div" className="date-picker-delivery">
                                <DatePickerField />
                            </Typography>
                        </Col>
                        <Col span={12}>
                            <QuantitySimField
                                title={`${
                                    _.gte(context?.width, 768) ?? 0
                                        ? "Количество сим-карт"
                                        : "Cим-карт"
                                }`}
                                formInstance={formInstance}
                            />
                        </Col>
                        <Col span={12}>
                            <QuantityEnvelopesField
                                title={`${
                                    _.gte(context?.width, 768) ?? 0
                                        ? "Количество конвертов"
                                        : "Конвертов"
                                }`}
                            />
                        </Col>
                        {addresses && _.size(addresses) ? (
                            <Col span={24} className="field-addresses-parent-container">
                                <Dropdown
                                    open={open}
                                    onOpenChange={() => {
                                        setOpen(!open);
                                    }}
                                    className="field-addresses"
                                    trigger={["click"]}
                                    placement="bottom"
                                    getPopupContainer={(e) =>
                                        document.body.querySelector(".field-addresses-container") ??
                                        document.body
                                    }
                                    overlay={
                                        <Menu
                                            items={_.chain(addresses)
                                                .map(({id, value}) => ({
                                                    key: id,
                                                    label: (
                                                        <div className="item">
                                                            <div
                                                                className="item-text"
                                                                onClick={() => onSelect(id)}
                                                            >
                                                                {value}
                                                            </div>
                                                            <img
                                                                src={trash}
                                                                alt=""
                                                                className="trash"
                                                                onClick={() => onDelete(id)}
                                                            />
                                                        </div>
                                                    )
                                                }))
                                                .valueOf()}
                                        />
                                    }
                                >
                                    <a onClick={(e) => e.preventDefault()}>
                                        {t("Сохраненные адреса")}
                                    </a>
                                </Dropdown>
                                <Typography
                                    as="div"
                                    className={`field-addresses-container${open ? " opened" : ""}`}
                                ></Typography>
                            </Col>
                        ) : null}

                        <Col span={24} className="field-address-container">
                            <AddressField
                                title={t("Адрес (Индекс, город, улица, дом, квартира)")}
                                defaultValue={formInstance.getFieldValue("address")}
                            />
                            {isIcon && (
                                <img
                                    src={_.gte(context?.width, 768) ? save : save2}
                                    alt=""
                                    className="icon"
                                    onClick={onSave}
                                />
                            )}
                        </Col>
                        <Col span={24}>
                            <Typography as="div" className="text-area-delivery-form">
                                <InputField.TextArea
                                    formItemProps={{
                                        label: "Комментарий к заказу",
                                        name: "comment"
                                    }}
                                />
                            </Typography>
                        </Col>
                        <Col span={24}>
                            <Typography as="div">
                                <Space direction="vertical" size={32}>
                                    <Typography>
                                        {t(
                                            "Доставка по Москве, Санкт-Петербургу, Махачкале осуществляется курьерами в будние дни с 10 до 19 часов. Доставка в другие регионы осуществляется почтой России. Все доставки бесплатны."
                                        )}
                                    </Typography>
                                    <Form.Item shouldUpdate>
                                        {({getFieldsValue, getFieldsError}) => {
                                            const {address, fio, phone, date, sim_count} =
                                                getFieldsValue();
                                            const errors = getFieldsErrorSize(getFieldsError);
                                            const formIsComplete =
                                                !!address &&
                                                !!fio &&
                                                !!phone &&
                                                !!sim_count &&
                                                !errors;

                                            return (
                                                <Typography as="div" className="btn-delivery-form">
                                                    <Button
                                                        type={
                                                            !formIsComplete ? "default" : "primary"
                                                        }
                                                        htmlType="submit"
                                                        disabled={!formIsComplete}
                                                        loading={contextBtn?.loadingBtn}
                                                    >
                                                        {t("Оформить доставку")}
                                                    </Button>
                                                </Typography>
                                            );
                                        }}
                                    </Form.Item>
                                </Space>
                            </Typography>
                        </Col>
                    </Row>
                </Form>
            </Typography>
        </div>
    );
});
