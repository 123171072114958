import * as Public from "../../components/public";

export type ComponentTypes =
    | typeof Public.HomePage
    | typeof Public.ResetPasswordPage
    | typeof Public.RegisterStorePage
    | typeof Public.NewPasswordPage
    | typeof Public.RegisterStorePhonePage;
export type PublicRouteTypes = {
    component: ComponentTypes;
    path: string;
};

const routes = {
    HomePage: {
        component: Public.HomePage,
        path: "/",
        breadcrumb: "Вход в кабинет дилера",
        class: "page-index",
        title: "Вход в кабинет дилера"
    },
    ResetPasswordPage: {
        component: Public.ResetPasswordPage,
        path: "/reset-password",
        breadcrumb: "Восстановление пароля",
        class: "page-index page-reset-password",
        title: "Восстановление пароля"
    },
    NewPasswordPage: {
        component: Public.NewPasswordPage,
        path: "/new-password",
        breadcrumb: "Вход в кабинет дилера",
        class: "page-index page-reset-password page-new-password",
        title: "Вход в кабинет дилера"
    },
    RegisterStorePage: {
        component: Public.RegisterStorePage,
        path: "/register-store",
        class: "page-index page-reset-password",
        title: "Регистрация в Store"
    },
    RegisterStorePhonePage: {
        component: Public.RegisterStorePhonePage,
        path: "/register-store-phone",
        class: "page-index page-reset-password",
        title: "Регистрация в Store"
    },
    ClearMyDataPage: {
        component: Public.ClearMyDataPage,
        path: "/clear_my_data",
        class: "page-index page-clear-my-data",
        title: "Запрос на удаление аккаунта"
    }
};
export default routes;
