import {useContext} from "react";
import {Space, Title, Typography} from "@bezlimit/bezlimit-ui";
import {Link} from "react-router-dom";
import {useTranslation} from "react-i18next";
import "./style.scss";
// components
import Politics from "../politics";
import LoginForm from "../../forms/login";
// ui
import Card from "../ui/card";
import _ from "lodash";
// core
import {LayoutContext} from "../../../core/Contexts";
// hooks
import useSearchRoute from "../../../core/hooks/useSearchRoute";
// services
import UserService from "../../../services/UserService";

export default function Login() {
    const context = useContext(LayoutContext);
    const {t} = useTranslation();
    const route = useSearchRoute(UserService.token);

    return (
        <Typography as="div" className="bl-login">
            <Space size={40} direction="vertical" align="center">
                <Title title={_.get(route, "title")}/>

                <Card theme="blured">
                    <>
                        <LoginForm />
                        <Link to="reset-password">
                            <Typography size={14} color="#fff" underline>
                                {t("Забыли пароль?")}
                            </Typography>
                        </Link>
                    </>
                </Card>

                {_.gte(context?.width ?? 0, 768) && <Politics />}
            </Space>
        </Typography>
    );
}
