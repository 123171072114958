import React, {useContext, useEffect, useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {Row, Col, Typography, Button, Input, Block, Title} from "@bezlimit/bezlimit-ui";
//component
import {DealerInfo} from "./dealerInfo";
import ErrorImg from "../../images/errorImg";
import InviteAUserModal from "../../modal/inviteAUser";
// services
import LevelsService from "../../../services/LevelsService";
//core
import {InterceptorContext, ModalContext} from "../../../core/Contexts";
// store
import Store from "../../../stores";
import InviteAUser from "../widgets/inviteAUser";

import "./style.scss";
import {ILevelsRequest} from "../../../core/models/Levels";

export const Dealers: React.FC = observer(() => {
    const context = useContext(InterceptorContext);
    const contextModal = useContext(ModalContext);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const mountedRef = useRef(true);
    const {Search} = Input;
    const [searchValue, setSearchValue] = useState("");
    const levels = LevelsService.items;

    const levelRequest: ILevelsRequest = {
        fields: "login, name, phone, user_id, id, loyalty.code, loyalty.name, activation.last_date, activation.total_cnt, activation.current_month_cnt, activation.last_month_personal_cnt, activation.previous_month_cnt",
        expand: "activation,loyalty"
    };

    const onChange = async (e: any) => {
        const value = e.target?.value;
        if (_.isEmpty(value) && _.isEqual(levels.length, 0)) {
            await LevelsService.fetchlevels(levelRequest);
        } else {
            setSearchValue(value ?? "");
        }
    };

    const onSearch = async (value: string) => {
        if (!value) {
            await LevelsService.fetchlevels(levelRequest);
        }
        await LevelsService.fetchlevels({query: value, ...levelRequest});
    };

    const onAddMore = async () => {
        await LevelsService.loadMorelevels({
            page: LevelsService.currentPage + 1,
            query: searchValue,
            ...levelRequest
        });
    };

    const onInviteAUser = () => {
        contextModal?.setVisible(true);
        contextModal?.setChildren(<InviteAUserModal />);
    };

    const getLevels = async () => {
        if (!mountedRef.current) {
            return;
        }
        await LevelsService.fetchlevels(levelRequest);
    };

    useEffect(() => {
        getLevels();
        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        if (Store.user?.level?.level === 2) {
            navigate("/");
        }
    }, [Store.user?.level?.level]);

    return (
        <Typography as="div" className="bl-dealers-page">
            <Title title="Дилеры" />
            {(levels?.length || searchValue) && (
                <>
                    <Row className="dealers-content" gutter={[16, 8]}>
                        <Col lg={18} md={18} xs={24}>
                            <Search
                                className="dealers-search-input"
                                placeholder={t("Поиск по ФИО")}
                                allowClear
                                onChange={onChange}
                                onSearch={onSearch}
                                style={{width: "100%"}}
                            />
                        </Col>
                        <Col lg={6} md={6} xs={24}>
                            <Button
                                className="dealers-search-btn"
                                type="default"
                                onClick={() => onSearch(searchValue)}
                            >
                                {t("Найти")}
                            </Button>
                        </Col>
                    </Row>
                    <Row className="dealers-invite">
                        <InviteAUser isPageDealers={true} />
                    </Row>
                    <Row gutter={[16, 8]}>
                        <Col span={24}>
                            {(levels ?? []).map((item) => (
                                <DealerInfo {...item} key={item.id} />
                            ))}
                        </Col>
                        <Col span={24}>
                            <Row gutter={[16, 8]}>
                                <Col xs={24} lg={10}>
                                    <Button
                                        style={{width: "100%"}}
                                        type="default"
                                        onClick={() => navigate("/dealers/bonus")}
                                    >
                                        {t("Начисления дилеров")}
                                    </Button>
                                </Col>
                                <Col xs={24} lg={14}>
                                    <Button
                                        style={{width: "100%"}}
                                        className=""
                                        type="primary"
                                        onClick={onAddMore}
                                        disabled={!LevelsService.hasItems}
                                        loading={context?.loadingBtn}
                                    >
                                        {t("Показать еще")}
                                    </Button>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </>
            )}
            {!levels?.length && !searchValue && (
                <Block className="bl-dealers-empty">
                    <Row gutter={[16, 40]} justify="center">
                        <ErrorImg className="dealers-empty-image" />
                        <Col span={24}>
                            <Typography className="dealers-empty-title">
                                {t("У вас нет закрепленных дилеров")}
                            </Typography>
                            <Typography className="dealers-empty-description">
                                {t(
                                    "Пригласите нового дилера и получайте заработок с каждой активации"
                                )}
                            </Typography>
                        </Col>
                        <Col span={24}>
                            <Button
                                style={{width: "100%"}}
                                type="primary"
                                onClick={onInviteAUser}
                                block
                            >
                                {t("Пригласить пользователя")}
                            </Button>
                        </Col>
                    </Row>
                </Block>
            )}
        </Typography>
    );
});
