import React from "react";
import {Empty as EmptyAntd, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";

export default React.memo(function Empty({description}: IProps) {
    const {t} = useTranslation();

    return (
        <EmptyAntd
            description={
                <Typography size={32} fontFamily="alumna" color="#fff">
                    {t(description ?? "Не найдено")}
                </Typography>
            }
            image={false}
            imageStyle={{display: "none"}}
        />
    );
});

interface IProps {
    description?: string;
}
