import {makeAutoObservable} from "mobx";
import {IItems, IMeta, INotificationsResponse} from "../core/models/News";

class Notifications implements INotificationsResponse {
    [Symbol.toStringTag] = this.constructor.name;
    private _items!: IItems[];
    private _meta!: IMeta;
    private _newsCount!: number;

    constructor() {
        makeAutoObservable(this);
    }

    get items() {
        return this._items;
    }

    set items(value: IItems[]) {
        this._items = value;
    }

    get newsCount() {
        return this._newsCount;
    }

    set newsCount(value: number) {
        this._newsCount = value;
    }

    get meta() {
        return this._meta;
    }

    set meta(value: IMeta) {
        this._meta = value;
    }
}

export default Notifications;
