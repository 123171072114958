import React, {useContext, useEffect, useRef, useState} from "react";
import {
    Badge,
    Button,
    Col,
    Collapse,
    Form,
    Icon,
    Input,
    Modal,
    Row,
    Skeleton,
    Space,
    Typography
} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {MobXProviderContext, observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import {InterceptorContext, LayoutContext, ModalContext} from "../../../core/Contexts";
import mode from "../../../images/mode.png";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
// components
import Error from "../../modal/error";
import CheckboxField from "../../fields/checkbox";
import FakeSelectField from "../../fields/fakeSelect";
import RadioField from "../../fields/radio";
// services
import PhonesFiltersService from "../../../services/PhonesFiltersService";
import PhoneService from "../../../services/PhoneService";
import PhonesGlobalService from "../../../services/PhonesGlobalService";
import {toGroupNumbers, withCharacterCb} from "../../../core/functions/inputNumber";
import SmartField from "../../fields/smart";
import {isPhonePattern} from "../numberSearch";
import NumberFilterMode from "../../modal/numberFilterMode";
import NumbersFiltersStoreService from "../../../services/NumbersFiltersStoreService";
import Success from "../../modal/success";
import SaveFiltersModal from "../../modal/saveFilters";
import MyFilterSets from "../../blocks/numbers/filters/desktop/selected/myFilterSets";
import {IPhonesCurrentFilters} from "../../../core/models/PhonesFilters";

export default React.memo(
    observer(function NumbersFilterMobileForm({
        phone,
        type,
        tariff,
        categories,
        region,
        pattern,
        phonePattern,
        phoneCombs,
        sort,
        Service,
        isFavorite,
        setIsFavorite,
        setType,
        setTariff,
        setCategories,
        setRegion,
        setPattern,
        setAllIsChecked,
        setPhone,
        setPhonePattern,
        setPhoneCombs
    }: IProps) {
        const {store} = useContext(MobXProviderContext);
        const context = useContext(InterceptorContext);
        const contextModal = useContext(ModalContext);
        const ref = useRef(null);
        const {t} = useTranslation();
        const [pageLoading, setPageLoading] = useState(false);
        const [disabled, setDisabled] = useState(false);
        const [tariffSize, setTariffSize] = useState(_.size(tariff));
        const [tariffFake, setTariffFake] = useState(tariff);
        const [categoriesSize, setCategoriesSize] = useState(_.size(categories));
        const [regionSize, setRegionSize] = useState(_.size(region));
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        const [isModalOpen, setIsModalOpen] = useState(false);
        const contextLayout = useContext(LayoutContext);
        const [shouldUpdate, setShouldUpdate] = useState(false);
        const [currentCheckbox, setCurrentCheckbox] = useState(NumbersFiltersStoreService.current);

        const {Panel} = Collapse;

        const handleCancelRemove = () => {
            PhonesFiltersService.isRemovedItem = false;
        };

        const handleCancel = () => {
            setIsModalOpen(false);
        };

        const onClickModal = () => {
            setIsModalOpen(true);
        };

        const onUpdate = () => {
            const clone = _.clone(NumbersFiltersStoreService.items);
            const value = clone.find((i, k) => _.isEqual(k, currentCheckbox));

            if (value) {
                value!.values = {
                    ...PhonesFiltersService.currentFilter
                };
                clone[currentCheckbox!] = value;
                NumbersFiltersStoreService.update(clone);
                NumbersFiltersStoreService.updateFilter(value);
                setShouldUpdate(false);
            }
        };

        const onFinish = async ({
            type,
            tariff,
            categories,
            region,
            pattern,
            phone_pattern,
            phone_combs
        }: any) => {
            const typeToArray = [type];
            setType(typeToArray);
            setTariff(tariff);
            setTariffFake(tariff);
            setCategories(categories);
            setRegion(region);
            setPattern(pattern);
            setPhone(!_.isNil(phone) && _.size(_.trim(phone)) ? _.trim(phone) : undefined);
            // contextModal?.setCssClass("");
            // contextModal?.setVisible(false);

            try {
                setPageLoading(true);
                store.pageLoading = true;
                await PhonesGlobalService.fetchAll(
                    {
                        phone_pattern,
                        phone_combs,
                        type: typeToArray,
                        tariff,
                        categories,
                        region,
                        pattern,
                        sort
                    },
                    {loading: false}
                );
                isPhonePattern.searchСounterСlick = isPhonePattern.searchСounterСlick + 1;
                isPhonePattern.isFilters = true;
            } catch (e) {
                contextModal?.setChildren(<Error />);
            } finally {
                setAllIsChecked(false);
                store.pageLoading = false;
            }
        };

        const onClear = () => {
            setType([]);
            setTariff([]);
            setTariffFake([]);
            setCategories([]);
            setRegion([]);
            setPattern([]);
            setPhone(undefined);
            setTariffSize(0);
            setCategoriesSize(0);
            setRegionSize(0);
            setDisabled(false);
            formInstance.setFieldsValue({
                type: "standard",
                tariff: [],
                categories: [],
                region: [],
                pattern: [],
                phone: ""
            });
            PhonesFiltersService.resetCurrentFilter();
        };

        const onValuesChange = (changedValues: any, values: any) => {
            const type = _.get(changedValues, "type");

            if (!_.isNil(currentCheckbox)) {
                const r = _.chain(NumbersFiltersStoreService.items[currentCheckbox].values)
                    .reduce<any>((r, i: any, k) => {
                        if (!_.includes(["sort", "grid"], k)) {
                            r[k] = k === "type" ? i[0] : i;
                        }

                        return r;
                    }, {})
                    .valueOf();

                if (!_.isEqual(values, r)) {
                    setShouldUpdate(true);
                } else {
                    setShouldUpdate(false);
                }
            }

            if (!_.isNil(type)) {
                setType([type]);

                if (!_.isEqual(type, "standard")) {
                    setTariff([]);
                    setTariffSize(0);
                    setTariffFake([]);
                    setDisabled(true);
                    const fields = formInstance.getFieldsValue();
                    fields.tariff = [];
                    formInstance.resetFields();
                    formInstance.setFieldsValue(fields);
                } else {
                    setDisabled(false);
                }
            }
            if (!_.isNil(_.get(changedValues, "pattern"))) {
                setPattern(_.get(changedValues, "pattern"));
            }
            if (!_.isNil(_.get(changedValues, "tariff"))) {
                setTariffSize(_.size(_.get(changedValues, "tariff")));
                setTariff(_.get(changedValues, "tariff"));
                setTariffFake(_.get(changedValues, "tariff"));
            }
            if (!_.isNil(_.get(changedValues, "categories"))) {
                setCategoriesSize(_.size(_.get(changedValues, "categories")));
                setCategories(_.get(changedValues, "categories"));
            }
            if (!_.isNil(_.get(changedValues, "region"))) {
                setRegionSize(_.size(_.get(changedValues, "region")));
                setRegion(_.get(changedValues, "region"));
            }
            if (!_.isNil(_.get(changedValues, "phone"))) {
                setPhone(_.get(changedValues, "phone"));
            }
            if (changedValues.phone_pattern) {
                setPhonePattern(
                    _.chain(changedValues.phone_pattern)
                        .reduce<string[]>((res, i) => {
                            (res || (res = [])).push(i ? i.toLocaleUpperCase() : "");
                            return res;
                        }, [])
                        .valueOf()
                );
            }

            let updated = _.clone(
                PhonesFiltersService.currentFilter[type as keyof IPhonesCurrentFilters]
            ) as string[];
            values.type = [values.type];

            PhonesFiltersService.setCurrentFilter({...(updated ?? {}), ...values});

            if (_.isNil(currentCheckbox)) {
                if (
                    _.isNil(NumbersFiltersStoreService.current) &&
                    (!!PhonesFiltersService.currentFilter.phone_combs?.replace(/,/gi, "") ||
                        PhonesFiltersService.currentFilter.phone_pattern?.filter((i) => i).length)
                ) {
                    setShouldUpdate(true);
                } else {
                    setShouldUpdate(false);
                }
            }
        };

        const onClick = () => {
            contextModal?.setVisible(true);
            contextModal?.setChildren(
                <NumberFilterMode isFavorite={isFavorite} setIsFavorite={setIsFavorite} />
            );
        };

        useEffect(() => {
            if (!_.includes(type, "standard")) {
                setDisabled(true);
            } else {
                setDisabled(false);
            }
        }, [type]);

        useEffect(() => {
            toGroupNumbers(ref, "phone_pattern", formInstance, withCharacterCb);
        }, [ref.current]);

        useEffect(() => {
            if (!store.pageLoading && pageLoading) {
                setPageLoading(false);
                contextModal?.setVisible(false);
                contextModal?.setMaskClosable(true);
                contextModal?.setCssClass("");
            }

            if (store.pageLoading) {
                contextModal?.setMaskClosable(false);
                contextModal?.setCssClass(" modalpage");
            }
        }, [store.pageLoading]);

        useEffect(() => {
            setCurrentCheckbox(NumbersFiltersStoreService.current);
            const filter = NumbersFiltersStoreService.items.find((i, k) =>
                _.isEqual(k, NumbersFiltersStoreService.current)
            );
            if (filter) {
                formInstance.setFieldsValue({
                    ...filter.values,
                    type: _.head(filter.values.type)
                });
            } else {
                formInstance.setFieldsValue({
                    ...PhonesFiltersService.currentFilter,
                    type: _.head(PhonesFiltersService.currentFilter.type)
                });
            }
        }, [NumbersFiltersStoreService.current]);
        useEffect(() => {
            if (!_.isNil(currentCheckbox)) {
                const filter: any = NumbersFiltersStoreService.items.find((i, k) =>
                    _.isEqual(k, NumbersFiltersStoreService.current)
                );

                if (filter) {
                    filter.values.sort = PhonesFiltersService.currentFilter.sort;
                    filter.values.grid = PhonesFiltersService.currentFilter.grid;

                    if (PhonesFiltersService.currentFilter.phone_combs === ",,") {
                        delete PhonesFiltersService.currentFilter.phone_combs;
                    }
                    let isUpdate = false;

                    _.chain(PhonesFiltersService.currentFilter)
                        .mapKeys((i, k) => {
                            let value: any = i;

                            if (k === "phone_pattern") {
                                value = _.chain(i as string[])
                                    .map((i) => (!i.length ? "N" : i))
                                    .valueOf();
                            }

                            if (!_.isEqual(value, filter.values[k])) {
                                isUpdate = true;
                            }
                        })
                        .valueOf();

                    if (isUpdate) {
                        setShouldUpdate(true);
                    } else {
                        setShouldUpdate(false);
                    }
                } else {
                    setShouldUpdate(false);
                }
            }
        }, [PhonesFiltersService.currentFilter]);

        const onClose = () => {
            var x = document.querySelectorAll(".ant-collapse-item-active");
            if (x.length) {
                for (var i = 0; i < x.length; i++) {
                    setTimeout(function () {
                        var el: any = document.querySelector(".ant-collapse-item-active");
                        el?.children[0]?.click();
                    }, 100);
                }
            }
        };

        return (
            <div ref={ref}>
                <Typography
                    as="div"
                    className={`bl-numbers-filter-mobile-form${
                        store.pageLoading ? " modal-loading" : ""
                    }`}
                >
                    {store.pageLoading
                        ? null
                        : NumbersFiltersStoreService.items.length > 0 && (
                              <Collapse expandIconPosition="end" bordered={false}>
                                  <Icon name="save_black" size="14" className="save-black-svg" />
                                  <Panel header="Мои наборы фильтров" key={"1"} id="searchBar">
                                      <MyFilterSets
                                          setType={setType}
                                          setTariff={setTariff}
                                          setCategories={setCategories}
                                          setRegion={setRegion}
                                          setPattern={setPattern}
                                          setPhonePattern={setPhonePattern}
                                          setPhoneCombs={setPhoneCombs}
                                          phonePattern={phonePattern}
                                          phoneCombs={phoneCombs}
                                          setTariffSize={setTariffSize}
                                          setCategoriesSize={setCategoriesSize}
                                          setRegionSize={setRegionSize}
                                      />
                                      <Typography as="div" className="close-colapse-btn">
                                          <Button type="text" onClick={onClose}>
                                              Скрыть
                                          </Button>
                                          <Icon name="Arrow_up_filter" size="18" />
                                      </Typography>
                                  </Panel>
                              </Collapse>
                          )}
                    {store.pageLoading ? (
                        <Typography as="div" className="mob-filter-skeleton">
                            <Row gutter={[8, 16]}>
                                <Col xs={14} className="middle">
                                    <Skeleton.Button active={true} className="line" />
                                </Col>
                                <Col xs={10} className="small flex end">
                                    <Skeleton.Button active={true} className="line" />
                                </Col>
                                <Col xs={7} className="middle">
                                    <Skeleton.Button active={true} className="round" />
                                </Col>
                                <Col xs={7} className="middle">
                                    <Skeleton.Button active={true} className="round" />
                                </Col>
                                <Col xs={24} className="short2 flex g5">
                                    <Skeleton.Button active={true} className="line bordered" />
                                    <Skeleton.Button active={true} className="line bordered" />
                                    <Skeleton.Button active={true} className="line bordered" />
                                    <Typography as="div"></Typography>
                                    <Typography as="div"></Typography>
                                    <Skeleton.Button active={true} className="line bordered" />
                                    <Skeleton.Button active={true} className="line bordered" />
                                    <Skeleton.Button active={true} className="line bordered" />
                                    <Skeleton.Button active={true} className="line bordered" />
                                    <Skeleton.Button active={true} className="line bordered" />
                                    <Skeleton.Button active={true} className="line bordered" />
                                    <Skeleton.Button active={true} className="line bordered" />
                                </Col>
                                <Col xs={20} className="middle mmt10">
                                    <Skeleton.Button active={true} className="line" />
                                </Col>
                                <Col xs={24} className="short2 long">
                                    <Skeleton.Button active={true} className="line bordered" />
                                </Col>
                                <Col xs={20} className="middle mmt10 flex row g6">
                                    <Skeleton.Button active={true} className="line" />
                                    <Skeleton.Button active={true} className="line" />
                                </Col>
                                <Col xs={24} className="middle flex g5 big">
                                    <Skeleton.Button active={true} className="round" />
                                    <Skeleton.Button active={true} className="round" />
                                    <Skeleton.Button active={true} className="round" />
                                    <Skeleton.Button active={true} className="round" />
                                </Col>
                                <Col xs={12} className="middle">
                                    <Typography as="div" className="border">
                                        <Row gutter={[8, 16]}>
                                            <Col xs={12} className="flex g5 center">
                                                <Skeleton.Button active={true} className="line" />
                                                <Skeleton.Button active={true} className="circle" />
                                                <Skeleton.Button
                                                    active={true}
                                                    className="line sm"
                                                />
                                            </Col>
                                        </Row>
                                    </Typography>
                                </Col>
                                <Col xs={12} className="middle">
                                    <Typography as="div" className="border">
                                        <Row gutter={[8, 16]}>
                                            <Col xs={12} className="flex g5 center">
                                                <Skeleton.Button active={true} className="line" />
                                                <Skeleton.Button
                                                    active={true}
                                                    className="circle o0"
                                                />
                                                <Skeleton.Button
                                                    active={true}
                                                    className="line sm rl"
                                                />
                                            </Col>
                                        </Row>
                                    </Typography>
                                </Col>
                                <Col xs={24} className="middle">
                                    <Typography as="div" className="border">
                                        <Row gutter={[8, 16]}>
                                            <Col xs={12} className="flex g5 center">
                                                <Skeleton.Button active={true} className="line" />
                                                <Skeleton.Button active={true} className="circle" />
                                                <Skeleton.Button
                                                    active={true}
                                                    className="line sm"
                                                />
                                            </Col>
                                            <Col xs={12} className="flex end">
                                                <Skeleton.Button
                                                    active={true}
                                                    className="line sm"
                                                />
                                            </Col>
                                        </Row>
                                    </Typography>
                                </Col>
                                <Col xs={24} className="middle long2">
                                    <Skeleton.Button active={true} className="round" />
                                </Col>
                                <Col xs={24} className="middle middle3 mm8">
                                    <Skeleton.Button active={true} className="line" />
                                </Col>
                                <Col xs={24} className="middle long2">
                                    <Skeleton.Button active={true} className="round" />
                                </Col>
                            </Row>
                        </Typography>
                    ) : (
                        <Form
                            form={formInstance}
                            layout="horizontal"
                            name="numbers-filter-mobile"
                            onFinish={onFinish}
                            onValuesChange={onValuesChange}
                            autoComplete="off"
                            initialValues={{
                                type: _.head(type),
                                tariff,
                                categories,
                                region,
                                pattern,
                                phone,
                                phone_pattern: phonePattern,
                                phone_combs: phoneCombs
                            }}
                        >
                            <Space size={16} direction="vertical" className="fields-container">
                                <RadioField name="type" items={PhonesFiltersService.filterType} />
                                <Space size={4} direction="vertical">
                                    <Form.Item
                                        className="field-phone_pattern"
                                        name="phone_pattern"
                                        normalize={(value) => {
                                            const regex = /[0-9abcnABCN]+/gim;
                                            const arr = _.chain(
                                                _.map(value, (i) => (i && _.size(i) ? i : "_"))
                                                    .join("")
                                                    .replace(/(\+7)/gm, "")
                                                    .split("")
                                                    .map((i) => (_.isEqual(i, "_") ? "" : i))
                                            )
                                                .filter((i) => {
                                                    if (i) {
                                                        return !!i.match(regex);
                                                    } else {
                                                        return _.isNil(i) || _.isEmpty(i);
                                                    }
                                                })
                                                .filter((i, k) => _.lt(k, 10))
                                                .valueOf();

                                            return arr;
                                        }}
                                    >
                                        <Input.Number
                                            count={10}
                                            type={"text"}
                                            inputMode={"text"}
                                            pattern={"[0-9ABCN]"}
                                            autoComplete={"off"}
                                            placeholder="N"
                                        />
                                    </Form.Item>
                                    <Typography
                                        as="div"
                                        size={12}
                                        color="#828282"
                                        className="helper-text"
                                    >
                                        {t("Для фильтра можно использовать ABCN или числа")}
                                    </Typography>
                                </Space>
                                <Space size={4} direction="vertical">
                                    <SmartField
                                        formInstance={formInstance}
                                        setPhoneCombs={setPhoneCombs}
                                        phoneCombs={phoneCombs}
                                        currentCheckbox={currentCheckbox}
                                        setShouldUpdate={setShouldUpdate}
                                    />
                                    <Typography
                                        as="div"
                                        size={12}
                                        color="#828282"
                                        className="helper-text"
                                    >
                                        {t(
                                            "Введите суммарно до 10 цифр для подбора номера с комбинациями в разных местах номера"
                                        )}
                                    </Typography>
                                </Space>
                                <CheckboxField
                                    name="pattern"
                                    items={PhonesFiltersService.filterPattern}
                                />
                                <Row gutter={[16, 16]}>
                                    <Col span={12} className="col-filter">
                                        <FakeSelectField
                                            name="tariff"
                                            title="Тарифы"
                                            items={PhonesFiltersService.filterTariff}
                                            count={tariffSize}
                                            selected={tariffFake}
                                            disabled={disabled}
                                            set={setTariff}
                                        />
                                    </Col>
                                    <Col span={12} className="col-filter">
                                        <FakeSelectField
                                            name="categories"
                                            title="Категории"
                                            items={PhonesFiltersService.filterCategories}
                                            count={categoriesSize}
                                            selected={categories}
                                            set={setCategories}
                                        />
                                    </Col>
                                    <Col span={24} className="col-filter">
                                        <FakeSelectField
                                            name="region"
                                            title="Регионы"
                                            items={PhonesFiltersService.filterRegion}
                                            count={regionSize}
                                            selected={region}
                                            set={setRegion}
                                            setShouldUpdate={setShouldUpdate}
                                        />
                                    </Col>
                                </Row>
                                <Form.Item noStyle shouldUpdate>
                                    {() => (
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={context?.loadingBtn}
                                            className="btn-apply-filters"
                                        >
                                            {t("Применить")}
                                            <Badge
                                                count={_.size(
                                                    [
                                                        ...(PhonesFiltersService.currentFilter
                                                            ?.type ?? []),
                                                        ...(PhonesFiltersService.currentFilter
                                                            ?.tariff ?? []),
                                                        ...(PhonesFiltersService.currentFilter
                                                            ?.categories ?? []),
                                                        ...(PhonesFiltersService.currentFilter
                                                            ?.region ?? []),
                                                        ...(PhonesFiltersService.currentFilter
                                                            ?.pattern ?? []),
                                                        _.chain(phonePattern)
                                                            .filter(
                                                                (i) => !_.isNil(i) && !_.isEmpty(i)
                                                            )
                                                            .size()
                                                            .valueOf() && 1,
                                                        !_.isNil(phoneCombs) &&
                                                            _.size(
                                                                _.replace(phoneCombs, /,/gi, "")
                                                            ) &&
                                                            1
                                                    ].filter((i) => i)
                                                )}
                                            />
                                        </Button>
                                    )}
                                </Form.Item>
                                <Typography as="div" className="text-center cancel">
                                    {_.isNil(NumbersFiltersStoreService.current) &&
                                    !PhonesFiltersService.size() &&
                                    !shouldUpdate ? null : !_.isNil(
                                          NumbersFiltersStoreService.current
                                      ) && shouldUpdate ? (
                                        <Typography as="div" className="group-button-refresh">
                                            <Row gutter={[50, 20]}>
                                                <Col xl={24} lg={24}>
                                                    <Button
                                                        type="text"
                                                        className="btn-refresh"
                                                        onClick={onUpdate}
                                                    >
                                                        {t("Обновить")}
                                                    </Button>
                                                </Col>
                                                <Col xl={24} lg={24}>
                                                    <Button
                                                        type="text"
                                                        className="btn-save-new"
                                                        onClick={onClickModal}
                                                    >
                                                        <Space direction="horizontal" size={9}>
                                                            {t("Сохранить новый")}
                                                            {_.gte(contextLayout?.width, 768) ? (
                                                                <Icon name="Save" size="14" />
                                                            ) : (
                                                                <Icon name="save_black" size="14" />
                                                            )}
                                                        </Space>
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Typography>
                                    ) : !_.isNil(NumbersFiltersStoreService.current) &&
                                      !shouldUpdate ? null : (
                                        <Typography as="div" className="group-button">
                                            <Row gutter={[6, 6]}>
                                                <Col xl={12} lg={24}>
                                                    <Button
                                                        type="text"
                                                        icon={<Icon name="save_violet" size="14" />}
                                                        onClick={onClickModal}
                                                        className="save-btn"
                                                    >
                                                        {t("Сохранить фильтр")}
                                                    </Button>
                                                </Col>
                                                <Col xl={12} lg={24}>
                                                    <Button
                                                        type="text"
                                                        icon={<Icon name="close" size="14" />}
                                                        onClick={onClear}
                                                        className="reset-btn"
                                                    >
                                                        {t("Сбросить фильтр")}
                                                    </Button>
                                                </Col>
                                            </Row>
                                        </Typography>
                                    )}
                                    {/* <Button
                                        type="text"
                                        icon={<Icon name="close" size="8" />}
                                        onClick={onClear}
                                    >
                                        {t("Очистить фильтр")}
                                    </Button> */}
                                </Typography>
                                <Button
                                    type="primary"
                                    className="mode"
                                    icon={<img src={mode} alt="" />}
                                    onClick={onClick}
                                >
                                    Режим фильтров
                                </Button>
                            </Space>
                        </Form>
                    )}
                </Typography>
                <Modal open={isModalOpen} onCancel={handleCancel} className="save-filter-modal">
                    <SaveFiltersModal
                        title={t("Сохранить набор фильтров")}
                        setIsModalOpen={setIsModalOpen}
                        phonePattern={phonePattern}
                        phoneCombs={phoneCombs}
                        isMobile={true}
                    />
                </Modal>
                <Modal
                    open={PhonesFiltersService.isRemovedItem}
                    onCancel={handleCancelRemove}
                    className="delete-success-modal"
                >
                    <Success title={t("Группа успешно удалена")} />
                </Modal>
            </div>
        );
    })
);

interface IProps {
    phone: string | undefined;
    type: string[];
    tariff: string[];
    categories: string[];
    region: string[];
    pattern: string[];
    phonePattern: string[];
    phoneCombs: string | undefined;
    sort: string;
    Service: PhoneService;
    isFavorite: boolean;
    setIsFavorite: React.Dispatch<React.SetStateAction<boolean>>;
    setType: React.Dispatch<React.SetStateAction<string[]>>;
    setTariff: React.Dispatch<React.SetStateAction<string[]>>;
    setCategories: React.Dispatch<React.SetStateAction<string[]>>;
    setRegion: React.Dispatch<React.SetStateAction<string[]>>;
    setPattern: React.Dispatch<React.SetStateAction<string[]>>;
    setAllIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
    setPhone: React.Dispatch<React.SetStateAction<string | undefined>>;
    setPhonePattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhoneCombs: React.Dispatch<React.SetStateAction<string | undefined>>;
}
