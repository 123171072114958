import {useContext} from "react";
import _ from "lodash";
import {observer} from "mobx-react";
import {IItems} from "../../../core/models/Notifications";
import {Button} from "@bezlimit/bezlimit-ui";
import {t} from "i18next";
import {InterceptorContext} from "../../../core/Contexts";
import BellNotificationsService from "../../../services/BellNotificationsService";

interface INotificationsDescription {
    NotifyNotifications: IItems[];
    pageSize: number;
    clickPageSizeCallback: () => void;
}

export default observer(function FooterNotificationsDescription({
    NotifyNotifications,
    pageSize,
    clickPageSizeCallback
}: INotificationsDescription) {
    const context = useContext(InterceptorContext);

    return (
        <>
            {!BellNotificationsService.items.length && (
                <div className="notifications-page-wrapper-notifications-description-headerTopNotifyNotifications">
                    {context?.loadingBtn
                        ? t("Уведомлений на номере нет")
                        : t("Информация о уведомлениях загружается")}
                </div>
            )}
            {!_.isUndefined(NotifyNotifications) && !!BellNotificationsService.items.length && (
                <Button
                    className="table-button-paginator"
                    onClick={clickPageSizeCallback}
                    disabled={_.gte(BellNotificationsService.items.length, pageSize)}
                >
                    {_.gt(pageSize, BellNotificationsService.items.length)
                        ? t("Показать ещё")
                        : t("Больше уведомлений нет")}
                </Button>
            )}
        </>
    );
});
