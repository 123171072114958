import {Button, Icon, Typography} from "@bezlimit/bezlimit-ui";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import _ from "lodash";
import {useEffect} from "react";

export default observer(function News({newsCount}: IProps) {
    const navigate = useNavigate();

    const click = () => {
        navigate("/news");
    };

    useEffect(() => {
        if (!_.isEqual(newsCount, 0)) {
            document.querySelector(".bl-news")?.classList.add("bl-news__move-shake");
            setTimeout(() => {
                document.querySelector(".bl-news")?.classList.remove("bl-news__move-shake");
            }, 3000);
        }
    }, [newsCount]);

    return (
        <Typography as="div" className="bl-news">
            <Button
                className="btn-default"
                onClick={() => {
                    click();
                }}
                icon={<Icon className="bl-news__icon" name="news_horn" />}
            />
            {!_.isEqual(newsCount, 0) && (
                <Typography className="bl-news__red-circle" as={"div"}>
                    {newsCount}
                </Typography>
            )}
        </Typography>
    );
});

interface IProps {
    newsCount: number;
}
