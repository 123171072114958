import {makeAutoObservable} from "mobx";

class Controls {
    [Symbol.toStringTag] = this.constructor.name;
    private _chatOpened: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    get chatOpened() {
        return this._chatOpened;
    }

    set chatOpened(value: boolean) {
        this._chatOpened = value;
    }
}

export default Controls;