import React, {useEffect} from "react";
import {Row, Col, Typography, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {isDesktop} from "react-device-detect";
import {useNavigate} from "react-router-dom";
import "./style.scss";
import _ from "lodash";
import Loading from "../../../blocks/ui/loading";

export default function NotFound() {
    const {t} = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        if (_.includes(window.location.pathname, "/l/phones")) {
            window.location.href = "https://store-old.bezlimit.ru/l/phones";
        }

        !localStorage.getItem("auth-token") && navigate("/");
    }, []);

    return (
        <>
            {_.includes(window.location.pathname, "/l/phones") ? (
                <Loading />
            ) : (
                <Row justify="center" gutter={32}>
                    <Col lg={{span: 24}} xs={{span: 24}}>
                        <Row justify="center" style={{textAlign: "center"}}>
                            <Col span={24}>
                                <div className="bl-page-title">
                                    <Space direction="vertical" align="center" size={24}>
                                        <div className="error-not-found">
                                            <Typography className="title">
                                                {t("Произошла ошибка")}
                                            </Typography>
                                            <Typography className="error-code">
                                                {isDesktop ? t("404 404 404") : t("404")}
                                            </Typography>
                                            <Typography className="description">
                                                {t(
                                                    "Такой страницы нет, но раз уж вы ее видите, вы можете перейти на "
                                                )}
                                                <a
                                                    href="/"
                                                    className="link link-white-gold transition"
                                                >
                                                    {t("главную")}
                                                </a>
                                            </Typography>
                                        </div>
                                    </Space>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            )}
        </>
    );
}
