import {useContext} from "react";
import {Space, Title, Typography, Back} from "@bezlimit/bezlimit-ui";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import _ from "lodash";
// core
import {LayoutContext} from "../../../core/Contexts";
//components
import IsRegisteredPhone from "../phoneNumberRegister";
// hooks
import useSearchRoute from "../../../core/hooks/useSearchRoute";
// services
import UserService from "../../../services/UserService";

export default function RegisterStorePhone() {
    const context = useContext(LayoutContext);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const route = useSearchRoute(UserService.token);

    return (
        <Typography as="div" className="bl-reset-password">
            <Space
                size={_.gte(context?.width ?? 0, 768) ? 122 : 40}
                direction="vertical"
                align="center"
            >
                <Typography as="div">
                    <Typography as="div">
                        <Back navigate={() => navigate("/register-store")}>{""}</Back>
                    </Typography>
                    <Space size={16} direction="vertical" align="center">
                        <Title title={_.get(route, "title")}/>
                        <Typography size={14}>
                            {t(
                                "Введите свой номер мобильного телефона для регистрации в сервисе Store"
                            )}
                        </Typography>
                    </Space>
                </Typography>
                <IsRegisteredPhone />
            </Space>
        </Typography>
    );
}
