import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {Form, Image, Typography, Button, Space} from "@bezlimit/bezlimit-ui";
import {FormItemProps, Upload, UploadProps, FormInstance} from "antd";
import {RcFile, UploadChangeParam, UploadFile} from "antd/lib/upload";
import {PlusOutlined} from "@ant-design/icons";
import _ from "lodash";
import {makeAutoObservable} from "mobx";
import "./style.scss";
// core
import {LayoutContext} from "../../../core/Contexts";
//store
import Store from "../../../stores";
import {observer} from "mobx-react";

type TPassportImageUrl = {
    photosFirst: string;
    photosSecond: string;
};

export const passportImageUrl: TPassportImageUrl = makeAutoObservable({
    photosFirst: "",
    photosSecond: ""
});

export default observer(function UploadField({name, title, form, props}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();
    const [loading, setLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState<string>();

    const getBase64 = async (img: RcFile, callback: (url: string) => void) => {
        try {
            const reader = new FileReader();
            reader.addEventListener("load", () => callback(reader.result as string));
            reader.readAsDataURL(img);
        } catch (e) {
            onCancel();
        }
    };

    const handleChange: UploadProps["onChange"] = async (info: UploadChangeParam<UploadFile>) => {
        setLoading(true);
        getBase64(info.file.originFileObj as RcFile, (url) => {
            if (_.isEqual(name, "passport")) {
                passportImageUrl.photosFirst = url;
            } else if (_.isEqual(name, "passport_second")) {
                passportImageUrl.photosSecond = url;
            }
            setLoading(false);
            setImageUrl(url);
        });
    };

    const normFile = (e: any) => {
        return Array.isArray(e) ? e : [e?.file];
    };

    const customRequest = async (options: any) => {};

    const onCancel = () => {
        setLoading(false);
        setImageUrl(undefined);
        form.setFieldsValue({
            [name]: undefined
        });
        if (_.isEqual(name, "passport")) {
            passportImageUrl.photosFirst = "";
        } else if (_.isEqual(name, "passport_second")) {
            passportImageUrl.photosSecond = "";
        }
    };

    useEffect(() => {
        if (_.isEqual(name, "passport")) {
            passportImageUrl.photosFirst = Store.reservations?.photosFirst;
            setImageUrl(Store.reservations?.photosFirst);
        } else if (_.isEqual(name, "passport_second")) {
            passportImageUrl.photosSecond = Store.reservations?.photosSecond;
            setImageUrl(Store.reservations?.photosSecond);
        }
    }, [Store.reservations?.photosFirst]);

    return (
        <Typography as="div" className="field-upload-container">
            {imageUrl && (
                <Button type="text" className="plus remove" onClick={onCancel}>
                    <PlusOutlined />
                </Button>
            )}

            <Form.Item
                {...props}
                valuePropName="fileList"
                getValueFromEvent={normFile}
                className={`field-upload${imageUrl ? " preview" : ""}`}
                name={name}
            >
                <Upload
                    accept="image/jpeg,image/png"
                    listType="picture-card"
                    customRequest={customRequest}
                    onChange={handleChange}
                >
                    {imageUrl ? (
                        <Typography as="div" className="preview-container">
                            <Image preview={false} src={imageUrl} />
                        </Typography>
                    ) : (
                        <Typography as="div">
                            <Space
                                direction="vertical"
                                size={_.gte(contextLayout?.width ?? 0, 768) ? 22 : 16}
                            >
                                <Button loading={loading} type="text" className="plus">
                                    <PlusOutlined />
                                </Button>

                                <Typography as="div" color="#fff" size={14} className="title">
                                    {t(title)}
                                </Typography>
                            </Space>
                        </Typography>
                    )}
                </Upload>
            </Form.Item>
        </Typography>
    );
});

interface IProps {
    name: string;
    title: string;
    form: FormInstance<any>;
    props?: FormItemProps<any>;
}
