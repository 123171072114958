import {Col, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import {useTranslation} from "react-i18next";
// forms
import NewDeliveryForm from "../../../forms/newDelivery";

export default function NewDelivery() {
    const {t} = useTranslation();
    return (
        <Typography as="div" className="bl-new-delivery">
            <Row>
                <Space direction="vertical">
                    <Col span={24}>
                        <Typography size={24} className="title-text">
                            {t("Оформление доставки")}
                        </Typography>
                    </Col>
                    <Col span={24}>
                        <Typography
                            color="rgba(255, 255, 255, 0.5)"
                            size={14}
                            className="sub-title-text"
                        >
                            {t(
                                "Вы можете заказать доставку сим-карт (пустышек) и конвертов с описанием тарифных планов, телефонами службы поддержки, короткими USSD командами и другой полезной информацией для абонентов."
                            )}
                        </Typography>
                    </Col>
                    <NewDeliveryForm />
                </Space>
            </Row>
        </Typography>
    );
}
