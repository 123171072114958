import {Typography, Row, Col, Skeleton} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import "./style.scss";

export default observer(function ActivationShimmer() {
    return (
        <>
            <div className="acnivation-shimmer">
                <Typography as="div" className="list-activation">
                    <Row className="row-group-btn-close" gutter={[8, 8]}>
                        <Col sm={4} xs={12} className="col-back">
                            <Skeleton.Button active={true} className="btn-skeleton-back" />
                        </Col>
                        <Col sm={4} xs={12} className="col-back">
                            <Skeleton.Button active={true} className="btn-skeleton-region" />
                        </Col>
                        <Col sm={4} xs={0} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-close" />
                        </Col>
                        <Col sm={8} xs={0} className="col-close"></Col>
                        <Col sm={4} xs={24} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-close" />
                        </Col>
                    </Row>
                </Typography>
            </div>
            <div className="acnivation-shimmer">
                <Typography as="div" className="list-activation">
                    <Row className="row-group-btn-close" gutter={[8, 8]}>
                        <Col sm={4} xs={12} className="col-back">
                            <Skeleton.Button active={true} className="btn-skeleton-back" />
                        </Col>
                        <Col sm={4} xs={12} className="col-back">
                            <Skeleton.Button active={true} className="btn-skeleton-region" />
                        </Col>
                        <Col sm={4} xs={0} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-close" />
                        </Col>
                        <Col sm={8} xs={0} className="col-close"></Col>
                        <Col sm={4} xs={24} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-close" />
                        </Col>
                    </Row>
                </Typography>
            </div>
            <div className="acnivation-shimmer">
                <Typography as="div" className="list-activation">
                    <Row className="row-group-btn-close" gutter={[8, 8]}>
                        <Col sm={4} xs={12} className="col-back">
                            <Skeleton.Button active={true} className="btn-skeleton-back" />
                        </Col>
                        <Col sm={4} xs={12} className="col-back">
                            <Skeleton.Button active={true} className="btn-skeleton-region" />
                        </Col>
                        <Col sm={4} xs={0} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-close" />
                        </Col>
                        <Col sm={8} xs={0} className="col-close"></Col>
                        <Col sm={4} xs={24} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-close" />
                        </Col>
                    </Row>
                </Typography>
            </div>
            <div className="acnivation-shimmer">
                <Typography as="div" className="list-activation">
                    <Row className="row-group-btn-close" gutter={[8, 8]}>
                        <Col sm={4} xs={12} className="col-back">
                            <Skeleton.Button active={true} className="btn-skeleton-back" />
                        </Col>
                        <Col sm={4} xs={12} className="col-back">
                            <Skeleton.Button active={true} className="btn-skeleton-region" />
                        </Col>
                        <Col sm={4} xs={0} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-close" />
                        </Col>
                        <Col sm={8} xs={0} className="col-close"></Col>
                        <Col sm={4} xs={24} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-close" />
                        </Col>
                    </Row>
                </Typography>
            </div>
            <div className="acnivation-shimmer">
                <Typography as="div" className="list-activation">
                    <Row className="row-group-btn-close" gutter={[8, 8]}>
                        <Col sm={4} xs={12} className="col-back">
                            <Skeleton.Button active={true} className="btn-skeleton-back" />
                        </Col>
                        <Col sm={4} xs={12} className="col-back">
                            <Skeleton.Button active={true} className="btn-skeleton-region" />
                        </Col>
                        <Col sm={4} xs={0} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-close" />
                        </Col>
                        <Col sm={8} xs={0} className="col-close"></Col>
                        <Col sm={4} xs={24} className="col-close">
                            <Skeleton.Button active={true} className="btn-skeleton-close" />
                        </Col>
                    </Row>
                </Typography>
            </div>
        </>
    );
});
