// store
import Store from "../stores";

class ControlsService {
    [Symbol.toStringTag] = this.constructor.name;
    private static readonly controls = Store.controls;

    static get chatOpened() {
        return ControlsService.controls.chatOpened;
    }

    static setChatOpened(state: boolean) {
        ControlsService.controls.chatOpened = state;
    }
}

export default ControlsService;