import {FormInstance} from "antd";
import {useContext, useEffect} from "react";
// core
import {InterceptorContext, ModalContext} from "../Contexts";

export default function useOnCancel(form: FormInstance<any>) {
    const context = useContext(InterceptorContext);
    const contextModal = useContext(ModalContext);

    const onCancel = () => {
        contextModal?.setVisible(false);
        context?.setErrors(undefined);
        form.resetFields();
    };

    useEffect(() => {
        context?.setErrors(undefined);
        contextModal?.setOnCancel(() => onCancel);

        return () => {
            contextModal?.setOnCancel(() => () => {
                form.resetFields();
                contextModal.setVisible(false);
            });
        };
    }, []);

    return onCancel;
}
