import moment from "moment";
import {SetStateAction} from "react";
import {IItems} from "../../../core/models/Notifications";
import {AppstoreOutlined, BarsOutlined} from "@ant-design/icons";
import _ from "lodash";
import {Space} from "antd";
import {Typography} from "@bezlimit/bezlimit-ui";

const arrayIconFilter = ["LineFilter", "СubeFilter"];

export const switchNotifyNotificationsIndex = (sended_at: string, switchIndex: number) => {
    switch (switchIndex) {
        case 0:
            return (
                moment(sended_at).isAfter(moment().subtract(1, "days")) &&
                _.isEqual(moment().format("YYYY-MM-DD"), moment(sended_at).format("YYYY-MM-DD"))
            );
        case 1:
            return (
                moment(sended_at).isAfter(moment().subtract(7, "days")) &&
                !moment(sended_at).isAfter(moment().subtract(0, "days")) &&
                !_.isEqual(moment().format("YYYY-MM-DD"), moment(sended_at).format("YYYY-MM-DD"))
            );
        case 2:
            return moment(sended_at).isBefore(moment().subtract(8, "days"));
        default:
            break;
    }
};

export const funcFilterDescription = (name: string) => {
    switch (name) {
        case "LineFilter":
            return "notifications-page-wrapper-notifications-descriptionLineFilter";
        case "СubeFilter":
            return "notifications-page-wrapper-notifications-descriptionСubeFilter";
        default:
            break;
    }
};

export const funcIconSwitchIndex = (
    switchIndex: number,
    setIconSwitchIndexCallback: (x: SetStateAction<string>) => void,
    IconSwitchIndex: string
) => {
    return (
        switchIndex === 0 && (
            <div>
                {arrayIconFilter.map(
                    (name, index) =>
                        (index === 0 && (
                            <BarsOutlined
                                key={name}
                                onClick={() => setIconSwitchIndexCallback(name)}
                                className={
                                    IconSwitchIndex === `${name}`
                                        ? "switchIndexNotifications"
                                        : undefined
                                }
                            />
                        )) ||
                        (index === 1 && (
                            <AppstoreOutlined
                                key={name}
                                onClick={() => setIconSwitchIndexCallback(name)}
                                className={
                                    IconSwitchIndex === `${name}`
                                        ? "switchIndexNotifications"
                                        : undefined
                                }
                            />
                        ))
                )}
            </div>
        )
    );
};

export const funcNotifyNotificationsMap = (
    NotifyNotifications: IItems[],
    switchIndex: number,
    pageSize: number
) => {
    return NotifyNotifications.filter((i, k) => _.lte(k, pageSize)).map((item, index) => {
        return (
            switchNotifyNotificationsIndex(item.created_at, switchIndex) && (
                <div
                    className={`notifications-page-wrapper-notifications-description-item ${
                        !item.is_read && "red-circle-border"
                    }`}
                    key={index}
                >
                    {!item.is_read && <Typography className="red-circle" as={"div"}></Typography>}
                    <Space size={32} direction={"vertical"}>
                        <div className="notifications-page-wrapper-notifications-description-title">
                            {item.title}
                        </div>
                        <Typography
                            as="span"
                            className="notifications-page-wrapper-notifications-description-more"
                        >
                            {item.text}
                        </Typography>
                    </Space>
                    <div className="notifications-page-wrapper-notifications-description-moreDetailed">
                        {
                            moment(item.created_at).format("DD.MM.YYYY HH:mm").split(" ")[
                                switchIndex === 0 ? 1 : 0
                            ]
                        }
                    </div>
                </div>
            )
        );
    });
};
