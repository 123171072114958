import {useContext, useState} from "react";
import {Space, Title, Typography, Back} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// forms
import NewPasswordForm from "../../forms/newPassword";
import EmailText from "../../forms/newPassword/emailText";
import {registerStoreContext} from "../../forms/registerStore";
//modal
import CodeConfirmationFormPhone from "../../modal/codeConfirmationPhone";
// ui
import Card from "../ui/card";
//services
import UserService from "../../../services/UserService";
// core
import {LayoutContext, UserContext} from "../../../core/Contexts";
// hooks
import useSearchRoute from "../../../core/hooks/useSearchRoute";

export default observer(function NewPassword() {
    const context = useContext(LayoutContext);
    const contexUser = useContext(UserContext);
    const [IsRegisteredPhone, setIsRegisteredPhone] = useState(false);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const route = useSearchRoute(UserService.token);

    const onSubmit = async ({code, phone}: any) => {
        if (code) {
            if (contexUser?.email === "IsRegisteredPhone") {
                const requestValues = {
                    name:
                        registerStoreContext?.firstName! +
                        " " +
                        registerStoreContext?.lastName +
                        " " +
                        registerStoreContext?.patronymic,
                    phone: phone,
                    code: code,
                    ...(registerStoreContext.ref_id ? {ref_id: registerStoreContext.ref_id} : {})
                };
                setIsRegisteredPhone(true);
                UserService.getUsersRegister(requestValues).then(() => {
                    contexUser?.setEmail("setPhone");
                    (window as any).ym(90016982, "reachGoal", "FormSend_kod");
                });
            } else {
                await UserService.putRestorePassword({
                    phone:
                        phone.replace(/\+/g, "").length === 11
                            ? phone.replace(/\+/g, "").substring(1)
                            : phone.replace(/\+/g, ""),
                    code: code
                });
                contexUser?.setEmail("setPhone");
            }
        }
    };

    if (
        (contexUser?.phone && contexUser?.email !== "setPhone") ||
        (registerStoreContext?.phone && contexUser?.email !== "setPhone")
    ) {
        return (
            <Typography as="div" className="bl-new-password">
                <Space
                    size={_.gte(context?.width ?? 0, 768) ? 48 : 24}
                    direction="vertical"
                    align="center"
                >
                    <Typography as="div">
                        <Back
                            navigate={() =>
                                navigate(
                                    registerStoreContext?.phone
                                        ? "/register-store-phone"
                                        : "/reset-password"
                                )
                            }
                        >
                            {""}
                        </Back>
                        <Space size={16} direction="vertical" align="center">
                            <Title className="bl-title" title={"Введите код"} />
                            <Typography as="div">
                                <Typography size={14}>
                                    {t("Вам отправлен код по SMS на указанный номер")}
                                    <br />
                                    {t(
                                        "После нажатия на кнопку «Подтвердить» Вам поступит новый пароль по SMS"
                                    )}
                                </Typography>
                            </Typography>
                            <Typography size={24} className="gold" fontFamily="alumna">
                                {_.isUndefined(contexUser?.phone)
                                    ? registerStoreContext?.phone
                                    : contexUser?.phone}
                            </Typography>
                        </Space>
                    </Typography>
                    <Card theme="blured">
                        <CodeConfirmationFormPhone
                            phone={
                                _.isUndefined(contexUser?.phone)
                                    ? registerStoreContext?.phone
                                    : contexUser?.phone
                            }
                            onSubmit={onSubmit}
                        />
                    </Card>
                </Space>
            </Typography>
        );
    }

    return (
        <Typography as="div" className="bl-new-password">
            <Space
                size={_.gte(context?.width ?? 0, 768) ? 48 : 24}
                direction="vertical"
                align="center"
            >
                <Typography as="div">
                    <Back navigate={() => navigate("/reset-password")}>{""}</Back>
                    <Space size={16} direction="vertical" align="center">
                        <Title className="bl-title" title={_.get(route, "title")} />
                        <Typography size={24} className="gold" fontFamily="alumna">
                            {IsRegisteredPhone
                                ? t("Вы успешно зарегистрированы!")
                                : t("Спасибо, что вернулись!")}
                        </Typography>
                        <Typography as="div">
                            {_.gte(context?.width ?? 0, 768) ? (
                                <Typography size={14}>
                                    {t("Теперь вы снова можете пользоваться личным кабинетом")}
                                    <br />
                                    {IsRegisteredPhone
                                        ? t('Партнеров и дилеров Безлимит! - "Store"')
                                        : t(
                                              'Партнеров и дилеров Безлимит! - "Мир красивых номеров"'
                                          )}
                                </Typography>
                            ) : (
                                <EmailText />
                            )}
                        </Typography>
                    </Space>
                </Typography>
                <Card theme="blured">
                    <NewPasswordForm />
                </Card>
            </Space>
        </Typography>
    );
});
