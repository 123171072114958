import React, {useContext, useEffect} from "react";
import {Block, Button, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {observer} from "mobx-react";
import "./style.scss";
// core
import {LayoutContext} from "../../../../core/Contexts";
// components
import {useNavigate} from "react-router-dom";
import ReservationsService from "../../../../services/ReservationsService";

export default React.memo(
    observer(function MyBooking() {
        const contextLayout = useContext(LayoutContext);
        const navigate = useNavigate();
        const {t} = useTranslation();

        const onClick = () => {
            navigate("/reservation");
        };

        useEffect(() => {
            ReservationsService.getReservationsSummary();
        }, []);

        const lineFunction = (text: string, number: number) => {
            return (
                <Typography as="div" className="bl-my-dealers-text">
                    <Typography
                        color="rgba(255, 255, 255, 0.5)"
                        size={_.gte(contextLayout?.width, 768) ?? 0 ? 17 : 14}
                    >
                        {text}
                    </Typography>
                    <Typography as="span" size={_.gte(contextLayout?.width, 768) ?? 0 ? 17 : 14}>
                        {number}
                    </Typography>
                </Typography>
            );
        };

        return (
            <Typography as="div" className="bl-my-dealers">
                <Block title={t("Забронированные номера")}>
                    {lineFunction(t("Сегодня"), ReservationsService.summary.today)}
                    {lineFunction(t("Вчера"), ReservationsService.summary.yesterday)}
                    {lineFunction(t("За все время"), ReservationsService.summary.total)}
                    <Button type="primary" onClick={onClick}>
                        {t("Брони")}
                    </Button>
                </Block>
            </Typography>
        );
    })
);
