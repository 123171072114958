import {Row, Col} from "@bezlimit/bezlimit-ui";
import "./style.scss";
// components
import ResetPassword from "../../../blocks/resetPassword";
// hooks
import usePuthState from "../../../../core/hooks/usePushState";

export default function ResetPasswordPage() {
    usePuthState();

    return (
        <Row justify="center" gutter={32}>
            <Col lg={{span: 24}} xs={{span: 24}}>
                <ResetPassword />
            </Col>
        </Row>
    );
}
