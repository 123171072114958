import React, {useContext} from 'react';
import _ from "lodash";
import successImgW1x from "../../../images/success@1x.webp";
import successImgW2x from "../../../images/success@2x.webp";
import successImg2x from "../../../images/success@2x.png";
import successImg from "../../../images/success@1x.png";
//core
import { LayoutContext } from '../../../core/Contexts';

const SuccessImg = ({alt='success', className='', ...delegated}) => {
    const context = useContext(LayoutContext);
    const width = _.gte(context?.width ?? 0, 768) ? 102 : 89;


    return(
        <picture>
            <source type='image/webp' srcSet={`${successImgW1x} 1x, ${successImgW2x} 2x`}/>
            <img src={successImg} width={width} srcSet={`${successImg2x} 2x`} alt={alt} className={className} {...delegated}/>
        </picture>
    );
}

export default SuccessImg;
