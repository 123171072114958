import {Button, Icon, Space, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {observer} from "mobx-react";
import "./style.scss";

import img from "../../../../../images/exclem.png";
import backPng from "../../../../../images/back-callection.png";
import {useNavigate} from "react-router";
import {useContext} from "react";
import {InterceptorContext, ModalContext} from "../../../../../core/Contexts";
import UserService from "../../../../../services/UserService";

export default observer(function Error({onCancel}: IProps) {
    const contextLayout = window.screen.width;
    const context = useContext(InterceptorContext);
    const navigate = useNavigate();
    const contextModal = useContext(ModalContext);

    const onClick = () => {
        !UserService.token ? navigate("/") : navigate("/home");
        contextModal?.setVisible(false);
    };

    return (
        <Typography as="div" className="modal-error-promised">
            <Button className="close-btn" onClick={onCancel}>
                <Icon name={"close"} size={"26px"} />
            </Button>
            <Button onClick={onClick} className="back-arrow">
                <img src={backPng} alt="" width={28} /> На главную
            </Button>
            <Space direction="vertical" size={[8, 16]}>
                <img src={img} alt="" width={88} />
                <Typography
                    as="div"
                    className="title-top"
                    size={_.gte(contextLayout, 768) ? 48 : 32}
                    fontFamily="alumna"
                >
                    {"Ошибка"}
                </Typography>
                {_.chain(context?.data)
                    .map(({name, message}) => (
                        <Typography key={name} className="description-error">
                            {message}
                        </Typography>
                    ))
                    .valueOf()}
                {_.lte(contextLayout, 431) && (
                    <Button type="primary" onClick={onCancel} className="button-close">
                        Закрыть
                    </Button>
                )}
            </Space>
        </Typography>
    );
});

interface IProps {
    onCancel: () => void;
}
