import React, {useContext} from "react";
import {Space, Typography, Block} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
import "./style.scss";
//images
import ErrorImg from "../../../images/errorImg";
// core
import {LayoutContext} from "../../../../core/Contexts";
import {Button} from "antd";

export default React.memo(function EmptyResult({title}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/numbers");
    };

    return (
        <Block className="bl-empty-result">
            <Space direction="vertical" size={40}>
                <ErrorImg />
                <Typography
                    level={2}
                    className="title"
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                    color="#fff"
                >
                    {title}
                </Typography>
                <Typography
                    as="div"
                    className="text"
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 17 : 14}
                    color="rgba(255, 255, 255, 0.5)"
                >
                    {t("Перейдите в “Базу номеров” для выбора номера")}
                </Typography>
                <Button
                    type={_.gte(contextLayout?.width ?? 0, 768) ? "default" : "primary"}
                    onClick={onClick}
                >
                    {t(
                        _.gte(contextLayout?.width ?? 0, 768)
                            ? "Перейти в Базу номеров"
                            : "База номеров"
                    )}
                </Button>
            </Space>
        </Block>
    );
});

interface IProps {
    title: string;
}
