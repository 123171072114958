import {Form, Icon, Space, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import "./style.scss";
import {useContext, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
//core
import useFormHandler from "../../../../../../../core/hooks/useFormHandler";
//services
import NumbersFiltersStoreService from "../../../../../../../services/NumbersFiltersStoreService";
//fields
import RadioSaveField from "../../../../../../fields/radioSave";
import {LayoutContext} from "../../../../../../../core/Contexts";
import PhonesFiltersService from "../../../../../../../services/PhonesFiltersService";

export default observer(function MyFilterSets(props: IProps) {
    const {t} = useTranslation();
    const ref = useRef(null);
    const [form] = Form.useForm();
    const formInstance = useFormHandler(form);
    const contextLayout = useContext(LayoutContext);

    const onValuesChange = (value: any, allValues: any) => {
        _.chain(NumbersFiltersStoreService.items)
            .find((i, k) => _.isEqual(k, value.filter))
            .valueOf();
    };

    useEffect(() => {
        const current = NumbersFiltersStoreService.current;
        formInstance.setFieldValue("filter", current);

        if (!_.isNil(current)) {
            PhonesFiltersService.setCurrentFilter({
                ...NumbersFiltersStoreService.items[current].values,
                grid: PhonesFiltersService.currentFilter.grid,
                sort: PhonesFiltersService.currentFilter.sort
            });
        }
    }, [NumbersFiltersStoreService.current]);

    return (
        <div ref={ref}>
            <Typography as="div" className="bl-filter-sets">
                <Form
                    form={formInstance}
                    layout="horizontal"
                    name="filters-sets"
                    onValuesChange={onValuesChange}
                    autoComplete="off"
                    initialValues={{
                        filter: NumbersFiltersStoreService.current
                    }}
                >
                    {_.lte(contextLayout?.width ?? 0, 768) ? (
                        <Typography as="div" className="titlt-filter-sets-mobile"></Typography>
                    ) : (
                        <Space direction="horizontal" size={9}>
                            <Icon name="Save" size="14" />
                            <Typography>{t("Мои наборы фильтров")}</Typography>
                        </Space>
                    )}
                    <RadioSaveField
                        formInstance={formInstance}
                        items={NumbersFiltersStoreService.items}
                        name={"filter"}
                        {...props}
                    />
                </Form>
            </Typography>
        </div>
    );
});

interface IProps {
    phonePattern: string[];
    phoneCombs: string | undefined;
    setType: React.Dispatch<React.SetStateAction<string[]>>;
    setTariff: React.Dispatch<React.SetStateAction<string[]>>;
    setCategories: React.Dispatch<React.SetStateAction<string[]>>;
    setRegion: React.Dispatch<React.SetStateAction<string[]>>;
    setPattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhonePattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhoneCombs: React.Dispatch<React.SetStateAction<string | undefined>>;
    setTariffSize?: React.Dispatch<React.SetStateAction<number>>;
    setCategoriesSize?: React.Dispatch<React.SetStateAction<number>>;
    setRegionSize?: React.Dispatch<React.SetStateAction<number>>;
}
