import React, {useState, useEffect, useRef, useContext} from "react";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {Button, Space, Title, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
//component
import {SupportFaqList} from "./supportFaqList";
import WriteBossModal from "../../modal/writeBoss";
//core
import WidgetChartra from "../../../core/functions/widgetChartra";
import {LayoutContext, ModalContext} from "../../../core/Contexts";
// services
import UserService from "../../../services/UserService";
import ControlsService from "../../../services/ControlsService";
//store
import Store from "../../../stores";
//mock
import {SUPPORTFAQMOCK} from "../../../mock/support/get_support_faq";

import "./style.scss";

export const Support: React.FC = observer(() => {
    const contextLayout = useContext(LayoutContext);
    const contextModal = useContext(ModalContext);
    const timeout: any = useRef();
    const {t} = useTranslation();
    const mountedRef = useRef(true);
    const chatOpened = ControlsService.chatOpened;
    const [btnDisabled, setBtnDisabled] = useState(false);

    const onWrite = () => {
        contextModal?.setChildren(<WriteBossModal />);
        contextModal?.setCssClass(" bl-write-boss-modal-form");
        contextModal?.setVisible(true);
    };

    const SetChartra = async () => {
        if (!mountedRef.current) {
            return;
        }

        clearTimeout(timeout.current);
        setBtnDisabled(true);
        timeout.current = setTimeout(() => {
            setBtnDisabled(false);
        }, 3000);

        await Promise.all([UserService.getPersonalManager(), UserService.getDealer()]);
        WidgetChartra(document, window, "Chatra", Store.user);
    };

    const onClick = () => {
        ControlsService.setChatOpened(!chatOpened);
    };

    useEffect(() => {
        SetChartra();

        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        const chatra = (window as any).Chatra;
        if (typeof chatra !== "function") {
            return;
        }
        if (chatOpened) {
            chatra("show");
            chatra("openChat", true);
        } else {
            chatra("hide");
        }
    }, [chatOpened]);

    return (
        <div className="bl-support-page">
            <Title title="Поддержка" />
            <Typography className="support-description">
                {t(`Вся необходимая информация по личному кабинету дилера.
                Если вы не нашли то, что нужно, мы с радостью поможем вам в круглосуточном чате.`)}
            </Typography>
            <Space
                direction="vertical"
                align="center"
                size={_.gte(contextLayout?.width, 768) ?? 0 ? 120 : 80}
            >
                <Space direction="vertical" align="center" size={16} className="buttons">
                    <Button type={"primary"} onClick={onClick} disabled={btnDisabled} block>
                        {t("Чат с оператором")}
                    </Button>
                    <Button type="default" onClick={onWrite} block>
                        {t("Написать директору")}
                    </Button>
                </Space>
                <SupportFaqList list={SUPPORTFAQMOCK} />
            </Space>
        </div>
    );
});
