import React, {useContext, useEffect, useRef, useState} from "react";
import {Button, Space, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import Clipboard from "react-clipboard.js";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext} from "../../../../../core/Contexts";
import WidgetChartra from "../../../../../core/functions/widgetChartra";
// services
import UserService from "../../../../../services/UserService";
import ControlsService from "../../../../../services/ControlsService";
//store
import Store from "../../../../../stores";

export default React.memo(
    observer(function Buttons({isProfile}: IProps) {
        const timeout: any = useRef();
        const mountedRef = useRef(true);
        const contextLayout = useContext(LayoutContext);
        const chatOpened = ControlsService.chatOpened;

        const {t} = useTranslation();
        const [disabled, setDisabled] = useState(false);
        const [btnDisabled, setBtnDisabled] = useState(false);

        const SetChartra = async () => {
            if (!mountedRef.current) {
                return;
            }

            clearTimeout(timeout.current);
            setBtnDisabled(true);
            timeout.current = setTimeout(() => {
                setBtnDisabled(false);
            }, 3000);

            await Promise.all([
                isProfile ? UserService.getPersonalManager2() : UserService.getPersonalManager(),
                UserService.getDealer()
            ]);
            WidgetChartra(document, window, "Chatra", Store.user);
            setBtnDisabled(false);
        };

        useEffect(() => {
            setBtnDisabled(true);
            SetChartra();
            return () => {
                mountedRef.current = false;
            };
        }, []);

        useEffect(() => {
            const chatra = (window as any).Chatra;
            if (typeof chatra !== "function") {
                return;
            }
            if (chatOpened) {
                chatra("show");
                chatra("openChat", true);
            } else {
                chatra("hide");
            }
        }, [chatOpened]);

        const onClick = () => {
            (
                window.open(`https://wa.me/7${UserService.personalManager.phone}`, "_blank") as any
            ).focus();
        };

        const onSuccess = () => {
            clearTimeout(timeout.current);
            setDisabled(true);
            timeout.current = setTimeout(() => {
                setDisabled(false);
            }, 5000);
        };

        const onChat = () => {
            ControlsService.setChatOpened(!chatOpened);
        };

        return (
            <Typography as="div" className="bl-personal-manager-buttons">
                <Space size={_.gte(contextLayout?.width, 768) ?? 0 ? 12 : 16} direction="vertical">
                    {UserService.hasPersonalManager && (
                        <Button onClick={onClick} type="primary">
                            {t("Написать в WhatsApp")}
                        </Button>
                    )}

                    {UserService.hasPersonalManager ? (
                        <Button type="default" disabled={disabled} className="copy">
                            <Clipboard
                                component="span"
                                data-clipboard-text={`+7${UserService.personalManager.phone}`}
                                onSuccess={onSuccess}
                            >
                                {t(disabled ? "Номер скопирован" : "Скопировать номер")}
                            </Clipboard>
                        </Button>
                    ) : (
                        <Button type="primary" onClick={onChat} disabled={btnDisabled}>
                            {t("Написать в поддержку")}
                        </Button>
                    )}
                </Space>
            </Typography>
        );
    })
);

interface IProps {
    isProfile?: boolean;
}
