import {Typography} from "@bezlimit/bezlimit-ui";
import React from "react";
import "./style.scss";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";

export default React.memo(function Total({title, sum}: IProps) {
    const {t} = useTranslation();

    return (
        <Typography
            as="div"
            className="accumulation-sum"
            size={17}
            color="rgba(255, 255, 255, 0.5)"
        >
            {t(title)}

            <Typography size={48} fontFamily="alumna" className="total-sum ">
                <NumberFormat
                    className="format-accumulated-sum"
                    thousandSeparator=" "
                    displayType="text"
                    value={sum.toFixed(!(sum % 1) ? 0 : 2)}
                />
            </Typography>
        </Typography>
    );
});

interface IProps {
    title: string;
    sum: number;
}
