import {Row, Col, Button} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";
// core
import {ITariffsAvailable} from "../../../../core/models/Tariffs";
// components
import Plane from "../plane";
// hooks
import {useContext, useEffect, useRef, useState} from "react";
//store
import {observer} from "mobx-react";
import {LayoutContext} from "../../../../core/Contexts";

let countIsSwap = {count: 0};

export default observer(function Desktop(props: Props[]) {
    const [countButton, setCountButton] = useState(0);
    const context = useContext(LayoutContext);
    const [isDisabledButton, setIsDisabledButton] = useState(false);
    const state = props;
    let tariffDesktop: HTMLDivElement | any = document.querySelector(
        ".modal-info-tariff-wrapper-description-desktop"
    );
    const divEl = useRef(null) as any;

    function calculateTileOffset() {
        let tileOffset;
        const width = context?.width ?? 0;

        if ((context?.width ?? 0) > 1450) {
            tileOffset = 730 - Math.floor((width - 1450) / 50) * 15;
        } else if ((context?.width ?? 0) > 1280) {
            tileOffset = 780 - Math.floor((width - 1280) / 50) * 15;
        } else if ((context?.width ?? 0) > 1000) {
            tileOffset = 670 - Math.floor((width - 1000) / 50) * 15;
        } else {
            tileOffset = 750 - Math.floor((width - 580) / 50) * 12;
        }

        return tileOffset;
    }

    const handlClickLeftButton = (tariffDesktop: HTMLDivElement, countIsSwap?: {count: number}) => {
        if (_.isEqual(countIsSwap!.count, 0) || isDisabledButton) return;
        countIsSwap!.count -= 1;
        setCountButton((pre) => pre - 1);
        tariffDesktop.style.left = tariffDesktop.offsetLeft + calculateTileOffset() + "px";
        handlClickRightDisabled();
    };

    const handlClickRightButton = (
        tariffDesktop: HTMLDivElement,
        countIsSwap?: {count: number}
    ) => {
        if (_.isEqual(countIsSwap!.count, (context?.width ?? 0) > 1280 ? 3 : 4) || isDisabledButton)
            return;
        countIsSwap!.count += 1;
        setCountButton((pre) => pre + 1);
        tariffDesktop.style.left = tariffDesktop.offsetLeft - calculateTileOffset() + "px";
        handlClickRightDisabled();
    };

    const handlClickRightDisabled = () => {
        setIsDisabledButton(true);
        setTimeout(() => {
            setIsDisabledButton(false);
        }, 1000);
    };

    useEffect(() => {
        countIsSwap.count = 0;
        let container: HTMLDivElement | any = document.getElementsByClassName(
            "modal-info-tariff-wrapper-description-desktop"
        )[0];
        let containerOut: HTMLDivElement | any = document.getElementsByClassName("bl-content")[0];
        let start: number;
        let current;
        let tariffDesktop: HTMLDivElement | any = document.querySelector(
            ".modal-info-tariff-wrapper-description-desktop"
        );
        tariffDesktop.style.left = tariffDesktop.offsetLeft + 10 + "px";
        container.addEventListener("mousedown", dragStart);

        function dragStart(e: {clientX: any}) {
            start = e.clientX;
            containerOut.addEventListener("mouseup", dragEnd);
        }

        function dragEnd(e: {clientX: any}) {
            current = e.clientX;
            let move = current - start;
            if (move > 0) {
                handlClickLeftButton(tariffDesktop, countIsSwap);
            } else if (move < 0) {
                handlClickRightButton(tariffDesktop, countIsSwap);
            }
            containerOut.removeEventListener("mouseup", dragEnd);
        }
    }, []);

    return (
        <>
            <div className="left-right-button">
                <div id="left-button">
                    <Button
                        type="text"
                        icon={<LeftOutlined />}
                        onClick={() => {
                            handlClickLeftButton(tariffDesktop, countIsSwap);
                        }}
                        disabled={_.isEqual(countButton, 0) || isDisabledButton}
                    />
                </div>
                <div id="right-button">
                    <Button
                        type="text"
                        icon={<RightOutlined />}
                        onClick={() => {
                            handlClickRightButton(tariffDesktop, countIsSwap);
                        }}
                        disabled={
                            _.isEqual(countButton, (context?.width ?? 0) > 1280 ? 3 : 4) ||
                            isDisabledButton
                        }
                    />
                </div>
            </div>
            <Row ref={divEl} className="modal-info-tariff-wrapper-description-desktop">
                {_.chain(state)
                    .map(({id, name, price, minutes, sms, internet, group_name}) => (
                        <Col key={id} className="modal-info-tariff-wrapper-description-item">
                            <Plane
                                group_name={group_name}
                                name={name}
                                subscription_fee={price}
                                packet_minutes={minutes}
                                packet_sms={sms}
                                packet_internet={internet}
                                isDesktop={true}
                            />
                        </Col>
                    ))
                    .valueOf()}
            </Row>
        </>
    );
});

interface IProps extends ITariffsAvailable {}

type Props = IProps;
