import {Button, Col, Icon, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import React, {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import "./style.scss";
// core
import {LayoutContext} from "../../../../core/Contexts";
import Store from "../../../../stores";
import ActivationModal from "../paymentReservedCard";
import UserCardService from "../../../../services/UserCardService";
import ModalBankCardsSaving from "../../profile/savingBankCards/modalBankCardsSaving";
import BonusesService from "../../../../services/BonusesService";

export default React.memo(
    observer(function ReplenichNumberBonuses() {
        const [isActivationModalVisible, setActivationModalVisible] = useState(false);
        const [hashSuccess, setHashSuccess] = useState(false);
        const contextLayout = useContext(LayoutContext);
        const {t} = useTranslation();
        const [isSavingModalVisible, setSavingModalVisible] = useState(false);
        const arrayHash = ["#already_added_url", "#card-add-fail"];

        const onClick = () => {
            UserCardService.fetchGetBankCard();
            setActivationModalVisible(true);
        };

        useEffect(() => {
            if (_.isEqual("#card-add-success", document.location.hash)) {
                UserCardService.fetchGetBankCard();
                setHashSuccess(true);
                setTimeout(() => {
                    setActivationModalVisible(true);
                }, 100);
            }
            !_.isEqual(
                arrayHash.filter((hash: string) => _.isEqual(hash, document.location.hash)).length,
                0
            ) && setSavingModalVisible(true);
        }, []);

        return (
            <>
                <Typography
                    as="div"
                    className={`bl-replenish-number-bonuses ${
                        !_.isNil(Store.user.loyalty) ? "" : " without-loyalty"
                    }`}
                >
                    <Button
                        disabled={
                            BonusesService.accumulated < 100 ||
                            _.isEqual(Store.user.personal_data?.passport_status, "reject")
                        }
                        onClick={onClick}
                    >
                        <Row>
                            <Space>
                                <Col>
                                    <Space direction="vertical" size={8}>
                                        <Typography
                                            className="block-title"
                                            size={_.gte(contextLayout?.width, 768) ?? 0 ? 17 : 14}
                                        >
                                            {t("Пополнить номер")}
                                        </Typography>
                                        <Typography
                                            color="rgba(255, 255, 255, 0.5)"
                                            size={14}
                                            className="text"
                                        >
                                            {_.isEqual(
                                                Store.user.personal_data?.passport_status,
                                                "reject"
                                            )
                                                ? t("Внесите паспортные данные")
                                                : t("От 100 бонусов на номер Безлимит")}
                                        </Typography>
                                    </Space>
                                </Col>
                                <Col>
                                    <Typography as="div" className="icon-vector">
                                        <Icon name={"arrowRight"} />
                                    </Typography>
                                </Col>
                            </Space>
                        </Row>
                    </Button>
                </Typography>
                <ActivationModal
                    hashSuccess={hashSuccess}
                    isMainPage={true}
                    visible={isActivationModalVisible}
                    cancelHandler={() => setActivationModalVisible(false)}
                />
                <ModalBankCardsSaving
                    visible={isSavingModalVisible}
                    cancelHandler={() => setSavingModalVisible(false)}
                />
            </>
        );
    })
);
