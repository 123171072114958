import React, {useContext, useRef} from "react";
import {Button, Col, Form, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import {InterceptorContext, LayoutContext, ModalContext} from "../../../core/Contexts";
import {getFieldsErrorSize} from "../../../core/functions/form";
import {IPayoutRequest} from "../../../core/models/Bonuses";
// services
import BonusesService from "../../../services/BonusesService";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
import useFieldFocus from "../../../core/hooks/useFieldFocus";
import useOnCancel from "../../../core/hooks/useOnCancel";
import useOnChange from "../../../core/hooks/useOnChange";
// components
import PhoneField from "../../fields/phone";
import Error from "../../modal/error";
import ReplenichBonusesField from "../../fields/replenichBonuses";
import Success from "../../modal/success";
import RadioButtonGroup from "./radioButtonGroup";
import CodeConfirmationReplenishBonuses from "../../modal/codeConfirmationReplenishBonuses";
import SubtitleSuccess from "./subtitleSuccess";
import {Rules} from "../rules";

export default React.memo(
    observer(function FormReplenishmentBonuses() {
        const context = useContext(InterceptorContext);
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const ref = useRef(null);
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        const onCancel = useOnCancel(formInstance);
        const onChange = useOnChange(formInstance);
        useFieldFocus(ref, "phone");

        const onChangeSumm = () => {
            const fields = form.getFieldsValue();
            fields.summ = form.getFieldsValue().replenichSum;
            form.setFieldsValue(fields);
        };

        const onSubmit = async ({code, phone, sum, id}: any) => {
            await BonusesService.postPayoutBonuses({
                code,
                phone,
                sum,
                id,
                type: "phone"
            });

            contextModal?.setChildren(
                <Success
                    title={<Typography as="span">{t("Номер успешно пополнен")}</Typography>}
                    phone={phone}
                    subtitle={<SubtitleSuccess sum={sum} />}
                />
            );
        };

        const onFinish = async ({phone: tel, summ: sum}: IPayoutRequest) => {
            const phone = _.replace(tel!, /(\+7|\s|-)/gi, "");
            try {
                const {id} = await BonusesService.postPayoutBonuses({phone, sum, type: "phone"});
                contextModal?.setChildren(
                    <CodeConfirmationReplenishBonuses
                        phone={phone}
                        id={id}
                        sum={sum}
                        type="phone"
                        onSubmit={onSubmit}
                    />
                );
                formInstance.resetFields();
            } catch (e: any) {
                contextModal?.setChildren(<Error phone={phone} />);
            }
        };

        const textBonusesService = () => {
            if (_.gte(BonusesService.accumulated, 1000) && BonusesService.accumulated < 5000) {
                return (
                    <Typography size={12} color="#828282">
                        {t(
                            "от 100 до " +
                                new Intl.NumberFormat("ru-RU").format(
                                    +Math.floor(BonusesService.accumulated)
                                ) +
                                " бонусов"
                        )}
                    </Typography>
                );
            } else if (_.gte(BonusesService.accumulated, 5000)) {
                return (
                    <Typography as="div" className="text-declination" size={12} color="#828282">
                        {t("от 100  до 5000 бонусов")}
                    </Typography>
                );
            } else if (
                !_.gte(BonusesService.accumulated, 1000) &&
                BonusesService.accumulated > 100
            ) {
                return (
                    <Typography as="div" className="text-declination" size={12} color="#828282">
                        {t(
                            "от 100 до " +
                                new Intl.NumberFormat("ru-RU").format(
                                    +Math.floor(BonusesService.accumulated)
                                ) +
                                " бонусов"
                        )}
                    </Typography>
                );
            } else if (
                !_.gte(BonusesService.accumulated, 1000) &&
                BonusesService.accumulated < 100
            ) {
                return (
                    <Typography as="div" className="text-declination" size={12} color="#828282">
                        {t("от 100  до 5000 бонусов")}
                    </Typography>
                );
            }
        };

        return (
            <div ref={ref}>
                <Typography as="div" className="bl-replenishment-bonuses-form">
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="ten-for-bezlimit"
                        onFinish={onFinish}
                        onFieldsChange={onChange}
                        autoComplete="off"
                        initialValues={{
                            phone: "",
                            summ: ""
                        }}
                    >
                        <Space
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 8 : 24}
                            direction="vertical"
                        >
                            <PhoneField />
                            <Space size={20} className="input-summ-bonuses">
                                <Row>
                                    <Col span={10}>
                                        <ReplenichBonusesField
                                            name="summ"
                                            form={form}
                                            pattern={Rules.regex(
                                                /^(10[0-9]|1[1-9]\d|[2-9]\d\d|1000)$/gi,
                                                ""
                                            )}
                                        />
                                    </Col>
                                    <Col span={14}>{textBonusesService()}</Col>
                                </Row>
                            </Space>
                            <Typography
                                as="div"
                                className="bl-radio-button-group"
                                onChange={onChangeSumm}
                            >
                                <RadioButtonGroup />
                            </Typography>

                            <Form.Item noStyle shouldUpdate>
                                {({getFieldsValue, getFieldsError}) => {
                                    const {phone, summ} = getFieldsValue();
                                    const errors = getFieldsErrorSize(getFieldsError);
                                    const isSumm = _.gte(summ, 100) && !_.gte(summ, 5001);
                                    const formIsComplete =
                                        !!phone &&
                                        !!summ &&
                                        !(_.replace(phone!, /(\+7|\s|-)/gi, "").length < 10) &&
                                        !errors &&
                                        isSumm;

                                    return (
                                        <Button
                                            type={!formIsComplete ? "default" : "primary"}
                                            htmlType="submit"
                                            disabled={!formIsComplete}
                                            loading={context?.loadingBtn}
                                        >
                                            {t("Пополнить")}
                                        </Button>
                                    );
                                }}
                            </Form.Item>

                            {_.lte(contextLayout?.width ?? 0, 768) && (
                                <Typography
                                    as="div"
                                    className="text-center cancel"
                                    size={14}
                                    color="#000"
                                >
                                    <Button type="text" onClick={onCancel}>
                                        {t("Отменить")}
                                    </Button>
                                </Typography>
                            )}
                        </Space>
                    </Form>
                </Typography>
            </div>
        );
    })
);
