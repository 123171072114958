import {makeAutoObservable} from "mobx";
import {ITariffsAvailable} from "../core/models/Tariffs";

class Tariffs {
    [Symbol.toStringTag] = this.constructor.name;
    private _items!: ITariffsAvailable[];

    constructor() {
        makeAutoObservable(this);
    }
    get items() {
        return this._items;
    }
    set items(value: ITariffsAvailable[]) {
        this._items = value;
    }
}

export default Tariffs;
