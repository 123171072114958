import React, {useCallback, useContext, useEffect, useState} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// forms
import SimForm from "../../forms/sim";
// core
import {LayoutContext, ModalContext} from "../../../core/Contexts";
// services
import ReservationsService from "../../../services/ReservationsService";
// components
import ErrorModal from "../error";

export default React.memo(
    observer(function SimModal({phone}: IProps) {
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const {t} = useTranslation();
        const [message, setMessage] = useState<string>();

        const postEasyDeliverySim = useCallback(async (phone: number) => {
            try {
                const {result, message} = await ReservationsService.postEasyDeliverySim(phone);

                if (!result) {
                    throw new Error(message);
                }

                setMessage(message);
            } catch (e: any) {
                contextModal?.setChildren(<ErrorModal subtitle={e.message} />);
            }
        }, []);

        useEffect(() => {
            postEasyDeliverySim(phone);
        }, []);

        return (
            <Typography as="div" className="bl-sim-modal">
                <Space
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 40 : 16}
                    direction="vertical"
                    align="center"
                >
                    <Typography as="div">
                        <Space size={8} direction="vertical" align="center">
                            <Typography
                                size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                                level={2}
                                color="#000000"
                            >
                                {t("Выдача SIM в Билайн")}
                            </Typography>
                            {message ? (
                                <Typography color="#000000" size={14} className="subtitle">
                                    {t(message)}
                                </Typography>
                            ) : null}
                        </Space>
                    </Typography>
                    <SimForm phone={phone} message={message} />
                </Space>
            </Typography>
        );
    })
);

interface IProps {
    phone: number;
}
