// store
import Store from "../stores";
// core
import {axiosFetchBuilder, IAxiosConfig} from "../core/Axios";
import {
    IBanksResponse,
    IPaySBPRequest,
    IPaySBPResponse,
    IPaymentStatusResponse
} from "../core/models/Bank";

enum Path {
    banks = "/sbp/banks",
    paySBP = "/sbp/order",
    paymentStatus = "/super-link/payment/status"
}

const TOKEN = "e7lNZteg1OqtjMyAMIRIxa4s1mvwwVztwDmBPcDBmhsoFq9X-oBYCzoHqgynekH_";

class BanksService {
    [Symbol.toStringTag] = this.constructor.name;
    private static readonly banks = Store.banks;

    static get token() {
        return BanksService.banks.token;
    }

    static get items() {
        return BanksService.banks.items;
    }

    static async getBanks() {
        BanksService.banks.token = process.env.REACT_APP_TOKEN_HEADER ?? TOKEN; //подстраховка

        if (BanksService.banks.token) {
            const res: IBanksResponse = await axiosFetchBuilder({
                url: Path.banks,
                loading: false,
                loadingBtn: false
            } as IAxiosConfig);
            BanksService.banks.items = res.items;
        }
    }

    static async postPaySBP(request: IPaySBPRequest) {
        BanksService.banks.token = process.env.REACT_APP_TOKEN_HEADER ?? TOKEN;

        if (BanksService.banks.token) {
            const res: IPaySBPResponse = await axiosFetchBuilder({
                url: Path.paySBP,
                method: "POST",
                data: {...request},
                loading: false,
                loadingBtn: true
            } as IAxiosConfig);
            return res;
        }
    }

    static async getPaymentStatus(payment_id: number) {
        BanksService.banks.token = process.env.REACT_APP_TOKEN_HEADER ?? TOKEN;

        if (BanksService.banks.token) {
            const res: IPaymentStatusResponse = await axiosFetchBuilder({
                url: Path.paymentStatus,
                params: {payment_id: payment_id},
                loading: false,
                loadingBtn: false
            } as IAxiosConfig);

            return res;
        }
    }
}

export default BanksService;
