import React, {useContext} from "react";
import {Icon, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext} from "../../../../../../../core/Contexts";
import date from "../../../../../../../core/functions/date";
import currency from "../../../../../../../core/functions/currency";

export default React.memo(function Status({status, activated_at, unblock_sum, pay_type}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();

    return (
        <Typography as="div" className="bl-activation-list-items-item-status">
            <Space direction="vertical" size={0}>
                <Space
                    direction="horizontal"
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 10 : 5}
                    align="center"
                    className="status-text"
                >
                    {_.isEqual(pay_type, "super_link") && (
                        <svg width="24" height="24">
                            <use href={"#super_link"} />
                        </svg>
                    )}
                    {_.isEqual(status, 1) ? (
                        <>
                            <Icon name="status_active" size="20" />
                            <Typography as="div" color="#fff" size={17}>
                                {t("Активирован")}
                            </Typography>
                        </>
                    ) : (
                        <>
                            <Icon name="status_blocked" size="20" />
                            <Typography as="div" color="#fff" size={17}>
                                {t("Заблокирован")}
                            </Typography>
                        </>
                    )}
                </Space>
                <Typography
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 14 : 12}
                    color="rgba(255, 255, 255, 0.5)"
                    className="status-date"
                >
                    {_.isEqual(status, 1)
                        ? t("Дата активации: {date}", {date: date(activated_at)})
                        : t("Для разблокировки: {sum}", {sum: currency(unblock_sum)})}
                </Typography>
            </Space>
        </Typography>
    );
});

interface IProps {
    status: number;
    activated_at: string;
    unblock_sum: number;
    pay_type: string;
}
