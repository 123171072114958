import {useCallback, useContext, useEffect, useRef, useState} from "react";
import {MobXProviderContext, observer} from "mobx-react";
import {Button, Col, Row, Space, Title, Typography, Back} from "@bezlimit/bezlimit-ui";
import {useNavigate} from "react-router-dom";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext} from "../../../../core/Contexts";
import {axiosFetchBuilder, IAxiosConfig} from "../../../../core/Axios";
// ui
import CopyButton from "../../../blocks/ui/copy";
// components
import NumberSearchForm from "../../../forms/numberSearch";
import DefaultList from "./list/default";
import BookingList from "./list/booking";
import NumbersFilters from "../../../blocks/numbers/filters/desktop";
import NumbersListFakeHeader from "../../../blocks/numbers/list/default/fake";
// services
import PhonesDefaultService from "../../../../services/PhonesDefaultService";
import PhonesBookingService from "../../../../services/PhonesBookingService";
import PhonesGlobalService from "../../../../services/PhonesGlobalService";
import PhonesFiltersService from "../../../../services/PhonesFiltersService";
import UserService from "../../../../services/UserService";
// hooks
import useSearchRoute from "../../../../core/hooks/useSearchRoute";
// portal
import CopyPortal from "../../../portals/copy";
import {svgIconNumberSprite} from "../../../blocks/ui/svgIconNumberSprite";
import FavoriteNumbers from "./favoriteNumbers";
import {makeAutoObservable} from "mobx";
import SkeletonList from "./skeleton/list";
import KanbanList from "./skeleton/kanban";
import SkeletonMobile from "./skeleton/mobile";
import NumbersFiltersStoreService from "../../../../services/NumbersFiltersStoreService";

const pageStore = makeAutoObservable({
    isInit: false
});

export default observer(function NumbersPage() {
    const {store} = useContext(MobXProviderContext);
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();
    const timeout: any = useRef();
    const [isFavorite, setIsFavorite] = useState<boolean>(
        !localStorage.getItem("isFavorite")
            ? true
            : localStorage.getItem("isFavorite") && localStorage.getItem("isFavorite") === "true"
            ? true
            : false
    );
    const [allCopyText, setAllCopyText] = useState("Скопировать все");
    const [allCopy, setAllCopy] = useState<string>();
    const [allCopyLoading, setAllCopyLoading] = useState(false);
    const [isPaid, setIsPaid] = useState(false);
    const [allCopyDisabled, setAllCopyDisabled] = useState(false);
    const [phone, setPhone] = useState<string>();
    const [phonePattern, setPhonePattern] = useState<string[]>([]);
    const [phoneCombs, setPhoneCombs] = useState<string>();
    const [type, setType] = useState<string[]>(["standard"]);
    const [tariff, setTariff] = useState<string[]>([]);
    const [categories, setCategories] = useState<string[]>([]);
    const [region, setRegion] = useState<string[]>([]);
    const [pattern, setPattern] = useState<string[]>([]);
    const [isAllChecked, setAllIsChecked] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [isChecked, setIsChecked] = useState(false);
    const [gridType, setGridType] = useState<string>("list");
    const [gridTypePrev, setGridTypePrev] = useState<string>(gridType);
    const [sort, setSort] = useState("expensive");
    const [cssClass, setCssClass] = useState(false);
    const navigate = useNavigate();
    const route = useSearchRoute(UserService.token);

    const setTypeHandler = (values: React.SetStateAction<string[]>) => {
        const last = _.last(values as string[]);

        if (_.size(last)) {
            setType([last!]);

            if (!_.isEqual(last, "standard")) {
                setTariff([]);
            }
        } else {
            setType(["standard"]);
        }
    };

    const onCopyAllPaid = async () => {
        setAllCopyLoading(true);
        setAllCopyDisabled(true);
        let data: any[] = [];
        const request = PhonesFiltersService.getFormatedFilters({
            phone,
            type,
            tariff,
            categories,
            region,
            phone_pattern: phonePattern as any,
            phone_combs: phoneCombs,
            sort
        });

        const res: any = await axiosFetchBuilder({
            url: "/phones",
            params: {
                ...request,
                per_page: 500,
                expand: "reservation,tariff,region,mask,priceParams"
            },
            loading: false
        } as IAxiosConfig);

        data = [...data, ...res.items];

        if (_.gt(res._meta.pageCount, 1)) {
            for (let i = 2; i <= res._meta.pageCount; i++) {
                const res: any = await axiosFetchBuilder({
                    url: "/phones",
                    params: {
                        ...request,
                        page: i,
                        per_page: 500,
                        expand: "reservation,tariff,region,mask,priceParams"
                    },
                    loading: false
                } as IAxiosConfig);
                data = [...data, ...res.items];
            }
        }

        const str = onCopy(data);
        setAllCopy(str);
    };

    async function copyPageUrl(str: string) {
        try {
            await navigator.clipboard.writeText(str);
        } catch (err) {}
    }

    useEffect(() => {
        if (allCopy) {
            setTimeout(() => {
                if (navigator.clipboard) {
                    copyPageUrl(allCopy ?? "");
                }

                setAllCopy(undefined);
                setAllCopyLoading(false);
                setAllCopyText("Номера скопированы!");
                setTimeout(() => {
                    setAllCopyDisabled(false);
                    setAllCopyText("Скопировать все");
                }, 3000);
            }, 3000);
        }
    }, [allCopy]);

    const onCopy = (list?: any) => {
        let buffString = _.chain(list ? list : PhonesDefaultService.selectedRowKeysItems)
            .groupBy(({type}) => {
                if (PhonesDefaultService.isPaid(type)) {
                    return "*Платные*";
                } else if (PhonesDefaultService.isInternet(type)) {
                    return "*Интернет*";
                } else {
                    return "*Бесплатные*";
                }
            })
            .reduce<{[k: string]: any}>((res, i, k) => {
                res[k] = {type: k, values: _.chain(i).groupBy("tariff.name").valueOf()};
                return res;
            }, {})
            .orderBy(
                ({type}) => _.indexOf(["*Бесплатные*", "*Интернет*", "*Платные*"], type),
                "asc"
            )
            .map(({type, values}) => {
                let res = "";

                if (_.isEqual(type, "*Платные*")) {
                    const reduced = _.chain(values)
                        .reduce<any[]>((res, i, k) => {
                            (res || (res = [])).push({
                                type: k,
                                values: _.chain(i)
                                    .orderBy(({priceParams}) => priceParams.mask_price, "desc")
                                    .valueOf()
                            });
                            return res;
                        }, [])
                        .orderBy(({type}) => parseInt(_.head(type.match(/\d+/)) ?? "0"), "desc")
                        .valueOf();

                    _.forEach(reduced, (i, k) => {
                        if (_.isEqual(type, "*Платные*")) {
                            res += `${"Тариф: " + i.values[0].tariff.price + " руб/мес"}\n`;
                        }
                        _.forEach(i.values, (i) => {
                            const p = i.phone.toString();
                            const p2 = p.substring(0, 3);
                            const p3 = p.substring(p.length - 7);
                            const phone = p2 + ") " + p3;
                            res += `*+7 (${phone + " - " + i.priceParams.mask_price + " руб"}*\n`;
                        });
                        res += `\n`;
                    });
                } else {
                    const reduced = _.chain(values)
                        .reduce<any[]>((res, i, k) => {
                            (res || (res = [])).push({
                                type: k,
                                values: _.chain(i)
                                    .orderBy(({priceParams}) => priceParams.mask_price, "desc")
                                    .valueOf()
                            });
                            return res;
                        }, [])
                        .orderBy(({type}) => parseInt(_.head(type.match(/\d+/)) ?? "0"), "desc")
                        .valueOf();

                    _.forEach(reduced, (i, k) => {
                        if (_.isEqual(type, "*Бесплатные*")) {
                            res += `${"Тариф: " + i.values[0].tariff.price + " руб/мес"}\n`;
                        } else {
                            res += `${"Тариф: " + i.values[0].tariff.price + " руб/мес"}\n`;
                        }
                        _.forEach(i.values, (i) => {
                            const p = i.phone.toString();
                            const p2 = p.substring(0, 3);
                            const p3 = p.substring(p.length - 7);
                            const phone = p2 + ") " + p3;
                            res += `*+7 (${phone}*\n`;
                        });
                        res += `\n`;
                    });
                }

                return res;
            })
            .join("")
            .valueOf();
        const types = buffString.match(/\*\*Обычные\*\*|\*\*Платные\*\*|\*\*Интернет\*\*/g);
        if (types && types.length === 1) {
            buffString = buffString.replace(types[0], "");
        }
        return buffString;
    };

    const onCopySuccess = () => {
        clearTimeout(timeout.current);
        setDisabled(true);
        timeout.current = setTimeout(() => {
            setDisabled(false);
        }, 3000);
    };

    const setIsAllChecked = useCallback((checked: boolean) => {
        if (checked) {
            setAllIsChecked(true);
        } else {
            setAllIsChecked(false);
        }
    }, []);

    useEffect(() => {
        if (PhonesDefaultService.sizeSelectedRowKeys) {
            setIsChecked(true);
        } else {
            setIsChecked(false);
        }

        setIsAllChecked(PhonesDefaultService.isEqualSizeItemsAndSelectedRowKeys);
    }, [PhonesDefaultService.getObject("default").selectedRowKeys, PhonesDefaultService.getObject("paid").selectedRowKeys, PhonesDefaultService.getObject("standard").selectedRowKeys, PhonesDefaultService.getObject("internet").selectedRowKeys]);

    useEffect(() => {
        setIsAllChecked(PhonesDefaultService.isEqualSizeItemsAndSelectedRowKeys);

        if (_.includes(type, "paid")) {
            setIsPaid(true);
        } else {
            setIsPaid(false);
        }
    }, [PhonesDefaultService.getObject("default").items, PhonesDefaultService.getObject("paid").items, PhonesDefaultService.getObject("standard").items, PhonesDefaultService.getObject("internet").items]);

    useEffect(() => {
        if (!_.isEqual(localStorage.getItem("isFavorite"), "collection")) {
            setType(["standard"]);
            setSort("expensive");
            setTariff([]);
            setRegion([]);
            setPattern([]);
            setCategories([]);
            setPhonePattern([]);
            setPhoneCombs(undefined);
            PhonesFiltersService.phonesFilters.general_number = [];
            PhonesFiltersService.phonesFilters.filtersActive = undefined;
            PhonesGlobalService.fetchAllInit(pageStore, store);
            localStorage.setItem("isFavorite", isFavorite.toString());
            store.isFavorite = isFavorite;

            if (!isFavorite) {
                NumbersFiltersStoreService.getFilters();
            }
        } else {
            store.numberFilters.isCollection = true;
        }
    }, [isFavorite, store.numberFilters.isCollection]);

    useEffect(() => {
        if (!_.isEqual(gridType, "kanban") && _.lte(contextLayout?.width ?? 0, 768)) {
            setGridTypePrev(gridType);
            setGridType("kanban");
        } else if (
            _.gte(contextLayout?.width ?? 0, 768) &&
            _.isEqual(gridType, "kanban") &&
            _.isEqual(gridTypePrev, "list")
        ) {
            setGridType(gridTypePrev);
        }
    }, [contextLayout?.width]);

    const isReservation = () => {
        return (
            Boolean(_.size(tariff) || _.size(categories) || _.size(region) || _.size(pattern)) ||
            !_.isEqual(type[0], "standard") ||
            _.gte(phonePattern.join("").length, 1) ||
            _.gte(phoneCombs, "")
        );
    };

    useEffect(() => {
        PhonesFiltersService.getFilters();
        return () => {
            pageStore.isInit = false;
            PhonesFiltersService.cleanUp();
        };
    }, []);

    return (
        <>
            {svgIconNumberSprite()}
            <Back navigate={() => navigate("/")} />
            <Row
                justify="center"
                gutter={[16, _.gte(contextLayout?.width ?? 0, 768) ? 82 : 40]}
                className={`${
                    isFavorite && _.gte(contextLayout?.width ?? 0, 768) ? " isFavorite" : ""
                }${isFavorite ? " fav" : " notfav"}${gridType === "list" ? " list" : " kanb"}${
                    store.numberFilters.isCollection && " isCollection"
                }`}
            >
                <Col span={24}>
                    <Row justify="center" align="middle" gutter={[16, 16]}>
                        <Col span={24}>
                            <Typography as="div" className="text-center">
                                <Title title={_.get(route, "title")} />
                            </Typography>
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <Row justify="center" gutter={[16, 16]}>
                        <Col xs={24}>
                            {store.numberFilters.isCollection || isFavorite ? (
                                <FavoriteNumbers
                                    phone={phone}
                                    type={type}
                                    sort={sort}
                                    tariff={tariff}
                                    categories={categories}
                                    region={region}
                                    pattern={pattern}
                                    phone_pattern={phonePattern}
                                    phone_combs={phoneCombs}
                                    isFavorite={isFavorite}
                                    setType={setType}
                                    setIsFavorite={setIsFavorite}
                                    setTariff={setTariff}
                                    setCategories={setCategories}
                                    setRegion={setRegion}
                                    setPattern={setPattern}
                                    setPhonePattern={setPhonePattern}
                                    setPhoneCombs={setPhoneCombs}
                                />
                            ) : (
                                <NumberSearchForm
                                    phone={phone}
                                    type={type}
                                    tariff={tariff}
                                    categories={categories}
                                    region={region}
                                    pattern={pattern}
                                    phonePattern={phonePattern}
                                    phoneCombs={phoneCombs}
                                    sort={sort}
                                    isFavorite={isFavorite}
                                    setIsFavorite={setIsFavorite}
                                    setType={setTypeHandler}
                                    setTariff={setTariff}
                                    setCategories={setCategories}
                                    setRegion={setRegion}
                                    setPattern={setPattern}
                                    setPhone={setPhone}
                                    setPhonePattern={setPhonePattern}
                                    setPhoneCombs={setPhoneCombs}
                                />
                            )}
                        </Col>
                    </Row>
                </Col>
                <Col span={24} className="bl-list-number-content">
                    <Row justify="center" gutter={[16, 16]}>
                        <Col xs={24} lg={isFavorite ? 24 : 18}>
                            <div style={{display: store.pageLoading ? "block" : "none"}}>
                                {_.gte(contextLayout?.width ?? 0, 768) ? (
                                    gridType === "list" ? (
                                        <SkeletonList />
                                    ) : (
                                        <KanbanList isFavorite={isFavorite} />
                                    )
                                ) : (
                                    <SkeletonMobile />
                                )}
                            </div>
                            <Space
                                direction="vertical"
                                size={20}
                                className={`list-container${
                                    PhonesDefaultService.isGrouped ? " grouped" : ""
                                }${cssClass ? " mobile-grouped-dropdown-open" : ""}`}
                            >
                                {!store.numberFilters.isCollection &&
                                    !store.pageLoading &&
                                    !!PhonesDefaultService.isGrouped && (
                                        <NumbersListFakeHeader
                                            phone={phone}
                                            type={type}
                                            tariff={tariff}
                                            categories={categories}
                                            region={region}
                                            pattern={pattern}
                                            isAllChecked={isAllChecked}
                                            gridType={gridType}
                                            sort={sort}
                                            phonePattern={phonePattern}
                                            phoneCombs={phoneCombs}
                                            isFavorite={isFavorite}
                                            setIsFavorite={setIsFavorite}
                                            setType={setTypeHandler}
                                            setTariff={setTariff}
                                            setCategories={setCategories}
                                            setRegion={setRegion}
                                            setPattern={setPattern}
                                            setAllIsChecked={setAllIsChecked}
                                            setPhone={setPhone}
                                            setGridType={setGridType}
                                            setGridTypePrev={setGridTypePrev}
                                            setSort={setSort}
                                            setCssClass={setCssClass}
                                            setPhonePattern={setPhonePattern}
                                            setPhoneCombs={setPhoneCombs}
                                            Service={PhonesDefaultService.getObject("default")}
                                        />
                                    )}

                                <Space
                                    direction="vertical"
                                    size={_.gte(contextLayout?.width ?? 0, 768) ? 64 : 48}
                                >
                                    {!store.pageLoading && (
                                        <>
                                            <DefaultList
                                                objects={PhonesDefaultService.objects}
                                                phone={phone}
                                                type={type}
                                                tariff={tariff}
                                                categories={categories}
                                                region={region}
                                                pattern={pattern}
                                                isAllChecked={isAllChecked}
                                                gridType={gridType}
                                                sort={sort}
                                                phonePattern={phonePattern}
                                                phoneCombs={phoneCombs}
                                                isFavorite={isFavorite}
                                                setIsFavorite={setIsFavorite}
                                                setType={setTypeHandler}
                                                setTariff={setTariff}
                                                setCategories={setCategories}
                                                setRegion={setRegion}
                                                setPattern={setPattern}
                                                setPhone={setPhone}
                                                setGridType={setGridType}
                                                setGridTypePrev={setGridTypePrev}
                                                setSort={setSort}
                                                setAllIsChecked={setAllIsChecked}
                                                setCssClassGrouped={setCssClass}
                                                setPhonePattern={setPhonePattern}
                                                setPhoneCombs={setPhoneCombs}
                                            />
                                            {isReservation() && (
                                                <BookingList
                                                    objects={PhonesBookingService.objects}
                                                    gridType={gridType}
                                                    type={type}
                                                />
                                            )}
                                        </>
                                    )}
                                </Space>
                            </Space>
                            {isChecked && (
                                <CopyPortal>
                                    <Space size={16} direction="horizontal">
                                        <CopyButton
                                            title="Скопировать выбранные"
                                            disabled={disabled}
                                            onCopy={onCopy}
                                            onSuccess={onCopySuccess}
                                        />
                                        {isPaid && isAllChecked && (
                                            <>
                                                <Button
                                                    className="clopy-all"
                                                    onClick={onCopyAllPaid}
                                                    disabled={allCopyDisabled}
                                                    loading={allCopyLoading}
                                                >
                                                    {t(allCopyText)}
                                                </Button>
                                            </>
                                        )}
                                    </Space>
                                </CopyPortal>
                            )}
                        </Col>
                        {!_.isEqual(localStorage.getItem("isFavorite"), "collection") &&
                            !isFavorite &&
                            _.gte(contextLayout?.width ?? 0, 992) && (
                                <Col xs={0} lg={6}>
                                    <NumbersFilters
                                        phone={phone}
                                        type={type}
                                        tariff={tariff}
                                        categories={categories}
                                        region={region}
                                        pattern={pattern}
                                        phonePattern={phonePattern}
                                        phoneCombs={phoneCombs}
                                        sort={sort}
                                        setType={setTypeHandler}
                                        setTariff={setTariff}
                                        setCategories={setCategories}
                                        setRegion={setRegion}
                                        setPattern={setPattern}
                                        setPhonePattern={setPhonePattern}
                                        setPhoneCombs={setPhoneCombs}
                                        setIsFavorite={setIsFavorite}
                                    />
                                </Col>
                            )}
                    </Row>
                </Col>
            </Row>
        </>
    );
});
