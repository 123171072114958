import React, {useContext} from "react";
import {Button, Col, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import "./style.scss";
// core
import {IReservations, IReservationsRequest} from "../../../../../core/models/Reservations";
// components
import {LayoutContext, ModalContext} from "../../../../../core/Contexts";
import Phone from "../../../numbers/list/default/info/phone";
import Description from "./description";
import Icons from "../../../numbers/list/booking/actions/icons";
import Duration from "../../../numbers/list/booking/actions/duration";
import Actions from "./actions";
import Extend from "./extend";
import SimModal from "../../../../modal/sim";
import _ from "lodash";
import {observer} from "mobx-react";
import ReservationsService from "../../../../../services/ReservationsService";
import npsIcon from "../../../../../images/nps_icon.svg";

export default observer(function Info({
    props: {
        id,
        sim,
        phone_number: phone,
        pay_type,
        removed_at,
        recommend_sum,
        additional_data,
        status,
        phone: {
            mask: {category},
            region: {name: regionName},
            passport
        },
        tariff: {
            id: tariffId,
            name: tariffName,
            minutes,
            internet,
            sms,
            price,
            prolong_paid_price: prolongPaidPrice
        }
    },
    activeKey,
    setItems,
    updateList
}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const contextModal = useContext(ModalContext);
    const {t} = useTranslation();

    const onSim = () => {
        contextModal?.setChildren(<SimModal phone={phone} />);
        contextModal?.setVisible(true);
    };

    let phoneNPS = "";
    if (additional_data?.nps) {
        phoneNPS = additional_data?.nps.phone;
    }

    return (
        <Typography as="div" className={`bl-reservation-list-info bl-${pay_type ?? "default"}`}>
            <Row gutter={[16, 16]}>
                <Col md={14} lg={5} xl={4} className={`phone ${pay_type}`}>
                    <Space direction="vertical" size={0}>
                        <Phone category={category} phone={phone} />
                        {!_.isEqual(pay_type, "super_link") && (
                            <Button
                                type="text"
                                style={{
                                    cursor: _.isEqual(additional_data?.delivery_type, "esim")
                                        ? "default"
                                        : "pointer"
                                }}
                                onClick={
                                    _.isEqual(additional_data?.delivery_type, "esim")
                                        ? undefined
                                        : onSim
                                }
                            >
                                <Typography
                                    as="div"
                                    color="#fff"
                                    size={14}
                                    underline={
                                        _.isEqual(additional_data?.delivery_type, "esim")
                                            ? false
                                            : true
                                    }
                                >
                                    {_.isEqual(additional_data?.delivery_type, "esim")
                                        ? t("Оформлена eSIM ")
                                        : t("Легкая выдача SIM")}
                                </Typography>
                            </Button>
                        )}
                    </Space>
                </Col>
                <Col xs={0} lg={12} xl={13} className="description">
                    <Description
                        tariffName={tariffName}
                        minutes={minutes}
                        internet={internet}
                        sms={sms}
                        sim={sim}
                        pay_type={pay_type}
                        regionName={regionName}
                        additional_data={additional_data}
                    />
                </Col>
                {(_.isEqual(ReservationsService.gridType, "list") &&
                    _.gte(contextLayout?.width ?? 0, 768)) ||
                (_.isEqual(ReservationsService.gridType, "kanban") &&
                    _.isEqual(pay_type, "super_link")) ? (
                    <Col
                        xs={_.isEqual(pay_type, "super_link") ? 10 : 0}
                        lg={
                            !_.isEqual(pay_type, "super_link")
                                ? 3
                                : _.isEqual(ReservationsService.gridType, "list")
                                ? 7
                                : 10
                        }
                        className={`icons ${pay_type}`}
                    >
                        {!_.isEqual(phoneNPS, "") && (
                            <img src={npsIcon} width={24} alt="superLink" />
                        )}
                        {!_.isEqual(status, "queue") && (
                            <Icons reservation={{pay_type, removed_at}} />
                        )}
                    </Col>
                ) : null}
                {!_.isEqual(pay_type, "super_link") && (
                    <Col xs={10} lg={4} className="actions">
                        {!_.isEqual(status, "queue") && (
                            <Actions
                                id={id}
                                phone={phone}
                                tariffName={tariffName}
                                tariffId={tariffId}
                                minutes={minutes}
                                internet={internet}
                                sms={sms}
                                regionName={regionName}
                                passport={passport}
                                onSim={onSim}
                                updateList={updateList}
                            />
                        )}
                    </Col>
                )}
                <Col xs={18} md={20} lg={0} className="desc">
                    <Description
                        tariffName={tariffName}
                        minutes={minutes}
                        internet={internet}
                        sms={sms}
                        sim={sim}
                        pay_type={pay_type}
                        regionName={regionName}
                        additional_data={additional_data}
                    />
                </Col>
                <Col
                    xs={24}
                    sm={
                        !_.isEqual(pay_type, "super_link") &&
                        !_.isEqual(ReservationsService.gridType, "kanban")
                            ? 9
                            : 24
                    }
                    className={`duration ${pay_type ?? ""} ${ReservationsService.gridType}`}
                >
                    {!_.isEqual(status, "queue") ? (
                        <>
                            <Duration
                                reservation={{removed_at}}
                                suffix={true}
                                pay_type={pay_type}
                            />
                            {!_.isEqual(phoneNPS, "") ? (
                                <Typography as="div" className="nps-number">
                                    <Space direction="horizontal">
                                        <img src={npsIcon} width={24} alt="superLink" />
                                        {phoneNPS}
                                    </Space>
                                </Typography>
                            ) : null}
                        </>
                    ) : (
                        <Typography as="div">{t("Номер в очереди на бронирование.")}</Typography>
                    )}
                </Col>
                {!_.isEqual(pay_type, "super_link") && (
                    <>
                        <Col xs={24} sm={15} className="extend">
                            {!_.isEqual(status, "queue") && (
                                <Extend
                                    id={id}
                                    phone={phone}
                                    tariffId={tariffId}
                                    prolongPaidPrice={prolongPaidPrice}
                                    tariffName={tariffName}
                                    price={ReservationsService?.recommendSum ?? price}
                                    activeKey={activeKey}
                                    recommend_sum={recommend_sum}
                                    setItems={setItems}
                                    pay_type={pay_type}
                                />
                            )}
                        </Col>
                    </>
                )}
            </Row>
        </Typography>
    );
});

interface IProps {
    activeKey: string;
    props: IReservations;
    updateList: (request?: IReservationsRequest, loading?: boolean, reset?: boolean) => void;
    setItems: React.Dispatch<React.SetStateAction<IReservations[]>>;
}
