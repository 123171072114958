import React, {useContext, useRef} from "react";
import {Button, Form, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import cn from "classnames";
import _ from "lodash";

// core
import {IPersonalDataRequest} from "../../../core/models/User";
import {InterceptorContext, LayoutContext, ModalContext} from "../../../core/Contexts";
import {getFieldsErrorSize} from "../../../core/functions/form";
// services
import UserService from "../../../services/UserService";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
import useFieldFocus from "../../../core/hooks/useFieldFocus";
import useOnChange from "../../../core/hooks/useOnChange";
// components
import PhoneField from "../../fields/phone";
import Error from "../../modal/error";
import CodeConfirmation from "../../modal/codeConfirmation";
import Success from "../../modal/success";


export default React.memo(
    observer(function EditPhoneForm(props: Props) {
        const currPhone = props.phone;
        const context = useContext(InterceptorContext);
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const ref = useRef(null);
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        const onChange = useOnChange(formInstance);
        useFieldFocus(ref, `${props.blur ? "" : "phone"}`);

        const onSubmit = async ({code, phone}: any) => {
            await UserService.putPersonalData({code, phone});
            contextModal?.setChildren(
                <Success
                    title={
                        <Typography as="span">
                            {t("Номер успешно ")}
                            <br />
                            {currPhone ? t("изменен") : t("привязан")}
                        </Typography>
                    }
                    phone={phone}
                />
            );
            await UserService.getUsers();
        };

        const onFinish = async (values: IPersonalDataRequest) => {
            const phone = _.replace(values.phone!, /(\+7|\s|-)/gi, "");
            try {
                await UserService.putPersonalData({phone});
                contextModal?.setVisible(true);
                contextModal?.setChildren(<CodeConfirmation phone={phone} onSubmit={onSubmit} />);
                formInstance.resetFields();
                await UserService.getUsers();
            } catch (e: any) {
                contextModal?.setVisible(true);
                contextModal?.setChildren(<Error phone={phone} />);
            }
        };

        return (
            <div ref={ref}>
                <Typography as="div">
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="edit-phone"
                        onFinish={onFinish}
                        onFieldsChange={onChange}
                        autoComplete="off"
                        initialValues={{
                            phone: currPhone ?? ""
                        }}
                    >
                        <Space
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 8 : 24}
                            direction="vertical"
                        >
                            <PhoneField className={cn({"confirm-field": !currPhone})}/>
                            <Form.Item noStyle shouldUpdate>
                                {({getFieldsValue, getFieldsError}) => {
                                    const {phone} = getFieldsValue();
                                    const errors = getFieldsErrorSize(getFieldsError);
                                    const formIsComplete =
                                        !!phone &&
                                        !(_.replace(phone!, /(\+7|\s|-)/gi, "").length < 10) &&
                                        phone !== currPhone &&
                                        !errors;

                                    return (
                                        <Button
                                            style = {currPhone ? {} : {width: "100%"}}
                                            type={!formIsComplete ? "default" : "primary"}
                                            htmlType="submit"
                                            disabled={!formIsComplete}
                                            loading={context?.loadingBtn}
                                        >
                                            {currPhone ? t("Сменить номер") : t("Привязать номер")}
                                        </Button>
                                    );
                                }}
                            </Form.Item>
                        </Space>
                    </Form>
                </Typography>
            </div>
        );
    })
);

interface Props {
    phone?: number;
    blur?: boolean;
}
