import {makeAutoObservable} from "mobx";
import {IItems, IMeta, INewsNoticeCount} from "../core/models/Notifications";

class BellNotifications {
    [Symbol.toStringTag] = this.constructor.name;
    private _meta!: IMeta;
    private _items: IItems[] = [];
    private _newsNoticeCount!: INewsNoticeCount;

    constructor() {
        makeAutoObservable(this);
    }

    get items() {
        return this._items;
    }

    set items(value: IItems[]) {
        this._items = value;
    }

    get meta() {
        return this._meta;
    }

    set meta(value: IMeta) {
        this._meta = value;
    }

    get newsNoticeCount() {
        return this._newsNoticeCount;
    }

    set newsNoticeCount(value: INewsNoticeCount) {
        this._newsNoticeCount = value;
    }
}

export default BellNotifications;
