import {Form, Input} from "@bezlimit/bezlimit-ui";
import {FormItemProps} from "antd";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
// rules
import {Rules} from "../../forms/rules";

export default function CardField(props: FormItemProps<any>) {
    const {t} = useTranslation();

    return (
        <Form.Item
            {...props}
            hasFeedback
            className={props.className ?? "field-phone"}
            name="card"
            rules={[Rules.required()]}
        >
            <NumberFormat<any>
                customInput={Input}
                autoComplete="new-phone"
                format={(value) => {
                    const code = value.substring(0, 4);
                    const nums1 = value.substring(4, 8);
                    const nums2 = value.substring(8, 12);
                    const nums3 = value.substring(12, 16);
                    const nums4 = value.substring(16, 19);

                    return `${code}${nums1 ? ` ${nums1}` : ""}${nums2 ? ` ${nums2}` : ""}${
                        _.gt(_.size(value), 12) ? ` ${nums3}` : ""
                    }${nums4 ? ` ${nums4}` : ""}`;
                }}
                title={t("Номер карты")}
            />
        </Form.Item>
    );
}
