import {makeAutoObservable} from "mobx";
import i18n from "i18next";
import {initReactI18next} from "react-i18next";
// core
import {IHintsItems, ITranslate} from "../core/models/Translate";
import translations from "./translations";

class Settings {
    [Symbol.toStringTag] = this.constructor.name;
    private _locale: string = "ru-RU";
    private _dictionaries: Map<string, ITranslate> = new Map();
    private _missingKeys: string[] = [];
    private _hints!: IHintsItems;

    constructor() {
        makeAutoObservable(this);
        const locale = localStorage.getItem("locale") ?? this.locale;
        i18n.use(initReactI18next)
            .init({
                fallbackLng: locale,
                lng: locale,
                resources: {
                    "ru-RU": {
                        translation: translations.ru
                    },
                    "en-US": {
                        translation: translations.en
                    }
                },
                interpolation: {
                    prefix: "{",
                    suffix: "}"
                },
                keySeparator: false
            })
            .then();
    }

    get locale() {
        return this._locale;
    }

    set locale(value: string) {
        this._locale = value;
    }

    get dictionaries() {
        return this._dictionaries;
    }

    get missingKeys() {
        return this._missingKeys;
    }

    set hints(value: IHintsItems) {
        this._hints = value;
    }

    get hints() {
        return this._hints;
    }
}

export default Settings;
