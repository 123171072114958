import {useContext} from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import "./style.scss";
// core
import {UserContext} from "../../../../core/Contexts";
import _ from "lodash";
import {registerStoreContext} from "../../registerStore";

export default function EmailText() {
    const context = useContext(UserContext);
    const {t} = useTranslation();

    return (
        <Typography as="div" className="bl-email-text">
            {context?.email !== "setPhone" && (
                <Typography
                    dangerouslySetInnerHTML={{
                        __html: t(
                            `Введите отправленные на вашу почту {email} логин и пароль для входа`,
                            {
                                email: `<span>${context?.email}</span>`,
                                interpolation: {escapeValue: false}
                            }
                        )
                    }}
                ></Typography>
            )}
            {context?.email === "setPhone" && (
                <Typography
                    dangerouslySetInnerHTML={{
                        __html: t(
                            `Введите отправленные на ваш номер телефона {phone} логин и пароль для входа`,
                            {
                                phone: `<span>${
                                    _.isUndefined(context?.phone)
                                        ? registerStoreContext?.phone
                                        : context?.phone
                                }</span>`,
                                interpolation: {escapeValue: false}
                            }
                        )
                    }}
                ></Typography>
            )}
        </Typography>
    );
}
