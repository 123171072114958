import {useTranslation} from "react-i18next";
import {Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";

export default function BezlimitInfo() {
    const {t} = useTranslation();

    return (
        <Typography as="div" className="bl-legal-info legal-info-block">
            <Typography as="div" className="copyright">
                <a href="https://bezlimit.ru" target="_blank" rel="noreferrer">
                    <Typography className={"legal-info"}>
                        {t("Узнайте больше про © Безлимит")}
                    </Typography>
                </a>
            </Typography>
        </Typography>
    );
}
