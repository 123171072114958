import React, {useContext, useRef} from "react";
import {Button, Col, Form, Input, Row, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import {IReviewFormRequest} from "../../../core/models/User";
import {InterceptorContext, ModalContext} from "../../../core/Contexts";
import {getFieldsErrorSize} from "../../../core/functions/form";
// services
import UserService from "../../../services/UserService";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
import useFieldFocus from "../../../core/hooks/useFieldFocus";
import useOnCancel from "../../../core/hooks/useOnCancel";
import useOnChange from "../../../core/hooks/useOnChange";
// components
import EmailField from "../../fields/email";
import Error from "../../modal/error";
import FIOField from "../../fields/fio";
import PhoneField from "../../fields/phone";
import Success from "../../modal/success";
import CommentField from "../../fields/comment";

export default React.memo(
    observer(function WriteBossForm() {
        const context = useContext(InterceptorContext);
        const contextModal = useContext(ModalContext);
        const ref = useRef(null);
        const {t} = useTranslation();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        const onChange = useOnChange(formInstance);
        const onCancel = useOnCancel(formInstance);
        useFieldFocus(ref, "fio");

        const onFinish = async ({fio, ...values}: IReviewFormRequest) => {
            const arr = _.split(fio, " ");

            try {
                await UserService.postReview({name: arr[1], surname: arr[0], ...values});
                contextModal?.setChildren(
                    <Success
                        title={<Typography as="span">{t("Обращение отправлено")}</Typography>}
                    />
                );
            } catch (e: any) {
                contextModal?.setChildren(<Error />);
            } finally {
                contextModal?.setOnCancel(() => onCancel);
                contextModal?.setCssClass("");
            }
        };

        return (
            <div ref={ref}>
                <Typography as="div" className="bl-write-boss-form">
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="write-boss"
                        onFinish={onFinish}
                        onFieldsChange={onChange}
                        autoComplete="off"
                        initialValues={{
                            fio: "",
                            email: _.isEqual(UserService.profile.email_status, "confirmed")
                                ? UserService.profile.email
                                : "",
                            comment: "",
                            source: "web"
                        }}
                    >
                        <Form.Item hidden name="source">
                            <Input />
                        </Form.Item>
                        <Row gutter={[16, 0]}>
                            <Col xs={24} sm={12}>
                                <FIOField />
                                <EmailField />
                                <PhoneField />
                            </Col>
                            <Col xs={24} sm={12}>
                                <CommentField />
                            </Col>
                            <Col xs={24} className="button-container">
                                <Form.Item shouldUpdate>
                                    {({getFieldsValue, getFieldsError}) => {
                                        const {fio, email, comment} = getFieldsValue();
                                        const errors = getFieldsErrorSize(getFieldsError);
                                        const formIsComplete =
                                            !!fio && !!email && !!comment && !errors;

                                        return (
                                            <Button
                                                type={!formIsComplete ? "default" : "primary"}
                                                htmlType="submit"
                                                disabled={!formIsComplete}
                                                loading={context?.loadingBtn}
                                            >
                                                {t("Отправить")}
                                            </Button>
                                        );
                                    }}
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Typography>
            </div>
        );
    })
);
