import React, {useContext, useEffect, useState} from "react";
import {Button, Typography} from "@bezlimit/bezlimit-ui";
import {Table, ConfigProvider} from "antd";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import "./style.scss";
// components
import Header from "./header";
import Info from "./info";
import EmptyResult from "../emptyResult";
// core
import {InterceptorContext, LayoutContext} from "../../../../core/Contexts";
import {
    IMeta,
    IReservations,
    IReservationsBookRequest,
    IReservationsRequest
} from "../../../../core/models/Reservations";
import {useNavigate} from "react-router-dom";
// portal
import ReservationsService from "../../../../services/ReservationsService";
import BonusesService from "../../../../services/BonusesService";
import ReservationShimmer from "../../../private/pages/book/shimmerReservation";

export default React.memo(
    observer(function ReservationList({
        items,
        activeKey,
        meta,
        filterRequest,
        setItems,
        updateList,
        getReservationsByIsActivated,
        firstPageRender,
        setShimmer,
        shimmer
    }: IProps) {
        const contextLayout = useContext(LayoutContext);
        const {t} = useTranslation();
        const navigate = useNavigate();
        const [data, setData] = useState<DataType[]>([]);
        const [gridType, setGridType] = useState("list");
        const context = useContext(InterceptorContext);

        const columns = [
            {
                dataIndex: "info",
                key: "info",
                title: (
                    <Header
                        gridType={gridType}
                        setGridType={setGridType}
                        updateList={updateList}
                        setShimmer={setShimmer}
                    />
                )
            }
        ];

        const onClick = () => {
            navigate("/numbers");
        };

        const onClickLoadMore = () => {
            getReservationsByIsActivated(
                {
                    key: "reservation",
                    ...(filterRequest ? filterRequest : {}),
                    page: meta!.currentPage + 1
                },
                false
            );
        };

        useEffect(() => {
            ReservationsService.gridType = gridType;
        }, [gridType]);

        useEffect(() => {
            setData(
                _.chain(items)
                    .reduce<DataType[]>((res, i) => {
                        (res || (res = [])).push({
                            key: i.phone_number as React.Key,
                            info: shimmer ? (
                                <ReservationShimmer gridType={gridType} />
                            ) : (
                                <Info
                                    props={i}
                                    updateList={updateList}
                                    activeKey={activeKey}
                                    setItems={setItems}
                                />
                            )
                        });
                        return res;
                    }, [])
                    .valueOf()
            );
        }, [items, shimmer]);

        useEffect(() => {
            BonusesService.summary();
        }, []);

        return (
            <>
                {!firstPageRender ? (
                    <EmptyResult title="У вас пока нет зарезервированных номеров" />
                ) : (
                    <Typography
                        as="div"
                        className={`bl-reservation-list ${
                            _.gte(contextLayout?.width ?? 0, 768) ? gridType : "kanban"
                        }`}
                    >
                        <ConfigProvider
                            renderEmpty={() => (
                                <Typography
                                    as="div"
                                    color="rgba(255,255,255,.5)"
                                    fontFamily="alumna"
                                    size={32}
                                    className="empty"
                                >
                                    {t("Не найдено")}
                                </Typography>
                            )}
                        >
                            <Table columns={columns} dataSource={data} pagination={false} />
                            {!meta || (meta && _.lt(_.size(items), meta.totalCount)) ? (
                                <Typography as="div" className="loading-more">
                                    <Button
                                        type="default"
                                        onClick={onClickLoadMore}
                                        loading={context?.loadingBtn}
                                    >
                                        {t("Еще номера")}
                                    </Button>
                                </Typography>
                            ) : (
                                <Typography as="div" className="book-yet">
                                    <Button type="default" onClick={onClick}>
                                        {t("Забронировать еще номер")}
                                    </Button>
                                </Typography>
                            )}
                        </ConfigProvider>
                    </Typography>
                )}
            </>
        );
    })
);

interface DataType {
    key: React.Key;
    info: JSX.Element;
}

interface IProps {
    activeKey: string;
    items: IReservations[];
    updateList: (request?: IReservationsRequest, loading?: boolean, reset?: boolean) => void;
    setItems: React.Dispatch<React.SetStateAction<IReservations[]>>;
    setShimmer: React.Dispatch<React.SetStateAction<boolean>>;
    shimmer: boolean;
    firstPageRender?: boolean;
    meta: IMeta | undefined;
    setMeta: React.Dispatch<React.SetStateAction<IMeta | undefined>>;
    getReservationsByIsActivated: (
        {key, ...request}: IReservationsBookRequest,
        loading?: boolean
    ) => Promise<void>;
    filterRequest: IReservationsRequest | undefined;
}
