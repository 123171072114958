import {useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {MenuInfo} from "rc-menu/lib/interface";
import _ from "lodash";
import UserService from "../../../../services/UserService";

export const useHookDealerOrService = (toggle: () => void) => {
    const [isDealerOrService, setIsDealerOrService] = useState<string>("dealer");
    const navigate = useNavigate();
    const [selectedItem, setSelectedItem] = useState("/");

    useEffect(() => {
        handleSwitchDealerOrService("dealer");
    }, []);

    const click = async ({key}: MenuInfo) => {
        if (_.isEqual(key, "/logout")) {
            await UserService.postLogout();
            navigate("/");
        } else if (_.isEqual(key, "/insure")) {
            window.open("https://insure.bezlimit.ru/", "_blank");
        } else if (_.isEqual(key.slice(0, 4), "menu")) {
            return null;
        } else if (_.isEqual(key, "/bezlimit-ru")) {
            window.open("https://bezlimit.ru/", "_blank");
        } else if (_.includes(key, "http")) {
            window.open(key, "_.blank");
        } else {
            navigate(key);
        }
        toggle();
    };

    useEffect(() => {
        const newSelectedItem = location.pathname.split("/")[1] ?? "";
        setSelectedItem(`/${newSelectedItem}`);
    }, [location.pathname]);

    const handleSwitchDealerOrService = (key: string) => {
        switch (key) {
            case "dealer":
                return (() => {
                    setIsDealerOrService("dealer");
                    document
                        .querySelector(".bl-menu__dealer-service__dealer")
                        ?.classList.add("bl-menu__dealer-service__active");
                    document
                        .querySelector(".bl-menu__dealer-service__service")
                        ?.classList.remove("bl-menu__dealer-service__active");
                })();
            case "service":
                return (() => {
                    setIsDealerOrService("service");
                    document
                        .querySelector(".bl-menu__dealer-service__service")
                        ?.classList.add("bl-menu__dealer-service__active");
                    document
                        .querySelector(".bl-menu__dealer-service__dealer")
                        ?.classList.remove("bl-menu__dealer-service__active");
                })();
            default:
                break;
        }
    };

    return {
        handleSwitchDealerOrService: (key: string) => handleSwitchDealerOrService(key),
        click: (x: MenuInfo) => click(x),
        selectedItem: selectedItem,
        isDealerOrService: isDealerOrService
    };
};
