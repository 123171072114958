import ReactDOM from "react-dom";
import {BrowserRouter} from "react-router-dom";
import {Provider} from "mobx-react";
import "@bezlimit/bezlimit-ui/dist/styles/index.min.css";
import "./styles/index.scss";
//components
import App from "./App";
// store
import Store from "./stores";
// core
import Interceptor from "./core/Interceptor";
//locale
import "moment/locale/ru";
import moment from "moment";
import * as Sentry from "@sentry/react";

moment.locale("ru");

if ('REACT_APP_SENTRY_DSN' in process.env) {
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_DSN,
        integrations: [
            Sentry.browserTracingIntegration(),
            Sentry.replayIntegration(),
        ],
        // Performance Monitoring
        //tracesSampleRate: 1.0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        //tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
        // Session Replay
        replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}

const Root = () => {
    return (
        <Provider store={Store}>
            <BrowserRouter>
                <Interceptor>
                    <App />
                </Interceptor>
            </BrowserRouter>
        </Provider>
    );
};

ReactDOM.render(<Root />, document.getElementById("root"));
