import {useEffect} from "react";

export default function useFieldFocus(
    ref: React.MutableRefObject<HTMLDivElement | null>,
    name: string
) {
    useEffect(() => {
        if (ref.current) {
            const field = ref.current.querySelector(`.field-${name} input`);
            if (field) {
                (field as any).focus();
            }
        }
    }, [ref, name]);
}
