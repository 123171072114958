import _ from "lodash";
import {Moment} from "moment";
import {useCallback, useEffect, useState} from "react";
import {
    IBonusesData,
    IBonusesSummaryPaymentsResponse,
    IBonusesSummaryRequest
} from "../models/Bonus";
import {RangeValue} from "rc-picker/lib/interface";
import moment from "moment";

export default function useBonusSummary(
    period: RangeValue<moment.Moment>,
    activeKey: string,
    type: string,
    fn: (request?: IBonusesSummaryRequest, loading?: boolean) => Promise<any>
) {
    const [loading, setLoading] = useState<boolean>(false);
    const [summary, setSummary] = useState<IBonusesSummaryPaymentsResponse>();
    const [data, setData] = useState<IBonusesData[]>([]);

    const callback = useCallback(
        async (
            period: RangeValue<Moment> | null,
            data: IBonusesData[],
            isLoadMore: boolean = false
        ) => {
            if (isLoadMore) {
                setLoading(true);
            }
            const requests = [];
            const request = {
                ...(period
                    ? {
                          created_from: _.head(period)?.clone().format("YYYY-MM-DD 00:00:00"),
                          created_to: _.last(period)?.clone().format("YYYY-MM-DD 23:59:59")
                      }
                    : {})
            };

            if (!period) {
                const last = _.last(data)?.month ?? moment().add(1, "month");
                for (let i = 1; i < 7; i++) {
                    requests.push({
                        month: last.clone().subtract(i, "month").startOf("month"),
                        request: {
                            created_from: last
                                .clone()
                                .subtract(i, "month")
                                .startOf("month")
                                .format("YYYY-MM-DD 00:00:00"),
                            created_to: last
                                .clone()
                                .subtract(i, "month")
                                .endOf("month")
                                .format("YYYY-MM-DD 23:59:59")
                        }
                    });
                }
            } else {
                const head = _.head(period)!;
                const last = _.last(period)!;
                const diff =
                    last
                        .clone()
                        .startOf("month")
                        .diff(moment(head.clone().startOf("month")), "months", true) + 1;

                for (let i = 0; i < diff; i++) {
                    const from = _.isEqual(i + 1, diff)
                        ? head.clone()
                        : last.clone().subtract(i, "month").startOf("month");
                    const to = _.isEqual(i, 0)
                        ? last.clone().subtract(i, "month")
                        : last.clone().subtract(i, "month").endOf("month");

                    requests.push({
                        month: last.clone().subtract(i, "month").startOf("month"),
                        request: {
                            created_from: from.format("YYYY-MM-DD 00:00:00"),
                            created_to: to.format("YYYY-MM-DD 23:59:59"),
                            isFiltered: true
                        }
                    });
                }
            }

            const [first, ...others]: any = await Promise.all([
                ...(!isLoadMore ? [fn(request, !isLoadMore)] : []),
                ..._.map(requests, async ({month, request}) => {
                    const summary = await fn(request, !isLoadMore);
                    return {
                        month,
                        summary,
                        request
                    };
                })
            ]);

            if (!isLoadMore) {
                setSummary(first);
                setData([...data, ...others]);
            } else {
                setData([...data, first, ...others]);
                setLoading(false);
            }
        },
        []
    );

    useEffect(() => {
        setData([]);
        if (_.isEqual(activeKey, type)) {
            callback(period, []);
        }
    }, [period, activeKey]);

    return {summary, data, loading, setData, callback};
}
