import {Block, Space, Typography} from "@bezlimit/bezlimit-ui";
import NumberFormat from "react-number-format";
import _ from "lodash";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {LayoutContext} from "../../../core/Contexts";
import "./style.scss";
// forms
import FormReplenishmentBonuses from "../../forms/replenishmentOfBalanceBonuses";
// services
import BonusesService from "../../../services/BonusesService";

export default React.memo(function ReplenishmentOfBalanceBonuses() {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();
    return (
        <Typography as="div" className="bl-replenishment-balanse-bonuses">
            <Space
                size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 24}
                direction="vertical"
                align="center"
            >
                <Typography as="div">
                    <Space size={16} direction="vertical" align="center">
                        <Typography
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                            level={2}
                            color="#000000"
                        >
                            {t("Пополнение баланса бонусами")}
                        </Typography>
                        <Typography color="#000000" className="subtitle">
                            {" "}
                            {t("Пополнение баланса номера с бонусного счета")}{" "}
                        </Typography>
                        <Block>
                            <Space direction="vertical">
                                <Typography size={12} fontFamily="hauss" color="#CFC8DB">
                                    {t("Доступно для списания")}
                                </Typography>
                                <Typography as="div" size={24} fontFamily="alumna">
                                    <NumberFormat
                                        className="format-accumulated-sum"
                                        thousandSeparator=" "
                                        displayType="text"
                                        value={Math.floor(BonusesService.accumulated)}
                                    />
                                </Typography>
                            </Space>
                        </Block>
                    </Space>
                </Typography>
                <FormReplenishmentBonuses />
            </Space>
        </Typography>
    );
});
