import {observer} from "mobx-react";
import {Select} from "antd";
import {Typography, Radio, Button} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {isDesktop, isMobile, isMobileSafari} from "react-device-detect";
//store
import {IBank, IPaySBPRequest} from "../../../core/models/Bank";
import "./style.scss";
import {useContext, useEffect, useRef, useState} from "react";
import {InterceptorContext, LayoutContext, ModalContext} from "../../../core/Contexts";
import BanksService from "../../../services/BanksService";
import QRCodeModal from "../qrModal";
import Error from "../error";
import SbpModal from "../sbpModal";

export const BankListModal = observer(({phone, sum}: IProps) => {
    const {t} = useTranslation();
    const [value, setValue] = useState<string>();
    const select = useRef<any>();
    const context = useContext(InterceptorContext);
    const contextModal = useContext(ModalContext);
    const contextLayout = useContext(LayoutContext);
    const mountedRef = useRef(true);
    const banks: IBank[] | undefined = BanksService.items;
    const [open, setOpen] = useState(true);

    const [listHeight, setListHeight] = useState(456);

    const onChange = (value: string) => {
        setValue(value);
    };

    const setReadOnly = () => {
        if (isMobile && !isMobileSafari) {
            const el1 = document.getElementsByClassName(
                "ant-select-selection-search-input"
            )[0] as HTMLInputElement;
            el1.readOnly = true;
        }
    };

    const clearReadOnly = () => {
        if (isMobile && !isMobileSafari) {
            const el1 = document.getElementsByClassName(
                "ant-select-selection-search-input"
            )[0] as HTMLInputElement;
            el1.readOnly = false;
        }
    };

    const onClear = () => {
        setValue("");
        clearReadOnly();
    };

    const onCancel = () => {
        setValue(undefined);
        contextModal?.setCssClass("");
        contextModal?.setVisible(false);
    };

    const onCancelQR = () => {
        setValue(undefined);
        contextModal?.setCssClass(" bl-modal-bank-list");
        contextModal?.setChildren(<BankListModal phone={phone} sum={sum} />);
        contextModal?.setOnCancel(() => onCancel);
    };

    const payLinkForMobile = (link: string, paymentId: number) => {
        const res = window.open(link, "_self");
        console.log("qq open", res?.location.href);

        contextModal?.setCssClass(" bl-modal-sbp");
        contextModal?.setChildren(<SbpModal paymentId={paymentId} phone={phone} />);
        contextModal?.setOnCancel(() => onCancelQR);
    };

    const qrMobileForDesktop = (imgSrc: string, paymentId: number) => {
        contextModal?.setCssClass(" bl-modal-qrcode");
        contextModal?.setChildren(
            <QRCodeModal imgSrc={imgSrc} paymentId={paymentId} phone={phone} />
        );
        contextModal?.setOnCancel(() => onCancelQR);
    };

    const onPay = async () => {
        if (!phone || !sum) {
            console.log("ошибка Телефон, сумма:", phone, sum);
            return;
        }

        const id = banks?.find((item) => item.name === value)?.id;
        if (_.isNil(id)) {
            console.log("ошибка нет id для банка", value);
            return;
        }

        const request: IPaySBPRequest = {
            phone: phone,
            amount: sum,
            comment: `Пополнение баланса ${phone} на сумму ${sum} через Store.`,
            sbp_bank_id: id
        };

        try {
            const res = await BanksService.postPaySBP(request);
            res?.sbpPaymentUrl && isMobile && payLinkForMobile(res.sbpPaymentUrl, res.payment_id);
            res?.sbpQrUrl && isDesktop && qrMobileForDesktop(res.sbpQrUrl, res.payment_id);
        } catch {
            contextModal?.setCssClass(" ");
            contextModal?.setChildren(<Error />);
        }
    };

    const getBankList = async () => {
        if (!mountedRef.current) {
            return;
        }
        await BanksService.getBanks();
    };

    useEffect(() => {
        let height = _.gt(contextLayout?.width ?? 0, 490) ? 456 : 376;
        if (_.lte(screen.height, 600)) {
            height = 188;
        }
        listHeight !== height && setListHeight(height);
    }, [screen.height]);

    useEffect(() => {
        getBankList();
        return () => {
            mountedRef.current = false;
        };
    }, []);

    return (
        <>
            <Typography className="bank-title">{t("Выберите банк")}</Typography>
            <Select
                notFoundContent={<></>}
                getPopupContainer={() => document.body.querySelector(".bank-list-select")!}
                dropdownAlign={{overflow: {adjustX: false, adjustY: false}}}
                placement="bottomLeft"
                open={open}
                ref={(ref: any) => (select.current = ref)}
                autoFocus={false}
                showArrow={false}
                defaultOpen={true}
                listHeight={listHeight}
                value={value}
                className="bank-list-select"
                showSearch={true}
                allowClear={true}
                bordered={false}
                popupClassName="bank-list-dropdown"
                placeholder="Введите название банка"
                optionLabelProp="label"
                onChange={onChange}
                onSelect={setReadOnly}
                onClear={onClear}
                onFocus={clearReadOnly}
                onBlur={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                }}
                onDropdownVisibleChange={(visible) => {
                    !visible && setOpen(true);
                }}
            >
                {_.chain(banks)
                    .map((item) => (
                        <Select.Option
                            className="bank-list-option"
                            key={item.id}
                            value={item.name}
                            label={item.name}
                        >
                            <div className="bank-list-row">
                                <Typography size={18} color="#000">
                                    <span className="bank-list-icon">
                                        <img src={item.image} width={30} alt={"logo"} />
                                    </span>
                                    {t(item.name)}
                                </Typography>
                                <Radio key={item.id} checked={false} />
                            </div>
                        </Select.Option>
                    ))
                    .valueOf()}
            </Select>
            <div className="bank-list-holder" />
            <Button
                className="bank-list-btn"
                block
                type="primary"
                onClick={onPay}
                loading={context?.loadingBtn}
                disabled={!value}
            >
                {t("Оплатить")}
            </Button>
            <Button className="bank-cancel-btn" block type="link" onClick={onCancel}>
                {t("Отмена")}
            </Button>
        </>
    );
});

interface IProps {
    phone?: any;
    sum?: any;
}
