import {useContext, useEffect} from "react";
import {Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {FormInstance, FormItemProps} from "antd";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext} from "../../../core/Contexts";
// components
import UploadField from "../upload";
import ReservationsService from "../../../services/ReservationsService";

export default function PassportUploadField({props, form, title, subtitle}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();

    useEffect(() => {
        ReservationsService.getReservationsPhotos(+location.pathname.split("/")[3]);
    }, []);

    return (
        <Typography as="div" className="bl-field-passport-upload">
            <Space direction="vertical" size={16}>
                <Typography as="div" className="header">
                    <Space
                        direction="vertical"
                        size={_.gte(contextLayout?.width ?? 0, 768) ? 8 : 0}
                    >
                        <Typography as="div" color="#fff" size={24} className="title">
                            {title}
                        </Typography>
                        <Typography as="div" color="#fff" size={14} className="subtitle">
                            {subtitle}
                        </Typography>
                    </Space>
                </Typography>
                {_.gte(contextLayout?.width ?? 0, 768) ? null : (
                    <Typography as="div" className="subheader" color="#fff" size={17}>
                        {t("Фото паспорта")}
                    </Typography>
                )}

                <Typography as="div" className="content">
                    <Space
                        direction={
                            _.gte(contextLayout?.width ?? 0, 375) ? "horizontal" : "vertical"
                        }
                        size={_.gte(contextLayout?.width ?? 0, 768) ? 16 : 14}
                        align="center"
                    >
                        <UploadField
                            {...props}
                            form={form}
                            name="passport"
                            title="Разворот с фото"
                        />
                        <UploadField
                            {...props}
                            form={form}
                            name="passport_second"
                            title="Разворот с регистрацией"
                        />
                    </Space>
                </Typography>
            </Space>
        </Typography>
    );
}

interface IProps {
    form: FormInstance<any>;
    props?: FormItemProps<any>;
    title?: string;
    subtitle?: string;
}
