import {Block, Button, Space, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import {Link} from "react-router-dom";
// core
import date from "../../../../../core/functions/date";

export default observer(function BlContent({
    id,
    published_date,
    title,
    preview_text,
    preview_image,
    is_read
}: IProps) {
    const {t} = useTranslation();
    const dateFormat: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
    };

    return (
        <Block className="bl-list-news">
            <div className="bl-list-news-page-fallback-images"></div>
            <div
                className="bl-list-news-page-wrapper-images"
                style={{
                    backgroundImage: `url(${preview_image})`
                }}
            ></div>
            <Space direction="vertical" className="spase-content-news">
                {!is_read && (
                    <Typography className="bl-list-news__red-circle" as={"div"}></Typography>
                )}
                <Typography
                    className="published-date"
                    color="rgba(255, 255, 255, 0.5)"
                    size={17}
                    appearance="medium"
                >
                    {date(published_date, dateFormat)}
                </Typography>
                <Typography className="block-title" size={17} appearance="bold">
                    {t(title)}{" "}
                </Typography>
                <Typography className="preview-text">{t(preview_text)}</Typography>

                <Button>
                    <Link to={`/news/${id}`} className="table-title-button">
                        {t("Подробнее")}
                    </Link>
                </Button>
            </Space>
        </Block>
    );
});

interface IProps {
    published_date: string;
    title: string;
    preview_text: string;
    id: number;
    preview_image: string;
    is_read: boolean;
}
