import {Block, Button, Space, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {useTranslation} from "react-i18next";
// services
import DeliveryService from "../../../../services/DeliveryService";
// images
import ErrorImg from "../../../images/errorImg";
// components
import ButtonChatSupport from "../buttonChatSupport";
import GroupDeliveryColapse from "../groupDeliveryColapse";
import TitleDeliveries from "../titleDeliveries";

export default function ArchivedDelivery({activeKey, onTabClick}: IProps) {
    const {t} = useTranslation();

    const onClick = () => {
        onTabClick("deliveries/new");
    };

    return (
        <>
            {_.isEqual(DeliveryService.itemsSize, 0) ? (
                <Block className="bl-default-delivery">
                    <Typography as="div" className="text">
                        <Space direction="vertical" size={8}>
                            <Typography as="div" className="images">
                                <ErrorImg />
                            </Typography>

                            <Typography className="title-default">
                                {t("У вас пока нет архивных заказов")}
                            </Typography>
                            <Typography className="sub-title-default">
                                {t("Когда вы получите посылку, здесь появится ваш заказ")}
                            </Typography>
                        </Space>
                    </Typography>
                    <Typography as="div" className="btn-default">
                        <Button onClick={onClick}> {t("Новая доставка")} </Button>
                    </Typography>
                </Block>
            ) : (
                <>
                    <Block className="bl-my-deliveries">
                        <TitleDeliveries />
                        <GroupDeliveryColapse activeKey={activeKey} onTabClick={onTabClick} />
                    </Block>
                    <ButtonChatSupport />
                </>
            )}
        </>
    );
}

interface IProps {
    activeKey: string;
    onTabClick: (key: string, recipient?: any) => void;
}
