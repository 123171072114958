import React, {useContext} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import "./style.scss";
// components
import Phone from "./phone";
import Info from "./info";
import BookANumberForm from "../../forms/bookANumber";
// core
import {LayoutContext} from "../../../core/Contexts";
import {IPhonesFiltersRequest} from "../../../core/models/PhonesFilters";
// services
import PhoneService from "../../../services/PhoneService";

export default React.memo(function NumberListInfoModal({
    phone,
    tariffName,
    minutes,
    internet,
    category,
    type,
    sms,
    id,
    filters,
    Service
}: IProps) {
    const contextLayout = useContext(LayoutContext);

    return (
        <Typography as="div" className="bl-number-list-info-modal">
            <Space
                size={_.gte(contextLayout?.width ?? 0, 768) ? 56 : 44}
                direction="vertical"
                align="center"
            >
                <Space
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 32 : 24}
                    direction="vertical"
                    align="center"
                >
                    <Phone phone={phone} tariffName={tariffName} category={category} />
                    <Info minutes={minutes} internet={internet} sms={sms} />
                </Space>
                {filters && Service && (
                    <BookANumberForm
                        phone={phone}
                        type={type}
                        id={id}
                        filters={filters}
                        Service={Service}
                    />
                )}
            </Space>
        </Typography>
    );
});

interface IProps {
    phone: number;
    tariffName: string;
    minutes: number;
    internet: number;
    category?: string;
    type: number;
    sms: number;
    id: number;
    filters?: IPhonesFiltersRequest;
    Service?: PhoneService;
}
