import {
    Button,
    Checkbox,
    Drawer,
    Form,
    Icon,
    Input,
    Modal,
    Typography
} from "@bezlimit/bezlimit-ui";
import "./style.scss";

import {ReactNode, useContext, useEffect, useState} from "react";
import {useNavigate} from "react-router-dom";
import {axiosFetchBuilder} from "../../../../../../core/Axios";
import {IReservations, IReservationsResponse} from "../../../../../../core/models/Reservations";
import _ from "lodash";
import List from "./list";
import emptyImg from "../../../../../../images/empty-result.png";
import npsresr from "../../../../../../images/pnsreser4.svg";
import Edit from "./edit";
import {LayoutContext} from "../../../../../../core/Contexts";

export default function Nps(props: IProps) {
    const [open, setOpen] = useState(false);
    const [empty, setEmpty] = useState<ReactNode>();
    const navigate = useNavigate();
    const [form] = Form.useForm();
    const [state, setState] = useState<IReservationsResponse>();
    const [loading, setLoading] = useState(false);
    const [loadingNps] = useState(false);
    const [edit, setEdit] = useState(false);
    const [removed, setRemoved] = useState(false);
    const contextLayout = useContext(LayoutContext);

    const onClose = () => {
        form.setFieldValue("isSubmit", 0);
        form.submit();
        setOpen(false);
    };

    const onSubmit = () => {
        form.setFieldValue("isSubmit", 1);
        form.submit();
    };

    const onClick = async () => {
        const hideNps = localStorage.getItem("hideNps");

        if (!hideNps) {
            setOpen(true);
        } else {
            try {
                await parallel();
            } finally {
            }
        }
    };

    const getReservations = async (page: number): Promise<IReservationsResponse> => {
        return await axiosFetchBuilder({
            url: "/reservations",
            loading: false,
            params: {
                page,
                status: "nps",
                fields: "phone_number"
            }
        });
    };

    const parallel = async () => {
        try {
            const res: IReservationsResponse = await getReservations(1);

            if (!!res._meta.pageCount && !_.isEqual(res._meta.currentPage, res._meta.pageCount)) {
                const pages = _.range(res._meta.currentPage, res._meta.pageCount);
                const items = _.chain(await Promise.all(pages.map((i) => getReservations(i + 1))))
                    .reduce<IReservations[]>((res, i) => {
                        res = [...res, ...i.items];
                        return res;
                    }, [])
                    .valueOf();
                res.items = [...res.items, ...items];
            }

            if (res?.items?.length) {
                setState(res);
                setOpen(false);
                setRemoved(false);
            } else {
                setOpen(true);
                setEmpty(
                    <Typography as="div" className="empty-result">
                        <img src={emptyImg} alt="" />
                        <Typography
                            as="div"
                            className="empty-title"
                        >{`На данный момент у вас\nнет номеров,`}</Typography>
                        <Typography
                            as="div"
                            className="empty-text"
                        >{`которые можно подключить по акции\n«Не плати за связь». Активируйте новые номера,\nчтобы использовать акцию.`}</Typography>
                        <Typography as="div" className="empty-actions">
                            <Button type="primary" onClick={onClose}>
                                Закрыть
                            </Button>
                        </Typography>
                    </Typography>
                );
            }
        } catch (error) {}
    };

    const onFinish = async ({isSubmit, hideNps}: any) => {
        if (!!isSubmit) {
            try {
                setLoading(true);
                await parallel();
            } finally {
                setLoading(false);
            }
        }

        if (!!hideNps) {
            localStorage.setItem("hideNps", "1");
        }
    };

    useEffect(() => {
        if (!open) {
            setTimeout(() => {
                form.resetFields();
                setEmpty(null);
            }, 1000);
        }
    }, [open]);

    useEffect(() => {
        if (edit) {
            const f = async () => {
                await parallel();
            };
            f();
        }
    }, [edit]);

    useEffect(() => {
        if (
            props.npsPhone &&
            props.reservations &&
            _.get(props.reservations, "additional_data.nps.phone")
        ) {
            const f = async () => {
                await parallel();
            };
            f();
        }
    }, [props.reservations]);

    return (
        <Typography as="div" className="bl-nps-reservation">
            {props.npsPhone && !edit ? (
                <Edit
                    {...props}
                    setRemoved={setRemoved}
                    setEdit={setEdit}
                    phone={
                        props.npsPhone ?? _.get(props.reservations, "additional_data.nps.phone", "")
                    }
                />
            ) : (
                <List
                    {...props}
                    setEdit={setEdit}
                    removed={removed}
                    loadingNps={loadingNps}
                    state={state}
                    onClick={onClick}
                />
            )}

            {_.gt(contextLayout?.width, 768) ? (
                <Drawer
                    width={634}
                    className="bl-nps-reservation-drawer"
                    placement="right"
                    destroyOnClose={true}
                    onClose={onClose}
                    open={open}
                    maskClosable={false}
                    title={
                        !empty ? (
                            <Button
                                onClick={() => {
                                    navigate("/nps");
                                }}
                                icon={<Icon name="hint_header" />}
                            >
                                <Typography as="div" className="hint" />
                            </Button>
                        ) : (
                            false
                        )
                    }
                    closeIcon={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="30"
                            height="30"
                            viewBox="0 0 30 30"
                            fill="none"
                        >
                            <path
                                d="M29 1L15.0458 15M15.0458 15L1 29M15.0458 15L1 1M15.0458 15L29 29"
                                stroke="#E5E5E5"
                                stroke-width="2"
                                stroke-linecap="round"
                            />
                        </svg>
                    }
                >
                    {!empty ? (
                        <>
                            <Typography as="div" className="top">
                                <Typography as="div" className="text">
                                    Используй акцию
                                </Typography>
                                <Typography as="div" className="title">
                                    Не плати за связь!
                                </Typography>
                                <Typography as="div" className="text">
                                    Максимальный доход с минимумом усилий
                                </Typography>
                            </Typography>
                            <Typography as="div" className="bottom">
                                <Typography as="div" className="text">
                                    <b>Хочешь еще больше активаций?</b> Расскажи клиентам
                                    <br />о новой акции, подключи их друзей и заработай!
                                </Typography>
                                <Form
                                    form={form}
                                    layout="horizontal"
                                    autoComplete="off"
                                    initialValues={{hideNps: false, isSubmit: 1}}
                                    onFinish={onFinish}
                                >
                                    <Form.Item name="isSubmit" hidden={true}>
                                        <Input value={1} />
                                    </Form.Item>
                                    <Form.Item name="hideNps" valuePropName="checked">
                                        <Checkbox>Больше не показывать</Checkbox>
                                    </Form.Item>
                                    <Form.Item noStyle shouldUpdate>
                                        {() => {
                                            return (
                                                <Typography as="div" className="actions">
                                                    <Button
                                                        type="primary"
                                                        onClick={onSubmit}
                                                        loading={loading}
                                                    >
                                                        Привязать номер
                                                    </Button>
                                                    <Button onClick={onClose}>Закрыть</Button>
                                                </Typography>
                                            );
                                        }}
                                    </Form.Item>
                                </Form>
                            </Typography>
                        </>
                    ) : (
                        empty
                    )}
                </Drawer>
            ) : (
                <Modal
                    title={
                        !empty ? (
                            <Button
                                onClick={() => {
                                    navigate("/nps");
                                }}
                                icon={<Icon name="hint_header" />}
                            >
                                <Typography as="div" className="hint" />
                            </Button>
                        ) : (
                            false
                        )
                    }
                    className="bl-nps-reservation-drawer"
                    open={open}
                    footer={null}
                    maskClosable={false}
                    destroyOnClose={true}
                    onCancel={onClose}
                    maskStyle={{
                        background:
                            "linear-gradient(0deg, rgba(0, 0, 0, 0.40) 0%, rgba(0, 0, 0, 0.40) 100%), linear-gradient(0deg, rgba(100, 40, 120, 0.65) 0%, rgba(100, 40, 120, 0.65) 100%), rgba(255, 255, 255, 0.05)"
                    }}
                    closeIcon={
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="22"
                            height="21"
                            viewBox="0 0 22 21"
                            fill="none"
                        >
                            <path
                                d="M21 0.5L11.0327 10.5M11.0327 10.5L1 20.5M11.0327 10.5L1 0.5M11.0327 10.5L21 20.5"
                                stroke="white"
                                stroke-linecap="round"
                            />
                        </svg>
                    }
                >
                    {!empty ? (
                        <Typography as="div" className="inner-content">
                            <img src={npsresr} width={120} alt="" />
                            <Typography as="div" className="top">
                                <Typography as="div" className="text">
                                    Используй акцию
                                </Typography>
                                <Typography as="div" className="title">
                                    Не плати за связь!
                                </Typography>
                                <Typography as="div" className="text text2">
                                    Больше активаций и меньше усилий
                                </Typography>
                            </Typography>
                            <Typography as="div" className="bottom">
                                <Typography as="div" className="text">
                                    {
                                        "Хочешь еще больше активаций?\nРасскажи клиентам о новой акции,\nподключи их друзей и заработай! "
                                    }
                                </Typography>
                                <Form
                                    form={form}
                                    layout="horizontal"
                                    autoComplete="off"
                                    initialValues={{hideNps: false, isSubmit: 1}}
                                    onFinish={onFinish}
                                >
                                    <Form.Item name="isSubmit" hidden={true}>
                                        <Input value={1} />
                                    </Form.Item>
                                    <Form.Item name="hideNps" valuePropName="checked">
                                        <Checkbox>Больше не показывать</Checkbox>
                                    </Form.Item>
                                    <Form.Item noStyle shouldUpdate>
                                        {() => {
                                            return (
                                                <Typography as="div" className="actions">
                                                    <Button
                                                        type="primary"
                                                        onClick={onSubmit}
                                                        loading={loading}
                                                    >
                                                        Привязать номер
                                                    </Button>
                                                    <Button onClick={onClose}>Закрыть</Button>
                                                </Typography>
                                            );
                                        }}
                                    </Form.Item>
                                </Form>
                            </Typography>
                        </Typography>
                    ) : (
                        <Typography as="div" className="empty-result">
                            <img src={emptyImg} alt="" />
                            <Typography
                                as="div"
                                className="empty-title"
                            >{`На данный момент\nу вас нет номеров,`}</Typography>
                            <Typography
                                as="div"
                                className="empty-text"
                            >{`которые можно подключить\nпо акции «Не плати за связь».\nАктивируйте новые номера,\nчтобы использовать акцию.`}</Typography>
                            <Typography as="div" className="empty-actions">
                                <Button type="primary" onClick={onClose}>
                                    Закрыть
                                </Button>
                            </Typography>
                        </Typography>
                    )}
                </Modal>
            )}
        </Typography>
    );
}

interface IProps {
    npsPhone: string | undefined;
    reservations: IReservations | undefined;
    setNpsPhone: React.Dispatch<React.SetStateAction<string | undefined>>;
}
