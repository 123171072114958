import React from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import "./style.scss";
// core
import currency from "../../../../../../../core/functions/currency";

export default React.memo(
    observer(function Paid({maskPrice}: IProps) {
        return (
            <Typography as="span" size={18} color="#fff" className="bl-numbers-list-actions-paid">
                {currency(maskPrice)}
            </Typography>
        );
    })
);

interface IProps {
    maskPrice: number;
}
