import React, {useContext, useEffect, useState} from "react";
import {Typography, Space, Icon, Breadcrumb} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext} from "../../../../core/Contexts";
import {useTranslation} from "react-i18next";

const defaultOptions = {
    color: "#642878"
};

export default React.memo(function Phone({phone, tariffName, category}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();
    const [state, setState] = useState<string>("Обычный");

    useEffect(() => {
        let name: string;

        switch (category) {
            case "bronze AAA":
                name = "Бронза ААА";
                break;
            case "bronze":
                name = "Бронза";
                break;
            case "silver":
                name = "Серебро";
                break;
            case "silver_special":
                name = "Серебро-супер";
                break;
            case "gold":
                name = "Золото";
                break;
            case "platinum":
                name = "Платина";
                break;
            case "brilliant":
                name = "Бриллиант";
                break;
            default:
                name = "Обычный";
                break;
        }

        setState(name);
    }, [category]);

    return (
        <Typography as="div" className="bl-number-list-info-modal-phone">
            <Space size={0} direction="vertical" align="center">
                <Typography
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 48 : 32}
                    color="#642878"
                    fontFamily="alumna"
                    className="phone"
                >
                    {phone}
                </Typography>
                <Breadcrumb separator={<Icon name="dot-purple" size="5" />}>
                    {category && (
                        <Breadcrumb.Item>
                            <Typography
                                as="span"
                                {...defaultOptions}
                                size={_.gte(contextLayout?.width ?? 0, 768) ? 17 : 14}
                            >
                                {t(state)}
                            </Typography>
                        </Breadcrumb.Item>
                    )}

                    <Breadcrumb.Item>
                        <Typography
                            as="span"
                            {...defaultOptions}
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 17 : 14}
                        >
                            {t(tariffName)}
                        </Typography>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Space>
        </Typography>
    );
});

interface IProps {
    phone: number;
    tariffName: string;
    category?: string;
}
