import {Form, Input} from "@bezlimit/bezlimit-ui";
import {FormItemProps} from "antd";
import {useTranslation} from "react-i18next";
// rules
import {Rules} from "../../forms/rules";

export default function EmailField({className, ...props}: FormItemProps<any>) {
    const {t} = useTranslation();

    return (
        <Form.Item
            {...props}
            hasFeedback
            className={className ?? "field-email"}
            name="email"
            rules={[Rules.email(), Rules.max(100)]}
        >
            <Input title={t("Электронная почта")} autoComplete="new-email" allowClear />
        </Form.Item>
    );
}
