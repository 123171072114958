import {makeAutoObservable} from "mobx";
import {ILoyaltyResponse, ILoyalty} from "../core/models/Loyalty";

class Loyalty implements ILoyaltyResponse {
    [Symbol.toStringTag] = this.constructor.name;
    private _items!: ILoyalty[];

    constructor() {
        makeAutoObservable(this);
    }

    get items() {
        return this._items;
    }

    set items(value: ILoyalty[]) {
        this._items = value;
    }
}

export default Loyalty;
