import React, {useContext, useRef} from "react";
import {useNavigate} from "react-router-dom";
import {Button, Form, Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// core
import {IRestorePasswordRequest} from "../../../core/models/Auth";
import {LayoutContext, UserContext} from "../../../core/Contexts";
import {getFieldsErrorSize} from "../../../core/functions/form";
// services
import UserService from "../../../services/UserService";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
import useFieldFocus from "../../../core/hooks/useFieldFocus";
// components
import EmailAndPhoneField from "../../fields/emailAndPhone";

export default React.memo(
    observer(function ResetPasswordForm() {
        const context = useContext(LayoutContext);
        const contextUser = useContext(UserContext);
        const ref = useRef(null);
        const {t} = useTranslation();
        const navigate = useNavigate();
        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        useFieldFocus(ref, "email");

        const onFinish = async (values: IRestorePasswordRequest) => {
            const putRestorePassword = Object.values(values);
            await UserService.putRestorePassword(
                Number.isInteger(+putRestorePassword[0])
                    ? {
                          phone: Number(
                              values.email!.replace(/\+/g, "").length === 11
                                  ? values.email!.replace(/\+/g, "").substring(1)
                                  : values.email!.replace(/\+/g, "")
                          )
                      }
                    : {email: values.email}
            );
            !Number.isInteger(+putRestorePassword[0]) &&
                contextUser?.setEmail(putRestorePassword[0]);
            contextUser?.setPhone(undefined);
            Number.isInteger(+putRestorePassword[0].replace(/\+/g, "")) &&
                contextUser?.setPhone(putRestorePassword[0]);
            formInstance.resetFields();
            navigate("/new-password");
        };

        return (
            <div ref={ref}>
                <Typography as="div" className="bl-reset-password-form">
                    <Form
                        form={formInstance}
                        layout="horizontal"
                        name="reset-password"
                        onFinish={onFinish}
                        autoComplete="off"
                        initialValues={{
                            email: ""
                        }}
                    >
                        <Space
                            size={_.gte(context?.width ?? 0, 768) ? 16 : 24}
                            direction="vertical"
                        >
                            <Typography as="div">
                                <EmailAndPhoneField />
                            </Typography>
                            <Typography as="div">
                                <Form.Item noStyle shouldUpdate>
                                    {({getFieldsValue, getFieldsError}) => {
                                        const {email} = getFieldsValue();
                                        const errors = getFieldsErrorSize(getFieldsError);
                                        const formIsComplete = !!email && !errors;

                                        return (
                                            <Button
                                                type={!formIsComplete ? "default" : "primary"}
                                                htmlType="submit"
                                                disabled={!formIsComplete}
                                            >
                                                {t("Отправить")}
                                            </Button>
                                        );
                                    }}
                                </Form.Item>
                            </Typography>
                        </Space>
                    </Form>
                </Typography>
            </div>
        );
    })
);
