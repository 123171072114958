import React, {useContext} from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext} from "../../../../../core/Contexts";

export default React.memo(function Item({value, header}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();

    return (
        <Typography
            as="div"
            className="bl-number-list-info-modal-info-item"
            size={_.gte(contextLayout?.width ?? 0, 768) ? 17 : 16}
            color="#1F1F1F"
        >
            <Typography
                as="div"
                className="header"
                size={_.gte(contextLayout?.width ?? 0, 768) ? 14 : 12}
                color="#828282"
            >
                {t(header)}
            </Typography>
            {_.isEqual(value, 0) ? "Безлимит" : value}
        </Typography>
    );
});

interface IProps {
    value: number;
    header: string;
}
