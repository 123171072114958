import {Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";

export default function LabelIconContent({src, label}: IProps) {
    const {t} = useTranslation();

    return (
        <Typography as="div" className="bl-labelMenuRoute__icon-content">
            <img width="25" height="25" src={src} alt="icon" />
            <Typography as="span" fontFamily="alumna" size={18} color="black">
                {t(label)}
            </Typography>
        </Typography>
    );
}

interface IProps {
    src: string;
    label: string;
}
