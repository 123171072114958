import React, {useContext, useMemo, useRef, useState} from "react";
import {Form, Input, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import _ from "lodash";
import closePng from "../../../images/close-lottery.svg";
import closePngMob from "../../../images/close-lottery-mob.svg";
import {LayoutContext} from "../../../core/Contexts";
import LotteryPhonesService from "../../../services/LotteryPhonesService";
import {observer} from "mobx-react";

export default observer(function Search({setLoading, setIsSearch}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const [form] = Form.useForm();
    const ref1 = useRef<HTMLDivElement>(null);
    const ref2 = useRef<HTMLDivElement>(null);
    const ref3 = useRef<HTMLDivElement>(null);
    const [b1, setB1] = useState("00");
    const [b2, setB2] = useState("00");
    const [b3, setB3] = useState("00");
    const isMobile = _.lt(contextLayout?.width ?? 0, 993);

    const debounceFetcher = useMemo(() => {
        const batchLog = async (query: string) => {
            try {
                setLoading(true);
                setIsSearch(true);
                await LotteryPhonesService.fetchlotteryPhones(
                    {
                        ...(!!query ? {query} : {})
                    },
                    false
                );
            } catch (error) {
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 1000);
            }
        };

        return _.debounce(batchLog, 200);
    }, [LotteryPhonesService.fetchlotteryPhones, 200]);

    const onValuesChange = async ({num1, num2, num3}: any, values: any) => {
        if (!_.isNil(num1)) {
            setB1(!!num1 ? num1 : "00");

            if (_.isEqual(num1.length, 2)) {
                ref2.current!.querySelector("input")?.focus();
            }
        }
        if (!_.isNil(num2)) {
            setB2(!!num2 ? num2 : "00");

            if (_.isEqual(num2.length, 2)) {
                ref3.current!.querySelector("input")?.focus();
            }
        }
        if (!_.isNil(num3)) {
            setB3(!!num3 ? num3 : "00");
        }

        if (!values.num1) {
            form.setFieldValue("num2", "");
            form.setFieldValue("num3", "");
            setB2("00");
            setB3("00");
        }
        if (!values.num2) {
            form.setFieldValue("num3", "");
            setB3("00");
        }

        const query = _.join(_.filter(_.values(values), Boolean), ".");

        debounceFetcher(query);
    };

    const normalize = (value: any) => {
        return value.replace(/[^\d]/gim, "").substring(0, 2);
    };

    const onClick = (ref: React.RefObject<HTMLDivElement>) => {
        ref.current?.querySelector("input")?.focus();
    };

    const onReset = () => {
        form.setFieldValue("num1", "");
        form.setFieldValue("num2", "");
        form.setFieldValue("num3", "");
        setB1("00");
        setB2("00");
        setB3("00");
        ref1.current!.querySelector("input")?.focus();
        setIsSearch(false);
        LotteryPhonesService.fetchlotteryPhones();
    };

    const onKeyDown = (value: string, ref?: React.RefObject<HTMLDivElement>, e?: any) => {
        if (e.key === "Backspace" && _.isEmpty(value) && !value && !!ref) {
            setTimeout(() => {
                ref.current!.querySelector("input")?.focus();
            }, 100);
        } else if (/[^\d]/gim.test(e.key) && e.key !== "Backspace") {
            e.preventDefault();
        }
    };

    return (
        <Typography as="div" className="bl-lottery-search-container">
            <Form
                form={form}
                layout="horizontal"
                onValuesChange={onValuesChange}
                autoComplete="off"
            >
                <svg
                    width="82"
                    height="82"
                    viewBox="0 0 82 82"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M49.878 49.8825C52.7334 47.033 54.5 43.0938 54.5 38.7422C54.5 30.048 47.4485 23 38.75 23C30.0515 23 23 30.048 23 38.7422C23 47.4363 30.0515 54.4843 38.75 54.4843C43.0947 54.4843 47.0285 52.726 49.878 49.8825ZM49.878 49.8825L59 59"
                        stroke="white"
                        stroke-opacity="0.65"
                        stroke-width="3"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                    />
                </svg>

                <div ref={ref1} className="input-container">
                    <Typography as="div" className="boll b1" onClick={onClick.bind(null, ref1)}>
                        {b1}
                    </Typography>
                    <Form.Item name="num1" normalize={normalize}>
                        <Input
                            autoComplete="new-num1"
                            placeholder="XX"
                            onKeyDown={onKeyDown.bind(null, form.getFieldValue("num1"), undefined)}
                        />
                    </Form.Item>
                </div>
                <div
                    ref={ref2}
                    className={`input-container${
                        _.isEqual(_.size(form.getFieldValue("num1")), 0) ? " disabled" : ""
                    }`}
                >
                    <Typography as="div" className="boll b2" onClick={onClick.bind(null, ref2)}>
                        {b2}
                    </Typography>
                    <Form.Item name="num2" normalize={normalize}>
                        <Input
                            autoComplete="new-num2"
                            placeholder="XX"
                            onKeyDown={onKeyDown.bind(null, form.getFieldValue("num2"), ref1)}
                        />
                    </Form.Item>
                </div>
                <div
                    ref={ref3}
                    className={`input-container${
                        _.isEqual(_.size(form.getFieldValue("num2")), 0) ? " disabled" : ""
                    }`}
                >
                    <Typography as="div" className="boll b3" onClick={onClick.bind(null, ref3)}>
                        {b3}
                    </Typography>
                    <Form.Item name="num3" normalize={normalize}>
                        <Input
                            autoComplete="new-num3"
                            placeholder="XX"
                            onKeyDown={onKeyDown.bind(null, form.getFieldValue("num3"), ref2)}
                        />
                    </Form.Item>
                </div>
                {!_.isEqual(_.size(form.getFieldValue("num2")), 0) && (
                    <Typography as="div" className="close-container">
                        <img src={isMobile ? closePngMob : closePng} alt="" onClick={onReset} />
                    </Typography>
                )}
            </Form>
            <Typography as="div" className="text">
                Введите последовательно числа кода для поиска
            </Typography>
        </Typography>
    );
});

interface IProps {
    setLoading: React.Dispatch<React.SetStateAction<boolean>>;
    setIsSearch: React.Dispatch<React.SetStateAction<boolean>>;
}
