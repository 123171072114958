import React from "react";
import {Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import "./style.scss";
// core
import NumberFormat from "react-number-format";

export default React.memo(
    observer(function Paid({maskPrice}: IProps) {
        return (
            <Typography as="span" size={18} color="#fff" className="bl-numbers-list-actions-paid">
                <NumberFormat
                    readOnly={true}
                    displayType="text"
                    value={maskPrice}
                    decimalSeparator=","
                    thousandSeparator=" "
                    suffix=" ₽"
                />
            </Typography>
        );
    })
);

interface IProps {
    maskPrice: number;
}
