import {Col, Layout, Row} from "@bezlimit/bezlimit-ui";
import "./style.scss";
//components
import Burger from "./burger";
import Info from "./info";
import Logo from "./logo";

export default function Header({toggle}: IProps) {
    return (
        <Layout.Header className="bl-header">
            <Row>
                <Col span={8}>
                    <Burger toggle={toggle} />
                </Col>
                <Col span={8}>
                    <Logo />
                </Col>
                <Col span={8}>
                    <Info />
                </Col>
            </Row>
        </Layout.Header>
    );
}

interface IProps {
    toggle: () => void;
}
