import {Button, Icon} from "@bezlimit/bezlimit-ui";

export default function Burger({toggle}: Props) {
    return (
        <div className="bl-burger item">
            <Button
                className="btn-burger"
                onClick={toggle}
                icon={<Icon fill="white" name={"burger"} size={"32px"} />}
            />
        </div>
    );
}

interface IProps {
    toggle: () => void;
}

type Props = IProps;
