//banners-small
import bronzeSmImgW1x from "../../../../../images/banner-bronze-sm@1x.webp";
import bronzeSmImgW2x from "../../../../../images/banner-bronze-sm@2x.webp";
import bronzeSmImg from "../../../../../images/banner-bronze-sm.png";
import silverSmImgW1x from "../../../../../images/banner-silver-sm@1x.webp";
import silverSmImgW2x from "../../../../../images/banner-silver-sm@2x.webp";
import silverSmImg from "../../../../../images/banner-silver-sm.png";
import goldSmImgW1x from "../../../../../images/banner-gold-sm@1x.webp";
import goldSmImgW2x from "../../../../../images/banner-gold-sm@2x.webp";
import goldSmImg from "../../../../../images/banner-gold-sm.png";
import topSmImgW1x from "../../../../../images/banner-top-sm@1x.webp";
import topSmImgW2x from "../../../../../images/banner-top-sm@2x.webp";
import topSmImg from "../../../../../images/banner-top-sm.png";

export const getLoyaltyBannerSmall = (code: string, name: string): JSX.Element => {
    switch(code){
        case "bronze":
            return (
                <picture>
                    <source type='image/webp' srcSet={`${bronzeSmImgW1x} 1x, ${bronzeSmImgW2x} 2x`}/>
                    <img src={bronzeSmImg} width={130} srcSet={`${bronzeSmImg} 2x`} alt={name}/>
                </picture>
            );
        case "silver":
            return (
                <picture>
                    <source type='image/webp' srcSet={`${silverSmImgW1x} 1x, ${silverSmImgW2x} 2x`}/>
                    <img src={silverSmImg} width={130} srcSet={`${silverSmImg} 2x`} alt={name}/>
                </picture>
            );
        case "gold":
            return (
                <picture>
                    <source type='image/webp' srcSet={`${goldSmImgW1x} 1x, ${goldSmImgW2x} 2x`}/>
                    <img src={goldSmImg} width={130} srcSet={`${goldSmImg} 2x`} alt={name}/>
                </picture>
            );
        case "top":
            return (
                <picture>
                    <source type='image/webp' srcSet={`${topSmImgW1x} 1x, ${topSmImgW2x} 2x`}/>
                    <img src={topSmImg} width={130} srcSet={`${topSmImg} 2x`} alt={name}/>
                </picture>
            );
        default:
            return <></>;
    }
}