import _ from "lodash";
// store
import Store from "../stores";
// services
import PhoneService from "./PhoneService";

class PhonesService {
    [Symbol.toStringTag] = this.constructor.name;
    protected _default: PhoneService = new PhoneService(Store.phones.default.default);
    protected _paid: PhoneService = new PhoneService(Store.phones.default.paid);
    protected _standard: PhoneService = new PhoneService(Store.phones.default.standard);
    protected _internet: PhoneService = new PhoneService(Store.phones.default.internet);

    public get default() {
        return this._default;
    }

    public get paid() {
        return this._paid;
    }

    public get standard() {
        return this._standard;
    }

    public get internet() {
        return this._internet;
    }

    public get objects() {
        const objects = [];

        if (this.paid.itemsSize) {
            objects.push(this.paid);
        }

        if (this.standard.itemsSize) {
            objects.push(this.standard);
        }

        if (this.internet.itemsSize) {
            objects.push(this.internet);
        }

        if (!_.size(objects)) {
            objects.push(this.default);
        }

        return objects;
    }

    public get isGrouped() {
        return this.paid.itemsSize || this.standard.itemsSize || this.internet.itemsSize;
    }

    public get isEqualSizeItemsAndSelectedRowKeys() {
        return _.isEqual(this.sizeAllItems, this.sizeSelectedRowKeys);
    }

    public get sizeAllItems() {
        return _.chain([
            ...(this.default.items ?? []),
            ...(this.standard.items ?? []),
            ...(this.paid.items ?? []),
            ...(this.internet.items ?? [])
        ])
            .size()
            .valueOf();
    }

    public get selectedRowKeysItems() {
        return _.chain([
            ...(this.default.items ?? []),
            ...(this.standard.items ?? []),
            ...(this.paid.items ?? []),
            ...(this.internet.items ?? [])
        ])
            .filter(({phone}) => _.includes(this.selectedRowKeys, phone))
            .valueOf();
    }

    public get selectedRowKeys() {
        return [
            ...(this.default.selectedRowKeys ?? []),
            ...(this.standard.selectedRowKeys ?? []),
            ...(this.paid.selectedRowKeys ?? []),
            ...(this.internet.selectedRowKeys ?? [])
        ];
    }

    public get sizeSelectedRowKeys() {
        return _.size(this.selectedRowKeys);
    }

    checkAll(checked: boolean) {
        if (checked) {
            this.default.setAllSelectedRowKeys();
            this.standard.setAllSelectedRowKeys();
            this.paid.setAllSelectedRowKeys();
            this.internet.setAllSelectedRowKeys();
        } else {
            this.default.resetSelectedRowKeys();
            this.standard.resetSelectedRowKeys();
            this.paid.resetSelectedRowKeys();
            this.internet.resetSelectedRowKeys();
        }
    }

    cleanUp() {
        if (this.default.itemsSize) {
            this.default.cleanUp();
        }
        if (this.paid.itemsSize) {
            this.paid.cleanUp();
        }
        if (this.standard.itemsSize) {
            this.standard.cleanUp();
        }
        if (this.internet.itemsSize) {
            this.internet.cleanUp();
        }
        return this;
    }

    getObject(type?: string) {
        switch (type) {
            case "internet":
                return this.internet;
            case "paid":
                return this.paid;
            case "standard":
                return this.standard;
            default:
                return this.default;
        }
    }

    isPaid(type: number) {
        return _.includes([2, 3, 7], type);
    }

    isFree(type: number) {
        return _.includes([1, 4, 5, 6], type);
    }

    isInternet(type: number) {
        return _.includes([8], type);
    }

    async fetchAllInit() {
        this.cleanUp();
        await Promise.all([
            this.getObject("standard").searchPhones({type: "standard", sort: "-tariff_price"}),
            this.getObject("paid").searchPhones({type: "paid", sort: "-price"}),
            this.getObject("internet").searchPhones({type: "internet", sort: "random"})
        ]);
    }
}

export default PhonesService;
