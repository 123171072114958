import React, {Dispatch, SetStateAction, useContext, useEffect, useRef} from "react";
import "./style.scss";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {Block, Col, Row, Typography, Progress, Button, Icon} from "@bezlimit/bezlimit-ui";
import cn from "classnames";
//store
import Store from "../../../../stores";
//services
import LoyaltyService from "../../../../services/LoyaltyService";
import ringPng from "../../../../images/ring11.png";
import {LayoutContext} from "../../../../core/Contexts";
import HintLoyalty from "./hint";

export default observer(function Loyalty({setVisibleLoyalty}: IProps) {
    const {t} = useTranslation();
    const mountedRef = useRef(true);
    const contextLayout = useContext(LayoutContext);

    const getUserInfo = async () => {
        if (!mountedRef.current) {
            return;
        }
        await LoyaltyService.getLoyalty();
    };

    useEffect(() => {
        getUserInfo();
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const onClick = () => {
        const el = document.body.querySelector(".bl-hint_header");
        el?.classList.add("none-loyalty");
        setVisibleLoyalty(false);
        window.scrollTo({
            top: 0,
            behavior: "smooth"
        });
    };

    const getProgressPersent = (
        code: string,
        upgrade_cnt: number,
        last_month_cnt: number
    ): number => {
        if (code === "top" || _.gte(last_month_cnt, 500)) {
            return 100;
        }
        if (!upgrade_cnt) {
            return 0;
        }
        return (100 * last_month_cnt) / (last_month_cnt + upgrade_cnt);
    };

    return (
        <Block
            className={`bl-widget-loyalty ${
                !_.isNil(Store.user.loyalty) ? "" : " without-loyalty"
            }`}
        >
            <Row gutter={[32, 16]}>
                <Col span={24}>
                    <div className="title-row">
                        {_.isNil(Store.user.loyalty) ? (
                            <>
                                <Typography className="loyalty-code">
                                    <img src={ringPng} alt="" /> Персональный
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography
                                    className={cn(
                                        "loyalty-code",
                                        `${Store.user.loyalty?.code ?? ""}`
                                    )}
                                >
                                    {Store.user.loyalty?.name}
                                </Typography>
                                <Typography className="subtitle">
                                    {t("Программа лояльности")}
                                </Typography>
                                {_.gte(contextLayout?.width ?? 0, 768) ? (
                                    <Typography as="div" className="hint-icon">
                                        <HintLoyalty />
                                    </Typography>
                                ) : (
                                    <Typography
                                        as="div"
                                        className="bl-hint_header bl-hint_header__move-shake btn-hint-mobile"
                                    >
                                        <Button
                                            onClick={onClick}
                                            icon={
                                                <Icon
                                                    className="bl-hint_header__icon"
                                                    name="hint_header"
                                                />
                                            }
                                            className="btn-default"
                                        />
                                    </Typography>
                                )}
                            </>
                        )}
                    </div>
                </Col>
                <Col span={24}>
                    {!_.isNil(Store.user.loyalty) && (
                        <>
                            {Store.user.loyalty?.code !== "top" ? (
                                <div className="loyalty-status">
                                    <Typography className="subtitle">
                                        {t("До следующего статуса")}
                                    </Typography>
                                    <Typography className="loyalty-status-count">
                                        {Store.user.loyalty?.scores?.upgrade_cnt ?? 0}
                                    </Typography>
                                </div>
                            ) : (
                                <Typography className="subtitle">
                                    {t("Вы достигли максимального уровня")}
                                </Typography>
                            )}
                        </>
                    )}

                    <Progress
                        className="loaylty-progress-line"
                        percent={getProgressPersent(
                            Store.user.loyalty?.code,
                            Store.user.loyalty?.scores?.upgrade_cnt,
                            Store.user.loyalty?.scores?.last_month_total_cnt
                        )}
                        showInfo={false}
                    />
                </Col>
                <Col span={24}>
                    <div className="activation">
                        <div className="activation-row">
                            <Typography>{t("Баллов за последние 30 дней")}</Typography>
                            <Typography className="activation-cnt gold">
                                {Store.user.loyalty?.scores?.last_month_total_cnt ?? 0}
                            </Typography>
                        </div>
                        {Store.user.level?.level < 2 && (
                            <Row>
                                <Col sm={10} xs={10}>
                                    <Typography as="div" className="bl-last-month">
                                        {t("Личные")}{" "}
                                        <Typography className="activation-cnt gold">
                                            {Store.user.loyalty?.scores?.last_month_personal_cnt ??
                                                0}
                                        </Typography>
                                    </Typography>
                                </Col>
                                <Col sm={6} xs={6}>
                                    <Typography as="div" className="bl-last-month">
                                        {t("НПС")}{" "}
                                        <Typography className="activation-cnt gold">
                                            {Store.user.loyalty?.scores?.last_month_nps_cnt ?? 0}
                                        </Typography>
                                    </Typography>
                                </Col>
                                <Col sm={8} xs={8}>
                                    <Typography as="div" className="bl-last-month last-chaild-col">
                                        {t("Дилерские")}{" "}
                                        <Typography className="activation-cnt gold">
                                            {Store.user.loyalty?.scores?.last_month_level_cnt ?? 0}
                                        </Typography>
                                    </Typography>
                                </Col>
                            </Row>
                        )}
                    </div>
                </Col>
            </Row>
        </Block>
    );
});

interface IProps {
    setVisibleLoyalty: Dispatch<SetStateAction<boolean>>;
}
