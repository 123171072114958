import React from "react";
import {Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import "./style.scss";
// core
import {IPhone} from "../../../../../../core/models/Phones";
// components
import Phone from "./phone";

export default React.memo(function Info({phone, mask: {category}, reservation}: IPhone) {
    const {t} = useTranslation();

    return (
        <Typography as="div" className="bl-numbers-list-info">
            <Space size={16}>
                <Phone category={category} phone={phone} reservation={reservation} />
                {reservation?.dealer_id && (
                    <Typography size={14} color="#fff" className="dealer-id-kanban">
                        {t("В брони: {dealer}", {dealer: reservation?.dealer_id})}
                    </Typography>
                )}
            </Space>
        </Typography>
    );
});
