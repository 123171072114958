import {useContext} from "react";
import {Form, Icon, Input} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {FormItemProps} from "antd";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext} from "../../../core/Contexts";
// rules
import {Rules} from "../../forms/rules";

export default function PasswordField({name, label, ...props}: FormItemProps<any>) {
    const {t} = useTranslation();
    const context = useContext(LayoutContext);

    return (
        <Form.Item
            {...props}
            hasFeedback
            className="field-password"
            name={name || "password"}
            rules={[Rules.required(), Rules.min(3), Rules.max(100)]}
        >
            <Input.Password
                title={label || t("Пароль")}
                autoComplete="new-password"
                iconRender={(visible) =>
                    visible ? (
                        <Icon name="eyeopen" size={_.gte(context?.width ?? 0, 768) ? "22" : "16"} />
                    ) : (
                        <Icon
                            name="eyeclose"
                            size={_.gte(context?.width ?? 0, 768) ? "22" : "16"}
                        />
                    )
                }
            />
        </Form.Item>
    );
}
