import {Typography} from "@bezlimit/bezlimit-ui";
import npsbImg from "../../../../../images/npsb1.svg";
import "./style.scss";
import {useContext} from "react";
import {LayoutContext} from "../../../../../core/Contexts";
import _ from "lodash";

export default function Block1() {
    const contextLayout = useContext(LayoutContext);
    return (
        <>
            <Typography as="div" className="bl-block1">
                <Typography as="div" className="top">
                    <img alt="" src={npsbImg} width={262} />
                    <Typography as="div" className="inner">
                        <Typography as="div" className="desc">
                            Используй акцию
                        </Typography>
                        <Typography as="div" className="title">
                            Не плати за связь!
                        </Typography>
                        <Typography as="div" className="desc mt16">
                            {_.gt(contextLayout?.width, 768)
                                ? "Максимальный доход с минимумом усилий"
                                : "Больше активаций и меньше усилий"}
                        </Typography>
                    </Typography>
                </Typography>
                <Typography as="div" className="bottom">
                    Раньше вы просто подключали красивые номера новым клиентам. Теперь у вас есть
                    возможность рассказать им о способе, как можно сделать связь бесплатной.
                    Увеличьте число активаций за счет друзей и знакомых своих клиентов.
                </Typography>
            </Typography>
            {_.lte(contextLayout?.width, 768) && (
                <Typography as="div" className="bl-block1-1">
                    Раньше вы просто подключали красивые номера новым клиентам. Теперь у вас есть
                    возможность рассказать им о способе, как можно сделать связь бесплатной.
                    Увеличьте число активаций за счет друзей и знакомых своих клиентов.
                </Typography>
            )}
        </>
    );
}
