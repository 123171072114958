import {Button, Col, Divider, Icon, Row, Typography} from "@bezlimit/bezlimit-ui";
import React, {useContext, useLayoutEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
import Clipboard from "react-clipboard.js";
import {Tooltip, notification} from "antd";
// core
import date from "../../../../../core/functions/date";
import {SVGWITHDEFS} from "../../../../../utils";
import _ from "lodash";
import Success from "../../../../../images/success.png";
import infbgmobImg from "../../../../../images/bg_toltip_save_track_number_mob.png";
import {LayoutContext} from "../../../../../core/Contexts";

export default function ListColapse({
    id,
    status_name,
    created_at,
    track_number,
    status,
    shipped_at,
    track_url
}: IProps) {
    const {t} = useTranslation();
    const dateFormat: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "numeric",
        day: "numeric"
    };
    const context = useContext(LayoutContext);
    const timeout: any = useRef();
    const refBg = useRef<HTMLDivElement>(null);
    const refTr = useRef<HTMLDivElement>(null);
    const page = window.location.pathname;
    const [isСookie, setIsСookie] = useState(false);

    const onClick = () => {
        notification.success({
            message: "",
            placement: !_.gte(context?.width ?? 0, 568) ? "top" : "topRight",
            className: "copy-referral-delivery",
            duration: 2,
            icon: <img src={Success} width={34} alt="superLink" />,
            description: t(`Данные о доставке ${track_number} скопированы в буфер обмена`)
        });
    };

    const onTrackNumber = () => {
        refTr.current!.classList.remove("hidden");
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            if (refTr.current) {
                refTr.current!.classList.add("hidden");
            }
        }, 10000);
    };

    const onSaveTrackNumber = () => {
        refBg.current!.classList.remove("hidden");
        clearTimeout(timeout.current);
        timeout.current = setTimeout(() => {
            if (refBg.current) {
                refBg.current!.classList.add("hidden");
            }
            localStorage.setItem("cookieModal", "true");
        }, 10000);
    };

    useLayoutEffect(() => {
        if (refBg.current || refTr.current) {
            clearInterval(timeout.current);
            onTrackNumber();
            timeout.current = setTimeout(() => {
                onSaveTrackNumber();
            }, 11000);
        }

        if (localStorage.getItem("cookieModal")) {
            setIsСookie(true);
        }

        return () => {
            clearInterval(timeout.current);
        };
    }, [refBg.current, refTr.current]);

    return (
        <Typography as="div" className="bl-widget-delivery-list">
            <Row gutter={[16, 32]}>
                <Col md={1} xs={0} className="img-status">
                    <Typography as="div">
                        {SVGWITHDEFS.includes(status) ? (
                            <Icon name={`status_${status}`} size="15" />
                        ) : (
                            <Icon name={`status_${status}`} size="15" />
                        )}
                    </Typography>
                </Col>

                <Col md={5} xs={10}>
                    <Typography className="delivery-list-sub-title">{t("Код доставки")}</Typography>
                    <Typography as="div" size={17} className="delivery-list-id copyable">
                        <Typography className="lattice">{t("#")}</Typography>
                        <Typography className="mobile-img">
                            {SVGWITHDEFS.includes(status) ? (
                                <svg width="15" height="15">
                                    <use href={`#${status}`} />
                                </svg>
                            ) : (
                                <Icon name={`status_${status}`} size="15" />
                            )}
                        </Typography>
                        <Typography className="id-list">{id}</Typography>
                    </Typography>
                </Col>
                <Col md={8} xs={14}>
                    <Typography className="delivery-list-sub-title">{t("Статус")}</Typography>
                    <Typography size={17} color="rgba(255, 255, 255, 0.5)" className="status-name">
                        {status_name}
                    </Typography>
                </Col>
                <Col md={5} xs={10}>
                    <Typography className="delivery-list-sub-title">
                        {t("Дата отправки")}
                    </Typography>
                    <Typography size={17} className="date-created-at">
                        {date(shipped_at, dateFormat)}
                    </Typography>
                </Col>
                <Col md={5} xs={14}>
                    <Typography className="delivery-list-sub-title">
                        {t("Номер отслеживания")}
                    </Typography>
                    <Row className="track-number">
                        <Col md={18} xs={21}>
                            {!_.isEqual(track_number, "") ? (
                                <Typography
                                    as="div"
                                    className="bl-track_number"
                                    id={`tooltip-container-${id}`}
                                >
                                    {_.gte(context?.width ?? 0, 568) ? (
                                        <Tooltip
                                            title={`Нажмите на трек-номер, чтобы перейти \nна сайт службы доставки для отслеживания посылки`}
                                            overlayClassName="toltip-track_number"
                                            getPopupContainer={() => {
                                                return document.getElementById(
                                                    `tooltip-container-${id}`
                                                )!;
                                            }}
                                        >
                                            <a href={track_url} target="_blank" rel="noreferrer">
                                                <Typography className="link link-white transition">
                                                    {track_number}
                                                </Typography>
                                            </a>
                                        </Tooltip>
                                    ) : (
                                        <>
                                            {_.isEqual(page, "/deliveries") && !isСookie ? (
                                                <div ref={refTr} className="track-container hidden">
                                                    <img alt="" src={infbgmobImg} width={285} />
                                                    <Typography as="div">
                                                        {`Нажмите на трек-номер, чтобы \nперейти на сайт службы доставки \nдля отслеживания посылки`}
                                                    </Typography>
                                                </div>
                                            ) : null}
                                            <a href={track_url} target="_blank" rel="noreferrer">
                                                <Typography className="link link-white transition">
                                                    {track_number}
                                                </Typography>
                                            </a>
                                        </>
                                    )}
                                </Typography>
                            ) : null}
                        </Col>
                        <Col md={6} xs={3}>
                            {!_.isEqual(track_number, "") ? (
                                <Typography
                                    as="div"
                                    className="bl-save-track_number"
                                    id={`tooltip-container-${id}`}
                                >
                                    {_.gte(context?.width ?? 0, 568) ? (
                                        <Tooltip
                                            title={
                                                "Скопировать ссылку \nдля отслеживания отправления"
                                            }
                                            overlayClassName="toltip-save-track_number"
                                            getPopupContainer={() => {
                                                return document.getElementById(
                                                    `tooltip-container-${id}`
                                                )!;
                                            }}
                                        >
                                            <Button className="copy" type="text">
                                                <Clipboard
                                                    component="span"
                                                    data-clipboard-text={`${
                                                        track_number ?? "не определен"
                                                    }`}
                                                    onSuccess={onClick}
                                                ></Clipboard>
                                            </Button>
                                        </Tooltip>
                                    ) : (
                                        <>
                                            {_.isEqual(page, "/deliveries") && !isСookie ? (
                                                <div
                                                    ref={refBg}
                                                    className="save-track-container hidden"
                                                >
                                                    <img alt="" src={infbgmobImg} width={240} />
                                                    <Typography as="div">
                                                        {
                                                            "Скопировать ссылку \nдля отслеживания отправления"
                                                        }
                                                    </Typography>
                                                </div>
                                            ) : null}
                                            <Button className="copy" type="text">
                                                <Clipboard
                                                    component="span"
                                                    data-clipboard-text={`${
                                                        track_number ?? "не определен"
                                                    }`}
                                                    onSuccess={onClick}
                                                ></Clipboard>
                                            </Button>
                                        </>
                                    )}
                                </Typography>
                            ) : null}
                        </Col>
                    </Row>
                </Col>
                <Divider />
            </Row>
        </Typography>
    );
}

interface IProps {
    id: number;
    status_name: string;
    created_at: string;
    track_number: string;
    status: string;
    shipped_at: string;
    track_url?: string;
}
