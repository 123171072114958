import React, {useContext} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import "./style.scss";
//images
import SuccessImg from "../../images/successImg";
// core
import {LayoutContext} from "../../../core/Contexts";

export default React.memo(function Success({title, subtitle, phone}: IProps) {
    const context = useContext(LayoutContext);

    return (
        <Typography as="div" className="bl-success">
            <Space size={16} direction="vertical" align="center">
                <SuccessImg />
                <Typography
                    size={_.gte(context?.width ?? 0, 768) ? 24 : 17}
                    level={2}
                    color="#000000"
                >
                    {title}
                </Typography>
                {phone && (
                    <Typography
                        size={_.gte(context?.width ?? 0, 768) ? 48 : 32}
                        color="#642878"
                        fontFamily="alumna"
                        className="phone"
                    >
                        {phone}
                    </Typography>
                )}
                {subtitle && (
                    <Typography as="div" size={14} color="#000000" className="subtitle">
                        {subtitle}
                    </Typography>
                )}
            </Space>
        </Typography>
    );
});

interface IProps {
    title: JSX.Element;
    subtitle?: JSX.Element;
    phone?: string;
}
