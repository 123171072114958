import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import ControlsService from "../../../services/ControlsService";
import {observer} from "mobx-react";
import {useEffect} from "react";
import "./style.scss";
//store
import Store from "../../../stores";
import WidgetChartra from "../../../core/functions/widgetChartra";

export default observer(function Politics() {
    const {t} = useTranslation();
    const chatOpened = ControlsService.chatOpened;

    const SetChartra = async () => {
        WidgetChartra(document, window, "Chatra", Store.user, true);
    };

    useEffect(() => {
        SetChartra();
    }, []);

    const onClick = () => {
        ControlsService.setChatOpened(!chatOpened);
    };

    useEffect(() => {
        const chatra = (window as any).Chatra;
        if (typeof chatra !== "function") {
            return;
        }
        if (chatOpened) {
            chatra("show");
            chatra("openChat", true);
        } else {
            chatra("hide");
        }
    }, [chatOpened]);

    return (
        <Typography as="div" className="bl-politics">
            <Space size={8} direction="vertical" align="center">
                <Typography size={12} color="rgba(255, 255, 255, 0.5)">
                    {t("Все права защищены. При входе вы принимаете:")}
                </Typography>
                <a href="/files/Политика_конфиденциальности_Store_24_07_2023.pdf" target="_blank">
                    <Typography size={12} underline color="#fff">
                        {t("Политика конфиденциальности")}
                    </Typography>
                </a>
                <a href="/files/Согласие на получение рассылки Store.pdf" target="_blank">
                    <Typography size={12} underline color="#fff">
                        {t("Согласие на получение рассылки")}
                    </Typography>
                </a>
                <a href="/files/Правила пользования Store.pdf" target="_blank">
                    <Typography size={12} underline color="#fff">
                        {t("Правила пользования")}
                    </Typography>
                </a>
                <Typography
                    size={12}
                    className="bl-politics-support"
                    underline
                    color="#fff"
                    onClick={onClick}
                >
                    {t("Поддержка")}
                </Typography>
            </Space>
        </Typography>
    );
});
