import _ from "lodash";
import moment from "moment";
// core
import {axiosFetchBuilder, IAxiosConfig} from "../core/Axios";
import {IPhonesResponse} from "../core/models/Phones";
import {
    IReservationsBookingRequest,
    IReservationsEasyDeliverySimResponse,
    IReservationsPassportPhotoRequest,
    IReservationsPutRequest,
    IReservationsRequest,
    IReservationsResponse,
    IReservationsTurboBookingRequest
} from "../core/models/Reservations";
// store
import Store from "../stores";

enum Path {
    turbo = "/reservations/turbo",
    reservations = "/reservations",
    reservationsEsim = "/reservations/esim",
    reservationsSummary = "/reservations/summary",
    passportPhoto = "/reservations/passport-photo",
    easyDeliverySim = "/reservations/easy-delivery-sim"
}

class ReservationsService {
    [Symbol.toStringTag] = this.constructor.name;
    private readonly store = Store.reservations;

    get summary() {
        return (
            this.store.cnt ?? {
                today: 0,
                yesterday: 0,
                total: 0
            }
        );
    }

    get gridType() {
        return this.store.gridType;
    }

    set gridType(value) {
        this.store.gridType = value;
    }

    get items() {
        return this.store.items;
    }

    set items(value) {
        this.store.items = value;
    }

    get itemsSize() {
        return _.size(this.store.items);
    }

    get meta() {
        return this.store.meta;
    }

    get perPage() {
        return this.store.meta?.perPage ?? 20;
    }

    get totalCount() {
        return this.store.meta?.totalCount ?? 0;
    }

    get currentPage() {
        return this.store.meta?.currentPage ?? 1;
    }

    get hasItems() {
        return _.gt(this.totalCount, this.itemsSize);
    }

    get recommendSum() {
        return this.store.recommendSum;
    }

    isActivated(key: string) {
        return _.isEqual(key, "activation") ? "activated" : "";
    }

    async getReservationsByPnone(request?: IReservationsRequest): Promise<IReservationsResponse> {
        return await axiosFetchBuilder({
            url: Path.reservations,
            params: {...request, expand: "phone,tariff,phone.mask,phone.region,additional_data"}
        });
    }

    async getReservations(request?: IReservationsRequest) {
        const res: IReservationsResponse = await axiosFetchBuilder({
            url: Path.reservations,
            params: {
                ...request,
                expand: "phone,tariff,recommend_sum,phone.mask,phone.region,additional_data"
            }
        });

        this.store.recommendSum = res.items[0]?.recommend_sum;
        this.store.items = res.items;
        this.store.meta = res._meta;
    }

    async postReservationTurbo({
        understand,
        loading = false,
        ...request
    }: IReservationsTurboBookingRequest) {
        const res: IPhonesResponse = await axiosFetchBuilder({
            url: Path.turbo,
            method: "POST",
            data: request,
            loading
        } as IAxiosConfig);

        if (!_.isNil(understand) && understand) {
            let phones: string[] = [];
            const storage = localStorage.getItem("understand");

            if (storage) {
                phones = JSON.parse(storage) ?? [];
            }

            phones.push(request.phone);
            localStorage.setItem("understand", JSON.stringify(phones));
        }

        return res;
    }

    async postReservation({...request}: IReservationsBookingRequest | any) {
        const res: IPhonesResponse = await axiosFetchBuilder({
            url: Path.reservations,
            method: "POST",
            data: request,
            loading: false
        });

        return res;
    }

    async postReservationEsim({...request}: IReservationsBookingRequest | any) {
        const res: IPhonesResponse = await axiosFetchBuilder({
            url: Path.reservationsEsim,
            method: "POST",
            data: request,
            loading: false
        });

        return res;
    }

    async putReservationEsim({...request}: IReservationsPutRequest | any) {
        const res: IPhonesResponse = await axiosFetchBuilder({
            url: `${Path.reservationsEsim}/${request.id}`,
            method: "PUT",
            data: request,
            loading: false
        });

        return res;
    }

    async putReservation({id, ...request}: IReservationsPutRequest) {
        const res: IPhonesResponse = await axiosFetchBuilder({
            method: "PUT",
            url: `${Path.reservations}/${id}`,
            loading: false,
            data: request
        });

        return res;
    }

    async postPassportPhoto({type, ...request}: IReservationsPassportPhotoRequest) {
        if (request.photo) {
            const res: IPhonesResponse = await axiosFetchBuilder({
                url: Path.passportPhoto,
                loading: false,
                method: "POST",
                data: request,
                params: {type}
            });

            return res;
        }
    }

    async getReservationsByIsActivated(request?: IReservationsRequest, loading: boolean = true) {
        const res: IReservationsResponse = await axiosFetchBuilder({
            url: Path.reservations,
            params: {
                ...request,
                expand: "phone,tariff,recommend_sum,phone.mask,phone.unblock_sum,phone.region,phone.passport.photos,additional_data"
            },
            loading
        } as IAxiosConfig);

        this.store.recommendSum = res.items[0]?.recommend_sum;
        return res;
    }

    async getReservationsPhotos(phone: number) {
        const res: IReservationsResponse = await axiosFetchBuilder({
            url: Path.reservations,
            params: {phone_number: phone, expand: "phone.passport.photos,additional_data"}
        } as IAxiosConfig);
        this.store.photosFirst = res.items[0]?.phone?.passport?.photos[0].url ?? "";
        this.store.photosSecond = res.items[0]?.phone?.passport?.photos[1].url ?? "";
    }

    async postEasyDeliverySim(phone: number) {
        const res: IReservationsEasyDeliverySimResponse = await axiosFetchBuilder({
            url: Path.easyDeliverySim,
            method: "POST",
            data: {phone}
        });
        return res;
    }

    async putReservationWithProlong({...request}: IReservationsPutRequest) {
        const res: IPhonesResponse = await axiosFetchBuilder({
            url: `${Path.reservations}/${request.id}`,
            method: "PUT",
            data: request,
            loading: false
        } as IAxiosConfig);

        return res;
    }

    async deletReservation({...request}: IReservationsPutRequest) {
        const res: IPhonesResponse = await axiosFetchBuilder({
            url: `${Path.reservations}/${request.id}`,
            method: "DELETE",
            data: request,
            loading: false
        } as IAxiosConfig);

        return res;
    }

    async getReservationsSummary(): Promise<void> {
        const res: any = await Promise.all([
            axiosFetchBuilder({
                url: Path.reservationsSummary,
                params: {date_from: moment().format("YYYY-MM-DD 00:00:00")}
            }),
            axiosFetchBuilder({
                url: Path.reservationsSummary,
                params: {
                    date_from: moment().subtract(1, "day").format("YYYY-MM-DD 00:00:00"),
                    date_to: moment().subtract(1, "day").format("YYYY-MM-DD 23:59:59")
                }
            }),
            axiosFetchBuilder({
                url: Path.reservationsSummary
            })
        ]);

        this.store.cnt = {
            today: res[0]?.cnt ?? 0,
            yesterday: res[1]?.cnt ?? 0,
            total: res[2]?.cnt ?? 0
        };
    }
}

export default new ReservationsService();
