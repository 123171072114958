// core
import {IPhonesFiltersStore, IUserFilter} from "../core/models/PhonesFilters";
// store
import NumbersFiltersStore from "../stores/NumbersFiltersStore";
import Store from "../stores";
import _ from "lodash";
import PhonesFiltersService from "./PhonesFiltersService";
import {axiosFetchBuilder} from "../core/Axios";

enum Path {
    userFilter = "phones/users/filter"
}

class NumbersFiltersStoreService {
    [Symbol.toStringTag] = this.constructor.name;
    protected readonly store: NumbersFiltersStore = Store.numbersFiltersStore;

    get items() {
        return this.store.items;
    }

    get current() {
        return this.store.current;
    }

    set current(value: number | undefined) {
        this.store.current = value;
    }

    async add(value: IPhonesFiltersStore) {
        const res: any = await this.saveFilter(value);

        value.id = res.id;
        const items = this.items;
        items.unshift(value);
        this.store.items = items;
        const size = _.size(this.items);
        this.store.current = _.lte(size, 0) ? size - 1 : 0;
    }

    update(value: IPhonesFiltersStore[]) {
        this.store.items = value;
    }

    remove(index: number) {
        const items = _.clone(this.items);
        const id = items[index].id;
        items.splice(index, 1);
        this.update(items);
        const size = _.size(this.items);
        this.store.current = _.gt(size, 0) ? size - 1 : undefined;

        if (!_.isNil(this.store.current)) {
            PhonesFiltersService.setCurrentFilter(this.items[this.current!].values);
        } else {
            this.reset();
        }

        this.deleteFilter(id!);
    }

    reset() {
        this.store.current = undefined;
        PhonesFiltersService.resetCurrentFilter();
    }

    async getFilters() {
        const res: IUserFilter = await axiosFetchBuilder({
            url: Path.userFilter,
            params: {
                per_page: 100
            }
        });

        if (res?.items?.length) {
            const filters: IPhonesFiltersStore[] = _.chain(res.items)
                .map((i) => {
                    return {
                        id: i.id,
                        name: i.name,
                        values: {
                            type: i.type ? [i.type] : [],
                            tariff: i.service_limit
                                ? _.map(i.service_limit, (i) => i.toString())
                                : [],
                            categories: i.mask_category ? i.mask_category : [],
                            region: i.region_id ? _.map(i.region_id, (i) => i.toString()) : [],
                            pattern: i.mask_pattern ? i.mask_pattern : [],
                            phone_combs: i.phone_combs ? _.join(i.phone_combs, ",") : undefined,
                            phone_pattern: i.phone_pattern ? _.split(i.phone_pattern, "") : []
                        }
                    };
                })
                .valueOf();
            this.update(filters);
        }
    }

    deleteFilter(id: number) {
        axiosFetchBuilder({
            url: `${Path.userFilter}/${id}`,
            method: "DELETE",
            loading: false
        });
    }

    updateFilter({id, name, values}: IPhonesFiltersStore) {
        fetch(`${process.env.REACT_APP_API_URL}/${Path.userFilter}/${id}`, {
            headers: {
                "Content-type": "application/json; charset=UTF-8",
                Authorization: process.env.REACT_APP_BASIC_AUTH_HEADER!,
                "Api-token": Store.user.token
            },
            method: "PUT",
            body: JSON.stringify({
                name: name,
                type: values.type ? values.type[0] : "",
                ...(values.tariff && values.tariff.length
                    ? {service_limit: _.map(values.tariff, (i) => +i)}
                    : {}),
                ...(values.categories && values.categories.length
                    ? {mask_category: values.categories}
                    : {}),
                ...(values.region && values.region.length
                    ? {region_id: _.map(values.region, (i) => +i)}
                    : {}),
                ...(values.pattern && values.pattern.length ? {mask_pattern: values.pattern} : {}),
                ...(values.phone_combs && !!_.replace(values.phone_combs, /,/gi, "")
                    ? {phone_combs: _.map(_.split(values.phone_combs, ","), (i) => +i)}
                    : {}),
                ...(values.phone_pattern && values.phone_pattern.length
                    ? {
                          phone_pattern: _.join(
                              _.map(values.phone_pattern, (i) => (_.isEmpty(i) ? "N" : i)),
                              ""
                          )
                      }
                    : {})
            })
        } as any);
    }

    async saveFilter({name, values}: IPhonesFiltersStore) {
        return await axiosFetchBuilder({
            url: Path.userFilter,
            method: "POST",
            loading: false,
            data: {
                name: name,
                type: values.type ? values.type[0] : "",
                ...(values.tariff && values.tariff.length
                    ? {service_limit: _.map(values.tariff, (i) => +i)}
                    : {}),
                ...(values.categories && values.categories.length
                    ? {mask_category: values.categories}
                    : {}),
                ...(values.region && values.region.length
                    ? {region_id: _.map(values.region, (i) => +i)}
                    : {}),
                ...(values.pattern && values.pattern.length ? {mask_pattern: values.pattern} : {}),
                ...(values.phone_combs && !!_.replace(values.phone_combs, /,/gi, "")
                    ? {phone_combs: _.map(_.split(values.phone_combs, ","), (i) => +i)}
                    : {}),
                ...(values.phone_pattern && values.phone_pattern.length
                    ? {
                          phone_pattern: _.join(
                              _.map(values.phone_pattern, (i) => (_.isEmpty(i) ? "N" : i)),
                              ""
                          )
                      }
                    : {})
            }
        });
    }
}

export default new NumbersFiltersStoreService();
