// core
import _ from "lodash";
import {axiosFetchBuilder, IAxiosConfig} from "../core/Axios";
import {IBankCards, IPostBankCardSave} from "../core/models/UserCard";
// store
import Store from "../stores";

enum Path {
    fetchGetBankCard = "/card",
    deleteBankCard = "/card/{id}",
    cardPayment = "/card/payment"
}

class UserCardService {
    [Symbol.toStringTag] = this.constructor.name;
    private static readonly userCard = Store.userCard;

    static get cardsUser() {
        return UserCardService.userCard.cards;
    }

    static async fetchGetBankCard() {
        try {
            const res: IBankCards = await axiosFetchBuilder({
                url: Path.fetchGetBankCard,
                loading: false
            });

            UserCardService.userCard.cards = res.items;
        } catch (err) {
            throw err;
        }
    }

    static async postBankCardSave() {
        try {
            const res: IPostBankCardSave = await axiosFetchBuilder({
                url: Path.fetchGetBankCard,
                method: "POST",
                loading: false,
                data: {
                    success_url: new URL("#card-add-success", window.location.href).toString(),
                    already_added_url: new URL(
                        "#already_added_url",
                        window.location.href
                    ).toString(),
                    fail_url: new URL("#card-add-fail", window.location.href).toString()
                }
            } as IAxiosConfig);

            return res;
        } catch (err) {
            throw err;
        }
    }

    static async deleteBankCard(id: number) {
        try {
            await axiosFetchBuilder({
                url: _.replace(Path.deleteBankCard, "{id}", id.toString()),
                method: "DELETE",
                loading: false
            } as IAxiosConfig);
        } catch (err) {
            throw err;
        }
    }

    static async postBankCardPayment(card_id: number, phone: number, amount: number) {
        try {
            const res: IPostBankCardSave = await axiosFetchBuilder({
                url: Path.cardPayment,
                method: "POST",
                loading: false,
                data: {
                    card_id,
                    phone,
                    amount
                }
            } as IAxiosConfig);

            return res;
        } catch (err) {
            throw err;
        }
    }
}

export default UserCardService;
