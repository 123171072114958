import {Button, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useEffect, useRef, useState} from "react";
import {useTranslation} from "react-i18next";
// core
import WidgetChartra from "../../../../core/functions/widgetChartra";
// services
import ControlsService from "../../../../services/ControlsService";
// stores
import Store from "../../../../stores";

export default observer(function ButtonChatSupport({type, text}: IProps) {
    const timeout: any = useRef();
    const {t} = useTranslation();
    const mountedRef = useRef(true);
    const chatOpened = ControlsService.chatOpened;
    const [btnDisabled, setBtnDisabled] = useState(false);

    const SetChartra = async () => {
        if (!mountedRef.current) {
            return;
        }

        clearTimeout(timeout.current);
        setBtnDisabled(true);
        timeout.current = setTimeout(() => {
            setBtnDisabled(false);
        }, 3000);

        WidgetChartra(document, window, "Chatra", Store.user);
    };

    const onClick = () => {
        ControlsService.setChatOpened(!chatOpened);
    };

    useEffect(() => {
        SetChartra();

        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        const chatra = (window as any).Chatra;
        if (typeof chatra !== "function") {
            return;
        }
        if (chatOpened) {
            chatra("show");
            chatra("openChat", true);
        } else {
            chatra("hide");
        }
    }, [chatOpened]);
    return (
        <Typography as="div" className="btn-deliveries-support">
            <Button type={type ? type : "default"} onClick={onClick} disabled={btnDisabled}>
                {t(text ? text : "Чат с поддержкой")}
            </Button>
        </Typography>
    );
});

interface IProps {
    type?: any;
    text?: string;
}
