import {Col, Row, Typography, Space} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import bee from "../../../../../../../../images/social_bee.svg";
import vk from "../../../../../../../../images/social_vk_c.png";
import ok from "../../../../../../../../images/social_ok_c.png";
import wa from "../../../../../../../../images/social_wa_c.png";
import tg from "../../../../../../../../images/social_tg_c.png";
import vb from "../../../../../../../../images/social_vb_c.png";
import sk from "../../../../../../../../images/social_sk_c.png";
import yt from "../../../../../../../../images/social_yt_c.png";
import ya from "../../../../../../../../images/social_ya_c.png";
import tt from "../../../../../../../../images/social_tt_c.png";
import nav from "../../../../../../../../images/social_nav_c.png";
import gmap from "../../../../../../../../images/social_gmap_c.png";
import yamap from "../../../../../../../../images/social_yamap_c.png";
import gmail from "../../../../../../../../images/social_gmail_c.png";
import mail from "../../../../../../../../images/social_mail_c.png";
import bz from "../../../../../../../../images/logo_bez_mobile.svg";

export default function Mobile({price}: IProps) {
    const {t} = useTranslation();
    return (
        <Row className="row-list-service">
            <Col>
                <Space direction="vertical" size={8}>
                    <Row>
                        <Col span={6}>
                            <img className="social-icon" src={bee} width={16} alt="logo" />
                        </Col>
                        <Col span={18}>
                            <Typography as="div">{t("Безлимит на Билайн")}</Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <img className="social-icon" src={vk} width={16} alt="logo" />
                            <img className="social-icon" src={ok} width={16} alt="logo" />
                        </Col>
                        <Col span={18}>
                            <Typography as="div">{t("Безлимит на Социальные сети")}</Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <img className="social-icon" src={wa} width={16} alt="logo" />
                            <img className="social-icon" src={tg} width={16} alt="logo" />
                            <img className="social-icon" src={vb} width={16} alt="logo" />
                            <img className="social-icon" src={sk} width={16} alt="logo" />
                        </Col>
                        <Col span={18}>
                            <Typography as="div">{t("Безлимит на Мессенджеры")}</Typography>
                        </Col>
                    </Row>
                    {_.isEqual(price, 590) ? null : (
                        <Row>
                            <Col span={6}>
                                <img className="social-icon" src={yt} width={16} alt="logo" />
                                <img className="social-icon" src={tt} width={16} alt="logo" />
                            </Col>
                            <Col span={18}>
                                <Typography as="div">{t("Безлимит на Видео")}</Typography>
                            </Col>
                        </Row>
                    )}
                    {/* <Row>
                        <Col span={6}>
                            <img className="social-icon" src={vk} width={16} alt="logo" />
                            <img className="social-icon" src={ya} width={16} alt="logo" />
                        </Col>
                        <Col span={18}>
                            <Typography as="div">{t("Безлимит на Музыку")}</Typography>
                        </Col>
                    </Row> */}
                    <Row>
                        <Col span={6}>
                            <img className="social-icon" src={yamap} width={16} alt="logo" />
                            <img className="social-icon" src={gmap} width={16} alt="logo" />
                            <img className="social-icon" src={nav} width={14} alt="logo" />
                        </Col>
                        <Col span={18}>
                            <Typography as="div"> {t("Безлимит на Карты")}</Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <img className="social-icon" src={gmail} width={16} alt="logo" />
                            <img className="social-icon" src={mail} width={16} alt="logo" />
                            <img className="social-icon" src={ya} width={16} alt="logo" />
                        </Col>
                        <Col span={18}>
                            <Typography as="div">{t("Безлимит на Почтовые сервисы")}</Typography>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={6}>
                            <img className="social-icon" src={bz} width={12} alt="logo" />
                        </Col>
                        <Col span={18}>
                            <Typography as="div">{t("Безлимит на Безлимит")}</Typography>
                        </Col>
                    </Row>
                </Space>
            </Col>
        </Row>
    );
}

interface IProps {
    price: number;
}
