import {useEffect, useContext} from "react";
import {FormInstance} from "antd";
import _ from "lodash";
// core
import {InterceptorContext} from "../Contexts";
import {IErrors} from "../models/Error";

export default function useFormHandler(form: FormInstance<any>) {
    const context = useContext(InterceptorContext);

    useEffect(() => {
        if (context?.data) {
            form.setFields(
                _.chain(context?.data)
                    .reduce<IErrors[]>((res, i) => {
                        (res || (res = [])).push({
                            name: i.field,
                            errors: [i.message]
                        });
                        return res;
                    }, [])
                    .valueOf()
            );
        }
    }, [context?.status, context?.data, form]);

    return form;
}
