import React, {useContext} from "react";
import {BlockProps} from "@bezlimit/bezlimit-ui/dist/components/block";
import {Block} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
// core
import {LayoutContext} from "../../../../core/Contexts";

export default React.memo(function Card({children, theme, ...props}: IProps) {
    const context = useContext(LayoutContext);

    switch (theme) {
        case "blured":
            props.style = {
                ...(props.style ?? {}),
                background: "rgba(255, 255, 255, 0.07)",
                backdropFilter: "blur(24px)",
                borderRadius: `${_.gte(context?.width ?? 0, 768) ? 32 : 20}px`
            };
            break;
    }

    return (
        <Block className="bl-block" {...props}>
            {children}
        </Block>
    );
});

interface IProps extends BlockProps {
    children: JSX.Element;
    theme?: "default" | "blured";
}
