import React, {useContext, useEffect, useRef, useState} from "react";
import {
    Typography,
    Block,
    Button,
    Icon,
    Title,
    Row,
    Col,
    Space,
    Carousel,
    Tooltip
} from "@bezlimit/bezlimit-ui";
import {MobXProviderContext, observer} from "mobx-react";
import cn from "classnames";
import _ from "lodash";
import "./style.scss";
// components
import {ICollection} from "../../../../../../core/models/Phones";
import {LayoutContext, ModalContext} from "../../../../../../core/Contexts";
import Error from "../../../../../modal/error";
import Collapse from "./collapse";
import NumberFilterMode from "../../../../../modal/numberFilterMode";
import Filter from "../../../../ui/filter";
import {ScrollerButtons} from "../../../../scroller_buttons";
import {Scroller} from "../../../../scroller";
import ShareSuperLinkWithIdModal from "../../../../../modal/shareSuperLinkWithId";
import podb from "../../../../../../images/podb.png";
import backCallection from "../../../../../../images/back-callection.png";

export interface ICollectionView {
    [id: number]: {
        currentPage: number;
        index?: number;
    };
}

export interface ICollectionViewMap {
    [index: number]: number;
}

const Collection: React.FC<IProps> = observer(({isFavorite, setIsFavorite}) => {
    const {store} = useContext(MobXProviderContext);
    const contextLayout = useContext(LayoutContext);
    const contextModal = useContext(ModalContext);
    const collection = useRef<ICollectionView>({});
    const [itemSelected, setItemSelected] = useState(0);
    const slider = useRef<any>();
    const [openPanels, setOpenPanels] = React.useState([]);
    const [loadingBtnId, setloadingBtnId] = useState(-1);
    const [data, setData] = useState<any>([]);

    const onClick = () => {
        contextModal?.setVisible(true);
        contextModal?.setChildren(
            <NumberFilterMode isFavorite={isFavorite} setIsFavorite={setIsFavorite} />
        );
    };

    const onLoadMoreClick = async (id: number) => {
        const curentPage = collection.current[id].currentPage;
        setloadingBtnId(id);
        setOpenPanels([]);
        const timeS = Date.now();

        await store.numberFilters.loadMoreCollectionItem(id, curentPage + 1);
        const timeE = Date.now();
        const diff = timeE - timeS;
        if (diff < 1000) {
            await new Promise((resolve) => setTimeout(resolve, 1000 - diff));
        }
        setloadingBtnId(-1);
        collection.current[id].currentPage = curentPage + 1;
    };

    const onItemClick = (id: number) => {
        const index = collection.current[id].index ?? 0;
        setItemSelected(index);
    };

    const InitCollection = async () => {
        try {
            setOpenPanels([]);
            await store.numberFilters.getCollection();
            if (!store.numberFilters.collection?.length) return;
            ((store.numberFilters.collection as ICollection[]) ?? []).forEach((item) => {
                collection.current[item.id] = {
                    currentPage: 1
                };
            });
        } catch (err) {
            contextModal?.setVisible(true);
            contextModal?.setChildren(<Error />);
            contextModal?.setCssClass(" ");
        }
    };

    useEffect(() => {
        if (!store.numberFilters.collection?.length) {
            InitCollection();
        } else {
            ((store.numberFilters.collection as ICollection[]) ?? []).forEach((item) => {
                collection.current[item.id] = {
                    currentPage: 1
                };
            });
        }
    }, []);

    const collapseClose = () => {
        setOpenPanels([]);
    };

    //для мобилки
    const afterChange = (currentSlide: number) => {
        setItemSelected(currentSlide);
    };

    const onShare = (id: number) => {
        contextModal?.setChildren(<ShareSuperLinkWithIdModal id={id} />);
        contextModal?.setVisible(true);
    };

    const onBack = async (id: number) => {
        const el = document.getElementById(`collection-block-btn-back-${id}`);
        const curentPage = collection.current[id].currentPage;
        el?.classList.add("loading");
        setloadingBtnId(id);
        setOpenPanels([]);
        const timeS = Date.now();

        await store.numberFilters.loadMoreCollectionItem(id, curentPage - 1);
        const timeE = Date.now();
        const diff = timeE - timeS;
        if (diff < 1000) {
            await new Promise((resolve) => setTimeout(resolve, 1000 - diff));
        }
        setloadingBtnId(-1);
        el?.classList.remove("loading");
        collection.current[id].currentPage = curentPage - 1;
    };

    useEffect(() => {
        const Collection = _.chain(store.numberFilters.collection)
            .map((item, index) => {
                if (!item) return null;
                if (collection.current[item.id]) {
                    collection.current[item.id].index = index;
                }
                return (
                    <div key={item.id}>
                        <Block
                            id={`collection-item-${index}`}
                            onClick={() => onItemClick(item.id)}
                            style={{
                                backgroundImage: `linear-gradient(162.04deg, rgba(100, 40, 120, 0.75) 3.38%, rgba(113, 43, 130, 0) 47.04%), linear-gradient(0deg, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.2)), linear-gradient(343.68deg, rgba(155, 26, 186, 0.65) 6.45%, rgba(113, 43, 130, 0) 78.96%),
                                    url(${
                                        _.gte(contextLayout?.width ?? 0, 768)
                                            ? item.background_url
                                            : item.background_mob_url
                                    })`
                            }}
                            className={cn("collection-block", {
                                "item-selected": itemSelected === index
                            })}
                        >
                            <div>
                                <Typography
                                    as="div"
                                    className="info-desc"
                                    id={`info-desc-tooltip-id-${index}`}
                                >
                                    <Tooltip
                                        placement="bottomRight"
                                        title={item.description}
                                        trigger={
                                            _.lte(contextLayout?.width ?? 0, 576)
                                                ? "click"
                                                : "hover"
                                        }
                                        overlayClassName="info-desc-tooltip-container"
                                    >
                                        <img src={podb} width={20} alt="" />
                                    </Tooltip>
                                </Typography>

                                {_.gt(collection.current[item.id]?.currentPage, 1) && (
                                    <Typography
                                        as="div"
                                        className="back-collection"
                                        id={`collection-block-btn-back-${item.id}`}
                                    >
                                        <img
                                            src={backCallection}
                                            alt=""
                                            width={28}
                                            onClick={() => onBack(item.id)}
                                        />
                                    </Typography>
                                )}

                                <Title className="gold collection-title" size={32}>
                                    {item.name}
                                </Title>
                                <Row gutter={[16, 57]}>
                                    <Col span={24}>
                                        <Space
                                            className="collection-item-wrapper"
                                            direction="vertical"
                                            size={30}
                                        >
                                            <Collapse
                                                items={item.phones}
                                                openPanels={openPanels}
                                                setOpenPanels={setOpenPanels}
                                                withShimmer={loadingBtnId === item.id}
                                                InitCollection={InitCollection}
                                            />
                                        </Space>
                                    </Col>
                                </Row>
                            </div>
                            <Typography as="div" className="actions-container">
                                <Button
                                    id={`collection-block-btn-${item.id}`}
                                    block
                                    type="primary"
                                    className="collection-block-btn"
                                    loading={loadingBtnId === item.id}
                                    onClick={() => onLoadMoreClick(item.id)}
                                >
                                    <Icon name={"arrowRound"} size={"14"} />
                                    <div>{"Еще номера"}</div>
                                </Button>
                                <Button
                                    id={`collection-block-btn-${item.id}`}
                                    block
                                    type="primary"
                                    className="collection-block-btn"
                                    onClick={() => onShare(item.id)}
                                >
                                    <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="21"
                                        height="21"
                                        viewBox="0 0 21 21"
                                        fill="none"
                                    >
                                        <g clipPath="url(#clip0_91_493)">
                                            <path
                                                d="M5.5 13C6.88071 13 8 11.8807 8 10.5C8 9.11929 6.88071 8 5.5 8C4.11929 8 3 9.11929 3 10.5C3 11.8807 4.11929 13 5.5 13Z"
                                                stroke="#1F1F1F"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M15.5 8C16.8807 8 18 6.88071 18 5.5C18 4.11929 16.8807 3 15.5 3C14.1193 3 13 4.11929 13 5.5C13 6.88071 14.1193 8 15.5 8Z"
                                                stroke="#1F1F1F"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M15.5 18C16.8807 18 18 16.8807 18 15.5C18 14.1193 16.8807 13 15.5 13C14.1193 13 13 14.1193 13 15.5C13 16.8807 14.1193 18 15.5 18Z"
                                                stroke="#1F1F1F"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M7.75 9.41666L13.25 6.58333"
                                                stroke="#1F1F1F"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                            <path
                                                d="M7.75 11.5833L13.25 14.4167"
                                                stroke="#1F1F1F"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                            />
                                        </g>
                                        <defs>
                                            <clipPath id="clip0_91_493">
                                                <rect
                                                    width="20"
                                                    height="20"
                                                    fill="white"
                                                    transform="translate(0.5 0.5)"
                                                />
                                            </clipPath>
                                        </defs>
                                    </svg>
                                    <div>{"Поделиться"}</div>
                                </Button>
                            </Typography>
                        </Block>
                    </div>
                );
            })
            .valueOf();
        setData(Collection);
    }, [store.numberFilters.collection, loadingBtnId, openPanels]);

    return (
        <Typography as="div" className="bl-number-selection-collection">
            {_.gte(contextLayout?.width ?? 0, 768) ? (
                <ScrollerButtons
                    itemSelected={itemSelected}
                    setItemSelected={setItemSelected}
                    total={store.numberFilters.collection?.length ?? 0}
                    collapseClose={collapseClose}
                />
            ) : (
                <Scroller />
            )}
            {_.lte(contextLayout?.width ?? 0, 767) && (
                <Filter isFavorite={true} count={0} onClick={onClick} />
            )}
            {_.gte(contextLayout?.width ?? 0, 450) ? (
                <Typography as="div" className="scrolled-row">
                    <Space id="scrolled-row" size={12} direction="horizontal" align="start">
                        {data}
                    </Space>
                </Typography>
            ) : (
                <Carousel
                    ref={(ref: any) => (slider.current = ref)}
                    speed={100}
                    dots={false}
                    arrows={false}
                    infinite={true}
                    slidesToShow={1}
                    initialSlide={0}
                    className="carousel-row"
                    afterChange={afterChange}
                    beforeChange={collapseClose}
                    centerMode={true}
                    centerPadding="20px"
                >
                    {data}
                </Carousel>
            )}
        </Typography>
    );
});

export default Collection;

interface IProps {
    isFavorite: boolean;
    setIsFavorite: React.Dispatch<React.SetStateAction<boolean>>;
}
