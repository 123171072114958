import {useContext} from "react";
import {Space, Title, Typography, Back} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import _ from "lodash";
// components
import RegisterStoreForm from "../../forms/registerStore";
// core
import {LayoutContext} from "../../../core/Contexts";
// hooks
import useSearchRoute from "../../../core/hooks/useSearchRoute";
// services
import UserService from "../../../services/UserService";

export default function RegisterStore() {
    const context = useContext(LayoutContext);
    const {t} = useTranslation();
    const navigate = useNavigate();
    const route = useSearchRoute(UserService.token);


    return (
        <Typography as="div" className="bl-reset-password">
            <Space
                size={_.gte(context?.width ?? 0, 768) ? 122 : 40}
                direction="vertical"
                align="center"
            >
                <Typography as="div">
                    <Typography as="div">
                        <Back navigate={() => navigate("/")}>{""}</Back>
                    </Typography>
                    <Space size={16} direction="vertical" align="center">
                        <Title title={_.get(route, "title")} />
                        <Typography size={14}>
                            {t(
                                "Заполните личные данные пользователя для регистрации в сервисе Store"
                            )}
                        </Typography>
                    </Space>
                </Typography>
                <RegisterStoreForm />
            </Space>
        </Typography>
    );
}
