import {Typography, Skeleton} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import React from "react";

export default React.memo(function LotterySkeleton() {
    return (
        <Typography as="div" className="skeleton-lottery">
            <Typography as="div" className="skeleton-container">
                <Typography as="div" className="item">
                    <Skeleton.Button active={true} className="text half2" />
                    <Skeleton.Button active={true} className="text" />
                    <Typography as="div" className="inner">
                        <Skeleton.Button active={true} className="text half" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                    </Typography>
                </Typography>
                <Typography as="div" className="item">
                    <Skeleton.Button active={true} className="text half2" />
                    <Skeleton.Button active={true} className="text" />
                    <Typography as="div" className="inner">
                        <Skeleton.Button active={true} className="text half" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                    </Typography>
                </Typography>
                <Typography as="div" className="item">
                    <Skeleton.Button active={true} className="text half2" />
                    <Skeleton.Button active={true} className="text" />
                    <Typography as="div" className="inner">
                        <Skeleton.Button active={true} className="text half" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                    </Typography>
                </Typography>
                <Typography as="div" className="item">
                    <Skeleton.Button active={true} className="text half2" />
                    <Skeleton.Button active={true} className="text" />
                    <Typography as="div" className="inner">
                        <Skeleton.Button active={true} className="text half" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                    </Typography>
                </Typography>
                <Typography as="div" className="item">
                    <Skeleton.Button active={true} className="text half2" />
                    <Skeleton.Button active={true} className="text" />
                    <Typography as="div" className="inner">
                        <Skeleton.Button active={true} className="text half" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                    </Typography>
                </Typography>
                <Typography as="div" className="item">
                    <Skeleton.Button active={true} className="text half2" />
                    <Skeleton.Button active={true} className="text" />
                    <Typography as="div" className="inner">
                        <Skeleton.Button active={true} className="text half" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                    </Typography>
                </Typography>
                <Typography as="div" className="item">
                    <Skeleton.Button active={true} className="text half2" />
                    <Skeleton.Button active={true} className="text" />
                    <Typography as="div" className="inner">
                        <Skeleton.Button active={true} className="text half" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                    </Typography>
                </Typography>
                <Typography as="div" className="item">
                    <Skeleton.Button active={true} className="text half2" />
                    <Skeleton.Button active={true} className="text" />
                    <Typography as="div" className="inner">
                        <Skeleton.Button active={true} className="text half" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                    </Typography>
                </Typography>
                <Typography as="div" className="item">
                    <Skeleton.Button active={true} className="text half2" />
                    <Skeleton.Button active={true} className="text" />
                    <Typography as="div" className="inner">
                        <Skeleton.Button active={true} className="text half" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                    </Typography>
                </Typography>
                <Typography as="div" className="item">
                    <Skeleton.Button active={true} className="text half2" />
                    <Skeleton.Button active={true} className="text" />
                    <Typography as="div" className="inner">
                        <Skeleton.Button active={true} className="text half" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                        <Skeleton.Button active={true} className="circle" />
                    </Typography>
                </Typography>
            </Typography>
            <Typography as="div" className="actions">
                <Skeleton.Button active={true} className="button" />
            </Typography>
        </Typography>
    );
});
