import {ComponentTypes} from "../routes/privateRoutes";

export default function PrivateLayout({component}: Props) {
    const Component = component;
    return <Component />;
}

interface IProps {
    component: ComponentTypes;
}

type Props = IProps;
