import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {Checkbox, Col, Form, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import {FormItemProps} from "antd";
import _ from "lodash";
// core
import {LayoutContext} from "../../../core/Contexts";
import {IFilter} from "../../../core/models/Phones";

export default React.memo(function ItemsField({items, isEmpty, disabledFilters, props}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();

    return (
        <Form.Item
            {...props}
            hasFeedback
            className={`field-items${isEmpty && " empty"}`}
            name="items"
        >
            <Checkbox.Group>
                <Row gutter={[16, 16]}>
                    {_.chain(items)
                        .chunk(
                            _.gte(contextLayout?.width ?? 0, 768)
                                ? Math.ceil(_.size(items) / 4)
                                : _.size(items)
                        )
                        .map((i, k) => {
                            return (
                                <Col key={k} lg={6}>
                                    <Space direction="vertical" size={16}>
                                        {_.map(i, ({name, filter}) => (
                                            <Checkbox
                                                key={filter}
                                                value={filter}
                                                disabled={
                                                    !!_.find(
                                                        disabledFilters,
                                                        (i) => i.filter === filter
                                                    )
                                                }
                                            >
                                                <Typography as="div" size={14} color="#642878">
                                                    {t(name)}
                                                </Typography>
                                            </Checkbox>
                                        ))}
                                    </Space>
                                </Col>
                            );
                        })
                        .valueOf()}
                </Row>
            </Checkbox.Group>
        </Form.Item>
    );
});

interface IProps {
    items: IFilter[];
    isEmpty: boolean;
    props?: FormItemProps<any>;
    disabledFilters?: IFilter[];
}
