import {makeAutoObservable} from "mobx";
import {ILottery, ILotterySummary} from "../core/models/Lottery";

class Lottery {
    [Symbol.toStringTag] = this.constructor.name;
    private _lottery: ILottery | undefined;
    private _summary: ILotterySummary | undefined;

    constructor() {
        makeAutoObservable(this);
    }

    get summary() {
        return this._summary;
    }

    set summary(value: ILotterySummary | undefined) {
        this._summary = value;
    }

    get lottery() {
        return this._lottery;
    }

    set lottery(value: ILottery | undefined) {
        this._lottery = value;
    }
}

export default Lottery;
