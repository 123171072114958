import {Block, Col, Divider, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import "./style.scss";
import {Moment} from "moment";
import {RangeValue} from "rc-picker/lib/interface";
import React, {useState} from "react";
//services
import BonusService from "../../../../services/BonusService";
//components
import Title from "../title";
import Total from "../total";
import List from "../list";
import PaymentsTitlt from "./paymentsTitle";
//forms
import OperationHistoryForm from "../../../forms/operationHistory";
//core
import useBonusSummary from "../../../../core/hooks/useBonusSummary";
import DefaultBlock from "../defaultBlock";
import BonusesService from "../../../../services/BonusesService";

export default function Payments({activeKey}: IProps) {
    const {t} = useTranslation();
    const [period, setPeriod] = useState<RangeValue<Moment> | null>(null);
    const {summary, data, loading, setData, callback} = useBonusSummary(
        period,
        activeKey,
        "payments",
        BonusService.getPaymentsSummary
    );

    const onClick = () => {
        callback(period, data, true);
    };

    return (
        <>
            {_.isEqual(summary?.sum, 0) && _.isEqual(period, null) ? (
                <Typography className="text-content-null">{t("Начислений не найдено")}</Typography>
            ) : _.isEqual(summary?.sum, 0) && !_.isEqual(period, null) ? (
                <DefaultBlock
                    setPeriod={setPeriod}
                    title="За выбранный период начислений не найдено"
                    subtitle=""
                />
            ) : (
                <Block className="bl-accrual-history">
                    <Typography as="div" className="header-bl-operations-histoty">
                        <Row>
                            <Col md={0} xs={24}>
                                <Typography as="div" className="title-mobile">
                                    <Title>История начислений</Title>
                                </Typography>
                            </Col>
                            <Col xs={24} md={{span: 12, push: 12}}>
                                <Typography as="div" className="total-sum-amount">
                                    <Space size={0}>
                                        <Total title="Накоплено" sum={summary?.sum ?? 0} />
                                        {!period ? (
                                            <>
                                                <Divider type="vertical" />
                                                <Total
                                                    title="Начисляются"
                                                    sum={summary?.process_sum ?? 0}
                                                />
                                            </>
                                        ) : null}
                                    </Space>
                                </Typography>
                            </Col>
                            <Col xs={24} md={{span: 12, pull: 12}}>
                                <Typography as="div" className="title-and-datapicker">
                                    <Space size={16} direction="vertical">
                                        <Typography as="div" className="title-desctop">
                                            <Title>История начислений</Title>
                                        </Typography>

                                        <OperationHistoryForm
                                            label="Период начислений"
                                            activeKey={activeKey}
                                            set={setPeriod}
                                        />
                                    </Space>
                                </Typography>
                            </Col>
                        </Row>
                    </Typography>
                    <PaymentsTitlt period={period} />
                    <Divider />

                    {_.size(data) ? (
                        <List
                            start={BonusesService.firstPaymentAt}
                            period={period}
                            data={data}
                            activeKey={activeKey}
                            loading={loading}
                            setData={setData}
                            onClick={onClick}
                            fn={BonusService.getPaymentsItems}
                        />
                    ) : null}
                </Block>
            )}
        </>
    );
}

interface IProps {
    activeKey: string;
}
