import {useContext, useEffect} from "react";
import {Block, Button, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import _ from "lodash";
// core
import {LayoutContext} from "../../../../core/Contexts";
// components
import {useNavigate} from "react-router-dom";
import UserService from "../../../../services/UserService";
import {observer} from "mobx-react";

export default observer(function MyActivations() {
    const contextLayout = useContext(LayoutContext);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const onClick = () => {
        navigate("/activation");
    };

    useEffect(() => {
        UserService.getFullUserInfoLevel();
    }, []);

    const lineFunction = (text: string, number: number) => {
        return (
            <Typography as="div" className="bl-my-dealers-text">
                <Typography
                    color="rgba(255, 255, 255, 0.5)"
                    size={_.gte(contextLayout?.width, 768) ?? 0 ? 17 : 14}
                >
                    {text}
                </Typography>
                <Typography as="span" size={_.gte(contextLayout?.width, 768) ?? 0 ? 17 : 14}>
                    {number}
                </Typography>
            </Typography>
        );
    };

    return (
        <Typography as="div" className="bl-my-dealers">
            <Block title={t("Активации")}>
                {lineFunction(
                    t("Текущий месяц"),
                    _.isUndefined(UserService.levelUserInfo?.activation.current_month_cnt)
                        ? 0
                        : +UserService.levelUserInfo!.activation.current_month_cnt
                )}
                {lineFunction(
                    t("Прошлый месяц"),
                    _.isUndefined(UserService.levelUserInfo?.activation.previous_month_cnt)
                        ? 0
                        : +UserService.levelUserInfo!.activation.previous_month_cnt
                )}
                {lineFunction(
                    t("За все время"),
                    _.isUndefined(UserService.levelUserInfo?.activation.total_cnt)
                        ? 0
                        : +UserService.levelUserInfo!.activation.total_cnt
                )}
                <Button type="primary" onClick={onClick}>
                    {t("Активации")}
                </Button>
            </Block>
        </Typography>
    );
});
