import {makeAutoObservable} from "mobx";
// core
import {IPhonesFiltersStore} from "../core/models/PhonesFilters";
// core

class NumbersFiltersStore {
    [Symbol.toStringTag] = this.constructor.name;
    private _items: IPhonesFiltersStore[] = [];
    private _current: number | undefined;

    constructor() {
        makeAutoObservable(this);
    }

    get items() {
        return this._items;
    }

    set items(value: IPhonesFiltersStore[]) {
        this._items = value;
    }

    get current() {
        return this._current;
    }

    set current(value: number | undefined) {
        this._current = value;
    }
}

export default NumbersFiltersStore;
