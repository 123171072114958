import {Block, Button, Space} from "@bezlimit/bezlimit-ui";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import "./style.scss";
import {ISupportFaq} from "../../support/supportFaqList";
// components
import SupportFaqCarusel from "../../../modal/support";
// core
import {ModalContext} from "../../../../core/Contexts";
//mock
import {SUPPORTFAQMOCK} from "../../../../mock/support/get_support_faq";
// store
import Store from "../../../../stores";

export default React.memo(function Support() {
    const contextModal = useContext(ModalContext);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const list: ISupportFaq[] = SUPPORTFAQMOCK;

    const onClick = (id: number) => {
        contextModal?.setVisible(true);
        contextModal?.setId(id);
        contextModal?.setChildren(<SupportFaqCarusel list={list} />);
    };

    return (
        <div className="bl-support-widget">
            <Block
                title={t("Поддержка")}
                description={t("Список наиболее часто задаваемых вопросов")}
            >
                <Space className="support-content" size={[8, 8]} wrap={true}>
                    {list
                        .filter((i, k) => (Store.user?.level?.level !== 2 ? k < 3 : k < 6))
                        .map((item) => (
                            <Button
                                key={item.id}
                                type="default"
                                onClick={() => onClick(item.id - 1)}
                            >
                                {t(item.title)}
                            </Button>
                        ))}

                    <Button
                        className="support-link"
                        type="default"
                        onClick={() => navigate("/support")}
                    >
                        {t("Здесь нет моего вопроса")}
                    </Button>
                </Space>
            </Block>
        </div>
    );
});
