import {makeAutoObservable} from "mobx";
import {ILevel, IMeta, ILevelsResponse} from "../core/models/Levels";

class Levels implements ILevelsResponse {
    [Symbol.toStringTag] = this.constructor.name;
    private _items!: ILevel[];
    private _meta!: IMeta;

    constructor() {
        makeAutoObservable(this);
    }

    get items() {
        return this._items;
    }

    set items(value: ILevel[]) {
        this._items = value;
    }

    get meta() {
        return this._meta;
    }

    set meta(value: IMeta) {
        this._meta = value;
    }
}

export default Levels;
