import React from "react";
import {Modal, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import "./style.scss";
// components
import Filter from "./filter";
// services
import PhonesFiltersService from "../../../../../../services/PhonesFiltersService";
import NumbersFiltersStoreService from "../../../../../../services/NumbersFiltersStoreService";
import MyFilterSets from "./myFilterSets";
import Success from "../../../../../modal/success";

export default React.memo(
    observer(function Selected({
        type,
        tariff,
        categories,
        region,
        pattern,
        phonePattern,
        phoneCombs,
        setType,
        setTariff,
        setCategories,
        setRegion,
        setPattern,
        setPhonePattern,
        setPhoneCombs
    }: IProps) {
        const {t} = useTranslation();

        const handleCancelRemove = () => {
            PhonesFiltersService.isRemovedItem = false;
        };

        return (
            <>
                <Typography as="div" className="bl-numbers-filters-selected">
                    {NumbersFiltersStoreService.items.length > 0 && (
                        <MyFilterSets
                            setType={setType}
                            setTariff={setTariff}
                            setCategories={setCategories}
                            setRegion={setRegion}
                            setPattern={setPattern}
                            setPhonePattern={setPhonePattern}
                            setPhoneCombs={setPhoneCombs}
                            phonePattern={phonePattern}
                            phoneCombs={phoneCombs}
                        />
                    )}
                    <Typography as="div" className="items">
                        {!!_.size(type) && (
                            <Filter items={type} filters={PhonesFiltersService.filterType} />
                        )}
                        {!!_.size(tariff) && (
                            <Filter
                                items={tariff}
                                set={setTariff}
                                filters={PhonesFiltersService.filterTariff}
                                type="tariff"
                            />
                        )}
                        {!!_.size(categories) && (
                            <Filter
                                items={categories}
                                set={setCategories}
                                filters={PhonesFiltersService.filterCategories}
                                type="categories"
                            />
                        )}
                        {!!_.size(region) && (
                            <Filter
                                items={region}
                                set={setRegion}
                                filters={PhonesFiltersService.filterRegion}
                                type="region"
                            />
                        )}
                        {!!_.size(pattern) && (
                            <Filter
                                items={pattern}
                                set={setPattern}
                                filters={PhonesFiltersService.filterPattern}
                                type="pattern"
                            />
                        )}
                    </Typography>
                    {!!_.size(_.replace(phoneCombs ?? "", /\,/gm, "")) && (
                        <Typography as="div">
                            <Filter
                                items={["phoneCombs"]}
                                set={setPhoneCombs}
                                filters={[
                                    {
                                        filter: "phoneCombs",
                                        name: t("Комбинации: {name}", {
                                            name: _.chain(_.split(phoneCombs!, ","))
                                                .filter((i) => !!(!_.isNil(i) && _.size(i)))
                                                .join("-")
                                                .valueOf()
                                        })
                                    }
                                ]}
                            />
                        </Typography>
                    )}
                    {!!_.size(phonePattern.filter((i) => i)) && (
                        <Typography as="div">
                            <Filter
                                items={["phonePattern"]}
                                set={setPhonePattern}
                                filters={[
                                    {
                                        filter: "phonePattern",
                                        name: t("ABCN-фильтрация: {name}", {
                                            name: _.chain(phonePattern)
                                                .map((i) => (_.size(_.trim(i)) ? i : "N"))
                                                .reduce((res = "", i, k) => {
                                                    res += _.isEqual(k, 3) ? ` ${i}` : i;
                                                    return res;
                                                }, "")
                                                .valueOf()
                                        })
                                    }
                                ]}
                            />
                        </Typography>
                    )}
                </Typography>
                <Modal
                    open={PhonesFiltersService.isRemovedItem}
                    onCancel={handleCancelRemove}
                    className="delete-success-modal"
                >
                    <Success title={t("Группа успешно удалена")} />
                </Modal>
            </>
        );
    })
);

interface IProps {
    type: string[];
    tariff: string[];
    categories: string[];
    region: string[];
    pattern: string[];
    phonePattern: string[];
    phoneCombs: string | undefined;
    setType: React.Dispatch<React.SetStateAction<string[]>>;
    setTariff: React.Dispatch<React.SetStateAction<string[]>>;
    setCategories: React.Dispatch<React.SetStateAction<string[]>>;
    setRegion: React.Dispatch<React.SetStateAction<string[]>>;
    setPattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhonePattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhoneCombs: React.Dispatch<React.SetStateAction<string | undefined>>;
}
