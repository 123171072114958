import "./style.scss";

export const Scroller = (): JSX.Element => {
  return (
    <div className="bl-scroller-wrapper">
      <svg
        className="scroller-bg"
        viewBox="0 0 88 22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g>
          <circle cy="11" cx="2" r="2" fill="#9876A4" />
          <circle cy="11" cx="16" r="2" fill="#9876A4" />
          <circle cy="11" cx="30" r="2" fill="#9876A4" />
          <circle cy="11" cx="44" r="2" fill="#9876A4" />
          <circle cy="11" cx="58" r="2" fill="#9876A4" />
          <circle cy="11" cx="72" r="2" fill="#9876A4" />
          <circle cy="11" cx="86" r="2" fill="#9876A4" />
        </g>
        <animate
          dur="0.1s"
          attributeName="opacity"
          from="1"
          to="0"
          begin="move.end"
          repeatCount="0"
          fill="freeze"
        />
      </svg>
      <svg
        className="scroller-line"
        viewBox="0 0 88 22"
        xmlns="http://www.w3.org/2000/svg"
      >
        <rect x="0" y="9" width="32" height="5" rx="2" fill="white">
          <animate
            id="move"
            attributeType="XML"
            attributeName="x"
            from="-100"
            to="88"
            dur="3s"
            repeatCount="indefinite"
          />
          <animate
            dur="0.1s"
            attributeName="opacity"
            from="1"
            to="0"
            begin="move.end"
            repeatCount="0"
            fill="freeze"
          />
        </rect>
      </svg>
    </div>
  );
};
