import React, {useContext} from "react";
import {Icon, Typography, Breadcrumb, Space} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext, ModalContext} from "../../../../../../../core/Contexts";
import {IPhonesFiltersRequest} from "../../../../../../../core/models/PhonesFilters";
// componentns
import NumberListInfoModal from "../../../../../../modal/numberListInfo";
import Limits from "./limits";
// services
import PhoneService from "../../../../../../../services/PhoneService";

const defaultOptions = {
    size: 14,
    color: "rgba(255, 255, 255, 0.5)"
};

export default React.memo(function Description({
    phone,
    tariffName,
    regionName,
    minutes,
    internet,
    category,
    type,
    sms,
    id,
    filters,
    Service
}: IProps) {
    const contextLayout = useContext(LayoutContext);
    const contextModal = useContext(ModalContext);

    const onClick = () => {
        contextModal?.setChildren(
            <NumberListInfoModal
                phone={phone}
                category={category}
                tariffName={tariffName}
                minutes={minutes}
                internet={internet}
                type={type}
                sms={sms}
                id={id}
                filters={filters}
                Service={Service}
            />
        );
        contextModal?.setVisible(true);
    };

    return (
        <Typography as="div" className="bl-numbers-list-info-description">
            <Breadcrumb separator={<Icon name="dot" size="5" />}>
                <Breadcrumb.Item className="tariff-name">
                    <Space size={4}>
                        <Typography as="span" className="nowrap" {...defaultOptions}>
                            {tariffName}
                        </Typography>
                        {_.lte(contextLayout?.width, 768) && (
                            <Icon className="info" name="info" size="16" onClick={onClick} />
                        )}
                    </Space>
                </Breadcrumb.Item>
                {_.gte(contextLayout?.width, 768) ?? 0 ? (
                    <Breadcrumb.Item>
                        <Typography as="span" {...defaultOptions}>
                            {regionName}
                        </Typography>
                    </Breadcrumb.Item>
                ) : null}
                {_.gte(contextLayout?.width, 768) ?? 0 ? (
                    <>
                        {Service.hasLimits({minutes, internet, sms}) && (
                            <Limits minutes={minutes} internet={internet} sms={sms} />
                        )}
                    </>
                ) : null}
            </Breadcrumb>
        </Typography>
    );
});

interface IProps {
    phone: number;
    tariffName: string;
    regionName: string;
    minutes: number;
    internet: number;
    category: string;
    type: number;
    sms: number;
    id: number;
    filters: IPhonesFiltersRequest;
    Service: PhoneService;
}
