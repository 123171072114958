import {Button, Form, Space, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import React, {useContext, useRef} from "react";
import _ from "lodash";
//core
import {InterceptorContext, LayoutContext} from "../../../core/Contexts";
import useFormHandler from "../../../core/hooks/useFormHandler";
import useFieldFocus from "../../../core/hooks/useFieldFocus";
//fields
import SaveFiltersField from "../../fields/saveFilters";
//services
import NumbersFiltersStoreService from "../../../services/NumbersFiltersStoreService";
import PhonesFiltersService from "../../../services/PhonesFiltersService";

export default observer(function SaveFiltersForm({
                                                     index,
                                                     name,
                                                     isMobile,
                                                     phonePattern,
                                                     phoneCombs,
                                                     setIsModalOpen
                                                 }: IProps) {
    const context = useContext(InterceptorContext);
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();
    const ref = useRef(null);
    const [form] = Form.useForm();
    const formInstance = useFormHandler(form);
    useFieldFocus(ref, "phone");

    const onFinish = ({saveFilters}: any) => {
        if (!_.isNil(index)) {
            const clone = _.clone(NumbersFiltersStoreService.items);
            const value = clone.find((i, k) => _.isEqual(k, index));
            value!.name = saveFilters;
            clone[index] = value!;
            NumbersFiltersStoreService.update(clone);

            if (value) {
                NumbersFiltersStoreService.updateFilter(value);
            }
        } else {
            const value = {
                name: saveFilters,
                values: {
                    ...PhonesFiltersService.currentFilter,
                    ...(isMobile
                        ? {}
                        : {
                            phone_combs: phoneCombs,
                            phone_pattern: phonePattern
                        })
                }
            };

            NumbersFiltersStoreService.add(value);
        }

        if (setIsModalOpen) {
            setIsModalOpen(false);
            formInstance.resetFields();
        }
    };

    return (
        <div ref={ref}>
            <Typography as="div" className="bl-save-filters">
                <Form
                    form={formInstance}
                    layout="horizontal"
                    name="saveFilters"
                    onFinish={onFinish}
                    autoComplete="off"
                    initialValues={{
                        saveFilters:
                            NumbersFiltersStoreService.items.find((i, k) => _.isEqual(k, index))
                                ?.name ?? ""
                    }}
                >
                    <Space
                        size={_.gte(contextLayout?.width ?? 0, 768) ? 8 : 24}
                        direction="vertical"
                    >
                        <SaveFiltersField />
                        <Form.Item noStyle shouldUpdate>
                            {({getFieldsValue, getFieldsError}) => {
                                const value = getFieldsValue();
                                const formIsComplete = !_.isEqual(value.saveFilters, "");

                                return (
                                    <Button
                                        type={!formIsComplete ? "default" : "primary"}
                                        htmlType="submit"
                                        disabled={!formIsComplete}
                                        loading={context?.loadingBtn}
                                    >
                                        {t("Сохранить")}
                                    </Button>
                                );
                            }}
                        </Form.Item>
                    </Space>
                </Form>
            </Typography>
        </div>
    );
});

interface IProps {
    isMobile?: boolean;
    index?: number;
    name?: string;
    phonePattern: string[];
    phoneCombs: string | undefined;
    setIsModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
