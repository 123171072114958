import {Form, DatePicker} from "@bezlimit/bezlimit-ui";
import {FormItemProps} from "antd";
import _ from "lodash";
import moment from "moment";
import {Rules} from "../../forms/rules";

const options = {
    allowClear: true,
    format: "DD.MM.YYYY",
    showToday: false,
    def: moment().add(1, "days")
};

export default function DatePickerField(props: FormItemProps<any>) {
    return (
        <Form.Item name="date" {...props}>
            <DatePicker
                defaultValue={moment().add(1, "days")}
                placeholder=""
                {...options}
                onFocus={(option) => {
                    (option.target as any).closest("form").classList.add("focused");
                }}
                onBlur={(option) => {
                    const target = option.target as any;
                    if (!_.size(_.trim(target.value))) {
                        target.closest("form").classList.remove("focused");
                    }
                }}
                disabledDate={(current) => current.isBefore(moment())}
            />
        </Form.Item>
    );
}
