import React, {useContext, useEffect, useRef, useState} from "react";
import {Carousel, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
// core
import {ModalContext} from "../../../core/Contexts";
// components
import {ISupportFaq, ISupportFaqList} from "../../blocks/support/supportFaqList";
import "./style.scss";

const SupportFaq = ({title, description}: ISupportFaq): JSX.Element => {
    const {t} = useTranslation();

    const createMarkup = (description: string) => {
        return {__html: description};
    };

    return (
        <div className="bl-support-faq">
            <Typography className="faq-title">{t(title)}</Typography>
            <div
                className="faq-description"
                dangerouslySetInnerHTML={createMarkup(description)}
            ></div>
        </div>
    );
};

export default React.memo(
    observer(function SupportFaqCarusel({list}: ISupportFaqList) {
        const {t} = useTranslation();
        const ref = useRef<any>(null);
        const context = useContext(ModalContext);
        const [currentIndex, setCurrentIndex] = useState(context?.id ?? 0);

        useEffect(() => {
            setCurrentIndex(context?.id ?? 0);
        }, [context?.id]);

        return (
            <Carousel
                className="modal-carousel-container"
                ref={ref}
                speed={100}
                effect="fade"
                dots={false}
                arrows={true}
                infinite={false}
                initialSlide={currentIndex + 1}
                adaptiveHeight={true}
                nextArrow={
                    <Typography>
                        {t(
                            _.get(
                                list,
                                `[${
                                    _.isEqual(currentIndex, list.length) ? 0 : currentIndex + 1
                                }].title`,
                                ""
                            )
                        )}
                    </Typography>
                }
                prevArrow={
                    <Typography>
                        {t(
                            _.get(
                                list,
                                `[${
                                    _.isEqual(currentIndex, 0) ? list.length : currentIndex - 1
                                }].title`,
                                ""
                            )
                        )}
                    </Typography>
                }
                onReInit={() => {
                    if (
                        ref.current &&
                        !_.isEqual(
                            _.get(ref.current, "innerSlider.track.props.currentSlide", 0),
                            currentIndex
                        )
                    ) {
                        ref.current.goTo(currentIndex);
                    }
                }}
                beforeChange={(currentSlide, nextSlide) => {
                    setCurrentIndex(nextSlide);
                }}
            >
                {(list || []).map((item) => (
                    <React.Fragment key={item.id}>
                        <SupportFaq {...item} />
                    </React.Fragment>
                ))}
            </Carousel>
        );
    })
);
