import {Col, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import "./style.scss";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
//core
import {IDescriptionTariffAvailable} from "../../../../../../core/models/Tariffs";
import {getFieldContent} from "../../../../../../utils";

export default observer(function ListModal({title, fields}: Props) {
    const {t} = useTranslation();

    return (
        <>
            <Typography as="div" className="modal-tariff-container-wrapper">
                <Space
                    direction="vertical"
                    size={[8, 18]}
                    className="modal-carousel-container modal-tariff-container"
                >
                    <Typography className="info-tariff-modal-title">{t(title)}</Typography>

                    {_.chain(fields)
                        .filter(({value}) => !_.isNil(value))
                        .map(({title, value, contentAfter, contentBefore}) => (
                            <>
                                <Row gutter={[40, 16]} key={title}>
                                    <Col span={12}>
                                        <Typography className="modal-title-content">
                                            {t(title)}
                                        </Typography>
                                    </Col>
                                    <Col span={12}>
                                        <Typography
                                            className="moda-value-content"
                                            dangerouslySetInnerHTML={{
                                                __html: getFieldContent(
                                                    title,
                                                    contentBefore,
                                                    (value as string) === "0"
                                                        ? t("Безлимит")
                                                        : value,
                                                    (value as string) === "0" ? "" : contentAfter
                                                )
                                            }}
                                        ></Typography>
                                    </Col>
                                </Row>
                            </>
                        ))
                        .valueOf()}
                </Space>
            </Typography>
        </>
    );
});

interface IProps extends IDescriptionTariffAvailable {}

type Props = IProps;
