import React, {useContext, useEffect, useState} from "react";
import {Button, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import "./style.scss";
// components
import {LayoutContext, ModalContext} from "../../../../../../core/Contexts";
import ExtendModal from "../../../../../modal/extend";
import BonusesService from "../../../../../../services/BonusesService";
import _ from "lodash";
import {IReservations} from "../../../../../../core/models/Reservations";
import {observer} from "mobx-react";
import ActivationModal from "../../../../widgets/paymentReservedCard";
import UserCardService from "../../../../../../services/UserCardService";
import ModalBankCardsSaving from "../../../../profile/savingBankCards/modalBankCardsSaving";
import NotBonuses from "../../../../../modal/notBonuses";

export default React.memo(
    observer(function Extend({
        id,
        phone,
        tariffId,
        prolongPaidPrice,
        tariffName,
        recommend_sum,
        price,
        activeKey,
        setItems,
        pay_type
    }: IProps) {
        const contextModal = useContext(ModalContext);
        const contextLayout = useContext(LayoutContext);
        const [hashSuccess, setHashSuccess] = useState(false);
        const [isActivationModalVisible, setActivationModalVisible] = useState(false);
        const [isActivationModalNotBonuses, setActivationModalNotBonuses] = useState(false);
        const [isSavingModalVisible, setSavingModalVisible] = useState(false);
        const arrayHash = ["#already_added_url", "#card-add-fail"];
        const {t} = useTranslation();

        const onClick = async () => {
            contextModal?.setChildren(
                <ExtendModal
                    id={id}
                    phone={phone}
                    tariffId={tariffId}
                    prolongPaidPrice={prolongPaidPrice}
                />
            );
            contextModal?.setVisible(true);
        };

        const onModal = () => {
            UserCardService.fetchGetBankCard();
            if (!_.gte(BonusesService.accumulated, recommend_sum)) {
                setActivationModalNotBonuses(true);
            } else {
                setActivationModalVisible(true);
            }
        };

        useEffect(() => {
            if (_.isEqual(phone.toString(), localStorage.getItem("number-payment"))) {
                if (_.isEqual("#card-add-success", document.location.hash)) {
                    UserCardService.fetchGetBankCard();
                    setHashSuccess(true);
                    setTimeout(() => {
                        setActivationModalVisible(true);
                    }, 100);
                }
                !_.isEqual(
                    arrayHash.filter((hash: string) => _.isEqual(hash, document.location.hash))
                        .length,
                    0
                ) && setSavingModalVisible(true);
            }
        }, []);

        return (
            <>
                <Typography as="div" className="bl-reservation-list-info-extend">
                    <Button
                        className="btn-reservation"
                        type="default"
                        onClick={onClick}
                        disabled={
                            !_.gte(BonusesService.accumulated, prolongPaidPrice) ||
                            _.isEqual(pay_type, "bonus")
                        }
                    >
                        <Typography
                            as="div"
                            color="#fff"
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 17 : 14}
                        >
                            {t("Продлить бронь")}
                        </Typography>
                    </Button>
                    <Button className="btn-replenishment" type="primary" onClick={onModal}>
                        <Typography
                            as="div"
                            color="#000000"
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 17 : 14}
                        >
                            {t("Оплатить")} {recommend_sum ?? price}
                        </Typography>
                    </Button>
                </Typography>
                <ActivationModal
                    isMainPage={false}
                    hashSuccess={hashSuccess}
                    visible={isActivationModalVisible}
                    cancelHandler={() => setActivationModalVisible(false)}
                    number={phone}
                    tariffName={tariffName}
                    price={price}
                    activeKey={activeKey}
                    setItems={setItems}
                    recommend_sum={recommend_sum}
                />
                <ModalBankCardsSaving
                    visible={isSavingModalVisible}
                    cancelHandler={() => setSavingModalVisible(false)}
                />
                <NotBonuses
                    hashSuccess={hashSuccess}
                    isMainPage={false}
                    visible={isActivationModalNotBonuses}
                    cancelHandler={() => setActivationModalNotBonuses(false)}
                    number={phone}
                    tariffName={tariffName}
                    recommend_sum={recommend_sum}
                />
            </>
        );
    })
);

interface IProps {
    id: number;
    phone: number;
    tariffId: number;
    prolongPaidPrice: number;
    tariffName: string;
    price: number;
    recommend_sum: number;
    activeKey: string;
    setItems: React.Dispatch<React.SetStateAction<IReservations[]>>;
    pay_type: string;
}
