import {Block, Button, Col, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import _ from "lodash";
import React, {useContext, useEffect} from "react";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import {observer} from "mobx-react";
// components
import List from "./list";
// servise
import DeliveryService from "../../../../services/DeliveryService";
import {LayoutContext} from "../../../../core/Contexts";

export default React.memo(
    observer(function Delivery() {
        const {t} = useTranslation();
        const navigate = useNavigate();
        const context = useContext(LayoutContext);

        const onDeliveryNew = () => {
            navigate("/deliveries/new");
        };

        const onDelivery = () => {
            navigate("/deliveries");
        };

        useEffect(() => {
            DeliveryService.fetchDelivery({per_page: 6});
        }, []);

        return (
            <Typography as="div" className="bl-widget-delivery">
                {_.isEqual(DeliveryService.itemsSize, 0) ? (
                    <Block>
                        <Row>
                            <Col md={24} xs={0}>
                                <Typography className="title-desctop">{t("Доставка")}</Typography>
                            </Col>
                            <Col md={24} xs={0}>
                                <Typography className="sub-title-desctop">
                                    {t(
                                        "Доставка сим-карты и конвертов с описанием тарифных планов"
                                    )}
                                </Typography>
                            </Col>
                            <Space direction="vertical" size={8}>
                                <Col span={24}>
                                    <Typography className="text-items-size">
                                        {t("У вас пока нет оформленных доставок")}
                                    </Typography>
                                </Col>
                                <Col span={24}>
                                    <Typography className="sub-text-items-size">
                                        {t("Перейдите в “Новая доставка” для выбора оформления")}
                                    </Typography>
                                </Col>
                                <Col md={0} xs={24}>
                                    <Typography className="title-mobile">
                                        {t("Доставка")}
                                    </Typography>
                                </Col>
                                <Col md={0} xs={24}>
                                    <Typography className="suttitle-mobile">
                                        {t(
                                            "Доставка сим-карты и конвертов с описанием тарифных планов"
                                        )}
                                    </Typography>
                                </Col>
                                <Col span={24}>
                                    {_.gte(context?.width ?? 0, 768) ? (
                                        <Typography as="div" className="button-delivery-items-size">
                                            <Button type="default" onClick={onDeliveryNew}>
                                                {t("Новая доставка")}
                                            </Button>
                                        </Typography>
                                    ) : (
                                        <Typography
                                            as="div"
                                            className="button-delivery-items-size-mobile"
                                        >
                                            <Button type="primary" onClick={onDeliveryNew}>
                                                {t("Новая доставка")}
                                            </Button>
                                        </Typography>
                                    )}
                                </Col>
                            </Space>
                        </Row>
                    </Block>
                ) : (
                    <Block
                        title={t("Доставка")}
                        description={t(
                            "Доставка сим-карты и конвертов с описанием тарифных планов"
                        )}
                    >
                        <Row>
                            <Col span={24}>
                                <List />
                            </Col>
                            <Col span={24}>
                                <Typography as="div" className="button-delivery">
                                    <Space size={16}>
                                        <Button type="default" onClick={onDelivery}>
                                            {t("Доставки")}
                                        </Button>
                                        <Button type="primary" onClick={onDeliveryNew}>
                                            {t("Новая доставка")}
                                        </Button>
                                    </Space>
                                </Typography>
                            </Col>
                        </Row>
                    </Block>
                )}
            </Typography>
        );
    })
);
