import {Typography, Row, Col, Checkbox, Space, Skeleton, Divider} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {MobXProviderContext, observer} from "mobx-react";
import {useContext, useEffect, useState} from "react";

const KanbanList: React.FC<IProps> = observer(({isFavorite}) => {
    const [arr, setArr] = useState(Array(33));
    const {store} = useContext(MobXProviderContext);

    useEffect(() => {
        if (store.pageLoading) {
            setTimeout(() => {
                setArr(Array(99));
            }, 300);
        } else {
            setArr(Array(33));
        }
    }, [store.pageLoading]);

    return (
        <Typography as="div" className="skeleton-list kanban-list">
            <Row gutter={[24, 24]} className="head">
                <Col md={12}>
                    <Checkbox disabled={true}>Выбрать все</Checkbox>
                </Col>
                <Col md={12} className="flex-left">
                    <Space direction="horizontal" size={8} className="rt8">
                        <Skeleton.Button active={true} className="w40 h40" />
                        <Skeleton.Button active={true} className="w40 h40" />
                    </Space>
                </Col>
                <Col md={24}>
                    <Divider />
                </Col>
            </Row>
            <Row gutter={[16, 16]} className="wrapper-container">
                {_.chain(arr)
                    .map((i, k) => (
                        <Col md={8}>
                            <Typography as="div" className="wrapper">
                                <Row gutter={[12, 12]} className="list">
                                    <Col md={3} lg={isFavorite ? 2 : 3}>
                                        <Checkbox disabled={false} />
                                    </Col>
                                    <Col md={17} lg={isFavorite ? 19 : 17}>
                                        <Skeleton.Button active={true} className="w100 h40" />
                                        <Skeleton.Button active={true} className="w100" />
                                        <Skeleton.Button active={true} className="w100 h16" />
                                    </Col>
                                    <Col md={4} lg={isFavorite ? 3 : 4} className="flex-left">
                                        <Skeleton.Button active={true} className="w26" />
                                    </Col>
                                </Row>
                            </Typography>
                        </Col>
                    ))
                    .valueOf()}
            </Row>
            <Row gutter={[24, 24]}>
                <Col md={24}>
                    <Skeleton.Button active={true} className="w100 h48" />
                </Col>
            </Row>
        </Typography>
    );
});

export default KanbanList;

interface IProps {
    isFavorite: boolean;
}
