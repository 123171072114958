import {Row, Col} from "@bezlimit/bezlimit-ui";
// components
import NewPassword from "../../../blocks/newPassword";
// hooks
import usePuthState from "../../../../core/hooks/usePushState";

export default function NewPasswordPage() {
    usePuthState();

    return (
        <Row justify="center" gutter={32}>
            <Col lg={{span: 24}} xs={{span: 24}}>
                <NewPassword />
            </Col>
        </Row>
    );
}
