import {Menu as MenuAntd, Typography, Space, Row, Col, Skeleton} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
// import _ from "lodash";

export default observer(function DesctopShimmer() {
    return (
        <div className="bl-menu sidebar-profile-shimmer">
            <Typography as="div" className="list-sessions">
                <Row className="row-group-btn-close">
                    <Col sm={22} xs={24} className="col-back">
                        <Skeleton.Button active={true} className="btn-skeleton-back" />
                    </Col>
                    <Col sm={2} xs={0} className="col-close">
                        <Skeleton.Button active={true} className="btn-skeleton-close" />
                    </Col>
                </Row>

                <div className="links">
                    <MenuAntd mode="inline" />
                </div>
                <Space direction="vertical" size={24}>
                    <Typography as="div" className="block-basic">
                        <Typography as="div" className="title">
                            <Skeleton.Button active={true} className="btn-skeleton-title" />
                        </Typography>
                        <Skeleton.Button active={true} className="btn-skeleton-active" />
                    </Typography>
                    <Typography as="div" className="block-active-sessions">
                        <Typography as="div" className="title">
                            <Skeleton.Button active={true} className="btn-skeleton-title" />
                        </Typography>
                        <Skeleton.Button active={true} className="btn-skeleton-active" />
                        <Skeleton.Button active={true} className="btn-skeleton-active" />
                        <Skeleton.Button active={true} className="btn-skeleton-active" />
                    </Typography>

                    <Typography as="div" className="btn-close-group">
                        <Skeleton.Button active={true} className="btn-skeleton" />
                        <Skeleton.Button active={true} className="btn-skeleton" />
                    </Typography>
                </Space>
            </Typography>
        </div>
    );
});
