import {Back, Col, Row} from "@bezlimit/bezlimit-ui";
import {useNavigate} from "react-router-dom";
// components;
import "./style.scss";
import Block1 from "./block1";
import Block2 from "./block2";
import Block3 from "./block3";
import Block4 from "./block4";

export default function NpsPage() {
    const navigate = useNavigate();
    return (
        <Row justify="center" gutter={32}>
            <Back navigate={() => navigate("/")} />
            <Col lg={{span: 24}} xs={{span: 24}} className="p0">
                <Row gutter={[16, 16]}>
                    <Col xs={24}>
                        <Block1 />
                    </Col>
                    <Col xs={24} lg={10}>
                        <Block2 />
                    </Col>
                    <Col xs={24} lg={14}>
                        <Block3 />
                        <Block4 />
                    </Col>
                </Row>
            </Col>
        </Row>
    );
}
