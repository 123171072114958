import {Col, Row, Space, Typography, Progress, Icon} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
import cn from "classnames";
import UserService from "../../../../../services/UserService";
import {IItems} from "../../../../../core/models/Bonus";
import NpsHistory from "../../../../../images/nps-history.png";

export default function ListPayments({
    type,
    status,
    total_sum,
    sum,
    phone,
    comment,
    loyalty,
    action,
    isLoyalty
}: IProps) {
    const {t} = useTranslation();
    const accrued = total_sum - sum;

    const getLoyaltyProgress = (num1: number, num2: number): number => {
        const sum = num1 + num2;
        if (!sum) {
            return 0;
        }
        return (num1 * 100) / sum;
    };

    return (
        <Typography as="div" className="payments-history">
            <Row gutter={[8, 16]}>
                {isLoyalty && (
                    <Col md={5} xs={0}>
                        <Space>
                            {!_.isNil(loyalty) && !_.isNil(loyalty.code) ? (
                                <Icon name={`loyalty_${loyalty.code}`} size="16" />
                            ) : (
                                <Icon name={`loyalty_${UserService.loyalty?.code}`} size="16" />
                            )}
                            <Typography size={17} color="rgba(255, 255, 255, 0.5)">
                                {!_.isNil(loyalty) && loyalty?.name
                                    ? loyalty?.name
                                    : UserService.loyalty?.name}
                            </Typography>
                        </Space>
                    </Col>
                )}

                <Col md={isLoyalty ? 7 : 12} xs={0}>
                    {_.isEqual(type, "gift") ? (
                        !_.isNil(action) ? (
                            <Typography as="div" className="bl-nps">
                                <Typography size={17}>{comment}</Typography>{" "}
                                <Typography as="div" size={14} className="nps-description">
                                    <img src={NpsHistory} width={20} alt="" />
                                    {action.description}
                                </Typography>
                            </Typography>
                        ) : (
                            <Typography size={17}>{comment}</Typography>
                        )
                    ) : !_.isNil(action) ? (
                        <Typography as="div" className="bl-nps">
                            <Typography size={17}>{phone}</Typography>{" "}
                            <Typography as="div" size={14} className="nps-description">
                                <img src={NpsHistory} width={20} alt="" />
                                {action.description}
                            </Typography>
                        </Typography>
                    ) : (
                        <Typography size={17}>{phone}</Typography>
                    )}
                </Col>
                <Col md={6} xs={0}>
                    {_.isEqual(type, "gift") ? (
                        <Space>
                            <Icon name="lightning2" size="14" />
                            <Typography size={17}>{t("Выплачен")}</Typography>
                        </Space>
                    ) : _.isEqual(status, 2) ? (
                        <Space>
                            <Icon name="status_active" size="14" />
                            <Typography size={17}>{t("Активен")}</Typography>
                        </Space>
                    ) : _.isEqual(status, 3) ? (
                        <Space>
                            <Icon name="status_blocked" size="14" />
                            <Typography size={17}>{t("Заблокирован")}</Typography>
                        </Space>
                    ) : _.isEqual(status, 4) ? (
                        <Space>
                            <Icon name="status_completed" size="14" />
                            <Typography size={17}>{t("Выплачен")}</Typography>
                        </Space>
                    ) : (
                        <Space>
                            <Icon name="status_process" size="14" />
                            <Typography size={17}>{t("Перевыпущен")}</Typography>
                        </Space>
                    )}
                </Col>
                <Col md={0} xs={12} className="col-mobile-status-payments">
                    {_.isEqual(type, "gift") ? (
                        <Icon name="lightning2" size="14" />
                    ) : _.isEqual(status, 2) ? (
                        <Icon name="mob-active" size="14" />
                    ) : _.isEqual(status, 3) ? (
                        <Icon name="mob-blocked" size="14" />
                    ) : _.isEqual(status, 4) ? (
                        <Icon name="status_completed" size="14" />
                    ) : (
                        <Icon name="mob-process" size="14" />
                    )}
                    {_.isEqual(type, "gift") ? (
                        !_.isNil(action) ? (
                            <Typography as="div" className="bl-nps">
                                <Typography size={17}>{comment}</Typography>{" "}
                                <Typography as="div" size={14} className="nps-description">
                                    <img src={NpsHistory} width={20} alt="" />
                                    {action.description}
                                </Typography>
                            </Typography>
                        ) : (
                            <Typography size={17}>{comment}</Typography>
                        )
                    ) : !_.isNil(action) ? (
                        <Typography as="div" className="bl-nps">
                            <Typography size={17}>{phone}</Typography>{" "}
                            <Typography as="div" size={14} className="nps-description">
                                <img src={NpsHistory} width={20} alt="" />
                                {action.description}
                            </Typography>
                        </Typography>
                    ) : (
                        <Typography size={17}>{phone}</Typography>
                    )}
                </Col>
                <Col md={0} xs={12} className="mobile-loyalty">
                    <Space>
                        {_.isEqual(loyalty?.code, "bronze") ? (
                            <Icon name="bronze-loyalty-mob" size="15" />
                        ) : _.isEqual(loyalty?.code, "silver") ? (
                            <Icon name="silver-loyalty-mob" size="15" />
                        ) : _.isEqual(loyalty?.code, "gold") ? (
                            <Icon name="gold-loyalty-mob" size="15" />
                        ) : _.isEqual(loyalty?.code, "top") ? (
                            <Icon name="top-loyalty-mob" size="15" />
                        ) : null}
                        <Typography size={17} color="rgba(255, 255, 255, 0.5)">
                            {!_.isNil(loyalty) && loyalty.name}
                        </Typography>
                    </Space>
                </Col>
                <Col md={0} xs={24} className="mobile-loyalty-bar">
                    {_.isEqual(type, "phone") && (
                        <Progress
                            className={cn("loaylty-progress-line", {active: status === 2})}
                            percent={getLoyaltyProgress(total_sum, accrued)}
                            showInfo={false}
                        />
                    )}
                </Col>
                <Col md={3} xs={12}>
                    <Typography as="div" className="mobile-payments-sum">
                        <Typography size={24} className="list-total-sum">
                            <NumberFormat
                                thousandSeparator=" "
                                displayType="text"
                                value={sum.toFixed(2)}
                            />
                        </Typography>
                        <Typography
                            className="mobile-text-payments"
                            size={14}
                            color="rgba(255, 255, 255, 0.5)"
                        >
                            {t("Накоплено")}
                        </Typography>
                    </Typography>
                </Col>
                <Col md={3} xs={12}>
                    <Typography as="div" className="mobile-total-sum">
                        <Space className="total-sum-sum-ikon">
                            <Typography size={24} className="total-sum-sum">
                                <NumberFormat
                                    thousandSeparator=" "
                                    displayType="text"
                                    value={accrued.toFixed(2)}
                                />
                            </Typography>
                            <Typography>
                                <Icon name="Polygon5" size="8" />
                            </Typography>
                        </Space>
                        <Typography
                            className="mobile-text-payments"
                            size={14}
                            color="rgba(255, 255, 255, 0.5)"
                        >
                            {t("Начисляется")}
                        </Typography>
                    </Typography>
                </Col>
            </Row>
        </Typography>
    );
}

interface IProps extends IItems {
    isLoyalty: boolean;
}
