import {Dropdown, Icon, Menu, Modal, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {useContext, useState} from "react";
import "./style.scss";
import _ from "lodash";
//components
import SaveFiltersModal from "../../../../../../../modal/saveFilters";
//services
import NumbersFiltersStoreService from "../../../../../../../../services/NumbersFiltersStoreService";
//core
import {LayoutContext} from "../../../../../../../../core/Contexts";
import PhonesFiltersService from "../../../../../../../../services/PhonesFiltersService";
import {observer} from "mobx-react";

export default observer(function DropdownFilter({
    index,
    phonePattern,
    phoneCombs,
    setType,
    setTariff,
    setCategories,
    setRegion,
    setPattern,
    setPhonePattern,
    setPhoneCombs,
    ...props
}: IProps) {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [name, setName] = useState<string>();

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const onRename = () => {
        const value = NumbersFiltersStoreService.items.find((i, k) => _.isEqual(k, index));
        setName(value?.name);
        setIsModalOpen(true);
    };

    const onClick = () => {
        PhonesFiltersService.isRemovedItem = true;
        NumbersFiltersStoreService.remove(index);

        if (!_.size(NumbersFiltersStoreService.items)) {
            setType(["standard"]);
            setTariff([]);
            setCategories([]);
            setRegion([]);
            setPattern([]);
            setPhonePattern([]);
            setPhoneCombs(undefined);
            if (props.setTariffSize) {
                props.setTariffSize(0);
            }
            if (props.setCategoriesSize) {
                props.setCategoriesSize(0);
            }
            if (props.setRegionSize) {
                props.setRegionSize(0);
            }
        } else {
            if (!_.isNil(NumbersFiltersStoreService.current)) {
                const {values} =
                    NumbersFiltersStoreService.items[NumbersFiltersStoreService.current];
                setType(values.type ?? []);
                setTariff(values.tariff ?? []);
                setCategories(values.categories ?? []);
                setRegion(values.region ?? []);
                setPattern(values.pattern ?? []);
                setPhonePattern(values.phone_pattern ?? []);
                setPhoneCombs(values.phone_combs);
                if (props.setTariffSize && !_.isNil(values.tariff)) {
                    props.setTariffSize(values.tariff.length ?? 0);
                }
                if (props.setCategoriesSize && !_.isNil(values.categories)) {
                    props.setCategoriesSize(values.categories.length ?? 0);
                }
                if (props.setRegionSize && !_.isNil(values.region)) {
                    props.setRegionSize(values.region.length ?? 0);
                }
            }
        }
    };

    const menu = (
        <Menu
            items={[
                {
                    key: "1",
                    label: (
                        <Typography onClick={onRename} className="change-name">
                            <Icon name="tool" size="14" />
                            {"Изменить название"}
                        </Typography>
                    )
                },
                {
                    key: "2",
                    label: (
                        <Typography className="delete-filter" onClick={onClick}>
                            <Icon name="trash" size="14" />
                            {"Удалить"}
                        </Typography>
                    )
                }
            ]}
        />
    );
    return (
        <Typography as="div" className="bl-dropdown-filter">
            <Dropdown
                className="bl-dropdown-filter"
                overlay={menu}
                placement="topRight"
                trigger={["click"]}
            >
                {_.lte(contextLayout?.width ?? 0, 768) ? (
                    <Icon name="editor" size="16" />
                ) : (
                    <Icon name="more" size="16" />
                )}
            </Dropdown>
            <Modal open={isModalOpen} onCancel={handleCancel} className="rename-modal">
                <SaveFiltersModal
                    title={t("Изменить название")}
                    index={index}
                    name={name}
                    setIsModalOpen={setIsModalOpen}
                    phonePattern={phonePattern}
                    phoneCombs={phoneCombs}
                />
            </Modal>
        </Typography>
    );
});

interface IProps {
    index: number;
    phonePattern: string[];
    phoneCombs: string | undefined;
    setType: React.Dispatch<React.SetStateAction<string[]>>;
    setTariff: React.Dispatch<React.SetStateAction<string[]>>;
    setCategories: React.Dispatch<React.SetStateAction<string[]>>;
    setRegion: React.Dispatch<React.SetStateAction<string[]>>;
    setPattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhonePattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhoneCombs: React.Dispatch<React.SetStateAction<string | undefined>>;
    setTariffSize?: React.Dispatch<React.SetStateAction<number>>;
    setCategoriesSize?: React.Dispatch<React.SetStateAction<number>>;
    setRegionSize?: React.Dispatch<React.SetStateAction<number>>;
}
