// store
import Store from "../stores";
// core
import {axiosFetchBuilder, IAxiosConfig} from "../core/Axios";
import {IAddress, IAddressesRequest, IAddressesResponse} from "../core/models/Address";

enum Path {
    addresses = "/system/addresses"
}

class AddressesService {
    [Symbol.toStringTag] = this.constructor.name;
    private static readonly addresses = Store.addresses;

    private static async getAddresses(
        request?: IAddressesRequest
    ): Promise<IAddressesResponse> {
        const res: IAddressesResponse = await axiosFetchBuilder({
            url: Path.addresses,
            tag: "update",
            loading: false,
            loadingBtn: false,
            params: {
                ...request
            }
        } as IAxiosConfig);
        return res;
    }

    static async fetchAddresses(request: IAddressesRequest): Promise<IAddress[]> {
        const res: IAddressesResponse = await AddressesService.getAddresses(request);
        AddressesService.addresses.items = res.items;
        return res.items;
    }

}

export default AddressesService;
