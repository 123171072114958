import {Button, Skeleton, Space, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {MobXProviderContext, observer} from "mobx-react";
import {useContext, useEffect, useMemo, useState} from "react";
import "./style.scss";
// imeges
import fnRemove from "../../../../../images/fn-remove.svg";
import mode from "../../../../../images/mode.png";
// core
import {LayoutContext, ModalContext} from "../../../../../core/Contexts";
import NumberFilterMode from "../../../../modal/numberFilterMode";
import PhonesFiltersService from "../../../../../services/PhonesFiltersService";
import Error from "../../../../modal/error";
import PhonesGlobalService from "../../../../../services/PhonesGlobalService";

const FavoriteNumbers: React.FC<IProps> = observer(
    ({
        phone,
        sort,
        isFavorite,
        type,
        tariff,
        categories,
        region,
        pattern,
        phone_pattern,
        phone_combs,
        setType,
        setIsFavorite,
        setTariff,
        setCategories,
        setRegion,
        setPattern,
        setPhonePattern,
        setPhoneCombs
    }) => {
        const {store} = useContext(MobXProviderContext);
        const contextModal = useContext(ModalContext);
        const [pageLoading, setPageLoading] = useState(false);
        const [disabled, setDisabled] = useState(false);
        const [selected, setSelected] = useState<number[]>(
            PhonesFiltersService.phonesFilters.general_number ?? []
        );
        const [numbers] = useState([0, 1, 2, 3, 4, 5, 6, 7, 8, 9]);
        const contextLayout = useContext(LayoutContext);

        const debounceFetcher = useMemo(() => {
            const batchLog = async () => {
                store.pageLoading = true;
                setDisabled(true);
                PhonesGlobalService.fetchAll(
                    {
                        sort,
                        type,
                        tariff,
                        categories,
                        region,
                        pattern,
                        phone_pattern: _.chain(phone_pattern)
                            .reduce<string[]>((res, i) => {
                                (res || (res = [])).push(!_.trim(i) ? "N" : i);
                                return res;
                            }, [])
                            .join("")
                            .valueOf(),
                        phone_combs,
                        general_number: PhonesFiltersService.phonesFilters.general_number
                    },
                    {loading: false}
                )
                    .then(() => {
                        store.pageLoading = false;
                        setDisabled(false);
                    })
                    .catch((e) => {
                        contextModal?.setChildren(<Error />);
                    });
            };

            return _.debounce(batchLog, 700);
        }, [PhonesGlobalService.fetchAll, 700]);

        const onUpdate = async () => {
            debounceFetcher();
        };

        const onSelect = async (number: number) => {
            const updated = [...selected];
            updated.push(number);
            setSelected(updated);
            PhonesFiltersService.phonesFilters.general_number = updated;
            onUpdate();
        };

        const onDeSelect = async (number: number) => {
            const updated = [...selected];
            const newValues = updated.filter((i) => !_.isEqual(i, number));
            setSelected(newValues);
            PhonesFiltersService.phonesFilters.general_number = newValues;
            onUpdate();
        };

        const onReset = async () => {
            if (!disabled) {
                setSelected([]);
                PhonesFiltersService.phonesFilters.general_number = undefined;
                onUpdate();
            }
        };

        const onClick = () => {
            contextModal?.setVisible(true);
            contextModal?.setChildren(
                <NumberFilterMode isFavorite={isFavorite} setIsFavorite={setIsFavorite} />
            );
        };

        useEffect(() => {
            setPageLoading(store.pageLoading);
        }, [store.pageLoading]);

        // useEffect(() => {
        //     onUpdate();
        // }, [PhonesFiltersService.phonesFilters.general_number]);

        return (
            <Typography
                as="div"
                className={`bl-favorite-numbers ${_.gte(_.size(selected), 3) && "all-selected"}${
                    disabled ? " op4" : ""
                }`}
            >
                <Space
                    direction="vertical"
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 16}
                    className="first-space"
                >
                    {!store.numberFilters.isCollection && (
                        <Space
                            direction="vertical"
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 12 : 8}
                        >
                            <Space
                                direction="horizontal"
                                size={_.gte(contextLayout?.width ?? 0, 768) ? 14 : 4}
                                className="numbers"
                            >
                                {_.map(selected, (i, k) => (
                                    <Button
                                        key={i}
                                        type="text"
                                        className={`selected ${
                                            _.gte(_.size(selected), 3) && "full"
                                        }`}
                                        onClick={onDeSelect.bind(null, i)}
                                        disabled={disabled}
                                    >
                                        <div className={`bg index-${k + 1}`}></div>
                                        <Typography as="div" fontFamily="alumna">
                                            {i}
                                        </Typography>
                                    </Button>
                                ))}

                                {_.lt(_.size(selected), 3) &&
                                    _.chain(numbers)
                                        .filter((i) => !_.includes(selected, i))
                                        .map((i) => (
                                            <Button
                                                key={i}
                                                type="text"
                                                className="default"
                                                onClick={onSelect.bind(null, i)}
                                                disabled={disabled}
                                            >
                                                <Typography as="div" fontFamily="alumna">
                                                    {i}
                                                </Typography>
                                            </Button>
                                        ))
                                        .valueOf()}
                                {_.isEqual(_.size(selected), 3) && (
                                    <Typography as="div" className="remove" onClick={onReset}>
                                        <img src={fnRemove} alt="" />
                                    </Typography>
                                )}
                            </Space>
                            <Typography as="div" className="subtext">
                                {_.lte(_.size(selected), 0) ? (
                                    _.gte(contextLayout?.width ?? 0, 768) ? (
                                        "Выберите любимую цифру"
                                    ) : (
                                        <>
                                            <span>Выберите</span>{" "}
                                            <span className="stron">любимую цифру</span>
                                        </>
                                    )
                                ) : _.lte(_.size(selected), 1) ? (
                                    _.gte(contextLayout?.width ?? 0, 768) ? (
                                        "Дополнительная цифра. Можете добавить еще две цифры"
                                    ) : (
                                        <>
                                            <div className="stron">Дополнительная цифра.</div>
                                            <div>Можете добавить еще две цифры</div>
                                        </>
                                    )
                                ) : _.lte(_.size(selected), 2) ? (
                                    _.gte(contextLayout?.width ?? 0, 768) ? (
                                        "Дополнительная цифра. Можете добавить еще одну цифру"
                                    ) : (
                                        <>
                                            <div className="stron">Дополнительная цифра.</div>
                                            <div>Можете добавить еще одну цифру</div>
                                        </>
                                    )
                                ) : _.gte(contextLayout?.width ?? 0, 768) ? (
                                    "Можете удалить цифру"
                                ) : (
                                    <>
                                        <span className="stron">Выбранные цифры.</span>{" "}
                                        <span>Можете удалить цифру</span>
                                    </>
                                )}
                            </Typography>
                        </Space>
                    )}
                    {!!_.gte(contextLayout?.width ?? 0, 768) && (
                        <>
                            {pageLoading ? (
                                <Skeleton.Button active={true} className="btn-skeleton" />
                            ) : (
                                <Button
                                    type="primary"
                                    className="mode"
                                    icon={<img src={mode} alt="" />}
                                    onClick={onClick}
                                >
                                    Режим фильтров
                                </Button>
                            )}
                        </>
                    )}
                </Space>
            </Typography>
        );
    }
);
export default FavoriteNumbers;

interface IProps {
    phone: string | undefined;
    type: string[];
    tariff: string[];
    categories: string[];
    region: string[];
    pattern: string[];
    sort: string;
    phone_pattern: string[];
    phone_combs: string | undefined;
    isFavorite: boolean;
    setType: React.Dispatch<React.SetStateAction<string[]>>;
    setIsFavorite: React.Dispatch<React.SetStateAction<boolean>>;
    setTariff: React.Dispatch<React.SetStateAction<string[]>>;
    setCategories: React.Dispatch<React.SetStateAction<string[]>>;
    setRegion: React.Dispatch<React.SetStateAction<string[]>>;
    setPattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhonePattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhoneCombs: React.Dispatch<React.SetStateAction<string | undefined>>;
}
