import {makeAutoObservable} from "mobx";
// core
import {IAddress} from "../core/models/Address";
// core

class Addresses {
    [Symbol.toStringTag] = this.constructor.name;
    private _items!: IAddress[] | undefined;

    constructor() {
        makeAutoObservable(this);
    }

    get items() {
        return this._items;
    }

    set items(value: IAddress[] | undefined) {
        this._items = value;
    }
}

export default Addresses;
