import {useContext} from "react";
import {Space, Typography, Icon, Button} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {useNavigate} from "react-router-dom";
import Clipboard from "react-clipboard.js";
import {notification, Tooltip} from "antd";
import {isAndroid} from "react-device-detect";
import _ from "lodash";
import "./style.scss";
// core
import {LayoutContext, ModalContext} from "../../../../../../core/Contexts";
// components
import RemoveBookingModal from "../../../../../modal/removeBooking";
import {IPassport, IReservationsRequest} from "../../../../../../core/models/Reservations";
import {observer} from "mobx-react";
import ReservationsService from "../../../../../../services/ReservationsService";
import simPng from "../../../../../../images/sim3.png";

export default observer(function Actions({
    id,
    phone,
    tariffName,
    minutes,
    internet,
    tariffId,
    sms,
    regionName,
    passport,
    onSim,
    updateList
}: IProps) {
    const contextModal = useContext(ModalContext);
    const contextLayout = useContext(LayoutContext);
    const navigate = useNavigate();
    const {t} = useTranslation();

    const onEdit = () => {
        navigate(`/reservation/${tariffId}/${phone}`);
    };

    const onRemove = () => {
        contextModal?.setChildren(
            <RemoveBookingModal id={id} phone={phone} updateList={updateList} />
        );
        contextModal?.setVisible(true);
    };

    const onSuccess = () => {
        notification.success({
            message: "",
            placement: "bottomRight",
            duration: 3,
            description: t("Данные о номере {phone} скопированы в буфер обмена", {phone})
        });
    };

    return (
        <Typography
            as="div"
            className={`bl-reservation-list-info-actions${isAndroid ? " is-android" : ""}`}
        >
            <Space direction="horizontal" size={_.gte(contextLayout?.width ?? 0, 992) ? 22 : 18}>
                <Typography
                    as="div"
                    className="bl-reservation-list-info-actions-tooltip-container"
                    id={`tooltip-container-${id}`}
                >
                    <Tooltip
                        getPopupContainer={() => {
                            return document.getElementById(`tooltip-container-${id}`)!;
                        }}
                        overlayClassName="toltip-reservation"
                        title={
                            passport
                                ? t("Паспортные данные заполнены")
                                : t("Паспортные данные не заполнены")
                        }
                    >
                        {passport ? (
                            <Icon name="passport" size="24" />
                        ) : (
                            <Icon name="Fall" size="24" />
                        )}
                    </Tooltip>
                </Typography>
                {_.gte(contextLayout?.width ?? 0, 992) &&
                    !_.isEqual(ReservationsService.gridType, "kanban") && (
                        <Typography
                            as="div"
                            className="bl-reservation-list-info-actions-tooltip-container-copy"
                            id={`tooltip-container-${id}`}
                        >
                            <Tooltip
                                title="Скопировать в буфер обмена"
                                overlayClassName="toltip-reservation-hover"
                                getPopupContainer={() => {
                                    return document.getElementById(`tooltip-container-${id}`)!;
                                }}
                            >
                                <Button
                                    className="copy"
                                    type="text"
                                    icon={<Icon name="copy" size="24" />}
                                >
                                    <Clipboard
                                        component="span"
                                        data-clipboard-text={`${phone}\n${t(
                                            "Тариф"
                                        )}: ${tariffName}\n${t("Регион")}: ${regionName}\n${
                                            minutes && `${t("Минуты")}: ${minutes}`
                                        }\n${sms && `${t("СМС")}: ${sms}`}\n${
                                            internet && `${t("Интернет")}: ${internet} ${t("Гб")}`
                                        }`}
                                        onSuccess={onSuccess}
                                    ></Clipboard>
                                </Button>{" "}
                            </Tooltip>
                        </Typography>
                    )}
                {_.lt(contextLayout?.width ?? 0, 992) ||
                _.isEqual(ReservationsService.gridType, "kanban") ? (
                    <Typography
                        as="div"
                        className="bl-reservation-list-info-actions-tooltip-container-hover"
                        id={`tooltip-container-${id}`}
                    >
                        <Tooltip
                            title="Скопировать в буфер обмена"
                            overlayClassName="toltip-reservation-hover"
                            getPopupContainer={() => {
                                return document.getElementById(`tooltip-container-${id}`)!;
                            }}
                        >
                            <Button
                                type="text"
                                icon={<img src={simPng} alt="" className="simpng" />}
                                onClick={onSim}
                            />
                        </Tooltip>
                    </Typography>
                ) : null}
                <Typography
                    as="div"
                    className="bl-reservation-list-info-actions-tooltip-container-hover"
                    id={`tooltip-container-${id}`}
                >
                    <Tooltip
                        title="Редактировать бронь"
                        overlayClassName="toltip-reservation-hover"
                        getPopupContainer={() => {
                            return document.getElementById(`tooltip-container-${id}`)!;
                        }}
                    >
                        <Button
                            type="text"
                            icon={<Icon name="edit2" size="24" />}
                            onClick={onEdit}
                        />
                    </Tooltip>
                </Typography>
                <Typography
                    as="div"
                    className="bl-reservation-list-info-actions-tooltip-container-delete"
                    id={`tooltip-container-${id}`}
                >
                    <Tooltip
                        title="Отменить бронь"
                        overlayClassName="toltip-reservation-hover"
                        getPopupContainer={() => {
                            return document.getElementById(`tooltip-container-${id}`)!;
                        }}
                    >
                        <Button
                            type="text"
                            icon={<Icon name="remove" size="24" />}
                            onClick={onRemove}
                        />
                    </Tooltip>
                </Typography>
            </Space>
        </Typography>
    );
});

interface IProps {
    id: number;
    phone: number;
    tariffName: string;
    minutes: number;
    tariffId: number;
    internet: number;
    sms: number;
    regionName: string;
    passport: IPassport;
    onSim: () => void;
    updateList: (request?: IReservationsRequest, loading?: boolean, reset?: boolean) => void;
}
