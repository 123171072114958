import {Col, Row, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import {useTranslation} from "react-i18next";

export default function PayoutsTitle() {
    const {t} = useTranslation();
    return (
        <Typography as="div" className="bl-title-payouts">
            <Row>
                <Col span={6}>
                    <Typography size={17} color="rgba(255, 255, 255, 0.5)">
                        {t("Дата")}
                    </Typography>
                </Col>
                <Col span={6}>
                    <Typography size={17} color="rgba(255, 255, 255, 0.5)">
                        {t("Операций")}
                    </Typography>
                </Col>
                <Col span={4}></Col>
                <Col span={8}>
                    <Typography
                        size={17}
                        color="rgba(255, 255, 255, 0.5)"
                        className="sub-title-accrued"
                    >
                        {t("Списано")}
                    </Typography>
                </Col>
            </Row>
        </Typography>
    );
}
