import _ from "lodash";
import moment from "moment";

export default function date(str: string, options?: Intl.DateTimeFormatOptions) {
    if (moment(new Date(str), "YYYY-MM-DD HH:mm:ss", true).isValid()) {
        return new Intl.DateTimeFormat(localStorage.getItem("locale") || "ru-RU", options).format(
            new Date(_.replace(str, /\s/g, "T"))
        );
    }
    return "";
}
