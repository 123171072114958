import {Form, Space, Typography} from "@bezlimit/bezlimit-ui";
import {FormInstance, FormItemProps, Input} from "antd";
import {useEffect, useRef, useState} from "react";
import _, {isNil} from "lodash";
import "./style.scss";
import PhonesFiltersService from "../../../services/PhonesFiltersService";
import {observer} from "mobx-react";
import NumbersFiltersStoreService from "../../../services/NumbersFiltersStoreService";

export default observer(function SmartField({
    formInstance,
    props,
    phoneCombs,
    setPhoneCombs,
    currentCheckbox,
    setShouldUpdate
}: IProps) {
    const ref = useRef(null);
    const [partition, setPartition] = useState(["", "", ""]);

    const width = (values: any[], width: number) => {
        _.forEach(values, (i) => {
            if (ref.current && i) {
                const els = document.body.querySelectorAll(`.input_${i.key}`);

                if (els) {
                    _.forEach(els, (value: any) => {
                        const el = value.closest(".ant-space-item");
                        el.style.width = `${width}%`;
                        el.style.display = "block";
                        el.style.position = "static";
                        el.style.opacity = "1";

                        if (!width) {
                            el.style.opacity = "0";
                            setTimeout(() => {
                                el.style.position = "absolute";
                            }, 350);
                        }
                    });
                }
            } else {
                const els = document.body.querySelectorAll(".bl-smart-field .ant-space-item");
                _.forEach(els, (el: any, k) => {
                    el.style.width = `${_.isEqual(k, 0) ? 50 : 25}%`;
                    el.style.display = "block";
                    el.style.position = "static";
                    el.style.opacity = "1";
                });
            }
        });
    };

    const resize = (updated: string[]) => {
        const fields = formInstance.getFieldsValue();

        const grouped = _.values(
            _.chain(
                _.chain(updated)
                    .reduce<any>((res, i, k) => {
                        (res || (res = [])).push({key: k, value: i, length: i.length});
                        return res;
                    }, [])
                    .valueOf()
            )
                .groupBy(({length}) => length)
                .valueOf()
        );

        if (!_.size(_.replace(fields["phone_combs"], /,/gi, ""))) {
            width([grouped[0][0]], 50);
            width([grouped[0][1]], 25);
            width([grouped[0][2]], 25);
        } else if (_.isEqual(_.size(grouped), 3)) {
            width(grouped[0], 25);
            width(grouped[1], 25);
            width(grouped[2], 50);
        } else if (_.isEqual(_.size(grouped), 2) && _.isEqual(_.size(grouped[0]), 2)) {
            width(grouped[0], 25);
            width(grouped[1], 50);
        } else if (_.isEqual(_.size(grouped), 2) && _.isEqual(_.size(grouped[1]), 2)) {
            width(grouped[0], 25);
            width(grouped[1], 37.5);
        } else {
            width(grouped[0], 33.33333333);
            width(grouped[1], 33.33333333);
            width(grouped[2], 33.33333333);
        }

        if (_.isEqual(_.size(_.replace(fields["phone_combs"], /,/gi, "")), 10)) {
            const length = _.chain(grouped[0])
                .filter(({length}) => !length)
                .size()
                .valueOf();

            if (length && _.isEqual(length, 2) && _.isEqual(_.size(grouped[1]), 1)) {
                width(grouped[0], 0);
                width(grouped[1], 100);
            } else if (length) {
                const isFull = Boolean(grouped[1] && grouped[2]);
                width(grouped[0], 0);

                if (isFull) {
                    width(grouped[1], 25);
                    width(grouped[2], 75);
                } else {
                    width(grouped[1], 50);
                    width(grouped[2], 50);
                }
            }
        }
    };

    const onKeyUp = (e: any, key: number) => {
        const updated = _.clone(partition);
        let value = _.replace(e.target.value.toString(), /(\+7|\D)/gm, "");
        updated[key] = value;

        if (_.lte(_.size(_.join(updated, "")), 10)) {
            updated[key] = value;
        } else {
            const size = _.size(_.join(updated, ""));
            const value1 = _.slice(value, 0, 10 - size).join("");
            updated[key] = value1;
        }
        const fields = formInstance.getFieldsValue();
        fields["phone_combs"] = _.join(updated, ",");
        formInstance.setFieldsValue(fields);
        setPhoneCombs(fields["phone_combs"]);
        setPartition(updated);

        let values = _.clone(PhonesFiltersService.currentFilter);
        values.phone_combs = fields["phone_combs"];
        PhonesFiltersService.setCurrentFilter(values);

        if (_.isNil(currentCheckbox) && !isNil(setShouldUpdate)) {
            if (
                _.isNil(NumbersFiltersStoreService.current) &&
                !!PhonesFiltersService.currentFilter.phone_combs?.replace(/,/gi, "")
            ) {
                setShouldUpdate(true);
            } else {
                setShouldUpdate(false);
            }
        }
    };

    const onKeyDown = (e: any) => {
        if (
            (_.inRange(e.keyCode, 48, 58) && !e.shiftKey) ||
            _.isEqual(e.keyCode, 8) ||
            (_.inRange(e.keyCode, 96, 106) && !e.shiftKey) ||
            (_.isEqual(e.keyCode, 86) && e.ctrlKey) ||
            (_.isEqual(e.keyCode, 86) && e.metaKey)
        ) {
        } else {
            e.preventDefault();
        }
    };

    const onPaste = (e: any, key: number) => {
        e.preventDefault();

        const updated = _.clone(partition);
        let value = _.replace(e.clipboardData.getData("text/plain"), /(\+7|^8|\D)/gm, "");
        const size = _.size(_.join(updated, ""));

        if (_.lt(size + _.size(value), 10)) {
            e.target.value = value;
        } else {
            e.target.value = _.slice(value, 0, 10 - size + e.target.value.length).join("");
        }
        onKeyUp(e, key);
    };

    useEffect(() => {
        const partition = _.split(phoneCombs ? phoneCombs : ",,", ",");
        setPartition(partition);
        const fields = formInstance.getFieldsValue();
        fields["phone_combs"] = _.join(partition, ",");
        formInstance.setFieldsValue(fields);
        resize(partition);
    }, [phoneCombs]);

    return (
        <div ref={ref}>
            <Typography as="div" className="bl-smart-field">
                <Space direction="horizontal" size={10} className="inputs">
                    <Input
                        className="input_0"
                        onChange={(value) => onKeyUp(value, 0)}
                        onKeyDown={onKeyDown}
                        onPaste={(value) => onPaste(value, 0)}
                        value={partition[0]}
                    />
                    <Input
                        className="input_1"
                        onChange={(value) => onKeyUp(value, 1)}
                        onKeyDown={onKeyDown}
                        onPaste={(value) => onPaste(value, 1)}
                        value={partition[1]}
                    />
                    <Input
                        className="input_2"
                        onChange={(value) => onKeyUp(value, 2)}
                        onKeyDown={onKeyDown}
                        onPaste={(value) => onPaste(value, 2)}
                        value={partition[2]}
                    />
                </Space>
                <Form.Item {...props} hidden className="field-phone_combs" name="phone_combs">
                    <Input autoComplete="new-phone_combs" />
                </Form.Item>
            </Typography>
        </div>
    );
});

interface IProps {
    formInstance: FormInstance<any>;
    props?: FormItemProps<any>;
    phoneCombs: string | undefined;
    setPhoneCombs: React.Dispatch<React.SetStateAction<string | undefined>>;
    currentCheckbox?: number | undefined;
    setShouldUpdate?: React.Dispatch<React.SetStateAction<boolean>>;
}
