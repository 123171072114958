import {Col, Row, Space, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {useContext} from "react";
import "./style.scss";
import {LayoutContext} from "../../../../../core/Contexts";
import infinity_ph from "../../../../../images/infinity_phone.png";

export default function InfoCommon({packet_minutes, packet_sms, packet_internet}: Props) {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);

    const infinityLogo =
        _.gte(contextLayout?.width, 767) ?? 0 ? (
            <img src={infinity_ph} width={100} height={45} alt="logo" />
        ) : (
            <img src={infinity_ph} width={35} height={16} alt="logo" />
        );

    return (
        <Typography as="div" className="bl-info-common">
            <Row className="bl-info-common-row" gutter={[20, 20]}>
                <Col xs={24} md={24}>
                    <Typography className="info-common-rest">{t("На все остальное")}</Typography>
                </Col>
                <Space
                    className="bl-info-common-wrapper"
                    direction="vertical"
                    size={_.gte(contextLayout?.width, 360) ? 20 : 5}
                >
                    <Col>
                        <Space direction="vertical" size={0}>
                            <Typography className="info-packet-subtitle">Интернет</Typography>
                            {_.isEqual(packet_internet, 0) ? (
                                infinityLogo
                            ) : (
                                <Typography className="info-packet-value gold">
                                    {packet_internet}
                                    <Typography as={"span"} className="info-packet-sub gold">
                                        {t(" Гб")}
                                    </Typography>
                                </Typography>
                            )}
                        </Space>
                    </Col>
                    <Col>
                        <Space direction="vertical" size={0}>
                            <Typography className="info-packet-subtitle">
                                {t("Разговоры")}
                            </Typography>
                            {_.isEqual(packet_minutes, 0) ? (
                                infinityLogo
                            ) : (
                                <Typography className="info-packet-value gold">
                                    {packet_minutes}
                                    <Typography as={"span"} className="info-packet-sub gold">
                                        {t(" мин")}
                                    </Typography>
                                </Typography>
                            )}
                        </Space>
                    </Col>
                    <Col>
                        <Space direction="vertical" size={0}>
                            <Typography className="info-packet-subtitle">
                                {t("Сообщения")}
                            </Typography>
                            {_.isEqual(packet_sms, 0) ? (
                                infinityLogo
                            ) : (
                                <Typography className="info-packet-value gold">
                                    {packet_sms}
                                    <Typography as={"span"} className="info-packet-sub gold">
                                        {t(" sms")}
                                    </Typography>
                                </Typography>
                            )}
                        </Space>
                    </Col>
                </Space>
            </Row>
        </Typography>
    );
}

interface IProps {
    packet_minutes: number;
    packet_sms: number;
    packet_internet: number;
}

type Props = IProps;
