import React from "react";
import {Typography, Breadcrumb, Icon} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import _ from "lodash";
import "./style.scss";

const defaultOptions = {
    size: 14,
    color: "rgba(255, 255, 255, 0.5)"
};

export default React.memo(function Limits({minutes, internet, sms}: IProps) {
    const {t} = useTranslation();

    return (
        <Breadcrumb separator={<Icon name="dot" size="5" />} className="bl-limits">
            {_.gte(minutes, 0) && (
                <Breadcrumb.Item>
                    <Typography as="span" className="nowrap" {...defaultOptions}>
                        {_.isEqual(minutes, 0) ? t("Безлимит") : `${minutes} ${t("мин.")}`}
                    </Typography>
                </Breadcrumb.Item>
            )}
            {_.gte(internet, 0) && (
                <Breadcrumb.Item>
                    <Typography as="span" className="nowrap" {...defaultOptions}>
                        {_.isEqual(internet, 0) ? t("Безлимит") : `${internet} ${t("ГБ")}`}
                    </Typography>
                </Breadcrumb.Item>
            )}
            {_.gte(sms, 0) && (
                <Breadcrumb.Item>
                    <Typography as="span" className="nowrap" {...defaultOptions}>
                        {_.isEqual(sms, 0) ? t("Безлимит") : `${sms} ${t("смс")}`}
                    </Typography>
                </Breadcrumb.Item>
            )}
        </Breadcrumb>
    );
});

interface IProps {
    minutes: number;
    internet: number;
    sms: number;
}
