import React, {useContext} from "react";
import {Space, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {MobXProviderContext, observer} from "mobx-react";
import "./style.scss";
// components
import NumbersFilterMobileForm from "../../forms/numbersFilterMobile";
// services
import PhoneService from "../../../services/PhoneService";

export default React.memo(
    observer(function NumbersFiltersMobileModal(props: IProps) {
        const {t} = useTranslation();
        const {store} = useContext(MobXProviderContext);

        return (
            <Typography as="div" className="bl-numbers-filters-mobile-modal">
                <Space direction="vertical" size={24} align="center">
                    {!store.pageLoading && (
                        <Typography level={2} color="#000" size={17} className="title">
                            {t("Фильтры")}
                        </Typography>
                    )}

                    <NumbersFilterMobileForm {...props} />
                </Space>
            </Typography>
        );
    })
);

interface IProps {
    phone: string | undefined;
    type: string[];
    tariff: string[];
    categories: string[];
    region: string[];
    pattern: string[];
    phonePattern: string[];
    phoneCombs: string | undefined;
    sort: string;
    Service: PhoneService;
    isFavorite: boolean;
    setIsFavorite: React.Dispatch<React.SetStateAction<boolean>>;
    setType: React.Dispatch<React.SetStateAction<string[]>>;
    setTariff: React.Dispatch<React.SetStateAction<string[]>>;
    setCategories: React.Dispatch<React.SetStateAction<string[]>>;
    setRegion: React.Dispatch<React.SetStateAction<string[]>>;
    setPattern: React.Dispatch<React.SetStateAction<string[]>>;
    setAllIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
    setPhone: React.Dispatch<React.SetStateAction<string | undefined>>;
    setPhonePattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhoneCombs: React.Dispatch<React.SetStateAction<string | undefined>>;
}
