import React from "react";
import {Typography, Row, Col} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import "./style.scss";
import errorImg from "../../../../images/error@2x_2.jpg";

export default React.memo(
    observer(function ClipBoardErrorModal() {
        const {t} = useTranslation();

        return (
            <Typography as="div" className="bl-clipboard-error-modal">
                <Row gutter={[24, 24]}>
                    <Col span={24}>
                        <img className="social-icon" src={errorImg} width={97} alt="logo" />
                    </Col>
                    <Col xs={24}>
                        <Typography as="div" color="#000" size={16}>
                            {t(
                                " Скопируйте полный серийный номер или последние 11 цифр для вставки из буфера обмена!"
                            )}
                        </Typography>
                    </Col>
                </Row>
            </Typography>
        );
    })
);
