import {Block, Button, Space, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import _ from "lodash";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import {useContext} from "react";
// components
import GroupDeliveryColapse from "./groupDeliveryColapse";
import TitleDeliveries from "./titleDeliveries";
import ButtonChatSupport from "./buttonChatSupport";
// servise
import DeliveryService from "../../../services/DeliveryService";
// images
import ErrorImg from "../../images/errorImg";
import {InterceptorContext} from "../../../core/Contexts";

export default observer(function Delivery({activeKey, onTabClick}: IProps) {
    const {t} = useTranslation();
    const context = useContext(InterceptorContext);

    const onClick = () => {
        onTabClick("deliveries/new");
    };

    return (
        <>
            {_.isEqual(DeliveryService.itemsSize, 0) ? (
                <Block className="bl-default-delivery">
                    <Typography as="div" className="text">
                        <Space direction="vertical" size={8}>
                            <Typography as="div" className="images">
                                <ErrorImg />
                            </Typography>
                            {!_.isUndefined(context?.data?.message) ? (
                                <>
                                    <Typography className="title-default">
                                        {t(`Раздел "Доставка" временно недоступен.`)}
                                    </Typography>
                                    <Typography className="sub-title-default">
                                        {t(
                                            "Попробуйте зайти позднее для просмотра и оформления заказов."
                                        )}
                                    </Typography>
                                </>
                            ) : (
                                <>
                                    <Typography className="title-default">
                                        {t("У вас пока нет активных заказов")}
                                    </Typography>
                                    <Typography className="sub-title-default">
                                        {t("Когда вы оформите доставку, здесь появится ваш заказ")}
                                    </Typography>
                                </>
                            )}
                        </Space>
                    </Typography>
                    <Typography as="div" className="btn-default">
                        <Button type="default" onClick={onClick}>
                            {t("Новая доставка")}
                        </Button>
                    </Typography>
                </Block>
            ) : (
                <>
                    <Block className="bl-my-deliveries">
                        <TitleDeliveries />
                        <GroupDeliveryColapse activeKey={activeKey} onTabClick={onTabClick} />
                    </Block>
                    <ButtonChatSupport />
                </>
            )}
        </>
    );
});

interface IProps {
    activeKey: string;
    onTabClick: (key: string, recipient?: any) => void;
}
