import {Form, Input, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {observer} from "mobx-react";
import {useContext, useEffect, useState} from "react";
import {useTranslation} from "react-i18next";
import {InterceptorContext} from "../../../core/Contexts";
import {isPatronymic} from "../../forms/registerStore";

// rules
import {Rules} from "../../forms/rules";

export default observer(function FirstAndLastNameField({
    className,
    name,
    titleText,
    ...props
}: any) {
    const {t} = useTranslation();
    const context = useContext(InterceptorContext);
    const [firstAndLastName, setFirstAndLastName] = useState<string>();

    const errorContext = _.chain(
        _.isArray(context?.data)
            ? context?.data
            : context?.data?.message
            ? [{message: context?.data?.message, field: context?.data?.message}]
            : []
    )
        .map(({field, message}) => (field === name ? message : undefined))
        .join(" ")
        .valueOf();

    const [error, seterror] = useState(errorContext);

    useEffect(() => {
        seterror(errorContext);
    }, [errorContext]);

    return (
        <>
            <Form.Item
                {...props}
                hasFeedback
                name={name}
                normalize={(value: string) => {
                    const letters = value.slice(0, 2);
                    return letters.replace(/[^a-zа-яё]/gim, "") + value.slice(2);
                }}
                rules={[
                    name !== "patronymic" && Rules.required,
                    name !== "patronymic" && Rules.minFirstAndLastName(2, name),
                    name === "patronymic" && !isPatronymic.isPatronymic && Rules.required,
                    name === "patronymic" &&
                        !isPatronymic.isPatronymic &&
                        Rules.minFirstAndLastName(2, name),
                    Rules.max(25),
                    ({getFieldValue}) => ({
                        validator(_, value) {
                            const sum = value;
                            if (sum.replace(/[^a-zа-я\s-'ё]/gi, "") !== sum) {
                                return Promise.reject(
                                    new Error(
                                        t(
                                            "Допускаются русские и латинские буквы, дефис, одинарная кавычка и пробел."
                                        )
                                    )
                                );
                            }
                            return Promise.resolve();
                        }
                    })
                ]}
            >
                <Input
                    disabled={name === "patronymic" && isPatronymic.isPatronymic ? true : false}
                    value={firstAndLastName}
                    onChange={(e) => {
                        setFirstAndLastName(e.target.value);
                        seterror("");
                    }}
                    title={titleText}
                    autoComplete="new-email"
                />
            </Form.Item>
            <Typography className={"field-email-errorTypography"}>{error}</Typography>
        </>
    );
});
