import React, {useEffect} from "react";
import {Typography, Space, Button, Form} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import "./style.scss";
// core
import Store from "../../../stores";
import useFormHandler from "../../../core/hooks/useFormHandler";
import useOnCancel from "../../../core/hooks/useOnCancel";
import {observer} from "mobx-react";
import WidgetChartra from "../../../core/functions/widgetChartra";
import UserService from "../../../services/UserService";

export default React.memo(
    observer(function PurchaseOfPaidNumbers({phone, priceParams}: IProps) {
        const {t} = useTranslation();

        const [form] = Form.useForm();
        const formInstance = useFormHandler(form);
        const onCancel = useOnCancel(formInstance);

        useEffect(() => {
            WidgetChartra(document, window, "Chatra", Store.user);
        }, []);

        const onClickSupport = () => {
            onCancel();
            const chatra = (window as any).Chatra;
            chatra("show");
            chatra("openChat", true);
        };

        const personalManagerPhone = UserService.hasPersonalManager
            ? UserService.personalManager.phone
            : "";
        const onClickWhatsApp = () => {
            onCancel();
            window.open(
                `https://wa.me/7${personalManagerPhone}?text=Здравствуйте! Хочу купить платный номер ${phone} за ${priceParams} ₽`
            );
        };

        return (
            <Typography as="div" className="bl-purchaseOfPaidNumbers">
                <Space size={16} direction="vertical" align="center">
                    <Typography
                        level={2}
                        color="#000000"
                        className="bl-purchaseOfPaidNumbers-title"
                    >
                        {t("Хочу купить")}
                    </Typography>

                    <Typography size={14} color="#000000" fontFamily="hauss" className="subtitle">
                        {t("Для покупки номера ")}
                        <Typography as="span" color="#642878">
                            {phone}
                        </Typography>
                        {t(" обратитесь к персональному менеджеру")}
                    </Typography>
                    {Store.user?.level?.level === 0 ? (
                        <Button type={"primary"} onClick={onClickSupport}>
                            {t("Написать в поддержку")}
                        </Button>
                    ) : (
                        <Button type={"primary"} onClick={onClickWhatsApp}>
                            {t("Написать в WhatsApp")}
                        </Button>
                    )}
                    <Typography as="div" className="text-center cancel" size={14} color="#000">
                        <Button type="text" onClick={onCancel}>
                            {t("Закрыть")}
                        </Button>
                    </Typography>
                </Space>
            </Typography>
        );
    })
);

interface IProps {
    phone?: number;
    priceParams?: number;
}
