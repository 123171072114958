import "./style.scss";
import {Button, Typography} from "@bezlimit/bezlimit-ui";
import {LeftOutlined, RightOutlined} from "@ant-design/icons";

export const ScrollerButtons: React.FC<IProps> = ({
    itemSelected,
    setItemSelected,
    total,
    collapseClose
}) => {
    let scrolledRow: HTMLDivElement | null = document.querySelector(".scrolled-row");

    const handlClickLeftButton = (scrolledRow: HTMLDivElement) => {
        if (itemSelected === 0) {
            return;
        }
        scrolledRow.scrollLeft -= 340;
        const newItemSelected = itemSelected - 1;
        setItemSelected(newItemSelected);
    };

    const handlClickRightButton = (scrolledRow: HTMLDivElement) => {
        const last = total - 1;
        if (itemSelected === last) {
            return;
        }
        scrolledRow.scrollLeft += 340;
        const newItemSelected = itemSelected + 1;
        setItemSelected(newItemSelected);
    };

    return (
        <div className="bl-scroller-buttons">
            <div id="left-button">
                <Button
                    type="text"
                    icon={<LeftOutlined />}
                    onClick={() => {
                        scrolledRow && handlClickLeftButton(scrolledRow);
                        collapseClose();
                    }}
                    disabled={itemSelected === 0}
                />
            </div>
            <Typography className="current-item gold">{`${itemSelected + 1}/`}</Typography>
            <Typography className="total gold">{total}</Typography>
            <div id="right-button">
                <Button
                    type="text"
                    icon={<RightOutlined />}
                    onClick={() => {
                        scrolledRow && handlClickRightButton(scrolledRow);
                        collapseClose();
                    }}
                    disabled={itemSelected === total - 1}
                />
            </div>
        </div>
    );
};

interface IProps {
    itemSelected: number;
    setItemSelected: React.Dispatch<React.SetStateAction<number>>;
    total: number;
    collapseClose: () => void;
}
