import {makeAutoObservable} from "mobx";
import {ILotteryPhone, IMeta, ILotteryPhonesResponse} from "../core/models/Lottery";

class LotteryPhones implements ILotteryPhonesResponse {
    [Symbol.toStringTag] = this.constructor.name;
    private _items!: ILotteryPhone[];
    private _meta!: IMeta;

    constructor() {
        makeAutoObservable(this);
    }

    get items() {
        return this._items;
    }

    set items(value: ILotteryPhone[]) {
        this._items = value;
    }

    get meta() {
        return this._meta;
    }

    set meta(value: IMeta) {
        this._meta = value;
    }
}

export default LotteryPhones;
