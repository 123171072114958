import {Typography} from "@bezlimit/bezlimit-ui";
import React from "react";
import {useTranslation} from "react-i18next";

export default React.memo(function Title({children}: IProps) {
    const {t} = useTranslation();

    return <Typography size={24}>{t(children)}</Typography>;
});

interface IProps {
    children: string;
}
