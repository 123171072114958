import React, {useState} from "react";
import {Checkbox, Col, Form, Row, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
// ui
import Grid from "../../../ui/grid";
// core
import {useTranslation} from "react-i18next";
import _ from "lodash";
import {IReservationsRequest} from "../../../../../core/models/Reservations";

const CheckboxGroup = Checkbox.Group;

export default React.memo(function Header({gridType, setGridType, updateList, setShimmer}: IProps) {
    const {t} = useTranslation();
    const [value, setValue] = useState(gridType);
    const [form] = Form.useForm();

    const onValuesChange = (changedValues: any, {filter}: any) => {
        updateList({...{}, ...(_.size(filter) ? {type: _.join(filter, ",")} : {})}, false, true);
        setShimmer(true);
    };

    return (
        <Typography as="div" className="bl-reservation-list-header">
            <Row gutter={[16, 16]}>
                <Col xs={24} lg={12}>
                    <Form
                        form={form}
                        layout="horizontal"
                        onValuesChange={onValuesChange}
                        autoComplete="off"
                    >
                        <Typography as="div" className="filter-container">
                            <Form.Item name="filter">
                                <CheckboxGroup>
                                    <Checkbox value="nps">{t("С акцией НПС")}</Checkbox>
                                    <Checkbox value="free">{t("Обычные")}</Checkbox>
                                    <Checkbox value="super_link">
                                        {t("Брони по суперссылке")}
                                    </Checkbox>
                                    <Checkbox value="bonus">{t("Платные")}</Checkbox>
                                    <Checkbox />
                                </CheckboxGroup>
                            </Form.Item>
                        </Typography>
                    </Form>
                </Col>
                <Col xs={0} lg={12}>
                    <Grid
                        value={value}
                        onChange={(value: string | number) => {
                            setGridType(value.toString());
                            setValue(value.toString());
                        }}
                    />
                </Col>
            </Row>
        </Typography>
    );
});

interface IProps {
    gridType: string;
    setGridType: React.Dispatch<React.SetStateAction<string>>;
    updateList: (request?: IReservationsRequest, loading?: boolean, reset?: boolean) => void;
    setShimmer: React.Dispatch<React.SetStateAction<boolean>>;
}
