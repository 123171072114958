import React from "react";
import {Col, Row, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
// core
import {IReservations} from "../../../../../core/models/Reservations";
// components
import Item from "./item";

export default React.memo(function Items({items}: IProps) {
    return (
        <Typography as="div" className="bl-activation-list-items">
            <Row gutter={[16, 16]}>
                {_.chain(items)
                    .map((i, k) => (
                        <Col key={k} span={24}>
                            <Item {...i} />
                        </Col>
                    ))
                    .valueOf()}
            </Row>
        </Typography>
    );
});

interface IProps {
    items: IReservations[];
}
