import {useCallback, useEffect, useState} from "react";
import _ from "lodash";
import moment from "moment";
// core
import {IResponse} from "../Response";

export default function useCodeConfirmation(onTrigger: () => Promise<IResponse<any>>) {
    const [loading, setLoading] = useState(false);
    const [start, setStart] = useState(new Date().getTime());
    const [sendingRepeatedNotify] = useState(300);
    const [end, setEnd] = useState(new Date().getTime() + sendingRepeatedNotify * 1000);
    const [currentTimestamp, setCurrentTimestamp] = useState(start);
    const [duration, setDuration] = useState(moment.duration(moment(end).diff(currentTimestamp)));

    const onReset = useCallback((sendingRepeatedNotify: number) => {
        const start = new Date().getTime();
        const end = start + sendingRepeatedNotify * 1000;
        setStart(start);
        setEnd(end);
        setCurrentTimestamp(start);
        setDuration(moment.duration(moment(end).diff(start)));
    }, []);

    useEffect(() => {
        if (duration.asMilliseconds() > 0) {
            if (
                !_.isEqual(
                    duration.asMilliseconds,
                    moment.duration(moment(end).diff(currentTimestamp)).asMilliseconds()
                )
            ) {
                setLoading(false);
            }
            const timeout = setTimeout(() => {
                setCurrentTimestamp(currentTimestamp + 1000);
                setDuration(moment.duration(moment(end).diff(currentTimestamp)));
            }, 1000);

            return () => {
                clearTimeout(timeout);
            };
        }
    }, [currentTimestamp, end, duration]);

    useEffect(() => {
        if (duration.asMilliseconds() <= 0 && sendingRepeatedNotify && loading) {
            onReset(sendingRepeatedNotify);
        }
    }, []);

    const onClick = () => {
        setLoading(true);
        onTrigger()
            .then((res: any) => {
                onReset(res.timeout);
            })
            .finally(() => {
                setLoading(false);
            });
    };

    return {duration, loading, setLoading, onClick};
}
