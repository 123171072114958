import React, {ReactFragment, ReactNode, useContext, useEffect, useState} from "react";
import {isMobile} from "react-device-detect";
import {useTranslation} from "react-i18next";
import {Button, Form, Modal, Typography} from "@bezlimit/bezlimit-ui";
import NumberFormat from "react-number-format";
import "./style.scss";
//images
import SuccessImg from "../../../images/successImg";
import ErrorImg from "../../../images/errorImg";
import ReplenishmentOfBalanceBonuses from "../../../modal/replenishmentOfBalanceBonuses";
import {ModalContext} from "../../../../core/Contexts";
import _ from "lodash";
import AutopaymentDefaultCreate from "./autopaymentDefaultCreate";
import useFormHandler from "../../../../core/hooks/useFormHandler";
import UserCardService from "../../../../services/UserCardService";
import PaymentForNumberBonuses from "../../../modal/paymentForNumberBonuses";
import {IReservations} from "../../../../core/models/Reservations";
import {useNavigate} from "react-router-dom";
import AutopaymentSBP from "./autopaymentSBP";

type TContent = {
    [key: string]: {
        title: string;
        footer: Array<ReactNode>;
        children: ReactFragment | ReactNode;
    };
};

type TProps = {
    isMainPage: boolean;
    visible: boolean;
    cancelHandler: () => void;
    number?: number;
    tariffName?: string;
    price?: number;
    activeKey?: string;
    hashSuccess?: boolean;
    recommend_sum?: number;
    setItems?: React.Dispatch<React.SetStateAction<IReservations[]>>;
};

const ActivationModal = ({
    isMainPage,
    visible,
    cancelHandler,
    number,
    tariffName,
    price,
    activeKey,
    hashSuccess,
    recommend_sum,
    setItems
}: TProps) => {
    const {t} = useTranslation();
    const [state, setState] = useState<"default" | "defaultCreate" | "success" | "error">(
        "default"
    );
    const navigate = useNavigate();
    const [apiErrors, setApiErrors] = useState(null);
    const contextModal = useContext(ModalContext);
    const [initForm] = Form.useForm();
    const form = useFormHandler(initForm);

    const cardOrBonusesClickHandler = (key: string) => {
        switch (key) {
            case "card":
                setState("defaultCreate");
                break;
            case "sbp":
                cancelHandler();
                contextModal?.setVisible(true);
                contextModal?.setChildren(
                    <AutopaymentSBP
                        number={number}
                        isMainPage={isMainPage}
                        form={form}
                        tariffName={tariffName}
                        cancelHandler={cancelHandler}
                        setApiErrors={setApiErrors}
                        setState={setState}
                    />
                );
                break;
            case "bonuses":
                cancelHandler();
                contextModal?.setVisible(true);
                isMainPage
                    ? contextModal?.setChildren(<ReplenishmentOfBalanceBonuses />)
                    : contextModal?.setChildren(
                          <PaymentForNumberBonuses
                              phone={number!}
                              tariffName={tariffName!}
                              price={price!}
                              activeKey={activeKey!}
                              setItems={setItems!}
                              recommend_sum={recommend_sum}
                          />
                      );
                break;

            default:
                break;
        }
    };

    const onCloseModal = () => {
        cancelHandler();
        setState("default");
        form.resetFields();
        navigate(
            `/${
                window.location.href
                    .split("/")
                    [window.location.href.split("/").length - 1].split("?")[0]
                    .split("#")[0]
            }`
        );
    };

    const onFinish = async (props: {
        card_id: string | number;
        phone: string | number;
        sum_payment: string | number;
    }) => {
        try {
            const phone = isMainPage
                ? +_.replace(props.phone.toString(), /(\+7|\s|-)/gi, "")
                : number;
            await UserCardService.postBankCardPayment(+props.card_id, phone!, +props.sum_payment);
            setState("success");
        } catch (err: any) {
            if (!!err.response.data.name) {
                setApiErrors(err.response.data.message);
                setState("error");
            }
        }
    };

    useEffect(() => {
        if (hashSuccess) {
            setState("defaultCreate");
        }
    }, [hashSuccess]);

    const content: TContent = {
        default: {
            title: isMainPage ? t("Пополнить номер") : t("Оплатить картой"),
            footer: [
                <Button
                    className="button-pay-with-card button-pay-with-bonuses"
                    type="outline"
                    onClick={() => cardOrBonusesClickHandler("card")}
                >
                    {isMainPage ? t("Пополнить картой") : t("Оплатить картой")}
                </Button>,
                <Button
                    className="button-pay-with-sbp"
                    type="primary"
                    onClick={() => cardOrBonusesClickHandler("sbp")}
                >
                    {t("Онлайн через СПБ")}
                </Button>,
                <Button
                    className="button-pay-with-bonuses"
                    type="outline"
                    onClick={() => cardOrBonusesClickHandler("bonuses")}
                >
                    {isMainPage ? t("Пополнить бонусами") : t("Оплатить бонусами")}
                </Button>
            ],
            children: (
                <>
                    {isMainPage ? (
                        <div className="modal-pay-default-description">
                            {t("После пополнения баланс номера будет увеличен")}
                        </div>
                    ) : (
                        <div className="modal-pay-default-description">
                            {t("После оплаты номер телефона ")}
                            <Typography
                                as={"span"}
                                className="modal-pay-default-number"
                                color="#642878"
                            >
                                {
                                    <NumberFormat
                                        value={number}
                                        displayType={"text"}
                                        thousandSeparator={false}
                                        format="##########"
                                    />
                                }
                            </Typography>
                            {t(" будет активирован")}
                        </div>
                    )}
                </>
            )
        },
        defaultCreate: {
            title: isMainPage ? t("Пополнить картой") : t("Оплатить картой"),
            footer: [],
            children: (
                <AutopaymentDefaultCreate
                    numberPhone={number}
                    isMainPage={isMainPage}
                    onFinish={onFinish}
                    form={form}
                    tariffName={tariffName}
                />
            )
        },
        success: {
            title: isMainPage ? t("Номер пополнен") : t("Номер активирован"),
            footer: [],
            children: isMainPage ? (
                <div className="activation-modal--description">
                    {t(`Зачислено ${form.getFieldValue("sum_payment")} ₽`)}
                </div>
            ) : (
                <>
                    {number && (
                        <Typography className="removement-modal--number" color="#642878">
                            {
                                <NumberFormat
                                    value={number}
                                    displayType={"text"}
                                    thousandSeparator={false}
                                    format="### ###-##-##"
                                />
                            }
                        </Typography>
                    )}
                    <div className="activation-modal--description">
                        {t(
                            "Вы можете совершать звонки и получать SMS сообщения и пользоваться интернетом"
                        )}
                    </div>
                </>
            )
        },
        error: {
            title: t("Произошла ошибка"),
            footer: [],
            children: (
                <>
                    {apiErrors ? (
                        <div key={apiErrors} style={{textAlign: "center"}}>
                            {apiErrors}
                        </div>
                    ) : (
                        <div style={{textAlign: "center"}}>{t("Повторите попытку позже")}</div>
                    )}
                </>
            )
        }
    };

    let ModalImage = null;
    if (state === "success") {
        ModalImage = <SuccessImg />;
    } else if (state === "error") {
        ModalImage = <ErrorImg />;
    }

    const isForPhoneModalType = isMobile && state === "default";

    return (
        <Modal
            centered
            open={visible}
            onCancel={onCloseModal}
            title={content[state].title}
            footer={content[state].footer}
            img={ModalImage}
            forPhone={isForPhoneModalType}
            className="modal-pay-card-or-bonuses"
        >
            {content[state].children}
        </Modal>
    );
};

export default ActivationModal;
