import React, {useContext, useEffect, useState} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// forms
import InviteAUserForm from "../../forms/inviteAUser";
// core
import {LayoutContext} from "../../../core/Contexts";
// store
import Store from "../../../stores";

export default React.memo(
    observer(function InviteAUserModal() {
        const contextLayout = useContext(LayoutContext);
        const {t} = useTranslation();
        const [percent, setPercent] = useState(0);

        useEffect(() => {
            setPercent(
                Store.user.loyalty
                    ? Store.bonuses.rateBasicLevel
                    : Store.bonuses.rateBasicLevel + Store.bonuses.rateLoyaltyLevel
            );
        }, [Store.user.loyalty, Store.bonuses.rateBasicLevel, Store.bonuses.rateLoyaltyLevel]);

        return (
            <Typography as="div" className="bl-invite-a-user-modal">
                <Space
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 40 : 16}
                    direction="vertical"
                    align="center"
                >
                    <Typography as="div">
                        <Space size={8} direction="vertical" align="center">
                            <Typography
                                size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                                level={2}
                                color="#000000"
                            >
                                {t("Пригласить пользователя")}
                            </Typography>
                            <Typography color="#000000" size={14} className="subtitle">
                                <Typography as="span" color="#642878" size={14}>
                                    {t(`Получайте ${percent}% `)}
                                </Typography>
                                {t("в виде бонусов")}
                                <br />
                                {t("за активации друзей")}
                            </Typography>
                        </Space>
                    </Typography>
                    <InviteAUserForm />
                </Space>
            </Typography>
        );
    })
);
