import {makeAutoObservable} from "mobx";
import {IMeta, IReservations, ICnt} from "../core/models/Reservations";

class Reservations {
    [Symbol.toStringTag] = this.constructor.name;
    private _items!: IReservations[];
    private _meta!: IMeta;
    private _cnt!: ICnt;
    private _photosFirst!: string;
    private _photosSecond!: string;
    private _gridType!: string;
    private _recommendSum!: number;

    constructor() {
        makeAutoObservable(this);
    }

    get gridType() {
        return this._gridType;
    }

    set gridType(value: string) {
        this._gridType = value;
    }

    get items() {
        return this._items;
    }

    set items(value: IReservations[]) {
        this._items = value;
    }

    get meta() {
        return this._meta;
    }

    set meta(value: IMeta) {
        this._meta = value;
    }

    get cnt() {
        return this._cnt;
    }

    set cnt(value: ICnt) {
        this._cnt = value;
    }

    get photosFirst() {
        return this._photosFirst;
    }

    set photosFirst(value: string) {
        this._photosFirst = value;
    }

    get photosSecond() {
        return this._photosSecond;
    }

    set photosSecond(value: string) {
        this._photosSecond = value;
    }

    get recommendSum() {
        return this._recommendSum;
    }

    set recommendSum(value: number) {
        this._recommendSum = value;
    }
}

export default Reservations;
