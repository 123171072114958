import React from "react";
import {useTranslation} from "react-i18next";
import {Button, Icon} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
// core
import {IFilter} from "../../../../../../../core/models/Phones";
import PhonesFiltersService from "../../../../../../../services/PhonesFiltersService";
import {IPhonesCurrentFilters} from "../../../../../../../core/models/PhonesFilters";

export default React.memo(function Filter({items, filters, type, set}: IProps) {
    const {t} = useTranslation();

    const onClick = (value: string) => {
        const clone = _.clone(items);
        const index = _.indexOf(clone, value);
        clone.splice(index, 1);

        if (set) {
            set(_.find(filters, (i) => _.includes(["phoneCombs"], i.filter)) ? undefined : clone);
        }
        if (type) {
            PhonesFiltersService.setCurrentFilter({
                [type as keyof IPhonesCurrentFilters]: _.find(filters, (i) =>
                    _.includes(["phone_combs"], i.filter)
                )
                    ? undefined
                    : clone
            });
        }
    };

    return (
        <>
            {_.chain(filters)
                .filter(({filter}) => _.includes(items, filter))
                .map(({name, filter}) => (
                    <Button
                        key={filter}
                        type="default"
                        className={set ? "" : "not-click-able"}
                        onClick={set ? () => onClick(filter) : undefined}
                        icon={set ? <Icon name="close" size="8" /> : undefined}
                    >
                        {t(name)}
                    </Button>
                ))
                .valueOf()}
        </>
    );
});

interface IProps {
    items: string[];
    filters: IFilter[];
    type?: string;
    set?: React.Dispatch<React.SetStateAction<any>>;
}
