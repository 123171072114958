import {Collapse, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {observer} from "mobx-react";
// services
import DeliveryService from "../../../../services/DeliveryService";
// components
import ListColapse from "./listColapse";
import ListDescription from "./listColapse/listDescription";
import {Button} from "antd";
import {useState} from "react";

const {Panel} = Collapse;

export default observer(function GroupDeliveryColapse({activeKey, onTabClick}: IProps) {
    const [loading, setLoading] = useState(false);

    const onClick = async () => {
        try {
            setLoading(true);
            await DeliveryService.loadMoreDelivery({
                is_archived: DeliveryService.isArchive(activeKey),
                page: DeliveryService.itemsSize / 50 + 1
            });
        } catch (error) {
            throw error;
        } finally {
            setLoading(false);
        }
    };

    return (
        <>
            {_.chain(DeliveryService.items)
                .map(
                    (
                        {
                            id,
                            status_name,
                            created_at,
                            track_number,
                            address,
                            comment,
                            fio,
                            logistic_comment,
                            additional_comment,
                            phone,
                            status,
                            shipped_at,
                            track_url
                        },
                        key
                    ) => {
                        return (
                            <Collapse ghost expandIconPosition="end" key={key}>
                                <ListColapse
                                    id={id}
                                    status_name={status_name}
                                    created_at={created_at}
                                    track_number={track_number}
                                    status={status}
                                    shipped_at={shipped_at}
                                    track_url={track_url}
                                />

                                <Panel header="" key={""}>
                                    <ListDescription
                                        fio={fio}
                                        phone={phone}
                                        address={address}
                                        comment={comment}
                                        logistic_comment={logistic_comment}
                                        additional_comment={additional_comment}
                                        onTabClick={onTabClick}
                                    />
                                </Panel>
                            </Collapse>
                        );
                    }
                )
                .valueOf()}
            {DeliveryService.meta.totalCount > DeliveryService.itemsSize && (
                <Typography as="div" className="load-more">
                    <Button onClick={onClick} loading={loading}>
                        Показать ещё
                    </Button>
                </Typography>
            )}
        </>
    );
});

interface IProps {
    activeKey: string;
    onTabClick: (key: string, recipient?: any) => void;
}
