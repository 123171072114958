import {observer} from "mobx-react";
import {useContext, useState} from "react";
import {Col, Icon, Row} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import SVG from "react-inlinesvg";
//images
import americanexpress from "../../../../../images/americanexpress.svg";
import mastercard from "../../../../../images/mastercard.svg";
import dinersclub from "../../../../../images/dinersclub.svg";
import elo from "../../../../../images/elo.svg";
import hiper from "../../../../../images/hiper.svg";
import hipercard from "../../../../../images/hipercard.svg";
import jcb from "../../../../../images/jcb.svg";
import maestro from "../../../../../images/maestro.svg";
import unionpay from "../../../../../images/unionpay.svg";
import visa from "../../../../../images/visa.svg";
import mir from "../../../../../images/mir.svg";
import discover from "../../../../../images/discover.svg";
//components
import ModalBankCardsRemoval from "../modalBankCardsRemoval";
import {LayoutContext} from "../../../../../core/Contexts";

export default observer(function ListRowBankCards({cardId, type, maskerPan}: Props) {
    const [isRemovementModalVisible, setRemovementModalVisible] = useState(false);
    const contextLayout = useContext(LayoutContext);
    const arrayIconsCardType = [
        {cardType: "americanexpress", cardImport: americanexpress},
        {cardType: "mir", cardImport: mir},
        {cardType: "mastercard", cardImport: mastercard},
        {cardType: "dinersclub", cardImport: dinersclub},
        {cardType: "elo", cardImport: elo},
        {cardType: "hiper", cardImport: hiper},
        {cardType: "hipercard", cardImport: hipercard},
        {cardType: "jcb", cardImport: jcb},
        {cardType: "maestro", cardImport: maestro},
        {cardType: "unionpay", cardImport: unionpay},
        {cardType: "visa", cardImport: visa},
        {cardType: "discover", cardImport: discover}
    ];

    return (
        <>
            <Row className="bankCards-list--row">
                <Col span={24}>
                    <Row gutter={[0, 16]} justify="space-between">
                        <Col className="numbers-list--type">
                            <SVG
                                src={
                                    _.find(arrayIconsCardType, (card) =>
                                        _.isEqual(
                                            card.cardType,
                                            type.toLowerCase().replace(/\s/g, "")
                                        )
                                    )?.cardImport ?? ""
                                }
                                width={30}
                            />
                            <p>{type}</p>
                        </Col>
                        <Row>
                            <Col className="numbers-list--maskerPan">
                                <span>
                                    {_.gte(contextLayout?.width ?? 0, 340)
                                        ? "•••• •••• •••• "
                                        : "•••• "}
                                </span>
                                {maskerPan}
                            </Col>
                            <Col className="numbers-list--trash">
                                <span onClick={() => setRemovementModalVisible(true)}>
                                    <Icon name="trash" size="large" />
                                </span>
                            </Col>
                        </Row>
                    </Row>
                </Col>
            </Row>

            <ModalBankCardsRemoval
                id={cardId}
                type={type}
                maskerPan={maskerPan}
                visible={isRemovementModalVisible}
                cancelHandler={() => setRemovementModalVisible(false)}
            />
        </>
    );
});

type Props = {
    cardId: number;
    type: string;
    maskerPan: string;
};
