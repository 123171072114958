import React, {useCallback, useContext, useEffect, useState} from "react";
import {observer} from "mobx-react";
import _ from "lodash";
import {Block, Button, Col, Divider, Row, Typography, Title} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
//service
import BonusesLevelsService from "../../../services/BonusesLevelsService";
import BonusesService from "../../../services/BonusesService";
//component
import SummaryTitle from "./title";
import DealerBonuses from "./dealerBonuses";
import AccrualsFilterForm, {IFilterRequest} from "../../forms/accrualsFilter";
//core
import {InterceptorContext} from "../../../core/Contexts";

import "./style.scss";

export const DealersBonuses: React.FC = observer(() => {
    const {t} = useTranslation();
    const context = useContext(InterceptorContext);
    const levels = BonusesLevelsService.items;
    const [filterState, setFilterState] = useState<IFilterRequest>();

    const onAddMore = async () => {
        const request: any = {};

        if (_.isArray(filterState?.period) && _.size(filterState?.period)) {
            const [from, to] = filterState!.period;
            request.date_from = from.format("YYYY-MM-DD 00:00:00");
            request.date_to = to.format("YYYY-MM-DD 23:59:59");
        }

        if (!_.isNil(filterState?.search) && _.size(filterState?.search)) {
            request.query = filterState!.search;
        }

        await BonusesLevelsService.loadMorelevels({
            ...request,
            page: BonusesLevelsService.currentPage + 1
        });
    };

    const callback = useCallback(async () => {
        const request: any = {};

        if (_.isArray(filterState?.period) && _.size(filterState?.period)) {
            const [from, to] = filterState!.period;
            request.date_from = from.format("YYYY-MM-DD 00:00:00");
            request.date_to = to.format("YYYY-MM-DD 23:59:59");
        }

        if (!_.isNil(filterState?.search) && _.size(filterState?.search)) {
            request.query = filterState!.search;
        }

        await Promise.all([
            BonusesLevelsService.fetchlevels(request),
            BonusesService.getLevelsSummary(request)
        ]);
    }, [filterState]);

    useEffect(() => {
        callback();
    }, [filterState]);

    return (
        <div className="bl-dealers-bonuses-page">
            <Title title={t("Начисления дилеров")} />
            <Block className="bl-dealers-bonuses-content">
                <Row gutter={[16, 16]}>
                    <Col lg={{span: 9, push: 15}} xs={24}>
                        <SummaryTitle
                            filterState={filterState}
                            summarySum={BonusesService.summarySum}
                            summaryProcessSum={BonusesService.summaryProcessSum}
                        />
                    </Col>
                    <Col xs={24} lg={{span: 15, pull: 9}} className="d-flex align-items-center">
                        <AccrualsFilterForm setFilterState={setFilterState} />
                    </Col>
                    <Col span={24}>
                        <Row gutter={[16, 16]}>
                            <Col lg={9} md={9} xs={0}>
                                <Typography className="col-title">{t("Дата")}</Typography>
                            </Col>
                            <Col lg={7} md={7} xs={0}>
                                <Typography className="col-title">{t("Операций")}</Typography>
                            </Col>
                            <Col
                                lg={
                                    !!(!BonusesService.summaryProcessSum || filterState?.period)
                                        ? 6
                                        : 4
                                }
                                md={
                                    !!(!BonusesService.summaryProcessSum || filterState?.period)
                                        ? 6
                                        : 4
                                }
                                xs={0}
                            >
                                <Typography
                                    className={`col-title${
                                        !(!BonusesService.summaryProcessSum || filterState?.period)
                                            ? " penultimate"
                                            : " last"
                                    }`}
                                >
                                    {t("Накоплено")}
                                </Typography>
                            </Col>
                            {!(!BonusesService.summaryProcessSum || filterState?.period) && (
                                <Col lg={4} md={4} xs={0}>
                                    <Typography className="col-title last">
                                        {t("Начисляется")}
                                    </Typography>
                                </Col>
                            )}
                        </Row>
                        <Divider className="divider-title" />
                    </Col>
                    <Col span={24}>
                        <Row gutter={[16, {xs: 0, sm: 0, md: 16, lg: 16}]}>
                            {_.chain(levels)
                                .map((item, key) => (
                                    <React.Fragment key={key}>
                                        <Col span={24}>
                                            <DealerBonuses level={item} filterState={filterState} />
                                        </Col>
                                    </React.Fragment>
                                ))
                                .valueOf()}
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Typography as="div" className="btn-show">
                            <Button
                                className="btn-show-more"
                                loading={context?.loadingBtn}
                                disabled={!BonusesLevelsService.hasItems}
                                onClick={onAddMore}
                            >
                                {t("Показать еще")}
                            </Button>
                        </Typography>
                    </Col>
                </Row>
            </Block>
        </div>
    );
});
