import {Button, Col, Row, Space, Typography, Icon} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import React, {useContext} from "react";
import {useTranslation} from "react-i18next";
import "./style.scss";
import {observer} from "mobx-react";
// core
import {LayoutContext, ModalContext} from "../../../../core/Contexts";
// services
import BonusesService from "../../../../services/BonusesService";
// components
import OutputOfBalanceBonuses from "../../../modal/outputOfBalanceBonuses";
import Store from "../../../../stores";

export default React.memo(
    observer(function OutputNumberBonuses() {
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const {t} = useTranslation();

        const onClick = () => {
            contextModal?.setVisible(true);
            contextModal?.setChildren(<OutputOfBalanceBonuses />);
        };

        const ifDescriptionText = () => {
            if (
                _.isEqual(Store.user.personal_data?.phone_status, "reject") &&
                _.isEqual(Store.user.personal_data?.passport_status, "reject")
            ) {
                return t("Внесите ПД и подтвердите телефон");
            } else if (_.isEqual(Store.user.personal_data?.phone_status, "reject")) {
                return t("Подтвердите номер телефона");
            } else if (_.isEqual(Store.user.personal_data?.passport_status, "reject")) {
                return t("Внесите паспортные данные");
            } else {
                return t("От 5000 бонусов");
            }
        };

        return (
            <Typography
                as="div"
                className={`bl-replenish-number-bonuses ${
                    !_.isNil(Store.user.loyalty) ? "" : " without-loyalty"
                }`}
            >
                <Button
                    disabled={
                        BonusesService.accumulated < 5000 ||
                        _.isEqual(Store.user.personal_data?.phone_status, "reject") ||
                        _.isEqual(Store.user.personal_data?.passport_status, "reject")
                    }
                    onClick={onClick}
                >
                    <Row>
                        <Space>
                            <Col>
                                <Space direction="vertical" size={8}>
                                    <Typography
                                        className="block-title"
                                        size={_.gte(contextLayout?.width, 768) ?? 0 ? 17 : 14}
                                    >
                                        {t("Вывод на карту")}
                                    </Typography>
                                    <Typography
                                        color="rgba(255, 255, 255, 0.5)"
                                        size={14}
                                        className="text"
                                    >
                                        {ifDescriptionText()}
                                    </Typography>
                                </Space>
                            </Col>
                            <Col>
                                <Typography as="div" className="icon-vector">
                                    <Icon name={"arrowRight"} />
                                </Typography>
                            </Col>
                        </Space>
                    </Row>
                </Button>
            </Typography>
        );
    })
);
