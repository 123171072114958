import React, {useState} from "react";
import {observer} from "mobx-react";
import {Form, Typography, Button} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {useContext, useEffect, useRef} from "react";
import {useTranslation} from "react-i18next";
import {FieldData} from "rc-field-form/lib/interface";
import "./style.scss";
// hooks
import useFormHandler from "../../../core/hooks/useFormHandler";
// core
import {InterceptorContext, LayoutContext} from "../../../core/Contexts";
import {getFieldsErrorSize} from "../../../core/functions/form";
import {toGroupNumbers, toDisabledGroupNumbers} from "../../../core/functions/inputNumber";
import useFieldFocus from "../../../core/hooks/useFieldFocus";
// components
import CodeField from "../../fields/code";

export default React.memo(
    observer(function CodeConfirmationForm({onSubmit, ...props}: any) {
        const ref = useRef(null);
        const timeout: any = useRef();
        const [loading, setLoading] = useState(false);
        const context = useContext(InterceptorContext);
        const contextLayout = useContext(LayoutContext);
        const {t} = useTranslation();
        const [initForm] = Form.useForm();
        const formInstance = useFormHandler(initForm);
        useFieldFocus(ref, "code");

        const onSend = async (values: any) => {
            try {
                await onSubmit(values);
            } catch (e) {
            } finally {
                formInstance.resetFields();
                toGroupNumbers(ref, "code", formInstance);
                setLoading(false);
            }
        };

        const onFinish = async (values: any) => {
            clearTimeout(timeout.current);
            setLoading(true);
            toDisabledGroupNumbers(ref);
            onSend({...props, code: _.join(values.code!, "")});
        };

        const onFieldsChange = async (changedFields: FieldData[], allFields: FieldData[]) => {
            clearTimeout(timeout.current);

            const code = _.values(_.omitBy(_.head(changedFields)?.value, _.isNil)).filter(
                (i) => !_.isEmpty(i)
            );

            if (_.gte(_.size(code), 4) && _.gte(contextLayout?.width ?? 0, 768)) {
                toDisabledGroupNumbers(ref);
                timeout.current = setTimeout(() => {
                    onSend({...props, code: _.join(code, "")});
                }, 2000);
            }

            if (_.lt(_.size(code), 4) && context?.data?.message) {
                context.setErrors(null);
            }
        };

        useEffect(() => {
            toGroupNumbers(ref, "code", formInstance);
        }, [ref]);

        return (
            <div ref={ref}>
                <Typography as="div" className="bl-code-confirmation-form">
                    <Form
                        form={formInstance}
                        name="codeConfirmation"
                        initialValues={{code: ""}}
                        onFinish={onFinish}
                        onFieldsChange={onFieldsChange}
                        autoComplete="off"
                    >
                        <Typography as="div" className="code-container">
                            {_.gte(contextLayout?.width ?? 0, 768) && (
                                <Button type="default" loading={loading} />
                            )}
                            <CodeField />
                        </Typography>
                        {!context?.data?.message && _.lte(contextLayout?.width ?? 0, 767) && (
                            <Typography
                                as="div"
                                size={14}
                                color="#000000"
                                className="send-to-phone-message"
                            >
                                {t("Мы отправили код подтверждения ")}
                                <br />
                                {t("на ")}
                                <Typography
                                    as="span"
                                    color="#642878"
                                    size={14}
                                    className="send-phone-message-diler"
                                >
                                    {props.phone}
                                </Typography>
                            </Typography>
                        )}
                        {_.lte(contextLayout?.width ?? 0, 768) && (
                            <Form.Item shouldUpdate>
                                {({getFieldsValue, getFieldsError}) => {
                                    const {code} = getFieldsValue();
                                    const start = (code || []).length;
                                    const end = _.chain(code || [])
                                        .filter((i) => !_.isEmpty(i))
                                        .valueOf().length;
                                    const errors = getFieldsErrorSize(getFieldsError);
                                    const formIsComplete =
                                        !!code && _.isEqual(start, end) && !errors;

                                    return (
                                        <Button
                                            type={!formIsComplete ? "default" : "primary"}
                                            htmlType="submit"
                                            disabled={!formIsComplete}
                                            loading={context?.loadingBtn}
                                        >
                                            {t("Подтвердить")}
                                        </Button>
                                    );
                                }}
                            </Form.Item>
                        )}
                    </Form>
                </Typography>
            </div>
        );
    })
);
