import {useEffect, useState} from "react";
import {Col, Row, Back} from "@bezlimit/bezlimit-ui";
import {useNavigate} from "react-router-dom";
import "./style.scss";
import _ from "lodash";
import {
    browserName,
    browserVersion,
    isAndroid,
    isIOS,
    mobileModel,
    mobileVendor,
    osName
} from "react-device-detect";
// components
import Profile from "../../../blocks/profile";
import MobileMenuSessions from "../../../blocks/profile/mobbileMemuSession";
//services
import SessionsServise from "../../../../services/Sessions";
//store
import Store from "../../../../stores";

export default function ProfilePage() {
    const navigate = useNavigate();
    const [visibleMob, setVisibleMob] = useState(false);

    useEffect(() => {
        const f = async () => {
            if (!!Store.user.token) {
                if ("geolocation" in navigator) {
                    /* местоположение доступно */
                    let lat;
                    let lon;
                    const position: any = await new Promise((resolve, reject) => {
                        navigator.geolocation.getCurrentPosition(resolve, (error) => {
                            if (error.code == error.PERMISSION_DENIED) {
                                resolve(true);
                            }
                        });
                    });
                    lat = position?.coords?.latitude ?? "";
                    lon = position?.coords?.longitude ?? "";
                    await SessionsServise.putSessions({
                        lat,
                        lon,
                        device_data: JSON.stringify({
                            name: isIOS ? mobileModel : isAndroid ? mobileVendor : "PC",
                            type: "web",
                            os_name: _.isEqual(osName, "Linux")
                                ? "linux"
                                : _.isEqual(osName, "Mac OS")
                                ? "macOS"
                                : _.isEqual(osName, "Windows")
                                ? "windows"
                                : _.isEqual(osName, "Android")
                                ? "android"
                                : osName,
                            browser_name: browserName,
                            browser_version: browserVersion
                        })
                    });
                } else {
                    await SessionsServise.putSessions({
                        lat: "",
                        lon: "",
                        device_data: JSON.stringify({
                            name: isIOS ? mobileModel : isAndroid ? mobileVendor : "PC",
                            type: "web",
                            os_name: _.isEqual(osName, "Linux")
                                ? "linux"
                                : _.isEqual(osName, "Mac OS")
                                ? "macOS"
                                : _.isEqual(osName, "Windows")
                                ? "windows"
                                : _.isEqual(osName, "Android")
                                ? "android"
                                : osName,
                            browser_name: browserName,
                            browser_version: browserVersion
                        })
                    });

                    /* местоположение НЕ доступно */
                }
                getSessions();
            }
        };
        f();
    }, [Store.user.token]);

    const getSessions = async () => {
        await SessionsServise.fetchSessions();
    };

    return (
        <>
            {!visibleMob ? (
                <Row justify="center" gutter={32}>
                    <Back navigate={() => navigate("/")} />
                    <Col lg={{span: 24}} xs={{span: 24}}>
                        <Profile setVisibleMob={setVisibleMob} />
                    </Col>
                </Row>
            ) : (
                <MobileMenuSessions setVisibleMob={setVisibleMob} />
            )}
        </>
    );
}
