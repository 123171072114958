import React, {useContext, useEffect} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import CodeConfirmationForm from "../../forms/codeConfirmation";
import _ from "lodash";
import "./style.scss";
// components
import Duration from "../../blocks/duration";
// services
import UserService from "../../../services/UserService";
// core
import {InterceptorContext, LayoutContext} from "../../../core/Contexts";

export default React.memo(function CodeConfirmationReplenishBonuses({
    phone,
    onSubmit,
    ...props
}: any) {
    const context = useContext(InterceptorContext);
    const contextLayout = useContext(LayoutContext);
    const {t} = useTranslation();

    useEffect(() => {
        const el = document.body.querySelector(".send-phone-message-diler");

        if (el) {
            el.textContent = UserService.personalData.phone.toString();
        }
    }, [contextLayout?.width]);

    return (
        <Typography as="div" className="bl-code-confirmation-replenish-bonuses">
            <Space
                size={_.gte(contextLayout?.width ?? 0, 768) ? 8 : 56}
                direction="vertical"
                align="center"
            >
                <Typography as="div" className="title-container">
                    <Space size={8} direction="vertical" align="center">
                        <Typography
                            size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                            level={2}
                            color="#000000"
                        >
                            {t("Введите код")}
                        </Typography>
                        <Typography
                            size={14}
                            color={_.gte(contextLayout?.width ?? 0, 768) ? "#000" : "#828282"}
                            className="text-helper subtitle"
                        >
                            {t("На ваш контактный номер был отправлен код ")}
                            <br />
                            {t("по SMS для подтверждения перевода")}
                        </Typography>
                    </Space>
                    <Typography size={24} color="#642878" className="text-helper phone">
                        {phone}
                    </Typography>
                </Typography>
                <CodeConfirmationForm {...props} phone={phone} onSubmit={onSubmit} />
                {!context?.data?.message && _.gte(contextLayout?.width ?? 0, 768) && (
                    <Typography size={14} color="#000000" className="send-to-phone-message">
                        {t("Мы отправили код подтверждения на {phone}", {
                            phone: UserService.personalData.phone
                        })}
                    </Typography>
                )}
                <Duration
                    onTrigger={() =>
                        UserService.putPersonalData({
                            phone: UserService.personalData.phone.toString(),
                            isloading: false
                        })
                    }
                />
            </Space>
        </Typography>
    );
});
