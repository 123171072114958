import React from "react";
import {Button, Typography} from "@bezlimit/bezlimit-ui";
import Clipboard from "react-clipboard.js";
import {useTranslation} from "react-i18next";
import "./style.scss";

export default React.memo(function CopyButton({disabled, title, onCopy, onSuccess}: IProps) {
    const {t} = useTranslation();

    return (
        <Typography as="div" className="bl-copy-button">
            <Button type="default" disabled={disabled}>
                <Clipboard component="span" data-clipboard-text={onCopy()} onSuccess={onSuccess}>
                    {t(disabled ? "Номера скопированы!" : title)}
                </Clipboard>
            </Button>
        </Typography>
    );
});

interface IProps {
    title: string;
    disabled: boolean;
    onCopy: () => any;
    onSuccess: () => void;
}
