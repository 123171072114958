import {Col, Icon, Row, Space, Typography, Progress} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import {useTranslation} from "react-i18next";
import NumberFormat from "react-number-format";
import cn from "classnames";
import _ from "lodash";
// store
import Store from "../../../../stores";
//core
import {IBonusLevel} from "../../../../core/models/Bonuses";
import BonusesService from "../../../../services/BonusesService";
import {IFilterRequest} from "../../../forms/accrualsFilter";
import {observer} from "mobx-react";

export default observer(function DealerBonuses({level, filterState}: IProps) {
    const {t} = useTranslation();
    const inProcess = level.sum - level.charged_sum;

    // |100 - 100 * (sum - charged_sum) / sum|
    const getProgress = (sum: number, chargedSum: number): number => {
        if (!sum) {
            return 0;
        }
        return 100 - (100 * (sum - chargedSum)) / sum;
    };

    return (
        <Typography as="div" className="bl-dealer-bonuses">
            <Row gutter={[16, {xs: 4, sm: 4, md: 16, lg: 16}]}>
                <Col md={9} xs={0}>
                    <Typography className="dealers-bonuses-text">{level.name}</Typography>
                    {!_.isEqual(Store.user?.level?.level, 1) && (
                        <Typography className="dealers-bonuses-subtext">
                            {t(`(ID в биллинге: ${level.dealer_id})`)}
                        </Typography>
                    )}
                </Col>
                {inProcess ? (
                    <Col md={0} xs={12}>
                        <Typography className="dealers-bonuses-text">{level.name}</Typography>
                        {!_.isEqual(Store.user?.level?.level, 1) && (
                            <Typography className="dealers-bonuses-subtext">
                                {t(`(ID в биллинге: ${level.dealer_id})`)}
                            </Typography>
                        )}
                    </Col>
                ) : (
                    <Col md={0} xs={24} className="col-no-process-bar">
                        <Typography className="dealers-bonuses-text">{level.name}</Typography>
                        {!_.isEqual(Store.user?.level?.level, 1) && (
                            <Typography className="dealers-bonuses-subtext">
                                {t(`(ID в биллинге: ${level.dealer_id})`)}
                            </Typography>
                        )}
                    </Col>
                )}
                <Col md={7} xs={0}>
                    {inProcess ? (
                        <div>
                            <Space>
                                <Icon name="status_process" size="14" />
                                <Typography className="dealers-bonuses-text">
                                    {t("Процесс начисления")}
                                </Typography>
                            </Space>
                            <Progress
                                className="dealers-bonuses-progress-line"
                                percent={getProgress(level.sum, level.charged_sum)}
                                showInfo={false}
                            />
                        </div>
                    ) : (
                        <Space>
                            <Icon name="status_completed" size="10" />
                            <Typography className="dealers-bonuses-text">
                                {t("Завершено")}
                            </Typography>
                        </Space>
                    )}
                </Col>
                <Col md={0} xs={12} className={cn("col-mobile-status", {left: !inProcess})}>
                    {inProcess ? (
                        <Space>
                            <Icon name="mob-process" size="14" />
                            <Typography className="dealers-bonuses-text">
                                {t("Начисляется")}
                            </Typography>
                        </Space>
                    ) : (
                        <Space>
                            <Icon name="status_completed" size="10" />
                            <Typography className="dealers-bonuses-text">
                                {t("Завершено")}
                            </Typography>
                        </Space>
                    )}
                </Col>
                {!!inProcess && (
                    <Col md={0} xs={24} className="col-mobile-progress">
                        <Progress
                            className="dealers-bonuses-progress-line"
                            percent={getProgress(level.sum, level.charged_sum)}
                            showInfo={false}
                        />
                    </Col>
                )}

                <Col
                    md={!!(!BonusesService.summaryProcessSum || filterState?.period) ? 6 : 4}
                    xs={!!(!BonusesService.summaryProcessSum || filterState?.period) ? 24 : 12}
                >
                    <Typography as="div" className="mobile-bonuses-sum">
                        <Typography
                            className={cn("mobile-bonuses-sum-sum", {
                                right: !inProcess,
                                "mobile-charged-sum-wrapper": !(
                                    !BonusesService.summaryProcessSum || filterState?.period
                                )
                                    ? false
                                    : true
                            })}
                        >
                            <NumberFormat
                                thousandSeparator=" "
                                displayType="text"
                                value={level.charged_sum?.toFixed(2)}
                            />
                        </Typography>
                        <Typography
                            className={cn("mobile-bonuses-sum-text", {
                                right: !inProcess,
                                "mobile-charged-sum-wrapper": !(
                                    !BonusesService.summaryProcessSum || filterState?.period
                                )
                                    ? false
                                    : true
                            })}
                            size={14}
                            color="rgba(255, 255, 255, 0.5)"
                        >
                            {t("Накоплено")}
                        </Typography>
                    </Typography>
                </Col>

                {!(!BonusesService.summaryProcessSum || filterState?.period) && (
                    <Col md={4} xs={12}>
                        <Typography as="div" className="mobile-bonuses-charged-sum">
                            <Space className="mobile-charged-sum-wrapper">
                                <Typography className="mobile-charged-sum-sum">
                                    <NumberFormat
                                        thousandSeparator=" "
                                        displayType="text"
                                        value={(level.sum - level.charged_sum).toFixed(2)}
                                    />
                                </Typography>
                                <Typography>
                                    <Icon name="Polygon5" size="8" />
                                </Typography>
                            </Space>
                            <Typography
                                className="mobile-charged-sum-text"
                                size={14}
                                color="rgba(255, 255, 255, 0.5)"
                            >
                                {t("Начисляется")}
                            </Typography>
                        </Typography>
                    </Col>
                )}
            </Row>
        </Typography>
    );
});

interface IProps {
    level: IBonusLevel;
    filterState?: IFilterRequest | undefined;
}
