import * as Private from "../../components/private";
import * as Public from "../../components/public";

export type ComponentTypes =
    | typeof Private.DashboardPage
    | typeof Private.BonusesPage
    | typeof Private.NumbersPage
    | typeof Private.ProfilePage
    | typeof Private.BookPage
    | typeof Private.BookPageReservations
    | typeof Private.OperationHistoryPage
    | typeof Private.DealersPage
    | typeof Private.DealersBonusesPage
    | typeof Private.DeliveryPage
    | typeof Private.RedemptionPage
    | typeof Private.LotteryPage
    | typeof Private.SupportPage
    | typeof Private.TariffConnection
    | typeof Private.NotificationsPage
    | typeof Private.NewsPage
    | typeof Private.TariffsPage
    | typeof Private.NewsDescriptionPage
    | typeof Private.SuperLingPage
    | typeof Private.NpsPage;
export type PrivateRouteTypes = {
    component: ComponentTypes;
    path: string;
};

const routes = {
    DashboardPage: {
        component: Private.DashboardPage,
        path: "/",
        breadcrumb: "Профиль пользователя",
        class: "page-dashboard",
        title: "Главная"
    },
    BonusesPage: {
        component: Private.BonusesPage,
        path: "/bonus",
        breadcrumb: "Мои бонусы",
        class: "page-bonuses",
        title: "Мои бонусы"
    },
    NumbersPage: {
        component: Private.NumbersPage,
        path: "/numbers",
        breadcrumb: "База номеров",
        class: "page-database-of-numbers",
        title: "База номеров"
    },
    ProfilePage: {
        component: Private.ProfilePage,
        path: "/profile",
        breadcrumb: "Профиль",
        class: "page-profile",
        title: "Профиль"
    },
    BookPageResevation: {
        component: Private.BookPage,
        path: "/reservation",
        breadcrumb: "Мои брони",
        class: "page-book",
        title: "Мои брони"
    },
    BookPageActivation: {
        component: Private.BookPage,
        path: "/activation",
        breadcrumb: "Мои активации",
        class: "page-book",
        title: "Мои активации"
    },
    BookPageReservations: {
        component: Private.BookPageReservations,
        path: "/reservation/:id/:phone",
        breadcrumb: "Редактирование бронирования",
        class: "page-book-reservations",
        title: "Редактирование бронирования"
    },
    OperationHistoryPage: {
        component: Private.OperationHistoryPage,
        path: "/history",
        breadcrumb: "История операций",
        class: "page-history",
        title: "История операций"
    },
    DealersPage: {
        component: Private.DealersPage,
        path: "/dealers",
        breadcrumb: "Дилеры",
        class: "page-dealers",
        title: "Дилеры"
    },
    DealersBonusesPage: {
        component: Private.DealersBonusesPage,
        path: "/dealers/bonus",
        breadcrumb: "Начисления дилеров",
        class: "page-dealers-bonuses",
        title: "Начисления дилеров"
    },
    NewDeliveryPage: {
        component: Private.DeliveryPage,
        path: "/deliveries/new",
        breadcrumb: "Новая доставка",
        class: "page-delivery",
        title: "Новая доставка"
    },
    MiDeliveryPage: {
        component: Private.DeliveryPage,
        path: "/deliveries",
        breadcrumb: "Мои доставки",
        class: "page-delivery",
        title: "Мои доставки"
    },
    ArchiveDeliveryPage: {
        component: Private.DeliveryPage,
        path: "/deliveries/archive",
        breadcrumb: "Архив доставок",
        class: "page-delivery",
        title: "Архив доставок"
    },
    SupportPage: {
        component: Private.SupportPage,
        path: "/support",
        breadcrumb: "Поддержка",
        class: "page-support",
        title: "Поддержка"
    },
    // RedemptionPage: {
    //     component: Private.RedemptionPage,
    //     path: "/redemption",
    //     breadcrumb: "Выкуп номеров",
    //     class: "page-redemption",
    //     title: "Выкуп номеров"
    // },
    // LotteryPage: {
    //     component: Private.LotteryPage,
    //     path: "/lottery",
    //     breadcrumb: "Розыгрыш",
    //     class: "page-lottery",
    //     title: "Розыгрыш"
    // },
    TariffsPage: {
        component: Private.TariffsPage,
        path: "/tariffs",
        breadcrumb: "Тарифы",
        class: "page-tariffs",
        title: "Тарифы"
    },
    TariffConnection: {
        component: Private.TariffConnection,
        path: "/tariffs/:name",
        breadcrumb: "Тариф",
        class: "plans-connection",
        title: "Тариф"
    },
    NotificationsPage: {
        component: Private.NotificationsPage,
        path: "/notifications",
        breadcrumb: "Уведомления",
        class: "notifications",
        title: "Уведомления"
    },
    NewsPage: {
        component: Private.NewsPage,
        path: "/news",
        breadcrumb: "Новости",
        class: "page-news",
        title: "Новости"
    },
    NewsDescriptionPage: {
        component: Private.NewsDescriptionPage,
        path: "/news/:id",
        breadcrumb: "Новость",
        class: "page-news",
        title: "Новость"
    },
    SuperLingPage: {
        component: Private.SuperLingPage,
        path: "/super-link",
        breadcrumb: "Суперссылка",
        class: "page-super-link",
        title: "Суперссылка"
    },
    NpsPage: {
        component: Private.NpsPage,
        path: "/nps",
        breadcrumb: "Не плати за связь!",
        class: "page-nps",
        title: "Не плати за связь!"
    },
    ClearMyDataPage: {
        component: Public.ClearMyDataPage,
        path: "/clear_my_data",
        breadcrumb: "Запрос на удаление аккаунта",
        class: "page-index page-clear-my-data is-has-token",
        title: "Запрос на удаление аккаунта"
    }
};
export default routes;
