import React, {useContext} from "react";
import {Typography, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// forms
import TenForBezlimitForm from "../../forms/tenForBezlimit";
// core
import {LayoutContext} from "../../../core/Contexts";

export default React.memo(
    observer(function TenForBezlimit() {
        const contextLayout = useContext(LayoutContext);
        const {t} = useTranslation();

        return (
            <Typography as="div" className="bl-ten-for-bezlimit">
                <Space
                    size={_.gte(contextLayout?.width ?? 0, 768) ? 40 : 24}
                    direction="vertical"
                    align="center"
                >
                    <Typography as="div">
                        <Space size={8} direction="vertical" align="center">
                            <Typography
                                size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 17}
                                level={2}
                                color="#000000"
                            >
                                {t("10% за Безлимит")}
                            </Typography>
                        </Space>
                    </Typography>
                    <TenForBezlimitForm />
                </Space>
            </Typography>
        );
    })
);
