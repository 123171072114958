import React, {useContext, useState} from "react";
import {Space, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import "./style.scss";
// ui
// import Sort from "../../../../ui/sort";
import Grid from "../../../../ui/grid";
import Filter from "../../../../ui/filter";
// components
// import SortDropdown from "../../../../dropdown/sort";
import NumbersFiltersMobileModal from "../../../../../modal/numbersFilterMobile";
// import Error from "../../../../../modal/error";
// core
import {LayoutContext, ModalContext} from "../../../../../../core/Contexts";
// services
import PhoneService from "../../../../../../services/PhoneService";
// import PhonesGlobalService from "../../../../../../services/PhonesGlobalService";
import {MobXProviderContext, observer} from "mobx-react";
import NumberFilterMode from "../../../../../modal/numberFilterMode";
// import PhonesFiltersService from "../../../../../../services/PhonesFiltersService";

export default React.memo(
    observer(function Header({
        phone,
        type,
        tariff,
        categories,
        region,
        pattern,
        gridType,
        sort,
        phonePattern,
        phoneCombs,
        Service,
        isFavorite,
        setIsFavorite,
        setType,
        setTariff,
        setCategories,
        setRegion,
        setPattern,
        setSort,
        setGridType,
        setCssClass,
        setAllIsChecked,
        setPhone,
        setGridTypePrev,
        setPhonePattern,
        setPhoneCombs
    }: IProps) {
        const {store} = useContext(MobXProviderContext);
        const contextLayout = useContext(LayoutContext);
        const contextModal = useContext(ModalContext);
        const [value, setValue] = useState(gridType);

        const onCancel = () => {
            contextModal?.setCssClass("");
            contextModal?.setVisible(false);
        };

        const onClick = () => {
            if (isFavorite && _.lt(contextLayout?.width ?? 0, 768)) {
                contextModal?.setVisible(true);
                contextModal?.setChildren(
                    <NumberFilterMode isFavorite={isFavorite} setIsFavorite={setIsFavorite} />
                );
            } else {
                contextModal?.setCssClass(" bl-filter-modal-mobile");
                contextModal?.setVisible(true);
                contextModal?.setOnCancel(() => onCancel);
                contextModal?.setChildren(
                    <NumbersFiltersMobileModal
                        phone={phone}
                        type={type}
                        tariff={tariff}
                        categories={categories}
                        region={region}
                        pattern={pattern}
                        phonePattern={phonePattern}
                        phoneCombs={phoneCombs}
                        sort={sort}
                        isFavorite={isFavorite}
                        setIsFavorite={setIsFavorite}
                        setType={setType}
                        setTariff={setTariff}
                        setCategories={setCategories}
                        setRegion={setRegion}
                        setPattern={setPattern}
                        setAllIsChecked={setAllIsChecked}
                        setPhone={setPhone}
                        setPhonePattern={setPhonePattern}
                        setPhoneCombs={setPhoneCombs}
                        Service={Service}
                    />
                );
            }
        };

        return (
            <Typography as="div" className="bl-numbers-list-header">
                <Space size={_.gte(contextLayout?.width ?? 0, 768) ? 24 : 16}>
                    {_.lte(contextLayout?.width ?? 0, 992) && (
                        <Filter
                            isFavorite={isFavorite}
                            count={_.size(
                                [
                                    ...type,
                                    ...tariff,
                                    ...categories,
                                    ...region,
                                    ...pattern,
                                    _.chain(phonePattern)
                                        .filter((i) => !_.isNil(i) && !_.isEmpty(i))
                                        .size()
                                        .valueOf() && 1,
                                    !_.isNil(phoneCombs) &&
                                        _.size(_.replace(phoneCombs, /,/gi, "")) &&
                                        1
                                ].filter((i) => i)
                            )}
                            onClick={onClick}
                        />
                    )}
                    {/* <Sort
                        sortKey={sort}
                        menu={
                            <SortDropdown
                                sortKey={sort}
                                set={setCssClass}
                                onTrigger={(key: string) => {
                                    setSort(key);

                                    try {
                                        PhonesGlobalService.fetchAll({
                                            type,
                                            tariff,
                                            categories,
                                            region,
                                            pattern,
                                            phone_pattern: _.chain(phonePattern)
                                                .reduce<string[]>((res, i) => {
                                                    (res || (res = [])).push(!_.trim(i) ? "N" : i);
                                                    return res;
                                                }, [])
                                                .join("")
                                                .valueOf(),
                                            phone_combs: phoneCombs,
                                            sort: key,
                                            general_number:
                                                PhonesFiltersService.phonesFilters.general_number
                                        });
                                    } catch (e: any) {
                                        contextModal?.setVisible(true);
                                        contextModal?.setChildren(<Error />);
                                        contextModal?.setOnCancel(() => onCancel);
                                    }
                                }}
                            />
                        }
                        set={setCssClass}
                    /> */}
                    {!store.numberFilters.isCollection && _.gte(contextLayout?.width ?? 0, 768) && (
                        <Grid
                            value={value}
                            onChange={(value: string | number) => {
                                setGridType(value.toString());
                                setGridTypePrev(value.toString());
                                setValue(value.toString());
                            }}
                        />
                    )}
                </Space>
            </Typography>
        );
    })
);

interface IProps {
    phone: string | undefined;
    type: string[];
    tariff: string[];
    categories: string[];
    region: string[];
    pattern: string[];
    gridType: string;
    sort: string;
    phonePattern: string[];
    phoneCombs: string | undefined;
    Service: PhoneService;
    isFavorite: boolean;
    setIsFavorite: React.Dispatch<React.SetStateAction<boolean>>;
    setType: React.Dispatch<React.SetStateAction<string[]>>;
    setTariff: React.Dispatch<React.SetStateAction<string[]>>;
    setCategories: React.Dispatch<React.SetStateAction<string[]>>;
    setRegion: React.Dispatch<React.SetStateAction<string[]>>;
    setPattern: React.Dispatch<React.SetStateAction<string[]>>;
    setSort: React.Dispatch<React.SetStateAction<string>>;
    setGridType: React.Dispatch<React.SetStateAction<string>>;
    setGridTypePrev: React.Dispatch<React.SetStateAction<string>>;
    setCssClass: React.Dispatch<React.SetStateAction<boolean>>;
    setAllIsChecked: React.Dispatch<React.SetStateAction<boolean>>;
    setPhone: React.Dispatch<React.SetStateAction<string | undefined>>;
    setPhonePattern: React.Dispatch<React.SetStateAction<string[]>>;
    setPhoneCombs: React.Dispatch<React.SetStateAction<string | undefined>>;
}
