import {useContext, useEffect, useState} from "react";
import {Button, Col, Icon, Row, Space, Title, Typography} from "@bezlimit/bezlimit-ui";
import _ from "lodash";
import {observer} from "mobx-react";
import {useTranslation} from "react-i18next";
import "./style.scss";
// import like from "../../../../../images/like.png";
// import like2 from "../../../../../images/like2.png";
// core
import {LayoutContext, ModalContext} from "../../../../../core/Contexts";
// services
import Slider from "./slider";
import Footer from "../../../../blocks/footer";
import {axiosFetchBuilder} from "../../../../../core/Axios";
import {IPosters, IStatistics, IUrl} from "../../../../../core/models/User";
// components
import Stat1 from "../../../../../images/stat_img_1.png";
import Stat2 from "../../../../../images/stat_img_2.png";
import Stat3 from "../../../../../images/stat_img_3.png";
import {Scroller} from "../../../../blocks/scroller";
import ShimmerStatistic from "./shimmerStatistic";

const Bottom: React.FC = observer(() => {
    const {t} = useTranslation();
    const contextModal = useContext(ModalContext);
    const contextLayout = useContext(LayoutContext);
    const [posters, setPosters] = useState<IPosters>();
    const [statistics, setStatistics] = useState<IStatistics>();

    const onModal = (type: number) => {
        let data: IUrl[] = [];
        switch (type) {
            case 1:
                data = posters?.poster ?? [];
                break;
            case 2:
                data = posters?.flyer ?? [];
                break;
            default:
                data = posters?.social ?? [];
                break;
        }

        contextModal?.setChildren(<Slider data={data} />);
        contextModal?.setVisible(true);
        contextModal?.setCssClass(" bl-slider-superlink-modal");
        contextModal?.setOnCancel(() => () => {
            contextModal?.setCssClass("");
            contextModal?.setVisible(false);
        });
    };

    useEffect(() => {
        async function f() {
            const res = await axiosFetchBuilder<IPosters>({
                url: "/super-link/posters"
            });
            setPosters(res);
        }
        f();
    }, []);

    useEffect(() => {
        async function f() {
            try {
                const res = await axiosFetchBuilder<IStatistics>({
                    url: "/super-link/statistics",
                    loading: false
                });
                setTimeout(() => {
                    setStatistics(res);
                }, 5000);
            } catch (error) {
                throw error;
            }
        }
        f();
    }, []);

    return (
        <Typography as="div" className="bl-sl-bottom">
            <Typography as="div" className="inner">
                <Space direction="vertical" size={_.gt(contextLayout?.width ?? 0, 992) ? 98 : 36}>
                    <Typography as="div" className="text-center">
                        <Title>
                            <Typography as="div" className="first">
                                {t("Как работает")}
                            </Typography>
                            <Typography as="div" className="second">
                                {t("суперссылка")}
                            </Typography>
                        </Title>
                    </Typography>
                    <Typography as="div" className="text-center snake">
                        {_.gt(contextLayout?.width ?? 0, 1240) ? (
                            <>
                                <Icon name="snake" size="1197" />
                                <Typography as="div" className="item first">
                                    <Typography
                                        as="div"
                                        className="num"
                                        fontFamily="alumna"
                                        size={160}
                                    >
                                        1
                                    </Typography>
                                    <Typography as="div" className="text" color="#fff" size={36}>
                                        <Typography as="b">{t("Поделись")}</Typography>
                                        <Typography as="span">{t("персональной")}</Typography>
                                        <Typography as="span">{t("суперссылкой")}</Typography>
                                    </Typography>
                                </Typography>
                                <Typography as="div" className="item second">
                                    <Typography
                                        as="div"
                                        className="num"
                                        fontFamily="alumna"
                                        size={160}
                                    >
                                        2
                                    </Typography>
                                    <Typography as="div" className="text" color="#fff" size={36}>
                                        <Typography as="b">{t("Пользователь")}</Typography>
                                        <Typography as="b">{t("самостоятельно")}</Typography>
                                        <Typography as="span">{t("подключает")}</Typography>
                                        <Typography as="span">{t("номер")}</Typography>
                                    </Typography>
                                </Typography>
                                <Typography as="div" className="item third">
                                    <Typography
                                        as="div"
                                        className="num"
                                        fontFamily="alumna"
                                        size={160}
                                    >
                                        3
                                    </Typography>
                                    <Typography as="div" className="text" color="#fff" size={36}>
                                        <Typography as="span">
                                            {t("Ты")}{" "}
                                            <Typography as="b">{t("получаешь")}</Typography>
                                        </Typography>
                                        <Typography as="span">
                                            <Typography as="b">{t("бонусы")}</Typography>{" "}
                                            <Typography as="span">{t("за каждую ")}</Typography>
                                        </Typography>
                                        <Typography as="span">{t("активацию")}</Typography>
                                    </Typography>
                                </Typography>
                            </>
                        ) : (
                            <>
                                <Typography as="div" className="item first">
                                    <Icon name="snake2" size="318" />
                                    <Typography
                                        as="div"
                                        className="num"
                                        fontFamily="alumna"
                                        size={_.gt(contextLayout?.width ?? 0, 320) ? 82 : 62}
                                    >
                                        1
                                    </Typography>
                                    <Typography
                                        as="div"
                                        className="text"
                                        color="#fff"
                                        size={_.gt(contextLayout?.width ?? 0, 320) ? 18 : 14}
                                    >
                                        <Typography as="b">{t("Поделись")}</Typography>
                                        <Typography as="span">{t("персональной")}</Typography>
                                        <Typography as="span">{t("суперссылкой")}</Typography>
                                    </Typography>
                                </Typography>
                                <Typography as="div" className="item second">
                                    <Icon name="snake2" size="318" />
                                    <Typography
                                        as="div"
                                        className="num"
                                        fontFamily="alumna"
                                        size={_.gt(contextLayout?.width ?? 0, 320) ? 82 : 62}
                                    >
                                        2
                                    </Typography>
                                    <Typography
                                        as="div"
                                        className="text"
                                        color="#fff"
                                        size={_.gt(contextLayout?.width ?? 0, 320) ? 18 : 14}
                                    >
                                        <Typography as="b">{t("Пользователь")}</Typography>
                                        <Typography as="b">{t("самостоятельно")}</Typography>
                                        <Typography as="span">{t("подключает")}</Typography>
                                        <Typography as="span">{t("номер")}</Typography>
                                    </Typography>
                                </Typography>
                                <Typography as="div" className="item third">
                                    <Icon name="snake2" size="318" />
                                    <Typography
                                        as="div"
                                        className="num"
                                        fontFamily="alumna"
                                        size={_.gt(contextLayout?.width ?? 0, 320) ? 82 : 62}
                                    >
                                        3
                                    </Typography>
                                    <Typography
                                        as="div"
                                        className="text"
                                        color="#fff"
                                        size={_.gt(contextLayout?.width ?? 0, 320) ? 18 : 14}
                                    >
                                        <Typography as="span">
                                            {t("Ты")}{" "}
                                            <Typography as="b">{t("получаешь")}</Typography>
                                        </Typography>
                                        <Typography as="span">
                                            <Typography as="b">{t("бонусы")}</Typography>{" "}
                                            <Typography as="span">{t("за каждую ")}</Typography>
                                        </Typography>
                                        <Typography as="span">{t("активацию")}</Typography>
                                    </Typography>
                                </Typography>
                            </>
                        )}
                    </Typography>
                    <Typography as="div" className="text-center">
                        <Title className="title2">
                            <Typography as="div" className="first">
                                {t("Мы продумали все")}
                            </Typography>
                            <Typography as="div" className="second">
                                {t("и даже это")}
                            </Typography>
                        </Title>
                    </Typography>
                    <Typography
                        as="div"
                        className="text-center subtitle"
                        color="#fff"
                        size={_.gt(contextLayout?.width ?? 0, 992) ? 36 : 18}
                    >
                        <Typography as="span">{t("Рекламная продукция Безлимит")}</Typography>
                        <Typography as="span">
                            <Typography as="b">{t("с твоей")}</Typography>{" "}
                            <Typography as="span">{t("персональной")}</Typography>{" "}
                            <Typography as="b">{t("суперссылкой")}</Typography>
                        </Typography>
                    </Typography>
                    <Typography as="div" className="text-center actions">
                        <Row gutter={[16, 16]}>
                            <Col xs={24} lg={7}>
                                <Button
                                    className="b1"
                                    icon={<Icon name="sp1" size="98" />}
                                    onClick={() => onModal(1)}
                                >
                                    {t("Плакаты")}
                                </Button>
                            </Col>
                            <Col xs={24} lg={7}>
                                <Button
                                    className="b2"
                                    icon={<Icon name="sp2" size="131" />}
                                    onClick={() => onModal(2)}
                                >
                                    {t("Флаеры")}
                                </Button>
                            </Col>
                            <Col xs={24} lg={10}>
                                <Button
                                    className="b3"
                                    icon={<Icon name="sp3" size="119" />}
                                    onClick={() => onModal(3)}
                                >
                                    {t("Картинки для соцсетей")}
                                </Button>
                            </Col>
                        </Row>
                    </Typography>
                    <Typography as="div" className="bl-statistics">
                        {_.gt(contextLayout?.width ?? 0, 425) ? (
                            <Title className="title-statistic">Статистика по вашей ссылке</Title>
                        ) : (
                            <>
                                <Title className="title-statistic-mobile">
                                    <Typography as="div" className="first">
                                        {t("Статистика")}
                                    </Typography>
                                    <Typography as="div" className="second">
                                        {t("по вашей ссылке")}
                                    </Typography>
                                </Title>
                                <Scroller />
                            </>
                        )}
                        {statistics ? (
                            <Typography as="div" className="bl-group-statistics">
                                <Typography as="div" className="group-coll">
                                    <Typography as="div" className="group-count">
                                        <img
                                            className="social-icon"
                                            src={Stat1}
                                            width={68}
                                            alt="logo"
                                        />
                                        <Space direction="vertical">
                                            <Typography
                                                as="div"
                                                className="count"
                                                size={
                                                    _.gt(contextLayout?.width ?? 0, 425) ? 42 : 32
                                                }
                                            >
                                                {statistics?.visits_count}
                                            </Typography>
                                            <Typography
                                                as="div"
                                                className="text-count"
                                                size={
                                                    _.gt(contextLayout?.width ?? 0, 425) ? 24 : 18
                                                }
                                            >
                                                {t("count_transition_suffix", {
                                                    count: statistics?.visits_count
                                                })}
                                            </Typography>
                                        </Space>
                                    </Typography>
                                    <Typography as="div" className="group-count">
                                        <img
                                            className="social-icon"
                                            src={Stat3}
                                            width={68}
                                            alt="logo"
                                        />
                                        <Space direction="vertical">
                                            <Typography
                                                as="div"
                                                className="count"
                                                size={
                                                    _.gt(contextLayout?.width ?? 0, 425) ? 42 : 32
                                                }
                                            >
                                                {statistics?.reservation_count}
                                            </Typography>
                                            <Typography
                                                as="div"
                                                className="text-count"
                                                size={
                                                    _.gt(contextLayout?.width ?? 0, 425) ? 24 : 18
                                                }
                                            >
                                                {t("count_armor_suffix", {
                                                    count: statistics?.reservation_count
                                                })}
                                            </Typography>
                                        </Space>
                                    </Typography>
                                    <Typography as="div" className="group-count">
                                        <img
                                            className="social-icon"
                                            src={Stat2}
                                            width={68}
                                            alt="logo"
                                        />
                                        <Space direction="vertical">
                                            <Typography
                                                as="div"
                                                className="count"
                                                size={
                                                    _.gt(contextLayout?.width ?? 0, 425) ? 42 : 32
                                                }
                                            >
                                                {statistics?.activation_count}
                                            </Typography>
                                            <Typography
                                                as="div"
                                                className="text-count"
                                                size={
                                                    _.gt(contextLayout?.width ?? 0, 425) ? 24 : 18
                                                }
                                            >
                                                {t("count_activations_suffix", {
                                                    count: statistics?.activation_count
                                                })}
                                            </Typography>
                                        </Space>
                                    </Typography>
                                </Typography>
                            </Typography>
                        ) : (
                            <ShimmerStatistic />
                        )}
                    </Typography>
                </Space>
            </Typography>
            <Footer />
        </Typography>
    );
});
export default Bottom;
