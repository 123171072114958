import {useTranslation} from "react-i18next";
import {Checkbox, Form, Typography} from "@bezlimit/bezlimit-ui";
import {FormItemProps} from "antd";
import _ from "lodash";
// core
import {IFilter} from "../../../core/models/Phones";

export default function CheckboxField({name, items, props}: IProps) {
    const {t} = useTranslation();

    return (
        <Form.Item {...props} className="field-checkbox" name={name}>
            <Checkbox.Group>
                {_.chain(items)
                    .map(({name: label, filter: value}) => (
                        <Checkbox key={value} value={value}>
                            <Typography as="div" size={17} color="#000">
                                {t(label)}
                            </Typography>
                        </Checkbox>
                    ))
                    .valueOf()}
            </Checkbox.Group>
        </Form.Item>
    );
}

interface IProps {
    name: string;
    items: IFilter[];
    props?: FormItemProps<any>;
}
