const translations = {
    ru: {
        count_bonus_one: "{count} бонуса",
        count_bonus_few: "{count} бонусов",
        count_bonus_many: "{count} бонусов",
        count_bonus_succes_one: "{count} бонус",
        count_bonus_succes_few: "{count} бонуса",
        count_bonus_succes_many: "{count} бонусов",
        count_number_clock_one: "{count} минуту",
        count_number_clock_few: "{count} минуты",
        count_number_clock_many: "{count} минут",
        count_number_payments_one: "{count} Начисление",
        count_number_payments_few: "{count} Начисления",
        count_number_payments_many: "{count} Начислений",
        count_number_payouts_one: "{count} Списание",
        count_number_payouts_few: "{count} Списания",
        count_number_payouts_many: "{count} Списаний",
        count_second_suffix_one: "секунда",
        count_second_suffix_few: "секунды",
        count_second_suffix_many: "секунд",
        count_minute_suffix_one: "минута",
        count_minute_suffix_few: "минуты",
        count_minute_suffix_many: "минут",
        count_hour_suffix_one: "час",
        count_hour_suffix_few: "часа",
        count_hour_suffix_many: "часов",
        count_day_suffix_one: "день",
        count_day_suffix_few: "дня",
        count_day_suffix_many: "дней",
        count_month_suffix_one: "месяц",
        count_month_suffix_few: "месяца",
        count_month_suffix_many: "месяцев",
        count_transition_suffix_one: "Переход",
        count_transition_suffix_few: "Перехода",
        count_transition_suffix_many: "Переходов",
        count_activations_suffix_one: "Активация",
        count_activations_suffix_few: "Активации",
        count_activations_suffix_many: "Активаций",
        count_armor_suffix_one: "Бронь",
        count_armor_suffix_few: "Брони",
        count_armor_suffix_many: "Броней"
    },

    en: {
        count_bonus_one: "{count} bonus",
        count_bonus_few: "{count} bonuses",
        count_bonus_many: "{count} bonuses",
        count_bonus_other: "{count} bonuses",
        count_bonus_succes_one: "{count} bonus",
        count_bonus_succes_few: "{count} bonuses",
        count_bonus_succes_many: "{count} bonuses",
        count_number_clock_one: "{count} minute",
        count_number_clock_few: "{count} minutes",
        count_number_clock_many: "{count} minutes",
        count_number_payments_one: "{count} Payment",
        count_number_payments_few: "{count} Payments",
        count_number_payments_many: "{count} Payments",
        count_number_payouts_one: "{count} Payout",
        count_number_payouts_few: "{count} Payouts",
        count_number_payouts_many: "{count} Payouts",
        count_second_suffix_one: "second",
        count_second_suffix_few: "seconds",
        count_second_suffix_many: "seconds",
        count_minute_suffix_one: "minute",
        count_minute_suffix_few: "minutes",
        count_minute_suffix_many: "minutes",
        count_hour_suffix_one: "hour",
        count_hour_suffix_few: "hours",
        count_hour_suffix_many: "hours",
        count_day_suffix_one: "day",
        count_day_suffix_few: "days",
        count_day_suffix_many: "days",
        count_month_suffix_one: "month",
        count_month_suffix_few: "monthes",
        count_month_suffix_many: "monthes"
    }
};

export default translations;
