import {Collapse, Row, Typography} from "@bezlimit/bezlimit-ui";
import "./style.scss";
import _ from "lodash";
import {observer} from "mobx-react";
import React from "react";
// servise
import DeliveryService from "../../../../../services/DeliveryService";
import ListColapse from "../../../delivery/groupDeliveryColapse/listColapse";
import ListDescription from "./listDescription";
import {useNavigate, useSearchParams} from "react-router-dom";

const {Panel} = Collapse;

export default React.memo(
    observer(function List() {
        const params = {sort: "date", order: "newest"};
        const [, setSearchParams] = useSearchParams();
        const naigate = useNavigate();
        const onTabClick = (key: string, recipient?: any) => {
            setSearchParams(params);
            naigate({
                pathname: `/${key}`,
                search: recipient && _.isString(recipient) ? recipient : ""
            });
        };

        return (
            <Typography as="div" className="bl-widget-delivery-list">
                <Row gutter={[16, 32]}>
                    {_.chain(DeliveryService.items)
                        .take(3)
                        .map(
                            (
                                {
                                    id,
                                    status_name,
                                    created_at,
                                    track_number,
                                    address,
                                    comment,
                                    fio,
                                    logistic_comment,
                                    additional_comment,
                                    phone,
                                    status,
                                    shipped_at,
                                    track_url
                                },
                                key
                            ) => {
                                return (
                                    <Collapse ghost expandIconPosition="end" key={key}>
                                        <ListColapse
                                            id={id}
                                            status_name={status_name}
                                            created_at={created_at}
                                            track_number={track_number}
                                            status={status}
                                            shipped_at={shipped_at}
                                            track_url={track_url}
                                        />

                                        <Panel header="" key={""}>
                                            <ListDescription
                                                fio={fio}
                                                phone={phone}
                                                address={address}
                                                comment={comment}
                                                logistic_comment={logistic_comment}
                                                additional_comment={additional_comment}
                                                onTabClick={onTabClick}
                                            />
                                        </Panel>
                                    </Collapse>
                                );
                            }
                        )
                        .valueOf()}
                </Row>
            </Typography>
        );
    })
);
