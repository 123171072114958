import {Col, Row, Typography} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
// components
import MyBonuses from "../../../blocks/widgets/myBonuses";
import DatabaseOfNumbers from "../../../blocks/widgets/databaseOfNombers";
import PersonalManager from "../../../blocks/widgets/personalManages";
import InviteAUser from "../../../blocks/widgets/inviteAUser";
import NewsSlider from "../../../blocks/widgets/newsSlider";
import Support from "../../../blocks/widgets/support";
import ReplenichNumberBonuses from "../../../blocks/widgets/replenichNumberBonuses";
import OutputNumberBonuses from "../../../blocks/widgets/outputNumberBonuses";
import InBooking from "../../../blocks/widgets/inBooking";
import Delivery from "../../../blocks/widgets/delivery";
import MyDealers from "../../../blocks/widgets/myDealers";
import MyActivations from "../../../blocks/widgets/myActivations";
import Loyalty from "../../../blocks/widgets/loyalty";
import LoyaltyBannerSlider from "../../../blocks/bonuses/bannerSlider";
import _ from "lodash";
// store
import Store from "../../../../stores";
import MyBooking from "../../../blocks/widgets/myBooking";
import {useTranslation} from "react-i18next";
import SuperLinkWidget from "../../../blocks/widgets/superLink";
import LotterySlider from "../../../blocks/widgets/lotterySlider";
import {useState} from "react";
import HintMobile from "../../../blocks/widgets/loyalty/hintMobile";

export default observer(function DashboardPage() {
    const {t} = useTranslation();
    const [visibleLoyalty, setVisibleLoyalty] = useState(true);

    return (
        <>
            {visibleLoyalty ? (
                <Row justify="center" gutter={[16, 16]}>
                    <Col lg={0} md={0} xs={24}>
                        <Typography className="title-gold-dashboard-page">
                            {t("Кабинет дилера")}
                        </Typography>
                    </Col>
                    <Col lg={12} md={24} xs={24}>
                        <SuperLinkWidget />
                    </Col>
                    <Col lg={12} md={24} xs={24}>
                        <LotterySlider />
                    </Col>
                    <Col span={24}>
                        <Row justify="center" gutter={[16, 16]}>
                            <Col lg={{span: 8, push: 8}} md={24} xs={24}>
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <DatabaseOfNumbers />
                                    </Col>
                                    <Col span={24}>
                                        <Loyalty setVisibleLoyalty={setVisibleLoyalty} />
                                    </Col>
                                    {!_.isNil(Store.user.loyalty) && (
                                        <Col lg={24} md={24} xs={24}>
                                            <LoyaltyBannerSlider />
                                        </Col>
                                    )}
                                </Row>
                            </Col>
                            <Col lg={{span: 8, pull: 8}} md={24} xs={24}>
                                <MyBonuses />
                            </Col>
                            <Col lg={8} md={24} xs={24}>
                                <Row gutter={[16, 16]}>
                                    <Col span={24}>
                                        <ReplenichNumberBonuses />
                                    </Col>
                                    <Col span={24}>
                                        <OutputNumberBonuses />
                                    </Col>
                                    <Col span={24}>
                                        <NewsSlider />
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Row justify="space-between" gutter={[16, 16]}>
                            <Col lg={Store.user?.level?.level !== 2 ? 8 : 8} md={24} xs={24}>
                                <MyBooking />
                            </Col>
                            <Col lg={Store.user?.level?.level !== 2 ? 8 : 0} md={24} xs={24}>
                                {Store.user?.level?.level !== 2 && <MyDealers />}
                            </Col>
                            <Col lg={Store.user?.level?.level !== 2 ? 8 : 16} md={24} xs={24}>
                                <MyActivations />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24}>
                        <Delivery />
                    </Col>
                    <Col span={24}>
                        <Row justify="center" gutter={[16, 16]}>
                            <Col lg={Store.user?.level?.level !== 2 ? 9 : 16} md={24} xs={24}>
                                <Support />
                            </Col>
                            <Col lg={Store.user?.level?.level !== 2 ? 9 : 8} md={24} xs={24}>
                                <PersonalManager title="Ваш персональный менеджер" />
                            </Col>
                            {Store.user?.level?.level !== 2 && (
                                <Col lg={6} md={24} xs={24}>
                                    <InviteAUser />
                                </Col>
                            )}
                        </Row>
                    </Col>
                    <Col span={24}>
                        <InBooking />
                    </Col>
                </Row>
            ) : (
                <HintMobile setVisibleLoyalty={setVisibleLoyalty} />
            )}
        </>
    );
});
