import React, {useContext} from "react";
import {observer} from "mobx-react";
import {Block, Col, Typography, Row, Space} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import cn from "classnames";
import _ from "lodash";
// components
import {getLoyaltyBanner} from "../bannerPicture/loyaltyBanner";
import {getLoyaltyBannerSmall} from "../bannerPicture/loyaltyBannerSmall";
// core
import {ILoyalty} from "../../../../../core/models/Loyalty";
import {LayoutContext} from "../../../../../core/Contexts";

import "./style.scss";

export default observer(function Banner({loyalty}: IProps) {
    const {t} = useTranslation();
    const contextLayout = useContext(LayoutContext);

    const ratePersonal = loyalty?.rate_personal ?? 0;
    const rateLevel = loyalty?.rate_level ?? 0;

    return (
        <Block className={cn("bl-banner", `${loyalty.code}-banner`)}>
            <Row gutter={[16, 0]}>
                <Col span={7} className="banner-title">
                    <Typography className="banner-title-text">{loyalty.name}</Typography>
                    <div className="banner-title-picture">
                        {(_.gte(contextLayout?.width, 1200) ?? 0) ||
                        ((_.lte(contextLayout?.width, 991) ?? 0) &&
                            (_.gte(contextLayout?.width, 400) ?? 0))
                            ? getLoyaltyBanner(loyalty.code, loyalty.name)
                            : getLoyaltyBannerSmall(loyalty.code, loyalty.name)}
                    </div>
                </Col>
                <Col span={17} className="banner-content">
                    <Space size={30} direction="vertical" align="center">
                        <Typography as="div" className="banner-text">
                            <p>
                                {t("Баллов от ")}
                                <span>{loyalty.min_scores_cnt ?? 0}</span>
                                {t(`${loyalty.code === "top" ? "" : " до "}`)}
                                <span>
                                    {loyalty.code === "top" ? "" : loyalty.max_scores_cnt ?? 0}
                                </span>
                                {t(" за 30 дней ")}
                            </p>
                        </Typography>

                        <Typography as="div" className="banner-text last">
                            {loyalty.level < 2 ? (
                                <p>
                                    {t("За личные активации ")}
                                    <span>{ratePersonal}%</span>
                                    <br />
                                    {t("За дилерские активации ")}
                                    <span>{rateLevel}%</span>
                                </p>
                            ) : (
                                <p className="">
                                    <br />
                                    {t("Процент за активации ")}
                                    <span>{ratePersonal}%</span>
                                    <br />
                                </p>
                            )}
                        </Typography>
                    </Space>
                </Col>
            </Row>
        </Block>
    );
});

interface IProps {
    loyalty: ILoyalty;
}
