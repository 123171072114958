import {Col, Icon, Menu as MenuAntd, Row, Typography} from "@bezlimit/bezlimit-ui";
import {useTranslation} from "react-i18next";
import React from "react";
import {observer} from "mobx-react";
import _ from "lodash";
import "./style.scss";
// store
import Store from "../../../../stores";
// service
import LabelMenuRoute from "./labelMenuRoute";
import {useHookDealerOrService} from "./helper";

export default React.memo(
    observer(function Menu({toggle}: IProps) {
        const {t} = useTranslation("common");
        const {handleSwitchDealerOrService, click, selectedItem, isDealerOrService} =
            useHookDealerOrService(toggle);

        const menuItems = _.isEqual(isDealerOrService, "dealer")
            ? [
                  {key: "/", label: t("Главная")},
                  {key: "/numbers", label: t("База номеров")},
                  {key: "/profile", label: t("Профиль")},
                  {key: "/reservation", label: t("Брони и активации")},
                  {key: "/bonus", label: t("Бонусы")},
                  {key: "/history", label: t("История операций")},
                  {key: "https://disk.yandex.ru/d/upRoHABSpyghRQ", label: t("Рекламный контент")}
              ]
                  .concat(
                      Store.user?.level?.level !== 2 ? [{key: "/dealers", label: t("Дилеры")}] : []
                  )
                  .concat([
                      {key: "/deliveries", label: t("Доставки SIM-карт")},
                      {key: "/tariffs", label: t("Тарифы")},
                      //{key: "/redemption", label: t("Выкуп номеров")},
                      //   {key: "/lottery", label: t("Розыгрыш")},
                      {key: "/support", label: t("Поддержка")},
                      {key: "/logout", label: t("Выйти")}
                  ])
            : [
                  {key: "/insure", label: t("Страхование")},
                  {key: "/bezlimit-ru", label: t("Сайт Безлимит")},
                  {
                      key: "menu-store",
                      label: (
                          <LabelMenuRoute
                              click={click}
                              selectedItem={selectedItem}
                              header={"Приложение Store"}
                              arrayLabelAndRouteFromTo={[0, 2]}
                          />
                      )
                  },
                  {key: "https://bezlimit.ru/blog/", label: t("Блог")},
                  {
                      key: "menu-social",
                      label: (
                          <LabelMenuRoute
                              click={click}
                              selectedItem={selectedItem}
                              header={"Социальные сети"}
                              arrayLabelAndRouteFromTo={[2, 7]}
                          />
                      )
                  },
                  {key: "/logout", label: t("Выйти")}
              ];

        return (
            <Typography as="div" className="bl-menu sidebar">
                <div className="bl-menu--wrapper">
                    <Icon
                        name={"close"}
                        size={"26px"}
                        className={"sidebar-close"}
                        onClick={toggle}
                    />
                    <Row justify="space-between" className="bl-menu__dealer-service">
                        <Col
                            onClick={() => handleSwitchDealerOrService("dealer")}
                            className="bl-menu__dealer-service__dealer"
                        >
                            {t("Кабинет дилера ")}
                        </Col>
                        <Col
                            onClick={() => handleSwitchDealerOrService("service")}
                            className="bl-menu__dealer-service__service"
                        >
                            {t(" Все сервисы")}
                        </Col>
                    </Row>
                    <Typography as="div" className="links">
                        <MenuAntd
                            mode="inline"
                            defaultSelectedKeys={["/"]}
                            onClick={click}
                            selectedKeys={[selectedItem]}
                            items={[...(Store.user.token ? menuItems : [])]}
                        />
                    </Typography>
                </div>
                <img src={process.env.PUBLIC_URL + "/logo.png"} alt="" className={"sidebar-logo"} />
            </Typography>
        );
    })
);

interface IProps {
    toggle: () => void;
}
