import {Button, Typography, Icon} from "@bezlimit/bezlimit-ui";
import {observer} from "mobx-react";
import _ from "lodash";
import {useContext, useEffect, useState} from "react";
// store
import Store from "../../../../../stores";
import {ModalContext} from "../../../../../core/Contexts";
import SettingsService from "../../../../../services/SettingsService";
import HintModal from "./hintModal";
import useQuery from "../../../../../core/hooks/useQuery";

export default observer(function HintHeader() {
    const contextModal = useContext(ModalContext);
    const [nameCode, setNameCode] = useState("");
    const query = useQuery();

    const onCancel = () => {
        contextModal?.setVisible(false);
        contextModal?.setCssClass("");
    };

    const click = () => {
        contextModal?.setVisible(true);
        contextModal?.setCssClass(" bl-hint-modal-mobile");
        contextModal?.setChildren(<HintModal list={Store.settings.hints} />);
        contextModal?.setOnCancel(() => onCancel);
    };

    useEffect(() => {
        const url = new URL(window.location.href);
        let name: string;
        switch (url.pathname.split("/")[1]) {
            case "":
                name = "main";
                break;
            case "profile":
                name = "profile";
                break;
            case "reservation":
                if (url.pathname.split("/")[3]) {
                    name = "reservation-phone";
                    break;
                }
                name = "reservations";
                break;
            case "support":
                name = "support";
                break;
            case "dealers":
                name = "dealers";
                break;
            case "numbers":
                name = "phones";
                break;
            case "tariffs":
                name = "tariffs";
                break;
            case "bonus":
                name = "bonuses";
                break;
            case "deliveries":
                name = "delivery";
                break;
            case "history":
                name = "bonus-history";
                break;
            case "lottery":
                name = "lottery";
                break;
            case "activation":
                name = "activations";
                break;
            case "super-link":
                name = "super-link";
                break;
            default:
                name = "";
                break;
        }
        setNameCode(name);
        if (!_.isEqual(name, "")) {
            const f = async () => {
                await SettingsService.getHints(name);
                if (!!query.get("modal")) {
                    click();
                } else {
                    onCancel();
                }
            };
            f();
        }
    }, [window.location.href]);

    return (
        <>
            {nameCode && (
                <Typography as="div" className="bl-hint_header bl-hint_header__move-shake">
                    <Button
                        className="btn-default"
                        onClick={() => {
                            click();
                        }}
                        icon={<Icon className="bl-hint_header__icon" name="hint_header" />}
                    />
                </Typography>
            )}
        </>
    );
});
