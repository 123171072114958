import {NamePath} from "antd/lib/form/interface";
import {FieldError} from "rc-field-form/es/interface";
import _ from "lodash";

export function getFieldsErrorSize(f: (nameList?: NamePath[] | undefined) => FieldError[]) {
    return _.chain<FieldError[]>(f())
        .filter(({errors}: FieldError) => _.size(errors))
        .size()
        .valueOf();
}
